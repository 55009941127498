define('ember-new/models/dzu-statuses-summary', ['exports', 'ember-data', 'ember-new/models/abstract'], function (exports, _emberData, _emberNewModelsAbstract) {

    var attr = _emberData['default'].attr;
    var refOne = _emberData['default'].belongsTo;

    exports['default'] = _emberData['default'].Model.extend({
        mm: attr('number'),
        statusId: attr('string'),
        sum: attr('number'),
        number: attr('number'),
        name: attr('string')
    });
});
define("ember-new/models/renouncement-simplified", ["exports", "ember", "ember-data", "ember-new/models/abstract", "ember-new/models/base-statusable-document-mixin"], function (exports, _ember, _emberData, _emberNewModelsAbstract, _emberNewModelsBaseStatusableDocumentMixin) {

    var attr = _emberData["default"].attr;
    var refOne = _emberData["default"].belongsTo;

    exports["default"] = _emberNewModelsAbstract["default"].extend({
        renId: attr('string'),
        renUuid: attr('string'),
        conType: attr('string'),
        renContractNo: attr('string'),
        conContractNo: attr('string'),
        conStatus: attr('string'),
        conUuid: attr('string'),
        tpaSpecialist: attr('string'),
        cliFirstName: attr('string'),
        cliLastName: attr('string'),
        cliPesel: attr('string'),
        renDateCreated: attr('string'),
        renStatusChangedDate: attr('string'),
        renDatePostmark: attr('string'),
        renDateEnter: attr('string'),
        renStatus: attr('string'),
        renType: attr('string'),
        hasContractsProposal: attr('string'),
        resignationIsOriginal: attr('string'),

        cliFirstNameSecondClient: attr('string'),
        cliLastNameSecondClient: attr('string'),
        cliPeselSecondClient: attr('string'),

        renPpmAddress: attr('string'),
        isAnonymized: attr('string'),

        renStatusClass: (function () {
            if (this.get('renStatus') == 'Skuteczne') {
                return 'danger';
            } else if (this.get('renStatus') == 'Niekuteczne') {
                return 'primary';
            }
            return 'warning';
        }).property('renStatus'),

        anonymizeAction: (function () {
            if (this.get('conUuid')) {
                return 'renouncement.anonymize-contract';
            }

            return 'renouncement.anonymize';
        }).property('conUuid'),

        anonymizeId: (function () {
            if (this.get('anonymizeAction') === 'renouncement.anonymize-contract') {
                return this.get('conUuid');
            }

            return this.get('renUuid');
        }).property('anonymizeAction'),

        alreadyAnonymized: (function () {
            if (this.get('isAnonymized') == "tak") {
                return true;
            } else {
                return false;
            }
        }).property('isAnonymized'),

        isAllowedToAnonymize: (function () {
            return this.get('isAnonymized') !== "tak" && this.get("TheUser").isGranted(['ROLE_GDPR_SPECIALIST']);
        }).property('isAllowedToAnonymize')
    });
});
define('ember-new/controllers/b2b-contract/administration/gas-business-tariff/list', ['exports', 'ember', 'ember-new/config/environment', 'ember-new/mixins/sortable-controller', 'ember-new/mixins/filterable-controller', 'ember-new/mixins/paginable-controller'], function (exports, _ember, _emberNewConfigEnvironment, _emberNewMixinsSortableController, _emberNewMixinsFilterableController, _emberNewMixinsPaginableController) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsSortableController['default'], _emberNewMixinsFilterableController['default'], _emberNewMixinsPaginableController['default'], {

        init: function init() {
            this._super.apply(this, arguments);
        },

        ajax: _ember['default'].inject.service('ajax'),
        applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),
        dictionariesManager: _ember['default'].inject.service('dictionaries-manager'),
        sortBy: 'date',
        sortModeAsc: false,
        itemsPerPage: 25,

        queryParams: ['usysId', 'excise', 'tariffFortum', 'tariffPgnig', 'product'],

        usysId: '',
        excise: '',
        tariffFortum: '',
        tariffPgnig: '',
        product: '',

        excisesToFilter: [{ label: 'Bez akcyzy', value: '0' }, { label: 'Opałowy', value: '1' }, { label: 'Silniki spalinowe', value: '2' }],

        initializeGasProducts: function initializeGasProducts(gasProducts) {
            var ctrl = this;

            gasProducts = gasProducts.filter(function (item) {
                return item.get('isHidden') !== true || item.get('id') === ctrl.get('model').get('productUuid');
            });

            this.set('gasProducts', gasProducts);
        }
    });
});
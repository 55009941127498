define('ember-new/models/blog-post', ['exports', 'ember-new/models/abstract', 'ember-new/mixins/base-document', 'ember-data'], function (exports, _emberNewModelsAbstract, _emberNewMixinsBaseDocument, _emberData) {

    var BlogPost = _emberNewModelsAbstract['default'].extend(_emberNewMixinsBaseDocument['default'], {

        _attributesExcludeBySerializer: ['contentImage', 'contentFile', 'likesCount', 'isPublished', 'isArchived', 'contentTextAttachmentFormatted'],

        title: _emberData['default'].attr('string'),
        isPublished: _emberData['default'].attr('boolean', { defaultValue: false }),
        isArchived: _emberData['default'].attr('boolean', { defaultValue: false }),

        contentType: _emberData['default'].attr('string'),
        contentYoutube: _emberData['default'].attr('string'),
        contentVimeo: _emberData['default'].attr('string'),
        contentTextAttachment: _emberData['default'].attr('string'),
        contentTextAttachmentFormatted: _emberData['default'].attr('string'), // virtualProperty
        contentHtml: _emberData['default'].attr('string'),
        // virtual property
        contentImage: _emberData['default'].attr('string'),
        contentFile: _emberData['default'].attr('string'),
        likesCount: _emberData['default'].attr('number'),

        likesUsers: _emberData['default'].attr(),
        likesUsersList: _emberData['default'].attr(),

        likesCountText: (function () {
            var count = this.get('likesCount');
            var people = "osób";

            if (count > 1 && count < 5) {
                people = 'osoby';
            } else if (count === 1) {
                people = 'osoba';
            }

            return count + ' ' + people;
        }).property('likesCount'),

        likeText: (function () {
            var count = this.get('likesCount');
            var text = "lubi";

            if (count > 1 && count < 5) {
                text = 'lubią';
            }

            return text;
        }).property('likesCount'),

        hasAttachment: (function () {
            return this.get('contentType') != null;
        }).property('contentType'),

        imageAttachment: (function () {
            return this.get('contentType') == 'image';
        }).property('contentType'),

        pdfAttachment: (function () {
            return this.get('contentType') == 'pdf';
        }).property('contentType'),

        audioAttachment: (function () {
            return this.get('contentType') == 'mp3';
        }).property('contentType'),

        youtubeAttachment: (function () {
            return this.get('contentType') == 'youtube';
        }).property('contentType'),

        vimeoAttachment: (function () {
            return this.get('contentType') == 'vimeo';
        }).property('contentType'),

        textAttachment: (function () {
            return this.get('contentType') == 'text';
        }).property('contentType'),

        // relations
        category: _emberData['default'].belongsTo('BlogCategory', { inverse: 'posts' }),
        content: _emberData['default'].belongsTo('File', { inverse: null }),

        loggedUserLike: (function () {
            return this.get('likesUsers').contains(EmberNew.TheUser.model.id);
        }).property('likesUsers')

    });

    exports['default'] = BlogPost;
});
define('ember-new/models/business-ppg-volume-info', ['exports', 'ember-new/models/abstract'], function (exports, _emberNewModelsAbstract) {

    var number = DS.attr('number');

    exports['default'] = _emberNewModelsAbstract['default'].extend({
        c: number,
        c1: number,
        c2: number,
        c3: number
    });
});
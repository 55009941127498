define("ember-new/routes/b2b-contract/administration/section-pricelists/copy", ["exports", "ember-new/routes/authenticated", "ember"], function (exports, _emberNewRoutesAuthenticated, _ember) {
    exports["default"] = _emberNewRoutesAuthenticated["default"].extend({

        model: function model(params) {
            return _ember["default"].RSVP.hash({
                basePrice: this.store.findRecord('BasePriceSetListDzuViewModel', params.pricesetUuid),
                conditionProducts: this.store.findAll('EePriceConditionProductListViewModel')
            });
        },

        setupController: function setupController(controller, model) {
            controller.set('conditionProducts', model.conditionProducts);
            controller.set('model', model.basePrice);
        },

        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            }
        }
    });
});
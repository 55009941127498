define('ember-new/controllers/individual-contract/set-vc-status', ['exports', 'ember', 'ember-new/utils/validators', 'ember-new/config/environment', 'ember-new/mixins/individual-contract/multiple-contract-changes'], function (exports, _ember, _emberNewUtilsValidators, _emberNewConfigEnvironment, _emberNewMixinsIndividualContractMultipleContractChanges) {
    var computed = _ember['default'].computed;
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsIndividualContractMultipleContractChanges['default'], {

        status: false,

        checkVcStatusUrl: computed(function () {
            return _emberNewConfigEnvironment['default'].apiUrl + '/rest/setvcstatusbulkactionconfirmationviewmodels';
        }),

        setVcStatusUrl: computed(function () {
            return _emberNewConfigEnvironment['default'].apiUrl + '/rest/setvcstatuses';
        }),

        canCheckVc: computed('validDataCount', function () {
            return this.get('validDataCount') > 0;
        }),

        checkVc: function checkVc() {
            var _this = this;

            var url = this.get('checkVcStatusUrl');
            var contractNos = this.get('validData').mapBy('number'); // poprawne numery umow
            var vcStatus = this.get('status');

            var requestObject = { contractNos: contractNos, vcStatus: vcStatus };

            this.clearErrors();
            this.set('isLoadingCheck', true);
            this.set('checkResult', null);

            return this.get('ajax').ajaxPost(url, requestObject).then(function (res) {
                return _this.handleCheckResponse(res);
            })['catch'](function (err) {
                return _this.handleError(err);
            });
        },

        setVc: function setVc() {
            var _this2 = this;

            var uuids = this.get('contractsToSetUuids');
            var reqObjName = 'SetVcStatus';
            var url = this.get('setVcStatusUrl');
            var vcStatus = this.get('status');

            var requestObject = {};
            requestObject[reqObjName] = { uuids: uuids, vcStatus: vcStatus };

            this.clearErrors();
            this.set('isLoadingSet', true);
            return this.get('ajax').ajaxPost(url, requestObject).then(function (res) {
                return _this2.handleSetResponse(res);
            })['catch'](function (err) {
                return _this2.handleError(err);
            });
        },

        actions: {

            checkVc: function checkVc() {
                this.checkVc();
            },

            setVc: function setVc() {
                this.setVc();
            },

            toggleVcStatus: function toggleVcStatus() {
                if (this.get('vsStatusToggle')) {
                    this.set('status', true);
                } else {
                    this.set('status', false);
                }
            }

        }
    });
});
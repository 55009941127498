define('ember-new/components/cell-data-label', ['exports', 'ember'], function (exports, _ember) {

    /**
     * Komponent uzywany do wyswietlania danych m.in. w cenach i warunkach cenowych
     */
    exports['default'] = _ember['default'].Component.extend({
        beforeValue: null,
        afterValue: null,
        value: null,
        label: null,
        noValuePlaceholder: '-',
        displayZeroValue: false,
        valueToDisplay: _ember['default'].computed('displayZeroValue', function () {
            var val = this.get('value');
            if (val === 0 && this.get('displayZeroValue')) {
                return 0;
            } else if (!val && !this.get('displayZeroValue')) {
                return this.get('noValuePlaceholder');
            } else {
                return this.get('value');
            }
        })
    });
});
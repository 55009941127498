define('ember-new/models/set-status-info', ['exports', 'ember-new/models/abstract', 'ember-data'], function (exports, _emberNewModelsAbstract, _emberData) {

   var SetStatusInfo = _emberNewModelsAbstract['default'].extend({
      time: _emberData['default'].attr('date'),
      setBy: _emberData['default'].belongsTo('User', { inverse: null }),
      statusDef: _emberData['default'].belongsTo('StatusDefinition', { inverse: null }),
      previousStatusDef: _emberData['default'].belongsTo('StatusDefinition', { inverse: null }),
      comment: _emberData['default'].attr('string')
   });

   exports['default'] = SetStatusInfo;
});
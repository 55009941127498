define('ember-new/components/d-form/individual-contract/recording-vc', ['exports', 'ember', 'ember-new/templates/components/d-form', 'ember-new/config/environment', 'ember-new/components/d-form-command'], function (exports, _ember, _emberNewTemplatesComponentsDForm, _emberNewConfigEnvironment, _emberNewComponentsDFormCommand) {
    exports['default'] = _emberNewComponentsDFormCommand['default'].extend({
        layout: _emberNewTemplatesComponentsDForm['default'],

        commandName: 'BoAddAudioToContractCommand',

        resolveContents: function resolveContents(form) {
            return new _ember['default'].RSVP.Promise(function (resolve, reject) {

                var store = form.get('store');
                var upload = store.peekAll('upload');
                _ember['default'].RSVP.Promise.all([upload]).then(function (result) {
                    var upload = result[0];

                    resolve(_ember['default'].Object.create({
                        recordingUuid: upload
                    }));
                });
            });
        },

        configure: function configure() {
            this.registerTransformer('recordingUuid', function (uuid) {
                return uuid;
            }, function (file) {
                if (typeof file === 'string') {
                    return file;
                } else if (file) {
                    return file.get('uuid');
                }
            });
        }

    });
});
define('ember-new/controllers/b2b-contract/dzu/show-gas/ppg/details', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        actions: {
            closePpgView: function closePpgView() {
                this.transitionToRoute('b2b-contract.dzu.show-gas.ppg');
            }
        }

    });
});
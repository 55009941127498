define('ember-new/models/gas-branch-dictionary-entry', ['exports', 'ember-new/models/abstract'], function (exports, _emberNewModelsAbstract) {

	var GasBranchDictionaryEntry = _emberNewModelsAbstract['default'].extend({
		label: DS.attr('string'),
		name: DS.attr('string'),
		isHidden: DS.attr('boolean'),
		uuid: DS.attr('string'),
		usysExportId: DS.attr('string')
	});

	exports['default'] = GasBranchDictionaryEntry;
});
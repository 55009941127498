define('ember-new/helpers/lt', ['exports', 'ember-truth-helpers/helpers/lt'], function (exports, _emberTruthHelpersHelpersLt) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberTruthHelpersHelpersLt['default'];
    }
  });
  Object.defineProperty(exports, 'lt', {
    enumerable: true,
    get: function get() {
      return _emberTruthHelpersHelpersLt.lt;
    }
  });
});
define('ember-new/controllers/b2b-contract/settings/base-prices/add-gas', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        fillForm: function fillForm(form) {
            form.set('model.fileId', null);
        },

        afterCommand: function afterCommand(response) {
            var ctrl = this;
            var route = this.container.lookup("route:b2b-contract.base-prices");
            route.refresh();
            setTimeout(function () {
                ctrl.transitionToRoute('b2b-contract.dzu.base-prices');
            }, 500);
        }

    });
});
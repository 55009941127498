define('ember-new/models/ppm-vas-view-model', ['exports', 'ember-new/models/abstract', 'ember-data'], function (exports, _emberNewModelsAbstract, _emberData) {

    var attr = _emberData['default'].attr;
    var refMany = _emberData['default'].hasMany;

    var PpmVasViewModel = _emberNewModelsAbstract['default'].extend({
        // basic properties:

        ppmNr: attr('string'),
        ppmVasElements: attr()
        // vasName:attr('string'),
        // idUsys:attr('number'),
        // idAlgotytm:attr('number'),
        // calculations:attr(),
        // calculationSum:attr('number'),
    });
    exports['default'] = PpmVasViewModel;
});

// import BaseDocumentMixin from 'ember-new/mixins/base-document';
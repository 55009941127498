define('ember-new/controllers/b2b-contract/dzu/show-ee/contract', ['exports', 'ember', 'ember-new/mixins/b2b-contract/controllers/dzu-show-contract', 'ember-new/mixins/b2b-contract/controllers/reload-contract-header'], function (exports, _ember, _emberNewMixinsB2bContractControllersDzuShowContract, _emberNewMixinsB2bContractControllersReloadContractHeader) {
	exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsB2bContractControllersDzuShowContract['default'], _emberNewMixinsB2bContractControllersReloadContractHeader['default'], {
		TheUser: _ember['default'].inject.service('theuser:main'),

		products: [],

		afterCommand: function afterCommand() {
			var ctrl = this;
			var route = this.container.lookup('route:b2b-contract.dzu.show-ee.contract');
			route.refresh();
			this.reloadContractHeader();
			setTimeout(function () {
				ctrl.transitionToRoute('b2b-contract.dzu.show-ee.contract');
			}, 500);
		},

		disableForDzuSpecialist: (function () {
			if (this.get("TheUser").isMainRoleIn(['ROLE_DZU_MANAGER', 'ROLE_DZU2_MANAGER', 'ROLE_ADMIN', 'ROLE_DZU2_SPECIALIST'])) {
				return false;
			}

			return true;
		}).property(),

		isDzuUser: (function () {
			return this.get("TheUser").isMainRoleIn(['ROLE_DZU2_SPECIALIST', 'ROLE_DZU2']);
		}).property(),

		isDisabledForDzuSpecialist: (function () {
			return !this.get("TheUser").isMainRoleIn(['ROLE_DZU_MANAGER', 'ROLE_DZU2_MANAGER', 'ROLE_ADMIN']);
		}).property(),

		fillFormInvoices: function fillFormInvoices(form) {
			form.set('model.contractId', this.get('model.contractUuid'));
			form.set('model.paymentTerm', this.get('model.paymentTermUuid'));
		},

		isManualMmVal: (function () {
			if (this.get('isManualMmValue') !== true) {
				this.set('isManualMmValue', false);
			}
			return !this.get('isManualMmValue');
		}).property('isManualMmValue'),

		isAntiSmog: (function () {
			return this.get('model.isAntiSmogPackage');
		}).property('model.isAntiSmogPackage'),

		fillFormBurden: function fillFormBurden(form) {
			form.set('model.contractId', this.get('model.contractUuid'));
			form.set('model.burdenCompletingConsumptionDeclarationsExist', this.get('model.burdenCompletingConsumptionDeclarationsExist'));
			form.set('model.burdenCompletingConsumptionDeclarationsMultiplier', this.get('model.burdenCompletingConsumptionDeclarationsMultiplier'));
			form.set('model.burdenCompletingConsumptionDeclarationsCharged', null);
			form.set('model.burdenFillingAttachmentsExist', this.get('model.burdenFillingAttachmentsExist'));
			form.set('model.burdenFillingAttachmentsMultiplier', this.get('model.burdenFillingAttachmentsMultiplier'));
			form.set('model.burdenFillingAttachmentsCharged', null);
			form.set('model.burdenFillingContractDocumentsExist', this.get('model.burdenFillingContractDocumentsExist'));
			form.set('model.burdenFillingContractDocumentsMultiplier', this.get('model.burdenFillingContractDocumentsMultiplier'));
			form.set('model.burdenFillingContractDocumentsCharged', null);
			form.set('model.burdenConfirmationLoyalistsExist', this.get('model.burdenConfirmationLoyalistsExist'));
			form.set('model.burdenConfirmationLoyalistsMultiplier', this.get('model.burdenConfirmationLoyalistsMultiplier'));
			form.set('model.burdenConfirmationLoyalistsCharged', null);
			form.set('model.burdenObtainingDistributionExist', this.get('model.burdenObtainingDistributionExist'));
			form.set('model.burdenObtainingDistributionMultiplier', this.get('model.burdenObtainingDistributionMultiplier'));
			form.set('model.burdenObtainingDistributionCharged', null);
			form.set('model.burdenFailProvideApprovalsExist', this.get('model.burdenFailProvideApprovalsExist'));
			form.set('model.burdenFailProvideApprovalsMultiplier', this.get('model.burdenFailProvideApprovalsMultiplier'));
			form.set('model.burdenFailProvideApprovalsCharged', null);

			if (this.get('model.statusNumber') < 7) {
				form.set('model.burdenCompletingConsumptionDeclarationsSingleAmount', this.get('burdens.completingConsumptionDeclarationsEE'));
				form.set('model.burdenFillingAttachmentsSingleAmount', this.get('burdens.fillingAttachmentsEE'));
				form.set('model.burdenFillingContractDocumentsSingleAmount', this.get('burdens.fillingContractDocumentsEE'));
				form.set('model.burdenConfirmationLoyalistsSingleAmount', this.get('burdens.confirmationLoyalistsEE'));
				form.set('model.burdenObtainingDistributionSingleAmount', this.get('burdens.obtainingDistributionEE'));
				form.set('model.burdenFailProvideApprovalsSingleAmount', this.get('burdens.failProvideApprovalsEE'));
			} else {
				form.set('model.burdenCompletingConsumptionDeclarationsSingleAmount', this.get('model.burdenCompletingConsumptionDeclarationsSingleAmount'));
				form.set('model.burdenFillingAttachmentsSingleAmount', this.get('model.burdenFillingAttachmentsSingleAmount'));
				form.set('model.burdenFillingContractDocumentsSingleAmount', this.get('model.burdenFillingContractDocumentsSingleAmount'));
				form.set('model.burdenConfirmationLoyalistsSingleAmount', this.get('model.burdenConfirmationLoyalistsSingleAmount'));
				form.set('model.burdenObtainingDistributionSingleAmount', this.get('model.burdenObtainingDistributionSingleAmount'));
				form.set('model.burdenFailProvideApprovalsSingleAmount', this.get('model.burdenFailProvideApprovalsSingleAmount'));
			}
		},

		otherReasonValue: (function () {
			if (this.get('model.otherTrue')) {
				return this.get('model.otherReason');
			} else {
				this.set('model.otherReason', '');
				return '';
			}
		}).property('model.otherTrue'),

		automaticExtensionNoticePeriodContent: [{ label: '6 miesięcy ze skutkiem na koniec miesiąca', value: '6 miesięcy ze skutkiem na koniec miesiąca' }, { label: 'Inne', value: 'Inne' }],

		specialistImplementDepartment: [{ label: 'BOK', value: 'b977bb2f-a174-47ed-8a01-a4ab1e062164' }, { label: "DG", value: "4439ae3e-6109-4ac9-8e51-cc147c3ffee4" }, { label: "TPA", value: "120e1873-177c-4ab9-a6e1-4f25182a2f0f" }, { label: "DzU", value: "8f135291-e227-420d-8951-8fc49ada0923" }],

		compensionCalculatingRateContent: [{ label: 'Ilość mcy * 1/12 zużycia wg cennika umownego/cennika sprzedawcy', value: 'Ilość mcy * 1/12 zużycia wg cennika umownego/cennika sprzedawcy' }, { label: 'Ilość mcy * 1/12 zużycia * 40% ceny wg cennika umownego/cennika sprzedawcy', value: 'Ilość mcy * 1/12 zużycia * 40% ceny wg cennika umownego/cennika sprzedawcy' }, { label: 'Inne', value: 'Inne' }, { label: 'Z góry określona', value: 'Z góry określona' }, { label: 'Ilość mcy * 1/12 zużycia * 150 złotych', value: 'Ilość mcy * 1/12 zużycia * 150 złotych' }],

		compensionCalculatingRateKindContent: [{ label: '%', value: '%' }, { label: "zł/MWh", value: "zł/MWh" }],

		isAutomaticExtensionNoticePeriod: (function () {
			return !(this.get('automaticExtensionNoticePeriod') === 'Inne');
		}).property('automaticExtensionNoticePeriod'),

		isNotCompensionCalculatingRateInne: (function () {
			return !(this.get('compensionCalculatingRate') === 'Inne');
		}).property('compensionCalculatingRate'),

		isNotCompensionCalculatingRateInneOrZgoryOkreslona: (function () {
			return !(this.get('compensionCalculatingRate') === 'Z góry określona' || this.get('compensionCalculatingRate') === 'Inne');
		}).property('compensionCalculatingRate')

	});
});
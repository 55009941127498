define('ember-new/routes/b2b-contract/dzu/show-ee/ppe/details/invoices/edit-invoice', ['exports', 'ember-new/routes/authenticated', 'ember'], function (exports, _emberNewRoutesAuthenticated, _ember) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({

        ppeId: null,
        areasQuantity: null,

        model: function model(params) {
            var targetModel = this.modelFor('b2b-contract.dzu.show-ee.ppe.details');
            this.set('ppeId', targetModel.get('uuid'));
            this.set('areasQuantity', targetModel.get('areasQuantity'));
            this.set('invoiceId', params.invoiceId);

            return this.store.findRecord('BusinessEeInvoiceViewModel', params.invoiceId, { reload: true });
        },

        setupController: function setupController(ctrl, model) {
            ctrl.set('model', model);
            ctrl.set('areasQuantity', this.get('areasQuantity'));
            ctrl.set('invoiceId', this.get('invoiceId'));
            ctrl.set('ppeId', this.get('ppeId'));
        },

        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            }
        }

    });
});
define('ember-new/components/select-box-option', ['exports', 'ember', 'ember-new/templates/components/select-box-option'], function (exports, _ember, _emberNewTemplatesComponentsSelectBoxOption) {
    exports['default'] = _ember['default'].Component.extend({
        layout: _emberNewTemplatesComponentsSelectBoxOption['default'],
        tagName: 'li',
        classNameBindings: [':select-box__option', 'isDisabled:select-box__option--disabled', 'isSelected:select-box__option--selected'],
        attributeBindings: ['tabindex'],

        option: null,
        optionLabelKey: 'title',
        optionDisabledKey: null,
        selected: null,
        searchText: '',

        isDisabled: _ember['default'].computed('option', 'optionDisabledKey', function () {
            if (this.get('optionDisabledKey')) {
                return _ember['default'].get(this.get('option'), this.get('optionDisabledKey'));
            }
        }),
        isSelected: _ember['default'].computed('option', 'selected', 'selected.[]', function () {
            if (Array.isArray(this.get('selected'))) {
                return _ember['default'].A(this.get('selected')).contains(this.get('option'));
            } else {
                return this.get('selected') === this.get('option');
            }
        }),
        tabindex: _ember['default'].computed('isDisabled', function () {
            if (!this.get('isDisabled')) {
                return -1;
            }
        }),

        keyDown: function keyDown(event) {
            // enter key selects item
            if (event.keyCode === 13) {
                this.send('selectItem');
            }
        },

        click: function click() {
            this.send('selectItem');
        },

        actions: {
            selectItem: function selectItem() {
                this.attrs['select-item'](this.get('option'));
            }
        }
    });
});
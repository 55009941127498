define('ember-new/routes/admin/dictionaries', ['exports', 'ember-new/routes/authenticated', 'ember'], function (exports, _emberNewRoutesAuthenticated, _ember) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({

        dictionariesManager: _ember['default'].inject.service('dictionaries-manager'),

        beforeModel: function beforeModel() {
            window.scrollTo(0, 0); // scroll to the top of application before reloading
        },

        model: function model(params) {
            this.store.unloadAll('DictionaryListItemViewModel');

            return this.store.query('DictionaryListItemViewModel', params);
        },

        setupController: function setupController(ctrl, model) {
            ctrl.set('model', model);

            var types = this.get('dictionariesManager').getDictionariesTypesListToFilter();

            ctrl.set('typesList', types);
            ctrl.send('hideLoading');
        },

        actions: {
            queryParamsDidChange: function queryParamsDidChange() {
                this.refresh();
            },
            loading: function loading(transition, originRoute) {
                var controller = this.controllerFor('admin.dictionaries');
                controller.send('showLoading');
            }
        }
    });
});
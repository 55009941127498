define('ember-new/routes/meeting-verification/ordering/mark-as-wrong-data', ['exports', 'ember-new/routes/authenticated'], function (exports, _emberNewRoutesAuthenticated) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({

        model: function model(params) {
            var task = this.store.findRecord('TaskListItemViewModel', params.taskId);
            return task;
        },

        setupController: function setupController(controller, model) {
            controller.set('taskUuid', model.get('uuid'));
        }

    });
});
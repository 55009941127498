define('ember-new/routes/b2b-contract/dzu/show-ee/ppe', ['exports', 'ember-new/routes/authenticated'], function (exports, _emberNewRoutesAuthenticated) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({

        model: function model(params) {
            var contract = this.modelFor('b2b-contract.dzu.show-ee');

            return this.store.query('BusinessContractPpmsListDzuViewModel', { contract_id: contract.get('id') });
        },

        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            }
        }

    });
});
define('ember-new/components/acq-favourite', ['exports', 'ember-new/config/environment', 'ember'], function (exports, _emberNewConfigEnvironment, _ember) {
	exports['default'] = _ember['default'].Component.extend({

		ajax: _ember['default'].inject.service('ajax'),
		applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),
		favouriteSalesmanSigningExpectedTime: null,
		favouriteSalesmanComment: null,
		isSubmitting: false,

		fillForm: function fillForm(form) {
			form.set('model.favouriteSalesmanSigningExpectedTime', this.get('favouriteSalesmanSigningExpectedTime'));
			form.set('model.favouriteSalesmanComment', this.get('favouriteSalesmanComment'));
		},

		actions: {
			submit: function submit() {
				this.set('isSubmitting', true);
				var task = this.get('task');
				var self = this;
				var controller = this.get('targetObject');
				var tasksRoute = controller.container.lookup("route:tasks");
				var taskType = task.get('acquisitionProcess.acqType').toLowerCase();

				var data = {
					acquisitionProcess: {
						favouriteSalesmanSigningExpectedTime: this.get('favouriteSalesmanSigningExpectedTime'),
						favouriteSalesmanComment: this.get('favouriteSalesmanComment')
					}
				};

				var promise = this.get('ajax').ajax(_emberNewConfigEnvironment['default'].apiUrl + '/rest/' + taskType + 'acquisitionprocesses/' + task.get('acquisitionProcess.id') + '/favourite', data, 'PATCH');

				promise.then(function (response) {

					tasksRoute.send('hideStartLoading', task.get('id'));

					self.set('isSubmitting', false);
					self.get('applicationAlerts').addActionMessage('success', "Dodano gwiazdkę");
					tasksRoute.send('refreshModel');
					setTimeout(function () {
						controller.transitionToRoute('tasks');
					}, 1000);
				}, function (response) {
					self.set('isSubmitting', false);
					self.get('applicationAlerts').addActionMessage('warning', "Wystąpił błąd, nie udało się dodać gwiazdki");
				});
			},

			closeModal: function closeModal() {
				var task = this.get('task');
				var controller = this.get('targetObject');
				var tasksRoute = controller.container.lookup("route:tasks");
				tasksRoute.send('hideStartLoading', task.get('id'));
				controller.transitionToRoute('tasks');
			}
		}
	});
});
define("ember-new/controllers/tasks/filters", ["exports", "ember", "ember-new/mixins/paginable-controller"], function (exports, _ember, _emberNewMixinsPaginableController) {
	exports["default"] = _ember["default"].Controller.extend({

		/**
   * Obiekt z filtrami przekazywany pozniej do queryParams.
   * Ustawione w oddzielnym obiekcie zeby potem przeleciec foreach/map,
   * a nie wypisywac recznie kazdy filtr.
   */
		filters: {
			coaching: null,
			company: null,
			contact: null,
			dateFrom: null,
			dateTo: null,
			favourite: null,
			gasTariffGroup: null,
			hotDeal: null,
			margin: null,
			reservation: null,
			reservationTime: null,
			salesman: null,
			source: null,
			status: null,
			tariffGroup: null,
			taskStatus: null,
			taskTarget: null,
			verificationMeeting: null,
			taskType: null
		},

		init: function init() {
			this._super();
			_ember["default"].run.schedule("afterRender", this, function () {
				this.send("setupQueryParamsToForm");
			});
		},

		staticSelectsContent: {
			favourite: [{ label: 'Doradca', value: 'salesman' }, { label: 'Manager', value: 'manager' }, { label: 'Manager i Doradca', value: 'both' }],
			hotDeal: [{ label: 'Tak', value: 'only_hotdeal' }, { label: 'Nie', value: 'only_not_hotdeal' }],
			taskTarget: [{ label: 'wszystkie', value: null }, { label: 'Gaz', value: 'gas' }, { label: 'EE', value: 'ee' }],
			source: [{ label: 'wszystkie', value: null }, { label: 'CC', value: '0cc93022-c065-418e-acbd-93b982892de2' }, { label: 'własne', value: '4980a689-b239-433e-b739-f96fd5f0b445' }],
			margin: [{ label: 'wszystkie', value: null }, { label: '100k', value: '100000' }, { label: '20k', value: '20000' }, { label: '10k', value: '10000' }, { label: '2k', value: '2000' }],
			taskStatus: [{ label: 'wszystkie', value: null }, { label: 'nierozliczone', value: 'only_not_resolved' }, { label: 'rozliczone', value: 'only_resolved' }, { label: 'rezygnacja', value: 'only_dismissed' }],
			reservationTime: [{ label: 'wszystkie', value: null }, { label: 'dziś', value: '1d' }, { label: 'jutro', value: '2d' }, { label: 'pojutrze', value: '3d' }, { label: '4-7 dni', value: '4d-7d' }, { label: 'ponad tydzień', value: '1w-2w' }, { label: 'ponad 2 tyg.', value: '>2w' }]
		},

		actions: {
			applyFilters: function applyFilters() {
				this.set('filters.page', 1); // reset page
				this.transitionToRoute('tasks', { queryParams: this.get('filters') });
			},
			resetFilters: function resetFilters() {
				var _this = this;

				var filters = this.get('filters');
				Object.keys(filters).forEach(function (key) {
					_this.set("filters." + key, null);
				});
			},

			/**
    * Ustawianie wartosci filtrow na podstawie queryParams z URL.
    * qParams - ustawiany w Route.
    */
			setupQueryParamsToForm: function setupQueryParamsToForm() {
				var _this2 = this;

				var qp = this.get('qParams');
				Object.keys(qp).forEach(function (param) {
					// sprawdzamy czy dany param istnieje w naszym obiekcie 'filters'
					// aby uniknac nadpisania innych queryParams (np. order, orderDirection etc..)
					// ktorych nie ustawiamy w filtrach ~lyczos
					// console.log(`filters.${param} hasOwnProp`, this.get('filters').hasOwnProperty(param));
					if (_this2.get('filters').hasOwnProperty(param)) {
						_this2.set("filters." + param, qp[param]);
					}
				});
			}
		}
	});
});
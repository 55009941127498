define('ember-new/mixins/b2b-contract/controllers/section-products/add-product-to-contract', ['exports', 'ember', 'ember-new/templates/components/d-form'], function (exports, _ember, _emberNewTemplatesComponentsDForm) {
    exports['default'] = _ember['default'].Mixin.create({
        layout: _emberNewTemplatesComponentsDForm['default'],

        filteredProducts: null,
        everyIndexes: false,
        isDefaultOff: true,

        mediumType: [{ label: 'EE', value: 'ee' }, { label: 'GAZ', value: 'gas' }],

        initializeGasProducts: function initializeGasProducts(gasProducts) {

            gasProducts = gasProducts.filter(function (item) {
                return item.get('isHidden') !== true;
            });

            this.set('gasProducts', gasProducts);
        },

        initializeEeProducts: function initializeEeProducts(eeProducts) {

            eeProducts = eeProducts.filter(function (item) {
                return item.get('isHidden') !== true;
            });

            this.set('eeProducts', eeProducts);
        },

        initializeEeIsIndex: function initializeEeIsIndex(eeProducts) {

            eeProducts = eeProducts.filter(function (item) {
                return item.get('isHidden') !== true && item.get('isIndex') !== true;
            });

            this.set('filteredEeProducts', eeProducts);
        },

        initializeGasIsIndex: function initializeGasIsIndex(gasProducts) {

            gasProducts = gasProducts.filter(function (item) {
                return item.get('isHidden') !== true && item.get('isIndex') !== true;
            });

            this.set('filteredGasProducts', gasProducts);
        }
    });
});
define('ember-new/components/d-form/b2b-contract/contract/burdens', ['exports', 'ember', 'ember-new/components/d-form-command', 'ember-new/templates/components/d-form', 'ember-new/config/environment'], function (exports, _ember, _emberNewComponentsDFormCommand, _emberNewTemplatesComponentsDForm, _emberNewConfigEnvironment) {
	exports['default'] = _emberNewComponentsDFormCommand['default'].extend({
		layout: _emberNewTemplatesComponentsDForm['default'],

		burdenCompletingConsumptionDeclarationsMultiplierObserver: (function () {
			var value = this.get('formData.burdenCompletingConsumptionDeclarationsSingleAmount') * this.get('formData.burdenCompletingConsumptionDeclarationsMultiplier');
			if (this.get('formData')) {
				this.set('formData.burdenCompletingConsumptionDeclarationsCharged', value);
			}
		}).observes('currentFormData.burdenCompletingConsumptionDeclarationsMultiplier'),

		burdenCompletingConsumptionDeclarationsExistObserver: (function () {
			if (this.get('formData')) {
				if (this.get('currentFormData.burdenCompletingConsumptionDeclarationsExist') === true) {
					this.set('formData.burdenCompletingConsumptionDeclarationsMultiplier', 1);
				} else {
					this.set('formData.burdenCompletingConsumptionDeclarationsMultiplier', null);
					this.set('formData.burdenCompletingConsumptionDeclarationsCharged', null);
				}
			}
		}).observes('formData.burdenCompletingConsumptionDeclarationsExist'),

		burdenFillingAttachmentsSingleMultiplierObserver: (function () {
			var value = this.get('formData.burdenFillingAttachmentsSingleAmount') * this.get('formData.burdenFillingAttachmentsMultiplier');
			if (this.get('formData')) {
				this.set('formData.burdenFillingAttachmentsCharged', value);
			}
		}).observes('currentFormData.burdenFillingAttachmentsMultiplier'),

		burdenFillingAttachmentsExistObserver: (function () {
			if (this.get('formData')) {
				if (this.get('currentFormData.burdenFillingAttachmentsExist') === true) {
					this.set('formData.burdenFillingAttachmentsMultiplier', null);
				} else {
					this.set('formData.burdenFillingAttachmentsMultiplier', null);
					this.set('formData.burdenFillingAttachmentsCharged', null);
				}
			}
		}).observes('formData.burdenFillingAttachmentsExist'),

		burdenFillingContractDocumentsMultiplierObserver: (function () {
			var value = this.get('formData.burdenFillingContractDocumentsSingleAmount') * this.get('formData.burdenFillingContractDocumentsMultiplier');
			if (this.get('formData')) {
				this.set('formData.burdenFillingContractDocumentsCharged', value);
			}
		}).observes('currentFormData.burdenFillingContractDocumentsMultiplier'),

		burdenFillingContractDocumentsExistObserver: (function () {
			if (this.get('formData')) {
				if (this.get('currentFormData.burdenFillingContractDocumentsExist') === true) {
					this.set('formData.burdenFillingContractDocumentsMultiplier', 1);
				} else {
					this.set('formData.burdenFillingContractDocumentsMultiplier', null);
					this.set('formData.burdenFillingContractDocumentsCharged', null);
				}
			}
		}).observes('formData.burdenFillingContractDocumentsExist'),

		burdenConfirmationLoyalistsMultiplierObserver: (function () {
			var value = this.get('formData.burdenConfirmationLoyalistsSingleAmount') * this.get('formData.burdenConfirmationLoyalistsMultiplier');
			if (this.get('formData')) {
				this.set('formData.burdenConfirmationLoyalistsCharged', value);
			}
		}).observes('currentFormData.burdenConfirmationLoyalistsMultiplier'),

		burdenConfirmationLoyalistsExistObserver: (function () {
			if (this.get('formData')) {
				if (this.get('currentFormData.burdenConfirmationLoyalistsExist') === true) {
					this.set('formData.burdenConfirmationLoyalistsMultiplier', 1);
				} else {
					this.set('formData.burdenConfirmationLoyalistsMultiplier', null);
					this.set('formData.burdenConfirmationLoyalistsCharged', null);
				}
			}
		}).observes('formData.burdenConfirmationLoyalistsExist'),

		burdenObtainingDistributionMultiplierObserver: (function () {
			var value = this.get('formData.burdenObtainingDistributionSingleAmount') * this.get('formData.burdenObtainingDistributionMultiplier');
			if (this.get('formData')) {
				this.set('formData.burdenObtainingDistributionCharged', value);
			}
		}).observes('currentFormData.burdenObtainingDistributionMultiplier'),

		burdenObtainingDistributionExistObserver: (function () {
			if (this.get('formData')) {
				if (this.get('currentFormData.burdenObtainingDistributionExist') === true) {
					this.set('formData.burdenObtainingDistributionMultiplier', null);
				} else {
					this.set('formData.burdenObtainingDistributionMultiplier', null);
					this.set('formData.burdenObtainingDistributionCharged', null);
				}
			}
		}).observes('formData.burdenObtainingDistributionExist'),

		burdenFailProvideApprovalsMultiplierObserver: (function () {
			var value = this.get('formData.burdenFailProvideApprovalsSingleAmount') * this.get('formData.burdenFailProvideApprovalsMultiplier');
			if (this.get('formData')) {
				this.set('formData.burdenFailProvideApprovalsCharged', value);
			}
		}).observes('currentFormData.burdenFailProvideApprovalsMultiplier'),

		burdenFailProvideApprovalsExistObserver: (function () {
			if (this.get('formData')) {
				if (this.get('currentFormData.burdenFailProvideApprovalsExist') === true) {
					this.set('formData.burdenFailProvideApprovalsMultiplier', 1);
				} else {
					this.set('formData.burdenFailProvideApprovalsMultiplier', null);
					this.set('formData.burdenFailProvideApprovalsCharged', null);
				}
			}
		}).observes('formData.burdenFailProvideApprovalsExist')

	});
});
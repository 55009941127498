define('ember-new/models/add-price-condition-view-model', ['exports', 'ember-new/models/abstract', 'ember-data'], function (exports, _emberNewModelsAbstract, _emberData) {

    var attr = _emberData['default'].attr;
    var refOne = _emberData['default'].belongsTo;

    exports['default'] = _emberNewModelsAbstract['default'].extend({
        productLocked: _emberData['default'].attr('boolean'),
        basePricesLocked: _emberData['default'].attr('boolean'),
        productId: _emberData['default'].attr('string'),
        basePriceId: _emberData['default'].attr('string'),
        priceSetUuid: _emberData['default'].attr('string'),
        defaultConditionProduct: _emberData['default'].attr('string')
    });
});
define("ember-new/helpers/audio", ["exports", "ember", "ember-new/config/environment", "ember-new/helpers/select-box-get"], function (exports, _ember, _emberNewConfigEnvironment, _emberNewHelpersSelectBoxGet) {
    exports.audio = audio;

    function audio(href, session) {

        var parsedValue = '<audio controls>' + '<source src="' + _emberNewConfigEnvironment["default"].apiUrlIndex + '_getfile/' + href[0] + '?&bearer=' + href[1] + '" type="audio/mpeg">' + 'Your browser does not support the audio element.' + '</audio>';

        return new _ember["default"].Handlebars.SafeString(parsedValue);
    }

    exports["default"] = _ember["default"].Helper.helper(audio);
});
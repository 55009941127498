define("ember-new/routes/meeting-verification/report", ["exports", "ember-new/routes/authenticated", "ember"], function (exports, _emberNewRoutesAuthenticated, _ember) {
    exports["default"] = _emberNewRoutesAuthenticated["default"].extend({
        // model: function () {
        //     return
        // },

        model: function model() {
            return _ember["default"].RSVP.hash({
                'saleMonths': this.store.findAll('BusinessMonthListItemViewModel')
            });
        },

        setupController: function setupController(controller, model) {
            this._super(controller, model.list);
            controller.set('saleMonths', model.saleMonths);
        },

        actions: {
            refreshRoute: function refreshRoute() {
                this.refresh();
            }
        }
    });
});
define("ember-new/mixins/b2b-contract/components/d-form/tariffs/osd-tariff-contractual-power", ["exports", "ember", "ember-new/templates/components/d-form"], function (exports, _ember, _emberNewTemplatesComponentsDForm) {
    exports["default"] = _ember["default"].Mixin.create({
        layout: _emberNewTemplatesComponentsDForm["default"],

        configure: function configure() {
            var _this = this;

            this._super();

            var osdTariffs = ["c3e938c1-4c83-4f96-80e7-b15608fedd6c", "57c07d55-1b6e-4b7a-8715-9e36e2515da5", "b6cd29cc-6275-4459-b2ad-673a04aeb373", "b11a0073-23d1-4b15-97a5-ee4bc7f2fdd7", "5b7b5f20-b330-4097-894a-7ee4d452223a", "8017cd14-ce69-4f67-8e44-61d25cdb5976", "dffa9569-62d7-4e25-a608-ddfda9b56bef", "092c84c1-109f-4c0a-ab3e-0ca9962d7858", "1727b666-3eb2-4c6f-87a6-50a340ed7784", "771997c3-2be5-4b13-9f92-646f825128f6"];

            this.addObserver('formData.osdTariffGroup', function () {
                if (osdTariffs.includes(_this.getValue('osdTariffGroup'))) {
                    _this.set('formData.contractualPower', '110');
                } else {
                    _this.set('formData.contractualPower', null);
                }
            });
        }
    });
});
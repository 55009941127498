define("ember-new/models/individual-df-connection", ["exports", "ember", "ember-data", "ember-new/models/abstract", "ember-new/models/base-statusable-document-mixin", "ember-new/mixins/models/individual-ee-gas-contract"], function (exports, _ember, _emberData, _emberNewModelsAbstract, _emberNewModelsBaseStatusableDocumentMixin, _emberNewMixinsModelsIndividualEeGasContract) {

    var attr = _emberData["default"].attr;
    var refOne = _emberData["default"].belongsTo;

    exports["default"] = _emberNewModelsAbstract["default"].extend(_emberNewModelsBaseStatusableDocumentMixin["default"], {

        eeContract: refOne('individual-ee-contract', { inverse: null }),
        gasContract: refOne('individual-gas-contract', { inverse: null })

    });
});
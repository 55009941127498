define('ember-new/models/individual-contract-vaps-list-view-model', ['exports', 'ember-data'], function (exports, _emberData) {

    var attr = _emberData['default'].attr;

    exports['default'] = _emberData['default'].Model.extend({
        uuid: attr('string'),
        name: attr('string'),
        type: attr('string'),
        typeString: attr('string'),
        price: attr('number'),
        idUsys: attr('number'),
        isRequired: attr('boolean'),
        validityPeriod: attr('string'),
        numberOfMonths: attr('number'),
        validityDateStart: attr('string'),
        validityDateEnd: attr('string'),
        assignedToContract: attr('boolean'),

        isAssigned: (function () {
            return this.get('assignedToContract') === true;
        }).property('assignedToContract')
    });
});
define('ember-new/controllers/b2b-contract/dzu/show-ee/ppe/details/index', ['exports', 'ember', 'ember-new/mixins/b2b-contract/controllers/reload-contract-header'], function (exports, _ember, _emberNewMixinsB2bContractControllersReloadContractHeader) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsB2bContractControllersReloadContractHeader['default'], {
        TheUser: _ember['default'].inject.service('theuser:main'),

        isDzuUser: (function () {
            return this.get("TheUser").isMainRoleIn(['ROLE_DZU2_SPECIALIST', 'ROLE_DZU2']);
        }).property(),

        disableForDzuSpecialist: (function () {
            return !this.get("TheUser").isMainRoleIn(['ROLE_DZU_MANAGER', 'ROLE_DZU2_MANAGER', 'ROLE_DZU2_SPECIALIST', 'ROLE_DZU2', 'ROLE_ADMIN']);
        }).property(),

        isManualMmValueDisabled: (function () {
            return this.get('disableForDzuSpecialist') || this.get('model.isManualMmByContract');
        }).property(),

        osdNull: true,
        disableSellerChange: true,
        disableContractTerminating: true,
        disableOs: true,
        disableFlattening: true,

        fillFormContactAddress: function fillFormContactAddress(form) {
            form.set('model.ppeId', this.get('model.uuid'));
            form.set('model.street', this.get('model.streetName'));
            form.set('model.streetNo', this.get('model.streetNumber'));
            form.set('model.aptNo', this.get('model.apartmentNumber'));
            form.set('model.postalCode', this.get('model.postalCode'));
            form.set('model.postalCity', this.get('model.postalCity'));
            form.set('model.city', this.get('model.city'));
            form.set('model.district', this.get('model.districtUuid'));
        },

        fillFormPpeData: function fillFormPpeData(form) {
            form.set('model.ppeId', this.get('model.uuid'));
            form.set('model.pointNumber', this.get('model.pointNumber'));
            form.set('model.deviceNumber', this.get('model.deviceNumber'));
            form.set('model.registrationNumber', this.get('model.registrationNumber'));
            form.set('model.pointNumberForWinback', this.get('model.pointNumberForWinback'));
            form.set('model.osd', this.get('model.osdUuid'));
            form.set('model.osdSection', this.get('model.osdSectionUuid'));
            form.set('model.billingFrequency', this.get('model.billingFrequencyUuid'));
            //zmiana sprzedawcy:
            form.set('model.os', this.get('model.osUuid'));
            form.set('model.sellerChange', this.get('model.sellerChange'));
            form.set('model.reserveAgreement', this.get('model.reserveAgreement'));
            form.set('model.isContractTerminating', this.get('model.isContractTerminating'));
            form.set('model.isNewConnection', this.get('model.isNewConnection'));
        },

        fillFormPpeSalesData: function fillFormPpeSalesData(form) {
            form.set('model.ppeId', this.get('model.uuid'));
            form.set('model.tariffGroup', this.get('model.tariffGroup'));
            form.set('model.flattenTariffGroup', this.get('model.flattenTariffGroup'));
            form.set('model.deliveredComprehensiveAgreement', this.get('model.deliveredComprehensiveAgreement'));
            form.set('model.correctionRatio', this.get('model.correctionRatioUuid'));
            form.set('model.loyalty', this.get('model.loyaltyUuid'));
            form.set('model.loyaltyEnd', this.get('model.loyaltyEnd'));
            form.set('model.dismissPeriod', this.get('model.dismissPeriod'));
            form.set('model.noticePeriodResult', this.get('model.noticePeriodResultUuid'));
            form.set('model.consumptionCalculatingMethod', this.get('model.consumptionCalculationMethodUuid'));
            form.set('model.tariffGroupChange', this.get('model.tariffGroupChange'));
            form.set('model.isDataUpdated', this.get('model.isDataUpdated'));
            form.set('model.customerCession', this.get('model.customerCession'));
            form.set('model.startDate', this.get('model.startDate'));
            form.set('model.isManualMm', this.get('model.isManualMm'));
            form.set('model.manualMm', this.get('model.manualMm'));
            form.set('model.endDate', this.get('model.endDate'));
            form.set('model.monthsNo', this.get('model.numberOfMonths'));
            form.set('model.contractMonths', this.get('model.contractMonths'));
            form.set('model.declaredYearConsumption', this.get('model.declaredYearConsumption'));
        },

        afterCommand: function afterCommand() {
            var ctrl = this;
            var route = this.container.lookup('route:b2b-contract.dzu.show-ee.ppe.details');
            route.refresh();
            this.reloadContractHeader();
            setTimeout(function () {
                ctrl.transitionToRoute('b2b-contract.dzu.show-ee.ppe.details');
            }, 500);
        },

        yesOrNo: [{ label: 'Tak', value: true }, { label: 'Nie', value: false }],

        loyaltyStatusDate: (function () {
            if (this.get('loyaltyStatus') != '34f0d211-f3f0-4783-8506-bcaade547dbc') {
                return true;
            }

            return false;
        }).property('loyaltyStatus'),

        isManualMmVal: (function () {
            if (this.get('isManualMmValue') !== true) {
                this.set('isManualMmValue', false);
            }
            return !this.get('isManualMmValue');
        }).property('isManualMmValue'),

        loyaltyContent: [{ label: 'Lojalka - data znana', value: '34f0d211-f3f0-4783-8506-bcaade547dbc' }, { label: 'Brak lojalki', value: 'a45aebb5-9c31-4c41-b130-c91bbaccb6b4' }]

    });
});
define('ember-new/helpers/substring', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Helper.helper(function (value, options) {
        var limit = options.limit;

        if (value !== undefined && value !== null) {
            var originalValue = value;
            value = value.toString().substring(0, limit);

            if (value.length < originalValue.toString().length) {

                return value + '...';
            }
            return value;
        }

        return null;
    });
});
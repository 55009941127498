define('ember-new/routes/b2b-contract/dzu/show-gas/remove-recording', ['exports', 'ember-new/routes/authenticated'], function (exports, _emberNewRoutesAuthenticated) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({

        model: function model(params) {
            var contract = this.modelFor('b2b-contract.dzu.show-gas');
            this.set('idContract', contract.get('id'));

            var recording = params.recordingUuid;

            return recording;
        },

        setupController: function setupController(controller, model) {
            controller.set("recording", model);
            controller.set('idContract', this.get('idContract'));
        }
    });
});
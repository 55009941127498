define("ember-new/routes/individual-contract/show-gas/entity-change", ["exports", "ember-new/routes/authenticated", "ember"], function (exports, _emberNewRoutesAuthenticated, _ember) {
    exports["default"] = _emberNewRoutesAuthenticated["default"].extend({

        model: function model(params) {
            var contract = this.modelFor('individual-contract.show-gas');
            Object.keys(params).map(function (key) {
                if (params[key] == 'null' || params[key] == null) {
                    delete params[key];
                }
            });
            params['contractUuid'] = contract.get('uuid');

            return _ember["default"].RSVP.hash({
                history: this.get('store').query('contract-entity-change-view-model', params),
                authors: this.get('store').query('entity-change-authors-view-model', { uuid: contract.get('uuid') }, { reload: true })
            });
        },

        setupController: function setupController(ctrl, model) {
            ctrl.set('authors', model.authors);
            ctrl.set('model', model.history);
        }
    });
});
define('ember-new/controllers/individual-contract-price-lists/template/add', ['exports', 'ember', 'ember-new/mixins/individual-contract-price-lists/controllers/template'], function (exports, _ember, _emberNewMixinsIndividualContractPriceListsControllersTemplate) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsIndividualContractPriceListsControllersTemplate['default'], {

        fillCodeValue: null,
        isButtonDisabled: true,

        fillForm: function fillForm(form) {
            form.set('model.usysId', null);
            form.set('model.name', null);
            form.set('model.code', null);
            form.set('model.productUuid', null);
            form.set('model.type', null);
            form.set('model.eeTariffGroupUuid', null);
            form.set('model.gasTariffGroupUuid', null);
            form.set('model.contractEndType', null);
            form.set('model.endMonths', '');
            form.set('model.endDate', '');
            form.set('model.promoPriceType', null);
            form.set('model.discountPriceMonths', null);
            form.set('model.discountPriceDate', null);
            form.set('model.promoFeeType', null);
            form.set('model.discountFeeMonths', null);
            form.set('model.discountFeeDate', null);
        }

    });
});
define('ember-new/controllers/individual-contract-price-lists/product/delete', ['exports', 'ember', 'ember-new/config/environment'], function (exports, _ember, _emberNewConfigEnvironment) {
    exports['default'] = _ember['default'].Controller.extend({
        ajax: _ember['default'].inject.service('ajax'),
        applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),

        actions: {
            'delete': function _delete() {
                var _this = this;

                var serializedData = {
                    DeleteIndividualProductCommand: {
                        productUuid: this.get('productUuid')
                    }
                };

                this.get('ajax').ajaxPost(_emberNewConfigEnvironment['default'].apiUrl + '/rest/deleteindividualproductcommands', serializedData).then(function () {
                    var route = _this.container.lookup('route:individual-contract-price-lists.product');
                    route.refresh();
                    _this.get('applicationAlerts').addActionMessage('success', "Produkt został usunięty");
                    _this.transitionToRoute('individual-contract-price-lists.product');
                }, function () {
                    _this.get('applicationAlerts').addActionMessage('warning', "Wystąpił błąd, nie udało się usunąć produktu.  Sprawdź czy nie jest przypisany template i najpierw go usuń.");
                });
            }
        }

    });
});
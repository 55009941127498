define("ember-new/templates/b2b-contract/dzu/show-gas/prices", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 12
            },
            "end": {
              "line": 6,
              "column": 31
            }
          },
          "moduleName": "ember-new/templates/b2b-contract/dzu/show-gas/prices.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("i");
          dom.setAttribute(el1, "class", "fa fa-plus");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" Dodaj\n                nowy zestaw cen");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 15,
                "column": 29
              },
              "end": {
                "line": 17,
                "column": 24
              }
            },
            "moduleName": "ember-new/templates/b2b-contract/dzu/show-gas/prices.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "label bg-red");
            var el2 = dom.createTextNode("Brak przypisanych PPG");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 24
              },
              "end": {
                "line": 21,
                "column": 49
              }
            },
            "moduleName": "ember-new/templates/b2b-contract/dzu/show-gas/prices.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "fa fa-plus");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" Dodaj nowy\n                            warunek rozliczeniowy");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 22,
                "column": 24
              },
              "end": {
                "line": 23,
                "column": 34
              }
            },
            "moduleName": "ember-new/templates/b2b-contract/dzu/show-gas/prices.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "fa fa-remove");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" Usuń\n                            zestaw");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 49,
                    "column": 40
                  },
                  "end": {
                    "line": 49,
                    "column": 182
                  }
                },
                "moduleName": "ember-new/templates/b2b-contract/dzu/show-gas/prices.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("i");
                dom.setAttribute(el1, "class", "fa fa-edit");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" Edytuj ceny");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 50,
                    "column": 38
                  },
                  "end": {
                    "line": 50,
                    "column": 187
                  }
                },
                "moduleName": "ember-new/templates/b2b-contract/dzu/show-gas/prices.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("i");
                dom.setAttribute(el1, "class", "fa fa-edit");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" Edytuj warunki");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child2 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 52,
                    "column": 38
                  },
                  "end": {
                    "line": 52,
                    "column": 176
                  }
                },
                "moduleName": "ember-new/templates/b2b-contract/dzu/show-gas/prices.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("i");
                dom.setAttribute(el1, "class", "fa fa-remove");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" Usuń");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 40,
                  "column": 28
                },
                "end": {
                  "line": 56,
                  "column": 28
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/dzu/show-gas/prices.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("tr");
              var el2 = dom.createTextNode("\n                                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n                                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n                                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n                                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "class", "text-center");
              var el3 = dom.createTextNode("\n                                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n\n                                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment(" <p>{{periodValue.value_type_}}</p> ");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var element1 = dom.childAt(element0, [7]);
              var morphs = new Array(6);
              morphs[0] = dom.createMorphAt(element0, 1, 1);
              morphs[1] = dom.createMorphAt(element0, 3, 3);
              morphs[2] = dom.createMorphAt(element0, 5, 5);
              morphs[3] = dom.createMorphAt(element1, 1, 1);
              morphs[4] = dom.createMorphAt(element1, 3, 3);
              morphs[5] = dom.createMorphAt(element1, 5, 5);
              return morphs;
            },
            statements: [["inline", "partial", [["subexpr", "concat", ["b2b-contract/dzu/partials/prices/period/", ["get", "periodValue.period_type_", ["loc", [null, [42, 97], [42, 121]]]]], [], ["loc", [null, [42, 46], [42, 122]]]]], [], ["loc", [null, [42, 36], [42, 124]]]], ["inline", "partial", [["subexpr", "concat", ["b2b-contract/dzu/partials/prices/value/gas/", ["get", "periodValue.value_type_", ["loc", [null, [44, 100], [44, 123]]]]], [], ["loc", [null, [44, 46], [44, 124]]]]], [], ["loc", [null, [44, 36], [44, 126]]]], ["inline", "partial", [["subexpr", "concat", ["b2b-contract/dzu/partials/prices/value/gas/params"], [], ["loc", [null, [46, 46], [46, 106]]]]], [], ["loc", [null, [46, 36], [46, 108]]]], ["block", "link-to", ["b2b-contract.dzu.show-gas.prices.edit-price", ["get", "periodValue.id", ["loc", [null, [49, 97], [49, 111]]]]], ["class", "btn btn-primary btn-xs"], 0, null, ["loc", [null, [49, 40], [49, 194]]]], ["block", "link-to", ["b2b-contract.dzu.show-gas.prices.edit-condition", ["get", "periodValue.id", ["loc", [null, [50, 99], [50, 113]]]]], ["class", "btn btn-primary btn-xs"], 1, null, ["loc", [null, [50, 38], [50, 199]]]], ["block", "link-to", ["b2b-contract.dzu.show-gas.prices.remove-price", ["get", "periodValue.id", ["loc", [null, [52, 97], [52, 111]]]]], ["class", "btn btn-danger btn-xs"], 2, null, ["loc", [null, [52, 38], [52, 188]]]]],
            locals: ["periodValue"],
            templates: [child0, child1, child2]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 28,
                "column": 20
              },
              "end": {
                "line": 59,
                "column": 20
              }
            },
            "moduleName": "ember-new/templates/b2b-contract/dzu/show-gas/prices.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("table");
            dom.setAttribute(el1, "class", "table table-bordered table-striped dataTable prices-table");
            dom.setAttribute(el1, "role", "grid");
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("thead");
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("tr");
            dom.setAttribute(el3, "role", "row");
            var el4 = dom.createTextNode("\n                                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("th");
            dom.setAttribute(el4, "class", "text-center");
            var el5 = dom.createTextNode("Zakres");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("th");
            dom.setAttribute(el4, "colspan", "3");
            dom.setAttribute(el4, "class", "text-center");
            var el5 = dom.createTextNode("Wartość");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("th");
            dom.setAttribute(el4, "class", "text-center");
            var el5 = dom.createTextNode("Parametry");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("th");
            dom.setAttribute(el4, "class", "text-center");
            var el5 = dom.createTextNode("Akcje");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n                            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("tbody");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]), 1, 1);
            return morphs;
          },
          statements: [["block", "each", [["get", "item.periodValues", ["loc", [null, [40, 36], [40, 53]]]]], [], 0, null, ["loc", [null, [40, 28], [56, 37]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child4 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 59,
                "column": 20
              },
              "end": {
                "line": 61,
                "column": 20
              }
            },
            "moduleName": "ember-new/templates/b2b-contract/dzu/show-gas/prices.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "callout callout-danger");
            var el2 = dom.createElement("p");
            var el3 = dom.createTextNode("Brak cen przypisanych do tego zestawu");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 8
            },
            "end": {
              "line": 64,
              "column": 8
            }
          },
          "moduleName": "ember-new/templates/b2b-contract/dzu/show-gas/prices.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "box box-primary");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "box-header");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h3");
          dom.setAttribute(el3, "class", "box-title");
          var el4 = dom.createTextNode("\n                        Taryfa: ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("br");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "box-tools");
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "box-body");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var element3 = dom.childAt(element2, [1]);
          var element4 = dom.childAt(element3, [1]);
          var element5 = dom.childAt(element3, [3]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(element4, 1, 1);
          morphs[1] = dom.createMorphAt(element4, 4, 4);
          morphs[2] = dom.createMorphAt(element5, 1, 1);
          morphs[3] = dom.createMorphAt(element5, 3, 3);
          morphs[4] = dom.createMorphAt(dom.childAt(element2, [3]), 1, 1);
          return morphs;
        },
        statements: [["content", "item.tariffGroupsString", ["loc", [null, [14, 32], [14, 59]]]], ["block", "unless", [["get", "item.ppmString", ["loc", [null, [15, 39], [15, 53]]]]], [], 0, null, ["loc", [null, [15, 29], [17, 35]]]], ["block", "link-to", ["b2b-contract.dzu.show-gas.prices.add-price", ["get", "item.id", ["loc", [null, [20, 80], [20, 87]]]]], ["class", "btn btn-primary btn-xs"], 1, null, ["loc", [null, [20, 24], [21, 61]]]], ["block", "link-to", ["b2b-contract.dzu.show-gas.prices.remove-set", ["get", "item.id", ["loc", [null, [22, 81], [22, 88]]]]], ["class", "btn btn-danger btn-xs"], 2, null, ["loc", [null, [22, 24], [23, 46]]]], ["block", "if", [["get", "item.periodValues", ["loc", [null, [28, 26], [28, 43]]]]], [], 3, 4, ["loc", [null, [28, 20], [61, 27]]]]],
        locals: ["item"],
        templates: [child0, child1, child2, child3, child4]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 64,
              "column": 8
            },
            "end": {
              "line": 66,
              "column": 8
            }
          },
          "moduleName": "ember-new/templates/b2b-contract/dzu/show-gas/prices.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "callout callout-danger");
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Brak zestawów cen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes", "wrong-type"]
        },
        "revision": "Ember@2.3.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 70,
            "column": 10
          }
        },
        "moduleName": "ember-new/templates/b2b-contract/dzu/show-gas/prices.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-md-12");
        var el3 = dom.createTextNode("\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "pull-right margin-right");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "clearfix");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element6 = dom.childAt(fragment, [0, 1]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(element6, [1]), 1, 1);
        morphs[1] = dom.createMorphAt(element6, 5, 5);
        morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "link-to", ["b2b-contract.dzu.show-gas.prices.add"], ["class", "btn btn-primary"], 0, null, ["loc", [null, [5, 12], [6, 43]]]], ["block", "each", [["get", "model", ["loc", [null, [9, 16], [9, 21]]]]], ["key", "@index"], 1, 2, ["loc", [null, [9, 8], [66, 17]]]], ["content", "outlet", ["loc", [null, [70, 0], [70, 10]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});
define('ember-new/components/download-file', ['exports', 'ember', 'ember-new/config/environment', 'ember-new/utils/utils'], function (exports, _ember, _emberNewConfigEnvironment, _emberNewUtilsUtils) {
    exports['default'] = _ember['default'].Component.extend({

        cookies: _ember['default'].inject.service(),

        suffix: null,

        tagName: 'a',
        attributeBindings: ['href', 'target'],

        target: '_blank',

        href: (function () {
            var url = '/download/' + this.get('file.id');
            if (this.get('suffix')) {
                url = url + '/' + this.get('suffix');
            }
            return _emberNewUtilsUtils['default'].generateSimpleUrl(url, '?', _emberNewConfigEnvironment['default'], this.get('TheUser'), this.get('cookies'));
        }).property('file')

    });
});
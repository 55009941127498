define('ember-new/models/individual-contract-contact-person-view-model', ['exports', 'ember-data'], function (exports, _emberData) {

    var attr = _emberData['default'].attr;

    exports['default'] = _emberData['default'].Model.extend({
        idContract: attr('string'),
        firstName: attr('string'),
        lastName: attr('string'),
        position: attr('string'),
        email: attr('string'),
        phoneMobile: attr('string'),
        phoneLandline: attr('string'),
        errors: attr()
    });
});
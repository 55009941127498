define('ember-new/controllers/individual-contract/status-history', ['exports', 'ember-new/config/environment', 'ember', 'ember-new/mixins/comments/comments-controller'], function (exports, _emberNewConfigEnvironment, _ember, _emberNewMixinsCommentsCommentsController) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsCommentsCommentsController['default'], {

        contractControllerName: null,

        addCommentApiUrl: (function () {
            var controllerName = 'individual' + this.get('contractType') + 'contracts';
            return _emberNewConfigEnvironment['default'].apiUrl + '/rest/' + controllerName + '/' + this.get('contractId') + '/comments';
        }).property('contract'),

        resetData: function resetData() {
            this.set('model', null);
        }
    });
});
define("ember-new/templates/b2b-contract/dzu/partials/ppm-prices/params", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 8
            },
            "end": {
              "line": 15,
              "column": 8
            }
          },
          "moduleName": "ember-new/templates/b2b-contract/dzu/partials/ppm-prices/params.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "flex-item");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("strong");
          var el3 = dom.createTextNode("Indeks");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(element1, 3, 3);
          morphs[1] = dom.createMorphAt(element1, 5, 5);
          morphs[2] = dom.createMorphAt(element1, 7, 7);
          return morphs;
        },
        statements: [["inline", "cell-data-label", [], ["value", ["subexpr", "@mut", [["get", "periodValue.resignation", ["loc", [null, [11, 36], [11, 59]]]]], [], []], "label", "Rezygnacja"], ["loc", [null, [11, 12], [11, 80]]]], ["inline", "cell-data-label", [], ["value", ["subexpr", "@mut", [["get", "periodValue.indexProfileFactor", ["loc", [null, [12, 36], [12, 66]]]]], [], []], "label", "Współczynnik profilowy"], ["loc", [null, [12, 12], [12, 99]]]], ["inline", "cell-data-label", [], ["value", ["subexpr", "@mut", [["get", "periodValue.indexExpectedPrice", ["loc", [null, [13, 36], [13, 66]]]]], [], []], "label", "Oczekiwana cena"], ["loc", [null, [13, 12], [13, 92]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 8
            },
            "end": {
              "line": 25,
              "column": 8
            }
          },
          "moduleName": "ember-new/templates/b2b-contract/dzu/partials/ppm-prices/params.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "flex-item");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("strong");
          var el3 = dom.createTextNode("Upust dodatkowy");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(element0, 3, 3);
          morphs[1] = dom.createMorphAt(element0, 5, 5);
          morphs[2] = dom.createMorphAt(element0, 7, 7);
          return morphs;
        },
        statements: [["inline", "cell-data-label", [], ["value", ["subexpr", "@mut", [["get", "periodValue.discountType", ["loc", [null, [20, 36], [20, 60]]]]], [], []], "label", "Rodzaj dodatkowego upustu"], ["loc", [null, [20, 12], [20, 96]]]], ["inline", "cell-data-label", [], ["value", ["subexpr", "@mut", [["get", "periodValue.discountDivision", ["loc", [null, [21, 36], [21, 64]]]]], [], []], "label", "Podział"], ["loc", [null, [21, 12], [21, 82]]]], ["inline", "cell-data-label", [], ["value", ["subexpr", "@mut", [["get", "periodValue.discountBoundry", ["loc", [null, [22, 36], [22, 63]]]]], [], []], "label", "Granica"], ["loc", [null, [22, 12], [22, 81]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": false,
        "revision": "Ember@2.3.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 38,
            "column": 5
          }
        },
        "moduleName": "ember-new/templates/b2b-contract/dzu/partials/ppm-prices/params.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("td");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "cell-flex text-center");
        var el3 = dom.createTextNode("\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "flex-item");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("strong");
        var el5 = dom.createTextNode("Inne");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "flex-item");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("strong");
        var el5 = dom.createTextNode("Białe certyfikaty");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0, 1]);
        var element3 = dom.childAt(element2, [7]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(dom.childAt(element2, [1]), 3, 3);
        morphs[1] = dom.createMorphAt(element2, 3, 3);
        morphs[2] = dom.createMorphAt(element2, 5, 5);
        morphs[3] = dom.createMorphAt(element3, 3, 3);
        morphs[4] = dom.createMorphAt(element3, 5, 5);
        morphs[5] = dom.createMorphAt(element3, 7, 7);
        morphs[6] = dom.createMorphAt(element3, 9, 9);
        return morphs;
      },
      statements: [["inline", "cell-data-label", [], ["value", ["subexpr", "@mut", [["get", "periodValue.orderInvoiceNumber", ["loc", [null, [6, 36], [6, 66]]]]], [], []], "label", "Numer zamówienia na FV"], ["loc", [null, [6, 12], [6, 99]]]], ["block", "if", [["subexpr", "eq", [["get", "periodValue.value_type_", ["loc", [null, [8, 18], [8, 41]]]], "index-gas"], [], ["loc", [null, [8, 14], [8, 54]]]]], [], 0, null, ["loc", [null, [8, 8], [15, 15]]]], ["block", "if", [["subexpr", "eq", [["get", "periodValue.productType", ["loc", [null, [17, 18], [17, 41]]]], "constantPrice"], [], ["loc", [null, [17, 14], [17, 58]]]]], [], 1, null, ["loc", [null, [17, 8], [25, 15]]]], ["inline", "cell-data-label", [], ["value", ["subexpr", "@mut", [["get", "periodValue.whiteRedeemption", ["loc", [null, [29, 36], [29, 64]]]]], [], []], "label", "Czy umarzam"], ["loc", [null, [29, 12], [29, 86]]]], ["inline", "cell-data-label", [], ["value", ["subexpr", "@mut", [["get", "periodValue.whiteCalculationMethod", ["loc", [null, [30, 36], [30, 70]]]]], [], []], "label", "Sposób wyliczenia"], ["loc", [null, [30, 12], [30, 98]]]], ["inline", "cell-data-label", [], ["value", ["subexpr", "@mut", [["get", "periodValue.whiteInPriceCost", ["loc", [null, [31, 36], [31, 64]]]]], [], []], "label", "Koszt białych certyfikatów w cenie", "displayZeroValue", true], ["loc", [null, [31, 12], [32, 53]]]], ["inline", "cell-data-label", [], ["value", ["subexpr", "@mut", [["get", "periodValue.whiteActualCost", ["loc", [null, [33, 36], [33, 63]]]]], [], []], "label", "Aktualny koszt białych certyfikatów", "displayZeroValue", true], ["loc", [null, [33, 12], [34, 53]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});
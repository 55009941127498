define('ember-new/models/individual-contract-template-list-view-model', ['exports', 'ember-data'], function (exports, _emberData) {

    var attr = _emberData['default'].attr;

    exports['default'] = _emberData['default'].Model.extend({
        uuid: attr('string'),
        productName: attr('string'),
        productUuid: attr('string'),
        name: attr('string'),
        code: attr('string'),
        type: attr('string'),
        tariffGroup: attr('string'),
        tariffGroupUuid: attr('string'),
        tariffGroupAreasQuantity: attr('string'),
        endType: attr('string'),
        endDate: attr('string'),
        endMonths: attr('number'),
        promoPriceType: attr('string'),
        discountPriceDate: attr('string'),
        discountPriceMonths: attr('number'),
        promoFeeType: attr('string'),
        discountFeeDate: attr('string'),
        discountFeeMonths: attr('number'),
        isArchival: attr('boolean'),
        usysId: attr('number'),
        dateCreated: attr('string'),
        dateOfLastChange: attr('string'),
        hasAnyContract: attr('boolean'),
        numberOfAssignedContracts: attr('number')
    });
});
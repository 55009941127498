define("ember-new/components/d-form/vas/edit-contract-vas", ["exports", "ember-new/components/d-form-command", "ember-new/templates/components/d-form"], function (exports, _emberNewComponentsDFormCommand, _emberNewTemplatesComponentsDForm) {
    exports["default"] = _emberNewComponentsDFormCommand["default"].extend({
        layout: _emberNewTemplatesComponentsDForm["default"],

        commandName: 'EditContractVasCommand',

        overheadTypeForMMChanged: (function () {
            var controller = this.get('targetObject');

            if (this.get('formData.overheadTypeForMM') === 'const') {
                controller.set('isConstOverheadTypeForMM', true);
                controller.set('overheadRatePerPercentPointVisibility', true);
            } else if (this.get('formData.valueType') === 'percent' && this.get('formData.overheadTypeForMM') === 'auto') {
                controller.set('overheadRatePerPercentPoint', null);
                controller.set('valueStep', 0.1);
                controller.set('overheadRatePerPercentPointVisibility', false);
                controller.set('isConstOverheadTypeForMM', false);
            } else {
                controller.set('isConstOverheadTypeForMM', false);
                this.set('formData.baseValue', null);
            }
        }).observes('formData.overheadTypeForMM'),

        inclusionTypeChanged: (function () {
            var controller = this.get('targetObject');

            if (this.get('formData.inclusionType') === 'discount') {
                controller.set('isDiscount', true);
                this.set('formData.overheadTypeForMM', 'const');
                this.set('formData.overheadForMM', null);
            } else {
                controller.set('isDiscount', false);
                this.set('formData.overheadTypeForMM', 'auto');
                this.set('formData.baseValue', null);
            }
        }).observes('formData.inclusionType'),

        valueTypeChanged: (function () {
            var controller = this.get('targetObject');

            if (this.get('formData.valueType') === 'percent') {
                controller.set('overheadRatePerPercentPoint', null);
                controller.set('valueStep', 0.1);
            }
            if (this.get('formData.valueType') === 'percent' && this.get('formData.overheadTypeForMM') === 'const') {
                controller.set('overheadRatePerPercentPoint', null);
                controller.set('valueStep', 0.1);
                controller.set('overheadRatePerPercentPointVisibility', true);
            }

            if (this.get('formData.valueType') === 'percent' && this.get('formData.overheadTypeForMM') === 'auto') {
                controller.set('overheadRatePerPercentPoint', null);
                controller.set('valueStep', 0.1);
                controller.set('overheadRatePerPercentPointVisibility', false);
            }
            if (this.get('formData.valueType') === 'PLN') {
                controller.set('overheadRatePerPercentPointVisibility', true);
            } else {
                controller.set('valueStep', 0.01);
            }
        }).observes('formData.valueType')

    });
});
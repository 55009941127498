define('ember-new/components/switch-table-view-mode', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        currentViewMode: 'normal',
        actions: {
            viewModeChanged: function viewModeChanged(mode) {
                this.sendAction('viewModeChanged', mode);
            }

        }
    });
});
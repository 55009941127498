define('ember-new/mixins/b2b-contract/components/d-form/section-products/add-product-to-contract', ['exports', 'ember', 'ember-new/templates/components/d-form'], function (exports, _ember, _emberNewTemplatesComponentsDForm) {
    exports['default'] = _ember['default'].Mixin.create({
        layout: _emberNewTemplatesComponentsDForm['default'],
        mdl: null,

        isDefaultObserver: _ember['default'].observer('formData.isDefaultConditionProduct', function () {
            this.get('targetObject').set('isDefaultOff', !this.get('formData.isDefaultConditionProduct'));
            if (!this.get('formData.isDefaultConditionProduct')) {
                this.set('formData.defaultConditionProductName', null);
            }
        }),

        everyIndexObserver: _ember['default'].observer('formData.everyIndexAvailableInConditions', 'formData.medium', function () {
            var controller = this.get('targetObject');
            var valueEveryIndex = this.get('formData.everyIndexAvailableInConditions');
            var valueMedium = this.get('formData.medium');

            if (this.get('formData')) {
                if (valueMedium === 'ee' && valueEveryIndex !== true) {
                    controller.set('filteredProducts', controller.get('eeProducts'));
                } else if (valueMedium === 'ee' && valueEveryIndex === true) {
                    controller.set('filteredProducts', controller.get('filteredEeProducts'));
                }

                if (valueMedium === 'gas' && valueEveryIndex !== true) {
                    controller.set('filteredProducts', controller.get('gasProducts'));
                } else if (valueMedium === 'gas' && valueEveryIndex === true) {
                    controller.set('filteredProducts', controller.get('filteredGasProducts'));
                }
            }
        }),

        mediumChanged: _ember['default'].observer('formData.medium', function () {
            if (this.get('formData')) {
                this.set('formData.allowedConditionProduct', null);
                this.set('formData.defaultConditionProductName', null);
            }
        }),

        resetDefault: _ember['default'].observer('formData.isDefaultConditionProduct', function () {
            if (this.get('formData')) {
                if (this.get('formData.isDefaultConditionProduct') === false) {
                    this.set('formData.defaultConditionProductName', null);
                }
            }
        }),

        indexProductsObserver: _ember['default'].observer('formData.allowedConditionProduct', 'formData.everyIndexAvailableInConditions', function () {
            var _this2 = this;

            var controller = this.get('targetObject');
            var valueMedium = this.get('formData.medium');
            var valueEveryIndexAvailableInConditions = this.get('formData.everyIndexAvailableInConditions');
            var products = [];
            var allowedList = [];
            if (valueMedium === 'ee') {
                products = controller.get('eeProducts');
            }
            if (valueMedium === 'gas') {
                products = controller.get('gasProducts');
            }

            var allowedConditionProduct = this.get('formData.allowedConditionProduct') ? this.get('formData.allowedConditionProduct') : [];
            var _this = this;
            var productsFilteredForDefaultProduct = products.filter(function (item) {
                return item.get('isHidden') !== true && (allowedConditionProduct.includes(item.get('id')) || item.get('isIndex') && valueEveryIndexAvailableInConditions);
            });

            if (valueEveryIndexAvailableInConditions) {
                (function () {
                    var productsFilteredForAllowed = products.filter(function (item) {
                        return item.get('isHidden') !== true && allowedConditionProduct.includes(item.get('id')) && !item.get('isIndex');
                    });

                    var productsCount = 0;
                    productsFilteredForAllowed.forEach(function (value, key) {
                        if (_this.get('formData.allowedConditionProduct') && _this.get('formData.allowedConditionProduct').includes(value.get('id'))) {
                            allowedList.push(value.get('id'));
                            productsCount++;
                        }
                    });

                    if (_this2.get('formData.allowedConditionProduct') && productsCount != _this2.get('formData.allowedConditionProduct').length) {
                        _this2.set('formData.allowedConditionProduct', allowedList);
                    }
                })();
            } else {
                if (!allowedList.includes(this.get('formData.defaultConditionProductName'))) {
                    this.set('formData.defaultConditionProductName', null);
                }
            }
            controller.set('filteredDefaultProducts', productsFilteredForDefaultProduct);
        }),

        afterSuccessfulSubmit: function afterSuccessfulSubmit() {
            var targetController = this.get('targetObject');
            var route = this.container.lookup('route:b2b-contract.administration.section-products.list');
            route.refresh();
            setTimeout(function () {
                targetController.transitionToRoute('b2b-contract.administration.section-products.list');
            }, 500);
        }
    });
});
define("ember-new/controllers/meeting-verification/handling", ["exports", "ember-new/mixins/paginable-controller", "ember-new/config/environment", "ember"], function (exports, _emberNewMixinsPaginableController, _emberNewConfigEnvironment, _ember) {
    exports["default"] = _ember["default"].Controller.extend(_emberNewMixinsPaginableController["default"], {

        canSubmit: true,
        isSubmitting: false,
        showSubmitResult: false,
        submitResult: null,
        tasksAlreadyHasStatusQty: 0,

        rowCollection: [],

        ajax: _ember["default"].inject.service('ajax'),
        applicationAlerts: _ember["default"].inject.service('application-alerts-manager'),

        actions: {
            submit: function submit() {
                var _this = this;

                this.set('isSubmitting', true);
                var self = this;
                var rowCollection = this.get('rowCollection');
                var selectedItems = [];
                var tasksAlreadyHasStatusQty = this.get('tasksAlreadyHasStatusQty');
                var isConfirmed = null;

                rowCollection.forEach(function (elm) {
                    if (elm.get('isToImport')) {
                        selectedItems.push({ taskId: elm.get('taskUuid'), verificationStatusId: elm.get('verificationStatus') });
                    }
                    if (elm.get('warnings').length > 0 && elm.get('isToImport')) {
                        tasksAlreadyHasStatusQty++;
                    }
                });

                if (selectedItems.length < 1) {
                    this.get('applicationAlerts').addActionMessage('warning', 'Żadna pozycja z listy nie została wybrana!');
                    this.set('isSubmitting', false);
                    return;
                }

                if (tasksAlreadyHasStatusQty > 0) {
                    isConfirmed = confirm('Ilość wybranych tasków, które posiadją już status weryfikacji to ' + tasksAlreadyHasStatusQty + '. Czy na pewno chcesz nadpisać ich statusy?');
                }

                if (isConfirmed === false) {
                    this.set('isSubmitting', false);
                    tasksAlreadyHasStatusQty = 0;
                    return;
                }

                var url = _emberNewConfigEnvironment["default"].apiUrl + '/rest/setverificationstatusformeetingslists';
                var payload = { SetStatusesForMeetingVerificationListCommand: { tasks: selectedItems } };
                this.get('ajax').ajaxPost(url, payload).then(function () {
                    self.get('applicationAlerts').addActionMessage('success', "Statusy zostały przypisane");
                    var route = _this.container.lookup('route:meeting-verification');
                    self.set('isSubmitting', false);
                    tasksAlreadyHasStatusQty = 0;
                    route.refreshModel();
                }, function (reject) {
                    self.set('isSubmitting', false);
                    self.rejectMethod(reject, self);
                    self.get('applicationAlerts').addActionMessage('warning', 'Wystąpił błąd!');
                });
            }
        },

        rejectMethod: function rejectMethod(reject, self) {
            reject.errors.forEach(function (singleError) {
                var id = singleError.property_path;
                var row = self.get('store').peekRecord('MeetingVerificationThuliumImportRow', id.toString());
                row.set('errors', []);
                setTimeout(function () {
                    row.set('errors', [singleError.message]);
                    row.set('isToImport', false);
                });
            });
        }
    });
});
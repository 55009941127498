define('ember-new/controllers/b2b-contract/administration/gas-business-tariff/add', ['exports', 'ember', 'ember-new/mixins/b2b-contract/controllers/gas-business-tariff/edit-controller'], function (exports, _ember, _emberNewMixinsB2bContractControllersGasBusinessTariffEditController) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsB2bContractControllersGasBusinessTariffEditController['default'], {

        fillForm: function fillForm(form) {
            form.set('model.name', '');
            form.set('model.usysId', null);
            form.set('model.excise', null);
            form.set('model.tariff', null);
            form.set('model.tariffPgnig', null);
            form.set('model.contractProduct', null);
        }
    });
});
define('ember-new/components/bs-switch', ['exports', 'ember', 'ember-cli-bootstrap-switch/components/bs-switch'], function (exports, _ember, _emberCliBootstrapSwitchComponentsBsSwitch) {

  var bsSwitchComponent = _emberCliBootstrapSwitchComponentsBsSwitch['default'].extend({

    willDestroyElement: function willDestroyElement() {
      this.$('input').bootstrapSwitch('destroy');
    },

    didInsertElement: function didInsertElement() {
      var _this = this;

      //assert("bootstrap-switch has to exist on Ember.$.fn.bootstrapSwitch", typeof Ember.$.fn.bootstrapSwitch === "function" );

      this.$('input').bootstrapSwitch({
        "size": this.get('btnSize'),
        "state": this.get('status'),
        "disabled": this.get('disabled'),
        "onText": this.get('onText'),
        "offText": this.get('offText'),
        "onColor": this.get('onColor'),
        "offColor": this.get('offColor'),
        "labelText": this.get('labelText')
      });

      this.$('input').on('switchChange.bootstrapSwitch', function (event, state) {
        // console.log(this); // DOM element
        // console.log(event); // jQuery event
        // console.log(state); // true | false
        _this.set('status', state);
        _this.send('sendActionToConsumer', state);
      });
    },

    actions: {
      sendActionToConsumer: function sendActionToConsumer(_state) {
        this.sendAction('callback', {
          "name": this.get('name'),
          "statusValue": _state
        });
      }
    }

  });

  exports['default'] = bsSwitchComponent;
});
define('ember-new/models/contract-dzu-list-item-view-model', ['exports', 'ember-data', 'ember-new/models/abstract'], function (exports, _emberData, _emberNewModelsAbstract) {

    var attr = _emberData['default'].attr;
    var refOne = _emberData['default'].belongsTo;

    exports['default'] = _emberData['default'].Model.extend({
        contractNo: attr('string'),
        type: attr('string'),
        hasNewConnection: attr('boolean'),

        dateOfficeReceivedLastDocument: attr('string'),
        dateOfficeReceived: attr('string'),
        dateContractReported: attr('string'),
        dateStart: attr('string'),

        address: attr(),
        nip: attr('string'),
        companyName: attr('string'),

        mainTariffGroup: attr(),

        status: attr('string'),
        statusNumber: attr('number'),
        statusTime: attr('string'),

        salesman: attr('string'),
        salesmanEmail: attr('string'),
        salesmanPhone: attr('number'),
        salesmanRegion: attr('string'),
        salesmanPartnerId: attr('string'),
        completeSpecialist: attr('string'),
        completeSpecialistPhone: attr('string'),
        completeSpecialistEmail: attr('string'),
        completeDepartment: attr('string'),
        implementSpecialist: attr('string'),
        implementSpecialistPhone: attr('string'),
        implementSpecialistEmail: attr('string'),
        implementSpecialistDepartment: attr('string'),
        mm: attr('number'),
        ppmCount: attr('number'),
        tariffGroup: attr('string'),
        reserveAgreement: attr('string'),
        product: attr('string'),
        monthsNo: attr('number'),
        contractType: attr('string'),
        commentsNotifications: attr('number'),
        notificationsExist: attr('boolean'),
        isIntroductionDocumentDownloaded: attr('boolean'),
        introductionDocumentDownloadedTime: attr('string'),

        validationStatus: attr('number'),
        isArchival: attr('boolean'),

        isCoaching: attr('boolean'),
        coachingName: attr('string'),
        lastComment: attr('string'),

        isGreatVolume: attr('number')
    });
});
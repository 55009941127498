define('ember-new/controllers/b2b-contract/salesman/show-gas/documents', ['exports', 'ember', 'ember-new/config/environment', 'ember-new/mixins/b2b-contract/controllers/reload-contract-header', 'ember-new/utils/utils'], function (exports, _ember, _emberNewConfigEnvironment, _emberNewMixinsB2bContractControllersReloadContractHeader, _emberNewUtilsUtils) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsB2bContractControllersReloadContractHeader['default'], {

        applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),
        cookies: _ember['default'].inject.service(),

        actions: {
            downloadZIP: function downloadZIP(contractUuid) {
                var url = _emberNewUtilsUtils['default'].generateSimpleUrl('/export/zip/' + contractUuid, '?', _emberNewConfigEnvironment['default'], this.get('TheUser'), this.get('cookies'));

                if (this.get('model.atLeastOneDocument')) {
                    window.open(url);
                } else {
                    this.get('applicationAlerts').addActionMessage('danger', 'Brak dokumentów do pobrania.');
                }
            }
        }

    });
});
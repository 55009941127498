define('ember-new/controllers/b2b-contract/dzu/show-ee/ppe/remove', ['exports', 'ember', 'ember-new/config/environment'], function (exports, _ember, _emberNewConfigEnvironment) {
    exports['default'] = _ember['default'].Controller.extend({
        ajax: _ember['default'].inject.service('ajax'),
        applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),
        queryParams: ['ppeUuid'],

        actions: {
            'delete': function _delete() {
                var serializedData = {
                    RemoveContractPpmCommand: {
                        ppeUuid: this.get('ppeUuid')
                    }
                };
                var _this = this;

                var promise = this.get('ajax').ajaxPost(_emberNewConfigEnvironment['default'].apiUrl + '/rest/removecontractppmcommands', serializedData);

                promise.then(function (response) {
                    _this.get('applicationAlerts').addActionMessage('success', "PPE zostało usunięte");
                    setTimeout(function () {
                        _this.transitionToRoute('b2b-contract.dzu.show-ee.ppe');
                    }, 500);
                }, function (response) {
                    _this.get('applicationAlerts').addActionMessage('warning', "Wystąpił błąd, nie udało się usunąć PPE");
                });
            },

            afterSuccessfulCommand: function afterSuccessfulCommand() {
                this.get('ctrl.model').reload();
            }
        }
    });
});
define('ember-new/controllers/individual-contract/show-ee/documents', ['exports', 'ember-new/config/environment', 'ember', 'ember-new/mixins/individual-contract/show/documents', 'ember-new/mixins/individual-contract/show'], function (exports, _emberNewConfigEnvironment, _ember, _emberNewMixinsIndividualContractShowDocuments, _emberNewMixinsIndividualContractShow) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsIndividualContractShowDocuments['default'], _emberNewMixinsIndividualContractShow['default'], {

        name: 'individual-contract',

        afterCommand: function afterCommand() {
            var ctrl = this;
            ctrl.transitionToRoute('individual-contract.show-ee.documents');
            ctrl.get('model').reload();
        },

        isEditable: (function () {
            var editSells = this.get('model.credentials.editSells');
            var isAnonymized = this.get('model.isAnonymized');
            return editSells && !isAnonymized;
        }).property('model')

    });
});
define('ember-new/components/profile-data-form', ['exports', 'ember', 'ember-new/config/environment'], function (exports, _ember, _emberNewConfigEnvironment) {

    var ProfileDataFormComponent = _ember['default'].Component.extend({

        applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),

        customUrl: _emberNewConfigEnvironment['default'].apiUrl + '/_uploader/avatar/upload',
        paramName: 'avatar',

        fillForm: function fillForm(form) {
            form.set('model.firstName', this.get('TheUser.model.firstName'));
            form.set('model.lastName', this.get('TheUser.model.lastName'));
            form.set('model.nick', this.get('TheUser.model.nick'));
            form.set('model.phone', this.get('TheUser.model.phone'));
            var controller = this.get('targetObject');
            form.set('model.avatar', controller.get('fileId'));
        },

        profileDataUrl: (function () {
            return '/ajax/profile/edit';
        }).property(),

        afterCommand: function afterCommand() {
            var ctrl = this.get('targetObject');
            ctrl.get('target.router').refresh();
        }

    });

    exports['default'] = ProfileDataFormComponent;
});
define('ember-new/components/d-form/b2b-contract/base-prices/add-ee', ['exports', 'ember', 'ember-new/components/d-form-command', 'ember-new/templates/components/d-form', 'ember-new/config/environment'], function (exports, _ember, _emberNewComponentsDFormCommand, _emberNewTemplatesComponentsDForm, _emberNewConfigEnvironment) {
    exports['default'] = _emberNewComponentsDFormCommand['default'].extend({
        layout: _emberNewTemplatesComponentsDForm['default'],

        clearFeedback: function clearFeedback() {
            this._super();
            this.get('targetObject').set('info', []);
        },

        resolveContents: function resolveContents(form) {
            return new _ember['default'].RSVP.Promise(function (resolve, reject) {

                var store = form.get('store');
                var upload = store.peekAll('upload');
                _ember['default'].RSVP.Promise.all([upload]).then(function (result) {
                    var upload = result[0];
                    resolve(_ember['default'].Object.create({
                        fileId: upload
                    }));
                });
            });
        },

        configure: function configure() {},

        eeResHider: (function () {
            if (this.get('formRows.eeReservationUser')) {
                this.set('formRows.eeReservationUser.hidden', this.get('formData.eeReservationType') != 'user');
            }
            if (this.get('formRows.eeDateEnd')) {
                this.set('formRows.eeDateEnd.hidden', this.get('formData.eeReservationType') != 'user');
            }
            if (this.get('formRows.eeReservationChannel')) {
                this.set('formRows.eeReservationChannel.hidden', this.get('formData.eeReservationType') != 'channel');
            }
        }).observes('formData.eeReservationType'),

        gasResHider: (function () {
            if (this.get('formRows.gasReservationUser')) {
                this.set('formRows.gasReservationUser.hidden', this.get('formData.gasReservationType') != 'user');
            }
            if (this.get('formRows.gasDateEnd')) {
                this.set('formRows.gasDateEnd.hidden', this.get('formData.eeReservationType') != 'user');
            }
            if (this.get('formRows.gasReservationChannel')) {
                this.set('formRows.gasReservationChannel.hidden', this.get('formData.gasReservationType') != 'channel');
            }
        }).observes('formData.gasReservationType')

    });
});
define('ember-new/mixins/dashboard/d-form/controllers/blog-post-form', ['exports', 'ember', 'ember-new/templates/components/d-form', 'ember-new/components/d-form-command'], function (exports, _ember, _emberNewTemplatesComponentsDForm, _emberNewComponentsDFormCommand) {
  exports['default'] = _ember['default'].Mixin.create(_emberNewComponentsDFormCommand['default'], {

    layout: _emberNewTemplatesComponentsDForm['default'],

    categoryHidden: (function () {
      return !EmberNew.TheUser.isGranted('ROLE_SALES_SUPPORT');
    }).property('category'),

    contentYoutubeHidden: (function () {
      return this.get('contentType') !== "youtube";
    }).property('contentType'),

    contentVimeoHidden: (function () {
      return this.get('contentType') !== "vimeo";
    }).property('contentType'),

    contentTextAttachmentHidden: (function () {
      return this.get('contentType') !== "text";
    }).property('contentType'),

    contentHidden: (function () {
      return this.get('contentType') !== 'image' && this.get('contentType') !== 'mp3' && this.get('contentType') !== 'pdf';
    }).property('contentType'),

    afterCommand: function afterCommand() {
      var ctrl = this;

      var route = this.container.lookup("route:dashboard");
      route.refresh();
      route.send('refreshModel');

      setTimeout(function () {
        ctrl.transitionToRoute('dashboard');
      }, 100);
    },

    category: (function () {
      var categories = this.get('categories');
      var toReturn = [];
      categories.forEach(function (category) {
        toReturn.push({ label: category.get('name'), value: category.get('id') });
      });
      return toReturn;
    }).property(),

    attachmentType: [{ label: 'Obraz', value: 'image' }, { label: 'Pdf', value: 'pdf' }, { label: 'Youtube', value: 'youtube' }, { label: 'Vimeo', value: 'vimeo' }, { label: 'Mp3', value: 'mp3' }, { label: 'Tekst', value: 'text' }]

  });
});
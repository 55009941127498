define('ember-new/controllers/b2b-contract/dzu/change-departments-specialists-dr', ['exports', 'ember', 'ember-new/mixins/b2b-contract/controllers/dzu-show-contract'], function (exports, _ember, _emberNewMixinsB2bContractControllersDzuShowContract) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsB2bContractControllersDzuShowContract['default'], {

        afterCommand: function afterCommand(response) {
            var ctrl = this;
            ctrl.transitionToRoute('b2b-contract.dzu');
            ctrl.get('model').reload();
            ctrl.get('target').send('refreshContractItemModel', ctrl.get('model.id'));
        }

    });
});
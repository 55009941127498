define('ember-new/controllers/tasks/task/resolve', ['exports', 'ember-new/config/environment', 'ember-new/controllers/abstract-task-modal', 'ember'], function (exports, _emberNewConfigEnvironment, _emberNewControllersAbstractTaskModal, _ember) {
	exports['default'] = _emberNewControllersAbstractTaskModal['default'].extend({
		defaultStatusId: '53f45a82e771decf228b4568',

		ajax: _ember['default'].inject.service('ajax'),

		savingInProgress: null,
		formData: null,

		endContractDates: [],

		products: [],

		excludeStatusesIds: ['55bf3be0e771de9c5b242fcc', '55bf3f17e771dee023242fcc'],

		// nowa lojalka
		loyaltyStatusSelection: null,
		loyaltyDate: null,
		loyaltyStatusSelectionGas: null,
		loyaltyDateGas: null,

		loyalDates: [{ label: 'Brak lojalki', value: 0 }, { label: 'Nie ustalono czy jest', value: 1 }, { label: 'Lojalka - data nieznana', value: 2 }, { label: 'Lojalka - data znana', value: 3 }],
		showLoyaltyDate: (function () {
			var lss = this.get('loyaltyStatusSelection');
			return lss && lss == 3;
		}).property('loyaltyStatusSelection'),

		showLoyaltyDateGas: (function () {
			var lss = this.get('loyaltyStatusSelectionGas');
			return lss && lss == 3;
		}).property('loyaltyStatusSelectionGas'),

		isIndexedProduct: false,
		isHotDeal: false,

		myInit: (function () {

			var loyalDates = [];
			var curYear = new Date().getFullYear();

			//loyalDates.push({label: "Nie znam", value: null});
			//loyalDates.push({label: "Brak lojalki", value: '0000-00-00'});
			//for (var i = curYear; i <= curYear + 8; i++) {
			//    loyalDates.push({label: "Grudzień " + i, value: i + '-12-31'});
			//}
			//loyalDates.push({label: "Inna data ", value: 'other'});
			//
			//this.set('loyalDates', loyalDates);

			var endContractDates = [];
			var curYear = new Date().getFullYear();

			for (var i = curYear; i < curYear + 8; i++) {
				endContractDates.push({ label: "Grudzień " + i, value: i + '-12-31' });
			}
			endContractDates.push({ label: "Inna data ", value: 'other' });

			this.set('endContractDates', endContractDates);
		}).on('init'),

		coachingValues: [],
		coachingUsers: [],

		results: [{ label: "Wykonano", value: 'done' }, { label: "Przełożono", value: 'move' }, { label: "Spotkanie nie odbyło się - klient / doradca zrezygnował", value: 'resignation' }],

		cerrors: null,
		additionalErrors: null,
		result: 'done',
		status: null,
		coaching: null,
		ccRating: null,
		comment: null,
		preComment: null,
		statementOfContract: false,
		statementOfGasContract: false,

		moveVisible: false,
		doneVisible: true,
		doneMeetingVisible: true,
		addNewTaskVisible: false,
		ccRatingVisible: (function () {
			if (this.get('model.isSourceCC') && this.get('model.type') === 'meeting' && (this.get('result') === 'done' || this.get('result') === 'resignation')) {
				return true;
			}

			return false;
		}).property('model.isSourceCC', 'model.type', 'result'),

		durationObj: null,
		actualizeClientData: false,
		displayPin70Count: false,

		init: function init() {
			this._super();
			this.initDurations();
			//this.loadSalesmen();
			this.set('status', this.get('defaultStatusId')); // tmp fix
			this.statusChanged();
			this.reset();
			var _this = this;
		},

		reset: function reset() {
			this.set('cerrors', null);
			this.set('additionalErrors', null);
			this.set('result', 'done');
			this.set('doneVisible', true);
			this.set('coaching', null);
			this.updateCoachingValues();
			this.set('comment', null);
			this.set('status', this.get('defaultStatusId'));
			this.set('endOfContract', null);
			//this.set('addNewTaskVisible', false);

			this.set('time', null);
		},

		updateCoachingValues: function updateCoachingValues() {
			this.set('coachingValues', []);

			// this.coachingValues.push({label: "Nie", value: null});

			var _this = this;
			$.each(this.get('coachingUsers'), function (key, value) {

				if (value.mainRole == "ROLE_SALES_MANAGER") {
					_this.coachingValues.push({ label: "Manager " + value.fullName, value: value.id });
				} else if (value.mainRole == "ROLE_SALES_HEADMANAGER") {
					_this.coachingValues.push({ label: "Makro manager " + value.fullName, value: value.id });
				} else if (value.mainRole == "ROLE_SALES_TEST_MANAGER") {
					_this.coachingValues.push({ label: "Program managerski " + value.fullName, value: value.id });
				}
			});
		},
		loadCoachingManagers: function loadCoachingManagers() {

			var users = [];
			var _this = this;

			var ajaxPromise = this.get('ajax').ajaxGet(_emberNewConfigEnvironment['default'].apiUrl + '/ajax/tasks/get_coaching_managers/' + _this.get('model.id'));

			ajaxPromise.then(function (response) {
				_this.set('coachingUsers', response.users);
				_this.updateCoachingValues();
			});
		},
		modelChanged: (function () {

			this.loadCoachingManagers();

			// nowa lojalka:
			var loyaltyStatusSelection = this.get('acquisitionProcess.loyaltyStatus') ? this.get('loyalDates')[this.get('acquisitionProcess.loyaltyStatus')] : null;
			this.set('loyaltyStatusSelection', loyaltyStatusSelection);
			this.set('loyaltyDate', this.get('acquisitionProcess.loyaltyDate'));
			var loyaltyStatusSelectionGas = this.get('acquisitionProcess.loyaltyStatus') ? this.get('loyalDates')[this.get('acquisitionProcess.loyaltyStatus')] : null;
			this.set('loyaltyStatusSelectionGas', loyaltyStatusSelectionGas);
			this.set('loyaltyDateGas', this.get('acquisitionProcess.loyaltyDate'));

			this.set('eeConsumption', this.get('model').get('acquisitionProcess.consumption'));
			this.set('eeOverhead', this.get('model').get('acquisitionProcess.overhead'));

			this.set('gasConsumption', this.get('model').get('acquisitionProcess.consumption'));
			this.set('gasOverhead', this.get('model').get('acquisitionProcess.overhead'));

			if (this.get('eeOverhead') === null) {
				this.set('eeOverhead', 40);
			}

			this.set('numberOfMonths', this.get('model').get('acquisitionProcess.numberOfMonths'));
			this.set('numberOfMonthsGas', this.get('model').get('acquisitionProcess.numberOfMonthsGas'));

			if (this.get('numberOfMonths') === null) {
				this.set('numberOfMonths', 48);
			}

			if (this.get('numberOfMonthsGas') === null) {
				this.set('numberOfMonthsGas', 48);
			}

			this.set('margin', this.get('model').get('acquisitionProcess.consumption') / 12 * this.get('model').get('acquisitionProcess.numberOfMonths') * this.get('model').get('acquisitionProcess.overhead'));

			this.set('mainTariffGroup', this.store.peekRecord('tariffGroupDictionaryEntry', this.get('model').get('acquisitionProcess.mainTariffGroup')));
			this.set('gasMainTariffGroup', this.store.peekRecord('gasTariffGroupDictionaryEntry', this.get('model').get('acquisitionProcess.mainTariffGroup')));
			this.set('eeOsd', this.store.peekRecord('osdDictionaryEntry', this.get('model').get('acquisitionProcess.osd')));
			this.set('gasOsd', this.store.peekRecord('gasOsdDictionaryEntry', this.get('model').get('acquisitionProcess.osd')));
			this.set('eeOs', this.store.peekRecord('osDictionaryEntry', this.get('model').get('acquisitionProcess.os')));
			this.set('gasOs', this.store.peekRecord('gasOsDictionaryEntry', this.get('model').get('acquisitionProcess.os')));

			this.set('isIndexedProduct', this.get('model.acquisitionProcess.isIndexedProduct'));
			this.set('isHotDeal', this.get('model.acquisitionProcess.isHotDeal'));

			//this.set('dateStartOfContract', this.get('model').get('acquisitionProcess.dateStartOfContract'));
			//this.set('dateStartOfGasContract', this.get('model').get('acquisitionProcess.dateStartOfGasContract'));
			this.set('taskTime', this.get('model').get('time'));

			if (moment(this.get('taskTime')).isAfter(moment(new Date()).subtract(2, 'days'))) {
				this.set('taskTime', this.get('model').get('time'));
			} else {
				this.set('taskTime', moment(new Date()));
			}

			if (this.get('model').get('type') == 'meeting') {
				this.set('taskTypeMeeting', true);
			} else {
				this.set('taskTypeMeeting', false);
			}

			this.updateCoachingValues();
			this.typeChanged();

			if (this.get('taskmodel.client.contactPersons.0') !== undefined) {
				var contactPerson = this.get('taskmodel.client.contactPersons.0');
				var newContactPerson = _ember['default'].Object.create();
				newContactPerson.set('firstName', contactPerson.firstName);
				newContactPerson.set('lastName', contactPerson.lastName);
				newContactPerson.set('position', contactPerson.position);
				newContactPerson.set('email', contactPerson.email);
				newContactPerson.set('phoneMobile', contactPerson.phoneMobile);
				newContactPerson.set('phoneLandline', contactPerson.phoneLandline);

				this.set('taskmodel.client.contactPersons', []);
				this.get('taskmodel.client.contactPersons').pushObject(newContactPerson);
			} else {
				var newContactPerson = _ember['default'].Object.create();
				newContactPerson.set('firstName', "");
				newContactPerson.set('lastName', "");
				newContactPerson.set('position', "");
				newContactPerson.set('email', "");
				newContactPerson.set('phoneMobile', "");
				newContactPerson.set('phoneLandline', "");

				this.set('taskmodel.client.contactPersons', []);
				this.get('taskmodel.client.contactPersons').pushObject(newContactPerson);
			}

			this.set('ccRating', null);
		}).observes('model'),

		hasEeContract: (function () {
			// @deprecated
			return false;
			return this.get('model').get('acquisitionProcess.hasEeContract');
		}).property('model'),
		hasGasContract: (function () {
			// @deprecated
			return false;
			return this.get('model').get('acquisitionProcess.hasGasContract');
		}).property('model'),

		clientNip: (function () {
			return this.get('taskmodel.client.nip');
		}).property('taskmodel'),

		companyName: (function () {
			return this.get('taskmodel.client.companyName');
		}).property('taskmodel'),

		actions: {

			// 4 testing:
			toggleProp: function toggleProp() {
				var prop = this.get('togglePropVal');
				var propVal = this.get(prop);
				this.set(prop, !propVal);
			},
			saveAction: function saveAction() {

				this.set('savingInProgress', 'loading');

				var _this = this;

				var result = this.get('result');
				var statusObject = this.store.peekRecord('statusDefinition', this.get('status'));

				// return;
				var statusNumber = statusObject.get('number');

				this.get('taskmodel.client.contactPersons').forEach(function (item) {
					item.get('firstName');
				});

				var data = {
					resolve: {
						result: _this.get('result'),
						comment: _this.get('comment'),
						status: _this.get('status')
					}
				};

				if (result === 'move') {
					data.resolve.taskTime = this.get('moveDate').replace('_', ' ');
				}

				if (result === 'done') {
					data.resolve.eeConsumption = this.get('eeConsumption');
					data.resolve.eeOverhead = this.get('eeOverhead');
					data.resolve.eeLoyaltyStatus = this.get('loyaltyStatusSelection') ? this.get('loyaltyStatusSelection') : null;
					data.resolve.eeLoyalty = this.get('loyaltyDate') ? this.get('loyaltyDate').replace('_', ' ') : null;
					//data.resolve.taskTime = this.get('taskTime').replace('_', ' ');;
					data.resolve.gasConsumption = this.get('gasConsumption');
					data.resolve.gasOverhead = this.get('gasOverhead');
					data.resolve.gasLoyaltyStatus = this.get('loyaltyStatusSelectionGas') ? this.get('loyaltyStatusSelectionGas') : null;
					data.resolve.gasLoyalty = this.get('loyaltyDateGas') ? this.get('loyaltyDateGas') : null;

					data.resolve.eeTariffGroup = this.get('mainTariffGroup.id');
					data.resolve.gasTariffGroup = this.get('gasMainTariffGroup.id');
					data.resolve.eeOsd = this.get('eeOsd.id');
					data.resolve.gasOsd = this.get('gasOsd.id');
					data.resolve.eeOs = this.get('eeOs.id');
					data.resolve.gasOs = this.get('gasOs.id');

					data.resolve.eeNumberOfMonths = this.get('numberOfMonths');
					data.resolve.gasNumberOfMonths = this.get('gasNumberOfMonths');

					if (this.get('acquisitionProcess.acqType') == 'ee') {
						if (data.resolve.eeLoyaltyStatus == 3 && data.resolve.eeLoyalty === null) {
							alert("Musisz wybrać datę końca lojalki.");
							return;
						}
					} else {
						if (data.resolve.gasLoyaltyStatus == 3 && data.resolve.gasLoyalty === null) {
							alert("Musisz wybrać datę końca lojalki.");
							return;
						}
					}
					data.resolve.isIndexedProduct = this.get('isIndexedProduct');
					data.resolve.isHotDeal = this.get('isHotDeal');
				}

				if (result === 'done' && this.get('model').get('type') == "meeting") {
					data.resolve.coaching = this.get('coaching');

					if (this.get('ccRatingVisible')) {
						data.resolve.ccRating = _this.get('ccRating') ? _this.get('ccRating') : null;
					}
				}

				if (result === 'done' && statusNumber == '3') {
					data.resolve.eeAgreement = this.get('statementOfContract');
					data.resolve.gasAgreement = this.get('statementOfGasContract');
					//data.resolve.dateStartOfContract = this.get('dateStartOfContract');
					//data.resolve.dateStartOfGasContract = this.get('dateStartOfGasContract');
					data.resolve.dateOfReceptionContract = this.get('dateOfReceptionContract');

					// === #ACP-1365 Dodanie checkboxow do dodania umowy na Gaz i G =========================
					//data.resolve.gazPrad = this.get('gazPrad');
					data.resolve.pin70 = this.get('pin70');
					data.resolve.pnp = this.get('pnp');
					data.resolve.pin70Count = this.get('pin70Count');
					data.resolve.auditEnergyEfficiency = this.get('auditEnergyEfficiency');
					data.resolve.auditEnergyEfficiencyCyclical = this.get('auditEnergyEfficiencyCyclical');
					data.resolve.auditGasEfficiency = this.get('auditGasEfficiency');
					data.resolve.dedicatedAccountManager = this.get('dedicatedAccountManager');
					data.resolve.ebok = this.get('ebok');
					data.resolve.efaktura = this.get('efaktura');
					data.resolve.auditRenewableSourcesAuditProfitability = this.get('auditRenewableSourcesAuditProfitability');
					data.resolve.renewableSourcesPowerReservation = this.get('renewableSourcesPowerReservation');
					data.resolve.renewableSourcesDesignAndLicenses = this.get('renewableSourcesDesignAndLicenses');

					data.resolve.eeProduct = this.get('eeProduct');
					data.resolve.gasProduct = this.get('gasProduct');

					data.resolve.isAutenti = this.get('isAutenti');
				}

				if (result === 'resignation' && this.get('ccRatingVisible')) {
					data.resolve.ccRating = _this.get('ccRating') ? _this.get('ccRating') : null;
				}

				if (result === 'resignation') {
					delete data.resolve.task;
				}

				var url = _emberNewConfigEnvironment['default'].apiUrl + '/rest/tasks/' + _this.get('model').get('id') + '/resolve';

				var ajaxPromise = _this.get('ajax').ajaxCustom(url, data, 'PATCH');

				ajaxPromise.then(function (response, textStatus, xhr) {
					_this.set('savingInProgress', null);

					_this.store.pushPayload(response);

					var tasksController = _this.get('tasksController');
					tasksController.get('target').send('refreshModel');

					alert('Zaktualizowano dane klienta.');

					// @TODO przerobic na pobieranie razem z resolvem
					_this.transitionToRoute('tasks');
				}, function (jqXHR) {
					console.log('fff');
					_this.set('savingInProgress', null);
					handleAjaxError(jqXHR, function () {
						var i = 0;

						_this.set('cerrors', _ember['default'].Object.create(jqXHR.responseJSON.errors.children));
						if (jqXHR.responseJSON.errors.errors) {
							_this.set('additionalErrors', jqXHR.responseJSON.errors.errors);
						}
						_this.set('savingInProgress', null);
					});
				});
			},

			closeAction: function closeAction() {
				this.transitionToRoute('tasks');
			},

			recommendationsAction: function recommendationsAction() {
				var buttons = [_ember['default'].Object.create({ title: 'Zamknij', dismiss: 'modal' })];
				this.get('tasksController.recommendations_modal').set('model', this.get('model'));
				Bootstrap.ModalManager.open('modal', 'Zebrane rekomendacje', 'RecommendationsModal', buttons, this.get('tasksController.recommendations_modal'));
			}
		},

		resultChanged: (function () {
			var result = this.get('result'),
			    statusObject = this.store.peekRecord('statusDefinition', this.get('status')),
			    statusNumber = statusObject.get('number');

			if (result && result === 'done') {
				this.set('doneVisible', true);
			} else {
				this.set('doneVisible', false);
			}

			if (result && result === 'done' && this.get('model').get('type') === 'meeting') {
				this.set('doneMeetingVisible', true);
			} else {
				this.set('doneMeetingVisible', false);
			}

			if (result && result == 'done' &&
			//this.get('model').get('type') == 'meeting' &&
			statusNumber && statusNumber == '3') {
				this.set('doneMeetingVisible', true);

				if (!this.get('model').get('acquisitionProcess.hasEeContract')) {
					this.set('contractDataEeVisible', true);
				} else {
					this.set('contractDataEeFalse', false);
				}
				if (!this.get('model').get('acquisitionProcess.hasGasContract')) {
					this.set('contractDataGasVisible', true);
				} else {
					this.set('contractDataGasFalse', false);
				}
			} else {
				this.set('contractDataEeVisible', false);
				this.set('contractDataGasVisible', false);
			}

			if (result && result == 'move') {
				this.set('moveVisible', true);
			} else {
				this.set('moveVisible', false);
			}

			var undone = this.get('model') && this.get('model.acquisitionProcess.undoneTasksCount');
			//var undone = this.get('model') && this.get('model').get('acquisitionProcess').get('undoneTasksCount');

			this.set('addNewTaskVisible', false);

			if (undone === 1 && result && result === 'done' && statusNumber && (statusNumber === 1 || statusNumber === 2)) {
				this.set('addNewTaskVisible', true);
			}
		}).observes('result', 'status', 'model'),

		statusChanged: (function () {
			var statusObject = this.store.peekRecord('statusDefinition', this.get('status'));
			var status = statusObject.get('number');

			if (status && status == 4) {
				this.set('loyalVisible', true);
			} else {
				this.set('loyalVisible', false);
			}
		}).observes('status'),

		initDurations: function initDurations() {

			var durations = [];

			for (var i = 900; i <= 14400; i += 900) {

				var hours = Math.floor(i / 60 / 60);
				var minutes = i / 60 % 60;

				durations.push({
					int: i,
					value: (hours > 0 ? hours + ' godz' : '') + (minutes > 0 ? ' ' + (minutes + ' min') : '')
				});
			}

			this.set('durations', durations);
		},

		loadSalesmen: function loadSalesmen() {

			var users = [];

			var ajaxPromise = this.get('ajax').ajaxGet(_emberNewConfigEnvironment['default'].apiUrl + '/ajax/tasks/get_salesmen_to_choose');

			var _this = this;

			ajaxPromise.then(function (response) {

				_this.get('store').pushPayload({
					user: response.users
				});
				var users = _this.get('store').all('user');

				var usersIds = [];
				for (var i = 0; i < response.users.length; i++) {
					usersIds.push(response.users[i].id);
				}

				users = users.filter(function (item) {
					return usersIds.indexOf(item.get('id')) >= 0;
				});

				_this.set('users', users);
			});
		},

		loyalDateChanged: (function () {

			if (this.get('loyalDate') === 'other') {
				this.set('otherDateVisible', true);
			} else {
				this.set('otherDateVisible', false);
			}
		}).observes('loyalDate'),

		contractDateChanged: (function () {

			if (this.get('endOfContract') === 'other') {
				this.set('otherContractDateVisible', true);
			} else {
				this.set('otherContractDateVisible', false);
			}
		}).observes('endOfContract'),

		taskType: 'meetingTask',
		types: [{
			value: 'phoneTask',
			name: 'Telefon'
		}, {
			value: 'emailTask',
			name: 'E-mail'
		}, {
			value: 'meetingTask',
			name: 'Spotkanie'
		}],

		taskmodel: null,

		typeChanged: (function () {

			var client = this.get('model.client');

			// jesli istnieje obecnie wybrany doradca to go przepisamy ~KJ
			var forUser = this.get('model.forUser');
			if (this.get('taskmodel') && this.get('taskmodel.forUser')) {
				forUser = this.get('taskmodel.forUser');
			}

			// to samo robimy z data
			var time = null;
			if (this.get('taskmodel') && this.get('taskmodel.time')) {
				time = this.get('taskmodel.time');
			}

			var hasToReserveClient = null;
			if (this.get('taskmodel') && this.get('taskmodel.hasToReserveClient')) {
				hasToReserveClient = this.get('taskmodel.hasToReserveClient');
			}

			this.set('taskmodel', this.store.createRecord(this.get('taskType'), {
				client: client,
				address: this.store.createRecord('address'),
				forUser: forUser,
				time: time,
				hasToReserveClient: hasToReserveClient
			}));

			if (this.get('taskType') == 'meetingTask') {
				this.copyAddressFromClient(client);
			}
		}).observes('taskType'),
		copyAddressFromClient: function copyAddressFromClient(client) {
			this.get('taskmodel').set('address.street', client.get('address.street'));
			this.get('taskmodel').set('address.streetNo', client.get('address.streetNo'));
			this.get('taskmodel').set('address.aptNo', client.get('address.aptNo'));
			this.get('taskmodel').set('address.postalCode', client.get('address.postalCode'));
			this.get('taskmodel').set('address.postalCity', client.get('address.postalCity'));
			this.get('taskmodel').set('address.city', client.get('address.city'));
			this.get('taskmodel').set('address.district', client.get('address.district'));
		},

		durationChanged: (function () {
			//if (this.get('taskModel')) {
			this.set('duration', this.get('durationObj.int'));
			//}
		}).observes('durationObj'),

		actualizeClientDataChanged: (function () {}).observes('actualizeClientData'),

		pin70Observers: (function () {
			if (this.get('pin70')) {
				this.set('displayPin70Count', true);
			} else {
				this.set('displayPin70Count', false);
			}
		}).observes('pin70'),

		isErrorValidation: function isErrorValidation(objectErrors) {
			var _this = this;

			if (!objectErrors) {
				return false;
			}

			if (objectErrors.errors !== undefined) {
				return true;
			} else {
				var returnedValue = false;
				$.each(objectErrors, function (key, value) {
					var valueX = value;
					if (value && (typeof value === 'value' || typeof value === 'function')) {
						//to nothing
					} else {
							//is object
							if (_this.isErrorValidation(valueX) === true) {
								returnedValue = true;
							}
						}
				});
				if (returnedValue) {
					return returnedValue;
				}
			}

			return false;
		},

		contractDataEeVisibleProp: (function () {
			return this.get('contractDataEeVisible') && this.get('model.acqType') === 'ee' && this.get('formData.allowResolveEe');
		}).property('contractDataEeVisible', 'formData'),

		doneEeVisibleProp: (function () {
			return this.get('doneVisible') && this.get('model.acqType') === 'ee' && this.get('formData.allowResolveEe');
		}).property('doneVisible', 'formData'),

		contractDataGasVisibleProp: (function () {
			return this.get('contractDataGasVisible') && this.get('model.acqType') === 'gas' && this.get('formData.allowResolveGas');
		}).property('contractDataGasVisible', 'formData'),

		doneGasVisibleProp: (function () {
			return this.get('doneVisible') && this.get('model.acqType') === 'gas' && this.get('formData.allowResolveGas');
		}).property('doneVisible', 'formData'),

		loadProducts: (function () {
			this.set('eeProducts', this.get('store').peekAll('EeBusinessContractProductViewModel'));
			this.set('gasProducts', this.get('store').peekAll('GasBusinessContractProductViewModel'));
		}).observes('model')

	});
});
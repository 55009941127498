define('ember-new/controllers/tasks/task/verification-meeting-record', ['exports', 'ember-new/controllers/abstract-task-modal'], function (exports, _emberNewControllersAbstractTaskModal) {

    var TaskVerificationMeetingRecordController = _emberNewControllersAbstractTaskModal['default'].extend({
        needs: ['tasks'],
        actions: {
            closeAction: function closeAction() {
                this.transitionToRoute('tasks');
            }
        },

        sessionId: (function () {
            return this.get('TheUser').get('token');
        }).property()

    });

    exports['default'] = TaskVerificationMeetingRecordController;
});
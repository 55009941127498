define('ember-new/mixins/b2b-contract/components/d-form/prices/gas-price', ['exports', 'ember', 'ember-new/components/d-form-command', 'ember-new/templates/components/d-form', 'ember-new/config/environment'], function (exports, _ember, _emberNewComponentsDFormCommand, _emberNewTemplatesComponentsDForm, _emberNewConfigEnvironment) {
    exports['default'] = _ember['default'].Mixin.create({
        layout: _emberNewTemplatesComponentsDForm['default'],

        periodTypeHider: (function () {
            if (this.get('formRows.periodType')) {
                if (this.get('formData.periodType') == 'year') {
                    this.set('formRows.year.hidden', false);
                    this.set('formRows.month.hidden', true);
                    this.set('formRows.startMonth.hidden', true);
                    this.set('formRows.endMonth.hidden', true);
                    this.set('formRows.startYear.hidden', true);
                    this.set('formRows.endYear.hidden', true);
                } else if (this.get('formData.periodType') == 'month') {
                    this.set('formRows.year.hidden', false);
                    this.set('formRows.month.hidden', false);
                    this.set('formRows.startMonth.hidden', true);
                    this.set('formRows.endMonth.hidden', true);
                    this.set('formRows.startYear.hidden', true);
                    this.set('formRows.endYear.hidden', true);
                } else if (this.get('formData.periodType') == 'months_range') {
                    this.set('formRows.year.hidden', true);
                    this.set('formRows.month.hidden', true);
                    this.set('formRows.startMonth.hidden', false);
                    this.set('formRows.endMonth.hidden', false);
                    this.set('formRows.startYear.hidden', false);
                    this.set('formRows.endYear.hidden', false);
                } else {
                    if (this.get('formRows.year')) {
                        this.set('formRows.year.hidden', true);
                    }
                    if (this.get('formRows.month')) {
                        this.set('formRows.month.hidden', true);
                    }
                    if (this.get('formRows.startMonth')) {
                        this.set('formRows.startMonth.hidden', true);
                    }
                    if (this.get('formRows.endMonth')) {
                        this.set('formRows.endMonth.hidden', true);
                    }
                    if (this.get('formRows.startYear')) {
                        this.set('formRows.startYear.hidden', true);
                    }
                    if (this.get('formRows.endYear')) {
                        this.set('formRows.endYear.hidden', true);
                    }
                }
            } else {
                if (this.get('formRows.year')) {
                    this.set('formRows.year.hidden', true);
                }
                if (this.get('formRows.month')) {
                    this.set('formRows.month.hidden', true);
                }
                if (this.get('formRows.startMonth')) {
                    this.set('formRows.startMonth.hidden', true);
                }
                if (this.get('formRows.endMonth')) {
                    this.set('formRows.endMonth.hidden', true);
                }
                if (this.get('formRows.startYear')) {
                    this.set('formRows.startYear.hidden', true);
                }
                if (this.get('formRows.endYear')) {
                    this.set('formRows.endYear.hidden', true);
                }
            }
        }).observes('formData.periodType'),

        productHider: (function () {
            var selectedProduct = this.store.peekRecord('gasPriceConditionProductViewModel', this.get('formData.productId'));

            if (selectedProduct) {
                var periodType = selectedProduct.get('periodType');
                if (periodType == 'overhead') {
                    this.set('formRows.value.hidden', true);
                    this.set('formRows.baseValue.hidden', true);
                    this.set('formRows.discount.hidden', true);
                    this.set('formRows.overhead.hidden', false);
                } else if (periodType == 'discount_overhead') {
                    this.set('formRows.value.hidden', true);
                    this.set('formRows.baseValue.hidden', true);
                    this.set('formRows.discount.hidden', false);
                    this.set('formRows.overhead.hidden', false);
                } else if (periodType == 'contract_price') {
                    this.set('formRows.value.hidden', false);
                    this.set('formRows.baseValue.hidden', true);
                    this.set('formRows.discount.hidden', true);
                    this.set('formRows.overhead.hidden', true);
                    this.set('formRows.value.label', 'Cena na umowie [gr/kWh]');
                } else if (periodType == 'contract_price_with_base') {
                    this.set('formRows.value.hidden', false);
                    this.set('formRows.baseValue.hidden', false);
                    this.set('formRows.discount.hidden', true);
                    this.set('formRows.overhead.hidden', true);
                    this.set('formRows.value.label', 'Cena na umowie [gr/kWh]');
                    this.set('formRows.baseValue.label', 'Cena bazowa [gr/kWh]');
                } else if (periodType == 'index_gas') {
                    this.set('formRows.value.hidden', false);
                    this.set('formRows.baseValue.hidden', false);
                    this.set('formRows.discount.hidden', true);
                    this.set('formRows.overhead.hidden', true);
                    this.set('formRows.value.label', 'A [gr/kWh]');
                    this.set('formRows.baseValue.label', 'A min [gr/kWh]');
                } else {
                    if (this.get('formRows.value')) {
                        this.set('formRows.value.hidden', true);
                    }
                    if (this.get('formRows.baseValue')) {
                        this.set('formRows.baseValue.hidden', true);
                    }
                    if (this.get('formRows.discount')) {
                        this.set('formRows.discount.hidden', true);
                    }
                    if (this.get('formRows.overhead')) {
                        this.set('formRows.overhead.hidden', true);
                    }
                }

                var productId = this.get('formData.productId');
                var basePriceListsPromise = this.get('store').query('BasePriceByPriceConditionViewModel', { productId: productId });

                if (this.get('formData') && this.get('formRows.productId')) {
                    var controller = this.get('targetObject');
                    controller.set('basePrices', basePriceListsPromise);
                    this.set('formData.basePrices', null);
                }

                if (selectedProduct.get('autoBasePrices') === true) {
                    var _this = this;
                    var contractId = this.get('targetObject.contractId');
                    this.get('store').query('BaseAutoPriceByPriceConditionViewModel', { productId: productId, contractId: contractId }).then(function (product) {
                        _this.set('formData.basePriceId', product.content[0].id);
                    });
                }

                this.get('formRows.basePriceId.field').disabledState(!selectedProduct.get('priceSetSelectVisible'));
            }
        }).observes('formData.productId')
    });
});
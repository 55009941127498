define("ember-new/controllers/b2b-contract/administration/section-pricelists/list", ["exports", "ember", "ember-new/mixins/sortable-controller", "ember-new/mixins/filterable-controller", "ember-new/mixins/paginable-controller"], function (exports, _ember, _emberNewMixinsSortableController, _emberNewMixinsFilterableController, _emberNewMixinsPaginableController) {
    exports["default"] = _ember["default"].Controller.extend(_emberNewMixinsSortableController["default"], _emberNewMixinsFilterableController["default"], _emberNewMixinsPaginableController["default"], {

        applicationAlerts: _ember["default"].inject.service('application-alerts-manager'),

        totalItems: null,

        isReloading: false,

        queryParams: ['typeFilter', 'datePricelistEffectiveFrom', 'datePricelistEffectiveTo', 'productFilter'],

        typeFilter: null,
        datePricelistEffectiveFrom: null,
        datePricelistEffectiveTo: null,
        productFilter: null,

        hasPricelists: _ember["default"].computed('model', function () {
            return this.get('model.length') > 0;
        }),

        type: [{ label: 'EE', value: 'ee' }, { label: 'Gaz', value: 'gas' }],

        initializeProducts: function initializeProducts(eeProducts, gasProducts) {
            var ctrl = this;

            eeProducts = eeProducts.filter(function (item) {
                return item.get('isHidden') !== true || item.get('id') === ctrl.get('model').get('productUuid');
            });

            gasProducts = gasProducts.filter(function (item) {
                return item.get('isHidden') !== true || item.get('id') === ctrl.get('model').get('productUuid');
            });

            var products = eeProducts.concat(gasProducts);

            this.set('products', products);
        },

        afterCommand: function afterCommand() {
            this.send('refreshModelAction');
        },

        displayImportButton: (function () {
            return this.get('TheUser').isGranted(['ROLE_ADMIN']) || this.get('TheUser').isGranted(['ROLE_DZU_MANAGER']) || this.get('TheUser').isGranted(['ROLE_DZU2_MANAGER']) || this.get('TheUser').isGranted(['ROLE_DE']) || this.get('TheUser').isGranted(['ROLE_DG']) || this.get('TheUser').isGranted(['ROLE_SALES_SUPPORT']);
        }).property('TheUser.model')

    });
});
define('ember-new/components/download-file-image', ['exports', 'ember', 'ember-new/config/environment'], function (exports, _ember, _emberNewConfigEnvironment) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'img',
        attributeBindings: ['src'],

        src: (function () {
            var token = this.get('TheUser').get('token');
            return _emberNewConfigEnvironment['default'].apiUrl + '/image/' + this.get('id') + '?bearer=' + token;
        }).property('file')

    });
});
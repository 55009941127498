define("ember-new/components/d-form/individual-contract-price-lists/template/add", ["exports", "ember-new/components/d-form-command", "ember-new/templates/components/d-form", "ember", "ember-new/mixins/individual-contract-price-lists/d-form/template"], function (exports, _emberNewComponentsDFormCommand, _emberNewTemplatesComponentsDForm, _ember, _emberNewMixinsIndividualContractPriceListsDFormTemplate) {
    exports["default"] = _emberNewComponentsDFormCommand["default"].extend(_emberNewMixinsIndividualContractPriceListsDFormTemplate["default"], {
        layout: _emberNewTemplatesComponentsDForm["default"],

        commandName: 'CreateIndividualProductTemplateCommand',

        contractEndTypeFilled: false,
        promoPriceTypeFilled: false,
        promoFeeTypeOk: false,

        vapSelectObserver: (function () {

            if (this.get('formData.vapUuid')) {
                var formData = this.get('formData');
                var controller = this.get('targetObject');
                var vapList = controller.get('model.vaps');
                var vapUuid = this.get('formData.vapUuid');

                var singleVap = this.getVapByUuid(vapList, vapUuid);

                formData.set('type', singleVap.get('type'));
                formData.set('customPrice', singleVap.get('price'));
            }
        }).observes('formData.vapUuid'),

        formObserver: (function () {
            var controller = this.get('targetObject');

            var usysId = this.get('formData.usysId');
            var type = this.get('formData.type');
            var productUuid = this.get('formData.productUuid');
            var eeTariffGroupUuid = this.get('formData.eeTariffGroupUuid');
            var gasTariffGroupUuid = this.get('formData.gasTariffGroupUuid');
            var contractEndType = this.get('formData.contractEndType');
            var promoPriceType = this.get('formData.promoPriceType');

            if (usysId && parseInt(usysId) && productUuid && type && (eeTariffGroupUuid || gasTariffGroupUuid) && contractEndType && this.get('contractEndTypeFilled') && promoPriceType && this.get('promoPriceTypeFilled') && this.get('promoFeeTypeOk')) {
                controller.set('isButtonDisabled', false);
            } else {
                controller.set('isButtonDisabled', true);
            }
        }).observes('formData.productUuid', 'formData.type', 'formData.name', 'formData.usysId', 'formData.contractEndType', 'formData.promoPriceType', 'formData.endMonths', 'formData.endDate', 'formData.discountPriceMonths', 'formData.discountPriceDate', 'formData.promoFeeType', 'formData.discountFeeMonths', 'formData.discountFeeDate', 'formData.eeTariffGroupUuid', 'formData.gasTariffGroupUuid'),

        contractEndTypeObserver: (function () {
            if (this.get('formData.contractEndType') === 'N' || this.get('formData.contractEndType') === 'FM' && this.get('formData.endMonths') || this.get('formData.contractEndType') === 'DD' && this.get('formData.endDate')) {
                this.set('contractEndTypeFilled', true);
            } else {
                this.set('contractEndTypeFilled', false);
            }
        }).observes('formData.contractEndType', 'formData.endMonths', 'formData.endDate'),

        promoPriceTypeObserver: (function () {
            if (this.get('formData.promoPriceType') === 'FM' && this.get('formData.discountPriceMonths') || this.get('formData.promoPriceType') === 'DD' && this.get('formData.discountPriceDate')) {
                this.set('promoPriceTypeFilled', true);
            } else {
                this.set('promoPriceTypeFilled', false);
            }
        }).observes('formData.promoPriceType', 'formData.discountPriceMonths', 'formData.discountPriceDate'),

        promoFeeTypeObserver: (function () {

            if (this.get('formData.type') === 'EE' && (this.get('formData.promoFeeType') === 'FM' && this.get('formData.discountFeeMonths') || this.get('formData.promoFeeType') === 'DD' && this.get('formData.discountFeeDate')) || this.get('formData.type') === 'GAS') {
                this.set('promoFeeTypeOk', true);
            } else {
                this.set('promoFeeTypeOk', false);
            }
        }).observes('formData.type', 'formData.promoFeeType', 'formData.discountFeeMonths', 'formData.discountFeeDate'),

        getVapByUuid: function getVapByUuid(vapList, uuid) {

            var toReturn = null;

            vapList.forEach(function (vap) {
                if (vap.get('id') === uuid) {
                    toReturn = vap;
                    return true;
                }
            });

            return toReturn;
        }

    });
});
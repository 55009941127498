define('ember-new/initializers/component-styles', ['exports', 'ember', 'ember-new/mixins/style-namespacing-extras', 'ember-component-css/initializers/component-styles'], function (exports, _ember, _emberNewMixinsStyleNamespacingExtras, _emberComponentCssInitializersComponentStyles) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberComponentCssInitializersComponentStyles['default'];
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function get() {
      return _emberComponentCssInitializersComponentStyles.initialize;
    }
  });

  // eslint-disable-next-line ember/new-module-imports
  _ember['default'].Component.reopen(_emberNewMixinsStyleNamespacingExtras['default']);
});
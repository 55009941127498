define("ember-new/routes/individual-contract/show-gas", ["exports", "ember", "ember-new/routes/authenticated"], function (exports, _ember, _emberNewRoutesAuthenticated) {
    exports["default"] = _emberNewRoutesAuthenticated["default"].extend({

        beforeModel: function beforeModel() {
            this._super.apply(this, arguments);
            this.store.unloadAll('upload');
        },

        model: function model(params) {

            this.store.unloadAll('IndividualGasContract');

            return this.store.findRecord('individual-gas-contract', params.contract_id, {
                reload: true
            });
        }

    });
});
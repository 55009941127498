define("ember-new/mixins/contract/contract-comments-controller", ["exports", "ember", "ember-new/config/environment", "ember-new/mixins/comments/comments-controller"], function (exports, _ember, _emberNewConfigEnvironment, _emberNewMixinsCommentsCommentsController) {
    exports["default"] = _ember["default"].Mixin.create(_emberNewMixinsCommentsCommentsController["default"], {
        contract: null,
        contractControllerName: 'null', // do wypelnienia w controlerze uzywajacym mixina

        isCc: (function () {
            return this.get('TheUser').model.mainRole === 'ROLE_CC_CONSULTANT';
        }).property(),
        visiblesFor: [{ value: "ROLE_DZU", label: "Do Doradcy" }, { value: "ROLE_DZU2", label: "Do DzU2" }],
        visibleFor: "ROLE_DZU",

        commentRecepientsOptions: (function () {
            var TheUser = this.get('TheUser');

            if (TheUser.isGranted(['ROLE_DE', 'ROLE_DG'])) {
                return [{ value: "SALES", label: "Do Doradcy" }, { value: "DZU", label: "Do DzU" }, { value: "DZU2", label: "Do DzU2" }];
            } else if (TheUser.isGranted(['ROLE_DZU'])) {
                return [{ value: "SALES", label: "Do Doradcy" }, { value: "DZU2", label: "Do DzU2" }, { value: "DS", label: "Do DS" }];
            } else if (TheUser.isGranted(['ROLE_DZU2'])) {
                return [{ value: "DZU", label: "Do DzU" }, { value: "DS", label: "Do DS" }];
            } else {
                return [];
            }
        }).property('TheUser'),
        commentsReceptientsValue: null,
        showCommentsReceptientsField: (function () {
            var TheUser = this.get('TheUser');

            if (TheUser.isGranted(['ROLE_DE', 'ROLE_DG', 'ROLE_DZU', 'ROLE_DZU2'])) {
                return true;
            }

            return false;
        }).property('TheUser'),

        isRoleDzu2: (function () {
            return this.get('TheUser').isMainRoleIn(["ROLE_DZU2_MANAGER", 'ROLE_DZU_DZU2_MANAGER', "ROLE_DZU2_SPECIALIST", "ROLE_DZU2_SUPERVISOR", 'ROLE_VINDICATION_SPECIALIST']);
        }).property(),
        isRoleSalesman: (function () {
            return this.get('TheUser').model.mainRole === "ROLE_SALES_SALESMAN" || this.get('TheUser').model.mainRole === "ROLE_SALES_TEST_MANAGER" || this.get('TheUser').model.mainRole === "ROLE_SALES_MANAGER" || this.get('TheUser').model.mainRole === "ROLE_SALES_HEADMANAGER";
        }).property(),
        isRoleDzu: (function () {
            return this.get('TheUser').isGranted('ROLE_DZU_SPECIALIST');
        }).property(),

        addCommentApiUrl: (function () {
            return _emberNewConfigEnvironment["default"].apiUrl + '/rest/' + this.get('contractControllerName') + '/' + this.get('contract').get('id') + '/comments';
        }).property('contract'),

        validateData: function validateData() {
            var result = this._super();

            if (result === false) {
                return false;
            }

            if (!this.get('showCommentsReceptientsField')) {
                return true;
            }

            var commentsReceptientsValue = this.get('commentsReceptientsValue');

            if (commentsReceptientsValue == null) {
                this.addActionMessage('danger', "Wybierz odbiorcę komentarza!");
                return false;
            }

            return true;
        },
        prepareDataToAddComment: function prepareDataToAddComment() {
            var data = {
                comment: {
                    content: this.get('comment'),
                    commentRecepients: this.get('commentsReceptientsValue')
                }
            };

            return data;
        }

    });
});
define("ember-new/templates/b2b-contract/dzu/partials/contract/vas/vas-overhead-type", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 4,
              "column": 0
            }
          },
          "moduleName": "ember-new/templates/b2b-contract/dzu/partials/contract/vas/vas-overhead-type.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    mwh\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 0
            },
            "end": {
              "line": 7,
              "column": 0
            }
          },
          "moduleName": "ember-new/templates/b2b-contract/dzu/partials/contract/vas/vas-overhead-type.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    m-c\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.3.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 7,
            "column": 7
          }
        },
        "moduleName": "ember-new/templates/b2b-contract/dzu/partials/contract/vas/vas-overhead-type.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode(" zł /\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["content", "model.overheadForMM", ["loc", [null, [1, 0], [1, 23]]]], ["block", "if", [["subexpr", "or", [["subexpr", "eq", [["get", "model.inclusionComponent", ["loc", [null, [2, 14], [2, 38]]]], "ee_mwh"], [], ["loc", [null, [2, 10], [2, 48]]]], ["subexpr", "eq", [["get", "model.inclusionComponent", ["loc", [null, [2, 53], [2, 77]]]], "gas_fuel_mwh"], [], ["loc", [null, [2, 49], [2, 93]]]]], [], ["loc", [null, [2, 6], [2, 94]]]]], [], 0, null, ["loc", [null, [2, 0], [4, 7]]]], ["block", "if", [["subexpr", "or", [["subexpr", "eq", [["get", "model.inclusionComponent", ["loc", [null, [5, 14], [5, 38]]]], "ee_trade_fee"], [], ["loc", [null, [5, 10], [5, 54]]]], ["subexpr", "eq", [["get", "model.inclusionComponent", ["loc", [null, [5, 59], [5, 83]]]], "gas_subscription"], [], ["loc", [null, [5, 55], [5, 103]]]]], [], ["loc", [null, [5, 6], [5, 104]]]]], [], 1, null, ["loc", [null, [5, 0], [7, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});
define('ember-new/components/d-field/d-field-collection-elements/text-element', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].TextField.extend({
		classNames: ['form-control'],
		placeholder: "",
		attributeBindings: ['isReadOnly:readonly', 'isAutocomplete:autocomplete'],
		isReadOnly: null,
		isAutocomplete: null,

		setReadOnly: function setReadOnly(value) {
			if (value === true) {
				this.set('isReadOnly', true);
			} else {
				this.set('isReadOnly', null);
			}
		},

		setAutocomplete: function setAutocomplete(value) {
			if (value === true) {
				this.set('isAutocomplete', true);
			} else {
				this.set('isAutocomplete', null);
			}
		}
	});
});
define('ember-new/controllers/b2b-contract/administration/section-pricelists/import-ee', ['exports', 'ember', 'ember-new/config/environment', 'ember-new/utils/utils'], function (exports, _ember, _emberNewConfigEnvironment, _emberNewUtilsUtils) {
    exports['default'] = _ember['default'].Controller.extend({
        applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),
        cookies: _ember['default'].inject.service(),
        ajax: _ember['default'].inject.service('ajax'),

        isButtonDisabled: false,
        fillPriceListNameValue: null,

        canSubmit: true,
        isSubmitting: false,
        selectedConditionProducts: [],
        linkToXlsxTemplate: 'https://teams.microsoft.com/_#/files/Og%C3%B3lny?threadId=19%3A3f31c11bee05451ebb85410bab7cc284%40thread.skype&ctx=channel&context=B2B%2520-%2520narz%25C4%2599dzia%2520do%2520zarz%25C4%2585dzania%2520produktami%2520i%2520cennikami&rootfolder=%252Fsites%252FFortumCRMBusinessManagement%252FShared%2520Documents%252FGeneral%252FProjekty%252FB2B%2520-%2520narz%25C4%2599dzia%2520do%2520zarz%25C4%2585dzania%2520produktami%2520i%2520cennikami',

        form: null,

        fillForm: function fillForm(form) {
            form.set('model.medium', 'EE');
            form.set('model.conditionalProducts', null);
            form.set('model.dateStart', null);
            form.set('model.excelFile', null);
        },

        afterCommand: function afterCommand(response) {
            // let ctrl = this;
            // let route = this.container.lookup('route:b2b-contract.administration.base-price-lists.import-ee');
            //
            // route.refresh();
            // setTimeout(function () {
            //     ctrl.transitionToRoute('b2b-contract.administration.base-price-lists');
            // }, 500);
        },

        actions: {
            submit: function submit() {
                var _this = this;

                this.set('isSubmitting', true);
                var self = this;
                var priceList = this.get('model.rows');
                var hasError = false;

                var rowCollection = [];

                priceList.forEach(function (singleRow) {

                    if (Object.keys(singleRow.get('errors')).length > 1) {
                        hasError = true;
                    } else {
                        rowCollection.push(_this.createRowModel(singleRow));
                    }
                });

                if (hasError) {
                    this.get('applicationAlerts').addActionMessage('warning', 'Importowany plik, zawiera błędy. Wczytaj poprawny plik.');
                    return;
                }

                var url = _emberNewConfigEnvironment['default'].apiUrl + '/rest/saveimportedeebasepricelistcommands';
                var payload = {
                    SaveImportedEeBasePriceListCommand: {
                        priceList: rowCollection,
                        dateFrom: this.get('dateFrom'),
                        selectedProducts: this.get('selectedConditionProducts')
                    }
                };
                this.get('ajax').ajaxPost(url, payload).then(function () {
                    self.get('applicationAlerts').addActionMessage('success', "Cennik został poprawnie dodany");
                    self.set('isSubmitting', false);
                    _this.transitionToRoute('b2b-contract.administration.section-pricelists.list');
                }, function (reject) {
                    self.set('isSubmitting', false);
                    self.rejectMethod(reject, self);
                    self.get('applicationAlerts').addActionMessage('warning', 'Wystąpił błąd!');
                });
            }
        },

        createRowModel: function createRowModel(singleRow) {
            var rowObject = {};
            rowObject.rowNumber = singleRow.get('rowNumber');
            rowObject.dateFrom = singleRow.get('dateFrom');
            rowObject.dateTo = singleRow.get('dateTo');
            rowObject.tariffGroup = singleRow.get('tariffGroup');
            rowObject.basePriceArea1 = singleRow.get('basePriceArea1');
            rowObject.basePriceArea2 = singleRow.get('basePriceArea2');
            rowObject.basePriceArea3 = singleRow.get('basePriceArea3');
            rowObject.basePriceArea4 = singleRow.get('basePriceArea4');
            rowObject.flatPrice = singleRow.get('flatPrice');
            rowObject.baseFee = singleRow.get('baseFee');

            return rowObject;
        }

    });
});
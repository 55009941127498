define('ember-new/utils/security', ['exports', 'ember-new/config/environment'], function (exports, _emberNewConfigEnvironment) {

    var SecurityClass = Ember.Object.extend({
        grantedRoles: null,
        roleGroups: {
            'ROLE_SALES': ['ROLE_SALES', 'ROLE_SALES_SALESMAN', 'ROLE_SALES_MANAGER', 'ROLE_SALES_HEADMANAGER', 'ROLE_SALES_TEST_MANAGER'],
            'ROLE_SALES_MAINTENANCE': ['ROLE_SALES_MAINTENANCE', 'ROLE_SALES_MAINTENANCE_SPECIALIST', 'ROLE_SALES_MAINTENANCE_MANAGER', 'ROLE_SALES_MAINTENANCE_HEADMANAGER'],
            'ROLE_DZU': ['ROLE_DZU', 'ROLE_DZU_SPECIALIST', 'ROLE_DZU_MANAGER', 'ROLE_DZU_DZU2_MANAGER', 'ROLE_DZU_OFFICE'],
            'ROLE_DZU2': ['ROLE_DZU2', 'ROLE_DZU2_SPECIALIST', 'ROLE_DZU2_MANAGER', 'ROLE_DZU2_SUPERVISOR', 'ROLE_DZU_DZU2_MANAGER'],
            'ROLE_CC': ['ROLE_CC', 'ROLE_CC_CONSULTANT', 'ROLE_CC_VERIFICATOR', 'ROLE_CC_MANAGER', 'ROLE_CC_HEADMANAGER'],
            'ROLE_BOK': ['ROLE_BOK_MANAGER'],
            'ROLE_GDPR': ['ROLE_GDPR_SPECIALIST']
        },
        getRolesGroup: function getRolesGroup(roleGroup) {
            return this.get('roleGroups.' + roleGroup);
        },
        getAvailableManagerRoles: function getAvailableManagerRoles(mainRole) {
            var managerRoles;
            switch (mainRole) {
                case 'ROLE_SALES_TEST_MANAGER':
                case 'ROLE_SALES_SALESMAN':
                    managerRoles = ['ROLE_SALES_MANAGER', "ROLE_SALES_HEADMANAGER"];
                    break;
                case 'ROLE_SALES_MANAGER':
                    managerRoles = ["ROLE_SALES_HEADMANAGER"];
                    break;
                case 'ROLE_SALES_MAINTENANCE_SPECIALIST':
                    managerRoles = ['ROLE_SALES_MAINTENANCE_MANAGER', "ROLE_SALES_MAINTENANCE_HEADMANAGER"];
                    break;
                case 'ROLE_SALES_MAINTENANCE_MANAGER':
                    managerRoles = ["ROLE_SALES_MAINTENANCE_HEADMANAGER"];
                    break;
                case 'ROLE_CC_CONSULTANT':
                    managerRoles = ["ROLE_CC_MANAGER", "ROLE_CC_HEADMANAGER", "ROLE_DZU_CC_MANAGER"];
                    break;
                case 'ROLE_CC_VERIFICATOR':
                    managerRoles = ["ROLE_CC_MANAGER", "ROLE_CC_HEADMANAGER"];
                    break;
                case 'ROLE_CC_MANAGER':
                    managerRoles = ["ROLE_CC_MANAGER", "ROLE_CC_HEADMANAGER"];
                    break;
                case 'ROLE_DZU_SPECIALIST':
                    managerRoles = ["ROLE_DZU_MANAGER", "ROLE_DZU_CC_MANAGER", "ROLE_DZU_DZU2_MANAGER"];
                    break;
                case 'ROLE_DZU2_SPECIALIST':
                    managerRoles = ["ROLE_DZU2_MANAGER", "ROLE_DZU2_SUPERVISOR", "ROLE_DZU_DZU2_MANAGER"];
                    break;
                case 'ROLE_DZU2_MANAGER':
                    managerRoles = ["ROLE_DZU2_SUPERVISOR", "ROLE_DZU_DZU2_MANAGER"];
                    break;
                case 'ROLE_DZU_MANAGER':
                    managerRoles = ["ROLE_DZU_DZU2_MANAGER"];
                    break;
                case 'ROLE_AUDYTY_SPECIALIST':
                    managerRoles = ["ROLE_AUDYTY_MANAGER"];
                    break;
                case 'ROLE_SALES_HEADMANAGER':
                case 'ROLE_ADMIN':
                case 'ROLE_SUPER_ADMIN':
                case 'ROLE_MANAGEMENT':
                case 'ROLE_AUDYTY_MANAGER':
                case 'ROLE_CENTRALA':
                case 'ROLE_HR':
                case 'ROLE_SALES_SUPPORT':
                case 'ROLE_CC_HEADMANAGER':
                case 'ROLE_BOK_MANAGER':
                default:
                    managerRoles = [];
                    break;
            }

            return managerRoles;
        },
        loadUserGrantedRoles: function loadUserGrantedRoles() {
            var self = this;

            var promise = $.ajax({
                url: _emberNewConfigEnvironment['default'].apiUrl + '/ajax/security/getGrantedRoles',
                type: 'GET',
                dataType: 'json',
                data: [],
                async: false
            });

            promise.then(function (response) {
                self.grantedRoles = response.grantedRoles;
            });
        },
        setUserGrantedRoles: function setUserGrantedRoles(roles) {
            this.set('grantedRoles', roles);
        }
    });

    exports['default'] = SecurityClass;
});
define("ember-new/mixins/comments/comments-controller", ["exports", "ember", "ember-new/config/environment", "ember-new/utils/ajax/get", "ember-new/mixins/action-messages"], function (exports, _ember, _emberNewConfigEnvironment, _emberNewUtilsAjaxGet, _emberNewMixinsActionMessages) {
    exports["default"] = _ember["default"].Mixin.create(_emberNewMixinsActionMessages["default"], {
        sortProperties: ['dateCreated'],
        sortAscending: false,

        isAddingComment: false,
        showAddCommentBox: false,

        ajax: _ember["default"].inject.service('ajax'),

        myInit: (function () {

            if (!this.get('TheUser').isGranted(['ROLE_ADMIN']) && this.get('TheUser').isGranted(['ROLE_SALES'])) {
                this.set('showAddCommentBox', true);
            } else {
                this.set('showAddCommentBox', false);
            }
        }).on('init'),

        comment: "",
        history: [],
        commentErrors: [],
        commentVisibility: 'only_for_bo',
        commentVisibilityToSelect: [{ value: 'only_for_bo', label: 'Tylko dla BO' }, { value: 'ph_and_bo', label: 'Dla PH i dla BO' }],

        filterType: "all",
        filterCommentText: null,
        filterAllComments: true,
        filterSystemComments: false,
        filterTextComments: false,

        contentChangedManually: null,
        filteredContent: (function () {

            var _this = this;

            var content = this.get('arrangedContent');

            if (!content) {
                return [];
            }

            var ret = content.filter(function (item) {
                var filter = _this.get('filterType');

                if (filter && filter !== 'all') {
                    if (filter == 'system' && item.get('isSystem')) {
                        return true;
                    }

                    if (filter == 'text' && !item.get('isSystem')) {
                        return true;
                    }

                    return false;
                } else {
                    return true;
                }
            });

            ret = ret.filter(function (item) {

                var filter = _this.get('filterCommentText');

                if (filter) {
                    if (item.get('userContent')) {
                        var rx = new RegExp(filter, 'gi');
                        return item.get('userContent').match(rx);
                    }

                    if (item.get('systemContent')) {
                        var rx = new RegExp(filter, 'gi');
                        return item.get('systemContent').match(rx);
                    }
                } else {
                    return true;
                }
            });

            ret.reverse();

            return ret;
        }).property('arrangedContent', 'filterType', 'filterCommentText', 'contentChangedManually'),

        prepareDataToAddComment: function prepareDataToAddComment() {
            var data = {
                comment: {
                    content: this.get('comment')
                }
            };

            return data;
        },
        validateData: function validateData() {

            this.clearActionMessages();

            var comment = this.get('comment');
            if (comment == null || comment.length == 0) {
                this.addActionMessage('danger', "Podaj treść komentarza!");
                return false;
            }

            return true;
        },

        actions: {

            changeFilterAction: function changeFilterAction(item) {

                this.set('filterAllComments', false);
                this.set('filterSystemComments', false);
                this.set('filterTextComments', false);

                this.set('filterType', item);

                switch (item) {
                    case 'text':
                        this.set('filterTextComments', true);
                        break;
                    case 'system':
                        this.set('filterSystemComments', true);
                        break;
                    default:
                        this.set('filterAllComments', true);
                }
            },

            addComment: function addComment() {

                var _this = this;

                if (this.get('isAddingComment') === true || !this.validateData()) {
                    return false;
                }

                this.set('isAddingComment', true);

                var data = this.prepareDataToAddComment();

                var ajaxPromise = this.get('ajax').ajaxPost(this.get('addCommentApiUrl'), data);

                ajaxPromise.then(function (response) {
                    //_this.loadHistory();
                    _this.set('commentErrors', []);
                    _this.set('comment', '');
                    _this.set('boldMe', 0);

                    _this.store.pushPayload(response);

                    _this.set('isAddingComment', false);
                    _this.set('contentChangedManually', new Date().getTime());
                }, function (response) {
                    _this.set('isAddingComment', false);
                    handleAjaxError(response, function () {
                        _this.set('commentErrors', response.responseJSON.errors.children.content.errors);
                    });
                });
            }

        }
    });
});
define("ember-new/templates/user/limits", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 11,
                  "column": 24
                },
                "end": {
                  "line": 13,
                  "column": 24
                }
              },
              "moduleName": "ember-new/templates/user/limits.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "d-field-text", [], ["type", "hidden", "key", "uuid", "value", ["subexpr", "@mut", [["get", "model.uuid", ["loc", [null, [12, 74], [12, 84]]]]], [], []]], ["loc", [null, [12, 28], [12, 86]]]]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 15,
                  "column": 24
                },
                "end": {
                  "line": 17,
                  "column": 24
                }
              },
              "moduleName": "ember-new/templates/user/limits.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "d-field-number", [], ["key", "reservationLimit", "value", ["subexpr", "@mut", [["get", "model.reservationLimit", ["loc", [null, [16, 74], [16, 96]]]]], [], []]], ["loc", [null, [16, 28], [16, 98]]]]],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 19,
                  "column": 24
                },
                "end": {
                  "line": 21,
                  "column": 24
                }
              },
              "moduleName": "ember-new/templates/user/limits.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "d-field-number", [], ["key", "activityLimit", "value", ["subexpr", "@mut", [["get", "model.activityLimit", ["loc", [null, [20, 71], [20, 90]]]]], [], []]], ["loc", [null, [20, 28], [20, 92]]]]],
            locals: [],
            templates: []
          };
        })();
        var child3 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 23,
                  "column": 24
                },
                "end": {
                  "line": 25,
                  "column": 24
                }
              },
              "moduleName": "ember-new/templates/user/limits.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "d-field-number", [], ["key", "marginLimit", "value", ["subexpr", "@mut", [["get", "model.marginLimit", ["loc", [null, [24, 69], [24, 86]]]]], [], []]], ["loc", [null, [24, 28], [24, 88]]]]],
            locals: [],
            templates: []
          };
        })();
        var child4 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 27,
                  "column": 24
                },
                "end": {
                  "line": 29,
                  "column": 24
                }
              },
              "moduleName": "ember-new/templates/user/limits.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "d-field-number", [], ["key", "contractsLimit", "value", ["subexpr", "@mut", [["get", "model.contractsLimit", ["loc", [null, [28, 72], [28, 92]]]]], [], []]], ["loc", [null, [28, 28], [28, 94]]]]],
            locals: [],
            templates: []
          };
        })();
        var child5 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 31,
                  "column": 24
                },
                "end": {
                  "line": 33,
                  "column": 24
                }
              },
              "moduleName": "ember-new/templates/user/limits.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "d-field-number", [], ["key", "dzu7Limit", "value", ["subexpr", "@mut", [["get", "model.dzu7Limit", ["loc", [null, [32, 67], [32, 82]]]]], [], []]], ["loc", [null, [32, 28], [32, 84]]]]],
            locals: [],
            templates: []
          };
        })();
        var child6 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 35,
                  "column": 24
                },
                "end": {
                  "line": 37,
                  "column": 24
                }
              },
              "moduleName": "ember-new/templates/user/limits.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "d-field-number", [], ["key", "contractedMeetingsLimit", "value", ["subexpr", "@mut", [["get", "model.contractedMeetingsLimit", ["loc", [null, [36, 81], [36, 110]]]]], [], []]], ["loc", [null, [36, 28], [36, 112]]]]],
            locals: [],
            templates: []
          };
        })();
        var child7 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 39,
                  "column": 24
                },
                "end": {
                  "line": 41,
                  "column": 24
                }
              },
              "moduleName": "ember-new/templates/user/limits.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "d-field-switch", [], ["key", "hasCommitionBase", "value", ["subexpr", "@mut", [["get", "model.hasCommitionBase", ["loc", [null, [40, 74], [40, 96]]]]], [], []]], ["loc", [null, [40, 28], [40, 98]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 8,
                "column": 20
              },
              "end": {
                "line": 43,
                "column": 20
              }
            },
            "moduleName": "ember-new/templates/user/limits.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(8);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
            morphs[3] = dom.createMorphAt(fragment, 7, 7, contextualElement);
            morphs[4] = dom.createMorphAt(fragment, 9, 9, contextualElement);
            morphs[5] = dom.createMorphAt(fragment, 11, 11, contextualElement);
            morphs[6] = dom.createMorphAt(fragment, 13, 13, contextualElement);
            morphs[7] = dom.createMorphAt(fragment, 15, 15, contextualElement);
            return morphs;
          },
          statements: [["block", "d-form-row", [], ["hidden", true], 0, null, ["loc", [null, [11, 24], [13, 39]]]], ["block", "d-form-row", [], ["label", "Limit rezerwacji klientów", "widthLabelClass", "col-md-4 col-sm-4", "widthFieldClass", "col-md-8 col-sm-8"], 1, null, ["loc", [null, [15, 24], [17, 39]]]], ["block", "d-form-row", [], ["label", "Wymóg aktywności", "widthLabelClass", "col-md-4 col-sm-4", "widthFieldClass", "col-md-8 col-sm-8"], 2, null, ["loc", [null, [19, 24], [21, 39]]]], ["block", "d-form-row", [], ["label", "Wymóg masy marży", "widthLabelClass", "col-md-4 col-sm-4", "widthFieldClass", "col-md-8 col-sm-8"], 3, null, ["loc", [null, [23, 24], [25, 39]]]], ["block", "d-form-row", [], ["label", "Wymóg umów", "widthLabelClass", "col-md-4 col-sm-4", "widthFieldClass", "col-md-8 col-sm-8"], 4, null, ["loc", [null, [27, 24], [29, 39]]]], ["block", "d-form-row", [], ["label", "Wymóg DzU7", "widthLabelClass", "col-md-4 col-sm-4", "widthFieldClass", "col-md-8 col-sm-8"], 5, null, ["loc", [null, [31, 24], [33, 39]]]], ["block", "d-form-row", [], ["label", "Ilość zamówionych spotkań CC", "widthLabelClass", "col-md-4 col-sm-4", "widthFieldClass", "col-md-8 col-sm-8"], 6, null, ["loc", [null, [35, 24], [37, 39]]]], ["block", "d-form-row", [], ["label", "Czy ma podstawę?", "widthLabelClass", "col-md-4 col-sm-4", "widthFieldClass", "col-md-8 col-sm-8"], 7, null, ["loc", [null, [39, 24], [41, 39]]]]],
          locals: [],
          templates: [child0, child1, child2, child3, child4, child5, child6, child7]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 49,
                  "column": 24
                },
                "end": {
                  "line": 51,
                  "column": 24
                }
              },
              "moduleName": "ember-new/templates/user/limits.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "d-field-text", [], ["type", "hidden", "key", "uuid", "value", ["subexpr", "@mut", [["get", "model.uuid", ["loc", [null, [50, 74], [50, 84]]]]], [], []]], ["loc", [null, [50, 28], [50, 86]]]]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 53,
                  "column": 24
                },
                "end": {
                  "line": 55,
                  "column": 24
                }
              },
              "moduleName": "ember-new/templates/user/limits.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "d-field-number", [], ["key", "reservationLimitDefault", "value", ["subexpr", "@mut", [["get", "model.reservationLimitDefault", ["loc", [null, [54, 81], [54, 110]]]]], [], []]], ["loc", [null, [54, 28], [54, 112]]]]],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 57,
                  "column": 24
                },
                "end": {
                  "line": 59,
                  "column": 24
                }
              },
              "moduleName": "ember-new/templates/user/limits.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "d-field-number", [], ["key", "activityLimitDefault", "value", ["subexpr", "@mut", [["get", "model.activityLimitDefault", ["loc", [null, [58, 78], [58, 104]]]]], [], []]], ["loc", [null, [58, 28], [58, 106]]]]],
            locals: [],
            templates: []
          };
        })();
        var child3 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 61,
                  "column": 24
                },
                "end": {
                  "line": 63,
                  "column": 24
                }
              },
              "moduleName": "ember-new/templates/user/limits.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "d-field-number", [], ["key", "marginLimitDefault", "value", ["subexpr", "@mut", [["get", "model.marginLimitDefault", ["loc", [null, [62, 76], [62, 100]]]]], [], []]], ["loc", [null, [62, 28], [62, 102]]]]],
            locals: [],
            templates: []
          };
        })();
        var child4 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 65,
                  "column": 24
                },
                "end": {
                  "line": 67,
                  "column": 24
                }
              },
              "moduleName": "ember-new/templates/user/limits.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "d-field-number", [], ["key", "contractsLimitDefault", "value", ["subexpr", "@mut", [["get", "model.contractsLimitDefault", ["loc", [null, [66, 79], [66, 106]]]]], [], []]], ["loc", [null, [66, 28], [66, 108]]]]],
            locals: [],
            templates: []
          };
        })();
        var child5 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 69,
                  "column": 24
                },
                "end": {
                  "line": 71,
                  "column": 24
                }
              },
              "moduleName": "ember-new/templates/user/limits.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "d-field-number", [], ["key", "dzu7LimitDefault", "value", ["subexpr", "@mut", [["get", "model.dzu7LimitDefault", ["loc", [null, [70, 74], [70, 96]]]]], [], []]], ["loc", [null, [70, 28], [70, 98]]]]],
            locals: [],
            templates: []
          };
        })();
        var child6 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 73,
                  "column": 24
                },
                "end": {
                  "line": 75,
                  "column": 24
                }
              },
              "moduleName": "ember-new/templates/user/limits.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "d-field-number", [], ["key", "contractedMeetingsLimitDefault", "value", ["subexpr", "@mut", [["get", "model.contractedMeetingsLimitDefault", ["loc", [null, [74, 88], [74, 124]]]]], [], []]], ["loc", [null, [74, 28], [74, 126]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 46,
                "column": 20
              },
              "end": {
                "line": 77,
                "column": 20
              }
            },
            "moduleName": "ember-new/templates/user/limits.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(7);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
            morphs[3] = dom.createMorphAt(fragment, 7, 7, contextualElement);
            morphs[4] = dom.createMorphAt(fragment, 9, 9, contextualElement);
            morphs[5] = dom.createMorphAt(fragment, 11, 11, contextualElement);
            morphs[6] = dom.createMorphAt(fragment, 13, 13, contextualElement);
            return morphs;
          },
          statements: [["block", "d-form-row", [], ["hidden", true], 0, null, ["loc", [null, [49, 24], [51, 39]]]], ["block", "d-form-row", [], ["label", "Domyślny limit rezerwacji klientów", "widthLabelClass", "col-md-4 col-sm-4", "widthFieldClass", "col-md-8 col-sm-8"], 1, null, ["loc", [null, [53, 24], [55, 39]]]], ["block", "d-form-row", [], ["label", "Domyślny wymóg aktywności", "widthLabelClass", "col-md-4 col-sm-4", "widthFieldClass", "col-md-8 col-sm-8"], 2, null, ["loc", [null, [57, 24], [59, 39]]]], ["block", "d-form-row", [], ["label", "Domyślny wymóg masy marży", "widthLabelClass", "col-md-4 col-sm-4", "widthFieldClass", "col-md-8 col-sm-8"], 3, null, ["loc", [null, [61, 24], [63, 39]]]], ["block", "d-form-row", [], ["label", "Domyślny wymóg umów", "widthLabelClass", "col-md-4 col-sm-4", "widthFieldClass", "col-md-8 col-sm-8"], 4, null, ["loc", [null, [65, 24], [67, 39]]]], ["block", "d-form-row", [], ["label", "Domyślny wymóg DzU7", "widthLabelClass", "col-md-4 col-sm-4", "widthFieldClass", "col-md-8 col-sm-8"], 5, null, ["loc", [null, [69, 24], [71, 39]]]], ["block", "d-form-row", [], ["label", "Domyślny ilość zamówionych spotkań CC", "widthLabelClass", "col-md-4 col-sm-4", "widthFieldClass", "col-md-8 col-sm-8"], 6, null, ["loc", [null, [73, 24], [75, 39]]]]],
          locals: [],
          templates: [child0, child1, child2, child3, child4, child5, child6]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 16
            },
            "end": {
              "line": 78,
              "column": 16
            }
          },
          "moduleName": "ember-new/templates/user/limits.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("legend");
          dom.setAttribute(el1, "class", "legend-text-dark");
          var el2 = dom.createTextNode("AKTUALNY MIESIĄC:");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("legend");
          dom.setAttribute(el1, "class", "legend-text-dark");
          var el2 = dom.createTextNode("DOMYŚLNE (NA TE WARTOŚCI BĘDĄ RESETOWANE USTAWIENIA):");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 7, 7, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "d-form-command", [], ["commandName", "EditUserLimit", "configure", ["subexpr", "@mut", [["get", "configureFrom", ["loc", [null, [8, 76], [8, 89]]]]], [], []], "afterSuccessfulCommand", ["subexpr", "@mut", [["get", "afterCommand", ["loc", [null, [9, 61], [9, 73]]]]], [], []], "fillCommandModel", ["subexpr", "@mut", [["get", "fillForm", ["loc", [null, [9, 91], [9, 99]]]]], [], []]], 0, null, ["loc", [null, [8, 20], [43, 39]]]], ["block", "d-form-command", [], ["commandName", "EditDefaultUserLimit", "configure", ["subexpr", "@mut", [["get", "configureFrom", ["loc", [null, [46, 83], [46, 96]]]]], [], []], "afterSuccessfulCommand", ["subexpr", "@mut", [["get", "afterCommand", ["loc", [null, [47, 61], [47, 73]]]]], [], []], "fillCommandModel", ["subexpr", "@mut", [["get", "fillForm", ["loc", [null, [47, 91], [47, 99]]]]], [], []]], 1, null, ["loc", [null, [46, 20], [77, 39]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.3.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 84,
            "column": 6
          }
        },
        "moduleName": "ember-new/templates/user/limits.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "admin_lte_layout");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "hold-transition skin-blue layout-top-nav fnl");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "wrapper");
        dom.setAttribute(el3, "style", "position: absolute; width: 100%");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "content-wrapper");
        dom.setAttribute(el4, "style", "min-height: 946px;");
        var el5 = dom.createTextNode("\n\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 1, 1]), 1, 1);
        morphs[1] = dom.createMorphAt(element0, 3, 3);
        return morphs;
      },
      statements: [["block", "subpage-modal", [], ["subpageTitle", "Edytuj limity", "modalCloseRoute", "users"], 0, null, ["loc", [null, [6, 16], [78, 34]]]], ["content", "outlet", ["loc", [null, [83, 4], [83, 14]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});
define('ember-new/serializers/set-status-info', ['exports', 'ember-new/serializers/application'], function (exports, _emberNewSerializersApplication) {
    exports['default'] = _emberNewSerializersApplication['default'].extend({
        SetStatusInfoSerializerInit: (function () {
            this.addAttrsConfig({
                setBy: { serialize: null, deserialize: 'id' },
                statusDef: { serialize: null, deserialize: 'id' },
                previousStatusDef: { serialize: null, deserialize: 'id' }
            });
        }).on('init')
    });
});
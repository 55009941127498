define('ember-new/components/d-form/individual-contract/documents', ['exports', 'ember', 'ember-new/components/d-form', 'ember-new/templates/components/d-form', 'ember-new/mixins/d-form/d-form-upload', 'ember-new/config/environment'], function (exports, _ember, _emberNewComponentsDForm, _emberNewTemplatesComponentsDForm, _emberNewMixinsDFormDFormUpload, _emberNewConfigEnvironment) {
    exports['default'] = _emberNewComponentsDForm['default'].extend(_emberNewMixinsDFormDFormUpload['default'], {
        layout: _emberNewTemplatesComponentsDForm['default'],

        contractId: null,
        type: null,

        submitRestAsSimple: true,
        submitModelNamespace: 'individualContract',
        submitRestAsSimpleMethod: 'PATCH',

        submitSimpleUrl: (function () {
            return _emberNewConfigEnvironment['default'].apiUrl + '/rest/individual' + this.get('type') + 'contracts/' + this.get('contractId') + '/documents';
        }).property(),

        resolveContents: function resolveContents(form) {
            return new _ember['default'].RSVP.Promise(function (resolve, reject) {

                var store = form.get('store');
                var upload = store.peekAll('upload');
                _ember['default'].RSVP.Promise.all([upload]).then(function (result) {
                    var upload = result[0];
                    resolve(_ember['default'].Object.create({
                        fileDocumentContract: upload,
                        fileDocumentProxy: upload,
                        fileDocumentInvoices: upload,
                        fileDocumentDataUpdateRequest: upload,
                        fileDocumentChangeTariffRequest: upload,
                        fileDocumentOthers: upload,
                        fileDocumentProxySubstitution: upload,
                        fileDocumentCession: upload,
                        fileDocumentLawTitleToPremises: upload,
                        fileDocumentNewDevice: upload,
                        fileDocumentForm: upload,
                        fileDocumentRegulation: upload,
                        fileDocumentProxyReserve: upload,

                        fileDocumentContract2: upload,
                        fileDocumentProxy2: upload,
                        fileDocumentInvoices2: upload,
                        fileDocumentDataUpdateRequest2: upload,
                        fileDocumentChangeTariffRequest2: upload,
                        fileDocumentOthers2: upload,
                        fileDocumentProxySubstitution2: upload,
                        fileDocumentCession2: upload,
                        fileDocumentLawTitleToPremises2: upload,
                        fileDocumentNewDevice2: upload,
                        fileDocumentForm2: upload,
                        fileDocumentRegulation2: upload,
                        fileDocumentProxyReserve2: upload,

                        fileDocumentContract3: upload,
                        fileDocumentProxy3: upload,
                        fileDocumentInvoices3: upload,
                        fileDocumentDataUpdateRequest3: upload,
                        fileDocumentChangeTariffRequest3: upload,
                        fileDocumentOthers3: upload,
                        fileDocumentProxySubstitution3: upload,
                        fileDocumentCession3: upload,
                        fileDocumentLawTitleToPremises3: upload,
                        fileDocumentNewDevice3: upload,
                        fileDocumentForm3: upload,
                        fileDocumentRegulation3: upload,
                        fileDocumentProxyReserve3: upload,
                        fileAutenti: upload,
                        fileAutenti2: upload,
                        fileAutenti3: upload,

                        partnerFile1: upload,
                        partnerFile2: upload,
                        partnerFile3: upload,
                        partnerFile4: upload,
                        partnerFile5: upload,
                        vcRecording: upload
                    }));
                });
            });
        }
    });
});
define('ember-new/mixins/d-form/d-field-checkbox', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Mixin.create({

        actions: {
            setSelection: function setSelection(event) {
                var value = event.target.checked;
                this.set('value', value);
                this.set('checked', value);
            }
        },

        initValue: (function () {
            var value = this.get('value');
            this.set('checked', value);
        }).on('didInsertElement')

    });
});
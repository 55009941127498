define('ember-new/controllers/b2b-contract/dzu/show-gas/ppg/details/invoices/edit-invoice', ['exports', 'ember', 'ember-new/mixins/b2b-contract/controllers/reload-contract-header'], function (exports, _ember, _emberNewMixinsB2bContractControllersReloadContractHeader) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsB2bContractControllersReloadContractHeader['default'], {

        fillForm: function fillForm(form) {
            form.set('model.ppgId', this.get('ppgId'));
            form.set('model.invoiceId', this.get('invoiceId'));
            form.set('model.consumption', this.get('model.consumption'));
            form.set('model.dateFrom', this.get('model.dateFrom'));
            form.set('model.dateTo', this.get('model.dateTo'));
        },

        afterCommand: function afterCommand() {
            var ctrl = this;
            var route = this.container.lookup('route:b2b-contract.dzu.show-gas.ppg.details.invoices');
            route.refresh();
            this.reloadContractHeader();
            setTimeout(function () {
                ctrl.transitionToRoute('b2b-contract.dzu.show-gas.ppg.details.invoices');
            }, 500);
        }

    });
});
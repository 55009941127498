define('ember-new/routes/individual-contract/change-contract-parameters', ['exports', 'ember-new/routes/authenticated'], function (exports, _emberNewRoutesAuthenticated) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({

        beforeModel: function beforeModel(transition) {
            window.scrollTo(0, 0); // scroll to the top of application before reloading
            this.set('filters', transition.queryParams);
        },

        setupController: function setupController(ctrl) {
            ctrl.set('filters', this.get('filters'));
        },

        resetController: function resetController(controller, isExiting, transition) {
            this._super.apply(this, arguments);

            if (isExiting) {
                controller.clear(); // or whatever function you want to call
            }
        }
    });
});
define('ember-new/helpers/format-number', ['exports', 'ember'], function (exports, _ember) {
    exports.formatNumber = formatNumber;

    /**
     * Zwraca sformatowaną wartość liczbową na podstawie danego jezyka.
     * Przykład użycia:
     * formatowanie bez argumentow:
     * {{format-number 123456.789}} =>  "123 456,789"
     *
     * Zaokraglenie do 2 miejsca po przecinku:
     * {{format-number 123456.789 precision=2 }} =>  "123 456,79"
     *
     * Dopełnienie do 10 cyfr dziesietnych:
     * {{format-number 123456.789 integerDigits=10 }} =>  "0 000 123 456,789"
     *
     * Zaokraglenie cyfr dziesietnych do 3 miejsca"
     * {{format-number 123456.789 significantDigits=3 }} =>  "123 000"
     *
     * @param params - jako pierwszy paramter podajemy wartosc do przeformatowania
     * @param locale - kod jezyka zgodnie z https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl#Locale_identification_and_negotiation
     * @param precision - precyzja liczby po przecinku (default 3)
     * @param integerDigits - liczba cyfr dopelniania liczby calkowitej (1-21)
     * @param significantDigits - zaokragelnie liczby calkowitej do X miejsca (jezeli jest podana wartosc to pola 'precision' i 'integerDigits' sa ignorowane)
     * @param placeholder - co ma sie wyswietlac jezeli wartosc nie bedzie liczba
     *
     */

    function formatNumber(params, _ref) {
        var _ref$locale = _ref.locale;
        var locale = _ref$locale === undefined ? 'pl-PL' : _ref$locale;
        var precision = _ref.precision;
        var significantDigits = _ref.significantDigits;
        var integerDigits = _ref.integerDigits;
        var _ref$placeholder = _ref.placeholder;
        var placeholder = _ref$placeholder === undefined ? '-' : _ref$placeholder;

        if (params[0] === null || isNaN(params[0]) || typeof params[0] === "undefined") {
            return placeholder;
        }

        var value = parseFloat(params[0]);
        // https://developer.mozilla.org/pl/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
        var formatter = undefined;
        if (significantDigits) {
            formatter = new Intl.NumberFormat(locale, {
                minimumSignificantDigits: significantDigits ? significantDigits : 1,
                maximumSignificantDigits: significantDigits ? significantDigits : 21
            });
        } else {
            formatter = new Intl.NumberFormat(locale, {
                minimumIntegerDigits: integerDigits ? integerDigits : 1,
                minimumFractionDigits: precision ? precision : 0,
                maximumFractionDigits: precision ? precision : 3
            });
        }
        return formatter.format(value);
    }

    exports['default'] = _ember['default'].Helper.helper(formatNumber);
});
define('ember-new/components/copy-contract', ['exports', 'ember'], function (exports, _ember) {
    var _slice = Array.prototype.slice;
    exports['default'] = _ember['default'].Component.extend({
        routing: _ember['default'].inject.service('-routing'), // DLA  ember >= 2.15 router: Ember.inject.service()

        commandName: (function () {
            var type = this.get('contract.type');
            if (type === 'ee') {
                return 'CreateEeContractFromAnother';
            } else if (type === 'gas') {
                return 'CreateGasContractFromAnother';
            }
        }).property('model.type'),
        afterCommand: function afterCommand(form, res) {
            if (typeof res.command_output.uuid === 'undefined') {
                throw new _ember['default'].Error("Undefined new contract UUID.", res);
            }

            var newContractUuid = res.command_output.uuid;
            // dokumentacja podaje ze transitionTo przyjmuje model albo string z ID, ale wtedy wywala blad 'object.pop'...
            // trzeba przekazac tablice z elementem (?) ~lyczos
            // przekierowanie do nowej umowy:
            // this.get('routing').transitionTo(`b2b-contract.dzu.show-${this.get('contract.type')}.contract`, [newContractUuid]);

            var oleNewContractUuids = { newContractUuid: res.command_output.uuid, oldContractUuid: this.get('contract.id') };
            this.send('afterContractToList', oleNewContractUuids);

            // przekierowanie do listy umow
            this.get('routing').transitionTo('b2b-contract.dzu');
        },
        fillForm: function fillForm(form) {
            form.set('model.contractId', this.get('contract.id'));
        },
        actions: {
            afterContractToList: function afterContractToList(_ref) {
                var newContractUuid = _ref.newContractUuid;
                var oldContractUuid = _ref.oldContractUuid;

                this.sendAction.apply(this, ['action'].concat(_slice.call(arguments)));
            }
        }
    });
});
define("ember-new/routes/individual-contract/show-ee", ["exports", "ember", "ember-new/routes/authenticated"], function (exports, _ember, _emberNewRoutesAuthenticated) {
    exports["default"] = _emberNewRoutesAuthenticated["default"].extend({

        beforeModel: function beforeModel() {
            this._super.apply(this, arguments);
            this.store.unloadAll('upload');
        },

        model: function model(params) {

            this.store.unloadAll('IndividualEeContract');

            return this.store.findRecord('individual-ee-contract', params.contract_id, {
                reload: true
            });
        }

    });
});
// setupController: function(ctrl, model) {
//     let relatedDocuments = this.store.findRecord('RelatedDocumentsViewModel', model.get('uuid'));
//
//     ctrl.set('relatedDocuments', relatedDocuments);
//     ctrl.set('model', model);
// }
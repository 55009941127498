define('ember-new/routes/b2b-contract/salesman/show-ee', ['exports', 'ember-new/routes/authenticated'], function (exports, _emberNewRoutesAuthenticated) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({

        commentsNotifications: null,
        notificationsExist: null,

        model: function model(params) {
            var contractList = this.modelFor('b2b-contract.salesman');
            var contract = contractList.list.filter(function (item) {
                return item.get('id') === params.contractId;
            })[0];
            if (contract) {
                this.set('commentsNotifications', contract.get('commentsNumber'));
                this.set('notificationsExist', contract.get('notificationsExist'));
            }

            return this.store.findRecord('ContractHeaderSalesmanViewModel', params.contractId);
        },

        setupController: function setupController(ctrl, model) {
            ctrl.set('model', model);
            ctrl.set('commentsNotifications', this.get('commentsNotifications'));
            ctrl.set('notificationsExist', this.get('notificationsExist'));

            // TODO: do ustalenia z Kamilem czy mozna to w ten sposob robic czy jakoś lepiej (np. w modelu)
            var client = this.store.findRecord('ContractSummarySalesmanViewModel', model.get('id'));
            ctrl.set('model.client', client);
        },

        actions: {
            updateModel: function updateModel() {
                alert("I'm updating the model mother!");
            },

            refreshModel: function refreshModel() {
                this.refresh();
            },

            refreshModelFromCommentController: function refreshModelFromCommentController() {
                this.store.unloadAll('ContractHeaderSalesmanViewModel');
                this.refresh();
            }
        }

    });
});
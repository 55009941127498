define('ember-new/components/d-field-select-multiple', ['exports', 'ember-new/components/d-field-select', 'ember-new/mixins/d-form/d-field-select-multiple'], function (exports, _emberNewComponentsDFieldSelect, _emberNewMixinsDFormDFieldSelectMultiple) {
	exports['default'] = _emberNewComponentsDFieldSelect['default'].extend(_emberNewMixinsDFormDFieldSelectMultiple['default'], {

		actions: {
			setSelection: function setSelection(event) {
				var elementId = this.get('elementId');
				var select2 = $('#'.concat(elementId, ' select'));
				var values = select2.val();
				this.set('value', values);
			}
		}
	});
});
define('ember-new/controllers/meeting-verification/list/show/delete', ['exports', 'ember', 'ember-new/config/environment'], function (exports, _ember, _emberNewConfigEnvironment) {
    exports['default'] = _ember['default'].Controller.extend({
        ajax: _ember['default'].inject.service('ajax'),
        applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),

        actions: {
            'delete': function _delete() {
                var _this2 = this;

                var serializedData = {
                    MarkAsIncorrectDataCommand: {
                        taskUuid: this.get('model.taskUuid')
                    }
                };

                this.get('ajax').ajaxPost(_emberNewConfigEnvironment['default'].apiUrl + '/rest/markasincorrectdatacommands', serializedData).then(function () {
                    var route = _this2.container.lookup('route:meeting-verification.list');
                    route.refresh();
                    _this2.get('applicationAlerts').addActionMessage('success', 'Spotkanie zostało usunięte z kampanii i oznaczone jako Błędne dane');
                    _this2.transitionToRoute('meeting-verification.list.show');
                }, function (response) {
                    var _this = _this2;
                    if (response['errors']) {
                        response['errors'].forEach(function (error) {
                            _this.get('applicationAlerts').addActionMessage('warning', 'Nie można usunąć spotkania. ' + error['message']);
                        });
                    } else {
                        _this2.get('applicationAlerts').addActionMessage('warning', "Nie udało się usunąć spotkania");
                    }
                });
            }
        }
    });
});
define('ember-new/components/verification-tasks-list', ['exports', 'ember', 'ember-new/config/environment'], function (exports, _ember, _emberNewConfigEnvironment) {
    exports['default'] = _ember['default'].Component.extend({

        ajax: _ember['default'].inject.service('ajax'),
        applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),

        actions: {
            markWrongData: function markWrongData(taskUuid) {
                var _this = this;

                var handleResponse = function handleResponse(res) {
                    _this.get('applicationAlerts').addActionMessage('success', 'OK');
                };

                var handleError = function handleError(error) {
                    _this.get('applicationAlerts').addActionMessage('danger', 'Nieznany błąd!');
                };

                var data = {
                    taskUuid: taskUuid
                };

                this.get('ajax').ajax(_emberNewConfigEnvironment['default'].apiUrl + '/ajax/meeting_verification_mark_wrong_data', data, 'PATCH').then(function (res) {
                    return handleResponse(res);
                }, function (err) {
                    return handleError(err.error);
                });
            }
        }
    });
});
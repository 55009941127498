define('ember-new/routes/individual-contract-partners/status-history', ['exports', 'ember-new/routes/authenticated', 'ember'], function (exports, _emberNewRoutesAuthenticated, _ember) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({
        model: function model(_ref) {
            var contractId = _ref.contractId;

            this.set('contractId', contractId);
            return this.store.findRecord('IndividualContractPartnerListItemViewModel', contractId).then(function (result) {
                return {
                    list: result
                };
            })['catch'](function () {
                return console.error('route model : error msg');
            });
        },

        setupController: function setupController(ctrl, _ref2) {
            var list = _ref2.list;

            var uuid = this.get('contractId');
            ctrl.set('isLoading', true);
            ctrl.set('contractId', uuid);
            ctrl.set('contract', list);

            // pobrac dane z endpointa
            // i zapisac do modelu
            // robimy w setupController zeby nie czekac na zaladownie routa
            this.store.query('individual-contract-partner-comments-view-model', { contractId: uuid }).then(function (res) {
                _ember['default'].run.later(function () {
                    ctrl.set('model', res);
                    ctrl.set('isLoading', false);
                });
            });
        },

        actions: {
            refresh: function refresh() {
                this.refresh();
            }
        }

    });
});
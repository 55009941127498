define('ember-new/controllers/b2b-contract/dzu/show-gas/client/add-representative-person', ['exports', 'ember', 'ember-new/mixins/b2b-contract/controllers/reload-contract-header'], function (exports, _ember, _emberNewMixinsB2bContractControllersReloadContractHeader) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsB2bContractControllersReloadContractHeader['default'], {

        fillForm: function fillForm(form) {

            form.set('model.contractId', this.get('idContract'));
            form.set('model.representativePersonFirstName', null);
            form.set('model.representativePersonLastName', null);
            form.set('model.representativePersonEmail', null);
            form.set('model.representativePersonPhoneMobile', null);
            form.set('model.representativePersonPhoneLandLine', null);
        },

        afterCommand: function afterCommand() {
            var ctrl = this;
            var route = this.container.lookup("route:b2b-contract.dzu.show-gas.client");
            route.refresh();
            this.reloadContractHeader();
            setTimeout(function () {
                ctrl.transitionToRoute('b2b-contract.dzu.show-gas.client');
            }, 500);
        },

        choosePerson: (function () {
            var contactPersons = this.get('model.contactPersons');
            var toReturn = [];

            contactPersons.forEach(function (person, index) {
                toReturn.push({ label: person.get('firstName') + ' ' + person.get('lastName'), value: index });
            });

            return toReturn;
        }).property('model.contactPersons'),

        choosePersonSettlement: (function () {
            var contactSettlementPersons = this.get('model.contactSettlementPersons');
            var toReturn = [];

            contactSettlementPersons.forEach(function (person, index) {
                toReturn.push({ label: person.get('firstName') + ' ' + person.get('lastName'), value: index });
            });

            return toReturn;
        }).property('model.contactSettlementPersons'),

        actions: {
            copyDataFromContactPerson: function copyDataFromContactPerson() {
                var model = this.get('model');
                var contactPersons = this.get('model.contactPersons');

                var index = this.get('index');

                var representative = {
                    firstName: contactPersons.objectAt(index).get('firstName'),
                    lastName: contactPersons.objectAt(index).get('lastName'),
                    position: contactPersons.objectAt(index).get('position'),
                    email: contactPersons.objectAt(index).get('email'),
                    phoneMobile: contactPersons.objectAt(index).get('phoneMobile'),
                    phoneLandline: contactPersons.objectAt(index).get('phoneLandline')
                };

                var clientRepresentative = _ember['default'].Object.create(representative);

                model.set('clientRepresentative', representative);
            },

            copyDataFromContactSettlementPerson: function copyDataFromContactSettlementPerson() {
                var model = this.get('model');
                var contactSettlementPersons = this.get('model.contactSettlementPersons');

                var indexSettlement = this.get('indexSettlement');

                var representative = {
                    firstName: contactSettlementPersons.objectAt(indexSettlement).get('firstName'),
                    lastName: contactSettlementPersons.objectAt(indexSettlement).get('lastName'),
                    position: contactSettlementPersons.objectAt(indexSettlement).get('position'),
                    email: contactSettlementPersons.objectAt(indexSettlement).get('email'),
                    phoneMobile: contactSettlementPersons.objectAt(indexSettlement).get('phoneMobile'),
                    phoneLandline: contactSettlementPersons.objectAt(indexSettlement).get('phoneLandline')
                };

                var clientRepresentative = _ember['default'].Object.create(representative);

                model.set('clientRepresentative', representative);
            }

        }

    });
});
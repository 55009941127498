define('ember-new/controllers/individual-contract-price-lists/template/vaps/add', ['exports', 'ember', 'ember-new/mixins/individual-contract-price-lists/controllers/vap'], function (exports, _ember, _emberNewMixinsIndividualContractPriceListsControllersVap) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsIndividualContractPriceListsControllersVap['default'], {
        fillForm: function fillForm(form) {
            form.set('model.templateUuid', this.get('model.template.uuid'));
            form.set('model.vapUuid', null);
            form.set('model.type', null);
            form.set('model.customPrice', null);
            form.set('model.isRequired', false);
            form.set('model.validityPeriod', null);
            form.set('model.numberOfMonths', null);
        }

    });
});
define("ember-new/routes/individual-contract-partners/bulk-filtered/mark-as-sent", ["exports", "ember-new/routes/authenticated", "ember-new/mixins/routes/individual-contract-partners/bulk-actions"], function (exports, _emberNewRoutesAuthenticated, _emberNewMixinsRoutesIndividualContractPartnersBulkActions) {
    exports["default"] = _emberNewRoutesAuthenticated["default"].extend(_emberNewMixinsRoutesIndividualContractPartnersBulkActions["default"], {
        resetController: function resetController(controller, isExiting, transition) {
            this._super.apply(this, arguments);

            if (isExiting) {
                controller.resetData(); // or whatever function you want to call
            }
        }
    });
});
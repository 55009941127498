define('ember-new/components/reservation-limit', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        model: null,

        userReservationsLeft: (function () {
            return this.get('model.userReservationsLeft');
        }).property(),

        isLimitUsed: (function () {
            var userReservationsLeft = this.get('model.userReservationsLeft');
            console.log(userReservationsLeft <= 0);
            return userReservationsLeft <= 0;
        }).property(),

        isUnlimited: (function () {
            var userReservationsLeft = this.get('model.userReservationsLeft');
            return userReservationsLeft === -1;
        }).property()

    });
});
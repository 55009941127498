define('ember-new/models/pkd-dictionary-entry', ['exports', 'ember-new/models/abstract'], function (exports, _emberNewModelsAbstract) {

	var PkdDictionaryEntry = _emberNewModelsAbstract['default'].extend({
		label: DS.attr('string'),
		isHidden: DS.attr('boolean'),
		uuid: DS.attr('string'),
		pkd: DS.attr('string'),
		sekcja: DS.attr('string'),
		dzial: DS.attr('string'),
		grupa: DS.attr('string'),
		klasa: DS.attr('string'),
		opis: DS.attr('string')
	});

	exports['default'] = PkdDictionaryEntry;
});
define('ember-new/controllers/meeting-verification/list/export', ['exports', 'ember', 'ember-new/config/environment'], function (exports, _ember, _emberNewConfigEnvironment) {
    exports['default'] = _ember['default'].Controller.extend({
        ajax: _ember['default'].inject.service('ajax'),
        applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),

        actions: {
            'export': function _export() {
                var _this2 = this;

                var serializedData = {
                    MeetingVerificationCampaignExportCommand: {
                        campaignUuid: this.get('model.id')
                    }
                };

                this.get('ajax').ajaxPost(_emberNewConfigEnvironment['default'].apiUrl + '/rest/meetingverificationcampaignexportcommands', serializedData).then(function () {
                    var route = _this2.container.lookup('route:meeting-verification.list');
                    route.refresh();
                    _this2.get('applicationAlerts').addActionMessage('success', 'Kampania została wyeksportowana');
                    _this2.transitionToRoute('meeting-verification.list.export', _this2.get('model'));
                }, function (response) {
                    var _this = _this2;
                    if (response['errors']) {
                        response['errors'].forEach(function (error) {
                            _this.get('applicationAlerts').addActionMessage('warning', 'Nie można eksportować kampanii. ' + error['message']);
                        });
                    } else {
                        _this2.get('applicationAlerts').addActionMessage('warning', "Nie udało się eksportować kampanii");
                    }
                });
            }
        }
    });
});
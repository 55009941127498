define('ember-new/controllers/b2b-contract/dzu/copy-contract', ['exports', 'ember'], function (exports, _ember) {
    var _slice = Array.prototype.slice;
    exports['default'] = _ember['default'].Controller.extend({

        actions: {
            addTempContractToList: function addTempContractToList(_ref) {
                var newContractUuid = _ref.newContractUuid;
                var oldContractUuid = _ref.oldContractUuid;

                var controller = this.container.lookup('controller:b2b-contract.dzu');
                controller.send.apply(controller, ['addTempContractToList'].concat(_slice.call(arguments)));
            }
        }
    });
});
define("ember-new/routes/b2b-contract/administration/gas-business-tariff/list", ["exports", "ember-new/routes/authenticated", "ember"], function (exports, _emberNewRoutesAuthenticated, _ember) {
    exports["default"] = _emberNewRoutesAuthenticated["default"].extend({

        model: function model(params) {
            this.store.unloadAll('GasBusinessTariffEntryViewModel');
            return _ember["default"].RSVP.hash({
                list: this.store.query('GasBusinessTariffEntryViewModel', params),
                gasProducts: this.store.findAll('GasBusinessContractProductViewModel')
            });
        },

        setupController: function setupController(ctrl, model) {
            ctrl.set('model', model.list);
            ctrl.set('statusesSummary', model.statusesSummary);
            ctrl.initializeGasProducts(model.gasProducts);
        },

        actions: {
            queryParamsDidChange: function queryParamsDidChange() {
                this.refresh();
            },
            refreshRoute: function refreshRoute() {
                this.refresh();
            },
            refreshAsync: function refreshAsync() {
                this.store.unloadAll('AnonymizationRegistryEntryViewModel');
                return this.store.query('AnonymizationRegistryEntryViewModel', this.get('params'));
            }
        }
    });
});
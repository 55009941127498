define('ember-new/mixins/individual-contract/show/client/delete', ['exports', 'ember', 'ember-new/config/environment'], function (exports, _ember, _emberNewConfigEnvironment) {
    var getOwner = _ember['default'].getOwner;
    exports['default'] = _ember['default'].Mixin.create({

        ajax: _ember['default'].inject.service('ajax'),
        applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),

        actions: {
            'delete': function _delete() {
                var _this2 = this;

                var container = this.get('container');
                var model = this.get('model');
                var modelName = model._internalModel.modelName;
                var type = null;
                var _this = this;

                if (modelName == 'individual-ee-contract') {
                    type = 'ee';
                } else {
                    type = 'gas';
                }
                var ajaxPromise = this.get('ajax').ajax(_emberNewConfigEnvironment['default'].apiUrl + '/rest/individual' + type + 'contracts/' + model.get('id') + '/client/second/main', [], 'DELETE');

                ajaxPromise.then(function (response) {
                    _this.get('applicationAlerts').addActionMessage('success', "Drugi klient został usunięty");
                    getOwner(_this).lookup('route:individual-contract.show-' + type + '.client').send('refreshRoute');
                    model.reload();

                    setTimeout(function () {
                        _this.transitionToRoute('individual-contract.show-' + type + '.client');
                    }, 1000);
                }, function () {
                    _this2.get('applicationAlerts').addActionMessage('warning', "Wystąpił błąd, nie udało się usunąć drugiego klienta");
                });
            }
        }

    });
});
define('ember-new/models/osd-section-dictionary-entry', ['exports', 'ember-new/models/abstract'], function (exports, _emberNewModelsAbstract) {

    var OsdSectionDictionaryEntry = _emberNewModelsAbstract['default'].extend({
        label: DS.attr('string'),
        name: DS.attr('string'),
        isHidden: DS.attr('boolean'),
        uuid: DS.attr('string'),
        osdUuids: DS.attr(),
        osd: DS.attr()
    });

    exports['default'] = OsdSectionDictionaryEntry;
});
define('ember-new/components/loading-route-spinner', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'h1',
        classNames: ["text-center", "loading_route_spinner", "spinner"],
        spinnerClass: (function () {
            if (this.get('type') === 'dark') {
                return 'css_spinner_dark';
            }

            return 'css_spinner';
        }).property('type'),
        type: 'white'
    });
});
define('ember-new/models/task', ['exports', 'ember-new/models/extendable', 'ember-new/mixins/base-document', 'ember-data'], function (exports, _emberNewModelsExtendable, _emberNewMixinsBaseDocument, _emberData) {

	// task model
	exports['default'] = _emberNewModelsExtendable['default'].extend(_emberNewMixinsBaseDocument['default'], {
		type: _emberData['default'].attr('string'), // discriminator

		_attributesExcludeBySerializer: ['forUser', 'acquisitionProcess', 'coaching', 'isSourceCC', 'ccRating', 'taskSimplified'],

		// attributes:
		time: _emberData['default'].attr('string'),
		isDone: _emberData['default'].attr('boolean'),
		isResignation: _emberData['default'].attr('boolean'),
		notes: _emberData['default'].attr('string'),
		// coaching: DS.attr('string'),

		isSourceCC: _emberData['default'].attr('boolean'),
		ccRating: _emberData['default'].attr('number'),
		forUser: _emberData['default'].belongsTo('user', { inverse: null, async: false }),
		client: _emberData['default'].belongsTo('ambBusinessClient', { inverse: null, async: false }), // narazie nie ma innego wiec pozwolilem sobie uproscic ~KJ
		source: _emberData['default'].belongsTo('taskSourceDictionaryEntry', { inverse: null }),

		eeAcquisitionProcess: _emberData['default'].belongsTo('eeAcquisitionProcess', { inverse: null, async: false }),
		gasAcquisitionProcess: _emberData['default'].belongsTo('gasAcquisitionProcess', { inverse: null, async: false }),
		acqType: _emberData['default'].attr('string'), // virtual property
		acquisitionProcess: (function () {
			if (this.get('acqType') == 'ee') {
				return this.get('eeAcquisitionProcess');
			} else {
				return this.get('gasAcquisitionProcess');
			}
		}).property('acqType'),

		coaching: _emberData['default'].belongsTo('user', { inverse: null, async: false }),
		osd: _emberData['default'].belongsTo('osdDictionaryEntry', { inverse: null }),
		os: _emberData['default'].belongsTo('OsDictionaryEntry', { inverse: null }),
		taskSimplified: _emberData['default'].belongsTo('taskSimplified'), // relation to simplify version of the task
		verificationMeetingRecording: _emberData['default'].attr(),
		_childrenMixinsSuffix: 'Mixin',
		_childrenSuffix: 'Task',

		// computed properties:
		formattedTime: (function () {
			var date = moment(this.get('time')).format('llll');
			return date;
		}).property('time'),
		// Zadania maja sie sortowac domyslnie tak:
		// stare nierozliczone - maja miec bordowe/czerwone tlo/ramke - co wygodniej
		// aktualne nierozliczone (nierozliczone dzis, normalna linijka, bez kolorowania)
		// przyszle
		isDoneOrder: (function () {
			var now = moment();
			now.hour(0);
			now.minute(0);
			now.second(0);
			if (!this.get('isDone') && moment(this.get('time')) < now) return 0;else if (this.get('isDone')) return 2;else return 1;
		}).property('isDone', 'time'),

		titleForBoSpecialist: (function () {
			var ret = 'Kliknij aby zmienić\n\n';
			ret = ret + 'Nazwa: ' + this.get('client.companyName') + '\n' + 'NIP: ' + this.get('client.nip') + '\n' + 'Ulica: ' + this.get('client.address.street') + '\n' + 'Nr domu: ' + this.get('client.address.streetNo') + '\n' + 'Nr lok: ' + this.get('client.address.aptNo') + '\n' + 'Kod pocztowy: ' + this.get('client.address.postalCode') + '\n' + 'Poczta: ' + this.get('client.address.postalCity') + '\n' + 'Miasto: ' + this.get('client.address.city') + '\n' + 'Województwo: ' + this.get('client.address.district.label');
			return ret;
		}).property('client.companyName', 'client.nip', 'client.address.street', 'client.address.streetNo', 'client.address.aptNo', 'client.address.postalCode', 'address.city.companyName', 'address.district'),

		titleForContactPerson: (function () {
			var ret = 'Kliknij aby zmienić\n\n';
			ret = ret + 'Imię: ' + this.get('client.contactPerson.firstName') + '\n' + 'Nazwisko: ' + this.get('client.contactPerson.lastName') + '\n' + 'Stanowisko: ' + this.get('client.contactPerson.position') + '\n' + 'E-mail: ' + this.get('client.contactPerson.email') + '\n' + 'Tel. kom: ' + this.get('client.contactPerson.phoneMobile') + '\n';
			if (this.get('client.contactPerson.phoneLandline')) {
				ret = ret + 'Tel. stac: ' + this.get('client.contactPerson.phoneLandline');
			}
			return ret;
		}).property('client.contactPerson.firstName', 'client.contactPerson.lastName', 'client.contactPerson.position', 'client.contactPerson.email', 'client.contactPerson.phoneMobile', 'client.contactPerson.phoneLandline'),

		presumedMarginAmountToShow: (function () {
			var margin = this.get('acquisitionProcess.presumedMarginAmount');
			if (margin !== 0) {
				return (margin / 1000).toFixed(0) + 'k';
			} else {
				return ' - ';
			}
		}).property('acquisitionProcess.presumedMarginAmount')
	});
});
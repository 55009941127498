define('ember-new/controllers/individual-contract/batch/start', ['exports', 'ember', 'ember-new/config/environment', 'ember-new/mixins/controllers/helpers/form-processing-controller'], function (exports, _ember, _emberNewConfigEnvironment, _emberNewMixinsControllersHelpersFormProcessingController) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsControllersHelpersFormProcessingController['default'], {

        applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),
        ajax: _ember['default'].inject.service('ajax'),

        redirectAfterSubmit: 'individual-contract',

        clear: function clear() {
            this.set('cerrors', null);
        },

        actions: {
            submit: function submit() {

                var _this = this;

                if (!this.beforeSubmit()) {
                    return;
                }

                this.clear();

                var data = {
                    ids: [],
                    dateStart: this.get('dateStart')
                };

                this.get('model').forEach(function (item) {
                    if (item.get('checked') === true) {
                        data.ids.push(item.id);
                    }
                });

                var ajaxPromise = this.get('ajax').ajax(_emberNewConfigEnvironment['default'].apiUrl + '/api/individual-contract/batch/start', data, 'PATCH');

                ajaxPromise.then(function (response) {
                    _this.handleSuccess(response);
                    _this.store.pushPayload(response);
                    _this.get('applicationAlerts').addActionMessage('success', 'Zaktualizowano wybrane elementy');
                    if (_this.get('redirectAfterSubmit')) {
                        _this.transitionToRoute(_this.get('redirectAfterSubmit'));
                    }
                }, function (response) {
                    _this.handleNotValid(response);
                    _this.set('savingInProgress', false);
                    _this.get('applicationAlerts').addActionMessage('warning', response.responseJSON.message);
                });
            }
        }

    });
});
define('ember-new/components/d-field-text', ['exports', 'ember', 'ember-new/mixins/d-form/d-field'], function (exports, _ember, _emberNewMixinsDFormDField) {
    exports['default'] = _ember['default'].TextField.extend(_emberNewMixinsDFormDField['default'], {
        classNames: ['form-control'],
        placeholder: "",
        attributeBindings: ['isReadOnly:readonly', 'isAutocomplete:autocomplete'],
        isReadOnly: null,
        isAutocomplete: null,

        setReadOnly: function setReadOnly(value) {
            if (value === true) {
                this.set('isReadOnly', true);
            } else {
                this.set('isReadOnly', null);
            }
        },

        setAutocomplete: function setAutocomplete(value) {
            if (value === true) {
                this.set('isAutocomplete', true);
            } else {
                this.set('isAutocomplete', null);
            }
        }
    });
});
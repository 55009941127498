define('ember-new/helpers/format-date', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Helper.helper(function (params, namedArgs) {

    var datetimeString = params[0],
        format = namedArgs.format;

    if (!datetimeString) {
      return '-- brak daty --';
    }

    return moment(datetimeString).format(format);
  });
});
define("ember-new/routes/b2b-contract/administration/section-products/list/edit-product-to-contract", ["exports", "ember-new/routes/authenticated", "ember"], function (exports, _emberNewRoutesAuthenticated, _ember) {
    exports["default"] = _emberNewRoutesAuthenticated["default"].extend({

        model: function model(params) {
            //this.store.unloadAll('BusinessProductViewModel');
            //this.store.unloadAll('EePriceConditionProductListViewModel');
            //this.store.unloadAll('GasPriceConditionProductListViewModel');

            return _ember["default"].RSVP.hash({
                model: this.store.findRecord('BusinessProductViewModel', params.productUuid, { reload: true }),
                eeProducts: this.store.findAll('EePriceConditionProductListViewModel'),
                gasProducts: this.store.findAll('GasPriceConditionProductListViewModel')
            });
        },

        setupController: function setupController(ctrl, model) {
            ctrl.set('model', model.model);
            ctrl.initializeEeProducts(model.eeProducts);
            ctrl.initializeGasProducts(model.gasProducts);
            ctrl.initializeEeIsIndex(model.eeProducts);
            ctrl.initializeGasIsIndex(model.gasProducts);
        },

        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            }
        }
    });
});
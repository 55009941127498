define('ember-new/models/blog-category', ['exports', 'ember-new/models/abstract', 'ember-new/mixins/base-document'], function (exports, _emberNewModelsAbstract, _emberNewMixinsBaseDocument) {

    var BlogCategory = _emberNewModelsAbstract['default'].extend(_emberNewMixinsBaseDocument['default'], {
        name: DS.attr('string'),
        position: DS.attr('number'),
        published: DS.attr('boolean'),

        // relations
        posts: DS.hasMany('BlogPost', { inverse: 'category' })
    });

    exports['default'] = BlogCategory;
});
define("ember-new/controllers/individual-contract/show-ee/ppm/distribution-rate-search", ["exports", "ember", "ember-new/config/environment"], function (exports, _ember, _emberNewConfigEnvironment) {
    exports["default"] = _ember["default"].Controller.extend({

        ajax: _ember["default"].inject.service('ajax'),

        ppeShowController: _ember["default"].inject.controller('individual-contract/show-ee/ppm'),

        osd: null,
        tariff: null,
        oplataAbonamentowa: null,
        oplataPrzejsciowa: null,
        oplataSieciowa: null,
        priceOplataAbonamentowa: null,
        priceOplataPrzejsciowa: null,
        priceOplataSieciowa: null,

        onInit: (function () {
            this.get('store').unloadAll('distributionRateSimplified');
            this.set('osd', this.get('ppeShowController.ppeForm.formData.osd'));
            this.set('tariff', this.get('ppeShowController.ppeForm.formData.tariff'));
            this.set('priceOplataAbonamentowa', this.get('ppeShowController.model.priceOplataAbonamentowa'));
            this.set('priceOplataPrzejsciowa', this.get('ppeShowController.model.priceOplataPrzejsciowa'));
            this.set('priceOplataSieciowa', this.get('ppeShowController.model.priceOplataSieciowa'));
            this.send('search');
        }).on('init'),

        ppeModelChanged: (function () {
            this.get('store').unloadAll('distributionRateSimplified');
            this.set('priceOplataAbonamentowa', this.get('ppeShowController.model.priceOplataAbonamentowa'));
            this.set('priceOplataPrzejsciowa', this.get('ppeShowController.model.priceOplataPrzejsciowa'));
            this.set('priceOplataSieciowa', this.get('ppeShowController.model.priceOplataSieciowa'));
            this.send('search');
        }).observes('ppeShowController.model'),

        ppeFormChanged: (function () {
            this.set('osd', this.get('ppeShowController.ppeForm.formData.osd'));
            this.set('tariff', this.get('ppeShowController.ppeForm.formData.tariff'));
            this.send('search');
        }).observes('ppeShowController.ppeForm.formData.osd', 'ppeShowController.ppeForm.formData.tariff'),

        actions: {
            search: function search() {

                this.get('store').unloadAll('distributionRateSimplified');

                var _this = this;

                var params = {
                    osd: this.get('osd.id'),
                    tariff: this.get('tariff.id'),
                    oplataAbonamentowa: this.get('priceOplataAbonamentowa'),
                    oplataPrzejsciowa: this.get('priceOplataPrzejsciowa'),
                    oplataSieciowa: this.get('priceOplataSieciowa')
                };

                var queryString = $.param(params);
                var promise = this.get('ajax').ajaxGet(_emberNewConfigEnvironment["default"].apiUrl + '/rest/distributionratesimplifieds?' + queryString);

                promise.then(function (response) {
                    _this.get('store').pushPayload(response);
                }, function (response) {});
            },

            select: function select(distributionRateId) {

                var _this = this;

                this.get('store').find('eeDistributionRate', distributionRateId).then(function (item) {
                    _this.set('ppeShowController.ppeForm.formData.tariff', item.get('tariff'));
                    _this.set('ppeShowController.ppeForm.formData.osd', item.get('osd'));
                    _this.set('ppeShowController.ppeForm.formData.oplataPrzejsciowa', item.get('oplataPrzejsciowa'));
                    _this.set('ppeShowController.ppeForm.formData.oplataAbonamentowa', item.get('oplataAbonamentowa'));
                    _this.set('ppeShowController.ppeForm.formData.oplataSieciowa', item.get('oplataSieciowa'));
                    //_this.set('ppeShowController.ppeForm.formData.distributionRate', item);
                    _this.transitionToRoute('individual-contract.show-ee.ppm');
                });
            }
        }
    });
});
define('ember-new/components/tab-content-for-form', ['exports', 'ember', 'ember-new/mixins/controllers/unsaved-data'], function (exports, _ember, _emberNewMixinsControllersUnsavedData) {
    exports['default'] = _ember['default'].Component.extend({
        editState: false,
        isAllowedEdit: false,
        isAllowedDelete: false,
        parentController: null,
        detailsLink: false,

        // target - obiekt ktory edytujemy - wykorzystywane do sprawdzania
        // czy obejkt byl zmieniany (po kliknieciu Anuluj robi rollback)
        target: null,

        editButtonText: (function () {
            return this.get('editState') ? 'Anuluj edycję' : 'Edytuj';
        }).property('editState'),
        buttonClasses: (function () {
            return this.get('editState') ? 'btn btn-sm btn-warning' : 'btn btn-sm btn-primary';
        }).property('editState'),

        rollbackUnsavedChanges: _ember['default'].observer('editState', function () {
            console.log('editstate changed... ');
        }),

        actions: {
            edit: function edit() {
                var target = this.get('target');

                // Jezeli kliknieto "Anuluj" i są zmiany w formularzu, nie zapisuj ich (w modelu/target)
                if (target && this.get('editState') && target.get('hasDirtyAttributes')) {
                    target.rollback();
                }

                this.set('editState', !this.get('editState'));

                if (_emberNewMixinsControllersUnsavedData['default'].detect(this.get('targetObject'))) {
                    this.get('targetObject').removeLock(this);
                }
            },

            'delete': function _delete() {
                if (this.get('targetObject')) {
                    this.get('targetObject')['delete']();
                }
            }
        }
    });
});
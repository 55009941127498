define('ember-new/components/d-form/individual-contract/additional-address', ['exports', 'ember', 'ember-new/components/d-form', 'ember-new/templates/components/d-form', 'ember-new/config/environment'], function (exports, _ember, _emberNewComponentsDForm, _emberNewTemplatesComponentsDForm, _emberNewConfigEnvironment) {
    exports['default'] = _emberNewComponentsDForm['default'].extend({
        layout: _emberNewTemplatesComponentsDForm['default'],

        contractId: null,
        type: null,

        submitRestAsSimple: true,
        submitModelNamespace: 'address',
        submitRestAsSimpleMethod: 'PATCH',

        submitSimpleUrl: (function () {
            return _emberNewConfigEnvironment['default'].apiUrl + '/rest/individual' + this.get('type') + 'contracts/' + this.get('contractId') + '/client/additional/address';
        }).property(),

        _copyDataFromFormToModel: function _copyDataFromFormToModel() {

            var model = this.get('model');
            var fields = this.get('fields');
            var formData = this.get('formData');
            var _this = this;
            var form = this;

            Object.keys(fields).forEach(function (key) {
                var value = formData.get(key);
                model.set(key, value);
            });
        },

        _getModelFieldsPayload: function _getModelFieldsPayload() {
            var keys = this.get('_modelFieldsKeys');
            var payload = {};
            var formData = this.get('formData');
            var form = this;

            keys.forEach(function (key) {

                var value = null;

                if (form.hasTransformerFor(key)) {
                    var transformer = form.getTransformerFor(key);
                    value = transformer.reverseTransform.call(form, formData.get(key));
                } else {
                    value = formData.get(key);
                }

                payload[key] = value;
            });

            return payload;
        }

    });
});
define("ember-new/components/d-form/b2b-contract/dzu/add-gas-contract-ppg", ["exports", "ember-new/components/d-form-command", "ember-new/templates/components/d-form", "ember-new/mixins/b2b-contract/controllers/reload-contract-header", "ember-new/mixins/b2b-contract/components/d-form/tariffs/gas-tariffs", "ember-new/mixins/b2b-contract/components/d-form/osd-os-observers-gas", "ember-new/mixins/b2b-contract/components/d-form/excises-sum"], function (exports, _emberNewComponentsDFormCommand, _emberNewTemplatesComponentsDForm, _emberNewMixinsB2bContractControllersReloadContractHeader, _emberNewMixinsB2bContractComponentsDFormTariffsGasTariffs, _emberNewMixinsB2bContractComponentsDFormOsdOsObserversGas, _emberNewMixinsB2bContractComponentsDFormExcisesSum) {
    exports["default"] = _emberNewComponentsDFormCommand["default"].extend(_emberNewMixinsB2bContractControllersReloadContractHeader["default"], _emberNewMixinsB2bContractComponentsDFormTariffsGasTariffs["default"], _emberNewMixinsB2bContractComponentsDFormOsdOsObserversGas["default"], _emberNewMixinsB2bContractComponentsDFormExcisesSum["default"], {
        layout: _emberNewTemplatesComponentsDForm["default"],

        commandName: 'AddPpgToGasContractDzuCommand',

        loyaltyEndFieldValidation: (function () {

            var loyalty = undefined;
            var loyaltyEnd = undefined;
            var controller = undefined;

            controller = this.get('targetObject');
            loyalty = this.get('formData.loyalty');
            loyaltyEnd = this.get('formRows.loyaltyEnd');

            if (loyalty === '57d93329e771de54348b4576' && loyaltyEnd !== undefined && loyaltyEnd !== null && loyaltyEnd !== false && loyaltyEnd !== '') {
                controller.set('isLoyaltyEndFieldDisabled', false);
            } else if (loyalty !== '57d93329e771de54348b4576' && loyaltyEnd !== undefined && loyaltyEnd !== null && loyaltyEnd !== false && loyaltyEnd !== '') {
                controller.set('isLoyaltyEndFieldDisabled', true);
            }
        }).observes('formData.loyalty'),

        numberOfMonthsCounter: (function () {
            var form = this.get('formData');
            var startDate = null;
            var months = undefined;
            var endDate = new Date(form.get('endDate'));
            var controller = this.get('targetObject');
            var monthCounterType = controller.get('monthCounterType');
            var operation = undefined;

            if (form.get('startDate') !== undefined && form.get('startDate') !== null && form.get('startDate') !== false) {
                startDate = new Date(form.get('startDate'));
            }

            if (startDate && endDate && (monthCounterType === null || monthCounterType === 'onlyFullMonths')) {
                operation = startDate.getMonth() + 1;
            } else if (startDate && endDate && monthCounterType === 'firstOrLastMonthIncluded') {
                operation = startDate.getMonth();
            } else if (startDate && endDate && monthCounterType === 'firstAndLastMonthIncluded') {
                operation = startDate.getMonth() - 1;
            } else {}

            if (startDate && endDate) {
                months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
                months -= operation;
                months += endDate.getMonth();
                form.set('numberOfMonths', months);
            }
        }).observes('formData.endDate'),

        /** Zmiana nastąpiła w tasku DCRM-7486-walidacja-gaz **/
        // sellerChangeOptions: function () {
        //     if (this.get('formData.sellerChange') === 'a1579e40-b192-4143-b97a-b73665646f3e') {
        //         if (this.get('formData.os') === 'c471c120-c036-4aca-9137-31ff8f7bb8a8') {
        //             this.set('formData.osd', '35c4a189-6253-4d6d-8416-58c1586ef0e7');
        //         } else if(this.get('formData.os') === 'dc22af69-ad47-4532-8b83-775c5c2ee484') {
        //             this.set('formData.osd', '8e15cb85-c621-4fc2-82f1-c26f0aa2cb57');
        //         } else if (this.get('formData.os') === '52bd662f-90c8-4719-b7c2-aa757d7dd4cd') {
        //             this.set('formData.osd', 'ad28acfc-3962-4369-abc2-f5d7fd8539b4');
        //         } else if (this.get('formData.os') === 'a20ac51d-3a4b-46f4-8904-95be3a212051') {
        //             this.set('formData.osd', 'bcdfcbc1-991c-49d7-b3a2-7697ffa68fe7');
        //         } else if (this.get('formData.os') === 'fc37b6ff-df03-4fff-b670-d7879af95f10') {
        //             this.set('formData.osd', 'da87f665-32fd-44a8-b977-260fab32bf7e');
        //         } else if (this.get('formData.os') === '43c86a67-0f40-4d2c-a055-2ac358f8a3e1') {
        //             this.set('formData.osd', 'ed58a90a-1f18-45cf-972e-fefc5018c5b2');
        //         }
        //     }
        //
        // }.observes('formData.sellerChange', 'formData.os'),

        afterSuccessfulSubmit: function afterSuccessfulSubmit() {
            var targetController = this.get('targetObject');
            var mainController = targetController.container.lookup('controller:b2b-contract.dzu.show-gas.ppg');
            var mainRoute = mainController.get('target');
            this.reloadContractHeader();
            mainRoute.send('refreshModel');
            setTimeout(function () {
                targetController.transitionToRoute('b2b-contract.dzu.show-gas.ppg');
            }, 500);
        }
    });
});
define('ember-new/components/d-form/b2b-contract/documents/add-audio-recording', ['exports', 'ember', 'ember-new/components/d-form-command', 'ember-new/templates/components/d-form', 'ember-new/config/environment'], function (exports, _ember, _emberNewComponentsDFormCommand, _emberNewTemplatesComponentsDForm, _emberNewConfigEnvironment) {
    exports['default'] = _emberNewComponentsDFormCommand['default'].extend({
        layout: _emberNewTemplatesComponentsDForm['default'],

        clearFeedback: function clearFeedback() {
            this._super();
            this.get('targetObject').set('info', []);
        },

        resolveContents: function resolveContents(form) {
            return new _ember['default'].RSVP.Promise(function (resolve, reject) {

                var store = form.get('store');
                var upload = store.peekAll('upload');
                _ember['default'].RSVP.Promise.all([upload]).then(function (result) {
                    var upload = result[0];

                    resolve(_ember['default'].Object.create({
                        recordingUuid: upload
                    }));
                });
            });
        },

        configure: function configure() {
            this.registerTransformer('recordingUuid', function (uuid) {
                return uuid;
            }, function (file) {
                return file;
            });
        }

    });
});
define("ember-new/routes/b2b-contract/dzu/show-ee/remove-ppe", ["exports", "ember-new/routes/authenticated"], function (exports, _emberNewRoutesAuthenticated) {
    exports["default"] = _emberNewRoutesAuthenticated["default"].extend({
        model: function model(params) {
            var ppe = params.ppeUuid;
            return ppe;
        },

        setupController: function setupController(controller, model) {
            controller.set("ppe", model);
        }
    });
});
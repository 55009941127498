define('ember-new/components/data-table', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        tableId: null, // id tabeli
        firstColFixed: false, // przyklejona pierwsza kolumna
        lastColFixed: false, // przyklejona ostatnia kolumna
        extraWidthAdded: false,

        didInsertElement: function didInsertElement() {
            this.checkTableId();
        },

        // jezeli nie podano ustawia automatycznie tableId
        checkTableId: function checkTableId() {
            if (this.get('tableId') === null) this.set('tableId', this.elementId + '-data-table');
        },

        // Przelicza wysokość przyklejonych kolumn
        calculateTableCells: (function () {
            this._super.apply(this, arguments);

            var self = this;
            var table = this.$('#' + self.get('tableId')),
                tableBody = table.find('tbody'),
                tableHead = table.find('thead'),
                parentWidth = table.parent().width(),
                extraWidth = 35; // tymczawsowy fix na potrzeby filtrow,
            // TODO: przeliczac szerokosc kolumn dynamicznie po zmianie filtru

            // ustawienie szerokosci dla kazdej komroki w naglowku tabeli [TH]
            // na taka samą szerokość jak komorka w wierszu poniezej
            tableHead.find('th').each(function (index, thElem) {
                var td = tableBody.find('tr:first-child td:eq(' + index + ')'),
                    th = $(thElem);
                var thWidth = undefined,
                    tdWidth = undefined;

                // zapobiega ciaglemu dodawaniu szerokosci przy zmianie orientacji urzadzenia
                if (self.get('extraWidthAdded')) {
                    thWidth = th.outerWidth();
                    tdWidth = td.outerWidth();
                } else {
                    thWidth = th.outerWidth() + extraWidth;
                    tdWidth = td.outerWidth() + extraWidth;
                    self.set('extraWidthAdded', true);
                }

                // glupio to wyglada...
                // ale dziala. w przypadku tabel z metoda width(), outerWidth() wystepują problemy
                // to samo, jezeli chodzi o "border-collapese"
                // https://stackoverflow.com/questions/13280798/why-is-the-javascript-width-of-a-table-cell-one-pixel-too-short
                // dlatego robimy to w ten sposob: ~lyczos
                if (tdWidth > thWidth) {
                    th.css({ 'min-width': tdWidth, 'max-width': tdWidth });
                    td.css({ 'min-width': tdWidth, 'max-width': tdWidth });
                } else {
                    td.css({ 'min-width': thWidth, 'max-width': thWidth });
                    th.css({ 'min-width': thWidth, 'max-width': thWidth });
                }
            });

            // jezeli szerokosc zawartosci tabeli jest mniejsza/rowna szerokosci containera
            // nie dokonuj dodatkowych obliczen
            if (tableBody[0].scrollWidth < parentWidth) {
                return;
            }

            // ustawienie szerokosci na potrzeby scrolla horizontal
            tableBody.width(parentWidth);
            tableHead.width(parentWidth);

            this.makeTableScrollable(table);
        }).on('didRender'),

        // przy evencie scroll'a wykonuje obliczenia ustawiające
        makeTableScrollable: function makeTableScrollable(tableJQueryObject) {
            var self = this;
            // pierwsze wywolanie po wczytaniu komponentu
            self.calculateTdPosition();
            var tableBody = tableJQueryObject.find('tbody');
            // wywoluje przy evencie przewijania
            tableBody.scroll(function () {
                self.calculateTdPosition();
            });
        },

        calculateTdPosition: (function () {
            var self = this;
            var table = this.$('#' + self.get('tableId')),
                tableBody = table.find('tbody'),
                tableHead = table.find('thead'),
                tableBodyScrollWidth = tableBody[0].scrollWidth,
                tableBodyWidth = tableBody.width(),
                tableBodyScrollLeft = tableBody.scrollLeft();

            // tdPos:
            // scrollWidth - szerokosc calego scrolla
            // width - szerokosc widocznego obszaru tabeli
            // scrollLeft - wartosc przesuniecia suwaka
            var tdPos = tableBodyScrollWidth - tableBodyWidth - tableBodyScrollLeft,

            // sprawdza czy aktualna pozycja scrolla niewykracza poza szerokosc tabeli
            // dzieki temu nie dopuszczamy do afektu przesunięcia komorek naglowka względem komorek body
            allowScroll = tableBodyWidth + tableBodyScrollLeft < tableBodyScrollWidth;

            // fix dla efektu przeskakiwania
            if (tdPos <= 0 && allowScroll) return;

            tableHead.css("left", -tableBody.scrollLeft()); // thead - ustawienie pozycji relatywnie do scroll'a tbody
            tableHead.find('th:nth-last-child(1)').css("right", tdPos); // ustawienie pozycji dla ostatnej komorki naglowka
            tableHead.find('td:nth-last-child(1)').css("right", tdPos); // ustawienie pozycji dla ostatnej komorki naglowka (filtry w odstąpieniach)
            tableBody.find('td:nth-last-child(1)').css("right", tdPos); // ustawienie pozycji dla ostatnej komorki tbody
        }).on('didRender'),

        initResizeObserver: (function () {
            _ember['default'].$(window).on('resize', this.calculateTableCells.bind(this));
        }).on('init'),

        willDestroyElement: function willDestroyElement() {
            this._super.apply(this, arguments);
            this.$(window).off("resize");
        }

    });
});
define('ember-new/serializers/amb-business-client-serializer', ['exports', 'ember', 'ember-new/mixins/client-serializer'], function (exports, _ember, _emberNewMixinsClientSerializer) {

    var AmbBusinessClientSerializerMixin = _ember['default'].Mixin.create(_emberNewMixinsClientSerializer['default'], {

        DocumentsInit: (function () {
            this.addAttrsConfig({
                documentPorozumienieGazPrad: { serialize: 'id', deserialize: 'records' },
                documentPorozumienieGazPradZdjecie: { serialize: 'id', deserialize: 'records' },
                documentVoucher: { serialize: 'id', deserialize: 'records' }
            });
        }).on('init')
    });

    exports['default'] = AmbBusinessClientSerializerMixin;
});
define('ember-new/mixins/controllers/helpers/form-processing-controller', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
    exports['default'] = _ember['default'].Mixin.create({

        submitResult: null,
        submitResultType: null,
        isSubmitting: false,
        isValid: true,
        canSubmit: true,
        fieldsNeedFeedback: [],
        formFeedback: [],
        textSuccess: 'Zapisano!',
        textNotValid: 'Oops! Coś jest niepoprawne.',

        initializeFormFeedback: (function () {

            var fieldsNeedFeedback = this.get('fieldsNeedFeedback');

            if (!(fieldsNeedFeedback instanceof Array)) {
                throw new _ember['default'].Error("You need to specify `fieldsNeedFeedback` array to use `form-processing-controller` mixin");
            }

            var feedback = _ember['default'].Object.create();

            fieldsNeedFeedback.forEach(function (key) {

                if (key.indexOf('.') > 0) {
                    key = key.replace(/\./g, '::');
                }

                feedback.set(key, _ember['default'].Object.create({
                    type: null,
                    message: null
                }));
            });

            this.set('formFeedback', feedback);
        }).on('init'),
        handleSuccess: function handleSuccess(response) {
            this.set('isValid', true);
            this.set('isSubmitting', false);
            this.set('submitResult', this.get('textSuccess'));
            this.set('submitResultType', 'success');
        },
        handleNotValid: function handleNotValid(response) {
            this.set('isValid', false);
            this.set('isSubmitting', false);
            this.set('submitResult', this.get('textNotValid'));
            this.set('submitResultType', 'warning');

            this.transformResponseIntoFormFeedback(response);
        },
        transformResponseIntoFormFeedback: function transformResponseIntoFormFeedback(formErrors) {

            var fieldsErrors;
            var formFeedback = this.get('formFeedback');

            if (formErrors instanceof _emberData['default'].InvalidError) {

                if (formErrors.errors[0] === undefined) {
                    throw new _ember['default'].Error("No form-errors in formErrors.errors[0]");
                }

                fieldsErrors = formErrors.errors[0].detail;
            } else if (formErrors.errors && formErrors.errors.children && formErrors instanceof Object) {
                fieldsErrors = formErrors.errors.children;
            }

            if (fieldsErrors && fieldsErrors instanceof Object) {
                this.get('fieldsNeedFeedback').forEach(function (key) {

                    if (key.indexOf('.') > 0) {
                        key = key.replace(/\./g, '::');
                    }

                    var fieldError = false;

                    if (key.indexOf('::') > 0) {
                        var parts = key.split('::');
                        fieldError = fieldsErrors;

                        for (var i = 0; i < parts.length - 1; i++) {

                            var part = parts[i];

                            if (fieldError[part] && fieldError[part]['children'] && fieldError[part]['children'] instanceof Object) {
                                fieldError = fieldError[part].children;
                            } else {
                                fieldError = false;
                                i = 1000;
                                break;
                            }
                        }

                        var lastPart = parts[parts.length - 1];

                        if (fieldError[lastPart] && fieldError[lastPart] instanceof Object) {
                            fieldError = fieldError[lastPart];
                        }
                    } else {
                        if (fieldsErrors[key] && fieldsErrors[key] instanceof Object) {
                            fieldError = fieldsErrors[key];
                        }
                    }

                    if (fieldError !== false && fieldError.errors !== undefined && fieldError.errors instanceof Array && fieldError.errors.length > 0) {
                        var errorTxt = fieldError.errors[0];
                        formFeedback.set(key + '.type', 'error');
                        formFeedback.set(key + '.message', errorTxt);
                    }
                });
            }

            console.log("formFeedback", this.get('formFeedback'));
        },
        beforeSubmit: function beforeSubmit() {
            if (this.get('isSubmitting') === true || this.get('canSubmit') !== true) {
                return false;
            }

            this.clearFormResult();
            this.set('isSubmitting', true);
            return true;
        },
        clearFormResult: function clearFormResult() {
            this.set('isValid', true);
            this.set('isSubmitting', false);
            this.set('submitResult', null);
            this.set('submitResultType', null);
            this.initializeFormFeedback();
        }
    });
});
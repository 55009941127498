define('ember-new/controllers/b2b-contract/dzu/date-office-received-last', ['exports', 'ember', 'ember-new/config/environment'], function (exports, _ember, _emberNewConfigEnvironment) {
    exports['default'] = _ember['default'].Controller.extend({

        fillForm: function fillForm(form) {

            form.set('model.contractId', this.get('model.id'));
            form.set('model.dateOfficeReceivedLast', this.get('model.dateOfficeReceivedLastDocument'));
            form.set('model.userContent', null);
        },

        afterCommand: function afterCommand(response) {
            var ctrl = this;
            ctrl.transitionToRoute('b2b-contract.dzu');
            ctrl.get('target').send('refreshContractItemModel', ctrl.get('model.id'));
        }

    });
});
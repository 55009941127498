define("ember-new/mixins/routes/individual-contract/add-df", ["exports", "ember", "ember-new/config/environment"], function (exports, _ember, _emberNewConfigEnvironment) {
    exports["default"] = _ember["default"].Mixin.create({

        ajax: _ember["default"].inject.service('ajax'),

        model: function model(params) {
            var _this = this;
            var store = this.get('store');

            store.unloadAll('renouncement');

            if (params.pesel === 'null') {
                var model = store.createRecord('individual-ee-contract', {
                    client: store.createRecord('individual-client', {
                        foreigner: true,
                        baseAddress: store.createRecord('address'),
                        additionalAddress: store.createRecord('address')
                    }),
                    ppmAddress: store.createRecord('address'),
                    complex: params.complex
                });

                model.set('partner', _this.get('TheUser.emberModel.duonPartner'));
                model.set('creatorName', _this.get('TheUser.model.fullName'));

                return model;
            }

            var promiseModel = new _ember["default"].RSVP.Promise(function (resolve, reject) {

                var data = { form: { pesel: params.pesel, foreigner: params.foreigner } };
                var promise = _this.get('ajax').ajaxPost(_emberNewConfigEnvironment["default"].apiUrl + '/api/individual-client/find_client_by_pesel', data);

                promise.then(function (response) {
                    if (response.status === true) {

                        store.pushPayload(response.model);

                        var model = store.createRecord('individual-ee-contract', {
                            client: store.peekRecord('individual-client', response.model.individualClient.id),
                            ppmAddress: store.createRecord('address')
                        });
                    } else {
                        var model = store.createRecord('individual-ee-contract', {
                            client: store.createRecord('individual-client', {
                                pesel: params.pesel,
                                baseAddress: store.createRecord('address'),
                                additionalAddress: store.createRecord('address')
                            }),
                            ppmAddress: store.createRecord('address')
                        });
                    }

                    model.set('partner', _this.get('TheUser.emberModel.duonPartner'));
                    model.set('creatorName', _this.get('TheUser.model.fullName'));

                    resolve(model);
                });
            }, "LoadModelOnIndividualContractAddRoute");

            return promiseModel;
        },
        setupController: function setupController(controller, model) {
            controller.clear();
            controller.clearFormResult();
            controller.set('model', model);
        },
        actions: {
            chooseEeRenouncement: function chooseEeRenouncement(renouncement) {
                if (renouncement.get('canBeAssignedEe')) {
                    this.get('controller').set('chosenEeRenouncement', renouncement.get('id'));
                }
            },
            chooseGasRenouncement: function chooseGasRenouncement(renouncement) {
                if (renouncement.get('canBeAssignedGas')) {
                    this.get('controller').set('chosenGasRenouncement', renouncement.get('id'));
                }
            }
        }
    });
});
define("ember-new/templates/b2b-contract/dzu/show-gas/comments", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 11,
                  "column": 16
                },
                "end": {
                  "line": 13,
                  "column": 16
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/dzu/show-gas/comments.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "d-field-textarea", [], ["key", "userContent", "placeholder", "Treść komentarza"], ["loc", [null, [12, 20], [12, 89]]]]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 14,
                  "column": 16
                },
                "end": {
                  "line": 16,
                  "column": 16
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/dzu/show-gas/comments.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "d-field-select", [], ["key", "subtype", "content", ["subexpr", "@mut", [["get", "commentSubtypeDK", ["loc", [null, [15, 59], [15, 75]]]]], [], []], "prompt", "-- Wybierz odbiorcę --"], ["loc", [null, [15, 20], [15, 109]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 5,
                "column": 12
              },
              "end": {
                "line": 17,
                "column": 12
              }
            },
            "moduleName": "ember-new/templates/b2b-contract/dzu/show-gas/comments.hbs"
          },
          isEmpty: false,
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(6);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
            morphs[3] = dom.createMorphAt(fragment, 7, 7, contextualElement);
            morphs[4] = dom.createMorphAt(fragment, 9, 9, contextualElement);
            morphs[5] = dom.createMorphAt(fragment, 10, 10, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "d-field-text", [], ["type", "hidden", "key", "clientId"], ["loc", [null, [7, 16], [7, 61]]]], ["inline", "d-field-text", [], ["type", "hidden", "key", "contextId"], ["loc", [null, [8, 16], [8, 62]]]], ["inline", "d-field-text", [], ["type", "hidden", "key", "type"], ["loc", [null, [9, 16], [9, 57]]]], ["inline", "d-field-text", [], ["type", "hidden", "key", "subtype"], ["loc", [null, [10, 16], [10, 60]]]], ["block", "d-form-row", [], ["widthLabelClass", "", "widthFieldClass", "col-md-8 col-sm-8", "label", ""], 0, null, ["loc", [null, [11, 16], [13, 31]]]], ["block", "d-form-row", [], ["widthLabelClass", "", "widthFieldClass", "col-md-12 col-sm-12 custom-field-paddings", "label", "", "classNames", "form-group col-md-3 col-sm-3"], 1, null, ["loc", [null, [14, 16], [16, 31]]]]],
          locals: ["formData", "formContents"],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 8
            },
            "end": {
              "line": 18,
              "column": 8
            }
          },
          "moduleName": "ember-new/templates/b2b-contract/dzu/show-gas/comments.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "d-form/b2b-contract/dzu/add-gas-contract-comment", [], ["submitButtonText", "Dodaj komentarz", "fillCommandModel", ["subexpr", "@mut", [["get", "fillForm", ["loc", [null, [6, 80], [6, 88]]]]], [], []]], 0, null, ["loc", [null, [5, 12], [17, 65]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 25,
                  "column": 16
                },
                "end": {
                  "line": 26,
                  "column": 16
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/dzu/show-gas/comments.hbs"
            },
            isEmpty: true,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 30,
                  "column": 16
                },
                "end": {
                  "line": 32,
                  "column": 16
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/dzu/show-gas/comments.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "d-field-text", [], ["key", "subtype", "value", ["subexpr", "@mut", [["get", "value", ["loc", [null, [31, 55], [31, 60]]]]], [], []], "hidden", true], ["loc", [null, [31, 20], [31, 74]]]]],
            locals: ["value"],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 33,
                  "column": 16
                },
                "end": {
                  "line": 35,
                  "column": 16
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/dzu/show-gas/comments.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "d-field-textarea", [], ["key", "userContent", "placeholder", "Treść komentarza"], ["loc", [null, [34, 20], [34, 89]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 23,
                "column": 12
              },
              "end": {
                "line": 36,
                "column": 12
              }
            },
            "moduleName": "ember-new/templates/b2b-contract/dzu/show-gas/comments.hbs"
          },
          isEmpty: false,
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(6);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
            morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
            morphs[3] = dom.createMorphAt(fragment, 6, 6, contextualElement);
            morphs[4] = dom.createMorphAt(fragment, 8, 8, contextualElement);
            morphs[5] = dom.createMorphAt(fragment, 9, 9, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "box-for-form", [], ["title", "Komentarz do działu kompletującego"], 0, null, ["loc", [null, [25, 16], [26, 33]]]], ["inline", "d-field-text", [], ["type", "hidden", "key", "clientId"], ["loc", [null, [27, 16], [27, 61]]]], ["inline", "d-field-text", [], ["type", "hidden", "key", "contextId"], ["loc", [null, [28, 16], [28, 62]]]], ["inline", "d-field-text", [], ["type", "hidden", "key", "type"], ["loc", [null, [29, 16], [29, 57]]]], ["block", "d-form-row", [], ["hidden", true], 1, null, ["loc", [null, [30, 16], [32, 31]]]], ["block", "d-form-row", [], ["widthLabelClass", "", "widthFieldClass", "col-md-8 col-sm-8", "label", ""], 2, null, ["loc", [null, [33, 16], [35, 31]]]]],
          locals: ["formData", "formContents"],
          templates: [child0, child1, child2]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 22,
              "column": 8
            },
            "end": {
              "line": 37,
              "column": 8
            }
          },
          "moduleName": "ember-new/templates/b2b-contract/dzu/show-gas/comments.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "d-form/b2b-contract/dzu/add-gas-contract-comment", [], ["submitButtonText", "Dodaj komentarz", "fillCommandModel", ["subexpr", "@mut", [["get", "fillFormDr", ["loc", [null, [24, 80], [24, 90]]]]], [], []]], 0, null, ["loc", [null, [23, 12], [36, 65]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 50,
                  "column": 24
                },
                "end": {
                  "line": 52,
                  "column": 24
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/dzu/show-gas/comments.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["content", "comment.systemContent", ["loc", [null, [51, 28], [51, 53]]]]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 52,
                  "column": 24
                },
                "end": {
                  "line": 54,
                  "column": 24
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/dzu/show-gas/comments.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["content", "comment.userContent", ["loc", [null, [53, 28], [53, 51]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 44,
                "column": 20
              },
              "end": {
                "line": 55,
                "column": 20
              }
            },
            "moduleName": "ember-new/templates/b2b-contract/dzu/show-gas/comments.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "comment.isSystem", ["loc", [null, [50, 30], [50, 46]]]]], [], 0, 1, ["loc", [null, [50, 24], [54, 31]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 42,
              "column": 12
            },
            "end": {
              "line": 57,
              "column": 12
            }
          },
          "moduleName": "ember-new/templates/b2b-contract/dzu/show-gas/comments.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "col-md-7");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["block", "bt-comment", [], ["author", ["subexpr", "@mut", [["get", "comment.createdBy", ["loc", [null, [44, 41], [44, 58]]]]], [], []], "authorRole", ["subexpr", "@mut", [["get", "comment.userRole", ["loc", [null, [45, 45], [45, 61]]]]], [], []], "authorDept", ["subexpr", "@mut", [["get", "comment.subtype", ["loc", [null, [46, 45], [46, 60]]]]], [], []], "date", ["subexpr", "@mut", [["get", "comment.dateCreated", ["loc", [null, [47, 39], [47, 58]]]]], [], []], "dateFormat", "LL H:mm", "systemComment", ["subexpr", "@mut", [["get", "comment.isSystem", ["loc", [null, [49, 48], [49, 64]]]]], [], []]], 0, null, ["loc", [null, [44, 20], [55, 35]]]]],
        locals: ["comment"],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.3.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 60,
            "column": 6
          }
        },
        "moduleName": "ember-new/templates/b2b-contract/dzu/show-gas/comments.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-xs-12");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("jeśli zalogowany jest user DZU lub ADMIN");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("jeśli zalogowany jest user DZU2");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "row");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(element0, 3, 3);
        morphs[1] = dom.createMorphAt(element0, 7, 7);
        morphs[2] = dom.createMorphAt(dom.childAt(element0, [9]), 1, 1);
        return morphs;
      },
      statements: [["block", "if", [["get", "dk", ["loc", [null, [4, 14], [4, 16]]]]], [], 0, null, ["loc", [null, [4, 8], [18, 15]]]], ["block", "if", [["get", "dr", ["loc", [null, [22, 14], [22, 16]]]]], [], 1, null, ["loc", [null, [22, 8], [37, 15]]]], ["block", "each", [["get", "model", ["loc", [null, [42, 20], [42, 25]]]]], [], 2, null, ["loc", [null, [42, 12], [57, 21]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});
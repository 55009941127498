define('ember-new/helpers/format-date-pl', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Helper.helper(function (datetimeStr) {

        var date = datetimeStr[0];

        if (date === null || date === undefined || typeof date === 'string' && date.length === 0) {
            return '-- brak daty --';
        }

        return moment(date).format('DD.MM.YYYY');
    });
});
define('ember-new/serializers/user', ['exports', 'ember-new/serializers/application'], function (exports, _emberNewSerializersApplication) {

    // User Serializer
    var UserSerializer = _emberNewSerializersApplication['default'].extend({
        attrs: {
            avatar: { serialize: 'id', deserialize: 'records' },
            assignations: { serialize: 'id', deserialize: 'records' }
        },
        serializeAttribute: function serializeAttribute(record, json, key, attribute) {

            if (this.modelFieldsToExclude !== undefined && this.modelFieldsToExclude.indexOf(attribute.name) >= 0 || record._attributesExcludeBySerializer !== undefined && record._attributesExcludeBySerializer.indexOf(attribute.name) >= 0) {
                if (record.get('id')) {
                    this._super(record, json, key, attribute, true);
                } else {
                    return false;
                }
            }
            this._super(record, json, key, attribute);
        }
    });

    exports['default'] = UserSerializer;
});
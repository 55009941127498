define("ember-new/templates/b2b-contract/dzu/partials/prices/value/ee/overhead-from-last-known-period", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.3.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 3,
            "column": 5
          }
        },
        "moduleName": "ember-new/templates/b2b-contract/dzu/partials/prices/value/ee/overhead-from-last-known-period.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("td");
        dom.setAttribute(el1, "colspan", "2");
        dom.setAttribute(el1, "class", "small-info-text");
        var el2 = dom.createTextNode("\n    Narzut / cena bazowa z ostatniego znanego okresu.\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  })());
});
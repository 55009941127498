define('ember-new/helpers/gte', ['exports', 'ember'], function (exports, _ember) {

  /**
   * Helper, który sprawdza czy drugi parametr jest większy lub równy od pierwszego
   * Uzycie: {{#if (gte number 1)}} .... {{/if}}
   * @param params do porównania
   */
  var gte = function gte(params) {
    return parseInt(params[0]) >= parseInt(params[1]);
  };
  exports['default'] = _ember['default'].Helper.helper(gte);
});
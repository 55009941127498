define('ember-new/mixins/individual-contract-price-lists/controllers/template', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Mixin.create({

        disableEndMonths: true,
        disableEndDate: true,
        disableDiscountPriceMonths: true,
        disableDiscountPriceDate: true,
        disableDiscountFeeMonths: true,
        disableDiscountFeeDate: true,
        disablePromoFeeType: false,

        afterCommand: function afterCommand(response) {
            var ctrl = this;
            var route = this.container.lookup('route:individual-contract-price-lists.template');
            route.refresh();
            setTimeout(function () {
                ctrl.transitionToRoute('individual-contract-price-lists.template');
            }, 500);
        },

        productContent: (function () {
            var products = [];
            this.get('products').forEach(function (product) {
                products.push({ label: product.get('name'), value: product.get('uuid') });
            });

            return products;
        }).property(),

        typeContent: [{ value: 'EE', label: 'EE' }, { value: 'GAS', label: 'GAZ' }],

        contractEndTypeContent: [{ value: 'FM', label: 'Liczba miesięcy' }, { value: 'DD', label: 'Do końca roku' }, { value: 'N', label: 'Na czas nieokreślony' }],

        promoPriceTypeContent: [{ value: 'FM', label: 'Liczba miesięcy od startu umowy' }, { value: 'DD', label: 'Do końca roku' }],

        promoFeeTypeContent: [{ value: 'FM', label: 'Liczba miesięcy od startu umowy' }, { value: 'DD', label: 'Do końca roku' }],

        months10To99: (function () {
            var values = [];
            for (var i = 10; i < 100; i++) {
                values.push({ label: i, value: i });
            }

            return values;
        }).property(),

        months0To99: (function () {
            var values = [];
            for (var i = 0; i < 100; i++) {
                values.push({ label: i, value: i + "" });
            }

            return values;
        }).property(),

        yearForTemplate: (function () {
            var values = [];
            var date = new Date();
            var year = date.getFullYear() - 1;
            var yearMax = year + 51;

            for (var i = year; i <= yearMax; i++) {
                values.push({ label: i, value: i });
            }

            return values;
        }).property(),

        hideEeTariffGroup: (function () {
            return this.get('type') !== 'EE';
        }).property('type'),

        hideGasTariffGroup: (function () {
            return this.get('type') !== 'GAS';
        }).property('type'),

        hideGasDiscountFeeMonths: (function () {
            return this.get('type') !== 'EE';
        }).property('type'),

        actions: {
            validateTemplateCode: function validateTemplateCode(code) {

                if (!code) {
                    this.set('notValidText', 'Podaj prawidłowy kod template');
                    return false;
                }

                var codeSplittedArray = code.split("_");
                if (!codeSplittedArray || !codeSplittedArray[1]) {
                    this.set('notValidText', 'Podaj prawidłowy kod template');
                    return false;
                }

                var tariffFromCode = codeSplittedArray[1];
                var tariffs = this.get('tariffs');
                if (!tariffs) {
                    return false;
                }

                var properTariff = tariffs.filter(function (tariff) {

                    return tariff.get('tariffName') === tariffFromCode;
                });

                if (properTariff.length === 0) {
                    this.set('notValidText', 'Podaj prawidłowy kod template');
                    return false;
                }
                var tariffType = properTariff[0].get('type');
                var regex = null;

                if (tariffType === 'EE') {
                    regex = /^.*(_.*)_cu([N]|\d{2}|\d{4})_(\d{1,2}|\d{4})M_(\d{1,2}|\d{4})OH$/i;
                } else if (tariffType === 'GAS') {
                    regex = /^.*(_.*)_cu([N]|\d{2}|\d{4})_(\d{1,2}|\d{4})M$/i;
                }

                var isValid = regex.test(code);
                if (isValid) {
                    this.set('validText', 'Kod Template poprawny.');
                    return true;
                } else {
                    this.set('notValidText', 'Podaj prawidłowy kod template');
                    return false;
                }
            },

            closeWindow: function closeWindow() {
                var ctrl = this;
                // odświeżanie dodane w afterCommand, więc tutaj komentuje
                /*let route = this.container.lookup('route:individual-contract-price-lists.template');
                 route.refresh();*/
                setTimeout(function () {
                    ctrl.transitionToRoute('individual-contract-price-lists.template');
                }, 500);
            }
        }
    });
});
define("ember-new/templates/components/entity-history-table", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 38,
                    "column": 36
                  },
                  "end": {
                    "line": 44,
                    "column": 36
                  }
                },
                "moduleName": "ember-new/templates/components/entity-history-table.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("tr");
                var el2 = dom.createTextNode("\n                                            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
                morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
                morphs[2] = dom.createMorphAt(dom.childAt(element0, [5]), 0, 0);
                return morphs;
              },
              statements: [["content", "attribute.attrHumanName", ["loc", [null, [40, 48], [40, 77]]]], ["content", "attribute.valueBefore", ["loc", [null, [41, 48], [41, 75]]]], ["content", "attribute.valueAfter", ["loc", [null, [42, 48], [42, 74]]]]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 37,
                  "column": 32
                },
                "end": {
                  "line": 45,
                  "column": 32
                }
              },
              "moduleName": "ember-new/templates/components/entity-history-table.hbs"
            },
            isEmpty: false,
            arity: 2,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "unless", [["subexpr", "eq", [["get", "index", ["loc", [null, [38, 50], [38, 55]]]], 0], [], ["loc", [null, [38, 46], [38, 58]]]]], [], 0, null, ["loc", [null, [38, 36], [44, 47]]]]],
            locals: ["attribute", "index"],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 28,
                "column": 28
              },
              "end": {
                "line": 46,
                "column": 28
              }
            },
            "moduleName": "ember-new/templates/components/entity-history-table.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            var el2 = dom.createTextNode("\n                                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var element2 = dom.childAt(element1, [1]);
            var element3 = dom.childAt(element1, [3]);
            var element4 = dom.childAt(element1, [5]);
            var morphs = new Array(10);
            morphs[0] = dom.createAttrMorph(element2, 'rowspan');
            morphs[1] = dom.createMorphAt(element2, 0, 0);
            morphs[2] = dom.createAttrMorph(element3, 'rowspan');
            morphs[3] = dom.createMorphAt(element3, 0, 0);
            morphs[4] = dom.createAttrMorph(element4, 'rowspan');
            morphs[5] = dom.createMorphAt(element4, 0, 0);
            morphs[6] = dom.createMorphAt(dom.childAt(element1, [7]), 0, 0);
            morphs[7] = dom.createMorphAt(dom.childAt(element1, [9]), 0, 0);
            morphs[8] = dom.createMorphAt(dom.childAt(element1, [11]), 0, 0);
            morphs[9] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["attribute", "rowspan", ["concat", [["get", "change.changedAttributes.length", ["loc", [null, [30, 52], [30, 83]]]]]]], ["content", "change.datetime", ["loc", [null, [30, 88], [30, 109]]]], ["attribute", "rowspan", ["concat", [["get", "change.changedAttributes.length", ["loc", [null, [31, 52], [31, 83]]]]]]], ["content", "change.author", ["loc", [null, [31, 88], [31, 107]]]], ["attribute", "rowspan", ["concat", [["get", "change.changedAttributes.length", ["loc", [null, [32, 52], [32, 83]]]]]]], ["content", "change.entityHumanName", ["loc", [null, [32, 88], [32, 116]]]], ["content", "change.changedAttributes.0.attrHumanName", ["loc", [null, [33, 40], [33, 86]]]], ["content", "change.changedAttributes.0.valueBefore", ["loc", [null, [34, 40], [34, 84]]]], ["content", "change.changedAttributes.0.valueAfter", ["loc", [null, [35, 40], [35, 83]]]], ["block", "each", [["get", "change.changedAttributes", ["loc", [null, [37, 40], [37, 64]]]]], ["key", "@index"], 0, null, ["loc", [null, [37, 32], [45, 41]]]]],
          locals: ["change"],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 8
            },
            "end": {
              "line": 52,
              "column": 8
            }
          },
          "moduleName": "ember-new/templates/components/entity-history-table.hbs"
        },
        isEmpty: false,
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "filters-area text-center");
          dom.setAttribute(el1, "id", "historyAccordion");
          dom.setAttribute(el1, "role", "tablist");
          dom.setAttribute(el1, "aria-multiselectable", "true");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "panel panel-primary");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "panel-heading");
          dom.setAttribute(el3, "role", "tab");
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5, "role", "button");
          dom.setAttribute(el5, "data-toggle", "collapse");
          dom.setAttribute(el5, "aria-expanded", "false");
          dom.setAttribute(el5, "class", "d-block accordion-toggle");
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "panel-collapse collapse filters-area");
          dom.setAttribute(el3, "role", "tabpanel");
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("table");
          dom.setAttribute(el4, "class", "table table-bordered dataTable");
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("thead");
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("tr");
          var el7 = dom.createTextNode("\n                                ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("th");
          var el8 = dom.createTextNode("Data i godzina");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                                ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("th");
          var el8 = dom.createTextNode("Autor");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                                ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("th");
          var el8 = dom.createTextNode("Encja");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                                ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("th");
          var el8 = dom.createTextNode("Pole");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                                ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("th");
          var el8 = dom.createTextNode("Zmiana Z");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                                ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("th");
          var el8 = dom.createTextNode("Zmiana Na");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("tbody");
          var el6 = dom.createTextNode("\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1, 1]);
          var element6 = dom.childAt(element5, [1]);
          var element7 = dom.childAt(element6, [1, 1]);
          var element8 = dom.childAt(element5, [3]);
          var morphs = new Array(8);
          morphs[0] = dom.createAttrMorph(element6, 'id');
          morphs[1] = dom.createAttrMorph(element7, 'data-parent');
          morphs[2] = dom.createAttrMorph(element7, 'href');
          morphs[3] = dom.createAttrMorph(element7, 'aria-controls');
          morphs[4] = dom.createMorphAt(element7, 1, 1);
          morphs[5] = dom.createAttrMorph(element8, 'id');
          morphs[6] = dom.createAttrMorph(element8, 'aria-labelledby');
          morphs[7] = dom.createMorphAt(dom.childAt(element8, [1, 3]), 1, 1);
          return morphs;
        },
        statements: [["attribute", "id", ["concat", ["historyHeading", ["get", "index", ["loc", [null, [6, 78], [6, 83]]]]]]], ["attribute", "data-parent", ["concat", ["#historyAccordion", ["get", "index", ["loc", [null, [8, 102], [8, 107]]]]]]], ["attribute", "href", ["concat", ["#historySection", ["get", "index", ["loc", [null, [8, 134], [8, 139]]]]]]], ["attribute", "aria-controls", ["concat", ["historySection", ["get", "index", ["loc", [null, [9, 84], [9, 89]]]]]]], ["content", "date.date", ["loc", [null, [10, 32], [10, 47]]]], ["attribute", "id", ["concat", ["historySection", ["get", "index", ["loc", [null, [14, 45], [14, 50]]]]]]], ["attribute", "aria-labelledby", ["concat", ["historyHeading", ["get", "index", ["loc", [null, [15, 58], [15, 63]]]]]]], ["block", "each", [["get", "date.changes", ["loc", [null, [28, 36], [28, 48]]]]], [], 0, null, ["loc", [null, [28, 28], [46, 37]]]]],
        locals: ["date", "index"],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 52,
              "column": 8
            },
            "end": {
              "line": 56,
              "column": 8
            }
          },
          "moduleName": "ember-new/templates/components/entity-history-table.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "alert alert-warning text-center");
          var el2 = dom.createTextNode("\n                Historia zmian jest pusta\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.3.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 58,
            "column": 6
          }
        },
        "moduleName": "ember-new/templates/components/entity-history-table.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-md-12");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 1]), 1, 1);
        return morphs;
      },
      statements: [["block", "each", [["get", "data", ["loc", [null, [3, 16], [3, 20]]]]], ["key", "@index"], 0, 1, ["loc", [null, [3, 8], [56, 17]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});
define('ember-new/controllers/tasks/task/move', ['exports', 'ember-new/config/environment', 'ember-new/controllers/abstract-task-modal', 'ember'], function (exports, _emberNewConfigEnvironment, _emberNewControllersAbstractTaskModal, _ember) {
	exports['default'] = _emberNewControllersAbstractTaskModal['default'].extend({
		queryParams: ['taskUuid'],
		taskUuid: null,
		needs: ['tasks'],
		actions: {
			saveAction: function saveAction() {
				this.set('savingInProgress', 'loading');

				var _this = this;
				var url = _emberNewConfigEnvironment['default'].apiUrl + '/rest/tasks/' + _this.get('model').get('id') + '/move';

				var ajaxPromise = $.ajax({
					url: url,
					type: 'PATCH',
					dataType: 'json',
					data: data
				});

				ajaxPromise.then(function (response, textStatus, xhr) {
					_this.set('savingInProgress', null);
					_this.store.pushPayload(response);
					_this.transitionToRoute('tasks');
				}, function (jqXHR) {
					handleAjaxError(jqXHR, function () {
						_this.set('cerrors', _ember['default'].Object.create(jqXHR.responseJSON.errors.children));
						_this.set('savingInProgress', null);
					});
				});
			},

			closeAction: function closeAction() {
				this.transitionToRoute('tasks');
			}
		},
		// bools
		isMeeting: false,
		// options
		durations: [],
		cerrors: [],
		// values
		moveDate: null,
		moveDuration: null,
		comment: null,

		// inits & observes
		myInit: (function () {
			//this.initDurations();
		}).on('init'),
		modelChanged: (function () {
			if (moment(this.get('moveDate')).isAfter(moment(new Date()).subtract(2, 'days'))) {
				this.set('moveDate', this.get('model').get('time'));
			} else {
				this.set('moveDate', moment(new Date()));
			}

			if (this.get('model').get('type') == 'meeting') {
				this.set('isMeeting', true);
			} else {
				this.set('isMeeting', false);
			}
		}).observes('model'),

		durationChanged: (function () {
			//if (this.get('taskModel')) {
			this.set('duration', this.get('durationObj.int'));
			//}
		}).observes('durationObj'),

		fillForm: function fillForm(form) {
			form.set('model.taskId', this.get('taskUuid'));
			form.set('model.time', null);
			form.set('model.comment', null);
		},

		afterCommand: function afterCommand() {
			var ctrl = this;
			ctrl.transitionToRoute('tasks');
			var route = this.container.lookup('route:tasks');
			route.refresh();
		}

	});
});
define('ember-new/mixins/individual-contract/show/sells', ['exports', 'ember', 'ember-new/mixins/controllers/unsaved-data'], function (exports, _ember, _emberNewMixinsControllersUnsavedData) {
    exports['default'] = _ember['default'].Mixin.create(_emberNewMixinsControllersUnsavedData['default'], {

        ajax: _ember['default'].inject.service('ajax'),

        name: null,
        activeMenuItem: (function () {
            return this.get('name') + '.show.sells';
        }).property('name'),

        bulbsAmount: [{ label: 1, value: 1 }, { label: 2, value: 2 }, { label: 3, value: 3 }, { label: 4, value: 4 }, { label: 5, value: 5 }, { label: 6, value: 6 }],

        setIsPikaActive: (function () {
            var model = this.get('model');

            if (!model || !model._internalModel) {
                return;
            }

            if (model.get('productPikaText') || model.get('kindOfBulbsPikaText') || model.get('productLinePikaText') || model.get('variantPikaText')) {
                model.set('isPikaSellsActive', true);
            } else {
                model.set('isPikaSellsActive', false);
            }
        }).observes('model'),

        productContent: (function () {
            var products = [];
            var allProducts = this.get('products');
            if (allProducts) {
                allProducts.forEach(function (product) {
                    products.push({ label: product.get('name'), value: product.get('uuid') });
                });

                return products;
            }
        }).property(),

        eeTariffContent: (function () {
            var tariffs = [];
            var tariffGroups = this.get('tariffGroups');
            if (tariffGroups) {
                tariffGroups.forEach(function (tariffGroup) {
                    if (tariffGroup.get('type') === 'EE') {
                        tariffs.push({ label: tariffGroup.get('name'), value: tariffGroup.get('uuid') });
                    }
                });
                return tariffs;
            }
        }).property(),

        gasTariffContent: (function () {
            var tariffs = [];
            var tariffGroups = this.get('tariffGroups');
            if (tariffGroups) {
                tariffGroups.forEach(function (tariffGroup) {
                    if (tariffGroup.get('type') === 'GAS') {
                        tariffs.push({ label: tariffGroup.get('name'), value: tariffGroup.get('uuid') });
                    }
                });
                return tariffs;
            }
        }).property(),

        actions: {

            showRenouncement: function showRenouncement(id) {
                var store = this.get('store');
                store.unloadRecord(store.peekRecord('renouncement', id));
                this.transitionToRoute('renouncement.show', id);
            },

            addRenouncement: function addRenouncement() {
                var type = this.getContractType();
                var route = 'individual-contract.show-' + type + '.sells.add-renouncement';
                this.transitionToRoute(route);
            },

            searchRenouncement: function searchRenouncement() {
                var type = this.getContractType();
                var route = 'individual-contract.show-' + type + '.sells.search-renouncement';
                this.transitionToRoute(route);
            },

            unassignRenouncement: function unassignRenouncement(id) {
                var contractType = this.getContractType();
                var route = 'individual-contract.show-' + contractType + '.sells.unassign-renouncement';
                this.transitionToRoute(route, id);
            }
        },

        getContractType: function getContractType() {
            var model = this.get('model');
            var modelName = model._internalModel.modelName;
            var type = null;

            if (modelName == 'individual-ee-contract') {
                type = 'ee';
            } else if (modelName == 'individual-gas-contract') {
                type = 'gas';
            }

            return type;
        },

        isEditable: (function () {
            var editSells = this.get('model.credentials.editSells');
            var isAnonymized = this.get('model.isAnonymized');
            return editSells && !isAnonymized;
        }).property()

    });
});
define('ember-new/controllers/b2b-contract/dzu/show-ee/client/add-contact-person', ['exports', 'ember', 'ember-new/mixins/b2b-contract/controllers/reload-contract-header'], function (exports, _ember, _emberNewMixinsB2bContractControllersReloadContractHeader) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsB2bContractControllersReloadContractHeader['default'], {

        fillForm: function fillForm(form) {

            form.set('model.contractId', this.get('idContract'));
            form.set('model.contactPersonFirstName', null);
            form.set('model.contactPersonLastName', null);
            form.set('model.contactPersonEmail', null);
            form.set('model.contactPersonPhoneMobile', null);
            form.set('model.contactPersonPhoneLandLine', null);
        },

        afterCommand: function afterCommand() {
            var ctrl = this;
            var route = this.container.lookup("route:b2b-contract.dzu.show-ee.client");
            route.refresh();
            this.reloadContractHeader();
            setTimeout(function () {
                ctrl.transitionToRoute('b2b-contract.dzu.show-ee.client');
            }, 500);
        }

    });
});
/*
 * @author Paweł Roguski <pawel.roguski@fortum.com>
 */
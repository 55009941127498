define('ember-new/models/individual-contract-simplified', ['exports', 'ember-data', 'ember-new/models/abstract'], function (exports, _emberData, _emberNewModelsAbstract) {

    var attr = _emberData['default'].attr;
    var refOne = _emberData['default'].belongsTo;

    exports['default'] = _emberNewModelsAbstract['default'].extend({
        conId: attr('string'),
        conType: attr('string'),
        conIsInDualFuel: attr('boolean'),
        conDzuSpecialist: attr('string'),
        conTpaSpecialist: attr('string'),
        conSalesman: attr('string'),
        conSalesmanAcquired: attr('string'),
        cliClientInfo: attr(),
        conContractNo: attr('string'),
        conContractCategory: attr('string'),
        conDateCreated: attr('string'),
        conDateSigned: attr('string'),
        conDateStart: attr('string'),
        conDateVerification: attr('string'),
        conCounter: attr('string'),
        conVerificationCounter: attr('string'),
        conStatus: attr('string'),
        conStatusNumber: attr('string'),
        conStatusLastChangedDate: attr('string'),
        conRenouncementStatus: attr('string'),
        conVerificationCallType: attr('string'),
        conVerificationCallStatus: attr('string'),
        conVcRecordingStatus: attr('string'),
        conDateUsysGeneration: attr('string'),
        conTariffGroups: attr('string'),
        conPartnerStatus: attr('string'),
        conPartnerMainStatus: attr('string'),
        conBoMainStatus: attr('string'),

        conPikaMajorVersion: attr('string'),
        conDatePikaSynchronization: attr('string'),
        // conProduct: attr('string'),
        conAcceptDate: attr('string'),

        productLabel: attr('string'),

        conHasRenouncement: attr('boolean'),

        ppmPointNo: attr('string'),
        ppmCounterNo: attr('string'),

        isAnonymized: attr('boolean'),
        uuid: attr('string'),
        whichSellerChange: attr('string'),

        counterClass: (function () {
            if (this.get('conCounter') == '--') {
                return null;
            }
            return this.get('conCounter') == '>17' ? 'success' : 'danger';
        }).property('conCounter'),

        verificationCounterClass: (function () {
            if (this.get('conVerificationCounter') == '--') {
                return null;
            }
            return this.get('conCounter') == '>10' ? 'success' : 'danger';
        }).property('conVerificationCounter'),

        renouncementStatusClass: (function () {
            return this.get('conRenouncementStatus') == 'Skuteczne' ? 'danger' : 'primary';
        }).property('conRenouncementStatus'),

        vcRecordingStatus: attr('boolean'),

        isEe: (function () {
            return this.get('conType') === 'ee';
        }).property('conType'),
        isGas: (function () {
            return this.get('conType') === 'gas';
        }).property('conType'),

        allowGenerateUsys: (function () {

            return this.get('conStatusNumber') == '208' || this.get('conStatusNumber') == '208.1' || this.get('conStatusNumber') == '205.1' || this.get('conStatusNumber') == '205.2' || this.get('conStatusNumber') == '205.3';
        }).property('conStatusNumber'),

        isAllowedToAnonymized: (function () {
            return !this.get('isAnonymized') && this.get("TheUser").isGranted(['ROLE_GDPR_SPECIALIST']);
        }).property('isAllowedToAnonymized')
    });
});
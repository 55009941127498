define('ember-new/mixins/controllers/individual-contract-partners/bulk-actions', ['exports', 'ember'], function (exports, _ember) {
    var computed = _ember['default'].computed;
    var _Ember$inject = _ember['default'].inject;
    var service = _Ember$inject.service;
    var controller = _Ember$inject.controller;
    exports['default'] = _ember['default'].Mixin.create({
        bulkFiltered: controller('individual-contract-partners.bulk-filtered'),
        actions: {
            afterBulkAction: function afterBulkAction() {
                this.get('bulkFiltered').send('afterBulkAction');
            }
        }
    });
});
define('ember-new/models/business-ppe-mm-fuel', ['exports', 'ember-new/models/abstract'], function (exports, _emberNewModelsAbstract) {

    var number = DS.attr('number');

    exports['default'] = _emberNewModelsAbstract['default'].extend({
        o1: number,
        o2: number,
        o3: number,
        m1: number,
        m2: number,
        m3: number
    });
});
define('ember-new/models/contract-by-region-report-view-model', ['exports', 'ember-new/models/abstract', 'ember-data'], function (exports, _emberNewModelsAbstract, _emberData) {

    var attr = _emberData['default'].attr;

    exports['default'] = _emberNewModelsAbstract['default'].extend({
        fullName: attr('string'),
        region: attr('string'),
        contractsToCorrect: attr('string'),
        contractsCurrentlyApproved: attr('string'),
        contractsToCorrectMM: attr('string'),
        contractsCurrentlyApprovedMM: attr('string'),
        contractsDzu1: attr('string'),
        contractsDzu1MM: attr('string'),
        contractsDzu2: attr('string'),
        contractsDzu2MM: attr('string')
    });
});
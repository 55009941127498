define('ember-new/models/business-contract-ppms-list-dzu-view-model', ['exports', 'ember-new/models/abstract', 'ember-data', 'ember-new/mixins/base-document'], function (exports, _emberNewModelsAbstract, _emberData, _emberNewMixinsBaseDocument) {

    var string = _emberData['default'].attr('string');

    exports['default'] = _emberNewModelsAbstract['default'].extend(_emberNewMixinsBaseDocument['default'], {
        uuid: string,
        type: string,
        deviceNumber: string,
        pointNumber: string,
        street: string,
        streetNo: string,
        aptNo: string,
        postalCode: string,
        city: string,
        os: string,
        osd: string,
        tariffGroup: string,
        ordinalNumber: _emberData['default'].attr('number'),
        consumptionByDeclaration: string,
        consumptionByInvoices: string,
        yearConsumption1: string,
        yearConsumption2: string,
        yearConsumption3: string
    });
});
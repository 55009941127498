define('ember-new/controllers/renouncement/show/history', ['exports', 'ember-new/mixins/controllers/individual-contract/entity-change', 'ember'], function (exports, _emberNewMixinsControllersIndividualContractEntityChange, _ember) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsControllersIndividualContractEntityChange['default'], Object.defineProperties({

        queryParams: {
            dateFrom: 'dateFrom',
            dateTo: 'dateTo',
            author: 'author'
        },

        queryParamsNames: {
            dateFrom: 'Data od',
            dateTo: 'Data do',
            author: 'Autor'
        }

    }, {
        queryParamsVars: {
            get: function get() {
                return this.queryParams;
            },
            configurable: true,
            enumerable: true
        }
    }));
});
define('ember-new/controllers/individual-contract-price-lists/product/add', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        fillForm: function fillForm(form) {
            form.set('model.name', null);
            form.set('model.code', null);
        },

        afterCommand: function afterCommand(response) {
            var ctrl = this;
            var route = this.container.lookup('route:individual-contract-price-lists.product');
            route.refresh();
            setTimeout(function () {
                ctrl.transitionToRoute('individual-contract-price-lists.product');
            }, 500);
        }

    });
});
define('ember-new/controllers/b2b-contract/dzu/show-ee/prices/edit-condition', ['exports', 'ember', 'ember-new/mixins/b2b-contract/controllers/dzu-show-prices-add-price'], function (exports, _ember, _emberNewMixinsB2bContractControllersDzuShowPricesAddPrice) {
  exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsB2bContractControllersDzuShowPricesAddPrice['default'], {

    indexExpectedPriceVisible: (function () {
      return this.get('model.indexExpectedPriceVisible');
    }).property('model.indexExpectedPriceVisible'),

    indexMarketBonusVisible: (function () {
      return this.get('model.indexMarketBonusVisible');
    }).property('model.indexMarketBonusVisible'),

    fillForm: function fillForm(form) {
      form.set('model.id', this.get('model.id'));
      form.set('model.propertyRights', this.get('model.propertyRights'));
      form.set('model.indexExpectedPrice', this.get('model.indexExpectedPrice'));
      form.set('model.indexMarketBonus', this.get('model.indexMarketBonus'));
      form.set('model.wbFactor', this.get('model.wbFactor'));
    },

    afterCommand: function afterCommand(response) {
      var ctrl = this;
      var route = this.container.lookup("route:b2b-contract.dzu.show-ee.prices");
      route.refresh();
      setTimeout(function () {
        ctrl.transitionToRoute('b2b-contract.dzu.show-ee.prices');
      }, 500);
    }

  });
});
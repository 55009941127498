define('ember-new/components/sticky-tight-table', ['exports', 'ember'], function (exports, _ember) {

    var Component = _ember['default'].Component.extend({
        tagName: 'div',
        sTableId: null,

        initStickyTables: (function () {
            //this.$('table').stickyTableHeaders({scrollableArea: this.$()}); @TODO naprawić
        }).on('didInsertElement')
    });

    exports['default'] = Component;
});
/* http://getbootstrap.com/css/?#helper-classes-colors */
define('ember-new/components/meeting-verification-row', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'tr',
        attributeBindings: ['role'],
        role: 'row',
        value: null,
        excludedUuids: ['4214f060-f24c-49de-abd5-e3c46071ac54', '0bc35814-e0e7-4f0d-a139-7ba33671af25'],

        row: null,

        testObserver: _ember['default'].observer('row.verificationStatus', function () {
            var pattern = new RegExp('[0-9a-z]{8}\-[0-9a-z]{4}\-[0-9a-z]{4}\-[0-9a-z]{4}\-[0-9a-z]{12}');
            if (pattern.test(this.get('row.verificationStatus'))) {
                this.set('row.errors', []);
                this.set('row.isToImport', true);
            } else {
                this.set('row.errors', ['Nie rozpoznano statusu. Wybierz ręcznie i zaznacz cały wiersz do zapisu!']);
                this.set('row.isToImport', false);
            }
        }),

        addValueToCollection: _ember['default'].on('didInsertElement', function () {
            var controller = this.get('targetObject');
            var collection = controller.get('rowCollection');
            var row = this.get('row');
            if (Array.isArray(collection)) {
                collection.push(row);
            }
        })
    });
});
define('ember-new/controllers/individual-contract/show-ee/sells', ['exports', 'ember-new/config/environment', 'ember', 'ember-new/mixins/individual-contract/show/sells'], function (exports, _emberNewConfigEnvironment, _ember, _emberNewMixinsIndividualContractShowSells) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsIndividualContractShowSells['default'], {

        renouncementStatement: null,

        init: (function () {
            if (this.get('renouncementStatement') && this.get('renouncementStatement.isVisible')) {
                alert(this.get('renouncementStatement.message'));
            }
        }).observes('renouncementStatement'),

        agreementsSimpleUrl: (function () {
            return _emberNewConfigEnvironment['default'].apiUrl + '/rest/individualeecontracts/' + this.get('model.id') + '/agreements';
        }).property(),

        lightBulbsSimpleUrl: (function () {
            return _emberNewConfigEnvironment['default'].apiUrl + '/rest/individualeecontracts/' + this.get('model.id') + '/light/bulbs';
        }).property(),

        isAllowedToModifyRenouncement: (function () {
            var renouncementInfo = this.get('model.renouncementInfo');
            var permission = this.get('TheUser').isMainRoleIn(['ROLE_GDPR_SPECIALIST']);

            return permission && this.get('isEditable') || renouncementInfo;
        }).property('model'),

        isEditable: (function () {
            var editSells = this.get('model.credentials.editSells');
            var isAnonymized = this.get('model.isAnonymized');
            var permission = this.get('TheUser').isMainRoleIn(['ROLE_GDPR_SPECIALIST']);

            return editSells && !isAnonymized && !permission;
        }).property('model')
    });
});
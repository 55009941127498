define('ember-new/models/comment', ['exports', 'ember-new/models/abstract', 'ember-data', 'ember-new/mixins/base-document'], function (exports, _emberNewModelsAbstract, _emberData, _emberNewMixinsBaseDocument) {

    var Comment = _emberNewModelsAbstract['default'].extend(_emberNewMixinsBaseDocument['default'], {
        type: _emberData['default'].attr('string'),
        subtype: _emberData['default'].attr('string'),
        userContent: _emberData['default'].attr('string'),
        systemContent: _emberData['default'].attr('string'),
        isSystem: _emberData['default'].attr('boolean'),
        clientID: _emberData['default'].attr('string'),
        contextID: _emberData['default'].attr('string'),
        showBolded: _emberData['default'].attr('boolean'),
        createdBy: _emberData['default'].belongsTo('user', { inverse: null }),
        dateCreated: _emberData['default'].attr('string'),
        number: _emberData['default'].attr('number'),
        userRole: _emberData['default'].attr('string')
    });

    exports['default'] = Comment;
});
define("ember-new/templates/b2b-contract/salesman/show-ee/summary", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 8
            },
            "end": {
              "line": 126,
              "column": 8
            }
          },
          "moduleName": "ember-new/templates/b2b-contract/salesman/show-ee/summary.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "row");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "col-sm-6");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("Klient ");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("table");
          dom.setAttribute(el3, "class", "table table-bordered table-striped table-hover");
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("thead");
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("tr");
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("th");
          dom.setAttribute(el6, "colspan", "2");
          var el7 = dom.createTextNode("Klient");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("tbody");
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("tr");
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("td");
          var el7 = dom.createTextNode("Nazwa");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("td");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("tr");
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("td");
          var el7 = dom.createTextNode("NIP");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("td");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("tr");
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("td");
          var el7 = dom.createTextNode("Kategoria");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("td");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment(" Umowa ");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("table");
          dom.setAttribute(el3, "class", "table table-bordered table-striped table-hover");
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("thead");
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("tr");
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("th");
          dom.setAttribute(el6, "colspan", "2");
          var el7 = dom.createTextNode("Umowa");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("tbody");
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("tr");
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("td");
          var el7 = dom.createTextNode("Nr umowy");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("td");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("tr");
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("td");
          var el7 = dom.createTextNode("Status");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("td");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(". ");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("tr");
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("td");
          var el7 = dom.createTextNode("Data podpisania umowy");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("td");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("tr");
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("td");
          var el7 = dom.createTextNode("Okres umowy (w miesiącach)");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("td");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("tr");
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("td");
          var el7 = dom.createTextNode("Okres umowy (w miesiącach - wg Doradcy)");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("td");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("tr");
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("td");
          var el7 = dom.createTextNode("Główna taryfa");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("td");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("tr");
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("td");
          var el7 = dom.createTextNode("Produkt");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("td");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "col-sm-6");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("Obsluga klienta ");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("table");
          dom.setAttribute(el3, "class", "table table-bordered table-striped table-hover");
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("thead");
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("tr");
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("th");
          dom.setAttribute(el6, "colspan", "2");
          var el7 = dom.createTextNode("Obsługa klienta");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("tbody");
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("tr");
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("td");
          var el7 = dom.createTextNode("Departament kompletujący");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("td");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("tr");
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("td");
          var el7 = dom.createTextNode("Specjalista kompletujący");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("td");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n\n                        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("table");
          dom.setAttribute(el3, "class", "table table-bordered table-striped table-hover");
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("thead");
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("tr");
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("th");
          dom.setAttribute(el6, "colspan", "2");
          var el7 = dom.createTextNode("Dane sprzedażowe");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("tbody");
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("tr");
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("td");
          var el7 = dom.createTextNode("Rodzaj umowy");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("td");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("tr");
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("td");
          var el7 = dom.createTextNode("Data zaraportowania umowy");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("td");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("tr");
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("td");
          var el7 = dom.createTextNode("Data zaakceptowania umowy");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("td");
          var el7 = dom.createTextNode("\n                                    ");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                                    ");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                                ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("tr");
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("td");
          var el7 = dom.createTextNode("Handlowiec");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("td");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("tr");
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("td");
          var el7 = dom.createTextNode("Region");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("td");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1]);
          var element6 = dom.childAt(element5, [1]);
          var element7 = dom.childAt(element6, [3, 3]);
          var element8 = dom.childAt(element6, [7, 3]);
          var element9 = dom.childAt(element8, [3, 3]);
          var element10 = dom.childAt(element5, [3]);
          var element11 = dom.childAt(element10, [3, 3]);
          var element12 = dom.childAt(element10, [5, 3]);
          var element13 = dom.childAt(element12, [5, 3]);
          var morphs = new Array(19);
          morphs[0] = dom.createMorphAt(dom.childAt(element7, [1, 3]), 0, 0);
          morphs[1] = dom.createMorphAt(dom.childAt(element7, [3, 3]), 0, 0);
          morphs[2] = dom.createMorphAt(dom.childAt(element7, [5, 3]), 0, 0);
          morphs[3] = dom.createMorphAt(dom.childAt(element8, [1, 3]), 0, 0);
          morphs[4] = dom.createMorphAt(element9, 0, 0);
          morphs[5] = dom.createMorphAt(element9, 2, 2);
          morphs[6] = dom.createMorphAt(dom.childAt(element8, [5, 3]), 0, 0);
          morphs[7] = dom.createMorphAt(dom.childAt(element8, [7, 3]), 0, 0);
          morphs[8] = dom.createMorphAt(dom.childAt(element8, [9, 3]), 0, 0);
          morphs[9] = dom.createMorphAt(dom.childAt(element8, [11, 3]), 0, 0);
          morphs[10] = dom.createMorphAt(dom.childAt(element8, [13, 3]), 0, 0);
          morphs[11] = dom.createMorphAt(dom.childAt(element11, [1, 3]), 0, 0);
          morphs[12] = dom.createMorphAt(dom.childAt(element11, [3, 3]), 0, 0);
          morphs[13] = dom.createMorphAt(dom.childAt(element12, [1, 3]), 0, 0);
          morphs[14] = dom.createMorphAt(dom.childAt(element12, [3, 3]), 0, 0);
          morphs[15] = dom.createMorphAt(element13, 1, 1);
          morphs[16] = dom.createMorphAt(element13, 3, 3);
          morphs[17] = dom.createMorphAt(dom.childAt(element12, [7, 3]), 0, 0);
          morphs[18] = dom.createMorphAt(dom.childAt(element12, [9, 3]), 0, 0);
          return morphs;
        },
        statements: [["content", "model.companyName", ["loc", [null, [16, 32], [16, 53]]]], ["content", "model.nip", ["loc", [null, [20, 32], [20, 45]]]], ["content", "model.clientCategory", ["loc", [null, [24, 32], [24, 56]]]], ["content", "model.contractNo", ["loc", [null, [39, 36], [39, 56]]]], ["content", "model.statusNumber", ["loc", [null, [43, 36], [43, 58]]]], ["content", "model.status", ["loc", [null, [43, 60], [43, 76]]]], ["content", "model.dateContractSigned", ["loc", [null, [47, 36], [47, 64]]]], ["content", "model.contractNumberOfMonths", ["loc", [null, [51, 36], [51, 68]]]], ["content", "model.contractNumberOfMonthsBySalesman", ["loc", [null, [55, 36], [55, 78]]]], ["content", "model.mainTariff", ["loc", [null, [59, 36], [59, 56]]]], ["content", "model.product", ["loc", [null, [63, 36], [63, 53]]]], ["content", "model.completingDepartment", ["loc", [null, [80, 32], [80, 62]]]], ["content", "model.completingSpecialist", ["loc", [null, [84, 32], [84, 62]]]], ["content", "model.contractType", ["loc", [null, [99, 36], [99, 58]]]], ["content", "model.dateContractReported", ["loc", [null, [103, 36], [103, 66]]]], ["inline", "if", [["get", "model.acceptDate", ["loc", [null, [108, 41], [108, 57]]]], ["get", "model.acceptDate", ["loc", [null, [108, 58], [108, 74]]]]], [], ["loc", [null, [108, 36], [108, 76]]]], ["inline", "unless", [["get", "model.acceptDate", ["loc", [null, [109, 45], [109, 61]]]], "Umowa jeszcze nie zaakceptowana"], [], ["loc", [null, [109, 36], [109, 97]]]], ["content", "model.salesman", ["loc", [null, [114, 36], [114, 54]]]], ["content", "model.region", ["loc", [null, [118, 36], [118, 52]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 131,
              "column": 8
            },
            "end": {
              "line": 166,
              "column": 8
            }
          },
          "moduleName": "ember-new/templates/b2b-contract/salesman/show-ee/summary.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("table");
          dom.setAttribute(el1, "class", "table table-bordered table-striped table-hover");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("thead");
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("tr");
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("th");
          dom.setAttribute(el4, "scope", "col");
          var el5 = dom.createTextNode("Dane wg DzU");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("th");
          dom.setAttribute(el4, "scope", "col");
          var el5 = dom.createTextNode("Dane wg Doradcy");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("tbody");
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("tr");
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("th");
          dom.setAttribute(el4, "scope", "row");
          var el5 = dom.createTextNode("Masa marży");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("tr");
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("th");
          dom.setAttribute(el4, "scope", "row");
          var el5 = dom.createTextNode("Wolumen");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("tr");
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("th");
          dom.setAttribute(el4, "scope", "row");
          var el5 = dom.createTextNode("Zużycie");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("tr");
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("th");
          dom.setAttribute(el4, "scope", "row");
          var el5 = dom.createTextNode("Narzut");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 3]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element0, [3]);
          var element3 = dom.childAt(element0, [5]);
          var element4 = dom.childAt(element0, [7]);
          var morphs = new Array(8);
          morphs[0] = dom.createMorphAt(dom.childAt(element1, [3]), 0, 0);
          morphs[1] = dom.createMorphAt(dom.childAt(element1, [5]), 0, 0);
          morphs[2] = dom.createMorphAt(dom.childAt(element2, [3]), 0, 0);
          morphs[3] = dom.createMorphAt(dom.childAt(element2, [5]), 0, 0);
          morphs[4] = dom.createMorphAt(dom.childAt(element3, [3]), 0, 0);
          morphs[5] = dom.createMorphAt(dom.childAt(element3, [5]), 0, 0);
          morphs[6] = dom.createMorphAt(dom.childAt(element4, [3]), 0, 0);
          morphs[7] = dom.createMorphAt(dom.childAt(element4, [5]), 0, 0);
          return morphs;
        },
        statements: [["content", "model.mmByDzu", ["loc", [null, [143, 24], [143, 41]]]], ["content", "model.mmBySalesman", ["loc", [null, [144, 24], [144, 46]]]], ["content", "model.volumeByDzu", ["loc", [null, [148, 24], [148, 45]]]], ["content", "model.volumeBySalesman", ["loc", [null, [149, 24], [149, 50]]]], ["content", "model.consumptionByDzu", ["loc", [null, [154, 24], [154, 50]]]], ["content", "model.consumptionBySalesman", ["loc", [null, [155, 24], [155, 55]]]], ["content", "model.overheadByDzu", ["loc", [null, [160, 24], [160, 47]]]], ["content", "model.overheadBySalesman", ["loc", [null, [161, 24], [161, 52]]]]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 179,
                    "column": 16
                  },
                  "end": {
                    "line": 181,
                    "column": 16
                  }
                },
                "moduleName": "ember-new/templates/b2b-contract/salesman/show-ee/summary.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["content", "comment.systemContent", ["loc", [null, [180, 20], [180, 45]]]]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 181,
                    "column": 16
                  },
                  "end": {
                    "line": 183,
                    "column": 16
                  }
                },
                "moduleName": "ember-new/templates/b2b-contract/salesman/show-ee/summary.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["content", "comment.userContent", ["loc", [null, [182, 20], [182, 43]]]]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 173,
                  "column": 12
                },
                "end": {
                  "line": 184,
                  "column": 12
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/salesman/show-ee/summary.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "comment.isSystem", ["loc", [null, [179, 22], [179, 38]]]]], [], 0, 1, ["loc", [null, [179, 16], [183, 23]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 172,
                "column": 8
              },
              "end": {
                "line": 185,
                "column": 8
              }
            },
            "moduleName": "ember-new/templates/b2b-contract/salesman/show-ee/summary.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "bt-comment", [], ["author", ["subexpr", "@mut", [["get", "comment.createdBy", ["loc", [null, [173, 33], [173, 50]]]]], [], []], "authorRole", ["subexpr", "@mut", [["get", "comment.userRole", ["loc", [null, [174, 37], [174, 53]]]]], [], []], "authorDept", ["subexpr", "@mut", [["get", "comment.subtype", ["loc", [null, [175, 37], [175, 52]]]]], [], []], "date", ["subexpr", "@mut", [["get", "comment.dateCreated", ["loc", [null, [176, 31], [176, 50]]]]], [], []], "dateFormat", "LL H:mm", "systemComment", ["subexpr", "@mut", [["get", "comment.isSystem", ["loc", [null, [178, 40], [178, 56]]]]], [], []]], 0, null, ["loc", [null, [173, 12], [184, 27]]]]],
          locals: ["comment"],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 185,
                "column": 8
              },
              "end": {
                "line": 187,
                "column": 8
              }
            },
            "moduleName": "ember-new/templates/b2b-contract/salesman/show-ee/summary.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            Brak komentarzy.\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 171,
              "column": 4
            },
            "end": {
              "line": 188,
              "column": 4
            }
          },
          "moduleName": "ember-new/templates/b2b-contract/salesman/show-ee/summary.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "each", [["get", "model.comments", ["loc", [null, [172, 16], [172, 30]]]]], [], 0, 1, ["loc", [null, [172, 8], [187, 17]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.3.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 192,
            "column": 6
          }
        },
        "moduleName": "ember-new/templates/b2b-contract/salesman/show-ee/summary.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-md-12 col-lg-12");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment(" PPE ");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-md-6 col-lg-6");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("@TODO dopisac akcje do sprawdzania  komentarzy");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment(" Komentarze ");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-md-6 col-lg-6");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element14 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(element14, [1]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element14, [5]), 1, 1);
        morphs[2] = dom.createMorphAt(dom.childAt(element14, [11]), 1, 1);
        return morphs;
      },
      statements: [["block", "box-for-form", [], ["title", "Umowa, klient, handlowiec", "isAllowedEdit", false, "detailsLink", "b2b-contract.salesman.show-ee.data"], 0, null, ["loc", [null, [3, 8], [126, 25]]]], ["block", "box-for-form", [], ["title", "Wolumeny i marże", "isAllowedEdit", false, "detailsLink", "b2b-contract.salesman.show-ee.volumes"], 1, null, ["loc", [null, [131, 8], [166, 25]]]], ["block", "box-for-form", [], ["title", "Ostatnie komentarze", "isAllowedEdit", false, "detailsLink", "b2b-contract.salesman.show-ee.comments"], 2, null, ["loc", [null, [171, 4], [188, 21]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});
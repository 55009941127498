define('ember-new/models/meeting-verification-thulium-import-row', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        isToImport: _emberData['default'].attr('boolean'),
        taskId: _emberData['default'].attr('string'),
        taskUuid: _emberData['default'].attr('string'),
        taskDescription: _emberData['default'].attr('array'),
        verificationStatus: _emberData['default'].attr('string'),
        errors: _emberData['default'].attr('array'),
        warnings: _emberData['default'].attr('array'),
        disabled: _emberData['default'].attr('bool')
    });
});
define('ember-new/routes/b2b-contract/dzu/show-ee/ppe/details/invoices', ['exports', 'ember-new/routes/authenticated', 'ember'], function (exports, _emberNewRoutesAuthenticated, _ember) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({

        ppeId: null,
        areasQuantity: null,
        isTariffGroupSet: null,

        model: function model(params) {
            var targetModel = this.modelFor('b2b-contract.dzu.show-ee.ppe.details');
            this.set('ppeId', targetModel.get('uuid'));
            this.set('areasQuantity', targetModel.get('areasQuantity'));
            this.set('isTariffGroupSet', targetModel.get('isTariffGroupSet'));

            return this.store.query('BusinessContractEeInvoiceDzuViewModel', { ppe_id: targetModel.get('uuid') });
        },

        setupController: function setupController(ctrl, model) {
            var content = model.content;

            ctrl.set('model', model);
            ctrl.set('areasQuantity', this.get('areasQuantity'));
            ctrl.set('isTariffGroupSet', this.get('isTariffGroupSet'));
            ctrl.set('ppeId', this.get('ppeId'));
            ctrl.set('isInvoicesExists', content.length > 0);
            ctrl.set('isDeleting', false);
        },

        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            }
        }

    });
});
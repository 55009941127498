define("ember-new/components/d-form/individual-contract-price-lists/business-tariff/edit", ["exports", "ember-new/components/d-form-command", "ember-new/templates/components/d-form"], function (exports, _emberNewComponentsDFormCommand, _emberNewTemplatesComponentsDForm) {
    exports["default"] = _emberNewComponentsDFormCommand["default"].extend({
        layout: _emberNewTemplatesComponentsDForm["default"],

        commandName: 'EditBusinessTariffCommand',

        afterSuccessfulSubmit: function afterSuccessfulSubmit() {
            var targetController = this.get('targetObject');
            var mainController = targetController.container.lookup('controller:individual-contract-price-lists.business-tariff');
            var mainRoute = mainController.get('target');
            mainRoute.send('refreshModel');

            setTimeout(function () {
                targetController.transitionToRoute('individual-contract-price-lists.business-tariff');
            }, 500);
        }
    });
});
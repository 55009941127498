define('ember-new/initializers/common', ['exports', 'ember-new/config/environment', 'ember', 'ember-new/loaders/afterAuthenticateDataLoader', 'ember-new/authorizers/custom'], function (exports, _emberNewConfigEnvironment, _ember, _emberNewLoadersAfterAuthenticateDataLoader, _emberNewAuthorizersCustom) {
    exports['default'] = {
        name: 'common',
        after: 'ember-data',
        initialize: function initialize(application) {
            application.appTypeDMT = 'DMT';
            application.appTypeDSP = 'DSP';
            application.appType = 'DSP';

            application.inject('component', 'application', 'application:main');
            application.inject('component', 'store', 'service:store');

            // init loaders
            //var AfterAuthenticateDataLoaderObject = AfterAuthenticateDataLoader.create();

            //AfterAuthenticateDataLoaderObject.set('store', container.lookup('store:main'));
            //AfterAuthenticateDataLoaderObject.set('`application', application);
            application.register('loader:after_authenticate_data_loader', _emberNewLoadersAfterAuthenticateDataLoader['default'], { instantiate: true });
            application.inject('loader', 'store', 'service:store');
            application.inject('loader', 'application', 'application:main');
            application.inject('view', 'application', 'application:main');

            String.prototype.capitalize = function () {
                return this.charAt(0).toUpperCase() + this.slice(1);
            };

            _ember['default'].onerror = function (error) {

                if (typeof error === 'object') {
                    _ember['default'].Logger.error(error.stack);
                } else {
                    _ember['default'].Logger.error(error);
                }
            };

            _ember['default'].deprecate = function () {
                return null;
            };

            _ember['default'].deprecateFunc = function () {
                return null;
            };

            var devMode = _emberNewConfigEnvironment['default'].isDev;

            window.handleBackendException = function (jqXHR) {
                if (devMode) {
                    var response = jqXHR.responseJSON;

                    if (response !== undefined) {
                        if (response.message !== undefined) {
                            console.error("Backend Exception' thrown: \n\n" + response.message);
                            console.error(response);
                        } else {
                            var exception = response[0];
                            console.error("Backend Exception '" + exception['class'] + "' thrown: \n\n" + exception.message + " \n\nat file " + exception.trace[0].file + "\nat line " + exception.trace[0].line);
                            console.error(exception);
                        }
                    } else {
                        console.error("HTTP 500: UnProcessable http exception");
                    }
                } else {
                    alert('Wystąpił błąd. Skontaktuj się z administratorem.');
                }
            };

            window.handleBackend403 = function (jqXHR, rejectFunction) {
                console.log('403');
                var msg;
                if (jqXHR.responseJSON !== undefined) {
                    msg = jqXHR.responseJSON.message;
                } else {
                    msg = "Nie masz uprawnień do wykonania tej akcji.";
                }

                alert(msg);
                //    alert(msg);

                if (rejectFunction !== undefined) {
                    rejectFunction(jqXHR);
                }
            };

            window.handleUnknownAjaxError = function (jqXHR) {
                if (jqXHR.status.toString().substring(0, 1) === "2") {
                    console.error("Server has returned 200 status code, so it seems response JSON to be broken.");
                } else {
                    console.error("Unknown ajax error to : " + url);
                }

                if (!devMode) {
                    alert('Wystąpił błąd. Skontaktuj się z administratorem.');
                }
            };

            window.handleAjaxError = function (jqXHR, onValidationErrorsCallback, onAccessDeniedCallback) {
                if (jqXHR.status == 500) {
                    handleBackendException(jqXHR);
                    return;
                } else if (jqXHR.status == 400 && onValidationErrorsCallback !== undefined && onValidationErrorsCallback !== null) {
                    onValidationErrorsCallback(jqXHR);
                } else if (jqXHR.status == 403) {
                    handleBackend403(jqXHR);
                    if (onAccessDeniedCallback !== undefined && onAccessDeniedCallback !== null) {
                        onAccessDeniedCallback(jqXHR);
                    }
                } else {
                    handleUnknownAjaxError(jqXHR);
                }
            };
        }
    };
});
define('ember-new/components/d-field-select-status-uuid', ['exports', 'ember', 'ember-new/components/d-field-select'], function (exports, _ember, _emberNewComponentsDFieldSelect) {
    exports['default'] = _emberNewComponentsDFieldSelect['default'].extend({

        dictionariesManager: _ember['default'].inject.service('dictionaries-manager'),
        excludeIds: null,

        // requred options:
        dictionaryName: null,

        initialize: (function () {
            var statusType = this.get('statusType');

            var _this = this;

            if (statusType === undefined || statusType === null || typeof statusType !== 'string') {
                throw new _ember['default'].Error('Component d-field-select-status requires `statusType` option.');
            }

            var dicManager = this.get('dictionariesManager');

            if (!dicManager.hasStatusCollection(statusType)) {
                throw new _ember['default'].Error("Option 'statusType` passed to d-field-select-status component is wrong. There is no `" + statusType + "` status.");
            }

            var content = dicManager.getStatusCollection(statusType);

            if (this.get('excludeStatusesIds') !== undefined && this.get('excludeStatusesIds') instanceof Array) {
                content = content.filter(function (item) {
                    return _this.get('excludeStatusesIds').indexOf(item.get('id')) < 0 ? true : false;
                });
            }
            this.set('content', content);
        }).on('init')
    });
});
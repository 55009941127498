define('ember-new/components/d-form/b2b-contract/documents/edit-gas-contract-documents', ['exports', 'ember', 'ember-new/components/d-form-command', 'ember-new/templates/components/d-form', 'ember-new/mixins/d-form/d-form-upload'], function (exports, _ember, _emberNewComponentsDFormCommand, _emberNewTemplatesComponentsDForm, _emberNewMixinsDFormDFormUpload) {
    exports['default'] = _emberNewComponentsDFormCommand['default'].extend(_emberNewMixinsDFormDFormUpload['default'], {
        layout: _emberNewTemplatesComponentsDForm['default'],

        clearFeedback: function clearFeedback() {
            this._super();
            this.get('targetObject').set('info', []);
        },

        resolveContents: function resolveContents(form) {
            return new _ember['default'].RSVP.Promise(function (resolve, reject) {

                var store = form.get('store');
                var upload = store.peekAll('upload');
                _ember['default'].RSVP.Promise.all([upload]).then(function (result) {
                    var upload = result[0];

                    resolve(_ember['default'].Object.create({
                        contractFUuid: upload,
                        authorizationUuid: upload,
                        PPMsUuid: upload,
                        saleTermsUuid: upload,
                        osdInvoiceUuid: upload,
                        salesInvoiceUuid: upload,
                        presentContractUuid: upload,
                        newDeviceDocumentsUuid: upload,
                        CEIDGUuid: upload,
                        NIPUuid: upload,
                        REGONUuid: upload,
                        KRSUuid: upload,
                        companyContractUuid: upload,
                        legalTitleToPremisesUuid: upload,
                        otherRegisterDocumentsUuid: upload,
                        additionalAuthorizationsUuid: upload,
                        statementUuid: upload,
                        dataUpdateUuid: upload,
                        cessionUuid: upload,
                        changeTariffRequestUuid: upload,
                        otherDocumentsUuid: upload,

                        linkTermsUuid: upload,
                        linkContractUuid: upload,
                        declaredConsumptionUuid: upload,
                        changePowerRequestUuid: upload,

                        EGAgreementDocumentUuid: upload,
                        EGAgreementUuid: upload
                    }));
                });
            });
        },

        configure: function configure() {
            this.registerTransformer('contractFUuid', function (uuid) {
                return uuid;
            }, function (file) {
                if (typeof file === 'string') {
                    return file;
                } else if (file) {
                    return file.get('uuid');
                }
                return null;
            });
            this.registerTransformer('authorizationUuid', function (uuid) {
                return uuid;
            }, function (file) {
                if (typeof file === 'string') {
                    return file;
                } else if (file) {
                    return file.get('uuid');
                }
                return null;
            });
            this.registerTransformer('PPMsUuid', function (uuid) {
                return uuid;
            }, function (file) {
                if (typeof file === 'string') {
                    return file;
                } else if (file) {
                    return file.get('uuid');
                }
                return null;
            });
            this.registerTransformer('saleTermsUuid', function (uuid) {
                return uuid;
            }, function (file) {
                if (typeof file === 'string') {
                    return file;
                } else if (file) {
                    return file.get('uuid');
                }
                return null;
            });
            this.registerTransformer('osdInvoiceUuid', function (uuid) {
                return uuid;
            }, function (file) {
                if (typeof file === 'string') {
                    return file;
                } else if (file) {
                    return file.get('uuid');
                }
                return null;
            });
            this.registerTransformer('salesInvoiceUuid', function (uuid) {
                return uuid;
            }, function (file) {
                if (typeof file === 'string') {
                    return file;
                } else if (file) {
                    return file.get('uuid');
                }
                return null;
            });
            this.registerTransformer('presentContractUuid', function (uuid) {
                return uuid;
            }, function (file) {
                if (typeof file === 'string') {
                    return file;
                } else if (file) {
                    return file.get('uuid');
                }
                return null;
            });
            this.registerTransformer('newDeviceDocumentsUuid', function (uuid) {
                return uuid;
            }, function (file) {
                if (typeof file === 'string') {
                    return file;
                } else if (file) {
                    return file.get('uuid');
                }
                return null;
            });
            this.registerTransformer('CEIDGUuid', function (uuid) {
                return uuid;
            }, function (file) {
                if (typeof file === 'string') {
                    return file;
                } else if (file) {
                    return file.get('uuid');
                }
                return null;
            });
            this.registerTransformer('NIPUuid', function (uuid) {
                return uuid;
            }, function (file) {
                if (typeof file === 'string') {
                    return file;
                } else if (file) {
                    return file.get('uuid');
                }
                return null;
            });
            this.registerTransformer('REGONUuid', function (uuid) {
                return uuid;
            }, function (file) {
                if (typeof file === 'string') {
                    return file;
                } else if (file) {
                    return file.get('uuid');
                }
                return null;
            });
            this.registerTransformer('KRSUuid', function (uuid) {
                return uuid;
            }, function (file) {
                if (typeof file === 'string') {
                    return file;
                } else if (file) {
                    return file.get('uuid');
                }
                return null;
            });
            this.registerTransformer('companyContractUuid', function (uuid) {
                return uuid;
            }, function (file) {
                if (typeof file === 'string') {
                    return file;
                } else if (file) {
                    return file.get('uuid');
                }
                return null;
            });
            this.registerTransformer('legalTitleToPremisesUuid', function (uuid) {
                return uuid;
            }, function (file) {
                if (typeof file === 'string') {
                    return file;
                } else if (file) {
                    return file.get('uuid');
                }
                return null;
            });
            this.registerTransformer('otherRegisterDocumentsUuid', function (uuid) {
                return uuid;
            }, function (file) {
                if (typeof file === 'string') {
                    return file;
                } else if (file) {
                    return file.get('uuid');
                }
                return null;
            });
            this.registerTransformer('additionalAuthorizationsUuid', function (uuid) {
                return uuid;
            }, function (file) {
                if (typeof file === 'string') {
                    return file;
                } else if (file) {
                    return file.get('uuid');
                }
                return null;
            });
            this.registerTransformer('statementUuid', function (uuid) {
                return uuid;
            }, function (file) {
                if (typeof file === 'string') {
                    return file;
                } else if (file) {
                    return file.get('uuid');
                }
                return null;
            });
            this.registerTransformer('dataUpdateUuid', function (uuid) {
                return uuid;
            }, function (file) {
                if (typeof file === 'string') {
                    return file;
                } else if (file) {
                    return file.get('uuid');
                }
                return null;
            });
            this.registerTransformer('cessionUuid', function (uuid) {
                return uuid;
            }, function (file) {
                if (typeof file === 'string') {
                    return file;
                } else if (file) {
                    return file.get('uuid');
                }
                return null;
            });
            this.registerTransformer('changeTariffRequestUuid', function (uuid) {
                return uuid;
            }, function (file) {
                if (typeof file === 'string') {
                    return file;
                } else if (file) {
                    return file.get('uuid');
                }
                return null;
            });
            this.registerTransformer('otherDocumentsUuid', function (uuid) {
                return uuid;
            }, function (file) {
                if (typeof file === 'string') {
                    return file;
                } else if (file) {
                    return file.get('uuid');
                }
                return null;
            });
            this.registerTransformer('linkTermsUuid', function (uuid) {
                return uuid;
            }, function (file) {
                if (typeof file === 'string') {
                    return file;
                } else if (file) {
                    return file.get('uuid');
                }
                return null;
            });
            this.registerTransformer('linkContractUuid', function (uuid) {
                return uuid;
            }, function (file) {
                if (typeof file === 'string') {
                    return file;
                } else if (file) {
                    return file.get('uuid');
                }
                return null;
            });
            this.registerTransformer('declaredConsumptionUuid', function (uuid) {
                return uuid;
            }, function (file) {
                if (typeof file === 'string') {
                    return file;
                } else if (file) {
                    return file.get('uuid');
                }
                return null;
            });
            this.registerTransformer('changePowerRequestUuid', function (uuid) {
                return uuid;
            }, function (file) {
                if (typeof file === 'string') {
                    return file;
                } else if (file) {
                    return file.get('uuid');
                }
                return null;
            });
            this.registerTransformer('EGAgreementDocumentUuid', function (uuid) {
                return uuid;
            }, function (file) {
                if (typeof file === 'string') {
                    return file;
                } else if (file) {
                    return file.get('uuid');
                }
                return null;
            });
            this.registerTransformer('EGAgreementUuid', function (uuid) {
                return uuid;
            }, function (file) {
                if (typeof file === 'string') {
                    return file;
                } else if (file) {
                    return file.get('uuid');
                }
                return null;
            });
        }

    });
});
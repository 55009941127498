define('ember-new/mixins/controllers/individual-contract-partners/change-contract-parameters', ['exports', 'ember'], function (exports, _ember) {
    var computed = _ember['default'].computed;
    var controller = _ember['default'].inject.controller;
    exports['default'] = _ember['default'].Mixin.create({
        individualContractPartners: controller(),
        changeContractParameters: controller('individual-contract-partners.change-contract-parameters'),
        uuids: computed('changeContractParameters.contractsToSetUuids', function () {
            return this.get('changeContractParameters.contractsToSetUuids');
        }),

        actions: {
            afterBulkAction: function afterBulkAction() {
                this.get('changeContractParameters').send('afterBulkAction');
            }
        }
    });
});
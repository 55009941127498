define('ember-new/helpers/format-currency', ['exports', 'ember'], function (exports, _ember) {
    exports.formatCurrency = formatCurrency;

    /**
     * Zwraca sformatowaną wartość w formie waluty.
     * Przykład użycia:
     * {{format-currency 10753.25 locale='en-US' currency='USD'}} =>  $10,753.25
     * {{format-currency 10753.25 }} => 10 753,25 zł
     *
     * @param params - jako pierwszy paramter podajemy wartosc do przeformatowania
     * @param locale - kod jezyka zgodnie z https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl#Locale_identification_and_negotiation
     * @param currency - kod waluty https://www.currency-iso.org/en/home/tables/table-a1.html
     * @param placeholder - symbol/wartosc jezeli param nie jest podany
     * @param minimumFractionDigits - minimalna ilosc cyfr czesci dziecietnej
     */

    function formatCurrency(params, _ref) {
        var _ref$locale = _ref.locale;
        var locale = _ref$locale === undefined ? 'pl-PL' : _ref$locale;
        var _ref$currency = _ref.currency;
        var currency = _ref$currency === undefined ? 'PLN' : _ref$currency;
        var _ref$placeholder = _ref.placeholder;
        var placeholder = _ref$placeholder === undefined ? '- zł' : _ref$placeholder;
        var _ref$minimumFractionDigits = _ref.minimumFractionDigits;
        var minimumFractionDigits = _ref$minimumFractionDigits === undefined ? 2 : _ref$minimumFractionDigits;

        if (!params[0] || isNaN(params[0]) || typeof params[0] === "undefined") {
            return placeholder;
        }

        var value = parseFloat(params[0]);

        // https://developer.mozilla.org/pl/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
        var formatter = new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: minimumFractionDigits
        });

        return formatter.format(value);
    }

    exports['default'] = _ember['default'].Helper.helper(formatCurrency);
});
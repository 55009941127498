define('ember-new/models/contract-summary-salesman-view-model', ['exports', 'ember-data', 'ember-new/models/abstract'], function (exports, _emberData, _emberNewModelsAbstract) {

    var attr = _emberData['default'].attr;

    exports['default'] = _emberData['default'].Model.extend({
        contractNo: attr('string'),
        companyName: attr('string'),
        nip: attr('string'),
        clientCategory: attr('string'),
        status: attr('string'),
        statusNumber: attr('number'),
        dateContractSigned: attr('string'),
        contractNumberOfMonths: attr('string'),
        mainTariff: attr('string'),
        product: attr('string'),
        completingDepartment: attr('string'),
        completingSpecialist: attr('string'),
        implementingDepartment: attr('string'),
        implementingSpecialist: attr('string'),
        contractType: attr('string'),
        salesman: attr('string'),
        region: attr('string'),

        mmByDzu: attr('string'),
        volumeByDzu: attr('string'),
        overheadByDzu: attr('string'),
        consumptionByDzu: attr('string'),

        mmBySalesman: attr('string'),
        volumeBySalesman: attr('string'),
        overheadBySalesman: attr('string'),
        consumptionBySalesman: attr('string'),

        dateContractReported: attr('string'),
        acceptDate: attr('string'),

        comments: attr(),
        contractNumberOfMonthsBySalesman: attr('number')

    });
});
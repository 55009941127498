define('ember-new/mixins/controllers/individual-ppm-show', ['exports', 'ember', 'ember-data', 'ember-new/mixins/controllers/helpers/form-processing-controller', 'ember-new/mixins/controllers/unsaved-data'], function (exports, _ember, _emberData, _emberNewMixinsControllersHelpersFormProcessingController, _emberNewMixinsControllersUnsavedData) {
    exports['default'] = _ember['default'].Mixin.create(_emberNewMixinsControllersHelpersFormProcessingController['default'], _emberNewMixinsControllersUnsavedData['default'], {

        applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),

        invoicesEditState: false,

        fieldsNeedFeedback: [],
        invoicesErrors: null,
        handleInvoicesErrors: function handleInvoicesErrors(response) {

            if (!(response instanceof _emberData['default'].InvalidError) || response.errors[0] === undefined || response.errors[0].detail.invoices.children === undefined) {
                return;
            }

            var invoicesErrorsArray = response.errors[0].detail.invoices.children;
            var resultArray = _ember['default'].A();

            invoicesErrorsArray.forEach(function (invoiceErrors, index) {

                var childrenErrors = invoiceErrors.children;
                resultArray[index] = _ember['default'].Object.create({});

                Object.keys(childrenErrors).forEach(function (key) {
                    var field = childrenErrors[key];
                    if (field.errors !== undefined && field.errors.length) {
                        resultArray[index].set(key, _ember['default'].Object.create({
                            message: field.errors[0],
                            type: 'error'
                        }));
                    } else {
                        resultArray[index].set(key, _ember['default'].Object.create({
                            message: null,
                            type: false
                        }));
                    }
                });
            });

            this.set('invoicesErrors', resultArray);
        },
        invoices: (function () {
            var arr = _ember['default'].A();
            var invoicesErrors = this.get('invoicesErrors');

            this.get('model.invoices').forEach(function (inv, index) {

                var err = invoicesErrors !== null && invoicesErrors instanceof Array ? invoicesErrors[index] : _ember['default'].Object.create({});

                console.log('errors', err);

                arr.pushObject(_ember['default'].Object.create({
                    invoice: inv,
                    errors: err
                }));
            });

            return arr;
        }).property('model.invoices.@each', 'invoicesErrors'),

        allowChangeDateStart: (function () {
            return this.get('model.businessRate.hasManualPrices');
        }).property('model.businessRate'),

        allowChangeDateEnd: (function () {
            return false;
        }).property('model.businessRate'),

        actions: {
            submitForm: function submitForm() {

                if (!this.beforeSubmit()) {
                    return;
                }

                var promise = this.get('model').customUpdate('edit/invoices', ['invoices'], [], 'individualPpm', {}, function (model) {
                    console.log("CLEARUJE");
                    model.get('invoices').clear();
                });

                var controller = this;

                promise.then(function (response) {
                    controller.handleSuccess(response);
                    controller.get('applicationAlerts').addActionMessage('success', 'Zaktualizowano dane faktur!');

                    controller.set('invoicesEditState', false);
                }, function (response) {

                    controller.handleInvoicesErrors(response);

                    controller.handleNotValid(response);
                    controller.get('applicationAlerts').addActionMessage('warning', 'Formularz faktur zawiera błędy');
                });
            },

            addInvoice: function addInvoice() {
                var invoices = this.get('model.invoices');

                invoices.pushObject(this.store.createRecord('individual-ppm-invoice'));
            },
            removeInvoice: function removeInvoice(index) {

                var invoices = this.get('model.invoices');
                var invoicesErrors = this.get('invoicesErrors');

                if (invoices.length < 1) {
                    return;
                }

                invoices.removeAt(index);
                if (invoicesErrors[index] !== undefined) {
                    delete invoicesErrors[index];
                }
            }
        }
    });
});
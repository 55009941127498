define('ember-new/routes/tasks/channel-reservations', ['exports', 'ember-new/routes/authenticated'], function (exports, _emberNewRoutesAuthenticated) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({

        beforeModel: function beforeModel() {
            this.get('store').unloadAll('bulk-channel-reservation.client-content-row-view-model');
        },

        model: function model() {
            return this.get('store').createRecord('bulk-channel-reservation.client-content-view-model');
        },

        setupController: function setupController(ctrl, model) {
            ctrl.set('model', model);
            ctrl.set('store', this.get('store'));
        },

        actions: {
            willTransition: function willTransition() {
                this._super.apply(this, arguments);
                this.set('controller.clientNipContent', '');
                this.set('controller.isResultReceived', false);
            }
        }

    });
});
define('ember-new/mixins/routes/filters-modal', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Mixin.create({
        model: function model(params, transition) {
            // queryParams dostepne w transition... nigdzie o tym w dokumentacji nie ma... ~lyczos
            this.set('qParams', transition.queryParams);
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('qParams', this.get('qParams'));
            controller.send("setupQueryParamsToForm");
        }

    });
});
define("ember-new/routes/b2b-contract/administration/section-pricelists/import-ee", ["exports", "ember-new/routes/authenticated", "ember"], function (exports, _emberNewRoutesAuthenticated, _ember) {
    exports["default"] = _emberNewRoutesAuthenticated["default"].extend({

        model: function model() {
            return _ember["default"].RSVP.hash({
                priceList: this.get('store').createRecord('base-price-list.ee-price-list-view-model'),
                conditionProducts: this.store.findAll('EePriceConditionProductListViewModel')
            });
        },

        setupController: function setupController(controller, model) {
            controller.set('conditionProducts', model.conditionProducts);
            controller.set('model', model.priceList);
            controller.set('store', this.get('store'));
        },

        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            }
        }
    });
});
define('ember-new/controllers/individual-contract/batch/status', ['exports', 'ember', 'ember-new/config/environment', 'ember-new/mixins/controllers/helpers/form-processing-controller'], function (exports, _ember, _emberNewConfigEnvironment, _emberNewMixinsControllersHelpersFormProcessingController) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsControllersHelpersFormProcessingController['default'], {

        applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),
        ajax: _ember['default'].inject.service('ajax'),

        redirectAfterSubmit: 'individual-contract',

        clear: function clear() {
            this.set('cerrors', null);
            this.set('batchErrors', null);
            this.set('isSubmitting', false);
        },

        batchErrors: null,

        actions: {
            submitStatus: function submitStatus() {

                var _this = this;

                if (!this.beforeSubmit()) {
                    return;
                }

                this.clear();

                var data = {
                    ids: [],
                    status: this.get('status') ? this.get('status') : null
                };

                this.get('model').forEach(function (item) {
                    if (item.get('checked') === true) {
                        data.ids.push(item.id);
                    }
                });

                var ajaxPromise = this.get('ajax').ajax(_emberNewConfigEnvironment['default'].apiUrl + '/api/individual-contract/batch/status', data, 'PATCH');

                ajaxPromise.then(function (response) {
                    _this.handleSuccess(response);

                    _this.store.pushPayload(response);
                    _this.get('applicationAlerts').addActionMessage('success', 'Zaktualizowano wybrane elementy');
                    if (_this.get('redirectAfterSubmit')) {
                        _this.transitionToRoute(_this.get('redirectAfterSubmit'));
                    }
                }, function (response) {

                    _this.set('isSubmitting', false);

                    console.log("super response", response);

                    if (response.status == 400) {
                        var batchErrors = response.responseJSON.errors;
                        var batchErrorsList = [];
                        Object.keys(batchErrors).forEach(function (contractNo) {
                            var contractErrors = batchErrors[contractNo];

                            contractErrors.forEach(function (errorObj) {
                                batchErrorsList.push("Umowa " + contractNo + ": " + errorObj.message);
                            });
                        });

                        _this.set('batchErrors', batchErrorsList);

                        _this.get('applicationAlerts').addActionMessage('warning', "Zmiana statusu nie powiodła się, gdyż jedna z umów zgłosiła błąd.");
                    } else {
                        _this.get('applicationAlerts').addActionMessage('danger', "Zmiana statusu nie powiodła się, z przyczyn technicznych.");
                    }
                });
            }
        }

    });
});
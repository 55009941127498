define("ember-new/templates/reports/volume", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 24
            },
            "end": {
              "line": 18,
              "column": 24
            }
          },
          "moduleName": "ember-new/templates/reports/volume.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "d-field-select", [], ["value", ["subexpr", "@mut", [["get", "businessMonth", ["loc", [null, [13, 38], [13, 51]]]]], [], []], "content", ["subexpr", "@mut", [["get", "businessMonths", ["loc", [null, [14, 40], [14, 54]]]]], [], []], "optionLabelPath", "name", "optionValuePath", "uuid", "prompt", " -- wybierz -- "], ["loc", [null, [12, 28], [17, 58]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 23,
              "column": 24
            },
            "end": {
              "line": 30,
              "column": 24
            }
          },
          "moduleName": "ember-new/templates/reports/volume.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "d-field-select", [], ["value", ["subexpr", "@mut", [["get", "region", ["loc", [null, [25, 38], [25, 44]]]]], [], []], "content", ["subexpr", "@mut", [["get", "regions", ["loc", [null, [26, 40], [26, 47]]]]], [], []], "optionLabelPath", "name", "optionValuePath", "uuid", "prompt", " -- wybierz -- "], ["loc", [null, [24, 28], [29, 58]]]]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 35,
              "column": 24
            },
            "end": {
              "line": 42,
              "column": 24
            }
          },
          "moduleName": "ember-new/templates/reports/volume.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "d-field-select", [], ["value", ["subexpr", "@mut", [["get", "salesman", ["loc", [null, [37, 38], [37, 46]]]]], [], []], "content", ["subexpr", "@mut", [["get", "salespeople", ["loc", [null, [38, 40], [38, 51]]]]], [], []], "optionLabelPath", "fullName", "optionValuePath", "uuid", "prompt", " -- wybierz -- "], ["loc", [null, [36, 28], [41, 58]]]]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 47,
              "column": 24
            },
            "end": {
              "line": 54,
              "column": 24
            }
          },
          "moduleName": "ember-new/templates/reports/volume.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "d-field-select", [], ["value", ["subexpr", "@mut", [["get", "aboveMargin", ["loc", [null, [49, 42], [49, 53]]]]], [], []], "content", ["subexpr", "@mut", [["get", "aboveMargins", ["loc", [null, [50, 44], [50, 56]]]]], [], []], "optionLabelPath", "label", "optionValuePath", "value", "prompt", " -- wybierz -- "], ["loc", [null, [48, 28], [53, 62]]]]],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 59,
              "column": 24
            },
            "end": {
              "line": 65,
              "column": 24
            }
          },
          "moduleName": "ember-new/templates/reports/volume.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "d-field-select-dictionary-id", [], ["value", ["subexpr", "@mut", [["get", "meetingSource", ["loc", [null, [61, 42], [61, 55]]]]], [], []], "optionValuePath", "id", "optionLabelPath", "label", "dictionaryName", "task_source"], ["loc", [null, [60, 28], [64, 66]]]]],
        locals: [],
        templates: []
      };
    })();
    var child5 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 70,
              "column": 24
            },
            "end": {
              "line": 77,
              "column": 24
            }
          },
          "moduleName": "ember-new/templates/reports/volume.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "d-field-select", [], ["value", ["subexpr", "@mut", [["get", "contractType", ["loc", [null, [72, 42], [72, 54]]]]], [], []], "content", ["subexpr", "@mut", [["get", "contractTypes", ["loc", [null, [73, 44], [73, 57]]]]], [], []], "optionLabelPath", "label", "optionValuePath", "value", "prompt", " -- wybierz -- "], ["loc", [null, [71, 28], [76, 62]]]]],
        locals: [],
        templates: []
      };
    })();
    var child6 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 126,
                "column": 28
              },
              "end": {
                "line": 129,
                "column": 28
              }
            },
            "moduleName": "ember-new/templates/reports/volume.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("td");
            dom.setAttribute(el1, "style", "background:gray; color:white");
            var el2 = dom.createTextNode("n/d");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("td");
            dom.setAttribute(el1, "style", "background:gray; color:white");
            var el2 = dom.createTextNode("n/d");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 129,
                "column": 28
              },
              "end": {
                "line": 132,
                "column": 28
              }
            },
            "moduleName": "ember-new/templates/reports/volume.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("td");
            dom.setAttribute(el1, "class", "gr");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("td");
            dom.setAttribute(el1, "class", "gr");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
            return morphs;
          },
          statements: [["content", "row.date1.contracts", ["loc", [null, [130, 47], [130, 72]]]], ["inline", "roundwithspaces", [["get", "row.date1.margin", ["loc", [null, [131, 66], [131, 82]]]]], ["precision", 0, "multiplier", 1], ["loc", [null, [131, 47], [131, 110]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 123,
              "column": 20
            },
            "end": {
              "line": 143,
              "column": 20
            }
          },
          "moduleName": "ember-new/templates/reports/volume.hbs"
        },
        isEmpty: false,
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("tr");
          dom.setAttribute(el1, "style", "text-align:center");
          var el2 = dom.createTextNode("\n                            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2, "class", "gr");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2, "class", "gr");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2, "class", "gr");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2, "class", "gr");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2, "class", "gr");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2, "class", "gr");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2, "class", "gr");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2, "class", "gr");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2, "class", "gr");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(11);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
          morphs[2] = dom.createMorphAt(element0, 3, 3);
          morphs[3] = dom.createMorphAt(dom.childAt(element0, [5]), 0, 0);
          morphs[4] = dom.createMorphAt(dom.childAt(element0, [7]), 0, 0);
          morphs[5] = dom.createMorphAt(dom.childAt(element0, [9]), 0, 0);
          morphs[6] = dom.createMorphAt(dom.childAt(element0, [11]), 0, 0);
          morphs[7] = dom.createMorphAt(dom.childAt(element0, [13]), 0, 0);
          morphs[8] = dom.createMorphAt(dom.childAt(element0, [15]), 0, 0);
          morphs[9] = dom.createMorphAt(dom.childAt(element0, [17]), 0, 0);
          morphs[10] = dom.createMorphAt(dom.childAt(element0, [19]), 0, 0);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", [["get", "row.class", ["loc", [null, [124, 38], [124, 47]]]]]]], ["content", "row.name", ["loc", [null, [125, 43], [125, 57]]]], ["block", "if", [["subexpr", "gte", [["get", "row.number", ["loc", [null, [126, 39], [126, 49]]]], 7], [], ["loc", [null, [126, 34], [126, 52]]]]], [], 0, 1, ["loc", [null, [126, 28], [132, 35]]]], ["content", "row.date2.contracts", ["loc", [null, [134, 43], [134, 68]]]], ["content", "row.date2.ppe", ["loc", [null, [135, 43], [135, 62]]]], ["inline", "roundwithspaces", [["get", "row.date2.margin", ["loc", [null, [136, 62], [136, 78]]]]], ["precision", 0, "multiplier", 1], ["loc", [null, [136, 43], [136, 106]]]], ["inline", "roundwithspaces", [["get", "row.date2.volume", ["loc", [null, [137, 62], [137, 78]]]]], ["precision", 0, "multiplier", 1], ["loc", [null, [137, 43], [137, 106]]]], ["content", "row.date3.contracts", ["loc", [null, [138, 43], [138, 68]]]], ["content", "row.date3.ppe", ["loc", [null, [139, 43], [139, 62]]]], ["inline", "roundwithspaces", [["get", "row.date3.margin", ["loc", [null, [140, 62], [140, 78]]]]], ["precision", 0, "multiplier", 1], ["loc", [null, [140, 43], [140, 106]]]], ["inline", "roundwithspaces", [["get", "row.date3.volume", ["loc", [null, [141, 62], [141, 78]]]]], ["precision", 0, "multiplier", 1], ["loc", [null, [141, 43], [141, 106]]]]],
        locals: ["row", "index"],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "fragmentReason": false,
        "revision": "Ember@2.3.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 149,
            "column": 0
          }
        },
        "moduleName": "ember-new/templates/reports/volume.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("article");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("section");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h4");
        var el4 = dom.createTextNode("Umów");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("section");
        dom.setAttribute(el3, "class", "content");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("fieldset");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("legend");
        var el6 = dom.createTextNode("Filtry");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "row");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "col-xs-12 col-md-6");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "form-group");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "col-xs-12 col-md-6");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "form-group");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "col-xs-12 col-md-6");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "form-group");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "col-xs-12 col-md-6");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "form-group");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "col-xs-12 col-md-6");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "form-group");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "col-xs-12 col-md-6");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "form-group");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("footer");
        dom.setAttribute(el3, "class", "tile-footer text-right");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4, "class", "btn btn-info");
        var el5 = dom.createTextNode("Filtruj");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4, "class", "btn btn-danger");
        var el5 = dom.createTextNode("Wyczyść filtry");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("section");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("fieldset");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("legend");
        var el5 = dom.createTextNode("Wyniki");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("table");
        dom.setAttribute(el4, "class", "table table-striped table_report");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("thead");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("tr");
        dom.setAttribute(el6, "class", "categories_header");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7, "colspan", "2");
        dom.setAttribute(el7, "class", "text-center header-bolded");
        var el8 = dom.createTextNode("sprzedaż zaległa");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7, "colspan", "4");
        dom.setAttribute(el7, "class", "text-center header-bolded");
        var el8 = dom.createTextNode("sprzedaż");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7, "colspan", "4");
        dom.setAttribute(el7, "class", "text-center header-bolded");
        var el8 = dom.createTextNode("akcept (= wynik miesiąca)");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("tr");
        dom.setAttribute(el6, "class", "categories_header");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7, "colspan", "2");
        dom.setAttribute(el7, "class", "text-center header-bolded");
        var el8 = dom.createTextNode("data zaraportowania przed ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7, "colspan", "4");
        dom.setAttribute(el7, "class", "text-center header-bolded");
        var el8 = dom.createTextNode("data zaraportowania ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" - ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7, "colspan", "4");
        dom.setAttribute(el7, "class", "text-center header-bolded");
        var el8 = dom.createTextNode("data akceptu ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" - ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("tr");
        dom.setAttribute(el6, "class", "categories_header");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7, "class", "text-center header-bolded");
        var el8 = dom.createTextNode("# umów");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7, "class", "text-center header-bolded");
        var el8 = dom.createTextNode("MM");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7, "class", "text-center header-bolded");
        var el8 = dom.createTextNode("# umów");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7, "class", "text-center header-bolded");
        var el8 = dom.createTextNode("# PPE");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7, "class", "text-center header-bolded");
        var el8 = dom.createTextNode("MM");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7, "class", "text-center header-bolded");
        var el8 = dom.createTextNode("Wolumen");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7, "class", "text-center header-bolded");
        var el8 = dom.createTextNode("# umów");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7, "class", "text-center header-bolded");
        var el8 = dom.createTextNode("# PPE");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7, "class", "text-center header-bolded");
        var el8 = dom.createTextNode("MM");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7, "class", "text-center header-bolded");
        var el8 = dom.createTextNode("Wolumen");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("tbody");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element2, [3, 3]);
        var element4 = dom.childAt(element2, [5]);
        var element5 = dom.childAt(element4, [1]);
        var element6 = dom.childAt(element4, [3]);
        var element7 = dom.childAt(element1, [3, 3, 1]);
        var element8 = dom.childAt(element7, [1, 3]);
        var element9 = dom.childAt(element8, [5]);
        var element10 = dom.childAt(element8, [7]);
        var morphs = new Array(14);
        morphs[0] = dom.createMorphAt(dom.childAt(element3, [1, 1]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element3, [3, 1]), 1, 1);
        morphs[2] = dom.createMorphAt(dom.childAt(element3, [5, 1]), 1, 1);
        morphs[3] = dom.createMorphAt(dom.childAt(element3, [7, 1]), 1, 1);
        morphs[4] = dom.createMorphAt(dom.childAt(element3, [9, 1]), 1, 1);
        morphs[5] = dom.createMorphAt(dom.childAt(element3, [11, 1]), 1, 1);
        morphs[6] = dom.createElementMorph(element5);
        morphs[7] = dom.createElementMorph(element6);
        morphs[8] = dom.createMorphAt(dom.childAt(element8, [3]), 1, 1);
        morphs[9] = dom.createMorphAt(element9, 1, 1);
        morphs[10] = dom.createMorphAt(element9, 3, 3);
        morphs[11] = dom.createMorphAt(element10, 1, 1);
        morphs[12] = dom.createMorphAt(element10, 3, 3);
        morphs[13] = dom.createMorphAt(dom.childAt(element7, [3]), 1, 1);
        return morphs;
      },
      statements: [["block", "d-form-row", [], ["label", "Miesiąc akceptacji umowy", "inRow", true], 0, null, ["loc", [null, [11, 24], [18, 39]]]], ["block", "d-form-row", [], ["label", "Region", "inRow", true], 1, null, ["loc", [null, [23, 24], [30, 39]]]], ["block", "d-form-row", [], ["label", "Doradca", "inRow", true], 2, null, ["loc", [null, [35, 24], [42, 39]]]], ["block", "d-form-row", [], ["label", "Masa marży", "inRow", true], 3, null, ["loc", [null, [47, 24], [54, 39]]]], ["block", "d-form-row", [], ["label", "Źródło", "inRow", true], 4, null, ["loc", [null, [59, 24], [65, 39]]]], ["block", "d-form-row", [], ["label", "Produkt", "inRow", true], 5, null, ["loc", [null, [70, 24], [77, 39]]]], ["element", "action", ["applyFilters"], [], ["loc", [null, [84, 20], [84, 45]]]], ["element", "action", ["resetFilters"], [], ["loc", [null, [85, 20], [85, 45]]]], ["inline", "format-date", [["get", "selectedBusinessMonth.salesStart", ["loc", [null, [104, 111], [104, 143]]]]], ["format", "DD.MM"], ["loc", [null, [104, 96], [104, 161]]]], ["inline", "format-date", [["get", "selectedBusinessMonth.salesStart", ["loc", [null, [105, 105], [105, 137]]]]], ["format", "DD.MM"], ["loc", [null, [105, 90], [105, 155]]]], ["inline", "format-date", [["get", "selectedBusinessMonth.salesTo", ["loc", [null, [105, 173], [105, 202]]]]], ["format", "DD.MM"], ["loc", [null, [105, 158], [105, 220]]]], ["inline", "format-date", [["get", "selectedBusinessMonth.acceptStart", ["loc", [null, [106, 98], [106, 131]]]]], ["format", "DD.MM"], ["loc", [null, [106, 83], [106, 149]]]], ["inline", "format-date", [["get", "selectedBusinessMonth.acceptTo", ["loc", [null, [106, 167], [106, 197]]]]], ["format", "DD.MM"], ["loc", [null, [106, 152], [106, 215]]]], ["block", "each", [["get", "rows", ["loc", [null, [123, 28], [123, 32]]]]], ["key", "@index"], 6, null, ["loc", [null, [123, 20], [143, 29]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6]
    };
  })());
});
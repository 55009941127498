define('ember-new/models/renouncement', ['exports', 'ember-data', 'ember-new/models/abstract', 'ember-new/mixins/base-document'], function (exports, _emberData, _emberNewModelsAbstract, _emberNewMixinsBaseDocument) {

    var attr = _emberData['default'].attr;
    var refOne = _emberData['default'].belongsTo;

    exports['default'] = _emberNewModelsAbstract['default'].extend(_emberNewMixinsBaseDocument['default'], {
        source: refOne('renouncement-source', { inverse: null }),
        dateEnter: attr('string'),
        datePostmark: attr('string'),
        resignationIsOriginal: attr('nullable-boolean'),
        tpaSpecialist: refOne('User', { inverse: null }),
        newTpaSpecialist: attr('string'),
        tpaSpecialistInfo: attr(),
        requiresResponse: attr('boolean'),
        hasResponse: attr('boolean'),
        dateResponse: attr('string'),
        comment: attr('string'),
        clientInfo: refOne('embed-individual-client', { embedded: true }),
        contractNo: attr('string'),
        eeContractNo: attr('string'),
        gasContractNo: attr('string'),
        pmContractNo: attr('string'),
        contractDateSigned: attr('string'),
        ppmAddress: refOne('address', { embedded: true }),
        ppeNo: attr('string'),
        ppeCounterNo: attr('string'),
        ppgNo: attr('string'),
        ppgCounterNo: attr('string'),
        isAnonymized: attr('boolean'),

        type: attr('string'),
        contract: null,
        eeContract: refOne('individual-ee-contract', { inverse: null }),
        gasContract: refOne('individual-gas-contract', { inverse: null }),
        renouncementStatus: refOne('renouncement-status-dictionary-entry', { inverse: null }),
        scannedDocuments: refOne('Upload', { inverse: null }),
        scannedDocuments2: refOne('Upload', { inverse: null }),
        scannedDocuments3: refOne('Upload', { inverse: null }),
        scannedDocuments4: refOne('Upload', { inverse: null }),
        scannedDocuments5: refOne('Upload', { inverse: null }),
        createdByInfo: attr(),
        credentials: attr(),
        counter: attr(),
        uuid: attr('string'),
        contractUuid: attr('string'),

        contractObserver: (function () {
            if (this.get('type') === 'ee' && this.get('eeContract')) {
                this.set('contract', this.get('eeContract'));
            }
            if (this.get('type') === 'gas' && this.get('gasContract')) {
                this.set('contract', this.get('gasContract'));
            }
            return null;
        }).observes('eeContract', 'gasContract', 'type'),

        hasContract: (function () {
            return this.get('eeContract').get('content') || this.get('gasContract').get('content');
        }).property('eeContract', 'gasContract'),

        isSourcePost: (function () {
            return this.get('source.id') == '5799e4d4cce826f6646d1abc';
        }).property('source'),

        typeString: (function () {
            var type = this.get('type');
            if (type == 'ee') {
                return 'Energia elektryczna';
            } else if (type == 'gas') {
                return 'Gaz';
            } else if (type == 'pm') {
                return 'Pakiet medyczny';
            } else {
                return null;
            }
        }).property('type'),

        isEe: (function () {
            var type = this.get('type');
            if (type === 'ee') {
                return true;
            } else {
                return false;
            }
        }).property('type'),

        isGas: (function () {
            var type = this.get('type');
            if (type === 'gas') {
                return true;
            } else {
                return false;
            }
        }).property('type'),

        isMedicalPackage: (function () {

            return this.get('type') === 'pm';
        }).property('type'),

        canBeAssignedEe: (function () {
            var type = this.get('type');
            var eeContract = this.get('eeContract');
            var ret = type === 'ee' && eeContract.content === null;
            return ret;
        }).property('type', 'eeContract'),

        canBeAssignedGas: (function () {
            var type = this.get('type');
            var gasContract = this.get('gasContract');
            var ret = type === 'gas' && gasContract.content === null;
            return ret;
        }).property('type', 'gasContract'),

        anonymizeAction: (function () {
            if (this.get('contractUuid')) {
                return 'renouncement.show.anonymize-contract';
            }

            return 'renouncement.show.anonymize';
        }).property('contractUuid'),

        anonymizeId: (function () {
            if (this.get('anonymizeAction') === 'renouncement.show.anonymize-contract') {
                return this.get('contractUuid');
            }

            return this.get('uuid');
        }).property('anonymizeAction')
    });
});
define("ember-new/routes/login", ["exports", "ember-new/routes/unauthenticated", "ember", "ember-new/config/environment"], function (exports, _emberNewRoutesUnauthenticated, _ember, _emberNewConfigEnvironment) {
  exports["default"] = _emberNewRoutesUnauthenticated["default"].extend({

    target: null,

    beforeModel: function beforeModel(transition) {
      this.set('target', _ember["default"].get(transition, 'queryParams.target'));
      return this._super.apply(this, arguments);
    },

    activate: function activate() {
      _ember["default"].$('body').toggleClass("login-page-body");
    },
    deactivate: function deactivate() {
      _ember["default"].$('body').toggleClass("login-page-body");
    },

    actions: {
      willTransition: function willTransition(transition) {
        var pathname = this.get('target');
        if (pathname && pathname != '/') {
          var _location = _emberNewConfigEnvironment["default"].legacyAppUrl + pathname;
          window.location.replace(_location);
        }
      }
    }

  });
});
define("ember-new/controllers/b2b-contract/dzu", ["exports", "ember-new/mixins/sortable-controller", "ember-new/mixins/filterable-controller", "ember-new/mixins/paginable-controller", "ember-new/config/environment", "ember", "ember-new/utils/utils", "ember-new/utils/encode-object-to-uri", "ember-new/utils/get-query-params-values"], function (exports, _emberNewMixinsSortableController, _emberNewMixinsFilterableController, _emberNewMixinsPaginableController, _emberNewConfigEnvironment, _ember, _emberNewUtilsUtils, _emberNewUtilsEncodeObjectToUri, _emberNewUtilsGetQueryParamsValues) {
    exports["default"] = _ember["default"].Controller.extend(_emberNewMixinsSortableController["default"], _emberNewMixinsFilterableController["default"], _emberNewMixinsPaginableController["default"], Object.defineProperties({

        applicationAlerts: _ember["default"].inject.service('application-alerts-manager'),
        cookies: _ember["default"].inject.service(),

        // dictionariesManager: Ember.inject.service('dictionaries-manager'),

        isReloading: false,

        statusFilterArray: [],

        totalItems: null,
        queryParams: {
            acceptDateFrom: 'acceptFrom',
            acceptDateTo: 'acceptTo',
            companyFilter: 'company',
            completeDepartmentFilter: 'completeDepartment',
            completeSpecialistFilter: 'completeSpecialist',
            contractNoFilter: 'number',
            contractStatusFilter: 'status',
            contractTypeFilter: 'type',
            contractVersionFilter: 'version',
            dateContractReportedFilterFrom: 'reportedFrom',
            dateContractReportedFilterTo: 'reportedTo',
            dateOfficeReceivedFilterFrom: 'receivedFrom',
            dateOfficeReceivedFilterTo: 'receivedTo',
            dateOfficeReceivedLastDocumentFilterFrom: 'lastdocFrom',
            dateOfficeReceivedLastDocumentFilterTo: 'lastdocTo',
            dateStartFilterFrom: 'startFrom',
            dateStartFilterTo: 'startTo',
            dateStatus1From: 'dateStatus1from',
            dateStatus1To: 'dateStatus1to',
            dateStatus3From: 'dateStatus3from',
            dateStatus3To: 'dateStatus3to',
            dateStatus51From: 'dateStatus51from',
            dateStatus51To: 'dateStatus51to',
            dateStatus5From: 'dateStatus5from',
            dateStatus5To: 'dateStatus5to',
            dateStatus6From: 'dateStatus6from',
            dateStatus6To: 'dateStatus6to',
            dateStatus7From: 'dateStatus7from',
            dateStatus7To: 'dateStatus7to',
            gasProductFilter: 'gasProduct',
            implementDepartmentFilter: 'implementDepartment',
            implementSpecialistFilter: 'implementSpecialist',
            mmVolumeFilter: 'mmVolume',
            newConnectionFilter: 'connection',
            nipFilter: 'nip',
            partnerFilter: 'partner',
            ppmNumberFilter: 'ppmNumber',
            productFilter: 'product',
            readCommentsFilter: 'readComments',
            regionFilter: 'region',
            reserveAgreementFilter: 'reserveAgreement',
            salesmanFilter: 'salesman',
            statusDateFromFilter: 'statusFrom',
            statusDateToFilter: 'statusTo',
            tariffFilter: 'tariff',
            validationStatusFilter: 'validationStatus',
            order: 'order',
            orderDirection: 'orderDirection'
        },

        // query params vars
        // partners
        acceptDateFrom: null,
        acceptDateTo: null,
        company: null,
        companyFilter: null,
        completeDepartment: null,
        completeDepartmentFilter: null,
        completeSpecialist: null,
        completeSpecialistFilter: null,
        contractNo: null,
        contractNoFilter: null,
        contractStatus: null,
        contractStatusFilter: null,
        contractType: null,
        contractTypeFilter: null,
        contractVersionFilter: null,
        dateContractReportedFilterFrom: null,
        dateContractReportedFilterTo: null,
        dateContractReportedFrom: null,
        dateContractReportedTo: null,
        dateOfficeReceivedFilterFrom: null,
        dateOfficeReceivedFilterTo: null,
        dateOfficeReceivedFrom: null,
        dateOfficeReceivedLastDocumentFilterFrom: null,
        dateOfficeReceivedLastDocumentFilterTo: null,
        dateOfficeReceivedLastDocumentFrom: null,
        dateOfficeReceivedLastDocumentTo: null,
        dateOfficeReceivedTo: null,
        dateStartFilterFrom: null,
        dateStartFilterTo: null,
        dateStartFrom: null,
        dateStartTo: null,
        dateStatus1From: null,
        dateStatus1To: null,
        dateStatus3From: null,
        dateStatus3To: null,
        dateStatus51From: null,
        dateStatus51To: null,
        dateStatus5From: null,
        dateStatus5To: null,
        dateStatus6From: null,
        dateStatus6To: null,
        dateStatus7From: null,
        dateStatus7To: null,
        gasProductFilter: null,
        implementDepartment: null,
        implementDepartmentFilter: null,
        implementSpecialist: null,
        implementSpecialistFilter: null,
        mmVolume: null,
        mmVolumeFilter: null,
        newConnection: null,
        newConnectionFilter: null,
        nip: null,
        nipFilter: null,
        partnerFilter: null,
        ppmNumber: null,
        ppmNumberFilter: null,
        productFilter: null,
        readComments: false,
        readCommentsFilter: null,
        region: null,
        regionFilter: null,
        reserveAgreement: null,
        reserveAgreementFilter: null,
        salesman: null,
        salesmanFilter: null,
        statusDateFrom: null,
        statusDateFromFilter: null,
        statusDateTo: null,
        statusDateToFilter: null,
        tariff: null,
        tariffFilter: null,
        validationStatus: null,
        validationStatusFilter: null,
        version: null,
        order: '',
        orderDirection: '',

        // przechowuje aktualnie aktywowane filtry ( labelki z obiektu filtersLabels )
        activatedFilters: [],

        // Opisy dla filtorw - uzywane przy podsumowaniu aktywnych filtorw
        filtersLabels: {
            acceptFrom: 'Data akceptu (od)',
            acceptTo: 'Data akceptu (do)',
            company: 'Firma',
            completeDepartment: 'Departament kompletujący',
            completeSpecialist: 'Departament kompletujący - specjalista',
            connection: '',
            dateStatus1from: 'Data zmiany statusu 1 (OD)',
            dateStatus1to: 'Data zmiany statusu 1 (DO)',
            dateStatus3from: 'Data zmiany statusu 3 (OD)',
            dateStatus3to: 'Data zmiany statusu 3 (DO)',
            dateStatus51from: 'Data zmiany statusu 5.1 (OD)',
            dateStatus51to: 'Data zmiany statusu 5.1 (DO)',
            dateStatus5from: 'Data zmiany statusu 5 (OD)',
            dateStatus5to: 'Data zmiany statusu 5 (DO)',
            dateStatus6from: 'Data zmiany statusu 6 (OD)',
            dateStatus6to: 'Data zmiany statusu 6 (DO)',
            dateStatus7from: 'Data zmiany statusu 7 (OD)',
            dateStatus7to: 'Data zmiany statusu 7 (DO)',
            gasProduct: 'Produkt - gaz',
            implementDepartment: 'Departament realizujący',
            implementSpecialist: 'Departament realizujący - specjalista',
            lastdocFrom: 'Data dost. ostat. brak. dok. do DzU (od)',
            lastdocTo: 'Data dost. ostat. brak. dok. do DzU (do)',
            mmVolume: 'Masa marży',
            nip: 'NIP',
            number: 'Numer umowy',
            partner: 'Partner',
            ppmNumber: 'Liczba PPM',
            product: 'Produkt',
            readComments: 'Tylko nieprzeczytane komentarze',
            receivedFrom: 'Data dostarczenia do DzU (od)',
            receivedTo: 'Data dostarczenia do DzU (do)',
            region: 'Region',
            reportedFrom: 'Data zaraportowania (od)',
            reportedTo: 'Data zaraportowania (do)',
            reserveAgreement: 'Umowa rezerwowa',
            salesman: 'Handlowiec',
            startFrom: 'Data startu (od)',
            startTo: 'Data startu (do)',
            status: 'Status',
            statusFrom: 'Data zmiany statusu (od)',
            statusTo: 'Data zmiany statusu (do)',
            tariff: 'Taryfa',
            type: 'Typ',
            validationStatus: 'Status walidacji',
            version: 'Rodzaj umowy'
        },

        filtersPanelCollapsed: false,

        versionValues: [{ label: 'akwizycja', value: '44ac3963-67cf-470d-ae04-16196ea4cb04,33532086-cc40-4a00-8c7a-15a9c53b568f' }, { label: 'akwizycja online', value: 'bf581b44-ca48-4608-b7c6-48c0753d6120, a0f4d380-6825-4ef7-be1f-6a2beb0e9f65' }, { label: 'cesja', value: '29ce7b60-6b9a-4294-8347-090381dccd4d,2afafd1e-960e-4077-8a89-bcb67bb4741a' }, { label: 'cesja online', value: 'fab8c22e-3258-4fb1-8b79-0031e5a6a79a,20c4d9fc-0631-41f2-99ee-b3503edbe943' }, { label: 'przedłużenie', value: '7b7e9d06-3ddb-43f1-b597-57048666cc92,1500a4ef-0e2e-49e1-b007-54c227c845ea' }, { label: 'przedłużenie online', value: '6648fad7-7b4a-4995-a63c-4bce2059532b,1225972f-ad5c-436f-bb20-2e52ad5a0484' }, { label: 'winback', value: 'f402482b-61a7-4622-a5d7-88d29f1b5298,8981c4b0-dd39-4d95-a0e5-e2014159cff8' }, { label: 'winback online', value: 'e7ea3d85-fc21-4022-a395-cd3d76eb6545,29d81cc1-7f0f-4e2a-adef-a9b922f3e9e3' }],

        yesNoToFilter: [{ label: 'tak', value: 'true' }, { label: 'nie', value: 'false' }],

        typeFilter: [{ label: 'EE', value: 'ee' }, { label: 'Gaz', value: 'gas' }],

        mmVolumeValues: [{ label: 'do 10k', value: '10' }, { label: 'od 11k do 50k', value: '50' }, { label: 'od 51k do 100k', value: '100' }, { label: 'powyżej 100k', value: 'more' }],

        ppmNumberValues: [{ label: '1 - 5', value: '5' }, { label: '6 - 10', value: '10' }, { label: '11 - 30', value: '30' }, { label: 'powyżej 30', value: 'more' }],

        validationSatusValues: [{ label: 'tylko z błędami', value: 'only_errors' }, { label: 'tylko z warningami', value: 'only_warnings' }, { label: 'z błędami lub warningami', value: 'errors_warnings' }],

        readCommentsFilterValues: [{ label: 'przeczytane', value: true }, { label: 'nieprzeczytane', value: false }],

        completeDepartmentValues: [{ label: 'DzU', value: '8f135291-e227-420d-8951-8fc49ada0923' }, { label: 'TPA', value: '120e1873-177c-4ab9-a6e1-4f25182a2f0f' }],

        implementDepartmentValues: [{ label: 'BOK', value: 'b977bb2f-a174-47ed-8a01-a4ab1e062164' }, { label: 'DG', value: '4439ae3e-6109-4ac9-8e51-cc147c3ffee4' }, { label: 'TPA', value: '120e1873-177c-4ab9-a6e1-4f25182a2f0f' }, { label: "DzU", value: "8f135291-e227-420d-8951-8fc49ada0923" }],
        hasContracts: _ember["default"].computed('model', function () {
            return this.get('model.length') > 0;
        }),

        sortColumns: [{ label: 'Numerze statusu', value: 'status' }, { label: 'Dacie ostatniej zmiany statusu', value: 'statusTime' }, { label: 'Dacie startu umowy', value: 'startDate' }, { label: 'Dacie dodania do CRM', value: 'dateCreated' }],

        tariffInit: true,

        /**
        * Zmienia obiekt z lista aktywowanych filtrow
        */
        setActivatedFilters: function setActivatedFilters() {
            var _this = this;

            var filtersLabels = this.get('filtersLabels'),
                // labelki z opisami filtrow
            filtersQueryParams = this.queryParamsVars; // queryParams z URL - klucz(backend) : wartosc (frontend)
            // dicManager = this.get('dictionariesManager');

            var activatedFilters = []; // tmp na czas mapowania

            Object.keys(filtersQueryParams).map(function (filterName) {
                var filterValue = _this.get(filterName),
                    // wartosc filtra
                filterKey = filtersQueryParams[filterName]; // nazwa filtra

                // sprawdzamy czy dany filtr jest rozny od null lub czy istnieje
                if (filterValue !== null && filterValue !== undefined && filterValue !== '') {
                    // szukamy Labelki w obiekcie filtersLabels po nazwie filtra z wartości własciwosci z queryParams
                    // przyklad dla nazwy firmy:
                    // companyFilter - nazwa wlasciwosci w queryParams (idzie na backend)
                    // company - wartość własciwosci z queryParams (to co w URL na frontend)
                    // filtersLabels - obiekt z opisami na potrzeby wyswtelenia
                    // filtersLabels.company - Nazwa do wyswietlenia

                    // let dicManagerVal  = dicManager.getMainDictionaryEntryNameByUuid(filterValue);
                    // console.log('dicManagerVal',dicManagerVal);

                    var currentFilter = {
                        label: filtersLabels[filterKey],
                        // jezeli znajduje wartosc(uuid) w slowniku to podstawia jego name, jezeli nie to ustawia wartosc z URL
                        value: filterValue,
                        // value: dicManagerVal ? dicManagerVal : filterValue,
                        isBoolean: filterValue === 'true' || filterValue === 'false' // uproszczone rzutowanie, na potrzeby HBS
                    };
                    activatedFilters.push(currentFilter);
                }
            });
            this.set('activatedFilters', activatedFilters);
        },

        /**
         * Zwrace tablice z lista specjalistow przypisana po departmentUuid
         * lub jezeli brak wybranego dzialu - zwraca wszystkich specjalistow przypisanych do konkretnej grupy derartamentow
         * lub jezeli brak podanej grupy departamentow (sourceDepartmentsArray) zwraca wszystkich specjalistow.
         * @param selectedDepartment obiekt wybranego departamentu
         * @param sourceDepartmentsArray obiekt z label/value departamentow do przeszukania w przypadku gdy nie ma wybranego selectedDepartment
         * @returns {*} array z obiekatami specjalistow
         */
        filterSpecialistsByDepartment: function filterSpecialistsByDepartment(selectedDepartment) {
            var sourceDepartmentsArray = arguments.length <= 1 || arguments[1] === undefined ? [] : arguments[1];

            var specialists = this.get('specialists'),
                sourceDepartmentsUuids = sourceDepartmentsArray.map(function (dept) {
                return dept.value;
            });
            // jezeli zostal wybrany departament zwraca tylko specjalistow do niego przypisanych, w innym razie wszystkich
            var result = undefined;
            if (selectedDepartment) {
                result = specialists.filter(function (user) {
                    return user.departmentUuid === selectedDepartment;
                });
            } else if (!selectedDepartment && sourceDepartmentsArray.length > 0) {
                result = specialists.filter(function (user) {
                    return sourceDepartmentsUuids.includes(user.departmentUuid);
                });
            } else {
                result = specialists;
            }

            return result;
        },

        /**
         * Filtorwanie listy specjalistów po departamencie kompletujacym.
         */
        dkFilter: (function () {
            return this.filterSpecialistsByDepartment(this.get('completeDepartment'), this.get('completeDepartmentValues'));
        }).property('completeDepartment'),

        /**
         * Filtorwanie listy specjalistów po departamencie realizującym.
         */
        drFilter: (function () {
            return this.filterSpecialistsByDepartment(this.get('implementDepartment'), this.get('implementDepartmentValues'));
        }).property('implementDepartment'),

        isFiltersPanelCollapsed: (function () {
            return this.get('filtersPanelCollapsed');
        }).property('filtersPanelCollapsed'),

        /**
         * inicjalizacja filtrów na podstawie wartości z obiektu queryParams
         */
        initializeTempFilters: function initializeTempFilters() {
            var _this2 = this;

            var filtersQueryParams = this.queryParamsVars;

            // przelatuje po wszystkich kluczach queryParams i ustawia wartosci z nich
            // dla zmiennych ( na potrzeby wstawienia w wartosci w inputy filtorw)
            Object.keys(filtersQueryParams).map(function (filterKey) {
                // nazwa zmiennej w formularzu
                var filterValueFieldName = filtersQueryParams[filterKey];

                // ustawienie wartosci pola formularza na podstawie wartosci z filtrow (queryParams / url)
                _this2.set(filterValueFieldName, _this2.get(filterKey));
                // console.log(filterValueFieldName, this.get(filterKey));
            });

            this.setActivatedStatusFilters(); // todo: przerobic na observer...
            this.setActivatedFilters();
        },

        tariffChanged: (function () {
            if (!this.get('tariff') && this.get('tariffInit')) {
                this.set('tariff', this.get('tariffFilter'));
                this.set('tariffInit', false);
            }
        }).observes('tariff'),

        initializeProducts: function initializeProducts(products) {
            var ctrl = this;

            products = products.filter(function (item) {
                return item.get('isHidden') !== true || item.get('id') === ctrl.get('model').get('productUuid');
            });

            this.set('products', products);
            this.set('totalItems', this.get('totalItems'));
        },

        initializeGasProducts: function initializeGasProducts(gasProducts) {
            var ctrl = this;

            gasProducts = gasProducts.filter(function (item) {
                return item.get('isHidden') !== true || item.get('id') === ctrl.get('model').get('productUuid');
            });

            this.set('gasProducts', gasProducts);
            this.set('totalItems', this.get('totalItems'));
        },

        /**
         * Ustawienie statusu 'isActive' w filtrach Masa marzy - status
         */
        setActivatedStatusFilters: function setActivatedStatusFilters() {
            var statusesSummary = this.get('statusesSummary'),
                activatedStatuses = this.get('status');
            activatedStatuses = activatedStatuses ? activatedStatuses.split(',') : [];
            activatedStatuses.map(function (statusId) {
                var obj = statusesSummary.findBy('id', statusId);
                if (obj) {
                    obj.set('isActive', true);
                }
            });
        },

        resetStatusFilters: function resetStatusFilters() {
            this.get('statusesSummary').forEach(function (obj) {
                return obj.set('isActive', false);
            });
        },

        statusDateFromChosen: (function () {
            return this.get('statusFrom') !== this.get('statusDateFromFilter');
        }).property('statusFrom'),

        statusDateToChosen: (function () {
            return this.get('statusTo') !== this.get('statusDateToFilter');
        }).property('statusTo'),

        // Interface:
        widthLabelClass: 'col-sm-6 col-md-6', // klasa szerokosci dla  kolumny filtrow "label"
        widthFieldClass: 'col-sm-6 col-md-6', // klasa szerokosci dla  kolumny filtrow "field"

        typeFilterIsAll: (function () {
            return this.get('contractTypeFilter') === null;
        }).property('contractTypeFilter'),

        typeFilterIsGas: (function () {
            return this.get('contractTypeFilter') === 'gas';
        }).property('contractTypeFilter'),

        typeFilterIsEe: (function () {
            return this.get('contractTypeFilter') === 'ee';
        }).property('contractTypeFilter'),

        statusFilterIsAll: (function () {
            return this.get('statusFilterArray').length === 0;
        }).property('statusFilterArray'),

        activatedStatusesChanged: false,

        actions: {
            /**
             *
             * @param contractId
             */
            introductionDocumentDownload: function introductionDocumentDownload(contractId) {
                var url = _emberNewUtilsUtils["default"].generateSimpleUrl('/export/getintroductiondocuments/' + contractId, '?', _emberNewConfigEnvironment["default"], this.get('TheUser'), this.get('cookies'));
                window.open(url);
            },

            /**
             * Zastosowanie wybranych filtrów
             */
            applyFilters: function applyFilters() {
                var _this3 = this;

                var filters = this.queryParamsVars;
                Object.keys(filters).map(function (filterParamKey) {
                    // pobieramy wartosc z pola formularza i ustawiamy jego wartosc dla filtrow

                    // jezeli wartosc filtru jest pusta to ustaw na null (usuwa z URL, fix na potrzeby sprawdzania aktywnych filtrow)
                    if (_this3.get(filters[filterParamKey]) === '') {
                        _this3.set(filterParamKey, null);
                    } else {
                        _this3.set(filterParamKey, _this3.get(filters[filterParamKey]));
                    }
                });
                this.setActivatedFilters();
                if (_emberNewMixinsPaginableController["default"].detect(this)) {
                    this.set('page', 1);
                }
                this.set('tariffInit', true);
                this.set('filtersPanelCollapsed', false); // zwija panel z filtrami
                this.send('refreshModel');
            },

            /**
             * Resetowanie wszystkich filtrów
             */
            resetDetailedFilters: function resetDetailedFilters() {
                var _this4 = this;

                var filters = this.queryParamsVars;
                // przelatuje po wszsystkich kluczach z queryParams i ustawia je na NULL
                Object.keys(filters).map(function (filterParamKey) {
                    _this4.set(filterParamKey, null);
                });
                this.resetStatusFilters();
                if (_emberNewMixinsPaginableController["default"].detect(this)) {
                    this.set('page', 1);
                }
                this.set('tariffInit', true);
                this.set('filtersPanelCollapsed', false); // zwija panel z filtrami
                this.send('refreshModel');
            },

            submitStatusDateFilters: function submitStatusDateFilters() {

                this.set('statusDateFromFilter', this.get('statusDateFrom'));
                this.set('statusDateToFilter', this.get('statusDateTo'));

                this.set('statusDateFrom');
                this.set('statusDateTo');

                if (_emberNewMixinsPaginableController["default"].detect(this)) {
                    this.set('page', 1);
                }
            },

            contractsExport: function contractsExport() {
                // if (this.get('totalItems') > 2000) {
                //     this.get('applicationAlerts').addActionMessage('danger', "Maksymalna ilość umów do eksportu wynosi 500, użyj filtrów");
                //     return false;
                // }

                var queryParamsValues = (0, _emberNewUtilsGetQueryParamsValues["default"])(this, this.queryParamsVars),
                    encodedURIFilters = (0, _emberNewUtilsEncodeObjectToUri["default"])(queryParamsValues),
                    encodedURI = _emberNewUtilsUtils["default"].generateSimpleUrl('/export/newContracts?' + encodedURIFilters, '&', _emberNewConfigEnvironment["default"], this.get('TheUser'), this.get('cookies'));

                window.open(encodedURI);
            },

            ppesExport: function ppesExport() {

                // if (this.get('totalItems') > 2000) {
                //     this.get('applicationAlerts').addActionMessage('danger', "Maksymalna ilość umów do eksportu PPE wynosi 2000, użyj filtrów");
                //     return false;
                // }

                var queryParamsValues = (0, _emberNewUtilsGetQueryParamsValues["default"])(this, this.queryParamsVars),
                    encodedURIFilters = (0, _emberNewUtilsEncodeObjectToUri["default"])(queryParamsValues),
                    encodedURI = _emberNewUtilsUtils["default"].generateSimpleUrl('/export/newPpe?' + encodedURIFilters, '&', _emberNewConfigEnvironment["default"], this.get('TheUser'), this.get('cookies'));

                window.open(encodedURI);
            },

            invoicesExport: function invoicesExport() {
                var queryParamsValues = (0, _emberNewUtilsGetQueryParamsValues["default"])(this, this.queryParamsVars),
                    encodedURIFilters = (0, _emberNewUtilsEncodeObjectToUri["default"])(queryParamsValues),
                    encodedURI = _emberNewUtilsUtils["default"].generateSimpleUrl('/export/newInvoices?' + encodedURIFilters, '&', _emberNewConfigEnvironment["default"], this.get('TheUser'), this.get('cookies'));

                window.open(encodedURI);
            },

            conditionsExport: function conditionsExport() {
                var queryParamsValues = (0, _emberNewUtilsGetQueryParamsValues["default"])(this, this.queryParamsVars),
                    encodedURIFilters = (0, _emberNewUtilsEncodeObjectToUri["default"])(queryParamsValues),
                    encodedURI = _emberNewUtilsUtils["default"].generateSimpleUrl('/export/conditions?' + encodedURIFilters, '&', _emberNewConfigEnvironment["default"], this.get('TheUser'), this.get('cookies'));

                window.open(encodedURI);
            },

            pricesExport: function pricesExport() {

                if (this.get('totalItems') > 2000) {
                    this.get('applicationAlerts').addActionMessage('danger', "Maksymalna ilość umów do eksportu cen wynosi 2000, użyj filtrów");
                    return false;
                }

                var queryParamsValues = (0, _emberNewUtilsGetQueryParamsValues["default"])(this, this.queryParamsVars),
                    encodedURIFilters = (0, _emberNewUtilsEncodeObjectToUri["default"])(queryParamsValues),
                    encodedURI = _emberNewUtilsUtils["default"].generateSimpleUrl('/export/newPrices?' + encodedURIFilters, '&', _emberNewConfigEnvironment["default"], this.get('TheUser'), this.get('cookies'));

                window.open(encodedURI);
            },

            welcomeLettersExport: function welcomeLettersExport() {
                var url = _emberNewUtilsUtils["default"].generateSimpleUrl('/export/welcomeLetter', '?', _emberNewConfigEnvironment["default"], this.get('TheUser'), this.get('cookies'));
                window.open(url);
            },

            /**
             *
             * @param uuid
             */
            oneContractExport: function oneContractExport(uuid) {
                var uuidToSend = JSON.stringify(uuid),
                    url = _emberNewUtilsUtils["default"].generateSimpleUrl('/export/newPpe/uuid/' + uuidToSend, '?', _emberNewConfigEnvironment["default"], this.get('TheUser'), this.get('cookies'));
                window.open(url);
            },

            typeFilterEe: function typeFilterEe() {
                this.set('contractTypeFilter', 'ee');

                return this.get('contractTypeFilter');
            },

            typeFilterGas: function typeFilterGas() {
                this.set('contractTypeFilter', 'gas');

                return this.get('contractTypeFilter');
            },

            typeFilterAll: function typeFilterAll() {
                this.set('contractTypeFilter', null);

                return this.get('contractTypeFilter');
            },

            /**
             * Przełącza filtr statusu umowy
             * @param filter obiekt z filtrem statusu umowy
             */
            applyStatusFilter: function applyStatusFilter(filter) {
                var filterId = _ember["default"].get(filter, 'id');
                this.set('status', filterId);
                this.send('applyFilters');
            },
            applyStatusFilterMultiple: function applyStatusFilterMultiple(filter) {
                var filterId = _ember["default"].get(filter, 'id'),
                    activatedStatuses = this.get('status'); // aktywne filtry - string
                activatedStatuses = activatedStatuses ? activatedStatuses.split(',') : [];
                var activatedFilterIndex = activatedStatuses.indexOf(filterId);

                if (activatedFilterIndex === -1) {
                    activatedStatuses.push(filterId);
                } else {
                    activatedStatuses.splice(activatedFilterIndex, 1);
                }
                activatedStatuses = activatedStatuses.join(); // konwertuj na string
                this.set('status', activatedStatuses);
                _ember["default"].set(filter, 'isActive', !_ember["default"].get(filter, 'isActive')); // zmienia flage isActive
                this.set('activatedStatusesChanged', true);

                // this.send('applyFilters');
            },
            applyStatusFilterMultipleBtn: function applyStatusFilterMultipleBtn() {
                this.set('activatedStatusesChanged', false);
                this.send('applyFilters');
            },

            /**
             * Resetuje  (wylacza) wszystkie filtry statusu umowy
             * @param filters
             */
            applyStatusFilterAll: function applyStatusFilterAll(filters) {
                this.set('statusFilterArray', []);
                filters.forEach(function (item) {
                    _ember["default"].set(item, 'isActive', false);
                });
                this.set('contractStatusFilter', []);
                if (_emberNewMixinsPaginableController["default"].detect(this)) {
                    this.set('page', 1);
                }
                this.set('activatedStatusesChanged', false);
            },

            collapseFiltersPanel: function collapseFiltersPanel() {
                this.set('filtersPanelCollapsed', !this.get('filtersPanelCollapsed'));
            },

            /**
             * wstawienie nowej umowy na liste DZU
             * wywolywane z komponentu copy-contract
             * https://guides.emberjs.com/v1.10.0/templates/actions/#toc_action-bubbling
             * @param newContractUuid
             * @param oldContractUuid
             */
            addTempContractToList: function addTempContractToList(_ref) {
                var _this5 = this;

                var newContractUuid = _ref.newContractUuid;
                var oldContractUuid = _ref.oldContractUuid;

                // console.log('in DZU controller...', newContractUuid, oldContractUuid);
                // console.log('in DZU controller... model', this.get('model.content'));
                var indexOfElementToInsertAfter = this.get('model.content').findIndex(function (elem) {
                    return elem.id === oldContractUuid;
                });
                // console.log('indexOfElementToInsertAfter', indexOfElementToInsertAfter);
                this.store.findRecord('ContractDzuListItemViewModel', newContractUuid).then(function (res, rej) {
                    // console.log('newContract get _internalModel', res.get('_internalModel'));
                    _this5.get('model.content').insertAt(indexOfElementToInsertAfter + 1, res.get('_internalModel'));
                    //todo: osbluzyc reject..
                });
            }
        }
    }, {
        queryParamsVars: { /**
                            * getter zwracajacy queryParams zainicjowane wczesniej
                            * uzywane do inicjalizacji filtrow (m.in. wypelnienia pol z adresu url)
                            * pobierane w ten sposob bo przy probie pobrania przez this.get('queryParams')
                            * dostaje juz zmodyfikowany obiekt przez mixin
                            * i nie można dostac się bezpośrednio do samych kluczy/wartości filtrow
                            * @returns {SortableControllerMixin.queryParams|{contractStatusFilter, contractTypeFilter, contractNoFilter, nipFilter, companyFilter, newConnectionFilter, dateContractReportedFilterFrom, dateContractReportedFilterTo, dateOfficeReceivedFilterFrom, dateOfficeReceivedFilterTo, dateOfficeReceivedLastDocumentFilterFrom, dateOfficeReceivedLastDocumentFilterTo, dateStartFilterFrom, dateStartFilterTo, tariffFilter, salesmanFilter, partnerFilter, regionFilter, contractVersionFilter, completeDepartmentFilter, completeSpecialistFilter, implementDepartmentFilter, implementSpecialistFilter, mmVolumeFilter, ppmNumberFilter, reserveAgreementFilter, validationStatusFilter, readCommentsFilter, statusDateFromFilter, statusDateToFilter, productFilter, gasProductFilter}}
                            */

            get: function get() {
                return this.queryParams;
            },
            configurable: true,
            enumerable: true
        }
    }));
});
define('ember-new/models/contract-salesman-list-item-view-model', ['exports', 'ember-data', 'ember-new/models/abstract'], function (exports, _emberData, _emberNewModelsAbstract) {
    exports['default'] = _emberNewModelsAbstract['default'].extend({
        contractNo: _emberData['default'].attr('string'),
        type: _emberData['default'].attr('string'),
        commentsNumber: _emberData['default'].attr('number'),

        companyCity: _emberData['default'].attr('string'),
        companyName: _emberData['default'].attr('string'),

        completingDepartmentName: _emberData['default'].attr('string'),
        completingSpecialistEmail: _emberData['default'].attr('string'),
        completingSpecialistFullName: _emberData['default'].attr('string'),
        completingSpecialistPhone: _emberData['default'].attr('string'),

        salesman: _emberData['default'].attr('string'),
        salesmanRegion: _emberData['default'].attr('string'),

        contractStatus: _emberData['default'].attr('string'),
        contractStatusDate: _emberData['default'].attr('string'),
        contractStatusNumber: _emberData['default'].attr('string'),

        dateContractReported: _emberData['default'].attr('string'),
        dateOfficeReceived: _emberData['default'].attr('string'),
        mm: _emberData['default'].attr('string'),
        nip: _emberData['default'].attr('string'),
        tariff: _emberData['default'].attr('string'),
        notificationsExist: _emberData['default'].attr('boolean'),
        commentsNotifications: _emberData['default'].attr('number'),
        lastComment: _emberData['default'].attr('string'),

        isCoaching: _emberData['default'].attr('boolean'),
        coachingName: _emberData['default'].attr('string')

    });
});
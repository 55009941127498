define("ember-new/loaders/afterAuthenticateDataLoader", ["exports", "ember", "ember-new/loaders/base", "ember-new/utils/ajax/get", "ember-new/config/environment"], function (exports, _ember, _emberNewLoadersBase, _emberNewUtilsAjaxGet, _emberNewConfigEnvironment) {
    exports["default"] = _emberNewLoadersBase["default"].extend({

        dictionariesManager: _ember["default"].inject.service(),
        cookies: _ember["default"].inject.service(),

        _doLoadToStore: function _doLoadToStore(url, token) {
            var promise = (0, _emberNewUtilsAjaxGet["default"])(url, token);
            var self = this;
            promise.then(function (payload) {
                self.get('store').pushPayload(payload);
            }, function () {});

            return promise;
        },
        _doLoad: function _doLoad(url, token) {
            var user = this.get('cookies').read('impersonatingUser');
            return (0, _emberNewUtilsAjaxGet["default"])(url, token, user);
        },
        load: function load(token) {

            var self = this;

            var authPromise = this._doLoad(_emberNewConfigEnvironment["default"].apiUrl + '/getAuthenticateData', token);

            var theuser = self.get('container').lookup('theuser:main');

            var mainPromise = new _ember["default"].RSVP.Promise(function (resolve, reject) {

                authPromise.then(function (data) {

                    // loads all dictionaries
                    var dictionariesPromise = self._doLoad(_emberNewConfigEnvironment["default"].apiUrl + '/api/dependencies/dictionaries', token);
                    var usersDictionariesPromise = self._doLoad(_emberNewConfigEnvironment["default"].apiUrl + '/api/dependencies/user_dictionaries', token);

                    var dependenciesPromise = _ember["default"].RSVP.all([dictionariesPromise, usersDictionariesPromise]);
                    var dictionariesManager = self.get('dictionariesManager');

                    dependenciesPromise.then(function (payload) {
                        dictionariesManager.setup(payload[0].mainDictionaries, payload[0].statusDictionaries, payload[0].periodsDictionaries, payload[1].usersDictionaries, payload[0].weeksDictionaries, payload[1].mainDictionariesForUser);
                    });

                    var dashboardRedAlertLogin = null;

                    if (_emberNewConfigEnvironment["default"].applicationType == "DSP") {
                        dashboardRedAlertLogin = self._doLoad(_emberNewConfigEnvironment["default"].apiUrl + '/ajax/dashboard/redalertlogin', token);
                    }

                    // returning a promise which will be resolved when all above promises will be done
                    var allPromise = _ember["default"].RSVP.all([authPromise, dependenciesPromise, dashboardRedAlertLogin]);

                    allPromise.then(function (resp) {
                        _ember["default"].$('#application_boostraping').hide();

                        theuser.onAuthenticationSuccess(data, token);

                        resolve();
                    });
                });
            });

            return mainPromise;
        }
    });
});
define("ember-new/routes/meeting-verification/list/delete", ["exports", "ember", "ember-new/routes/authenticated", "ember-new/config/environment"], function (exports, _ember, _emberNewRoutesAuthenticated, _emberNewConfigEnvironment) {
    exports["default"] = _emberNewRoutesAuthenticated["default"].extend({

        model: function model(params) {
            return {
                campaignUuid: params.campaign_id
            };
        },

        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            }
        }

    });
});
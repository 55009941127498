define("ember-new/mixins/d-form/d-field", ["exports", "ember", "ember-new/components/d-form-row", "ember-new/components/d-form"], function (exports, _ember, _emberNewComponentsDFormRow, _emberNewComponentsDForm) {
    exports["default"] = _ember["default"].Mixin.create({
        key: null,
        value: null,

        form: null,
        formRow: null,
        hasForm: false,
        hasFormRow: false,
        autoFocus: false,

        didInsertElementOb: (function () {}).on('didInsertElement'),
        willRenderOb: (function () {}).on('willRender'),

        becomeFocused: (function () {
            if (this.get('autoFocus')) this.$().focus();
        }).on('didInsertElement'),

        content: null,

        _isNeedToLoadContent: false,
        _isContentLoadedExternally: false,

        _devMode: false,

        dFieldInitialize: (function () {

            if (this.get('_devMode')) {
                console.log("INIT FIELD", this.get('key'));
            }
            var parentView = this.get('parentView');
            var foundFormObject = false;
            var foundFormRowObject = false;

            if (this.get('form') !== undefined && this.get('form') !== null && this.get('form') instanceof _emberNewComponentsDForm["default"]) {
                foundFormObject = true;
            }

            if (this.get('formRow') !== undefined && this.get('formRow') !== null && this.get('formRow') instanceof _emberNewComponentsDFormRow["default"]) {
                foundFormRowObject = true;
            }

            if (foundFormObject && this.get('key') === null) {
                throw new _ember["default"].Error("Param 'key' is required in d-field component");
            }

            if (!foundFormObject && parentView instanceof _emberNewComponentsDForm["default"]) {
                this.set('form', parentView);
                foundFormObject = true;
                foundFormRowObject = false;
            }

            if (!foundFormRowObject && parentView instanceof _emberNewComponentsDFormRow["default"]) {
                var grandParentView = parentView.get('parentView');
                foundFormRowObject = true;
                this.set('formRow', parentView);

                if (!foundFormObject && grandParentView instanceof _emberNewComponentsDForm["default"]) {
                    this.set('form', grandParentView);
                    foundFormObject = true;
                }
            }

            this.set('hasForm', foundFormObject);
            this.set('hasFormRow', foundFormObject);

            if (foundFormObject) {
                this.get('form').registerField(this.get('key'), this);
            }

            if (foundFormRowObject) {
                this.get('formRow').connectField(this.get('key'), this);

                if (foundFormObject) {
                    this.get('form').registerFormRow(this.get('key'), this.get('formRow'));
                }
            }

            var content = this.get('content');
            var isNeedToLoadContent = this.get('_isNeedToLoadContent');

            if (content !== null) {
                this.set('_isContentLoadedExternally', true);
            } else if (isNeedToLoadContent) {
                // load content in component
                this.set('_isContentLoadedExternally', false);
            }
        }).on('init'),

        setContent: function setContent(content) {
            this.set('content', content);
        },
        /**
        * Returns a content array or a promise
        */
        loadContent: function loadContent() {},
        filterContent: function filterContent(content) {
            return content;
        },
        onContentChange: (function () {}).observes('content'),

        clearValue: function clearValue() {
            this.set('value', null);
        }
    });
});
define("ember-new/routes/individual-contract/show-ee/sells", ["exports", "ember-new/routes/authenticated", "ember-new/mixins/routes/unsaved-alert"], function (exports, _emberNewRoutesAuthenticated, _emberNewMixinsRoutesUnsavedAlert) {
    exports["default"] = _emberNewRoutesAuthenticated["default"].extend(_emberNewMixinsRoutesUnsavedAlert["default"], {

        model: function model(params) {

            return Ember.RSVP.hash({
                products: this.store.query('IndividualContractProductListViewModel', params),
                tariffGroups: this.store.query('IndividualContractTariffGroupsListViewModel', params),
                contract: this.modelFor('individual-contract.show-ee')
            });
        },

        setupController: function setupController(ctrl, model) {
            ctrl.set('products', model.products);
            ctrl.set('tariffGroups', model.tariffGroups);
            ctrl.set('model', model.contract);
        },

        actions: {
            refreshModel: function refreshModel() {
                var parentRoute = this.container.lookup('route:individual-contract.show-ee');
                this.store.unloadAll('individual-ee-contract');
                parentRoute.refresh();
            }
        }
    });
});
define('ember-new/controllers/tasks/add', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Controller.extend({

		model: null, // client model
		client: null,
		queryParams: ['type'],
		formData: null,

		// flags
		allowAddTaskEE: false,
		allowAddTaskGas: false,

		// form options
		types: [{ value: 'phone', name: 'Telefon' }, { value: 'email', name: 'E-mail' }, { value: 'meeting', name: 'Spotkanie' }],
		// form data
		taskType: 'meeting',
		addTaskEE: true,
		addTaskGas: true,
		taskDatetime: null,
		// only for meeting:
		taskDuration: null,
		taskDurationObj: null,
		taskAddressStreet: null,
		taskAddressStreetNo: null,
		taskAddressAptNo: null,
		taskAddressPostalCode: null,
		taskAddressPostalCity: null,
		taskAddressCity: null,
		taskAddressProvince: null,
		durations: [],
		initDurations: (function () {
			var durations = [],
			    defaultDurationObj = undefined;

			for (var i = 900; i <= 14400; i += 900) {
				var hours = Math.floor(i / 60 / 60),
				    minutes = i / 60 % 60,
				    durationObj = {
					int: i,
					value: (hours > 0 ? hours + ' godz' : '') + (minutes > 0 ? ' ' + (minutes + ' min') : '')
				};

				if (i === 3600) {
					defaultDurationObj = durationObj;
				}

				durations.push(durationObj);
			}
			this.set('durations', durations);
			this.set('taskDurationObj', defaultDurationObj);
		}).on('init'),

		fillForm: function fillForm(form) {
			form.set('model.clientUuid', this.get('model.uuid')); // hidden

			form.set('model.taskType', this.get('taskType'));
			form.set('model.taskDatetime', this.get('taskDatetime'));
			form.set('model.taskDuration', this.get('taskDuration'));

			form.set('model.addTaskEE', this.get('addTaskEE'));
			form.set('model.addTaskGas', this.get('addTaskGas'));
			form.set('model.taskAddressStreet', this.get('model.address.street'));
			form.set('model.taskAddressStreetNo', this.get('model.address.streetNo'));
			form.set('model.taskAddressAptNo', this.get('model.address.aptNo'));
			form.set('model.taskAddressPostalCode', this.get('model.address.postalCode'));
			form.set('model.taskAddressPostalCity', this.get('model.address.postalCity'));
			form.set('model.taskAddressCity', this.get('model.address.city'));
			form.set('model.taskAddressDistrict', this.get('model.address.districtUuid'));

			this.set('formData', form.get('formData'));
		},

		afterCommand: function afterCommand() /*from, res*/{
			var ctrl = this;
			var route = this.container.lookup('route:tasks');
			route.refresh();
			setTimeout(function () {
				ctrl.transitionToRoute('tasks');
			}, 500);
		},

		setup: function setup(client, allowAddTaskEE, allowAddTaskGas) {
			this.set('model', client);
			this.set('allowAddTaskEE', allowAddTaskEE);
			this.set('allowAddTaskGas', allowAddTaskGas);
		},
		durationChanged: (function () {
			this.set('taskDuration', this.get('taskDurationObj.int'));
		}).observes('taskDurationObj'),

		isMeetingType: (function () {
			return this.get('formData.taskType') === 'meeting';
		}).property('formData.taskType')
	});
});
define("ember-new/templates/renouncement/partials/anonymize", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 6,
                  "column": 20
                },
                "end": {
                  "line": 14,
                  "column": 20
                }
              },
              "moduleName": "ember-new/templates/renouncement/partials/anonymize.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("ul");
              dom.setAttribute(el1, "class", "todo-list");
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("Imię: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("Nazwisko: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("PESEL: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("E-mail: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("Telefon: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element6 = dom.childAt(fragment, [1]);
              var morphs = new Array(5);
              morphs[0] = dom.createMorphAt(dom.childAt(element6, [1, 0]), 1, 1);
              morphs[1] = dom.createMorphAt(dom.childAt(element6, [3, 0]), 1, 1);
              morphs[2] = dom.createMorphAt(dom.childAt(element6, [5, 0]), 1, 1);
              morphs[3] = dom.createMorphAt(dom.childAt(element6, [7, 0]), 1, 1);
              morphs[4] = dom.createMorphAt(dom.childAt(element6, [9, 0]), 1, 1);
              return morphs;
            },
            statements: [["content", "model.clientInfo.firstName", ["loc", [null, [8, 74], [8, 104]]]], ["content", "model.clientInfo.lastName", ["loc", [null, [9, 78], [9, 107]]]], ["content", "model.clientInfo.pesel", ["loc", [null, [10, 75], [10, 101]]]], ["content", "model.clientInfo.email", ["loc", [null, [11, 76], [11, 102]]]], ["content", "model.clientInfo.phone", ["loc", [null, [12, 77], [12, 103]]]]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 15,
                  "column": 20
                },
                "end": {
                  "line": 24,
                  "column": 20
                }
              },
              "moduleName": "ember-new/templates/renouncement/partials/anonymize.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("ul");
              dom.setAttribute(el1, "class", "todo-list");
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("Ulica: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("Numer domu: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("Numer lokalu: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("Kod pocztowy: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("Poczta: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("Miejscowość: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element5 = dom.childAt(fragment, [1]);
              var morphs = new Array(6);
              morphs[0] = dom.createMorphAt(dom.childAt(element5, [1, 0]), 1, 1);
              morphs[1] = dom.createMorphAt(dom.childAt(element5, [3, 0]), 1, 1);
              morphs[2] = dom.createMorphAt(dom.childAt(element5, [5, 0]), 1, 1);
              morphs[3] = dom.createMorphAt(dom.childAt(element5, [7, 0]), 1, 1);
              morphs[4] = dom.createMorphAt(dom.childAt(element5, [9, 0]), 1, 1);
              morphs[5] = dom.createMorphAt(dom.childAt(element5, [11, 0]), 1, 1);
              return morphs;
            },
            statements: [["content", "model.clientInfo.clientAddress.street", ["loc", [null, [17, 75], [17, 116]]]], ["content", "model.clientInfo.clientAddress.streetNumber", ["loc", [null, [18, 80], [18, 127]]]], ["content", "model.clientInfo.clientAddress.apartmentNumber", ["loc", [null, [19, 82], [19, 132]]]], ["content", "model.clientInfo.clientAddress.postalCode", ["loc", [null, [20, 82], [20, 127]]]], ["content", "model.clientInfo.clientAddress.postalCity", ["loc", [null, [21, 76], [21, 121]]]], ["content", "model.clientInfo.clientAddress.city", ["loc", [null, [22, 81], [22, 120]]]]],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 26,
                    "column": 20
                  },
                  "end": {
                    "line": 34,
                    "column": 20
                  }
                },
                "moduleName": "ember-new/templates/renouncement/partials/anonymize.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("ul");
                dom.setAttribute(el1, "class", "todo-list");
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("li");
                var el3 = dom.createElement("span");
                dom.setAttribute(el3, "class", "text");
                var el4 = dom.createElement("strong");
                var el5 = dom.createTextNode("Imię: ");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("li");
                var el3 = dom.createElement("span");
                dom.setAttribute(el3, "class", "text");
                var el4 = dom.createElement("strong");
                var el5 = dom.createTextNode("Nazwisko: ");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("li");
                var el3 = dom.createElement("span");
                dom.setAttribute(el3, "class", "text");
                var el4 = dom.createElement("strong");
                var el5 = dom.createTextNode("PESEL: ");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("li");
                var el3 = dom.createElement("span");
                dom.setAttribute(el3, "class", "text");
                var el4 = dom.createElement("strong");
                var el5 = dom.createTextNode("E-mail: ");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("li");
                var el3 = dom.createElement("span");
                dom.setAttribute(el3, "class", "text");
                var el4 = dom.createElement("strong");
                var el5 = dom.createTextNode("Telefon: ");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element4 = dom.childAt(fragment, [1]);
                var morphs = new Array(5);
                morphs[0] = dom.createMorphAt(dom.childAt(element4, [1, 0]), 1, 1);
                morphs[1] = dom.createMorphAt(dom.childAt(element4, [3, 0]), 1, 1);
                morphs[2] = dom.createMorphAt(dom.childAt(element4, [5, 0]), 1, 1);
                morphs[3] = dom.createMorphAt(dom.childAt(element4, [7, 0]), 1, 1);
                morphs[4] = dom.createMorphAt(dom.childAt(element4, [9, 0]), 1, 1);
                return morphs;
              },
              statements: [["content", "model.clientInfo.firstNameSecondClient", ["loc", [null, [28, 74], [28, 116]]]], ["content", "model.clientInfo.lastNameSecondClient", ["loc", [null, [29, 78], [29, 119]]]], ["content", "model.clientInfo.peselSecondClient", ["loc", [null, [30, 75], [30, 113]]]], ["content", "model.clientInfo.emailSecondClient", ["loc", [null, [31, 76], [31, 114]]]], ["content", "model.clientInfo.phoneSecondClient", ["loc", [null, [32, 77], [32, 115]]]]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 25,
                  "column": 20
                },
                "end": {
                  "line": 35,
                  "column": 20
                }
              },
              "moduleName": "ember-new/templates/renouncement/partials/anonymize.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "bs-tab-pane", [], ["title", "Drugi klient"], 0, null, ["loc", [null, [26, 20], [34, 36]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 5,
                "column": 16
              },
              "end": {
                "line": 36,
                "column": 16
              }
            },
            "moduleName": "ember-new/templates/renouncement/partials/anonymize.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "bs-tab-pane", [], ["title", "Dane klienta"], 0, null, ["loc", [null, [6, 20], [14, 36]]]], ["block", "bs-tab-pane", [], ["title", "Adres"], 1, null, ["loc", [null, [15, 20], [24, 36]]]], ["block", "if", [["get", "model.clientInfo.peselSecondClient", ["loc", [null, [25, 26], [25, 60]]]]], [], 2, null, ["loc", [null, [25, 20], [35, 27]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 12
            },
            "end": {
              "line": 37,
              "column": 12
            }
          },
          "moduleName": "ember-new/templates/renouncement/partials/anonymize.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "bs-tab", [], ["type", "tabs"], 0, null, ["loc", [null, [5, 16], [36, 27]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 42,
                  "column": 20
                },
                "end": {
                  "line": 52,
                  "column": 20
                }
              },
              "moduleName": "ember-new/templates/renouncement/partials/anonymize.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("ul");
              dom.setAttribute(el1, "class", "todo-list");
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("Numer umowy z odstąpienia: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("Źródło odstąpienia: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("Data wpłynięcia: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("Data stempla: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("Status: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("Czy wymaga odpowiedzi: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("Komentarz: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [1]);
              var morphs = new Array(7);
              morphs[0] = dom.createMorphAt(dom.childAt(element3, [1, 0]), 1, 1);
              morphs[1] = dom.createMorphAt(dom.childAt(element3, [3, 0]), 1, 1);
              morphs[2] = dom.createMorphAt(dom.childAt(element3, [5, 0]), 1, 1);
              morphs[3] = dom.createMorphAt(dom.childAt(element3, [7, 0]), 1, 1);
              morphs[4] = dom.createMorphAt(dom.childAt(element3, [9, 0]), 1, 1);
              morphs[5] = dom.createMorphAt(dom.childAt(element3, [11, 0]), 1, 1);
              morphs[6] = dom.createMorphAt(dom.childAt(element3, [13, 0]), 1, 1);
              return morphs;
            },
            statements: [["content", "model.contractNo", ["loc", [null, [44, 95], [44, 115]]]], ["content", "model.source", ["loc", [null, [45, 88], [45, 104]]]], ["inline", "format-date-pl", [["get", "model.dateEnter", ["loc", [null, [46, 102], [46, 117]]]]], [], ["loc", [null, [46, 85], [46, 119]]]], ["inline", "format-date-pl", [["get", "model.datePostmark", ["loc", [null, [47, 99], [47, 117]]]]], [], ["loc", [null, [47, 82], [47, 119]]]], ["content", "model.renouncementStatus", ["loc", [null, [48, 76], [48, 104]]]], ["inline", "yes-no", [["get", "model.requiresResponse", ["loc", [null, [49, 100], [49, 122]]]]], [], ["loc", [null, [49, 91], [49, 124]]]], ["content", "model.comment", ["loc", [null, [50, 79], [50, 96]]]]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 53,
                  "column": 20
                },
                "end": {
                  "line": 77,
                  "column": 20
                }
              },
              "moduleName": "ember-new/templates/renouncement/partials/anonymize.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "row");
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col-md-6 col-xs-12");
              var el3 = dom.createTextNode("\n                                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("h5");
              var el4 = dom.createTextNode("Dane Umowy/PPE/PPG");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("ul");
              dom.setAttribute(el3, "class", "todo-list");
              var el4 = dom.createTextNode("\n                                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("li");
              var el5 = dom.createElement("span");
              dom.setAttribute(el5, "class", "text");
              var el6 = dom.createElement("strong");
              var el7 = dom.createTextNode("Data zawarcia umowy: ");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode(" ");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("li");
              var el5 = dom.createElement("span");
              dom.setAttribute(el5, "class", "text");
              var el6 = dom.createElement("strong");
              var el7 = dom.createTextNode("Numer PPE: ");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("li");
              var el5 = dom.createElement("span");
              dom.setAttribute(el5, "class", "text");
              var el6 = dom.createElement("strong");
              var el7 = dom.createTextNode("Numer licznika PPE: ");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("li");
              var el5 = dom.createElement("span");
              dom.setAttribute(el5, "class", "text");
              var el6 = dom.createElement("strong");
              var el7 = dom.createTextNode("Numer PPG: ");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("li");
              var el5 = dom.createElement("span");
              dom.setAttribute(el5, "class", "text");
              var el6 = dom.createElement("strong");
              var el7 = dom.createTextNode("Numer licznika PPG: ");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col-md-6 col-xs-12");
              var el3 = dom.createTextNode("\n                                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("h5");
              var el4 = dom.createTextNode("Adres PPE(G)");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("ul");
              dom.setAttribute(el3, "class", "todo-list");
              var el4 = dom.createTextNode("\n                                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("li");
              var el5 = dom.createElement("span");
              dom.setAttribute(el5, "class", "text");
              var el6 = dom.createElement("strong");
              var el7 = dom.createTextNode("Ulica: ");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("li");
              var el5 = dom.createElement("span");
              dom.setAttribute(el5, "class", "text");
              var el6 = dom.createElement("strong");
              var el7 = dom.createTextNode("Numer domu: ");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("li");
              var el5 = dom.createElement("span");
              dom.setAttribute(el5, "class", "text");
              var el6 = dom.createElement("strong");
              var el7 = dom.createTextNode("Numer lokalu: ");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("li");
              var el5 = dom.createElement("span");
              dom.setAttribute(el5, "class", "text");
              var el6 = dom.createElement("strong");
              var el7 = dom.createTextNode("Kod pocztowy: ");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("li");
              var el5 = dom.createElement("span");
              dom.setAttribute(el5, "class", "text");
              var el6 = dom.createElement("strong");
              var el7 = dom.createTextNode("Poczta: ");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("li");
              var el5 = dom.createElement("span");
              dom.setAttribute(el5, "class", "text");
              var el6 = dom.createElement("strong");
              var el7 = dom.createTextNode("Miejscowość: ");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var element1 = dom.childAt(element0, [1, 3]);
              var element2 = dom.childAt(element0, [3, 3]);
              var morphs = new Array(11);
              morphs[0] = dom.createMorphAt(dom.childAt(element1, [1]), 2, 2);
              morphs[1] = dom.createMorphAt(dom.childAt(element1, [3, 0]), 1, 1);
              morphs[2] = dom.createMorphAt(dom.childAt(element1, [5, 0]), 1, 1);
              morphs[3] = dom.createMorphAt(dom.childAt(element1, [7, 0]), 1, 1);
              morphs[4] = dom.createMorphAt(dom.childAt(element1, [9, 0]), 1, 1);
              morphs[5] = dom.createMorphAt(dom.childAt(element2, [1, 0]), 1, 1);
              morphs[6] = dom.createMorphAt(dom.childAt(element2, [3, 0]), 1, 1);
              morphs[7] = dom.createMorphAt(dom.childAt(element2, [5, 0]), 1, 1);
              morphs[8] = dom.createMorphAt(dom.childAt(element2, [7, 0]), 1, 1);
              morphs[9] = dom.createMorphAt(dom.childAt(element2, [9, 0]), 1, 1);
              morphs[10] = dom.createMorphAt(dom.childAt(element2, [11, 0]), 1, 1);
              return morphs;
            },
            statements: [["inline", "format-date-pl", [["get", "model.contractDateSigned", ["loc", [null, [58, 122], [58, 146]]]]], [], ["loc", [null, [58, 105], [58, 148]]]], ["content", "model.ppeNo", ["loc", [null, [59, 87], [59, 102]]]], ["content", "model.ppeCounterNo", ["loc", [null, [60, 96], [60, 118]]]], ["content", "model.ppgNo", ["loc", [null, [61, 87], [61, 102]]]], ["content", "model.ppgCounterNo", ["loc", [null, [62, 96], [62, 118]]]], ["content", "model.ppmAddress.street", ["loc", [null, [68, 83], [68, 110]]]], ["content", "model.ppmAddress.streetNumber", ["loc", [null, [69, 88], [69, 121]]]], ["content", "model.ppmAddress.apartmentNumber", ["loc", [null, [70, 90], [70, 126]]]], ["content", "model.ppmAddress.postalCode", ["loc", [null, [71, 90], [71, 121]]]], ["content", "model.ppmAddress.postalCity", ["loc", [null, [72, 84], [72, 115]]]], ["content", "model.ppmAddress.city", ["loc", [null, [73, 89], [73, 114]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 41,
                "column": 16
              },
              "end": {
                "line": 78,
                "column": 16
              }
            },
            "moduleName": "ember-new/templates/renouncement/partials/anonymize.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "bs-tab-pane", [], ["title", "Dane odstąpienia"], 0, null, ["loc", [null, [42, 20], [52, 36]]]], ["block", "bs-tab-pane", [], ["title", "Dane Umowy/PPE/PPG"], 1, null, ["loc", [null, [53, 20], [77, 36]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 80,
                "column": 16
              },
              "end": {
                "line": 82,
                "column": 16
              }
            },
            "moduleName": "ember-new/templates/renouncement/partials/anonymize.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            var el2 = dom.createElement("strong");
            dom.setAttribute(el2, "style", "color:green");
            var el3 = dom.createElement("i");
            dom.setAttribute(el3, "class", "fa fa-check-square");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" Spełnione warunki anonimizacji");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 82,
                "column": 16
              },
              "end": {
                "line": 84,
                "column": 16
              }
            },
            "moduleName": "ember-new/templates/renouncement/partials/anonymize.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            var el2 = dom.createElement("strong");
            dom.setAttribute(el2, "style", "color:red");
            var el3 = dom.createElement("i");
            dom.setAttribute(el3, "class", "fa fa-exclamation-triangle");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" Nie spełnione warunki anonimizacji");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 88,
                  "column": 24
                },
                "end": {
                  "line": 90,
                  "column": 24
                }
              },
              "moduleName": "ember-new/templates/renouncement/partials/anonymize.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["content", "alert", ["loc", [null, [89, 32], [89, 41]]]]],
            locals: ["alert"],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 85,
                "column": 16
              },
              "end": {
                "line": 92,
                "column": 16
              }
            },
            "moduleName": "ember-new/templates/renouncement/partials/anonymize.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1, "class", "text-warning");
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "fa fa-warning margin-right");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("Uwagi:");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("ul");
            dom.setAttribute(el1, "class", "text-warning");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("                    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
            return morphs;
          },
          statements: [["block", "each", [["get", "model.alerts", ["loc", [null, [88, 32], [88, 44]]]]], [], 0, null, ["loc", [null, [88, 24], [90, 33]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 40,
              "column": 12
            },
            "end": {
              "line": 93,
              "column": 12
            }
          },
          "moduleName": "ember-new/templates/renouncement/partials/anonymize.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("br");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "bs-tab", [], ["type", "tabs"], 0, null, ["loc", [null, [41, 16], [78, 27]]]], ["block", "if", [["get", "model.isOkToAnonymize", ["loc", [null, [80, 22], [80, 43]]]]], [], 1, 2, ["loc", [null, [80, 16], [84, 23]]]], ["block", "if", [["get", "model.alerts", ["loc", [null, [85, 22], [85, 34]]]]], [], 3, null, ["loc", [null, [85, 16], [92, 23]]]]],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 100,
              "column": 4
            },
            "end": {
              "line": 102,
              "column": 4
            }
          },
          "moduleName": "ember-new/templates/renouncement/partials/anonymize.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("i");
          dom.setAttribute(el1, "class", "fa fa-arrow-left margin-right");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("Anuluj\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes"]
        },
        "revision": "Ember@2.3.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 103,
            "column": 9
          }
        },
        "moduleName": "ember-new/templates/renouncement/partials/anonymize.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "class", "content");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-md-4");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-md-8");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("footer");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2, "type", "button");
        dom.setAttribute(el2, "class", "btn btn-danger");
        var el3 = dom.createElement("i");
        dom.setAttribute(el3, "class", "fa fa-eraser");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" Anonimizuj");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element7 = dom.childAt(fragment, [0, 1]);
        var element8 = dom.childAt(fragment, [2]);
        var element9 = dom.childAt(element8, [1]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(dom.childAt(element7, [1]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element7, [3]), 1, 1);
        morphs[2] = dom.createElementMorph(element9);
        morphs[3] = dom.createMorphAt(element8, 3, 3);
        return morphs;
      },
      statements: [["block", "box-for-form", [], ["title", "Klient", "isAllowedEdit", false], 0, null, ["loc", [null, [4, 12], [37, 29]]]], ["block", "box-for-form", [], ["title", "Odstąpienie", "editButtonText", "Szczegóły"], 1, null, ["loc", [null, [40, 12], [93, 29]]]], ["element", "action", ["anonymize", ["get", "model.id", ["loc", [null, [99, 33], [99, 41]]]]], [], ["loc", [null, [99, 12], [99, 43]]]], ["block", "link-to", [["get", "returnRoute", ["loc", [null, [100, 15], [100, 26]]]]], ["type", "button", "class", "btn btn-default"], 2, null, ["loc", [null, [100, 4], [102, 16]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});
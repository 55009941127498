define('ember-new/models/gas-business-tariff-entry-view-model', ['exports', 'ember-data'], function (exports, _emberData) {

    var attr = _emberData['default'].attr;

    exports['default'] = _emberData['default'].Model.extend({
        uuid: attr('string'),
        usysId: attr('string'),
        name: attr('string'),
        excise: attr('string'),
        exciseId: attr('string'),
        tariffFortum: attr('string'),
        tariffFortumUuid: attr('string'),
        tariffPgnig: attr('string'),
        tariffPgnigUuid: attr('string'),
        product: attr('string'),
        productUuid: attr('string')
    });
});
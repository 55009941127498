define("ember-new/routes/redirect-to-legacy", ["exports", "ember-new/routes/authenticated", "ember-new/config/environment"], function (exports, _emberNewRoutesAuthenticated, _emberNewConfigEnvironment) {
  exports["default"] = _emberNewRoutesAuthenticated["default"].extend({

    beforeModel: function beforeModel(transition) {

      this._super.apply(this, arguments);

      transition.then(function () {
        var pathname = window.location.pathname;
        pathname = pathname.replace('\/legacy', '');
        var location = _emberNewConfigEnvironment["default"].legacyAppUrl + pathname;

        window.location.replace(location);
      });
    }

  });
});
define('ember-new/mixins/individual-contract/show/prices', ['exports', 'ember', 'ember-new/config/environment', 'ember-new/mixins/controllers/unsaved-data'], function (exports, _ember, _emberNewConfigEnvironment, _emberNewMixinsControllersUnsavedData) {
    exports['default'] = _ember['default'].Mixin.create(_emberNewMixinsControllersUnsavedData['default'], {

        applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),
        ajax: _ember['default'].inject.service('ajax'),
        name: null,

        isEditable: (function () {
            var isAnonymized = this.get('contract.isAnonymized');
            return !isAnonymized;
        }).property()

    });
});
define("ember-new/templates/renouncement/add/contract-details", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 26,
                    "column": 16
                  },
                  "end": {
                    "line": 42,
                    "column": 16
                  }
                },
                "moduleName": "ember-new/templates/renouncement/add/contract-details.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "col-md-6 col-xs-12");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("h5");
                var el3 = dom.createTextNode("Dane drugiego klienta");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("ul");
                dom.setAttribute(el2, "class", "todo-list");
                var el3 = dom.createTextNode("\n                        ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("li");
                var el4 = dom.createElement("span");
                dom.setAttribute(el4, "class", "text");
                var el5 = dom.createElement("strong");
                var el6 = dom.createTextNode("Imię: ");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                        ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("li");
                var el4 = dom.createElement("span");
                dom.setAttribute(el4, "class", "text");
                var el5 = dom.createElement("strong");
                var el6 = dom.createTextNode("Nazwisko: ");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                        ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("li");
                var el4 = dom.createElement("span");
                dom.setAttribute(el4, "class", "text");
                var el5 = dom.createElement("strong");
                var el6 = dom.createTextNode("PESEL: ");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                        ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("li");
                var el4 = dom.createElement("span");
                dom.setAttribute(el4, "class", "text");
                var el5 = dom.createElement("strong");
                var el6 = dom.createTextNode("Rodzaj dokumentu\n                            tożsamości: ");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                        ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                        ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("li");
                var el4 = dom.createElement("span");
                dom.setAttribute(el4, "class", "text");
                var el5 = dom.createElement("strong");
                var el6 = dom.createTextNode("Nr i Seria dokumentu\n                            tożsamości: ");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                        ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("li");
                var el4 = dom.createElement("span");
                dom.setAttribute(el4, "class", "text");
                var el5 = dom.createElement("strong");
                var el6 = dom.createTextNode("E-mail: ");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                        ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("li");
                var el4 = dom.createElement("span");
                dom.setAttribute(el4, "class", "text");
                var el5 = dom.createElement("strong");
                var el6 = dom.createTextNode("Telefon: ");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element3 = dom.childAt(fragment, [1, 3]);
                var morphs = new Array(7);
                morphs[0] = dom.createMorphAt(dom.childAt(element3, [1, 0]), 1, 1);
                morphs[1] = dom.createMorphAt(dom.childAt(element3, [3, 0]), 1, 1);
                morphs[2] = dom.createMorphAt(dom.childAt(element3, [5, 0]), 1, 1);
                morphs[3] = dom.createMorphAt(dom.childAt(element3, [7, 0]), 1, 1);
                morphs[4] = dom.createMorphAt(dom.childAt(element3, [9, 0]), 1, 1);
                morphs[5] = dom.createMorphAt(dom.childAt(element3, [11, 0]), 1, 1);
                morphs[6] = dom.createMorphAt(dom.childAt(element3, [13, 0]), 1, 1);
                return morphs;
              },
              statements: [["content", "model.client.firstNameSecondClient", ["loc", [null, [30, 70], [30, 108]]]], ["content", "model.client.lastNameSecondClient", ["loc", [null, [31, 74], [31, 111]]]], ["content", "model.client.peselSecondClient", ["loc", [null, [32, 71], [32, 105]]]], ["content", "model.client.identificationDocumentTypeSecondClient.label", ["loc", [null, [34, 49], [34, 110]]]], ["content", "model.client.identificationDocumentNumberSecondClient", ["loc", [null, [37, 49], [37, 106]]]], ["content", "model.client.emailSecondClient", ["loc", [null, [38, 72], [38, 106]]]], ["content", "model.client.phoneSecondClient", ["loc", [null, [39, 73], [39, 107]]]]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 5,
                  "column": 8
                },
                "end": {
                  "line": 59,
                  "column": 8
                }
              },
              "moduleName": "ember-new/templates/renouncement/add/contract-details.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "row");
              var el2 = dom.createTextNode("\n\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col-md-6 col-xs-12");
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("h5");
              var el4 = dom.createTextNode("Dane i kontakt");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("ul");
              dom.setAttribute(el3, "class", "todo-list");
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("li");
              var el5 = dom.createElement("span");
              dom.setAttribute(el5, "class", "text");
              var el6 = dom.createElement("strong");
              var el7 = dom.createTextNode("Imię: ");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("li");
              var el5 = dom.createElement("span");
              dom.setAttribute(el5, "class", "text");
              var el6 = dom.createElement("strong");
              var el7 = dom.createTextNode("Nazwisko: ");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("li");
              var el5 = dom.createElement("span");
              dom.setAttribute(el5, "class", "text");
              var el6 = dom.createElement("strong");
              var el7 = dom.createTextNode("PESEL: ");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("li");
              var el5 = dom.createElement("span");
              dom.setAttribute(el5, "class", "text");
              var el6 = dom.createElement("strong");
              var el7 = dom.createTextNode("Rodzaj dokumentu\n                            tożsamości: ");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                        ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("li");
              var el5 = dom.createElement("span");
              dom.setAttribute(el5, "class", "text");
              var el6 = dom.createElement("strong");
              var el7 = dom.createTextNode("Nr i Seria dokumentu\n                            tożsamości: ");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("li");
              var el5 = dom.createElement("span");
              dom.setAttribute(el5, "class", "text");
              var el6 = dom.createElement("strong");
              var el7 = dom.createTextNode("E-mail: ");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("li");
              var el5 = dom.createElement("span");
              dom.setAttribute(el5, "class", "text");
              var el6 = dom.createElement("strong");
              var el7 = dom.createTextNode("Telefon: ");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("li");
              var el5 = dom.createElement("span");
              dom.setAttribute(el5, "class", "text");
              var el6 = dom.createElement("strong");
              var el7 = dom.createTextNode("Zanonimizowany: ");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col-md-6 col-xs-12");
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("h5");
              var el4 = dom.createTextNode("Adres główny");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("ul");
              dom.setAttribute(el3, "class", "todo-list");
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("li");
              var el5 = dom.createElement("span");
              dom.setAttribute(el5, "class", "text");
              var el6 = dom.createElement("strong");
              var el7 = dom.createTextNode("Ulica: ");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("li");
              var el5 = dom.createElement("span");
              dom.setAttribute(el5, "class", "text");
              var el6 = dom.createElement("strong");
              var el7 = dom.createTextNode("Numer domu: ");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("li");
              var el5 = dom.createElement("span");
              dom.setAttribute(el5, "class", "text");
              var el6 = dom.createElement("strong");
              var el7 = dom.createTextNode("Numer lokalu: ");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("li");
              var el5 = dom.createElement("span");
              dom.setAttribute(el5, "class", "text");
              var el6 = dom.createElement("strong");
              var el7 = dom.createTextNode("Kod pocztowy: ");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("li");
              var el5 = dom.createElement("span");
              dom.setAttribute(el5, "class", "text");
              var el6 = dom.createElement("strong");
              var el7 = dom.createTextNode("Poczta: ");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("li");
              var el5 = dom.createElement("span");
              dom.setAttribute(el5, "class", "text");
              var el6 = dom.createElement("strong");
              var el7 = dom.createTextNode("Miejscowość: ");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("li");
              var el5 = dom.createElement("span");
              dom.setAttribute(el5, "class", "text");
              var el6 = dom.createElement("strong");
              var el7 = dom.createTextNode("Województwo: ");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element4 = dom.childAt(fragment, [1]);
              var element5 = dom.childAt(element4, [1, 3]);
              var element6 = dom.childAt(element4, [5, 3]);
              var morphs = new Array(16);
              morphs[0] = dom.createMorphAt(dom.childAt(element5, [1, 0]), 1, 1);
              morphs[1] = dom.createMorphAt(dom.childAt(element5, [3, 0]), 1, 1);
              morphs[2] = dom.createMorphAt(dom.childAt(element5, [5, 0]), 1, 1);
              morphs[3] = dom.createMorphAt(dom.childAt(element5, [7, 0]), 1, 1);
              morphs[4] = dom.createMorphAt(dom.childAt(element5, [9, 0]), 1, 1);
              morphs[5] = dom.createMorphAt(dom.childAt(element5, [11, 0]), 1, 1);
              morphs[6] = dom.createMorphAt(dom.childAt(element5, [13, 0]), 1, 1);
              morphs[7] = dom.createMorphAt(dom.childAt(element5, [15, 0]), 1, 1);
              morphs[8] = dom.createMorphAt(element4, 3, 3);
              morphs[9] = dom.createMorphAt(dom.childAt(element6, [1, 0]), 1, 1);
              morphs[10] = dom.createMorphAt(dom.childAt(element6, [3, 0]), 1, 1);
              morphs[11] = dom.createMorphAt(dom.childAt(element6, [5, 0]), 1, 1);
              morphs[12] = dom.createMorphAt(dom.childAt(element6, [7, 0]), 1, 1);
              morphs[13] = dom.createMorphAt(dom.childAt(element6, [9, 0]), 1, 1);
              morphs[14] = dom.createMorphAt(dom.childAt(element6, [11, 0]), 1, 1);
              morphs[15] = dom.createMorphAt(dom.childAt(element6, [13, 0]), 1, 1);
              return morphs;
            },
            statements: [["content", "model.client.firstName", ["loc", [null, [12, 70], [12, 96]]]], ["content", "model.client.lastName", ["loc", [null, [13, 74], [13, 99]]]], ["content", "model.client.pesel", ["loc", [null, [14, 71], [14, 93]]]], ["content", "model.client.identificationDocumentType.label", ["loc", [null, [16, 49], [16, 98]]]], ["content", "model.client.identificationDocumentNumber", ["loc", [null, [19, 49], [19, 94]]]], ["content", "model.client.email", ["loc", [null, [20, 72], [20, 94]]]], ["content", "model.client.phone", ["loc", [null, [21, 73], [21, 95]]]], ["inline", "if", [["get", "model.client.isAnonymized", ["loc", [null, [22, 85], [22, 110]]]], "tak", "nie"], [], ["loc", [null, [22, 80], [22, 124]]]], ["block", "if", [["get", "model.client.peselSecondClient", ["loc", [null, [26, 22], [26, 52]]]]], [], 0, null, ["loc", [null, [26, 16], [42, 23]]]], ["content", "model.client.baseAddress.street", ["loc", [null, [47, 71], [47, 106]]]], ["content", "model.client.baseAddress.streetNo", ["loc", [null, [48, 76], [48, 113]]]], ["content", "model.client.baseAddress.aptNo", ["loc", [null, [49, 78], [49, 112]]]], ["content", "model.client.baseAddress.postalCode", ["loc", [null, [50, 78], [50, 117]]]], ["content", "model.client.baseAddress.postalCity", ["loc", [null, [51, 72], [51, 111]]]], ["content", "model.client.baseAddress.city", ["loc", [null, [52, 77], [52, 110]]]], ["content", "model.client.baseAddress.district.label", ["loc", [null, [53, 77], [53, 120]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 60,
                  "column": 8
                },
                "end": {
                  "line": 81,
                  "column": 8
                }
              },
              "moduleName": "ember-new/templates/renouncement/add/contract-details.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("ul");
              dom.setAttribute(el1, "class", "todo-list");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("Typ umowy: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("Czy Dual Fuel: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("Wariant umowy: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("Rodzaj umowy: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("Miejsce zawarcia umowy: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("Produkt: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("Numer umowy: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("Odebrane dokumenty w Fortum: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("Data podpisania: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("Data startu: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("Data końca: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("Liczba miesięcy: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("Termin płatności: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("Produkt: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("Taryfa: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("Cennik: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1]);
              var morphs = new Array(16);
              morphs[0] = dom.createMorphAt(dom.childAt(element2, [1, 0]), 1, 1);
              morphs[1] = dom.createMorphAt(dom.childAt(element2, [3, 0]), 1, 1);
              morphs[2] = dom.createMorphAt(dom.childAt(element2, [5, 0]), 1, 1);
              morphs[3] = dom.createMorphAt(dom.childAt(element2, [7, 0]), 1, 1);
              morphs[4] = dom.createMorphAt(dom.childAt(element2, [9, 0]), 1, 1);
              morphs[5] = dom.createMorphAt(dom.childAt(element2, [11, 0]), 1, 1);
              morphs[6] = dom.createMorphAt(dom.childAt(element2, [13, 0]), 1, 1);
              morphs[7] = dom.createMorphAt(dom.childAt(element2, [15, 0]), 1, 1);
              morphs[8] = dom.createMorphAt(dom.childAt(element2, [17, 0]), 1, 1);
              morphs[9] = dom.createMorphAt(dom.childAt(element2, [19, 0]), 1, 1);
              morphs[10] = dom.createMorphAt(dom.childAt(element2, [21, 0]), 1, 1);
              morphs[11] = dom.createMorphAt(dom.childAt(element2, [23, 0]), 1, 1);
              morphs[12] = dom.createMorphAt(dom.childAt(element2, [25, 0]), 1, 1);
              morphs[13] = dom.createMorphAt(dom.childAt(element2, [27, 0]), 1, 1);
              morphs[14] = dom.createMorphAt(dom.childAt(element2, [29, 0]), 1, 1);
              morphs[15] = dom.createMorphAt(dom.childAt(element2, [31, 0]), 1, 1);
              return morphs;
            },
            statements: [["content", "model.type", ["loc", [null, [63, 67], [63, 81]]]], ["content", "model.isInDualFuel", ["loc", [null, [64, 71], [64, 93]]]], ["content", "model.variant.label", ["loc", [null, [65, 71], [65, 94]]]], ["content", "model.category.label", ["loc", [null, [66, 70], [66, 94]]]], ["content", "model.signingPlace.label", ["loc", [null, [67, 80], [67, 108]]]], ["content", "model.product.label", ["loc", [null, [68, 65], [68, 88]]]], ["content", "model.contractNo", ["loc", [null, [69, 69], [69, 89]]]], ["inline", "format-date-pl", [["get", "model.dateReceived", ["loc", [null, [70, 102], [70, 120]]]]], [], ["loc", [null, [70, 85], [70, 122]]]], ["inline", "format-date-pl", [["get", "model.dateSigned", ["loc", [null, [71, 90], [71, 106]]]]], [], ["loc", [null, [71, 73], [71, 108]]]], ["inline", "format-date-pl", [["get", "model.dateStart", ["loc", [null, [72, 86], [72, 101]]]]], [], ["loc", [null, [72, 69], [72, 103]]]], ["inline", "format-date-pl", [["get", "model.dateEnd", ["loc", [null, [73, 85], [73, 98]]]]], [], ["loc", [null, [73, 68], [73, 100]]]], ["content", "model.numberOfMonths", ["loc", [null, [74, 73], [74, 97]]]], ["content", "model.paymentTerm.label", ["loc", [null, [75, 74], [75, 101]]]], ["content", "model.product.label", ["loc", [null, [76, 65], [76, 88]]]], ["content", "model.tariff.label", ["loc", [null, [77, 64], [77, 86]]]], ["content", "model.basePricesList", ["loc", [null, [78, 64], [78, 88]]]]],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.3.2",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 105,
                        "column": 79
                      },
                      "end": {
                        "line": 105,
                        "column": 130
                      }
                    },
                    "moduleName": "ember-new/templates/renouncement/add/contract-details.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("/");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["content", "point.address.aptNo", ["loc", [null, [105, 107], [105, 130]]]]],
                  locals: [],
                  templates: []
                };
              })();
              var child1 = (function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.3.2",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 108,
                        "column": 24
                      },
                      "end": {
                        "line": 110,
                        "column": 24
                      }
                    },
                    "moduleName": "ember-new/templates/renouncement/add/contract-details.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("td");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                    return morphs;
                  },
                  statements: [["content", "point.tariff.label", ["loc", [null, [109, 32], [109, 54]]]]],
                  locals: [],
                  templates: []
                };
              })();
              var child2 = (function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.3.2",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 110,
                        "column": 24
                      },
                      "end": {
                        "line": 112,
                        "column": 24
                      }
                    },
                    "moduleName": "ember-new/templates/renouncement/add/contract-details.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("td");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                    return morphs;
                  },
                  statements: [["content", "point.tariffOsd.label", ["loc", [null, [111, 32], [111, 57]]]]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.3.2",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 99,
                      "column": 16
                    },
                    "end": {
                      "line": 117,
                      "column": 16
                    }
                  },
                  "moduleName": "ember-new/templates/renouncement/add/contract-details.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("tr");
                  dom.setAttribute(el1, "role", "row");
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("td");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("td");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("td");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("td");
                  var el3 = dom.createTextNode("\n                            ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode(" ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("br");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                            ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode(" ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode(" ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                        ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("td");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("td");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n\n                    ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element0 = dom.childAt(fragment, [1]);
                  var element1 = dom.childAt(element0, [7]);
                  var morphs = new Array(12);
                  morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
                  morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
                  morphs[2] = dom.createMorphAt(dom.childAt(element0, [5]), 0, 0);
                  morphs[3] = dom.createMorphAt(element1, 1, 1);
                  morphs[4] = dom.createMorphAt(element1, 3, 3);
                  morphs[5] = dom.createMorphAt(element1, 4, 4);
                  morphs[6] = dom.createMorphAt(element1, 7, 7);
                  morphs[7] = dom.createMorphAt(element1, 9, 9);
                  morphs[8] = dom.createMorphAt(element1, 11, 11);
                  morphs[9] = dom.createMorphAt(element0, 9, 9);
                  morphs[10] = dom.createMorphAt(dom.childAt(element0, [11]), 0, 0);
                  morphs[11] = dom.createMorphAt(dom.childAt(element0, [13]), 0, 0);
                  return morphs;
                },
                statements: [["inline", "add-one", [["get", "index", ["loc", [null, [101, 38], [101, 43]]]]], [], ["loc", [null, [101, 28], [101, 45]]]], ["content", "point.counterNo", ["loc", [null, [102, 28], [102, 47]]]], ["content", "point.pointNo", ["loc", [null, [103, 28], [103, 45]]]], ["content", "point.address.street", ["loc", [null, [105, 28], [105, 52]]]], ["content", "point.address.streetNo", ["loc", [null, [105, 53], [105, 79]]]], ["block", "if", [["get", "point.address.aptNo", ["loc", [null, [105, 85], [105, 104]]]]], [], 0, null, ["loc", [null, [105, 79], [105, 137]]]], ["content", "point.address.postalCode", ["loc", [null, [106, 28], [106, 56]]]], ["content", "point.address.postalCity", ["loc", [null, [106, 57], [106, 85]]]], ["content", "point.address.city", ["loc", [null, [106, 86], [106, 108]]]], ["block", "if", [["get", "model.isEe", ["loc", [null, [108, 30], [108, 40]]]]], [], 1, 2, ["loc", [null, [108, 24], [112, 31]]]], ["content", "point.osd.label", ["loc", [null, [113, 28], [113, 47]]]], ["content", "point.os.label", ["loc", [null, [114, 28], [114, 46]]]]],
                locals: [],
                templates: [child0, child1, child2]
              };
            })();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 98,
                    "column": 12
                  },
                  "end": {
                    "line": 118,
                    "column": 12
                  }
                },
                "moduleName": "ember-new/templates/renouncement/add/contract-details.hbs"
              },
              isEmpty: false,
              arity: 2,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "unless", [["get", "point.isNew", ["loc", [null, [99, 26], [99, 37]]]]], [], 0, null, ["loc", [null, [99, 16], [117, 27]]]]],
              locals: ["point", "index"],
              templates: [child0]
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 118,
                    "column": 12
                  },
                  "end": {
                    "line": 120,
                    "column": 12
                  }
                },
                "moduleName": "ember-new/templates/renouncement/add/contract-details.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("tr");
                var el2 = dom.createElement("td");
                dom.setAttribute(el2, "colspan", "8");
                dom.setAttribute(el2, "class", "text-center");
                var el3 = dom.createElement("strong");
                var el4 = dom.createTextNode("Brak PPE(G)");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 82,
                  "column": 8
                },
                "end": {
                  "line": 125,
                  "column": 8
                }
              },
              "moduleName": "ember-new/templates/renouncement/add/contract-details.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("table");
              dom.setAttribute(el1, "class", "table table-bordered table-striped");
              dom.setAttribute(el1, "role", "grid");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("thead");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("tr");
              dom.setAttribute(el3, "role", "rowheader");
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("th");
              var el5 = dom.createTextNode("#");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("th");
              var el5 = dom.createTextNode("Numer licznika");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("th");
              var el5 = dom.createTextNode("Numer PPG");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("th");
              var el5 = dom.createTextNode("Adres");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("th");
              var el5 = dom.createTextNode("Grupa taryfowa");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("th");
              var el5 = dom.createTextNode("OSD");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("th");
              var el5 = dom.createTextNode("OS");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("tbody");
              var el3 = dom.createTextNode("\n\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]), 1, 1);
              return morphs;
            },
            statements: [["block", "each", [["get", "model.ppms", ["loc", [null, [98, 20], [98, 30]]]]], [], 0, 1, ["loc", [null, [98, 12], [120, 21]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 4
              },
              "end": {
                "line": 127,
                "column": 4
              }
            },
            "moduleName": "ember-new/templates/renouncement/add/contract-details.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
            morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            return morphs;
          },
          statements: [["block", "bs-tab-pane", [], ["title", "Dane klienta"], 0, null, ["loc", [null, [5, 8], [59, 24]]]], ["block", "bs-tab-pane", [], ["title", "Dane umowy"], 1, null, ["loc", [null, [60, 8], [81, 24]]]], ["block", "bs-tab-pane", [], ["title", "PPE(G)"], 2, null, ["loc", [null, [82, 8], [125, 24]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 130,
                "column": 8
              },
              "end": {
                "line": 132,
                "column": 8
              }
            },
            "moduleName": "ember-new/templates/renouncement/add/contract-details.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            zamknij\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 135,
                  "column": 12
                },
                "end": {
                  "line": 137,
                  "column": 12
                }
              },
              "moduleName": "ember-new/templates/renouncement/add/contract-details.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                wybierz umowę\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 134,
                "column": 8
              },
              "end": {
                "line": 138,
                "column": 8
              }
            },
            "moduleName": "ember-new/templates/renouncement/add/contract-details.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "bs-button", [], ["type", "success", "icon", "fa fa-thumbs-up", "class", "pull-right margin-right", "action", "choose", "value", ["subexpr", "@mut", [["get", "model.id", ["loc", [null, [135, 117], [135, 125]]]]], [], []]], 0, null, ["loc", [null, [135, 12], [137, 26]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "fragmentReason": {
            "name": "missing-wrapper",
            "problems": ["wrong-type", "multiple-nodes"]
          },
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 141,
              "column": 0
            }
          },
          "moduleName": "ember-new/templates/renouncement/add/contract-details.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "modal-footer");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element7 = dom.childAt(fragment, [3]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(element7, 1, 1);
          morphs[2] = dom.createMorphAt(element7, 3, 3);
          return morphs;
        },
        statements: [["block", "bs-tab", [], ["type", "tabs"], 0, null, ["loc", [null, [3, 4], [127, 15]]]], ["block", "bs-button", [], ["type", "primary", "icon", "fa fa-close", "class", "pull-right", "action", "closeModal"], 1, null, ["loc", [null, [130, 8], [132, 22]]]], ["block", "if", [["get", "model.canBeAssignedRenouncement", ["loc", [null, [134, 14], [134, 45]]]]], [], 2, null, ["loc", [null, [134, 8], [138, 15]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.3.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 141,
            "column": 18
          }
        },
        "moduleName": "ember-new/templates/renouncement/add/contract-details.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "subpage-modal", [], ["subpageTitle", "Szczegóły umowy", "modalCloseRoute", "renouncement.add"], 0, null, ["loc", [null, [1, 0], [141, 18]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});
define('ember-new/routes/individual-contract-price-lists/template/vaps/delete', ['exports', 'ember', 'ember-new/routes/authenticated'], function (exports, _ember, _emberNewRoutesAuthenticated) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({
        model: function model(params) {
            var content = this.store.query('IndividualContractTemplateVapListViewModel', { templateUuid: this.modelFor('individual-contract-price-lists.template.vaps').template.id, vapUuid: params.vapUuid }, { reload: true });

            return content;
        },

        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            }
        }
    });
});
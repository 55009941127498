define("ember-new/routes/tasks/task/favourite", ["exports", "ember-new/routes/authenticated", "ember"], function (exports, _emberNewRoutesAuthenticated, _ember) {
    exports["default"] = _emberNewRoutesAuthenticated["default"].extend({
        keyboard: _ember["default"].inject.service(),

        closeModal: _ember["default"].on('keyUp:Escape', function () {
            this.controller.transitionToRoute("tasks");
        }),

        actions: {
            didTransition: function didTransition() {
                this._super.apply(this, arguments);
                this.get('keyboard').activate(this);

                return true;
            },
            willTransition: function willTransition() {
                this._super.apply(this, arguments);
                this.get('keyboard').deactivate(this);
            }
        }
    });
});
define('ember-new/components/profile-notifications-form', ['exports', 'ember'], function (exports, _ember) {

  var ProfileNotificationsFormComponent = _ember['default'].Component.extend({

    fillForm: function fillForm(form) {
      form.set('model.smsNotification', this.get('model.smsNotification'));
      form.set('model.emailNotification', this.get('model.emailNotification'));
    },

    profileNotificationsUrl: (function () {
      return '/ajax/profile/edit/notification';
    }).property()

  });

  exports['default'] = ProfileNotificationsFormComponent;
});
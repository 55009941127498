define('ember-new/components/d-field-simple-select-dictionary-uuid-multiple', ['exports', 'ember', 'ember-new/mixins/d-form/d-field'], function (exports, _ember, _emberNewMixinsDFormDField) {
    exports['default'] = _ember['default'].Select.extend(_emberNewMixinsDFormDField['default'], {
        classNames: ['form-control', 'width-100percent'],
        multiple: true,

        dictionariesManager: _ember['default'].inject.service('dictionaries-manager'),
        optionLabelPath: "content.label",
        optionValuePath: "content",

        // requred options:
        dictionaryName: null,

        initialize: (function () {
            var dictionaryType = this.get('dictionaryName');

            if (dictionaryType === undefined || dictionaryType === null || typeof dictionaryType !== 'string') {
                throw new _ember['default'].Error('Component d-field-select-dictionary requires `dictionaryName` option.');
            }

            var dicManager = this.get('dictionariesManager');

            if (!dicManager.hasMainDictionary(dictionaryType)) {
                throw new _ember['default'].Error("Option 'dictionaryName` passed to d-field-select-dictionary component is wrong. There is no `" + dictionaryType + "` main-dictionary.");
            }

            var content = dicManager.getMainDictionary(dictionaryType);
            var selectArray = [];

            content.forEach(function (item) {
                if (item.get('isHidden') != true) {
                    selectArray.push({ label: item.get('label'), value: item.get('uuid') });
                }
            });

            if (typeof this.customActionBeforeSetContent == 'function') {
                content = this.customActionBeforeSetContent.call(this, content);
            }

            this.set('content', selectArray);
        }).on('init'),

        onSelection: (function () {
            var value = [];
            if (this.get('selection')) {
                this.get('selection').forEach(function (item) {
                    if (item) {
                        value.push(item.value);
                    }
                });
            }
            console.log('values', value);
            this.set('values', value);
        }).observes('selection')

    });
});
define('ember-new/components/submenu-item', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'a',
        classNames: 'submenu-item'.w(),
        title: null,
        icon: null,
        badge: null,
        hidden: false,

        click: function click() {
            this.sendAction('action', this.get('param'));
        }
    });
});
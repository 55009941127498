define('ember-new/mixins/individual-contract/show/process', ['exports', 'ember', 'ember-new/config/environment', 'ember-new/mixins/controllers/unsaved-data'], function (exports, _ember, _emberNewConfigEnvironment, _emberNewMixinsControllersUnsavedData) {
    exports['default'] = _ember['default'].Mixin.create(_emberNewMixinsControllersUnsavedData['default'], {

        isEditable: (function () {
            var verificationCall = this.get('contract.credentials.editVerificationCall');
            var isAnonymized = this.get('model.isAnonymized');
            return verificationCall && !isAnonymized;
        }).property()

    });
});
define('ember-new/models/gas-overhead-dictionary-entry', ['exports', 'ember-new/models/abstract', 'ember-data'], function (exports, _emberNewModelsAbstract, _emberData) {

    var GasOverheadDictionaryEntry = _emberNewModelsAbstract['default'].extend({
        label: _emberData['default'].attr('string'),
        name: _emberData['default'].attr('string'),
        value: _emberData['default'].attr('number'),
        hidden: _emberData['default'].attr('boolean')
    });

    exports['default'] = GasOverheadDictionaryEntry;
});
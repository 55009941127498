define('ember-new/services/keyboard', ['exports', 'ember', 'ember-new/utils/handle-key-event'], function (exports, _ember, _emberNewUtilsHandleKeyEvent) {
    var computed = _ember['default'].computed;
    var on = _ember['default'].on;
    var Service = _ember['default'].Service;
    exports['default'] = Service.extend({
        responderStack: computed(function () {
            return _ember['default'].A();
        }),

        activate: function activate(responder) {
            var responderStack = this.get('responderStack');

            // ensure the responder appears only once in the stack
            responderStack.removeObject(responder);
            responderStack.unshiftObject(responder);
        },

        deactivate: function deactivate(responder) {
            this.get('responderStack').removeObject(responder);
        },

        _initializeListener: on('init', function () {
            var _this = this;

            var eventNames = ['keyup', 'keydown'].map(function (name) {
                return name + '.ember-keyboard-listener';
            }).join(' ');

            _ember['default'].$(document).on(eventNames, null, function (event) {
                (0, _emberNewUtilsHandleKeyEvent['default'])(event, _this.get('responderStack'));
            });
        }),

        _teardownListener: on('isDestroying', function () {
            _ember['default'].$(document).off('.ember-keyboard-listener');
        })
    });
});
define('ember-new/models/contract', ['exports', 'ember-new/models/abstract', 'ember-new/models/base-statusable-document-mixin'], function (exports, _emberNewModelsAbstract, _emberNewModelsBaseStatusableDocumentMixin) {

    var Contract = _emberNewModelsAbstract['default'].extend(_emberNewModelsBaseStatusableDocumentMixin['default'], {
        // relations:
        client: DS.belongsTo('AmbBusinessClient', { inverse: null }),
        basePricing: DS.belongsTo('PricesList', { inverse: null }),
        baseMinPricing: DS.belongsTo('PricesList', { inverse: null }),
        ppe: DS.hasMany('Ppe', { inverse: 'contract' }),

        // embedded:
        address: DS.belongsTo('Address', { embedded: true }),
        contactAddress: DS.belongsTo('Address', { embedded: true }),
        tariffGroupsPrices: DS.attr(),

        // dictionaries:
        region: DS.belongsTo('DistrictDictionaryEntry', { inverse: false }),
        contractType: DS.belongsTo('ContractTypeDictionaryEntry', { inverse: false }),
        campaign: DS.belongsTo('PlainTextDictionaryEntry', { inverse: false }),
        product: DS.belongsTo('ProductDictionaryEntry', { inverse: false }),
        settlementPeriod: DS.belongsTo('SettlementPeriodDictionaryEntry', { inverse: false }),
        paymentTerm: DS.belongsTo('PaymentTermDictionaryEntry', { inverse: false }),
        tariffGroup: DS.belongsTo('TariffGroupDictionaryEntry', { inverse: false }),
        meetingSource: DS.belongsTo('TaskSourceDictionaryEntry', { inverse: false }),

        // basic properties
        contractDateSigned: DS.attr('string'),
        contractDateFrom: DS.attr(),
        contractDateTo: DS.attr('string'),
        dateOfProvision: DS.attr('string'),
        ppeNoDeclared: DS.attr('number'),
        dateOfReceptionContract: DS.attr('string'),
        representatives: DS.attr(),
        contactPersons: DS.attr(),

        estimatedConsumptionByConsultant: DS.attr('string'), //z number
        averageVolumeOfContractByConsultant: DS.attr('string'), //z number
        averageOverheadByConsultant: DS.attr('string'), //z number
        marginByConsultant: DS.attr('string'), //z number

        averageOverheadBySystem: DS.attr('number'),
        marginBySystem: DS.attr('number'),
        marginWithCommercialBySystem: DS.attr('number'),
        volumeBySystem: DS.attr('number'),
        consumptionBySystem: DS.attr('number'),

        consumptionByDzU: DS.attr('number'),
        marginByDzU: DS.attr('number'),
        marginWithCommercialByDzU: DS.attr('number'),
        marginWithCommercialForManagement: DS.attr('number'),
        volumeByDzU: DS.attr('number'),
        averageOverheadByDzU: DS.attr('number'),

        margin: DS.attr('number'),
        contractNumber: DS.attr('string'),

        marginInReport: DS.attr('number'),
        computation: DS.attr(),
        showLastSeenContractsComments: DS.attr(),

        // flags & packages & audits
        agreementEFaktura: DS.attr('boolean', { defaultValue: false }),
        eFakturaEmail: DS.attr('string', { defaultValue: null }),
        agreementPrzetwarzanieOsobowych: DS.attr('boolean', { defaultValue: false }),
        agreementUdostepnienieOsobowych: DS.attr('boolean', { defaultValue: false }),
        agreementInformacjeHandlowe: DS.attr('boolean', { defaultValue: false }),

        flagAudytEE: DS.attr('boolean', { defaultValue: false }),
        flagAudytEECykl: DS.attr('boolean', { defaultValue: false }),
        flagAudytGas: DS.attr('boolean', { defaultValue: false }),
        flagAudytGasCykl: DS.attr('boolean', { defaultValue: false }),
        flagAudytFT: DS.attr('boolean', { defaultValue: false }),

        audytEEPlannedDate: DS.attr('string', { defaultValue: null }),
        audytEEDoneDate: DS.attr('string', { defaultValue: null }),
        audytEECyklPlannedDate: DS.attr('string', { defaultValue: null }),
        audytEECyklDoneDate: DS.attr('string', { defaultValue: null }),
        audytGasPlannedDate: DS.attr('string', { defaultValue: null }),
        audytGasDoneDate: DS.attr('string', { defaultValue: null }),
        audytGasCyklPlannedDate: DS.attr('string', { defaultValue: null }),
        audytGasCyklDoneDate: DS.attr('string', { defaultValue: null }),
        audytFTPlannedDate: DS.attr('string', { defaultValue: null }),
        audytFTDoneDate: DS.attr('string', { defaultValue: null }),

        packageProduct: DS.belongsTo('DuonPackageProductDictionaryEntry', { inverse: null }),
        packagePradDlaDomu: DS.attr('boolean', { defaultValue: false }),
        packagePradDlaDomuPESELs: DS.attr('string'),
        packageGazPNP: DS.attr('boolean', { defaultValue: false }),
        packageGazPNPPESELs: DS.attr('string'),
        packageDedykowanaObslugaKlienta: DS.attr('boolean', { defaultValue: false }),
        packageDedykowanaObslugaKlientaComment: DS.attr('string'),

        // ??? @BH: potrzebne still? ~KJ
        consumptionsByYears: DS.attr(),

        completeChecklist: DS.attr('boolean'),
        completionOfDocuments: DS.attr('boolean'),

        isOnFortumTemplate: DS.attr('boolean'),

        // VirtualProperty
        numberOfMonths: DS.attr('number'),
        years: DS.attr(),

        mainTariffGroup: DS.belongsTo('TariffGroupDictionaryEntry', { inverse: false }),
        companySector: DS.belongsTo('BranchDictionaryEntry', { inverse: false }),
        hasLoyaltyContract: DS.attr('number'),
        loyaltyContractExpired: DS.attr('string'), //Umyślnie ~BH
        loyaltyStatus: DS.attr('number'),
        loyaltyStatusText: DS.attr('string'),
        loyaltyDate: DS.attr('string'),
        loyaltyShowDate: (function () {
            return this.get('loyaltyStatus') == 3;
        }).property('loyaltyStatus'),
        loyaltyContractRepudiateMonths: DS.attr('number'),
        os: DS.belongsTo('OsDictionaryEntry', { inverse: null }),
        osd: DS.belongsTo('OsdDictionaryEntry', { inverse: null }),

        // dokumenty
        umowaSprzedazyEnergiiElektrycznejDocument: DS.belongsTo('File', { inverse: null }),
        pelnomocnictwoKlientaDlaDuonDocument: DS.belongsTo('File', { inverse: null }),
        ceigdDocument: DS.belongsTo('File', { inverse: null }),
        kserokopiaNipDocument: DS.belongsTo('File', { inverse: null }),
        kserokopiaRegonDocument: DS.belongsTo('File', { inverse: null }),
        kserokopiaWypisuRejestruEwidencjiGospodarczejDocument: DS.belongsTo('File', { inverse: null }),
        kserokopiaTytuluPrawnegoDoLokaluDocument: DS.belongsTo('File', { inverse: null }),
        oswiadczenieModernizacjiDocument: DS.belongsTo('File', { inverse: null }),
        kserokopiaFakturyOsdDocument: DS.belongsTo('File', { inverse: null }),
        zalacznikNr1DoUmowySprzedazyDocument: DS.belongsTo('File', { inverse: null }),
        ogolneWarunkiSprzedazyEnergiiElektrycznejDocument: DS.belongsTo('File', { inverse: null }),
        kserokopiaDotychczasowejUmowySprzedazowejLubKompleksowejDocument: DS.belongsTo('File', { inverse: null }),
        krsDocument: DS.belongsTo('File', { inverse: null }),
        kserokopiaUmowySpolkiDocument: DS.belongsTo('File', { inverse: null }),
        inneRejestroweDocument: DS.belongsTo('File', { inverse: null }),
        fakturaSprzedazowaDocument: DS.belongsTo('File', { inverse: null }),
        inneZaswiadczeniaDocument: DS.belongsTo('File', { inverse: null }),

        wniosekAktualizacjaDanych: DS.belongsTo('File', { inverse: null }),
        wniosekAktualizacjaDanychZalaczniki: DS.belongsTo('File', { inverse: null }),
        dodatkoweUpowaznienia: DS.belongsTo('File', { inverse: null }),
        wniosekZmianaGrupyTaryfowej: DS.belongsTo('File', { inverse: null }),
        dokumentyDoNowegoPrzylaczaDocument: DS.belongsTo('File', { inverse: null }),

        status51Times: DS.attr('number'),

        // relations:
        eeAcquisitionProcess: DS.belongsTo('eeAcquisitionProcess', { inverse: null, async: false }),
        gasAcquisitionProcess: DS.belongsTo('gasAcquisitionProcess', { inverse: null, async: false }),
        acqType: DS.attr('string'), // virtual property
        acquisitionProcess: (function () {
            console.log("VIRTUAL PROPERTY CALLED", this.get('acqType'));
            if (this.get('acqType') == 'ee') {
                return this.get('eeAcquisitionProcess');
            } else {
                return this.get('gasAcquisitionProcess');
            }
        }).property('acqType'),

        boSpecialist: DS.belongsTo('User', { inverse: null, async: false }),
        dzu2Specialist: DS.belongsTo('User', { inverse: null, async: false }),
        salesman: DS.belongsTo('User', { inverse: null, async: false }),
        ccConsultant: DS.belongsTo('User', { inverse: null, async: false }),
        comments: DS.hasMany('AmbVerificationProcessComment', { inverse: 'context' }),
        latestDzuComment: DS.belongsTo('AmbVerificationProcessComment', { inverse: null }),
        latestDzu2Comment: DS.belongsTo('AmbVerificationProcessComment', { inverse: null }),
        latestDzuForDzu2Comment: DS.belongsTo('AmbVerificationProcessComment', { inverse: null }),
        latestSalesmanComment: DS.belongsTo('AmbVerificationProcessComment', { inverse: null }),

        manualMm: DS.attr('boolean'),

        newConnection: DS.belongsTo('NewConnectionDictionaryEntry', { inverse: false }),
        isNewDeviceSetManually: DS.attr('boolean'),

        showDownloadAllFiles: (function () {
            var ret = false;
            if (this.get('umowaSprzedazyEnergiiElektrycznejDocument')) ret = true;
            if (this.get('pelnomocnictwoKlientaDlaDuonDocument')) ret = true;
            if (this.get('ceigdDocument')) ret = true;
            if (this.get('kserokopiaNipDocument')) ret = true;
            if (this.get('kserokopiaRegonDocument')) ret = true;
            if (this.get('kserokopiaWypisuRejestruEwidencjiGospodarczejDocument')) ret = true;
            if (this.get('kserokopiaTytuluPrawnegoDoLokaluDocument')) ret = true;
            if (this.get('oswiadczenieModernizacjiDocument')) ret = true;
            if (this.get('kserokopiaFakturyOsdDocument')) ret = true;
            if (this.get('zalacznikNr1DoUmowySprzedazyDocument')) ret = true;
            if (this.get('ogolneWarunkiSprzedazyEnergiiElektrycznejDocument')) ret = true;
            if (this.get('kserokopiaDotychczasowejUmowySprzedazowejLubKompleksowejDocument')) ret = true;
            if (this.get('krsDocument')) ret = true;
            if (this.get('kserokopiaUmowySpolkiDocument')) ret = true;
            if (this.get('inneRejestroweDocument')) ret = true;
            if (this.get('fakturaSprzedazowaDocument')) ret = true;
            if (this.get('inneZaswiadczeniaDocument')) ret = true;
            if (this.get('dokumentyDoNowegoPrzylaczaDocument')) ret = true;
            return ret;
        }).property('umowaSprzedazyEnergiiElektrycznejDocument', 'pelnomocnictwoKlientaDlaDuonDocument', 'ceigdDocument', 'kserokopiaNipDocument', 'kserokopiaRegonDocument', 'kserokopiaWypisuRejestruEwidencjiGospodarczejDocument', 'kserokopiaTytuluPrawnegoDoLokaluDocument', 'oswiadczenieModernizacjiDocument', 'kserokopiaFakturyOsdDocument', 'zalacznikNr1DoUmowySprzedazyDocument', 'ogolneWarunkiSprzedazyEnergiiElektrycznejDocument', 'kserokopiaDotychczasowejUmowySprzedazowejLubKompleksowejDocument', 'krsDocument', 'kserokopiaUmowySpolkiDocument', 'inneRejestroweDocument', 'fakturaSprzedazowaDocument', 'inneZaswiadczeniaDocument', 'dokumentyDoNowegoPrzylaczaDocument'),

        phoneCallsFiles: DS.hasMany('File', { inverse: null }),
        isMMVisibleForSalesmen: DS.attr('boolean'),
        isMainClientInValuation: DS.attr('boolean'),

        executionDepartment: DS.belongsTo('ExecutionDepartmentDictionaryEntry', { inverse: null }),

        isSubstitutePowerOfAttorney: DS.attr('boolean'),
        substitutePowerOfAttorneyDescription: DS.attr('string'),

        isValuationMeetsConditions: DS.attr('boolean'),
        valuationConditionsInfo: DS.attr('string')
    });

    exports['default'] = Contract;
});
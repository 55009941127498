define('ember-new/initializers/route-styles', ['exports', 'ember-component-css/initializers/route-styles'], function (exports, _emberComponentCssInitializersRouteStyles) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberComponentCssInitializersRouteStyles['default'];
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function get() {
      return _emberComponentCssInitializersRouteStyles.initialize;
    }
  });
});
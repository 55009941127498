define('ember-new/controllers/renouncement/list', ['exports', 'ember-new/config/environment', 'ember', 'ember-new/mixins/sortable-controller', 'ember-new/mixins/filterable-controller', 'ember-new/mixins/paginable-controller', 'ember-new/mixins/batch-actions-controller', 'ember-new/utils/utils'], function (exports, _emberNewConfigEnvironment, _ember, _emberNewMixinsSortableController, _emberNewMixinsFilterableController, _emberNewMixinsPaginableController, _emberNewMixinsBatchActionsController, _emberNewUtilsUtils) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsSortableController['default'], _emberNewMixinsFilterableController['default'], _emberNewMixinsPaginableController['default'], _emberNewMixinsBatchActionsController['default'], {

        cookies: _ember['default'].inject.service(),
        dictionariesManager: _ember['default'].inject.service('dictionaries-manager'),
        init: function init() {
            this._super.apply(this, arguments);
            this._initTpaSpecialistsToFilter();
            this._initEfficienciesToFilter();
        },

        queryParams: ['renContractNo', 'contractNo', 'lastName', 'firstName', 'pesel', 'contractStatus', 'datePostmarkFrom', 'datePostmarkTo', 'dateEnterFrom', 'dateEnterTo', 'statusChangedDateFrom', 'statusChangedDateTo', 'dateCreatedFrom', 'dateCreatedTo', 'efficiency', 'requiresResponse', 'hasResponse', 'type', 'hasContractsProposal', 'street', 'streetNo', 'postalCode', 'city', 'tpaSpecialist', 'resignationIsOriginal', 'isAnonymized'],

        renContractNo: '',
        contractNo: '',
        lastName: '',
        firstName: '',
        pesel: '',
        contractStatus: '',
        datePostmarkFrom: '',
        datePostmarkTo: '',
        dateEnterFrom: '',
        dateEnterTo: '',
        dateCreatedFrom: '',
        dateCreatedTo: '',
        statusChangedDateFrom: '',
        statusChangedDateTo: '',
        efficiency: '',
        requiresResponse: '',
        hasResponse: '',
        type: '',
        hasContractsProposal: '',
        tpaSpecialist: '',
        street: '',
        streetNo: '',
        postalCode: '',
        city: '',
        isAnonymized: '',

        sortBy: 'dateCreated',
        sortModeAsc: false,

        efficiencyToFilter: [{ label: '---', value: null }, { label: 'skuteczne', value: 's' }, { label: 'nieskuteczne', value: 'n' }, { label: 'brak', value: 'b' }],

        yesNoToFilter: [{ label: 'tak', value: 'true' }, { label: 'nie', value: 'false' }],

        yesNoNullToFilter: [{ label: 'tak', value: 'true' }, { label: 'nie', value: 'false' }, { label: 'bez wartości', value: 'brak' }],

        typesToFilter: [{ label: 'EE', value: 'ee' }, { label: 'Gaz', value: 'gas' }, { label: 'Pakiet Medyczny', value: 'pm' }],
        typesToSelected: _ember['default'].computed('type', function () {
            var _this = this;

            return this.get('typesToFilter').filter(function (item) {
                return _this.get('type').split(',').includes(item.value);
            });
        }),
        efficienciesToFilter: [],
        _initEfficienciesToFilter: function _initEfficienciesToFilter() {
            var _this2 = this;

            var content = this.get('dictionariesManager').getMainDictionary('renouncement_status');
            content = content.filter(function (item) {
                return item.get('isHidden') !== true;
            });

            if (typeof this.customActionBeforeSetContent == 'function') {
                content = this.customActionBeforeSetContent.call(this, content);
            }
            content.forEach(function (item) {
                _this2.get('efficienciesToFilter').push({ value: item.get('id'), label: item.get('name') });
            });
        },
        efficienciesToSelected: _ember['default'].computed('efficiency', function () {
            var _this3 = this;

            return this.get('efficienciesToFilter').filter(function (item) {
                return _this3.get('efficiency').split(',').includes(item.value);
            });
        }),

        resignationIsOriginalToSelected: _ember['default'].computed('resignationIsOriginal', function () {
            var _this4 = this;

            console.log(this.get('resignationIsOriginal'));
            return this.get('yesNoNullToFilter').find(function (item) {
                return item.value === _this4.get('resignationIsOriginal');
            });
        }),

        isAnonymizedToSelected: _ember['default'].computed('isAnonymized', function () {
            var _this5 = this;

            return this.get('yesNoToFilter').find(function (item) {
                return item.value === _this5.get('isAnonymized');
            });
        }),

        batchActions: [{ label: 'Zmień pole "Czy odpowiedź udzielona"', value: 'batchResponse' }, { label: 'Zmień Opiekuna TPA', value: 'batchTpaSpecialist' }],

        tpaSpecialistsToFilter: [],
        _initTpaSpecialistsToFilter: function _initTpaSpecialistsToFilter() {
            var _this6 = this;

            this.get('tpaSpecialistsToFilter').push({
                value: 'brak',
                label: 'Brak opiekuna'
            });
            this.get('dictionariesManager').getUsersDictionary('dzu2_users_by_user').forEach(function (item) {
                _this6.get('tpaSpecialistsToFilter').push({
                    value: item.id,
                    label: item.fullNameWithRole
                });
            });
        },
        tpaSpecialistsToSelected: _ember['default'].computed('tpaSpecialist', function () {
            var _this7 = this;

            return this.get('tpaSpecialistsToFilter').filter(function (item) {
                return _this7.get('tpaSpecialist').split(',').includes(item.value);
            });
        }),

        actions: {
            filterBy: function filterBy(filterName, selection) {
                console.log('filter baj');
                var val = selection ? selection.value : '';
                this.set(filterName, val);
            },

            refresh: function refresh() {
                this.send('refreshRoute');
            },

            exportRenouncements: function exportRenouncements() {
                var url = _emberNewUtilsUtils['default'].generateSimpleUrl('/individual/export/renouncement', '?', _emberNewConfigEnvironment['default'], this.get('TheUser'), this.get('cookies'));
                window.open(url);
            },

            batchResponse: function batchResponse() {
                this.transitionToRoute('renouncement.batch.response');
            },

            batchTpaSpecialist: function batchTpaSpecialist() {
                this.transitionToRoute('renouncement.batch.tpa');
            }
        },

        exportOvernightRenouncementLink: _ember['default'].computed(function () {
            return _emberNewUtilsUtils['default'].generateSimpleUrl('/individual/export/renouncements/overnightexportfull', '?', _emberNewConfigEnvironment['default'], this.get('TheUser'), this.get('cookies'));
        }),

        exportRenouncementLink: _ember['default'].computed(function () {
            return _emberNewUtilsUtils['default'].generateSimpleUrl('/individual/export/renouncements', '?', _emberNewConfigEnvironment['default'], this.get('TheUser'), this.get('cookies'));
        }),

        isAllowedToAddRenouncement: (function () {
            return !this.get('TheUser').isMainRoleIn(['ROLE_GDPR_SPECIALIST']);
        }).property(),

        isAllowedToChangeMarked: (function () {
            return !this.get('TheUser').isMainRoleIn(['ROLE_GDPR_SPECIALIST']);
        }).property()
    });
});
define("ember-new/models/individual-ppm-invoice", ["exports", "ember", "ember-data", "ember-new/models/abstract"], function (exports, _ember, _emberData, _emberNewModelsAbstract) {

    var attr = _emberData["default"].attr;

    exports["default"] = _emberNewModelsAbstract["default"].extend({
        consumption1: attr('string'),
        consumption2: attr('string'),
        consumption3: attr('string'),

        dateFrom: attr('string'),
        dateTo: attr('string')
    });
});
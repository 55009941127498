define('ember-new/-extensions/ajax-load-data-to-store', ['exports', 'ember-new/utils/ajax/get'], function (exports, _emberNewUtilsAjaxGet) {

	var ajaxLoadDataToStore = function ajaxLoadDataToStore(url) {
		var promise = (0, _emberNewUtilsAjaxGet['default'])(url);
		promise.then(function (payload) {
			__container__.lookup('store:main').pushPayload(payload);
		}, function (response) {});

		return promise;
	};

	exports['default'] = ajaxLoadDataToStore;
});
define('ember-new/controllers/tasks/clients-add', ['exports', 'ember-new/config/environment', 'ember'], function (exports, _emberNewConfigEnvironment, _ember) {

    var ClientsAddController = _ember['default'].Controller.extend({
        cerrors: null,
        actions: {
            validate: function validate() {

                this.set('cerrors', null);
                var _this = this;

                this.model.save().then(function (response) {
                    _this.transitionToRoute('clients', _this.model.get('id'));
                }, function (response) {
                    _this.set('cerrors', _ember['default'].Object.create(response.responseJSON.errors.children));
                });
            }
        }
    });

    exports['default'] = ClientsAddController;
});
define('ember-new/routes/individual-contract/status-history', ['exports', 'ember-new/routes/authenticated', 'ember'], function (exports, _emberNewRoutesAuthenticated, _ember) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({
        model: function model(_ref) {
            var contractId = _ref.contractId;

            this.set('contractId', contractId);
        },

        setupController: function setupController(ctrl, model) {
            var conId = this.get('contractId');
            ctrl.set('isLoading', true);

            // pobrac dane z endpointa
            // i zapisac do modelu
            // robimy w setupController zeby nie czekac na zaladownie routa
            this.store.query('individual-contract-bo-comments-view-model', { contractId: conId }).then(function (res) {
                _ember['default'].run.later(function () {
                    ctrl.set('model', res);
                    ctrl.set('isLoading', false);
                });
            });

            var contract = this.store.peekRecord('individual-contract-simplified', conId);
            ctrl.set('contractType', contract.get('conType'));
            ctrl.set('contractId', conId);
        },
        resetController: function resetController(controller, isExiting, transition) {
            this._super.apply(this, arguments);

            if (isExiting) {
                controller.resetData(); // or whatever function you want to call
            }
        }

    });
});
define("ember-new/routes/individual-contract/batch/comment", ["exports", "ember", "ember-new/routes/authenticated", "ember-new/mixins/routes/unsaved-alert", "ember-new/mixins/routes/individual-contract/add-ee", "ember-new/config/environment"], function (exports, _ember, _emberNewRoutesAuthenticated, _emberNewMixinsRoutesUnsavedAlert, _emberNewMixinsRoutesIndividualContractAddEe, _emberNewConfigEnvironment) {
    exports["default"] = _emberNewRoutesAuthenticated["default"].extend({
        setupController: function setupController(controller, model) {

            if (!model.contract.isAny('checked')) {
                alert("Aby zmienić status zaznacz przynajmniej jedną umowę!");
                this.transitionTo('individual-contract');
                return;
            }

            this._super(controller, model.contract);
            controller.clear();
        }
    });
});
define('ember-new/components/d-form/individual-contract/manual-prices', ['exports', 'ember', 'ember-new/components/d-form', 'ember-new/templates/components/d-form', 'ember-new/config/environment'], function (exports, _ember, _emberNewComponentsDForm, _emberNewTemplatesComponentsDForm, _emberNewConfigEnvironment) {
    exports['default'] = _emberNewComponentsDForm['default'].extend({
        layout: _emberNewTemplatesComponentsDForm['default'],

        contractId: null,
        type: null,

        submitRestAsSimple: true,
        submitModelNamespace: 'pricesList',
        submitRestAsSimpleMethod: 'PATCH',

        submitSimpleUrl: (function () {
            return _emberNewConfigEnvironment['default'].apiUrl + '/rest/individual' + this.get('type') + 'contracts/' + this.get('contractId') + '/edit/manual/prices/list';
        }).property()

    });
});
define('ember-new/models/base-price-set-rows-list-dzu-view-model', ['exports', 'ember-new/models/abstract', 'ember-data'], function (exports, _emberNewModelsAbstract, _emberData) {
    exports['default'] = _emberNewModelsAbstract['default'].extend({
        dateFrom: _emberData['default'].attr('string'),
        dateTo: _emberData['default'].attr('string'),
        tariffGroup: _emberData['default'].attr('string'),
        basePriceArea1: _emberData['default'].attr('string'),
        basePriceArea2: _emberData['default'].attr('string'),
        basePriceArea3: _emberData['default'].attr('string'),
        basePriceArea4: _emberData['default'].attr('string'),
        basePriceFlatten: _emberData['default'].attr('string'),
        baseFee: _emberData['default'].attr('string')
    });
});
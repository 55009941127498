define("ember-new/controllers/tasks/tasks-client/company", ["exports", "ember-new/config/environment", "ember-new/controllers/abstract-task-modal", "ember"], function (exports, _emberNewConfigEnvironment, _emberNewControllersAbstractTaskModal, _ember) {
	exports["default"] = _ember["default"].Controller.extend({
		queryParams: ['taskUuid', 'taskId'],
		taskUuid: null,
		taskId: null,

		fillForm: function fillForm(form) {
			form.set('model.clientUuid', this.get('model.uuid'));
			form.set('model.companyName', this.get('model.companyName'));
			form.set('model.street', this.get('model.address.street'));
			form.set('model.streetNo', this.get('model.address.streetNo'));
			form.set('model.aptNo', this.get('model.address.aptNo'));
			form.set('model.postalCode', this.get('model.address.postalCode'));
			form.set('model.postalCity', this.get('model.address.postalCity'));
			form.set('model.city', this.get('model.address.city'));
			form.set('model.district', this.get('model.address.districtUuid'));
		},

		afterCommand: function afterCommand() {
			var ctrl = this;
			ctrl.get('model').reload();
			ctrl.transitionToRoute('tasks');
			var route = this.container.lookup('route:tasks');
			route.send('refreshModel');
			route.refresh();
		}
	});
});
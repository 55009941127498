define('ember-new/components/d-form/b2b-contract/administration/section-products/edit-to-condition', ['exports', 'ember-new/components/d-form-command', 'ember-new/templates/components/d-form'], function (exports, _emberNewComponentsDFormCommand, _emberNewTemplatesComponentsDForm) {
    exports['default'] = _emberNewComponentsDFormCommand['default'].extend({
        layout: _emberNewTemplatesComponentsDForm['default'],

        commandName: 'EditProductToConditionCommand',

        indexObserver: (function () {
            if (this.get('formData') && this.get('formRows.productModel')) {
                if (this.get('formData.isIndex')) {
                    this.set('formRows.productModel.hidden', false);
                } else {
                    this.set('formRows.productModel.hidden', true);
                    this.set('formData.productModel', null);
                }
            }
        }).observes('formData.isIndex'),

        afterSuccessfulSubmit: function afterSuccessfulSubmit() {
            var targetController = this.get('targetObject');
            var route = this.container.lookup('route:b2b-contract.administration.section-products.list');
            route.refresh();
            setTimeout(function () {
                targetController.transitionToRoute('b2b-contract.administration.section-products.list');
            }, 500);
        }

    });
});
define('ember-new/controllers/login', ['exports', 'ember', 'ember-new/config/environment'], function (exports, _ember, _emberNewConfigEnvironment) {

    var LoginController = _ember['default'].Controller.extend({
        username: null,
        password: null,
        warning: null,
        loading: false,
        appType: '',
        noValidBrowser: true,
        reCaptchaOn: false,
        reCaptchaValue: null,
        // siteKey: "6LfipscZAAAAAHJZPMieEqH-AMP-Itwapeoz_lfm",
        siteKey: null,

        session: _ember['default'].inject.service('session'),

        onStart: (function () {
            _ember['default'].$('#application_boostraping').hide();

            this.set('siteKey', _emberNewConfigEnvironment['default'].reCaptchaSiteKey);

            if (_emberNewConfigEnvironment['default'].applicationType === 'DSP') {
                this.set('appType', 'TRA');
            } else if (_emberNewConfigEnvironment['default'].applicationType === 'DMT') {
                this.set('appType', 'PART');
            }

            var browser = navigator.userAgent.toLocaleLowerCase();

            if (browser.indexOf('chrome') > -1 || browser.indexOf('crios') > -1) {
                if (browser.includes('opr') === false && browser.includes('edge') === false) {
                    if (browser.indexOf('chrome') >= 70 || browser.indexOf('crios') >= 50) {
                        this.noValidBrowser = false;
                    }
                }
            }
        }).on('init'),
        actions: {
            submit: function submit() {
                var _this = this;

                this.set('warning', null);
                this.set('loading', true);

                var credentials = this.getProperties('identification', 'password', 'reCaptchaValue');

                this.get('session').authenticate('authenticator:custom', credentials).then(function (res) {
                    _this.set('loading', false);
                }, function (rej) {
                    _this.set('captchaWarning', null);
                    _this.set('reCaptchaOn', false);
                    _this.set('loading', false);

                    if (rej && rej.detail === 'Invalid credentials.') {
                        _this.set('warning', 'Błędny login lub hasło. Logowanie nie powiodło się.');
                    } else if (rej && rej.detail === 'Account locked.') {
                        _this.set('warning', 'Twoje konto jest zablokowane. Skontaktuj się z administratorem.');
                    } else if (rej && rej.detail === 'Invalid login attempts exceed.') {
                        _this.set('warning', 'Twoje konto zostało zablokowane ze względu na 10 nieudanych prób logowania. Skontaktuj się z administratorem.');
                    } else if (rej && rej.detail === 'Invalid credentials with attempts.') {
                        _this.set('reCaptchaOn', true);
                        _this.set('warning', 'Błędny login lub hasło. Logowanie nie powiodło się. Pozostało ' + rej.attempts + ' prób. Po osiągnięciu limitu konto zostanie zablokowane.');
                    } else if (rej && rej.detail === 'ReCaptcha needed.') {
                        _this.set('reCaptchaOn', true);
                        _this.set('captchaWarning', 'Potwierdź, że nie jesteś robotem.');
                    } else {
                        _this.set('warning', 'Wystapił błąd po stronie serwera! Spróbuj ponownie później.');
                    }
                });
            },

            onCaptchaResolved: function onCaptchaResolved(reCaptchaResponse) {
                this.set('reCaptchaValue', reCaptchaResponse);
            }
        },
        linkResetPassword: (function () {
            return _emberNewConfigEnvironment['default'].apiUrl + "/resetting/request";
        }).property()
    });

    exports['default'] = LoginController;
});
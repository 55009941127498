define('ember-new/routes/profile', ['exports', 'ember-new/routes/authenticated'], function (exports, _emberNewRoutesAuthenticated) {

  var ProfileRoute = _emberNewRoutesAuthenticated['default'].extend({
    model: function model() {
      return this.get('TheUser');
    },

    setupController: function setupController(ctrl, model) {
      this._super.apply(this, arguments);
      ctrl.set('TheUser', model);
    }

  });

  exports['default'] = ProfileRoute;
});
define("ember-new/models/dictionary-types-mappings", ["exports"], function (exports) {
    var DictionaryTypesMappings = {
        plain: "PlainTextDictionaryEntry",
        "default": "PlainTextDictionaryEntry",
        tariff_group: "TariffGroupDictionaryEntry",
        gas_tariff_group: "GasTariffGroupDictionaryEntry",
        gas_tariff_group_pgnig: "GasTariffGroupPgnigDictionaryEntry",
        gas_tariff_group_duon: "GasTariffGroupDuonDictionaryEntry",
        os: "OsDictionaryEntry",
        gas_os: "GasOsDictionaryEntry",
        osd: "OsdDictionaryEntry",
        gas_osd: "GasOsdDictionaryEntry",
        branch: "BranchDictionaryEntry",
        gas_branch: "GasBranchDictionaryEntry",
        contract_owner: 'ContractOwnerDictionaryEntry',
        district: 'DistrictDictionaryEntry',
        sales_region: 'SalesRegionDictionaryEntry',
        product: 'ProductDictionaryEntry',
        gas_product: 'GasProductDictionaryEntry',
        contract_types: 'ContractTypeDictionaryEntry',
        gas_contract_types: 'GasContractTypeDictionaryEntry',
        correction: 'CorrectionDictionaryEntry',
        seller_change: 'SellerChangeDictionaryEntry',
        osd_section: "OsdSectionDictionaryEntry",
        settlement_period: 'SettlementPeriodDictionaryEntry',
        gas_settlement_period: 'GasSettlementPeriodDictionaryEntry',
        payment_term: 'PaymentTermDictionaryEntry',
        payment_term_basic_invoice: 'PaymentTermBasicInvoiceDictionaryEntry',
        cc_rating: 'CcRatingDictionaryEntry',
        billing_frequency: 'BillingFrequencyDictionaryEntry',
        energy_destiny: 'EnergyDestinyDictionaryEntry',
        gas_energy_destiny: 'GasEnergyDestinyDictionaryEntry',
        gas_energy_destiny_for_ppe: 'GasEnergyDestinyForPpeDictionaryEntry',
        declaration_of_excise: 'DeclarationOfExciseDictionaryEntry',
        meeting_verification: 'meetingVerificationDictionaryEntry',
        task_source: 'TaskSourceDictionaryEntry',
        decision_hr: 'DecisionHrDictionaryEntry',
        reason_of_incomplete_contract: 'ReasonOfIncompleteContractDictionaryEntry',
        consumption_calculating_method: 'ConsumptionCalculatingMethodDictionaryEntry',
        county: 'CountyDictionaryEntry',
        duon_partner: 'DuonPartnerDictionaryEntry',
        duon_package_product: 'DuonPackageProductDictionaryEntry',
        gas_overhead: 'GasOverheadDictionaryEntry',
        contract_version: 'ContractVersionDictionaryEntry',
        subscription: 'SubscriptionDictionaryEntry',
        contractual_power: 'ContractualPowerEntry',
        payment_system: 'PaymentSystemDictionaryEntry',
        new_connection: 'NewConnectionDictionaryEntry',
        payment_term_days_detail: 'PaymentTermDaysDetailDictionaryEntry',
        introduction_invoice_amount: 'IntroductionInvoiceAmountDictionaryEntry',
        introduction_invoice_detail: 'IntroductionInvoiceDetailDictionaryEntry',
        yes_no: "YesNoDictionaryEntry",
        user_sales_channel: 'UserSalesChannelDictionaryEntry',
        user_position: 'UserPositionDictionaryEntry',
        pkd: 'PkdDictionaryEntry',
        client_channel: 'ClientChannel',
        client_system_tag: "ClientSystemTag",
        client_user_tag: "ClientUserTag",
        individual_contract_additional_variant: 'IndividualContractAdditionalVariant',
        individual_product: 'IndividualProduct',
        individual_ee_product_line: 'IndividualEeProductLine',
        individual_gas_product_line: 'IndividualGasProductLine',
        individual_contract_category: 'IndividualContractCategory',
        individual_ee_tariff_group: 'IndividualContractEeTariffGroup',
        individual_gas_tariff_group: 'IndividualContractGasTariffGroup',
        individual_gas_fuel_purpose: 'IndividualContractGasFuelPurpose',
        renouncement_source: 'RenouncementSource',
        identification_document_type: 'IdentificationDocumentType',
        signing_place: 'SigningPlace',
        loyalty: 'Loyalty',
        sch_light_bulb: 'SchLightBulb',
        oplata_abonamentowa: 'OplataAbonamentowa',
        oplata_przejsciowa: 'OplataPrzejsciowa',
        oplata_sieciowa: 'OplataSieciowa',
        verification_call_type: 'VerificationCallType',
        verification_call_status: 'VerificationCallStatus',
        discount: 'Discount',
        legal_form: 'LegalFormDictionaryEntry',
        execution_department: 'ExecutionDepartmentDictionaryEntry',
        business_ee_tariff_group: 'BusinessEeTariffGroup',
        business_gas_tariff_group: 'BusinessGasTariffGroup',
        business_gas_tariff_group_pgnig: 'BusinessGasTariffGroupPgnig',
        business_gas_tariff_group_fortum: 'BusinessGasTariffGroupFortum',
        business_ee_usys_product_template: 'BusinessEeUsysProductTemplate',
        business_gas_usys_product_template: 'BusinessGasUsysProductTemplate',
        settlement_rule: 'SettlementRule',
        notice_period_time: 'NoticePeriodTime',
        b2b_loyalty_status: 'b2bLoyaltyStatus',
        renouncement_status: 'renouncementStatusDictionaryEntry',
        ppg_dates_type: 'PpgDatesType',
        client_type: 'ClientType',
        status_definition: 'StatusDefinition'

    };

    exports["default"] = DictionaryTypesMappings;
});
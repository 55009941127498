define('ember-new/controllers/individual-contract-price-lists/product/edit', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        fillForm: function fillForm(form) {
            form.set('model.name', this.get('model.name'));
            form.set('model.code', this.get('model.code'));
            form.set('model.uuid', this.get('productUuid'));
        }

    });
});
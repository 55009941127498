define("ember-new/utils/ajax/error-handlers", ["exports", "ember", "ember-new/config/environment"], function (exports, _ember, _emberNewConfigEnvironment) {

    var devMode = _emberNewConfigEnvironment["default"].isDev;

    var handleBackendException = function handleBackendException(jqXHR) {
        if (devMode) {
            var response = jqXHR.responseJSON;

            if (response !== undefined) {
                if (response.message !== undefined) {
                    console.error("Backend Exception' thrown: \n\n" + response.message);
                    console.error(response);
                } else {
                    var exception = response[0];
                    console.error("Backend Exception '" + exception["class"] + "' thrown: \n\n" + exception.message + " \n\nat file " + exception.trace[0].file + "\nat line " + exception.trace[0].line);
                    console.error(exception);
                }
            } else {
                console.error("HTTP 500: UnProcessable http exception");
            }
        } else {
            alert('Wystąpił błąd. Skontaktuj się z administratorem.');
        }
    };

    var handleBackend403 = function handleBackend403(jqXHR, rejectFunction) {
        var msg;
        if (jqXHR.responseJSON !== undefined) {
            msg = jqXHR.responseJSON.message;
        } else {
            msg = "Nie masz uprawnień do wykonania tej akcji.";
        }

        alert(msg);

        if (rejectFunction !== undefined) {
            rejectFunction(jqXHR);
        }
    };

    var handleUnknownAjaxError = function handleUnknownAjaxError(jqXHR) {
        if (jqXHR.status.toString().substring(0, 1) === "2") {
            console.error("Server has returned 200 status code, so it seems response JSON to be broken.");
        } else {
            console.error("Unknown ajax error to : " + url);
        }

        if (!devMode) {
            alert('Wystąpił błąd. Skontaktuj się z administratorem.');
        }
    };

    var handleAjaxError = function handleAjaxError(jqXHR, onValidationErrorsCallback, onAccessDeniedCallback) {
        if (jqXHR.status == 500) {
            handleBackendException(jqXHR);
            return;
        } else if (jqXHR.status == 400 && onValidationErrorsCallback !== undefined && onValidationErrorsCallback !== null) {
            onValidationErrorsCallback(jqXHR);
        } else if (jqXHR.status == 403) {
            handleBackend403(jqXHR);
            if (onAccessDeniedCallback !== undefined && onAccessDeniedCallback !== null) {
                onAccessDeniedCallback(jqXHR);
            }
        } else {
            handleUnknownAjaxError(jqXHR);
        }
    };

    exports["default"] = {
        handleBackendException: handleBackendException,
        handleBackend403: handleBackend403,
        handleUnknownAjaxError: handleUnknownAjaxError,
        handleAjaxError: handleAjaxError
    };
});
/**
 * @deprecated
 * Nie używaj tego. Użyj services/ajax
 * Zostawione for backward compatibility
 *
 * uzywane do obslugi starych metod get i post $.ajax, które też są @deprecated
 * ~KJ
 */
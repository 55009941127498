define('ember-new/controllers/tasks/update', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        queryParams: ['taskUuid', 'taskId'],
        taskUuid: null,
        taskId: null,

        savingInProgress: false,
        // showLoyaltyDate: false,
        formData: null,
        // options
        loyalStatusOptions: [{ label: 'Brak lojalki', value: 0 }, { label: 'Nie ustalono czy jest', value: 1 }, { label: 'Lojalka - data nieznana', value: 2 }, { label: 'Lojalka - data znana', value: 3 }],

        fillForm: function fillForm(form) {
            form.set('model.acqUuid', this.get('model.uuid'));
            form.set('model.taskUuid', this.get('taskUuid'));
            form.set('model.consumption', this.get('model.consumption'));
            form.set('model.overhead', this.get('model.overhead'));
            form.set('model.numberOfMonths', this.get('model.numberOfMonths'));
            form.set('model.loyaltyStatus', this.get('model.loyaltyStatus'));
            form.set('model.loyaltyDate', this.get('model.loyaltyDate'));
            form.set('model.os', this.get('model.osUuid'));
            form.set('model.osd', this.get('model.osdUuid'));
            form.set('model.mainTariffGroup', this.get('model.mainTariffGroupUuid'));

            this.set('formData', form.get('formData'));

            if (this.get('model.hasContract')) {
                this.set('actionMessages', [{ type: 'warning', message: 'Nie można edytować danych akwizycji klienta ze statusem "Umowa podpisana i odebrana", aby dokonać zmian w umowie dodanej do systemu przejdź do zakładki "Umowy" lub skorzystaj z przycisku poniżej: ' }]);
            }
        },

        afterCommand: function afterCommand(response) {
            var ctrl = this;
            ctrl.get('model').reload();
            ctrl.transitionToRoute('tasks');
            ctrl.get('target').send('refreshContractItemModel', this.get('taskId'));
        },

        hideLoyaltyDate: (function () {
            var loyaltyStatus = this.get('formData.loyaltyStatus');
            return loyaltyStatus !== 3 && loyaltyStatus !== '3';
        }).property('formData.loyaltyStatus'),

        actions: {}
    });
});
define('ember-new/routes/b2b-contract/dzu/show-ee', ['exports', 'ember-new/routes/authenticated'], function (exports, _emberNewRoutesAuthenticated) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({

        commentsNotifications: null,
        notificationsExist: null,

        beforeModel: function beforeModel(transition) {
            this._super.apply(this, arguments);
            window.scrollTo(0, 0);
            this.store.unloadAll('upload');
        },

        model: function model(params) {
            this.store.unloadAll('ContractHeaderDzuViewModel');

            var notificationsData = undefined;
            var contractList = undefined;
            var _this = this;

            contractList = this.modelFor('b2b-contract.dzu');
            notificationsData = contractList.list.filter(function (item) {
                return item.get('id') === params.contractId;
            })[0];

            if (!notificationsData || notificationsData === undefined) {
                notificationsData = this.store.findRecord('CommentsNotificationsDzuViewModel', params.contractId);
                notificationsData.then(function (response) {
                    _this.set('commentsNotifications', response.get('commentsNotifications'));
                    _this.set('notificationsExist', response.get('notificationsExist'));
                });
            } else {
                this.set('commentsNotifications', notificationsData.get('commentsNotifications'));
                this.set('notificationsExist', notificationsData.get('notificationsExist'));
            }

            return this.store.findRecord('ContractHeaderDzuViewModel', params.contractId);
        },

        setupController: function setupController(ctrl, model) {
            ctrl.set('model', model);
            ctrl.set('commentsNotifications', this.get('commentsNotifications'));
            ctrl.set('notificationsExist', this.get('notificationsExist'));

            var client = this.store.findRecord('ContractClientDzuViewModel', model.get('id'), { reload: true }),
                contract = this.store.findRecord('ContractDzuListItemViewModel', model.get('id'), { reload: true });
            ctrl.set('model.client', client);
            ctrl.set('model.contract', contract);
        },

        actions: {
            updateModel: function updateModel() {
                alert("I'm updating the model mother!");
            },

            refreshModel: function refreshModel() {
                this.refresh();
            },

            refreshModelFromCommentController: function refreshModelFromCommentController() {
                this.store.unloadAll('ContractHeaderDzuViewModel');
                this.refresh();
            },

            /**
             * Powraca do listy DZU i odświeża jednego row'a z danym kontraktem (aktualizuje nowe dane; status itd.)
             * @param contractId
             */
            backToDzuList: function backToDzuList(contractId) {
                this.transitionTo('b2b-contract.dzu');
                this.send('refreshContractItemModel', contractId); // akcja z routa DZU
            }
        }
    });
});
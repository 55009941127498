define('ember-new/controllers/individual-contract-partners/undo-contract-cancel', ['exports', 'ember', 'ember-new/config/environment'], function (exports, _ember, _emberNewConfigEnvironment) {
    var computed = _ember['default'].computed;
    var _Ember$inject = _ember['default'].inject;
    var service = _Ember$inject.service;
    var controller = _Ember$inject.controller;
    exports['default'] = _ember['default'].Controller.extend({
        ajax: service(),
        applicationAlerts: service('application-alerts-manager'),
        individualContractPartnersCtrl: controller('individual-contract-partners'),

        handleResponse: function handleResponse(res) {
            if (res.status === 'ok') {
                this.get('applicationAlerts').addActionMessage('success', 'Zaktualizowano dane!');
                this.transitionToRoute('individual-contract-partners');
                this.get('individualContractPartnersCtrl').send('refresh');
            }
        },

        handleError: function handleError(err) {
            console.log(err);
            this.get('applicationAlerts').addActionMessage('warning', 'Nie można wykonać akcji!');
            this.transitionToRoute('individual-contract-partners');
        },

        actions: {
            undoCancel: function undoCancel() {
                var _this = this;

                var uuid = this.get('model.uuid'),
                    url = _emberNewConfigEnvironment['default'].apiUrl + '/rest/undocontractcancellations',
                    requestObject = { UndoContractCancellation: { uuids: [uuid] } };

                return this.get('ajax').ajaxPost(url, requestObject).then(function (res) {
                    return _this.handleResponse(res);
                }, function (err) {
                    return _this.handleError(err);
                });
            }
        }
    });
});
define("ember-new/mixins/acquisition/acquisition-comments-route", ["exports", "ember", "ember-new/config/environment", "ember-new/mixins/comments/comments-route"], function (exports, _ember, _emberNewConfigEnvironment, _emberNewMixinsCommentsCommentsRoute) {
    exports["default"] = _ember["default"].Mixin.create(_emberNewMixinsCommentsCommentsRoute["default"], {
        restControllerName: '', // do rozszerzenia w docelowym route
        parentModelName: '', // do rozszerzenia w docelowym route
        setupController: function setupController(ctrl, model) {
            ctrl.set('arrangedContent', null);
            ctrl.set('arrangedContent', model);
            ctrl.set('acquisitionProcess', this.modelFor(this.get('parentModelName')));
        }
    });
});
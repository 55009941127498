define("ember-new/routes/modal", ["exports", "ember-new/routes/authenticated", "ember-new/config/environment", "ember"], function (exports, _emberNewRoutesAuthenticated, _emberNewConfigEnvironment, _ember) {

	// ModalRoute
	exports["default"] = _emberNewRoutesAuthenticated["default"].extend({

		renderTemplate: function renderTemplate() {
			this.render({
				outlet: 'modal',
				into: 'application'
			});
		},

		actions: {
			closeModalAction: function closeModalAction() {
				this.disconnectOutlet({
					outlet: 'modal',
					// Note that "into" parameter has been replaced by "parentView"
					parentView: 'application'
				});
			}
		}

	});
});
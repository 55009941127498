define('ember-new/mixins/b2b-contract/components/d-form/osd-section', ['exports', 'ember', 'ember-new/templates/components/d-form'], function (exports, _ember, _emberNewTemplatesComponentsDForm) {
    exports['default'] = _ember['default'].Mixin.create({
        layout: _emberNewTemplatesComponentsDForm['default'],

        configure: function configure() {
            var _this = this;

            /**
             * @param originalContent zawartosc do przefiltrowania
             * @returns {*}
             */
            var getFilteredContent = function getFilteredContent(originalContent) {
                var selectedOsdUuid = _this.get('formData.osd'); // aktualnie wybrane Osd uuid

                // jezeli nie jest wybrane zadne OSD zwraca oryginalne
                if (!selectedOsdUuid) {
                    return originalContent;
                }

                var osdSection = _this.get('store').peekAll('osd-section-dictionary-entry').filter(function (record) {
                    return record.get('osdUuids').includes(selectedOsdUuid);
                });

                var osdSectionUuids = [];

                // brak przypisanych OSD: wyczyść wartość starego pola.
                if (osdSection.length === 0) {
                    _this.set('formData.osdSection', null);
                } else if (osdSection.length === 1 && _this.get('currentFormData.osd')) {
                    // jezeli jest tylko jeden powiazany oddzial z OSD automatycznie go wybierz
                    _this.set('currentFormData.osdSection', osdSection[0].get('uuid'));
                } else if (osdSection.length > 1 && _this.get('currentFormData.osd')) {
                    // jezeli jest więcej powiazanych oddziałów - reset
                    _this.set('currentFormData.osdSection', null);
                }

                osdSection.forEach(function (element) {
                    osdSectionUuids.push(element.get('uuid'));
                });

                return originalContent.filter(function (record) {
                    return osdSectionUuids.includes(record.value);
                });
            };

            /**
             * Filtrowanie pola osdSection
             */
            this.registerContentFilter('osdSection', function (originalContent) {

                return getFilteredContent(originalContent);
            });

            /**
             * Przy zmianie wartosci pola `osd` przefiltruj `osdSection`
             */
            this.addObserver('formData.osd', function () {
                _this.filterContentFor('osdSection', false); // przekazujemy 'false' aby zapobiec zNULLowaniu wartości pola OSD SECTION przez metodę filterContentFor w d-form.js
            });
        }
    });
});
define("ember-new/components/d-form/individual-contract-price-lists/product/add", ["exports", "ember-new/components/d-form-command", "ember-new/templates/components/d-form"], function (exports, _emberNewComponentsDFormCommand, _emberNewTemplatesComponentsDForm) {
    exports["default"] = _emberNewComponentsDFormCommand["default"].extend({
        layout: _emberNewTemplatesComponentsDForm["default"],

        commandName: 'CreateIndividualProductCommand',

        afterSuccessfulSubmit: function afterSuccessfulSubmit() {
            var targetController = this.get('targetObject');
            var mainRoute = targetController.container.lookup("route:individual-contract-price-lists.product");
            mainRoute.refresh();

            setTimeout(function () {
                targetController.transitionToRoute('individual-contract-price-lists.product');
            }, 100);
        }
    });
});
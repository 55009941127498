define('ember-new/controllers/individual-contract-price-lists/template/vaps/edit', ['exports', 'ember', 'ember-new/mixins/individual-contract-price-lists/controllers/vap'], function (exports, _ember, _emberNewMixinsIndividualContractPriceListsControllersVap) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsIndividualContractPriceListsControllersVap['default'], {
        fillForm: function fillForm(form) {
            var model = this.get('model').get('firstObject');
            form.set('model.templateUuid', model.get('templateUuid'));
            form.set('model.vapUuid', model.get('vapUuid'));
            form.set('model.type', model.get('type'));
            form.set('model.customPrice', model.get('customPrice'));
            form.set('model.isRequired', model.get('isRequired'));
            form.set('model.validityPeriod', model.get('validityPeriod'));
            form.set('model.numberOfMonths', model.get('numberOfMonths'));
        }

    });
});
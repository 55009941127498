define('ember-new/controllers/b2b-contract/dzu/show-gas/ppg/details/index', ['exports', 'ember', 'ember-new/mixins/b2b-contract/controllers/reload-contract-header'], function (exports, _ember, _emberNewMixinsB2bContractControllersReloadContractHeader) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsB2bContractControllersReloadContractHeader['default'], {
        TheUser: _ember['default'].inject.service('theuser:main'),

        isDzuUser: (function () {
            return this.get("TheUser").isMainRoleIn(['ROLE_DZU2_SPECIALIST', 'ROLE_DZU2']);
        }).property(),

        disableForDzuSpecialist: (function () {
            return !this.get("TheUser").isMainRoleIn(['ROLE_DZU_MANAGER', 'ROLE_DZU2_MANAGER', 'ROLE_DZU2_SPECIALIST', 'ROLE_DZU2', 'ROLE_ADMIN']);
        }).property(),

        isManualMmValueDisabled: (function () {
            return this.get('disableForDzuSpecialist') || this.get('model.isManualMmByContract');
        }).property(),

        osdNull: true,
        disableContractTerminating: true,
        disableSellerChange: true,
        disableOs: true,
        excisesSum: null,

        fillFormContactAddress: function fillFormContactAddress(form) {
            form.set('model.ppgId', this.get('model.uuid'));
            form.set('model.street', this.get('model.streetName'));
            form.set('model.streetNo', this.get('model.streetNumber'));
            form.set('model.aptNo', this.get('model.apartmentNumber'));
            form.set('model.postalCode', this.get('model.postalCode'));
            form.set('model.postalCity', this.get('model.postalCity'));
            form.set('model.city', this.get('model.city'));
            form.set('model.district', this.get('model.districtUuid'));
        },

        fillFormPpgData: function fillFormPpgData(form) {
            form.set('model.ppgId', this.get('model.uuid'));
            form.set('model.pointNumber', this.get('model.pointNumber'));
            form.set('model.deviceNumber', this.get('model.deviceNumber'));
            form.set('model.registrationNumber', this.get('model.registrationNumber'));
            form.set('model.pointNumberForWinback', this.get('model.pointNumberForWinback'));
            form.set('model.energyDestiny', this.get('model.energyDestinyUuid'));
            form.set('model.osd', this.get('model.osdUuid'));
            //zmiana sprzedawcy:
            form.set('model.os', this.get('model.osUuid'));
            form.set('model.sellerChange', this.get('model.sellerChangeUuid'));
            form.set('model.reserveAgreement', this.get('model.reserveAgreement'));
            form.set('model.isContractTerminating', this.get('model.isContractTerminating'));
            form.set('model.isNewConnection', this.get('model.isNewConnection'));
        },

        fillFormPpgSalesData: function fillFormPpgSalesData(form) {
            form.set('model.ppgId', this.get('model.uuid'));
            form.set('model.osdTariffGroup', this.get('model.osdTariffGroupUuid'));
            form.set('model.pgnigTariffGroup', this.get('model.pgnigTariffGroupUuid'));
            form.set('model.fortumTariffGroup', this.get('model.fortumTariffGroupUuid'));
            form.set('model.os', this.get('model.osUuid'));
            form.set('model.contractualPower', this.get('model.contractualPower'));
            form.set('model.subscription', this.get('model.subscriptionUuid'));
            form.set('model.subscriptionValue', this.get('model.subscriptionValue'));
            form.set('model.deliveredComprehensiveAgreement', this.get('model.deliveredComprehensiveAgreement'));
            form.set('model.correction', this.get('model.correctionUuid'));
            form.set('model.loyalty', this.get('model.loyaltyUuid'));
            form.set('model.loyaltyStart', this.get('model.loyaltyStart'));
            form.set('model.loyaltyEnd', this.get('model.loyaltyEnd'));
            form.set('model.annexPriceFromLoyalty', this.get('model.annexPriceFromLoyalty'));
            form.set('model.dismissPeriod', this.get('model.dismissPeriod'));
            form.set('model.discount', this.get('model.discount'));
            form.set('model.priceFromLoyalty', this.get('model.priceFromLoyalty'));
            form.set('model.minimalPricePricelist', this.get('model.minimalPricePricelist'));
            form.set('model.noticePeriodTime', this.get('model.noticePeriodTimeUuid'));
            form.set('model.consumptionCalculationMethod', this.get('model.consumptionCalculationMethodUuid'));
            form.set('model.isContractTerminating', this.get('model.isContractTerminating'));
            form.set('model.settlementRules', this.get('model.settlementRuleUuid'));
            form.set('model.isManualMm', this.get('model.isManualMm'));
            form.set('model.manualMm', this.get('model.manualMm'));
            form.set('model.startDate', this.get('model.startDate'));
            form.set('model.endDate', this.get('model.endDate'));
            form.set('model.monthsNo', this.get('model.numberOfMonths'));
            form.set('model.contractMonths', this.get('model.contractMonths'));
        },

        fillFormDetailedConsumption: function fillFormDetailedConsumption(form) {
            form.set('model.ppgId', this.get('model.uuid'));
            form.set('model.january', this.get('model.january'));
            form.set('model.february', this.get('model.february'));
            form.set('model.march', this.get('model.march'));
            form.set('model.april', this.get('model.april'));
            form.set('model.may', this.get('model.may'));
            form.set('model.june', this.get('model.june'));
            form.set('model.july', this.get('model.july'));
            form.set('model.august', this.get('model.august'));
            form.set('model.september', this.get('model.september'));
            form.set('model.october', this.get('model.october'));
            form.set('model.november', this.get('model.november'));
            form.set('model.december', this.get('model.december'));
        },

        fillFormExcise: function fillFormExcise(form) {
            form.set('model.ppgId', this.get('model.uuid'));
            form.set('model.excise1', this.get('model.fuelExcise') ? this.get('model.fuelExcise') : 0);
            form.set('model.excise2', this.get('model.engineExcise') ? this.get('model.engineExcise') : 0);
            form.set('model.exciseA1', this.get('model.exciseLeaseholdA1') ? this.get('model.exciseLeaseholdA1') : 0);
            form.set('model.exciseA2', this.get('model.exciseLeaseholdA2') ? this.get('model.exciseLeaseholdA2') : 0);
            form.set('model.exciseA3', this.get('model.exciseLeaseholdA3') ? this.get('model.exciseLeaseholdA3') : 0);
            form.set('model.exciseA4', this.get('model.exciseLeaseholdA4') ? this.get('model.exciseLeaseholdA4') : 0);
            form.set('model.exciseA5', this.get('model.exciseLeaseholdA5') ? this.get('model.exciseLeaseholdA5') : 0);
            form.set('model.exciseA6', this.get('model.exciseLeaseholdA6') ? this.get('model.exciseLeaseholdA6') : 0);
            form.set('model.exciseA7', this.get('model.exciseLeaseholdA7') ? this.get('model.exciseLeaseholdA7') : 0);
            form.set('model.exciseA8', this.get('model.exciseLeaseholdA8') ? this.get('model.exciseLeaseholdA8') : 0);
            form.set('model.exciseB1', this.get('model.exciseLeaseholdB1') ? this.get('model.exciseLeaseholdB1') : 0);
            form.set('model.exciseB2', this.get('model.exciseLeaseholdB2') ? this.get('model.exciseLeaseholdB2') : 0);
            form.set('model.exciseB3', this.get('model.exciseLeaseholdB3') ? this.get('model.exciseLeaseholdB3') : 0);
            form.set('model.exciseB4', this.get('model.exciseLeaseholdB4') ? this.get('model.exciseLeaseholdB4') : 0);
            form.set('model.exciseB5', this.get('model.exciseLeaseholdB5') ? this.get('model.exciseLeaseholdB5') : 0);
            form.set('model.exciseB6', this.get('model.exciseLeaseholdB6') ? this.get('model.exciseLeaseholdB6') : 0);

            form.set('model.excisesSum', this.get('model.excisesSum') ? this.get('model.excisesSum') : 0);
        },

        fillFormPreinvoices: function fillFormPreinvoices(form) {
            form.set('model.ppgId', this.get('model.uuid'));
            form.set('model.invoiceDays', this.get('model.invoiceDays'));
            form.set('model.preinvoicesAmount', this.get('model.preinvoicesAmount'));
            form.set('model.preinvoicesPercent', this.get('model.preinvoicesPercent'));
            form.set('model.preinvoice1Days', this.get('model.preinvoice1Days'));
            form.set('model.preinvoice1Details', this.get('model.preinvoice1DetailsUuid'));
            form.set('model.preinvoice2Days', this.get('model.preinvoice2Days'));
            form.set('model.preinvoice2Details', this.get('model.preinvoice2DetailsUuid'));
            form.set('model.preinvoice3Days', this.get('model.preinvoice3Days'));
            form.set('model.preinvoice3Details', this.get('model.preinvoice3DetailsUuid'));
        },

        afterCommand: function afterCommand(response) {
            var ctrl = this;
            var route = this.container.lookup('route:b2b-contract.dzu.show-gas.ppg.details');
            route.refresh();
            this.reloadContractHeader();
            setTimeout(function () {
                ctrl.transitionToRoute('b2b-contract.dzu.show-gas.ppg.details');
            }, 500);
        },

        loyaltyStatusDate: (function () {
            if (this.get('loyaltyStatus') != '34f0d211-f3f0-4783-8506-bcaade547dbc') {
                return true;
            } else {
                return false;
            }
        }).property('loyaltyStatus'),

        isSubscriptionValueVisible: (function () {
            if (this.get('subscriptionType') == '92caf039-324e-496f-8603-26ef7d1863fc' || this.get('subscriptionType') == 'a8a80e31-8382-4593-8b24-f2ffb2f27a45') {
                return false;
            }
            return true;
        }).property('subscriptionType'),

        isManualMmVal: (function () {
            if (this.get('isManualMmValue') !== true) {
                this.set('isManualMmValue', false);
            }
            return !this.get('isManualMmValue');
        }).property('isManualMmValue'),

        preinvoice1hidden: (function () {
            if (this.get('preinvoices') === '1' || this.get('preinvoices') === '2' || this.get('preinvoices') === '3') {
                return false;
            } else {
                this.set('preinvoice1Days', '');
                this.set('preinvoice1Details', null);

                return true;
            }
        }).property('preinvoices'),

        preinvoice2hidden: (function () {
            if (this.get('preinvoices') === '2' || this.get('preinvoices') === '3') {

                return false;
            } else {
                this.set('preinvoice2Days', '');
                this.set('preinvoice2Details', null);

                return true;
            }
        }).property('preinvoices'),

        preinvoice3hidden: (function () {
            if (this.get('preinvoices') === '3') {

                return false;
            } else {
                this.set('preinvoice3Days', '');
                this.set('preinvoice3Details', null);

                return true;
            }
        }).property('preinvoices'),

        preinvoicesAmount: [{ label: '1', value: '1' }, { label: '2', value: '2' }, { label: '3', value: '3' }],

        loyaltyContent: [{ label: 'Lojalka - data znana', value: '34f0d211-f3f0-4783-8506-bcaade547dbc' }, { label: 'Brak lojalki', value: 'a45aebb5-9c31-4c41-b130-c91bbaccb6b4' }]
    });
});
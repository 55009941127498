define('ember-new/components/d-form/individual-contract/ppm/sells', ['exports', 'ember', 'ember-new/components/d-form', 'ember-new/templates/components/d-form', 'ember-new/config/environment'], function (exports, _ember, _emberNewComponentsDForm, _emberNewTemplatesComponentsDForm, _emberNewConfigEnvironment) {
	exports['default'] = _emberNewComponentsDForm['default'].extend({

		layout: _emberNewTemplatesComponentsDForm['default'],

		contractId: null,

		submitRestAsSimple: true,
		submitModelNamespace: 'individualPpm',
		submitRestAsSimpleMethod: 'PATCH',

		submitSimpleUrl: (function () {
			if (this.get('type') === 'ee') {
				return _emberNewConfigEnvironment['default'].apiUrl + '/rest/individualppes/' + this.get('ppeId') + '/edit/main';
			} else if (this.get('type') === 'gas') {
				return _emberNewConfigEnvironment['default'].apiUrl + '/rest/individualppgs/' + this.get('ppeId') + '/edit/main';
			}
		}).property(),

		afterSubmit: function afterSubmit(response) {
			this.get('store').pushPayload(response);
		},

		onInit: (function () {
			this.get('targetObject').set('ppeForm', this);
		}).on('init')

	});
});
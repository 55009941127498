define('ember-new/mixins/b2b-contract/components/d-form/excises-sum', ['exports', 'ember', 'ember-new/templates/components/d-form'], function (exports, _ember, _emberNewTemplatesComponentsDForm) {
    function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

    var fieldsNamesSuffixes = ['1', '2', 'A1', 'A2', 'A3', 'A4', 'A5', 'A6', 'A7', 'A8', 'B1', 'B2', 'B3', 'B4', 'B5', 'B6'];
    var watchedFieldsNames = fieldsNamesSuffixes.map(function (name) {
        return 'formData.excise' + name;
    }); // formData.excise1, formData.excise2 ...

    exports['default'] = _ember['default'].Mixin.create({
        layout: _emberNewTemplatesComponentsDForm['default'],

        /**
         * Sumowanie wartosci pol i ustawienie wyniku w formularzu.
         */
        sum: _ember['default'].observer.apply(_ember['default'], _toConsumableArray(watchedFieldsNames).concat([function () {
            var _this = this;

            var result = watchedFieldsNames.map(function (fieldName) {
                return _this.getFieldAndCheckValue(fieldName);
            }).reduce(function (a, b) {
                return a + b;
            });

            this.get('targetObject').set('excisesSum', result.toFixed(2)); // controller
        }])),

        /**
         * Sprawdza czy podana wartość jest libczą,
         * jeżeli nie to zwraca 0,
         * jezeli tak to zwraca float
         * @param fieldName nazwa pola
         * @returns number
         */
        getFieldAndCheckValue: function getFieldAndCheckValue(fieldName) {
            var val = parseFloat(this.get(fieldName));
            return isNaN(val) ? 0 : val;
        }
    });
});
define('ember-new/controllers/individual-contract-price-lists/template/edit', ['exports', 'ember', 'ember-new/mixins/individual-contract-price-lists/controllers/template'], function (exports, _ember, _emberNewMixinsIndividualContractPriceListsControllersTemplate) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsIndividualContractPriceListsControllersTemplate['default'], {

        fillForm: function fillForm(form) {
            form.set('model.uuid', this.get('templateUuid'));
            form.set('model.usysId', this.get('model.usysId'));
            form.set('model.code', this.get('model.code'));
            form.set('model.type', this.get('model.type'));
            form.set('model.endMonths', this.get('model.endMonths'));
            form.set('model.endDate', this.get('model.endDate'));
            form.set('model.promoPriceType', this.get('model.promoPriceType'));
            form.set('model.discountPriceMonths', this.get('model.discountPriceMonths') + "");
            form.set('model.discountPriceDate', this.get('model.discountPriceDate'));
            form.set('model.promoFeeType', this.get('model.promoFeeType'));
            form.set('model.discountFeeMonths', this.get('model.discountFeeMonths') + "");
            form.set('model.discountFeeDate', this.get('model.discountFeeDate'));

            form.set('model.isArchival', this.get('model.isArchival'));
            form.set('model.name', this.get('model.name'));
            form.set('model.productUuid', this.get('model.productUuid'));
            form.set('model.eeTariffGroupUuid', this.get('model.tariffGroupUuid'));
            form.set('model.gasTariffGroupUuid', this.get('model.tariffGroupUuid'));
            form.set('model.contractEndType', this.get('model.endType'));
            form.set('model.isArchival', this.get('model.isArchival'));

            var year = this.get('model.endDate') ? this.get('model.endDate').slice(0, 4) : null;
            if (year) {
                form.set('model.endDate', parseInt(year));
            }

            var promoPriceYear = this.get('model.discountPriceDate') ? this.get('model.discountPriceDate').slice(0, 4) : null;
            if (promoPriceYear) {
                form.set('model.discountPriceDate', parseInt(promoPriceYear));
            }

            var promoFeeYear = this.get('model.discountFeeDate') ? this.get('model.discountFeeDate').slice(0, 4) : null;
            if (promoFeeYear) {
                form.set('model.discountFeeDate', parseInt(promoFeeYear));
            }
        },

        hideMonths: (function () {
            return this.get('contractEndType') === 'DD';
        }).property('contractEndType'),

        hideYear: (function () {
            return this.get('contractEndType') === 'FM';
        }).property('contractEndType'),

        hidePromoPriceMonths: (function () {
            return this.get('promoPriceType') === 'DD';
        }).property('promoPriceType'),

        hidePromoPriceYear: (function () {
            return this.get('promoPriceType') === 'FM';
        }).property('promoPriceType'),

        hidePromoFeeMonths: (function () {
            return this.get('promoFeeType') === 'DD';
        }).property('promoFeeType', 'type'),

        hidePromoFeeYear: (function () {
            return this.get('promoFeeType') === 'FM';
        }).property('promoFeeType', 'type')

    });
});
define("ember-new/routes/b2b-contract/administration/gas-business-tariff/add", ["exports", "ember-new/routes/authenticated"], function (exports, _emberNewRoutesAuthenticated) {
    exports["default"] = _emberNewRoutesAuthenticated["default"].extend({

        model: function model(params) {
            return Ember.RSVP.hash({
                gasProducts: this.store.findAll('GasBusinessContractProductViewModel')
            });
        },

        setupController: function setupController(ctrl, model) {
            ctrl.initializeGasProducts(model.gasProducts);
        },

        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            }
        }
    });
});
define('ember-new/models/meeting-verification-campaign-list-view-model', ['exports', 'ember-data'], function (exports, _emberData) {

    var attr = _emberData['default'].attr;

    exports['default'] = _emberData['default'].Model.extend({
        uuid: attr('string'),
        identificator: attr('string'),
        tasksCount: attr('number'),
        resolvedTasksCount: attr('number'),
        createdBy: attr('string'),
        dateCreated: attr('string'),
        approvedBy: attr('string'),
        dateApproved: attr('string'),
        completedBy: attr('string'),
        dateCompleted: attr('string'),
        isApproved: attr('boolean'),
        isCompleted: attr('boolean'),
        exportFileUuid: attr('string')
    });
});
define('ember-new/initializers/reopen-ds-store', ['exports', 'ember', 'ember-data', 'ember-new/utils/utils'], function (exports, _ember, _emberData, _emberNewUtilsUtils) {

  var alreadyRun = false;

  exports['default'] = {
    name: 'reopen-ds-store',
    initialize: function initialize() {
      if (alreadyRun) {
        return;
      } else {
        alreadyRun = true;
      }

      _emberData['default'].Store.reopen({
        applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),
        doCustomUpdate: function doCustomUpdate(record, actionName, attributesToUpdate, metaData, customVarname, customRequestData, beforePushPayload) {

          var adapter = this.adapterFor(record._internalModel.modelName);
          var updatePromise = adapter.updateRecordCustom(this, record._internalModel.modelName, record, actionName, attributesToUpdate, metaData, customVarname, customRequestData);
          var store = this;

          var afterUpdatePromise = new _ember['default'].RSVP.Promise(function (resolve, reject) {

            updatePromise.then(function (response) {

              if (beforePushPayload !== undefined && typeof beforePushPayload === 'function') {
                beforePushPayload.call(this, record);
              }

              // reload object into the store
              if (response instanceof Object && Object.keys(response).length > 0) {
                store.pushPayload(response);
              }

              // returns server response JSON as a result of the promise
              resolve(response);
            }, function (response) {
              if (response.errors.children) {
                // do nothing validation will be resolved later in .then() of promise
                reject(response); // returns JSON with validation errors as a result of the promise
              } else if (response instanceof _emberData['default'].AdapterError || response instanceof _emberData['default'].AbortError || response instanceof _emberData['default'].TimeoutError || response instanceof Error) {
                  record.rollbackAttributes();
                  store.get('applicationAlerts').addActionMessage('danger', 'Nie udało się zapisać. Wystąpił błąd. Skontaktuj się z administratorem.');
                  reject(response);
                  return;
                }

              //if (jqXHR.status == 500) {
              //    record.rollback();
              //    handleBackendException(jqXHR);
              //    return;
              //}
              //else if (jqXHR.status == 400) {
              //    console.log('validation errors:');
              //    // do nothing validation will be resolved later in .then() of promise
              //    reject(jqXHR.responseJSON); // returns JSON with validation errors as a result of the promise
              //}
              //else if (jqXHR.status == 403) {
              //    handleBackend403(jqXHR, reject);
              //}
              //else {
              //    handleUnknownAjaxError(jqXHR);
              //}
            });
          }, updatePromise._label + " afterPatchAjax");

          return afterUpdatePromise;
        },
        doCustomPost: function doCustomPost(record, actionName, attributesToUpdate, metaData, customVarname, customRequestData, beforePushPayload) {

          var adapter = this.adapterFor(record._internalModel.modelName);
          var updatePromise = adapter.postRecordCustom(this, record._internalModel.modelName, record, actionName, attributesToUpdate, metaData, customVarname, customRequestData);
          var store = this;

          var afterPostPromise = new _ember['default'].RSVP.Promise(function (resolve, reject) {

            updatePromise.then(function (response) {

              var modelname = _emberNewUtilsUtils['default'].dasherizedToUppercase(record._internalModel.modelName);

              if (!Object.keys(response).contains(modelname)) {
                throw new _ember['default'].Error("Cannot find data of the record in payload. I was seeking for `" + modelname + "`");
              }

              var data = response[modelname];

              if (data instanceof Object && !(data instanceof Array) && data.id !== undefined) {
                store.updateId(record._internalModel, data);
              }

              if (beforePushPayload !== undefined && typeof beforePushPayload === 'function') {
                beforePushPayload.call(this, record);
              }

              // reload object into the store
              if (response instanceof Object && Object.keys(response).length > 0) {
                store.pushPayload(response);
              }

              // returns server response JSON as a result of the promise
              resolve(response);
            }, function (response) {
              if (response instanceof _emberData['default'].InvalidError) {
                // do nothing validation will be resolved later in .then() of promise
                reject(response); // returns JSON with validation errors as a result of the promise
              } else if (response instanceof _emberData['default'].AdapterError || response instanceof _emberData['default'].AbortError || response instanceof _emberData['default'].TimeoutError || response instanceof Error) {
                  store.get('applicationAlerts').addActionMessage('danger', 'Nie udało się zapisać. Wystąpił błąd. Skontaktuj się z administratorem.');
                  reject(response);
                  return;
                }

              //if (jqXHR.status == 500) {
              //    record.rollback();
              //    handleBackendException(jqXHR);
              //    return;
              //}
              //else if (jqXHR.status == 400) {
              //    console.log('validation errors:');
              //    // do nothing validation will be resolved later in .then() of promise
              //    reject(jqXHR.responseJSON); // returns JSON with validation errors as a result of the promise
              //}
              //else if (jqXHR.status == 403) {
              //    handleBackend403(jqXHR, reject);
              //}
              //else {
              //    handleUnknownAjaxError(jqXHR);
              //}
            });
          }, updatePromise._label + " afterPostCustom");

          return afterPostPromise;
        },
        reloadAfterUpdate: function reloadAfterUpdate(type, payload) {
          this.pushPayload(type, payload);
        }

      });
    }
  };
});
// _pushInternalModel(data) {
//   if (!(data.id !== null && data.id !== undefined && data.id !== '')) {
//     data.id = Ember.generateGuid(null, data.type);
//   }
//   return this._super(data);
// },
define('ember-new/components/validate-field', ['exports', 'ember', 'ember-new/utils/validators'], function (exports, _ember, _emberNewUtilsValidators) {

    /**
     * Komponent sprawdza daną wartość konkretym walidatorem.
     * Walidator może byc zdefiniowany przez użytkownika lub
     * można skorzystać z wbudowanych walidatorów (_availableValidators).
     *
     * PARAMS:
     * customValidator - funkcja walidująca zwracająca true/false.
     * Przykład: {{validate-field field=model.pesel customValidator=(action 'testVal')}}
     *
     * validator - nazwa wbudowanego walidatora
     * Przykład: {{validate-field field=model.pesel validator='pesel')}}
     */
    exports['default'] = _ember['default'].Component.extend({
        _availableValidators: [{ name: 'pesel', func: 'peselValidator' }, { name: 'gasContractNumber', func: 'gasContractNumberValidator' }, { name: 'eeContractNumber', func: 'eeContractNumberValidator' }, { name: 'contractNumber', func: 'contractNumberValidator' }, { name: 'minimumLength3', func: 'minimumLengthValidation' }, { name: 'notBlank', func: 'notBlankValidation' }, { name: 'positiveNumber', func: 'positiveNumberValidator' }, { name: 'partnerGasContractNumber', func: 'partnerGasContractNumberValidator' }, { name: 'partnerEeContractNumber', func: 'partnerEeContractNumberValidator' }],

        customValidator: null,
        field: null,
        validator: null,
        notValidHelpText: null,
        validHelpText: null,
        isValid: _ember['default'].computed('field', function () {
            var value = this.get('field'),
                validator = this.get('validator'),
                customValidator = this.get('customValidator');

            if (customValidator) {
                if (!(typeof customValidator === 'function')) {
                    throw new Error('"customValidator" must be a function! Correct syntax: "customValidator=(action \'customAction\')"');
                }
                return customValidator.call(this, value);
            } else if (validator) {
                var validators = this.get('_availableValidators');
                var selectedValidator = validators.find(function (v) {
                    return v.name === validator;
                });

                if (!selectedValidator || !selectedValidator.func) {
                    var buildIn = this.get('_availableValidators').mapBy('name');
                    throw new Error('You have to specify built-in validator or "customValidator". List of build-in functions: ' + buildIn);
                }
                return this.get(selectedValidator.func).call(this);
            }
        }),

        peselValidator: function peselValidator() {
            var pesel = this.get('field');
            var isValidPesel = false;
            try {
                // external validator for flexibility :)
                isValidPesel = _emberNewUtilsValidators['default'].pesel(pesel);
            } catch (err) {
                if (err === 'invalid_pesel_length') {
                    this.set('notValidHelpText', 'Pesel musi mieć 11 cyfr.');
                    return false;
                } else if (err === 'invalid_date_in_pesel') {
                    this.set('notValidHelpText', 'Niepoprawna data w numerze pesel.');
                    return false;
                } else {
                    // invalid_pesel_number
                    this.set('notValidHelpText', 'Błędny numer pesel');
                    return false;
                }
            }

            this.set('validHelpText', 'Numer pesel poprawny.');
            return isValidPesel;
        },

        gasContractNumberValidator: function gasContractNumberValidator() {
            var number = this.get('field');
            var isValid = _emberNewUtilsValidators['default'].gasContractNumber(number);

            if (!isValid) {
                this.set('notValidHelpText', 'Błędny numer umowy GAZ.');
                return isValid;
            }

            this.set('validHelpText', 'Numer umowy GAZ poprawny.');
            return isValid;
        },

        eeContractNumberValidator: function eeContractNumberValidator() {
            var number = this.get('field');
            var isValid = _emberNewUtilsValidators['default'].eeContractNumber(number);

            if (!isValid) {
                this.set('notValidHelpText', 'Błędny numer umowy EE.');
                return isValid;
            }

            this.set('validHelpText', 'Numer umowy EE poprawny.');
            return isValid;
        },

        contractNumberValidator: function contractNumberValidator() {
            var number = this.get('field');
            var isValid = _emberNewUtilsValidators['default'].contractNumber(number);

            if (!isValid) {
                this.set('notValidHelpText', 'Błędny numer umowy.');
                return isValid;
            }

            this.set('validHelpText', 'Numer umowy poprawny.');
            return isValid;
        },

        minimumLengthValidation: function minimumLengthValidation() {
            var value = this.get('field');
            var isValid = _emberNewUtilsValidators['default'].minimumLength3(value);
            if (!isValid) {
                this.set('notValidHelpText', 'Minimalna ilość znaków wynosi 3');
                return isValid;
            }
            return isValid;
        },

        notBlankValidation: function notBlankValidation() {
            var value = this.get('field');
            var isValid = _emberNewUtilsValidators['default'].notBlank(value);
            if (!isValid) {
                this.set('notValidHelpText', 'Pole nie może być puste');
                return isValid;
            }
            return isValid;
        },

        positiveNumberValidator: function positiveNumberValidator() {
            var value = this.get('field');
            if (value) {
                var isValid = _emberNewUtilsValidators['default'].positiveNumber(value);
                if (!isValid) {
                    this.set('notValidHelpText', 'Wartość powinna być liczbą dodatnią');
                    return isValid;
                }
                return isValid;
            }
        },

        partnerEeContractNumberValidator: function partnerEeContractNumberValidator() {
            var value = this.get('field');
            if (value) {
                var isValid = _emberNewUtilsValidators['default'].bothContractNumbersEe(value);
                if (!isValid) {
                    this.set('notValidHelpText', 'Błędny numer umowy EE.');
                    return isValid;
                }

                this.set('validHelpText', 'Numer umowy EE poprawny.');
                return isValid;
            }
        },

        partnerGasContractNumberValidator: function partnerGasContractNumberValidator() {
            var value = this.get('field');
            if (value) {
                var isValid = _emberNewUtilsValidators['default'].bothContractNumbersGas(value);
                if (!isValid) {
                    this.set('notValidHelpText', 'Błędny numer umowy GAZ.');
                    return isValid;
                }

                this.set('validHelpText', 'Numer umowy GAZ poprawny.');
                return isValid;
            }
        },

        actions: {
            validateFunction: function validateFunction() {
                this.sendAction(this.get('customValidator'));
            }

        }
    });
});
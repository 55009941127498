define('ember-new/authenticators/custom', ['exports', 'ember', 'ember-simple-auth-token/authenticators/jwt'], function (exports, _ember, _emberSimpleAuthTokenAuthenticatorsJwt) {
    function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

    exports['default'] = _emberSimpleAuthTokenAuthenticatorsJwt['default'].extend({

        restore: function restore(data) {
            var self = this;
            var promise = this._super.apply(this, arguments);

            var mainPromise = new _ember['default'].RSVP.Promise(function (resolve, reject) {
                promise.then(function (data) {
                    self.afterUserAuthenticatedSuccessfully(data).then(function () {
                        resolve(data);
                    });
                }, function (data) {
                    reject(data);
                });
            });

            return mainPromise;
        },

        authenticate: function authenticate(credentials) {
            var self = this;
            var promise = this._super.apply(this, arguments);

            var mainPromise = new _ember['default'].RSVP.Promise(function (resolve, reject) {
                promise.then(function (data) {
                    self.afterUserAuthenticatedSuccessfully(data).then(function () {
                        resolve(data);
                    });
                }, function (data) {
                    reject(data);
                });
            });

            return mainPromise;
        },

        getAuthenticateData: function getAuthenticateData(credentials) {
            var _authentication;

            this.set('reCaptcha', 'reCaptcha');

            var authentication = (_authentication = {}, _defineProperty(_authentication, this.passwordField, credentials.password), _defineProperty(_authentication, this.identificationField, credentials.identification), _defineProperty(_authentication, this.reCaptcha, credentials.reCaptchaValue), _authentication);

            return authentication;
        },

        // an function which will be called when User will be authenticated successfully ~KJ
        afterUserAuthenticatedSuccessfully: function afterUserAuthenticatedSuccessfully(data) {
            var loader = this.container.lookup('loader:after_authenticate_data_loader');
            var allPromise = loader.load(data.access_token);

            allPromise.then(function (resp) {
                if (Array.isArray(resp) && resp.length > 2 && resp[2]) {
                    EmberNew.redalertlogin = resp[2];
                }
            });

            return allPromise;
        },

        refreshAccessToken: function refreshAccessToken(token, headers) {
            headers = { Authorization: "Bearer " + token };

            return this._super(token, headers);
        }
    });
});
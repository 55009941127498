define('ember-new/serializers/contract-client-dzu-view-model', ['exports', 'ember-new/serializers/application'], function (exports, _emberNewSerializersApplication) {
    exports['default'] = _emberNewSerializersApplication['default'].extend({
        AddressSerializerInit: (function () {
            this.addAttrsConfig({
                contactAddress: { serialize: 'records', deserialize: 'records', noKey: true },
                address: { serialize: 'records', deserialize: 'records', noKey: true },

                contactPersons: { embedded: 'always' },
                representativePersons: { embedded: 'always' },
                contactSettlementPersons: { embedded: 'always' }
            });
        }).on('init')
    });
});
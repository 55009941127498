define('ember-new/mixins/individual-contract-price-lists/controllers/price-lists-access', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Mixin.create({

        isGrantedToView: (function () {
            return this.get('TheUser').isGranted(['ROLE_ADMIN', 'ROLE_DG', 'ROLE_DZU2_SPECIALIST', 'ROLE_DZU2_MANAGER']);
        }).property(),

        isGrantedToEdit: (function () {
            return this.get('TheUser').isGranted(['ROLE_ADMIN', 'ROLE_DG', 'ROLE_DZU2_MANAGER']);
        }).property()

    });
});
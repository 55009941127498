define('ember-new/models/individual-contract-template-condition-list-view-model', ['exports', 'ember-data'], function (exports, _emberData) {

    var attr = _emberData['default'].attr;

    exports['default'] = _emberData['default'].Model.extend({
        uuid: attr('string'),
        type: attr('string'),
        periodType: attr('string'),
        from: attr('string'),
        to: attr('string'),
        priceG: attr('string'),
        priceE1: attr('string'),
        priceE2: attr('string'),
        priceE3: attr('string'),
        priceE4: attr('string'),
        fee: attr('string'),
        templateType: attr('string'),

        isEe: (function () {
            return this.get('templateType') == 'EE';
        }).property('templateType')
    });
});
define('ember-new/router', ['exports', 'ember', 'ember-new/config/environment'], function (exports, _ember, _emberNewConfigEnvironment) {

    var Router = _ember['default'].Router.extend({
        location: _emberNewConfigEnvironment['default'].locationType
    });

    Router.map(function () {
        this.route('login', { path: '/login' });

        this.route('dashboard', { path: '/' }, function () {
            this.route('dashboardPostEdit', { path: '/dashboard/post/edit/:post_id' }, function () {});
            this.route('dashboardPostLikes', {
                resetNamespace: true,
                path: '/dashboard/post/likes/:post_id'
            }, function () {});
            this.route('suggest', { path: '/dashboard/suggest' });
            this.route('add', { path: '/dashboard/add' });
            this.route('addSitebar', { path: '/dashboard/addSitebar' });
            this.route('editSitebar', { path: '/dashboard/:sitebar_id/editSitebar' });
            this.route('editNav', { path: '/dashboard/editNav' });
            this.route('setting-edit', { path: '/dashboard/editSetting' });
        });

        this.route('meeting-verification', { path: '/meeting/verification' }, function () {
            this.route('ordering', { path: '/ordering' }, function () {
                this.route('mark-as-wrong-data', { path: '/mark-as-wrong-data/:taskId' });
            });
            this.route('handling', { path: '/handling' });
            this.route('list', { path: '/list' }, function () {
                this.route('approve', { path: '/approve/:campaign_id' });
                this.route('delete', { path: '/delete/:campaign_id' });
                this.route('export', { path: '/export/:campaign_id' });
                this.route('show', { path: '/show/:campaign_id' }, function () {
                    this.route('delete', { path: '/delete/:task_id' });
                });
            });
            this.route('report', { path: '/report' });
        });

        this.route('tasks', { path: '/tasks' }, function () {
            this.route('task', { path: '/:task_id' }, function () {
                this.route('move', { path: '/move' });
                this.route('resolve', { path: '/resolve' });
                this.route('favourite', { path: '/favourite' });
                this.route('verificationStatus', { path: '/verificationStatus' });
                this.route('verificationMeetingRecord', { path: '/verificationMeetingRecord' });
            });
            this.route('tasksClient', { path: 'client/:client_id' }, function () {
                this.route('company');
                this.route('eeContact');
                this.route('gasContact');
                this.route('reservation');
            });
            this.route('eeAcquisition', { path: 'acquisition/ee/:process_id' }, function () {
                this.route('update');
                this.route('comments');
            });
            this.route('gasAcquisition', { path: 'acquisition/gas/:process_id' }, function () {
                this.route('update');
                this.route('comments');
            });
            this.route('search');
            this.route('addClient');
            this.route('addReservation');
            this.route('add', { path: 'add/:cid' });
            this.route('orderingMeetings');
            this.route('filters');
            this.route('channel-reservations');
        });

        this.route('profile', { path: '/profile' }, function () {});

        this.route('b2b-contract', { 'path': '/business/contract' }, function () {
            this.route('dzu', {}, function () {
                this.route('date-office-received', { path: 'date-office-received/:contractId' });
                this.route('date-office-received-last', { path: 'date-office-received-last/:contractId' });
                this.route('change-status', { path: 'status/:contractId' });
                this.route('usys', { path: 'usys/:contractId' });
                this.route('show-ee', { path: '/ee/:contractId' }, function () {
                    this.route('client', {}, function () {
                        this.route('add-contact-person');
                        this.route('add-representative-person');

                        this.route('remove-contact-person', { path: '/remove-contact-person/:personId' });
                        this.route('remove-representative', { path: '/remove-representative/:personId' });
                    });
                    this.route('comments');
                    this.route('contract-status');
                    this.route('ppe', function () {
                        this.route('add');
                        this.route('edit', { path: '/:ppeId' });
                        this.route('remove');
                        this.route('details', { path: '/:ppeId' }, function () {
                            this.route('invoices', { path: '/invoices' }, function () {
                                this.route('edit-invoice', { path: '/edit-invoice/:invoiceId' });
                            });
                        });
                    });
                    this.route('contract', {}, function () {
                        this.route('add-contract', { path: '/:contractUuid' });
                    });
                    this.route('add-contact-person');
                    this.route('add-representative-person');
                    this.route('remove-person');
                    this.route('remove-representative');
                    this.route('prices', {}, function () {
                        this.route('add');
                        this.route('add-price', { path: '/add-price/:priceSetId' });
                        this.route('edit-price', { path: '/edit-price/:id' });
                        this.route('edit-condition', { path: '/edit-condition/:id' });
                        this.route('remove-price', { path: '/remove-price/:id' });
                        this.route('remove-set', { path: '/remove-set/:priceSetId' });
                        this.route('assign-to-ppm', { path: '/assign-to-ppm/:priceSetId' });
                        this.route('assign-to-ppm-in-tariff', { path: '/assign-to-ppm-in-tariff/:priceSetId' });
                    });
                    this.route('vas', {}, function () {
                        this.route('edit', { path: '/edit/:id' });
                        this.route('delete', { path: '/delete/:vasUuid' });
                    });
                    this.route('ppm-prices');
                    this.route('documents');
                    this.route('add-audio-recording');
                    this.route('remove-recording');
                    this.route('volumes');
                });
                this.route('show-gas', { path: '/gas/:contractId' }, function () {
                    this.route('client', {}, function () {
                        this.route('add-contact-person');
                        this.route('add-representative-person');
                        this.route('add-settlement-contact-person');

                        this.route('remove-contact-person', { path: '/remove-contact-person/:personId' });
                        this.route('remove-representative', { path: '/remove-representative/:personId' });
                        this.route('remove-settlement-contact-person', { path: '/remove-settlement-contact-person/:personId' });
                    });
                    this.route('contract', {}, function () {
                        this.route('add-contract', { path: '/:contractUuid' });
                    });
                    this.route('comments');
                    this.route('contract-status');
                    this.route('ppg', function () {
                        this.route('add');
                        this.route('edit', { path: '/:ppgId' });
                        this.route('remove');
                        this.route('details', { path: '/:ppgId' }, function () {
                            this.route('invoices', { path: '/invoices' }, function () {
                                this.route('edit-invoice', { path: '/edit-invoice/:invoiceId' });
                            });
                        });
                    });

                    this.route('prices', {}, function () {
                        this.route('add');
                        this.route('add-price', { path: '/add-price/:priceSetId' });
                        this.route('edit-price', { path: '/edit-price/:id' });
                        this.route('edit-condition', { path: '/edit-condition/:id' });
                        this.route('remove-price', { path: '/remove-price/:id' });
                        this.route('remove-set', { path: '/remove-set/:priceSetId' });
                        this.route('assign-to-ppm', { path: '/assign-to-ppm/:priceSetId' });
                        this.route('assign-to-ppm-in-tariff', { path: '/assign-to-ppm-in-tariff/:priceSetId' });
                    });
                    this.route('vas', {}, function () {
                        this.route('edit', { path: '/edit/:id' });
                        this.route('delete', { path: '/delete/:vasUuid' });
                    });
                    this.route('ppm-prices');
                    this.route('documents');
                    this.route('add-audio-recording');
                    this.route('remove-recording');
                    this.route('volumes');
                });
                this.route('change-departments-specialists-dk', { path: '/change-departments-specialists-dk/:contractId' });
                this.route('change-departments-specialists-dr', { path: '/change-departments-specialists-dr/:contractId' });
                this.route('copy-contract', { path: 'copy-contract/:contractId' });
            });

            this.route('administration', {}, function () {
                this.route('section-pricelists', {}, function () {
                    this.route('copy', { path: 'copy/:pricesetUuid' });
                    this.route('details', { path: 'details/:pricesetUuid' });
                    this.route('delete', { path: 'delete/:pricesetUuid' });
                    this.route('import-ee');
                    this.route('list', { path: '/list' }, function () {
                        this.route('delete', { path: '/delete/:pricesetUuid' });
                    });
                });
                this.route('section-general');
                this.route('section-burden', {}, function () {
                    this.route('edit');
                });
                this.route('vas', {}, function () {
                    this.route('edit', { path: 'edit/:vasUuid' });
                    this.route('add');
                    this.route('delete', { path: 'delete/:vasUuid' });
                });
                this.route('section-products', {}, function () {
                    this.route('list', {}, function () {
                        this.route('edit-product-to-contract', { path: 'edit-product-to-contract/:productUuid' });
                        this.route('edit-condition-product', { path: 'edit-condition-product/:productUuid' });
                        this.route('delete-product', { path: 'delete/:productUuid' });
                    });
                    this.route('details', { path: 'details/:productUuid' });
                    this.route('add-to-condition');
                    this.route('add-product-to-contract');
                });
                this.route('gas-business-tariff', {}, function () {
                    this.route('list');
                    this.route('add');
                    this.route('edit', { path: ':tariffUuid/edit' });
                    this.route('delete', { path: ':tariffUuid/delete' });
                });
            });

            this.route('salesman', function () {
                this.route('show-ee', { path: '/ee/:contractId' }, function () {
                    this.route('summary');
                    this.route('data');
                    this.route('comments');
                    this.route('volumes');
                    this.route('documents');
                });
                this.route('show-gas', { path: '/gas/:contractId' }, function () {
                    this.route('summary');
                    this.route('data');
                    this.route('comments');
                    this.route('volumes');
                    this.route('documents');
                });
            });

            this.route('settings', { path: '/settings' }, function () {
                this.route('base-prices', { path: '/base-prices' }, function () {
                    this.route('add-ee');
                    this.route('add-gas');
                });
            });
        });

        this.route('renouncement', { 'path': '/renouncement' }, function () {
            this.route('list', { path: '/list' });
            this.route('show', { path: '/:renouncement_id' }, function () {
                this.route('search-contracts', { path: '/search/contracts/:renouncement_id' }, function () {
                    this.route('details', { path: '/details/:contract_id' });
                });
                this.route('anonymize', { path: '/anonymize/:renouncement_uuid' });
                this.route('history', { path: '/history' });
                this.route('anonymize-contract', { path: 'anonymize/contract/:contract_uuid' });
            });
            this.route('add', { path: '/add' }, function () {
                this.route('contract-details', { path: '/details/:contract_id' });
            });
            this.route('batch', {}, function () {
                this.route('response');
                this.route('tpa');
            });
            this.route('anonymize', { path: '/anonymize/:renouncement_uuid' });
            this.route('anonymize-contract', { path: 'anonymize/contract/:contract_uuid' });
        });

        this.route('individual-contract', { 'path': '/individual/contract' }, function () {
            this.route('list', { path: '/list' });
            this.route('add');
            this.route('add-ee', { path: '/add-ee/:pesel/' }, function () {
                this.route('set-complex', { path: '/set-complex' });
                this.route('renouncement-details', { path: '/details/:renouncement_id' });
            });
            this.route('add-gas', { path: '/add-gas/:pesel/' }, function () {
                this.route('renouncement-details', { path: '/details/:renouncement_id' });
            });
            this.route('add-df', { path: '/add-df/:pesel/' }, function () {
                this.route('set-complex', { path: '/set-complex' });
                this.route('renouncement-details', { path: '/details/:renouncement_id' });
            });
            this.route('partner-add-df', { path: '/partner-add-df/:pesel/' }, function () {
                this.route('set-complex', { path: '/set-complex' });
                this.route('renouncement-details', { path: '/details/:renouncement_id' });
            });
            this.route('edit-status', { path: '/:individual_contract_type/:individual_contract_id/editStatus' });
            this.route('edit-verification-call', { path: '/:individual_contract_type/:individual_contract_id/editVerificationCall' });
            this.route('usys', { path: '/:individual_contract_type/:individual_contract_id/usys' });
            this.route('validate', { path: '/:individual_contract_type/:individual_contract_id/validate' });
            this.route('show-ee', { path: '/ee/:contract_id' }, function () {
                this.route('client', { path: '/client' }, function () {
                    this.route('delete', { path: '/delete' });
                });
                this.route('sells', { path: '/sells' }, function () {
                    this.route('add-renouncement', { path: '/add/renouncement' });
                    this.route('search-renouncement', { path: '/search/renouncement' }, function () {
                        this.route('details', { path: '/details/:renouncement_id' });
                    });
                    this.route('unassign-renouncement', { path: '/unassign/:renouncement_id' });
                });
                this.route('prices', { path: '/prices/:contractUuid' });
                this.route('ppm', { path: '/ppm/:ppm_id' }, function () {
                    this.route('distribution-rate-search', { path: '/distributionRateSearch' });
                });
                this.route('documents', { path: '/documents' });
                this.route('process', { path: '/process' });
                this.route('statusHistory');
                this.route('anonymize', { path: '/anonymize' });
                this.route('entityChange', { path: '/entityChange' });
            });
            this.route('show-gas', { path: '/gas/:contract_id' }, function () {
                this.route('client', { path: '/client' }, function () {
                    this.route('delete', { path: '/delete' });
                });
                this.route('sells', { path: '/sells' }, function () {
                    this.route('add-renouncement', { path: '/add/renouncement' });
                    this.route('search-renouncement', { path: '/search/renouncement' }, function () {
                        this.route('details', { path: '/details/:renouncement_id' });
                    });
                    this.route('unassign-renouncement', { path: '/unassign/:renouncement_id' });
                });
                this.route('prices', { path: '/prices/:contractUuid' });
                this.route('ppm', { path: '/ppm/:ppm_id' });
                this.route('documents', { path: '/documents' });
                this.route('process', { path: '/process' });
                this.route('statusHistory');
                this.route('anonymize', { path: '/anonymize' });
                this.route('entityChange', { path: '/entityChange' });
            });
            this.route('settings', {}, function () {
                this.route('distribution-rate');
                this.route('business-rate');
                this.route('products');
            });
            this.route('batch', {}, function () {
                this.route('status');
                this.route('ph');
                this.route('vc');
                this.route('usys');
                this.route('tpa');
                this.route('start');
                this.route('comment');
                this.route('discount');
            });
            this.route('pika-sync');
            this.route('setAcceptDate');
            this.route('changeContractParameters');
            this.route('setVcStatus');
            this.route('exportFiltered');
            // modal:
            this.route('statusHistory', { path: '/:contractId/statusHistory' });
            this.route('anonymization-export', { path: '/anonymization/export' });
        });

        this.route('individual-contract-price-lists', { path: '/individual/contract/price-lists' }, function () {
            this.route('product', {}, function () {
                this.route('add');
                this.route('edit', { path: '/:productUuid/edit' });
                this.route('delete', { path: '/:productUuid/delete' });
            });
            this.route('template', {}, function () {
                this.route('add');
                this.route('add-from-code');
                this.route('edit', { path: '/:templateUuid/edit' });
                this.route('delete', { path: '/:templateUuid/delete' });
                this.route('conditions', { path: '/:templateUuid/conditions' }, function () {
                    this.route('add-standard');
                    this.route('add-promo');
                    this.route('edit', { path: '/:conditionUuid/edit' });
                    this.route('delete', { path: '/:conditionUuid/delete' });
                });
                this.route('vaps', { path: '/:templateUuid/vaps' }, function () {
                    this.route('add');
                    this.route('edit', { path: '/:vapUuid/edit' });
                    this.route('delete', { path: '/:vapUuid/delete' });
                });
            });
            this.route('vap', {}, function () {
                this.route('add');
                this.route('edit', { path: '/:vapUuid/edit' });
                this.route('delete', { path: '/:vapUuid/delete' });
            });
            this.route('business-tariff', {}, function () {
                this.route('edit', { path: '/:businessTariffUuid/edit' });
            });
        });

        this.route('individual-contract-partners', { 'path': '/individual/contract/partners' }, function () {
            this.route('filters');
            this.route('partner-add-all', { path: 'add' }, function () {});
            this.route('bulkFiltered', function () {
                this.route('change-vc-status');
                this.route('cancel-contract');
                this.route('mark-as-for-shipping');
                this.route('mark-as-sent');
                this.route('comment');
            });
            this.route('bulkSelected', function () {
                this.route('change-vc-status');
                this.route('cancel-contract');
                this.route('mark-as-for-shipping');
                this.route('mark-as-sent');
                this.route('comment');
            });
            this.route('changeContractParameters', function () {
                this.route('change-vc-status');
                this.route('cancel-contract');
                this.route('mark-as-for-shipping');
                this.route('mark-as-sent');
                this.route('comment');
            });
            //modal
            this.route('statusHistory', { path: '/:contractId/statusHistory' });
            this.route('editContract', { path: '/:contractId/editContract' });
            this.route('undoContractCancel', { path: '/:contractId/undoContractCancel' });
            this.route('addFiles', { path: '/:contractId/addFiles' });
            this.route('addAudio', { path: '/:contractId/addAudio' });
            this.route('setContractCorrected', { path: '/:contractId/setContractCorrected' });
        });

        this.route('admin', function () {
            this.route('dictionaries', function () {
                this.route('add');
            });
            this.route('anonymization-registry');
        });

        this.resource('reports', { path: '/reports' }, function () {
            this.route('volume', { path: '/volume' });
            this.route('salesBySalesman', { path: '/sales/bySalesman' });
            this.route('salesByRegions', { path: '/sales/byRegions' });
            this.route('volumeBySalesman', { path: '/volume/bySalesman' });
            this.route('volumeByRegion', { path: '/volume/byRegion' });
        });

        this.route('legacy', { path: '/legacy' }, function () {
            this.route('cc', { path: '/cc' });
            this.route('knowledge', { path: '/knowledge' });
            this.route('shop', { path: '/shop' }, function () {
                this.route('index', { path: '/index' });
            });
            this.route('tests', { path: '/elearning' }, function () {
                this.route('home', { path: '/home' });
            });
            this.route('users', { path: '/users' });
            this.route('reports', { path: '/reports' }, function () {
                this.route('sales', { path: '/sales' });
                this.route('weeklyMeetingsReport', { path: '/weeklyMeetingsReport' });
                this.route('volume', { path: '/volume' });
                this.route('callCenter', { path: '/cc' });
                this.route('ratingsByAcceptMM', { path: '/ratings/by_accept' });
                this.route('ratingsBySoldMM', { path: '/ratings/by_sold' });
                this.route('verificationMeetings', { path: '/verificationMeetings' });
            });
        });
    });

    exports['default'] = Router;
});
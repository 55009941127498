define("ember-new/templates/components/dashboard-slider", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 5,
                  "column": 12
                },
                "end": {
                  "line": 9,
                  "column": 12
                }
              },
              "moduleName": "ember-new/templates/components/dashboard-slider.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1, "target", "_blank");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("img");
              dom.setAttribute(el2, "alt", "");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element6 = dom.childAt(fragment, [1]);
              var element7 = dom.childAt(element6, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element6, 'href');
              morphs[1] = dom.createAttrMorph(element7, 'src');
              return morphs;
            },
            statements: [["attribute", "href", ["concat", [["get", "slide.contentImage", ["loc", [null, [6, 27], [6, 45]]]]]]], ["attribute", "src", ["concat", [["get", "slide.contentFile", ["loc", [null, [7, 32], [7, 49]]]]]]]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 10,
                  "column": 12
                },
                "end": {
                  "line": 14,
                  "column": 12
                }
              },
              "moduleName": "ember-new/templates/components/dashboard-slider.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("i");
              dom.setAttribute(el2, "class", "fa fa-file-pdf-o");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element5 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element5, 'href');
              return morphs;
            },
            statements: [["attribute", "href", ["concat", [["get", "slide.contentFile", ["loc", [null, [11, 27], [11, 44]]]]]]]],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 15,
                  "column": 12
                },
                "end": {
                  "line": 19,
                  "column": 12
                }
              },
              "moduleName": "ember-new/templates/components/dashboard-slider.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("i");
              dom.setAttribute(el2, "class", "fa fa-file-audio-o");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element4 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element4, 'href');
              return morphs;
            },
            statements: [["attribute", "href", ["concat", [["get", "slide.contentFile", ["loc", [null, [16, 27], [16, 44]]]]]]]],
            locals: [],
            templates: []
          };
        })();
        var child3 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 20,
                  "column": 12
                },
                "end": {
                  "line": 24,
                  "column": 12
                }
              },
              "moduleName": "ember-new/templates/components/dashboard-slider.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1, "target", "_blank");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("i");
              dom.setAttribute(el2, "class", "fa fa-file-video-o");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element3, 'href');
              return morphs;
            },
            statements: [["attribute", "href", ["concat", [["get", "slide.contentYoutube", ["loc", [null, [21, 27], [21, 47]]]]]]]],
            locals: [],
            templates: []
          };
        })();
        var child4 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 25,
                  "column": 12
                },
                "end": {
                  "line": 29,
                  "column": 12
                }
              },
              "moduleName": "ember-new/templates/components/dashboard-slider.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1, "target", "_blank");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("i");
              dom.setAttribute(el2, "class", "fa fa-file-video-o");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element2, 'href');
              return morphs;
            },
            statements: [["attribute", "href", ["concat", [["get", "slide.contentVimeo", ["loc", [null, [26, 27], [26, 45]]]]]]]],
            locals: [],
            templates: []
          };
        })();
        var child5 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 30,
                  "column": 12
                },
                "end": {
                  "line": 32,
                  "column": 12
                }
              },
              "moduleName": "ember-new/templates/components/dashboard-slider.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["content", "slide.contentTextAttachmentFormatted", ["loc", [null, [31, 19], [31, 61]]]]],
            locals: [],
            templates: []
          };
        })();
        var child6 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 35,
                    "column": 20
                  },
                  "end": {
                    "line": 35,
                    "column": 77
                  }
                },
                "moduleName": "ember-new/templates/components/dashboard-slider.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("Edytuj");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 33,
                  "column": 12
                },
                "end": {
                  "line": 39,
                  "column": 12
                }
              },
              "moduleName": "ember-new/templates/components/dashboard-slider.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1, "class", "edit");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2, "href", "#");
              var el3 = dom.createTextNode("Usuń");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var element1 = dom.childAt(element0, [3]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(element0, 1, 1);
              morphs[1] = dom.createElementMorph(element1);
              return morphs;
            },
            statements: [["block", "link-to", ["dashboard.dashboardPostEdit", ["get", "slide.id", ["loc", [null, [35, 61], [35, 69]]]]], [], 0, null, ["loc", [null, [35, 20], [35, 89]]]], ["element", "action", ["removeAction", ["get", "slide.id", ["loc", [null, [36, 56], [36, 64]]]]], [], ["loc", [null, [36, 32], [36, 66]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 4
              },
              "end": {
                "line": 42,
                "column": 4
              }
            },
            "moduleName": "ember-new/templates/components/dashboard-slider.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "single-slide");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element8 = dom.childAt(fragment, [1]);
            var morphs = new Array(7);
            morphs[0] = dom.createMorphAt(element8, 1, 1);
            morphs[1] = dom.createMorphAt(element8, 2, 2);
            morphs[2] = dom.createMorphAt(element8, 3, 3);
            morphs[3] = dom.createMorphAt(element8, 4, 4);
            morphs[4] = dom.createMorphAt(element8, 5, 5);
            morphs[5] = dom.createMorphAt(element8, 6, 6);
            morphs[6] = dom.createMorphAt(element8, 7, 7);
            return morphs;
          },
          statements: [["block", "if", [["get", "slide.imageAttachment", ["loc", [null, [5, 18], [5, 39]]]]], [], 0, null, ["loc", [null, [5, 12], [9, 19]]]], ["block", "if", [["get", "slide.pdfAttachment", ["loc", [null, [10, 18], [10, 37]]]]], [], 1, null, ["loc", [null, [10, 12], [14, 19]]]], ["block", "if", [["get", "slide.audioAttachment", ["loc", [null, [15, 18], [15, 39]]]]], [], 2, null, ["loc", [null, [15, 12], [19, 19]]]], ["block", "if", [["get", "slide.youtubeAttachment", ["loc", [null, [20, 18], [20, 41]]]]], [], 3, null, ["loc", [null, [20, 12], [24, 19]]]], ["block", "if", [["get", "slide.vimeoAttachment", ["loc", [null, [25, 18], [25, 39]]]]], [], 4, null, ["loc", [null, [25, 12], [29, 19]]]], ["block", "if", [["get", "content.textAttachment", ["loc", [null, [30, 18], [30, 40]]]]], [], 5, null, ["loc", [null, [30, 12], [32, 19]]]], ["block", "if", [["get", "canEdit", ["loc", [null, [33, 18], [33, 25]]]]], [], 6, null, ["loc", [null, [33, 12], [39, 19]]]]],
          locals: ["slide"],
          templates: [child0, child1, child2, child3, child4, child5, child6]
        };
      })();
      return {
        meta: {
          "fragmentReason": {
            "name": "missing-wrapper",
            "problems": ["wrong-type"]
          },
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 43,
              "column": 0
            }
          },
          "moduleName": "ember-new/templates/components/dashboard-slider.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "each", [["get", "posts", ["loc", [null, [3, 12], [3, 17]]]]], [], 0, null, ["loc", [null, [3, 4], [42, 13]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 45,
              "column": 0
            },
            "end": {
              "line": 45,
              "column": 67
            }
          },
          "moduleName": "ember-new/templates/components/dashboard-slider.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Usuwam");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.3.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 45,
            "column": 94
          }
        },
        "moduleName": "ember-new/templates/components/dashboard-slider.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "slick-slider", [], ["slidesToShow", 3, "slidesToScroll", 1, "autoplay", true, "accessibility", false, "autoplaySpeed", 3000, "centerMode", ["subexpr", "@mut", [["get", "center", ["loc", [null, [1, 112], [1, 118]]]]], [], []], "arrows", false, "pauseOnHover", false, "centerPadding", ["subexpr", "@mut", [["get", "0px", ["loc", [null, [1, 165], [1, 168]]]]], [], []]], 0, null, ["loc", [null, [1, 0], [43, 17]]]], ["block", "loading-action-spinner", [], ["isVisible", ["subexpr", "@mut", [["get", "showLoading", ["loc", [null, [45, 36], [45, 47]]]]], [], []], "type", "dark"], 1, null, ["loc", [null, [45, 0], [45, 94]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});
define('ember-new/routes/b2b-contract/dzu/show-gas/prices/add-price', ['exports', 'ember-new/routes/authenticated'], function (exports, _emberNewRoutesAuthenticated) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({
        model: function model(params) {

            this.store.unloadAll('AddPriceConditionViewModel');
            this.store.unloadAll('GasPriceConditionProductViewModel');
            this.store.unloadAll('BasePriceSetSelectDzuViewModel');

            var route = this;
            var contract = this.modelFor('b2b-contract.dzu.show-gas');

            console.log('dddd', contract);

            var promiseModel = new Ember.RSVP.Promise(function (resolve, reject) {
                var products = route.store.query('GasPriceConditionProductViewModel', { contractProductId: contract.get('productId') });

                products.then(function (response) {
                    var basePrices = route.store.query('BasePriceSetSelectDzuViewModel', { productId: contract.get('productId') });
                    basePrices.then(function (response3) {
                        var addPriceView = route.store.query('AddPriceConditionViewModel', { priceSetId: params.priceSetId, contractId: contract.get('id') });
                        addPriceView.then(function (response4) {
                            resolve(response4.get('firstObject'));
                        });
                    });
                });
            }, "LoadModelGasAddPrice");

            return promiseModel;
        },

        setupController: function setupController(ctrl, model) {
            ctrl.set('contractId', this.modelFor('b2b-contract.dzu.show-gas').get('id'));
            ctrl.set('model', model);
        }
    });
});
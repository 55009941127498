define('ember-new/models/week', ['exports', 'ember-new/models/abstract', 'ember-data'], function (exports, _emberNewModelsAbstract, _emberData) {

    var Week = _emberNewModelsAbstract['default'].extend({

        startDay: _emberData['default'].attr('string'),
        endDay: _emberData['default'].attr('string'),
        quantityOfWorkDays: _emberData['default'].attr('number'),
        weekNumber: _emberData['default'].attr('number'),
        year: _emberData['default'].attr('number'),
        isAtTurnOfYear: _emberData['default'].attr('boolean'),

        dateRanges: (function () {
            var startDate = moment(this.get('startDay'));
            var endDate = moment(this.get('endDay'));

            var text = startDate.format('DD.MM') + ' - ' + endDate.format('DD.MM');
            return text;
        }).property('startDay', 'endDay'),
        name: (function () {
            var name = this.get('shortName');

            name = name + ' ' + this.get('dateRanges');

            return name;
        }).property('shortName', 'dateRanges'),
        shortName: (function () {
            var name = "Tydzień ";
            name = name + this.get('weekNumber') + '/' + this.get('year');

            return name;
        }).property('weekNumber', 'year', 'startDay', 'endDay')
    });

    exports['default'] = Week;
});
define('ember-new/mixins/batch-actions-controller', ['exports', 'ember', 'ember-new/config/environment'], function (exports, _ember, _emberNewConfigEnvironment) {
    exports['default'] = _ember['default'].Mixin.create({

        selectAll: false,

        selectedItems: [],

        batchActions: [],
        batchAction: null,

        reset: function reset() {
            this._super();
            this.set('selectAll', false);
        },

        onSelectAll: (function () {
            var ctrl = this;
            this.get('model').forEach(function (item) {
                item.set('checked', ctrl.get('selectAll'));
            });
        }).observes('selectAll'),

        actions: {
            doBatch: function doBatch() {
                var batchAction = this.get('batchAction');
                this.send(batchAction);
            }
        }

    });
});
define('ember-new/mixins/serializers/individual-ee-gas-contract', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Mixin.create({
        IndividualContractSerializerInit: (function () {
            this.addAttrsConfig({
                client: { serialize: 'records', deserialize: 'records' },

                product: { serialize: 'id', deserialize: 'id' },
                tariff: { serialize: 'id', deserialize: 'id' },
                variant: { serialize: 'id', deserialize: 'id' },
                billingFrequency: { serialize: 'id', deserialize: 'id' },

                dzuSpecialist: { serialize: 'id', deserialize: 'id' },
                tpaSpecialist: { serialize: 'id', deserialize: 'id' },
                partner: { serialize: 'id', deserialize: 'id' },
                verificationCallStatus: { serialize: 'id', deserialize: 'id' },
                verificationCallType: { serialize: 'id', deserialize: 'id' },

                fileDocumentContract: { serialize: 'id', deserialize: 'records' },
                fileDocumentProxy: { serialize: 'id', deserialize: 'records' },
                fileDocumentInvoices: { serialize: 'id', deserialize: 'records' },
                fileDocumentDataUpdateRequest: { serialize: 'id', deserialize: 'records' },
                fileDocumentChangeTariffRequest: { serialize: 'id', deserialize: 'records' },
                fileDocumentOthers: { serialize: 'id', deserialize: 'records' },
                fileDocumentProxySubstitution: { serialize: 'id', deserialize: 'records' },
                fileDocumentCession: { serialize: 'id', deserialize: 'records' },
                fileDocumentLawTitleToPremises: { serialize: 'id', deserialize: 'records' },
                fileDocumentNewDevice: { serialize: 'id', deserialize: 'records' },
                fileDocumentForm: { serialize: 'id', deserialize: 'records' },
                fileDocumentRegulation: { serialize: 'id', deserialize: 'records' },
                fileDocumentProxyReserve: { serialize: 'id', deserialize: 'records' },

                fileDocumentContract2: { serialize: 'id', deserialize: 'records' },
                fileDocumentProxy2: { serialize: 'id', deserialize: 'records' },
                fileDocumentInvoices2: { serialize: 'id', deserialize: 'records' },
                fileDocumentDataUpdateRequest2: { serialize: 'id', deserialize: 'records' },
                fileDocumentChangeTariffRequest2: { serialize: 'id', deserialize: 'records' },
                fileDocumentOthers2: { serialize: 'id', deserialize: 'records' },
                fileDocumentProxySubstitution2: { serialize: 'id', deserialize: 'records' },
                fileDocumentCession2: { serialize: 'id', deserialize: 'records' },
                fileDocumentLawTitleToPremises2: { serialize: 'id', deserialize: 'records' },
                fileDocumentNewDevice2: { serialize: 'id', deserialize: 'records' },
                fileDocumentForm2: { serialize: 'id', deserialize: 'records' },
                fileDocumentRegulation2: { serialize: 'id', deserialize: 'records' },
                fileDocumentProxyReserve2: { serialize: 'id', deserialize: 'records' },

                fileDocumentContract3: { serialize: 'id', deserialize: 'records' },
                fileDocumentProxy3: { serialize: 'id', deserialize: 'records' },
                fileDocumentInvoices3: { serialize: 'id', deserialize: 'records' },
                fileDocumentDataUpdateRequest3: { serialize: 'id', deserialize: 'records' },
                fileDocumentChangeTariffRequest3: { serialize: 'id', deserialize: 'records' },
                fileDocumentOthers3: { serialize: 'id', deserialize: 'records' },
                fileDocumentProxySubstitution3: { serialize: 'id', deserialize: 'records' },
                fileDocumentCession3: { serialize: 'id', deserialize: 'records' },
                fileDocumentLawTitleToPremises3: { serialize: 'id', deserialize: 'records' },
                fileDocumentNewDevice3: { serialize: 'id', deserialize: 'records' },
                fileDocumentForm3: { serialize: 'id', deserialize: 'records' },
                fileDocumentRegulation3: { serialize: 'id', deserialize: 'records' },
                fileDocumentProxyReserve3: { serialize: 'id', deserialize: 'records' },
                fileAutenti: { serialize: 'id', deserialize: 'records' },
                fileAutenti2: { serialize: 'id', deserialize: 'records' },
                fileAutenti3: { serialize: 'id', deserialize: 'records' },

                fileAudio1: { serialize: 'id', deserialize: 'records' },
                fileAudio2: { serialize: 'id', deserialize: 'records' },
                fileAudio3: { serialize: 'id', deserialize: 'records' },

                partnerFile1: { serialize: 'id', deserialize: 'records' },
                partnerFile2: { serialize: 'id', deserialize: 'records' },
                partnerFile3: { serialize: 'id', deserialize: 'records' },
                partnerFile4: { serialize: 'id', deserialize: 'records' },
                partnerFile5: { serialize: 'id', deserialize: 'records' },
                vcRecording: { serialize: 'id', deserialize: 'records' },
                businessRates: { serialize: 'id', deserialize: 'id' },

                eeProductLine: { serialize: 'id', deserialize: 'id' },

                gasProductLine: { serialize: 'id', deserialize: 'id' },
                ppmAddress: { serialize: 'records', deserialize: 'records', noKey: true }

            });
        }).on('init')
    });
});
define('ember-new/utils/date-range-select', ['exports', 'ember'], function (exports, _ember) {

    var DateRangeSelect = _ember['default'].Object.extend({
        monthNames: ['stycznia', 'lutego', 'marca', 'kwietnia', 'maja', 'czerwca', 'lipca', 'sierpnia', 'września', 'października', 'listopada', 'grudnia'],
        startDate: new Date('01-01-2020'),
        endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1),
        dates: [],
        getSelect: function getSelect(fromDay, toDay) {
            var returnDates = [];

            while (this.startDate <= this.endDate) {
                var firstDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth() - 1, fromDay);
                var lastDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), toDay);

                returnDates.unshift({
                    value: this.setDay(firstDate) + '-' + this.setMonth(firstDate) + '-' + this.setYear(firstDate) + ', ' + this.setDay(lastDate) + '-' + this.setMonth(lastDate) + '-' + this.setYear(lastDate),
                    label: 'Od ' + this.setDay(firstDate) + ' ' + this.monthNames[firstDate.getMonth()] + ' ' + this.setYear(firstDate) + ' do ' + this.setDay(lastDate) + ' ' + this.monthNames[lastDate.getMonth()] + ' ' + this.setYear(lastDate)
                });

                this.startDate.setMonth(this.startDate.getMonth() + 1);
            }

            return returnDates;
        },
        setDay: function setDay(date) {
            return new Intl.DateTimeFormat('pl', { day: '2-digit' }).format(date);
        },
        setMonth: function setMonth(date) {
            return new Intl.DateTimeFormat('pl', { month: '2-digit' }).format(date);
        },
        setYear: function setYear(date) {
            return new Intl.DateTimeFormat('pl', { year: 'numeric' }).format(date);
        }
    });

    exports['default'] = DateRangeSelect;
});
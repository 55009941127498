define('ember-new/authorizers/custom', ['exports', 'ember-simple-auth/authorizers/base', 'ember'], function (exports, _emberSimpleAuthAuthorizersBase, _ember) {
  exports['default'] = _emberSimpleAuthAuthorizersBase['default'].extend({
    authorize: function authorize(data, block) {
      var cookies = this.container.lookup('service:cookies');
      var user = cookies.read('impersonatingUser');
      var accessToken = data.access_token;
      block('Authorization', 'Bearer ' + accessToken);
      if (user) {
        block('X-Switch-User', user);
      }
    }
  });
});
define('ember-new/controllers/renouncement/show/search-contracts', ['exports', 'ember', 'ember-new/config/environment'], function (exports, _ember, _emberNewConfigEnvironment) {
    exports['default'] = _ember['default'].Controller.extend({

        ajax: _ember['default'].inject.service('ajax'),
        isLoaded: false,

        loadContracts: (function (params) {
            this.set('isLoaded', false);
            var _this = this;
            var store = this.get('store');

            store.unloadAll('individualContractSimplified');

            var promise = this.get('ajax').ajaxGet(_emberNewConfigEnvironment['default'].apiUrl + '/api/renouncements/search_contracts/' + _this.get('renouncement.id'));

            promise.then(function (response) {
                store.pushPayload({ individualContractSimplified: response.contracts });
                _this.set('isLoaded', true);
            });

            this.set('con', this.store.peekAll('individualContractSimplified'));
        }).observes('renouncement'),

        actions: {
            chooseContract: function chooseContract(contract) {
                var _this = this;
                var parentController = this.container.lookup('controller:renouncement.show');
                var renouncement = this.get('renouncement');

                var serializedData = {
                    contractId: contract.get('id'),
                    renouncementId: renouncement.get('id')
                };

                var promise = this.get('ajax').ajaxPost(_emberNewConfigEnvironment['default'].apiUrl + '/api/renouncements/assign_contract', serializedData);

                promise.then(function (response) {
                    _this.get('store').pushPayload(response);
                    parentController.set('renouncementStatement', response.renouncementStatement);
                    _this.transitionToRoute('renouncement.show');
                }, function (response) {});
            }
        }

    });
});
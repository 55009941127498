define("ember-new/mixins/comments/comments-route", ["exports", "ember", "ember-new/config/environment", "ember-new/utils/ajax/get"], function (exports, _ember, _emberNewConfigEnvironment, _emberNewUtilsAjaxGet) {
    exports["default"] = _ember["default"].Mixin.create({
        ajax: _ember["default"].inject.service('ajax'),
        restControllerName: '', // do rozszerzenia w docelowym route
        parentModelName: '', // do rozszerzenia w docelowym route
        model: function model() {
            this.store.unloadAll('comment');
            var item = this.modelFor(this.get('parentModelName'));
            var commentsPromise = this.get('ajax').ajaxGet(_emberNewConfigEnvironment["default"].apiUrl + '/rest/' + this.get('restControllerName') + '/' + item.get('id') + '/comments');
            var _this = this;

            var modelPromise = new _ember["default"].RSVP.Promise(function (resolve, reject) {
                commentsPromise.then(function (response) {
                    _this.store.pushPayload(response);
                    var comments = _this.store.peekAll('comment');
                    resolve(comments);
                });
            });
            return modelPromise;
        }
    });
});
define('ember-new/components/filter-datetime', ['exports', 'ember', 'ember-bootstrap-datetimepicker/components/bs-datetimepicker'], function (exports, _ember, _emberBootstrapDatetimepickerComponentsBsDatetimepicker) {
    exports['default'] = _emberBootstrapDatetimepickerComponentsBsDatetimepicker['default'].extend({
        format: 'DD.MM.YYYY',
        outputFormat: 'DD.MM.YYYY',
        sideBySide: false,
        outputType: 'string', // what bind to value? moment object or string

        hasDate: (function () {
            if (this.get('value') !== null && this.get('value') !== undefined) {
                return true;
            }

            return false;
        }).property('value'),

        onDidReceiveAttrs: (function () {
            this._validateAttrs();
        }).on('didReceiveAttrs'),

        _validateAttrs: function _validateAttrs() {
            if (this.get('outputType') != 'moment' && this.get('outputType') != 'string') {
                throw new _ember['default'].Error("Allowed `outputType` options of d-field-datetime is 'moment' or 'string'");
            }

            if (this.get('outputType') == 'string' && !this.get('outputFormat')) {
                throw new _ember['default'].Error("When `outputType` is string, `outputFormat` must be defined");
            }
        },

        // onDatePropertyChanged: function () {
        //     this.set('value', this.get('date'));
        // }.observes('date'),
        // onValuePropertyChanged: function () {
        //     this.set('date', this.get('value'));
        // }.observes('value'),

        _setupChangeEvent: function _setupChangeEvent(bsDateTimePicker) {
            var _this = this;

            bsDateTimePicker.on('dp.change', function (ev) {
                _ember['default'].run(function () {
                    var value;
                    var date = _this.bsDateTimePicker.date();

                    if (date === null) {
                        value = null;
                    } else if (_this.get('outputType') == "moment") {
                        value = date;
                    } else if (_this.get('outputType') == "string") {
                        value = date.format(_this.get('outputFormat'));
                    }

                    _this.set('value', value);
                });
            });
        },

        onValueChanged: (function () {
            //this._updateDateTimePicker();
        }).observes('value'),

        _updateDateTimePicker: function _updateDateTimePicker() {
            //this._super();

            if (this.bsDateTimePicker && this.get('value') !== undefined) {
                var val = this.get('value');

                if (val === undefined || val === null) {
                    this.bsDateTimePicker.date(null);
                } else if (this.get('outputType') == "string" && this.get('value').length === 10) {
                    this.bsDateTimePicker.date(moment(val, this.get('outputFormat')));
                }
            }
        },

        inputValueChanged: (function () {
            //if (moment(this.get('inputDateValue'))) {
            this.set('value', this.get('inputDateValue'));
            //}
        }).observes('inputDateValue'),

        actions: {
            removeValue: function removeValue() {
                this.bsDateTimePicker.date(null);
            },
            applyFilter: function applyFilter() {}
        }
    });
});
define("ember-new/controllers/b2b-contract/dzu/show-gas/prices/remove-set", ["exports", "ember", "ember-new/mixins/controllers/simple-ajax-controller"], function (exports, _ember, _emberNewMixinsControllersSimpleAjaxController) {
    exports["default"] = _ember["default"].Controller.extend(_emberNewMixinsControllersSimpleAjaxController["default"], {

        clientModel: null,

        commandName: "RemoveContractPriceSetDzuCommand",
        commandUrl: '/rest/removecontractpricesetdzucommands',
        command: function command() {
            return {
                RemoveContractPriceSetDzuCommand: {
                    priceSetId: this.get('model.priceSetId')
                }
            };
        },
        successInfo: 'Operacja zakończona poprawnie',
        errorInfo: 'Operacja zakończona niepoprawnie',

        afterSuccessfulCommand: function afterSuccessfulCommand() {
            var ctrl = this;
            var route = this.container.lookup('route:b2b-contract.dzu.show-gas.prices');
            route.refresh();
            setTimeout(function () {
                ctrl.transitionToRoute('b2b-contract.dzu.show-gas.prices');
            }, 500);
        }

    });
});
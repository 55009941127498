define("ember-new/routes/tasks/clients-add-businessclient", ["exports", "ember-new/routes/authenticated", "ember-new/config/environment", "ember"], function (exports, _emberNewRoutesAuthenticated, _emberNewConfigEnvironment, _ember) {

    var ClientsAddBusinessclientRoute = _emberNewRoutesAuthenticated["default"].extend({
        model: function model() {
            var client = this.store.createRecord('keBusinessClient');
            return client;
        },
        deactivate: function deactivate() {
            var model = this.modelFor(this.routeName);

            if (model.get('isNew') && !model.get('isSaving')) {
                model.deleteRecord();
            }
        }
    });

    exports["default"] = ClientsAddBusinessclientRoute;
});
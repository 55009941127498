define('ember-new/mixins/b2b-contract/controllers/client-info', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Mixin.create({

		clientTypeUuid: null,
		vatCentralization: false,

		fillFormClientInfo: function fillFormClientInfo(form) {
			form.set('model.clientUuid', this.get('model.clientUuid'));
			form.set('model.clientType', this.get('model.clientType'));
			form.set('model.clientTypeUuid', this.get('model.clientTypeUuid'));
			form.set('model.clientTypeName', this.get('model.clientTypeName'));
			form.set('model.clientVatCentralization', this.get('model.clientVatCentralization'));
			form.set('model.clientVatCentralizationCompanyName', this.get('model.clientVatCentralizationCompanyName'));
			form.set('model.clientVatCentralizationNip', this.get('model.clientVatCentralizationNip'));
			form.set('model.clientVatCentralizationStreet', this.get('model.clientVatCentralizationStreet'));
			form.set('model.clientVatCentralizationStreetNo', this.get('model.clientVatCentralizationStreetNo'));
			form.set('model.clientVatCentralizationAptNo', this.get('model.clientVatCentralizationAptNo'));
			form.set('model.clientVatCentralizationPostalCode', this.get('model.clientVatCentralizationPostalCode'));
			form.set('model.clientVatCentralizationPostalCity', this.get('model.clientVatCentralizationPostalCity'));
			form.set('model.clientVatCentralizationCity', this.get('model.clientVatCentralizationCity'));
			form.set('model.clientVatCentralizationDistrict', this.get('model.clientVatCentralizationDistrict'));
			form.set('model.clientVatCentralizationDistrictUuid', this.get('model.clientVatCentralizationDistrictUuid'));
			form.set('model.clientCreditVerification', this.get('model.clientCreditVerification'));
		},

		isClientTypeNameHidden: (function () {
			var clientTypeUuid = this.get('clientTypeUuid');
			return clientTypeUuid === '83903a48-1428-4beb-a2fd-2503c6ffcf0f' || !clientTypeUuid;
		}).property('clientTypeUuid'),

		clientTypeChanged: (function () {
			this.set('clientTypeUuid', this.get('model.clientTypeUuid'));
		}).observes('model.clientTypeUuid'),

		isNotClientVatCentralization: (function () {
			return !this.get('vatCentralization');
		}).property('vatCentralization'),

		vatCentralizationChanged: (function () {
			this.set('vatCentralization', this.get('model.clientVatCentralization'));
		}).observes('model.clientVatCentralization')

	});
});
define('ember-new/models/business-month-list-item-view-model', ['exports', 'ember-new/models/abstract', 'ember-data'], function (exports, _emberNewModelsAbstract, _emberData) {
    exports['default'] = _emberNewModelsAbstract['default'].extend({
        uuid: _emberData['default'].attr('string'),
        name: _emberData['default'].attr('string'),
        acceptStart: _emberData['default'].attr('date'),
        acceptTo: _emberData['default'].attr('date'),
        salesStart: _emberData['default'].attr('date'),
        salesTo: _emberData['default'].attr('date'),
        year: _emberData['default'].attr('number'),
        month: _emberData['default'].attr('number')
    });
});
define('ember-new/components/bulk-actions-partner-contracts', ['exports', 'ember', 'ember-new/config/environment', 'ember-new/utils/utils'], function (exports, _ember, _emberNewConfigEnvironment, _emberNewUtilsUtils) {
    var computed = _ember['default'].computed;
    var service = _ember['default'].inject.service;

    /**
     *
     */
    exports['default'] = _ember['default'].Component.extend({
        ajax: service(),
        applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),

        selectedFilters: null, // obiekt z filtrami {filter1: 'value', filter2: value, ...}
        uuids: null,

        endpointCheck: null, // endpoint do sprawdzenia przed wykonaniem akcji
        endpointDo: null,

        isLoading: false,
        isLoadingApplyChanges: false,
        exeResult: null,

        type: null, // edit-vc-status, cancel-contract, mark-as-for-shipping, mark-as-sent, comment

        commentVisibilityToSelect: [{ value: 'only_for_bo', label: 'Tylko dla BO' }, { value: 'ph_and_bo', label: 'Dla PH i dla BO' }],

        errors: [],
        hasErrors: computed('errors', function () {
            return this.get('errors').length > 0;
        }),
        isDisabledApplyButton: computed('isLoadingApplyChanges', 'canSendApplyRequest', function () {
            return this.get('isLoadingApplyChanges') || !this.get('canSendApplyRequest');
        }),
        notInProgress: computed('isLoadingApplyChanges', function () {
            return !this.get('isLoadingApplyChanges');
        }),
        checkUrl: computed('endpointCheck', function () {
            var endpoint = this.get('endpointCheck');
            return _emberNewConfigEnvironment['default'].apiUrl + '/rest/' + endpoint;
        }),
        doUrl: computed('endpointDo', function () {
            var endpoint = this.get('endpointDo');
            return _emberNewConfigEnvironment['default'].apiUrl + '/rest/' + endpoint;
        }),

        endpointRequestObjectName: null, // nazwa obiektu ktory idzie w requescie do endpointu ex. EditVcStatus

        // DANE Z API:
        checkResult: null, // dane otrzymane z API...
        checkResultCount: computed.alias('checkResult.length'),
        // Umowy dla ktorych jest mozliwa zmiana:
        checkResultCanBeChangedContracts: computed('checkResult', function () {
            var checkResults = this.get('checkResult');
            if (checkResults) {
                return checkResults.filter(function (item) {
                    return item['isValidToHaveActionMade'] === true;
                });
            }
            return false;
        }),
        checkResultCanBeChangedContractsCount: computed('checkResultCanBeChangedContracts', function () {
            return this.get('checkResultCanBeChangedContracts').length;
        }),
        checkResultCanBeChangedUuids: computed('checkResultCanBeChangedContracts', function () {
            return this.get('checkResultCanBeChangedContracts').mapBy('contractUuid');
        }),

        // tablica kontraktow ktore nie moga byc wyslane do endpointu
        checkResultCanNotBeChangedContracts: computed('checkResult', function () {
            var checkResults = this.get('checkResult');
            if (!checkResults) {
                return false;
            }
            return checkResults.filter(function (item) {
                return item['isValidToHaveActionMade'] === false && item['isCancelled'] !== true;
            });
        }),
        checkResultCanNotBeChangedContractsCount: computed('checkResultCanNotBeChangedContracts', function () {
            return this.get('checkResultCanNotBeChangedContracts').length;
        }),

        checkedObjectName: null, // nazwa obiektu ktory przychodzi po sprawdzeniu

        canSendApplyRequest: computed('checkResultCanBeChangedContractsCount', 'vcStatusUuid', 'checkResultCanNotBeChangedContractsCount', function () {
            // const vcStatusUuid = this.get('vcStatusUuid'),
            //     countValid = this.get('checkResultCanBeChangedContractsCount'),
            var countInvalid = this.get('checkResultCanNotBeChangedContractsCount');
            // type = this.get('type');

            return countInvalid === 0;
        }),

        vcStatusUuid: null,

        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
            var filters = this.get('selectedFilters'),
                uuids = this.get('uuids');

            if (filters && uuids) {
                throw new Error('Can not use both options. Please use "selectedFilters" or "uuids".');
            }

            this.initializeByType();

            this.checkData();
        },

        initializeByType: function initializeByType() {
            var type = this.get('type');
            // edit-vc-status, cancel-contract, mark-as-for-shipping, mark-as-sent, comment
            if (type === 'edit-vc-status') {
                this.initializeEditVcStatus();
            } else if (type === 'cancel-contract') {
                this.initializeCancelContract();
            } else if (type === 'mark-as-for-shipping') {
                this.initializeMarkAsForShipping();
            } else if (type === 'mark-as-sent') {
                this.initializeMarkAsSent();
            } else if (type === 'comment') {
                this.initializeCommentSent();
            }
        },
        initializeEditVcStatus: function initializeEditVcStatus() {
            this.set('endpointCheck', 'editstatusvcbulkactionconfirmationviewmodels');
            this.set('endpointDo', 'editstatusvcbulkactionexecuteviewmodels');
            this.set('endpointRequestObjectName', 'EditVcStatus');
        },
        initializeCancelContract: function initializeCancelContract() {
            this.set('endpointCheck', 'cancelcontractbulkactionconfirmationviewmodels');
            this.set('endpointDo', 'cancelcontracts');
            this.set('endpointRequestObjectName', 'CancelContract');
        },
        initializeMarkAsForShipping: function initializeMarkAsForShipping() {
            this.set('endpointCheck', 'markcontracttobesentbulkactionconfirmationviewmodels');
            this.set('endpointDo', 'marktobesentcontracts');
            this.set('endpointRequestObjectName', 'MarkToBeSentContract');
        },
        initializeMarkAsSent: function initializeMarkAsSent() {
            this.set('endpointCheck', 'sendcontractstopikabulkactionconfirmationviewmodels');
            this.set('endpointDo', 'sendtopikacontracts');
            this.set('endpointRequestObjectName', 'SendToPikaContract');
        },
        initializeCommentSent: function initializeCommentSent() {
            this.set('endpointCheck', 'commentbulkactionconfirmationviewmodels');
            this.set('endpointDo', 'commentbulkactionexecuteviewmodels');
            this.set('endpointRequestObjectName', 'AddCommentContract');
        },

        checkData: function checkData() {
            this.clearErrors();
            var filters = this.get('selectedFilters'),
                uuids = this.get('uuids');
            this.set('isLoading', true);

            if (filters && !_emberNewUtilsUtils['default'].isEmpty(filters)) {
                this.checkDataByFilters(filters);
            } else if (uuids && uuids.length > 0) {
                this.checkDataByUuids(uuids);
            } else {
                this.set('isLoading', false);
            }
        },

        applyChanges: function applyChanges() {
            var _this2 = this;

            var uuids = this.get('checkResultCanBeChangedUuids');
            var reqObjName = this.get('endpointRequestObjectName');
            var url = this.get('doUrl');

            var requestObject = {};
            requestObject[reqObjName] = { uuids: uuids };

            this.set('isLoadingApplyChanges', true);

            return this.get('ajax').ajaxPost(url, requestObject).then(function (res) {
                return _this2.handleSetResponse(res);
            });
        },
        applyChangesEditVc: function applyChangesEditVc() {
            var _this3 = this;

            var uuids = this.get('checkResultCanBeChangedUuids');
            var reqObjName = this.get('endpointRequestObjectName');
            var url = this.get('doUrl');

            var requestObject = { uuids: uuids };

            // TODO: refactoring na oddzilne metody
            if (this.get('type') === 'edit-vc-status') {

                var vcStatusUuid = this.get('vcStatusUuid');

                if (!vcStatusUuid) {
                    throw Error('\'vcStatusUuid\' can not be empty!');
                } else if (uuids.length === 0) {
                    throw Error('No contracts to be changed.');
                }

                requestObject.vcStatusUuid = vcStatusUuid;
            }

            if (this.get('type') === 'comment') {
                requestObject.commentVisibility = 'ph_and_bo';
                requestObject.commentText = this.get('commentText');
            }

            this.set('isLoadingApplyChanges', true);
            var _this = this;
            return this.get('ajax').ajaxPost(url, requestObject).then(function (res) {
                return _this3.handleSetResponse(res);
            }, function (resp) {
                _this.get('applicationAlerts').addActionMessage('danger', "Wystąpiły błędy podczas zmiany statusów. Żadna umowa nie została zaktualizowana.");
                _this.set('isLoadingApplyChanges', false);
                _this.set('exeResult', resp);
            });
        },

        /**
         *
         * @param filters {filter1: value, filter2: value}
         */
        checkDataByFilters: function checkDataByFilters(filters) {
            var _this4 = this;

            var filtersToCheck = filters ? filters : this.get('selectedFilters');
            var url = this.get('checkUrl');
            this.get('ajax').ajaxPost(url, { filters: filtersToCheck }).then(function (res) {
                return _this4.handleCheckResponse(res);
            })['catch'](function (e) {
                return _this4.handleError(e);
            });
        },

        /**
         *
         * @param uuids array of uuids
         */
        checkDataByUuids: function checkDataByUuids(uuids) {
            var _this5 = this;

            var url = this.get('checkUrl');
            this.get('ajax').ajaxPost(url, { uuids: uuids }).then(function (res) {
                return _this5.handleCheckResponse(res);
            })['catch'](function (e) {
                return _this5.handleError(e);
            });
        },

        handleCheckResponse: function handleCheckResponse(res) {
            // res: {EditStatusVcBulkActionConfirmationViewModel: [{},...,{}]}
            var modelName = Object.keys(res)[0]; // EditStatusVcBulkActionConfirmationViewModel
            this.set('checkedObjectName', modelName);
            this.set('checkResult', res[modelName]);
            this.set('isLoading', false);
        },
        handleSetResponse: function handleSetResponse(res) {
            this.set('isLoadingApplyChanges', false);
            // if(res.status === 'ok') {
            //     this.get('applicationAlerts');
            this.sendAction('afterAppliedChanges', res);
            // }
        },
        handleError: function handleError(err) {
            this.set('isLoading', false);
            var error = {};
            if (err.status) {
                // obsluga bledow typu xhr
                error.code = err.status;
                error.message = err.statusText;
            } else {
                // obsluga bledow z api
                error = err;
            }
            this.get('errors').pushObject(err);
        },
        clearErrors: function clearErrors() {
            this.set('errors', []);
        },

        actions: {
            checkData: function checkData() {
                this.checkData();
            },
            applyChanges: function applyChanges() {
                if (this.get('type') === 'edit-vc-status' || this.get('type') === 'comment') {
                    this.applyChangesEditVc();
                } else {
                    this.applyChanges();
                }
            },
            afterAppliedChanges: function afterAppliedChanges(res) {
                this.get('afterAppliedChanges')(res);
            }
        }
    });
});
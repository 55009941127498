define('ember-new/models/contract-contract-dzu-view-model', ['exports', 'ember-new/models/abstract', 'ember-data'], function (exports, _emberNewModelsAbstract, _emberData) {

	var attr = _emberData['default'].attr;

	exports['default'] = _emberNewModelsAbstract['default'].extend({

		contractNo: attr('string'),
		contractorNo: attr('string'),
		type: attr('string'),
		dateContractReported: attr('string'),
		dateContractSigned: attr('string'),
		dateStart: attr('string'),
		dateEnd: attr('string'),
		dateOwu: attr('string'),
		acceptDate: attr('string'),
		ppgMin: attr('string'),
		ppgMax: attr('string'),

		monthsNo: attr('string'),
		paymentTerm: attr('string'),
		paymentTermUuid: attr('string'),
		// product: attr(),
		// tariff: attr(),
		eInvoice: attr('boolean'),
		eInvoiceEmail: attr('string'),
		agreementProcessing: attr('boolean'),
		agreementSharing: attr('boolean'),
		agreementSending: attr('boolean'),
		agreementLogo: attr('boolean'),
		agreementsSetManually: attr('boolean'),
		idPartner: attr('string'),
		region: attr('string'),
		clientSource: attr('string'),

		contractType: attr('string'),
		contractTypeUuid: attr('string'),

		consumerType: attr('string'),
		consumerTypeUuid: attr('string'),

		salesmanUuid: attr('string'),
		salesman: attr('string'),

		ccConsultant: attr('string'),
		ccConsultantUuid: attr('string'),

		product: attr('string'),
		productUuid: attr('string'),

		eeTariff: attr('string'),
		eeTariffUuid: attr('string'),
		gasTariff: attr('string'),
		gasTariffUuid: attr('string'),

		completeSpecialist: attr('string'),
		completeSpecialistUuid: attr('string'),
		completeSpecialistDepartment: attr('string'),
		completeSpecialistDepartmentUuid: attr('string'),
		implementSpecialist: attr('string'),
		implementSpecialistUuid: attr('string'),
		implementSpecialistDepartment: attr('string'),
		implementSpecialistDepartmentUuid: attr('string'),

		contractStatusUuid: attr('string'),
		contractStatusName: attr('string'),
		statusTime: attr('string'),
		statusNumber: attr('string'),
		previousContractStatusUuid: attr('string'),
		previousContractStatusName: attr('string'),
		previousStatusNumber: attr('string'),

		fixedTermOrOpenEnded: attr('string'),
		automaticExtension: attr('boolean'),

		contractUuid: attr('string'),

		newDevice: attr('boolean'),
		ppeCount: attr('number'),

		usysTemplate: attr('string'),
		usysTemplateUuid: attr('string'),

		dateChooseType: attr('string'),
		dateChooseTypeUuid: attr('string'),
		contractAndPpgEndDate: attr('string'),
		isDateFromEndOfContract: attr('boolean'),
		numberOfMonthsBySalesman: attr('string'),
		loyaltyStatusBySalesman: attr('string'),
		loyaltyDateBySalesman: attr('string'),

		clientSatisfaction: attr('number'),

		//    faktury wstępne
		invoiceDays: attr('number'),
		preinvoicesAmount: attr('string'),
		preinvoicesPercent: attr('number'),
		preinvoice1Days: attr('number'),
		preinvoice1Details: attr('string'),
		preinvoice1DetailsUuid: attr('string'),
		preinvoice2Days: attr('number'),
		preinvoice2Details: attr('string'),
		preinvoice2DetailsUuid: attr('string'),
		preinvoice3Days: attr('number'),
		preinvoice3Details: attr('string'),
		preinvoice3DetailsUuid: attr('string'),

		isCoaching: attr('boolean'),
		coachingName: attr('string'),

		isManualMm: attr('boolean'),
		manualMm: attr('string'),
		isAntiSmogPackage: attr('boolean'),
		isAutenti: attr('boolean'),

		//burdens
		burdenCompletingConsumptionDeclarationsExist: attr('boolean'),
		burdenCompletingConsumptionDeclarationsMultiplier: attr('number'),
		burdenCompletingConsumptionDeclarationsSingleAmount: attr('number'),
		burdenCompletingConsumptionDeclarationsCharged: attr('number'),
		burdenFillingAttachmentsExist: attr('boolean'),
		burdenFillingAttachmentsMultiplier: attr('number'),
		burdenFillingAttachmentsSingleAmount: attr('number'),
		burdenFillingAttachmentsCharged: attr('number'),
		burdenFillingContractDocumentsExist: attr('boolean'),
		burdenFillingContractDocumentsMultiplier: attr('number'),
		burdenFillingContractDocumentsSingleAmount: attr('number'),
		burdenFillingContractDocumentsCharged: attr('number'),
		burdenObtainingDistributionExist: attr('boolean'),
		burdenObtainingDistributionMultiplier: attr('number'),
		burdenObtainingDistributionSingleAmount: attr('number'),
		burdenObtainingDistributionCharged: attr('number'),
		burdenConfirmationLoyalistsExist: attr('boolean'),
		burdenConfirmationLoyalistsMultiplier: attr('number'),
		burdenConfirmationLoyalistsSingleAmount: attr('number'),
		burdenConfirmationLoyalistsCharged: attr('number'),
		burdenFailProvideApprovalsExist: attr('boolean'),
		burdenFailProvideApprovalsMultiplier: attr('number'),
		burdenFailProvideApprovalsSingleAmount: attr('number'),
		burdenFailProvideApprovalsCharged: attr('number'),

		// verification summary
		contractInconsistentWithRepresentationTrue: attr('boolean'),
		contractInconsistentWithRepresentationAgreement: attr('boolean'),
		contractInconsistentWithRepresentationConsultation: attr('boolean'),
		noSubstitutionTrue: attr('boolean'),
		noSubstitutionAgreement: attr('boolean'),
		noSubstitutionConsultation: attr('boolean'),
		noInvoicesTrue: attr('boolean'),
		noInvoicesAgreement: attr('boolean'),
		noInvoicesConsultation: attr('boolean'),
		noFvInvoiceTrue: attr('boolean'),
		noFvInvoiceAgreement: attr('boolean'),
		noFvInvoiceConsultation: attr('boolean'),
		noFvOsdInvoiceTrue: attr('boolean'),
		noFvOsdInvoiceAgreement: attr('boolean'),
		noFvOsdInvoiceConsultation: attr('boolean'),
		noContractTrue: attr('boolean'),
		noContractAgreement: attr('boolean'),
		noContractConsultation: attr('boolean'),
		lackingAttachmentTrue: attr('boolean'),
		lackingAttachmentAgreement: attr('boolean'),
		lackingAttachmentConsultation: attr('boolean'),
		installationNotReadyTrue: attr('boolean'),
		installationNotReadyAgreement: attr('boolean'),
		installationNotReadyConsultation: attr('boolean'),
		noNewLinkDocumentsTrue: attr('boolean'),
		noNewLinkDocumentsAgreement: attr('boolean'),
		noNewLinkDocumentsConsultation: attr('boolean'),
		noCessionDocumentsTrue: attr('boolean'),
		noCessionDocumentsAgreement: attr('boolean'),
		noCessionDocumentsConsultation: attr('boolean'),
		pricesBelowBasicTrue: attr('boolean'),
		pricesBelowBasicAgreement: attr('boolean'),
		pricesBelowBasicConsultation: attr('boolean'),
		notAcceptedChangesTrue: attr('boolean'),
		notAcceptedChangesAgreement: attr('boolean'),
		notAcceptedChangesConsultation: attr('boolean'),
		noCreditworthinessAssessmentTrue: attr('boolean'),
		noCreditworthinessAssessmentAgreement: attr('boolean'),
		noCreditworthinessAssessmentConsultation: attr('boolean'),
		contractNotCompleteTrue: attr('boolean'),
		contractNotCompleteAgreement: attr('boolean'),
		contractNotCompleteConsultation: attr('boolean'),
		contractInconsistentWithProductTrue: attr('boolean'),
		contractInconsistentWithProductAgreement: attr('boolean'),
		contractInconsistentWithProductConsultation: attr('boolean'),
		illegibleSignatureTrue: attr('boolean'),
		illegibleSignatureAgreement: attr('boolean'),
		illegibleSignatureConsultation: attr('boolean'),
		noOWUTrue: attr('boolean'),
		noOWUAgreement: attr('boolean'),
		noOWUConsultation: attr('boolean'),
		pricesAboveBasicTrue: attr('boolean'),
		pricesAboveBasicAgreement: attr('boolean'),
		pricesAboveBasicConsultation: attr('boolean'),
		negativeCreditworthinessAssessmentTrue: attr('boolean'),
		negativeCreditworthinessAssessmentAgreement: attr('boolean'),
		negativeCreditworthinessAssessmentConsultation: attr('boolean'),
		otherTrue: attr('boolean'),
		otherReason: attr('string'),

		contractTypeLength: attr('string'),
		automaticExtensionFormChoice: attr('string'),
		automaticExtensionNoticePeriod: attr('string'),
		automaticExtensionNoticePeriodMonths: attr('string'),
		compensionCalculatingRate: attr('string'),
		compensionCalculatingRateMonths: attr('string'),
		compensionCalculatingRateConsumption: attr('string'),
		compensionCalculatingRateKind: attr('string'),
		compensionCalculatingRateValue: attr('string'),
		gazPrad: attr('boolean'),
		audit: attr('boolean'),
		vip: attr('boolean')
	});
});
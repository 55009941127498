define("ember-new/components/d-form/b2b-contract/dzu/add-ee-contract", ["exports", "ember-new/components/d-form-command", "ember-new/templates/components/d-form-b2b-contract-add-invoice"], function (exports, _emberNewComponentsDFormCommand, _emberNewTemplatesComponentsDFormB2bContractAddInvoice) {
    exports["default"] = _emberNewComponentsDFormCommand["default"].extend({
        layout: _emberNewTemplatesComponentsDFormB2bContractAddInvoice["default"],

        commandName: 'CreateEeContractFromAnother',

        afterSuccessfulSubmit: function afterSuccessfulSubmit() {
            var targetController = this.get('targetObject');
            var mainController = targetController.container.lookup('controller:b2b-contract.dzu');
            var mainRoute = mainController.get('target');
            mainRoute.send('refreshModel');
            setTimeout(function () {
                targetController.transitionToRoute('b2b-contract.dzu');
            }, 500);
        }
    });
});
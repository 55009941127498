define('ember-new/controllers/b2b-contract/dzu/show-ee/remove-recording', ['exports', 'ember', 'ember-new/config/environment'], function (exports, _ember, _emberNewConfigEnvironment) {
    exports['default'] = _ember['default'].Controller.extend({
        ajax: _ember['default'].inject.service('ajax'),
        applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),
        queryParams: ['recordingUuid'],

        actions: {
            'delete': function _delete() {
                var serializedData = {
                    removeRecordingCommand: {
                        recordingUuid: this.get('recording'),
                        contractUuid: this.get('idContract')
                    }
                };

                var _this = this;
                var promise = this.get('ajax').ajaxPost(_emberNewConfigEnvironment['default'].apiUrl + '/rest/removerecordingcommands', serializedData);

                promise.then(function (response) {
                    _this.get('applicationAlerts').addActionMessage('success', "Nagranie zostało usunięte");
                    setTimeout(function () {
                        _this.transitionToRoute('b2b-contract.dzu.show-ee.documents');
                    }, 500);
                }, function (response) {
                    _this.get('applicationAlerts').addActionMessage('warning', "Wystąpił błąd, nie udało się usunąć nagrania");
                });
            },

            afterSuccessfulCommand: function afterSuccessfulCommand() {
                this.get('ctrl.model').reload();
            }
        }
    });
});
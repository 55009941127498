define('ember-new/models/individual-contract-partner-list-item-view-model', ['exports', 'ember-data'], function (exports, _emberData) {

	var attr = _emberData['default'].attr;
	var refOne = _emberData['default'].belongsTo;

	exports['default'] = _emberData['default'].Model.extend({
		uuid: attr('string'),
		dateCreated: attr('string'),
		contractNo: attr('string'),
		type: attr('string'),
		clientUuid: attr('string'),
		clientName: attr('string'),
		clientSurname: attr('string'),
		clientPESEL: attr('string'),
		clientPhoneNumber: attr('string'),
		clientPhoneNumber2: attr('string'),
		mainContractStatus: attr('string'),
		dateOfMainContractStatusLastChange: attr('string'),
		statusSetByPartner: attr('string'),
		statusVC: attr('string'),
		acceptDate: attr('string'),
		dateContractReported: attr('string'),
		reportingUserFullName: attr('string'),
		reportingUserPartnerName: attr('string'),
		selected: attr('boolean'),
		dateSigned: attr('string'),
		tariffGroupUuid: attr('string'),
		foreigner: attr('boolean'),
		partnerName: attr('string'),
		statusBoUuid: attr('string'),
		userWhoSigned: attr('string'),
		userWhoSignedLastName: attr('string'),
		isEditable: attr('boolean'),
		loyaltyDate: attr('string'),
		loyaltyDateSetByBo: attr('boolean'),
		pikaReasonOfIncomplete: attr('string'),
		pikaReasonOfIncompleteDescription: attr('string'),
		phId: attr('string'),

		partnerFile1: refOne('Upload', { inverse: null }),
		partnerFile2: refOne('Upload', { inverse: null }),
		partnerFile3: refOne('Upload', { inverse: null }),
		partnerFile4: refOne('Upload', { inverse: null }),
		partnerFile5: refOne('Upload', { inverse: null }),
		vcRecording: refOne('Upload', { inverse: null }),

		partnerFile1Uuid: attr('string'),
		partnerFile2Uuid: attr('string'),
		partnerFile3Uuid: attr('string'),
		partnerFile4Uuid: attr('string'),
		partnerFile5Uuid: attr('string'),
		vcRecordingUuid: attr('string'),

		firstNameSecondClient: attr('string'),
		lastNameSecondClient: attr('string'),
		phoneSecondClient: attr('string'),
		phone2SecondClient: attr('string'),
		peselSecondClient: attr('string'),
		foreignerSecondClient: attr('boolean'),

		vcRecordingByBo: attr('string'),
		clientSatisfaction: attr('number'),
		isAnonymized: attr('boolean')
	});
});
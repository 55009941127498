define('ember-new/controllers/tasks/add-client', ['exports', 'ember-new/config/environment', 'ember-new/controllers/tasks/clients-add-businessclient', 'ember'], function (exports, _emberNewConfigEnvironment, _emberNewControllersTasksClientsAddBusinessclient, _ember) {

	// dodanie nowego klienta
	exports['default'] = _ember['default'].Controller.extend({
		queryParams: ['nip'],
		nip: null,
		cerrors: null,

		savingInProgress: null,
		contactPersons: [],

		afterSuccessfulCommand: function afterSuccessfulCommand(form, response) {
			if (response.status === 'ok') this.transitionToRoute('tasks.addReservation', { queryParams: { cid: response['command_output']['id'] } });
		},

		/**
   * do suuniecia po testach
   */
		/*
   generateTestNip: function () {
  	 const officeCodes = [
  		 "107", "108", "109", "111", "112", "113", "114", "115", "116", "117", "118", "119", "121", "122", "123", "124",
  		 "125", "154", "156", "157", "158", "337", "338", "339", "341", "342", "355", "356", "375", "376", "377", "378",
  		 "379", "381", "389", "392", "393", "394", "416", "417", "496", "497", "509", "511", "512", "519", "521", "522",
  		 "523", "524", "525", "526", "527", "528", "529", "531", "532", "533", "534", "535", "536", "566", "567", "568",
  		 "569", "572", "601", "701", "757", "758", "759", "761", "762", "774", "776", "796", "797", "798", "799", "811",
  		 "812", "821", "822", "823", "826", "837", "838", "931", "932", "948", "951", "952", "965", "971", "978"
  	 ];
  		 function getRandomInt(min, max) {
  		 return Math.floor(Math.random() * (max - min + 1)) + min;
  	 }
  		 function addLeadingZeros(number, length) {
  		 number = "" + number;
  		 var zerosNumber = length - number.length;
  			 for (let i = 0; i < zerosNumber; i++) {
  			 number = "0" + number;
  		 }
  		 return number;
  	 }
  
  	 function generateNip() {
  		 do {
  			 var officeCode = getRandomOfficeCode();
  			 var randomPart = getRandomPart();
  			 var base = "" + officeCode + randomPart;
  			 var controlSum = getNipControlSumField(base);
  		 }
  		 while (controlSum > 9);
  		 return base + controlSum;
  	 }
  		 function getRandomOfficeCode() {
  		 let index = getRandomInt(0, officeCodes.length-1);
  		 return officeCodes[index];
  	 }
  		 function getRandomPart() {
  		 let randomInt = getRandomInt(0, 999999);
  		 return addLeadingZeros(randomInt, 6);
  	 }
  		 function getNipControlSumField(base) {
  		 let controlSum = 6 * base[0] + 5 * base[1] + 7 * base[2] + 2 * base[3] + 3 * base[4]
  			 + 4 * base[5] + 5 * base[6] + 6* base[7] + 7 * base[8];
  		 let controlSumLastDigit = controlSum % 11;
  		 return controlSumLastDigit;
  	 }
  		 return generateNip();
  	 },
   */

		fillForm: function fillForm(form) {
			// DLA TESTOW:
			// form.set('model.nip', this.generateTestNip());
			// form.set('model.companyName', 'XYZ');
			// form.set('model.street', 'Koluszka');
			// form.set('model.streetNo', '32');
			// form.set('model.aptNo', '12');
			// form.set('model.postalCode', '22-321');
			// form.set('model.postalCity', 'Lodz');
			// form.set('model.city', 'Lodz');
			// form.set('model.district', null);
			// form.set('model.contactFirstName', 'cvbcvb');
			// form.set('model.contactLastName', 'asdasd');
			// form.set('model.contactPosition', 'it director');
			// form.set('model.contactEmail', 'asdas@wp.pl');
			// form.set('model.contactPhone', '222333444');
			// form.set('model.contactPhoneLandline', null);

			form.set('model.nip', this.get('nip'));
			form.set('model.companyName', null);
			form.set('model.street', null);
			form.set('model.streetNo', null);
			form.set('model.aptNo', null);
			form.set('model.postalCode', null);
			form.set('model.postalCity', null);
			form.set('model.city', null);
			form.set('model.district', null);
			form.set('model.contactFirstName', null);
			form.set('model.contactLastName', null);
			form.set('model.contactPosition', null);
			form.set('model.contactEmail', null);
			form.set('model.contactPhone', null);
			form.set('model.contactPhoneLandline', null);
		},

		actions: {
			setup: function setup() {
				this.send('reset');
				this.set('model', this.store.createRecord('ambBusinessClient'));
				var address = this.store.createRecord('address');
				this.set('model.address', address);
				var contactPerson = _ember['default'].Object.create();
				this.set('eeContactPerson', contactPerson);
				this.get('model').set('nip', this.get('nip'));
			},

			reset: function reset() {
				this.set('model', null);
				this.set('cerrors', null);
			}
		}
	});
});
define('ember-new/routes/individual-contract', ['exports', 'ember-new/routes/authenticated', 'ember'], function (exports, _emberNewRoutesAuthenticated, _ember) {

    var IndividualContractRoute = _emberNewRoutesAuthenticated['default'].extend({

        model: function model(params) {

            this.set('params', params);

            this.store.unloadAll('IndividualContractSimplified');

            return _ember['default'].RSVP.hash({
                contract: this.get('store').query('individualContractSimplified', params, { reload: true }),
                products: this.get('store').query('IndividualContractProductListViewModel', params, { reload: true })
            });
        },

        setupController: function setupController(ctrl, model) {
            ctrl.set('products', model.products);
            ctrl.set('model', model.contract);
        },

        actions: {
            queryParamsDidChange: function queryParamsDidChange() {
                this.refresh();

                if (this.get('controller')) {
                    this.get('controller').reset();
                }
            },
            refreshRoute: function refreshRoute() {
                this.refresh();
            },
            refreshAsync: function refreshAsync() {
                this.store.unloadAll('individualContractSimplified');
                return this.store.query('individualContractSimplified', this.get('params'));
            }
        }
    });

    exports['default'] = IndividualContractRoute;
});
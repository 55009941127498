define('ember-new/routes/b2b-contract/dzu/copy-contract', ['exports', 'ember-new/routes/authenticated'], function (exports, _emberNewRoutesAuthenticated) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({
        model: function model(params) {
            var dzu = this.modelFor('b2b-contract.dzu');
            this.store.unloadAll('ContractContractDzuViewModel');
            return this.store.findRecord('ContractContractDzuViewModel', params.contractId);
        },
        setupController: function setupController(controller, model) {
            controller.set("model", model);
        }
    });
});
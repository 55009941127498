define("ember-new/components/d-form/individual-contract-price-lists/vap/edit", ["exports", "ember-new/components/d-form-command", "ember-new/templates/components/d-form"], function (exports, _emberNewComponentsDFormCommand, _emberNewTemplatesComponentsDForm) {
    exports["default"] = _emberNewComponentsDFormCommand["default"].extend({
        layout: _emberNewTemplatesComponentsDForm["default"],

        commandName: 'EditVapCommand',
        monthsValue: null,

        afterSuccessfulSubmit: function afterSuccessfulSubmit() {
            var targetController = this.get('targetObject');
            var mainController = targetController.container.lookup('controller:individual-contract-price-lists.vap');
            var mainRoute = mainController.get('target');
            mainRoute.send('refreshModel');

            setTimeout(function () {
                targetController.transitionToRoute('individual-contract-price-lists.vap');
            }, 500);
        },

        validityPeriodObserver: (function () {

            if (this.get('formRows.numberOfMonths')) {

                if (this.get('formData.validityPeriod') === 'entireContractPeriod' || this.get('formData.validityPeriod') === null) {
                    this.set('formRows.numberOfMonths.hidden', true);
                    this.set('formData.numberOfMonths', null);
                } else {
                    this.set('formRows.numberOfMonths.hidden', false);
                }
            }
        }).observes('formData.validityPeriod')
    });
});
define('ember-new/models/extendable', ['exports', 'ember-new/models/abstract', 'ember'], function (exports, _emberNewModelsAbstract, _ember) {

	/**
  * Extends parent object with chilren model class mixin
  * Requires property _childrenMixinsSuffix
  */
	var get = _ember['default'].get;
	exports['default'] = _emberNewModelsAbstract['default'].extend({
		setupData: function setupData(data, partial) {
			var baseRecordClass = _ember['default'].String.capitalize(this.constructor.typeKey); // wyciagamy nazwe klasy modelu do ktorego setupujemy dane
			var type = data.type;
			if (type) {
				// jesli wsrod otrzymanych danych modelu mamy atrybut 'type' to znaczy że mamy doczynienia z dziedziczonym modelem
				var subClassName = type.classify() + _ember['default'].String.capitalize(this._childrenSuffix.toLowerCase());

				// ustalamy czy dane zostały już wrzucone do odpowiedniej subClassy modelu
				// jeśli zostały wrzucone do klasy rodzica to musimy zastosować na rodzicu mixin, aby uwzględnić pola z modelu-dziecka
				if (subClassName != baseRecordClass) {
					var mixinName = subClassName + _ember['default'].String.capitalize(this._childrenMixinsSuffix.toLowerCase());
					var mixin = get(mixinName);

					// komunikujemy że nie istnieje klasa(mixin) dziecka
					if (mixin === undefined) {
						console.debug("Nie istnieje Mixin " + mixinName + " tworzący z klasy rodzica " + baseRecordClass + " obiekt klasy dziecka " + subClassName);
					} else {
						this.reopen(mixin);
					}
				}
			}
			this._super(data, partial); // dalej wywolujemy domyslne zachowanie metody
		},
		eachAttribute: function eachAttribute() {
			if (this.get('type')) {}
			this._super.apply(this, arguments);
		}
	});
});
define('ember-new/controllers/individual-contract/set-accept-date', ['exports', 'ember', 'ember-new/utils/validators', 'ember-new/config/environment', 'ember-new/mixins/individual-contract/multiple-contract-changes'], function (exports, _ember, _emberNewUtilsValidators, _emberNewConfigEnvironment, _emberNewMixinsIndividualContractMultipleContractChanges) {
    var computed = _ember['default'].computed;
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsIndividualContractMultipleContractChanges['default'], {

        acceptDate: moment(),

        setDatesUrl: computed(function () {
            return _emberNewConfigEnvironment['default'].apiUrl + '/rest/setacceptdates';
        }),
        checkDatesUrl: computed(function () {
            return _emberNewConfigEnvironment['default'].apiUrl + '/rest/setacceptdatebulkactionconfirmationviewmodels';
        }),

        // do przycisku akcji zmiany dat
        canCheckDates: computed('validDataCount', function () {
            return this.get('validDataCount') > 0 && this.get('acceptDate');
        }),

        /**
         * CHECK:
         * @returns {*}
         */
        checkDates: function checkDates() {
            var _this = this;

            var url = this.get('checkDatesUrl');
            var contractNos = this.get('validData').mapBy('number'); // poprawne numery umow
            var acceptDate = moment(this.get('acceptDate')).format('YYYY-MM-DD');

            var requestObject = { contractNos: contractNos, acceptDate: acceptDate };

            this.clearErrors();
            this.set('isLoadingCheck', true);
            this.set('checkResult', null);

            return this.get('ajax').ajaxPost(url, requestObject).then(function (res) {
                return _this.handleCheckResponse(res);
            })['catch'](function (err) {
                return _this.handleError(err);
            });
        },

        // handleCheckResponse(res){
        //     const modelName = Object.keys(res)[0];
        //     this.set('checkedObjectName', modelName);
        //     this.set('checkResult', res[modelName]);
        //     this.set('isLoadingCheck', false);
        //
        // },
        // handleError(err){
        //     this.set('isLoadingCheck', false);
        //     this.set('isLoadingSet', false);
        //     let error = {};
        //     if (err.status) { // obsluga bledow typu xhr
        //         error.code = err.status;
        //         error.message = err.statusText;
        //     } else {
        //         // obsluga bledow z api
        //         error = err;
        //     }
        //     this.get('errors').pushObject(err);
        // },
        // clearErrors(){
        //     this.set('errors', []);
        // },

        /**
         * SET:
         */
        setDates: function setDates() {
            var _this2 = this;

            var uuids = this.get('contractsToSetUuids');
            var reqObjName = 'SetAcceptDate';
            var url = this.get('setDatesUrl');
            var acceptDate = this.get('acceptDate');

            var requestObject = {};
            requestObject[reqObjName] = { uuids: uuids, acceptDate: moment(acceptDate).format('YYYY-MM-DD') };

            this.clearErrors();
            this.set('isLoadingSet', true);
            return this.get('ajax').ajaxPost(url, requestObject).then(function (res) {
                return _this2.handleSetResponse(res);
            })['catch'](function (err) {
                return _this2.handleError(err);
            });
        },

        actions: {

            checkDates: function checkDates() {
                this.checkDates();
            },
            setDates: function setDates() {
                this.setDates();
            }
        }
    });
});
define('ember-new/services/bulk-actions-state', ['exports', 'ember'], function (exports, _ember) {
    var get = _ember['default'].get;
    var set = _ember['default'].set;
    var Service = _ember['default'].Service;
    exports['default'] = Service.extend({
        filters: null,
        uuids: null,

        setFilters: function setFilters(val) {
            set(this, 'filters', val);
        },
        getFilters: function getFilters() {
            return get(this, 'filters');
        },

        setUuids: function setUuids(val) {
            set(this, 'uuids', val);
        }

    });
});
define('ember-new/controllers/individual-contract/batch/usys', ['exports', 'ember', 'ember-new/mixins/controllers/helpers/form-processing-controller', 'ember-new/config/environment', 'ember-new/utils/utils'], function (exports, _ember, _emberNewMixinsControllersHelpersFormProcessingController, _emberNewConfigEnvironment, _emberNewUtilsUtils) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsControllersHelpersFormProcessingController['default'], {
        ajax: _ember['default'].inject.service('ajax'),
        cookies: _ember['default'].inject.service(),

        reset: (function () {
            this.set('cerrors', []);
            this.set('warn', []);
        }).observes('model'),

        clear: function clear() {
            this.set('cerrors', null);
        },

        actions: {
            generate: function generate() {

                this.set('cerrors', []);
                this.set('warn', []);

                var _this = this;

                if (!this.beforeSubmit()) {
                    return;
                }

                var data = {
                    ids: []
                };

                this.get('model.contract').forEach(function (item) {
                    if (item.get('checked') === true) {
                        data.ids.push(item.id);
                    }
                });

                var ajaxPromise = this.get('ajax').ajax(_emberNewConfigEnvironment['default'].apiUrl + '/api/individual-contract/batch/generate_usys', data, 'PATCH');

                ajaxPromise.then(function (response) {
                    _this.handleSuccess(response);
                    if (response.warn) {
                        _this.set('warn', response.warn);
                    }
                    window.location = _emberNewUtilsUtils['default'].generateSimpleUrl('/api/individual-contract/batch/download_usys/' + response[0], '?', _emberNewConfigEnvironment['default'], _this.get('TheUser'), _this.get('cookies'));
                }, function (response) {
                    _this.handleNotValid(response);
                    _this.set('savingInProgress', false);
                    _this.set('cerrors', response);

                    handleAjaxError(response, function (response) {
                        _this.set('cerrors', response);
                    });
                });
            }
        }

    });
});
define("ember-new/routes/individual-contract-partners/add-files", ["exports", "ember", "ember-new/routes/authenticated", "ember-new/config/environment"], function (exports, _ember, _emberNewRoutesAuthenticated, _emberNewConfigEnvironment) {
    exports["default"] = _emberNewRoutesAuthenticated["default"].extend({

        beforeModel: function beforeModel() {
            this.store.unloadAll('upload');
            this.store.unloadAll('IndividualContractPartnerFilesViewModel');
        },

        model: function model(_ref) {
            var contractId = _ref.contractId;

            var route = this;

            return _ember["default"].RSVP.hash({
                model: route.get('store').findRecord('IndividualContractPartnerFilesViewModel', contractId),
                contract: route.get('store').findRecord('IndividualContractPartnerListItemViewModel', contractId)
            });
        },

        setupController: function setupController(ctrl, model) {
            ctrl.set('model', model.model);
            ctrl.set('contract', model.contract);
        },

        actions: {
            reload: function reload() {
                this.refresh();
            }
        }

    });
});
define('ember-new/controllers/b2b-contract/administration/section-pricelists/copy', ['exports', 'ember', 'ember-new/templates/components/d-form'], function (exports, _ember, _emberNewTemplatesComponentsDForm) {
    exports['default'] = _ember['default'].Controller.extend({

        layout: _emberNewTemplatesComponentsDForm['default'],

        productName: '',

        fillForm: function fillForm(form) {
            form.set('model.priceListName', this.get('model.name'));
            form.set('model.dateStart', this.get('model.dateFrom'));
            form.set('model.uuid', this.get('model.id'));
            form.set('model.product', this.get('model.productUuid'));

            var productName = '';
            this.get('model.products').forEach(function (row) {
                productName = row.name;
            });
            this.set('productName', productName);
        }
    });
});
define("ember-new/components/d-form/b2b-contract/dzu/administration/base-price-lists/import-ee", ["exports", "ember", "ember-new/components/d-form-command", "ember-new/templates/components/d-form", "ember-new/config/environment", "ember-new/mixins/d-form/d-form-upload"], function (exports, _ember, _emberNewComponentsDFormCommand, _emberNewTemplatesComponentsDForm, _emberNewConfigEnvironment, _emberNewMixinsDFormDFormUpload) {
    var observer = _ember["default"].observer;
    var _Ember$inject = _ember["default"].inject;
    var service = _Ember$inject.service;
    var controller = _Ember$inject.controller;
    exports["default"] = _emberNewComponentsDFormCommand["default"].extend(_emberNewMixinsDFormDFormUpload["default"], {
        layout: _emberNewTemplatesComponentsDForm["default"],

        // commandName: 'import-ee-base-price-list',

        submitRestAsSimple: true,
        submitModelNamespace: 'ImportEeBasePriceListsCommand',
        submitRestAsSimpleMethod: 'POST',
        textAddingError: 'Wczytany plik zawiera błędy!',
        submitButtonText: 'Wczytaj',
        _textAddingSuccess: "Wczytano pomyślnie!",
        _textAddingException: "Wystąpił błąd. Nie udało się wczytać pliku.",
        _textUpdatingSuccess: "Wczytano pomyślnie!",
        content: null,

        submitSimpleUrl: (function () {
            return _emberNewConfigEnvironment["default"].apiUrl + '/rest/importeebasepricelistscommands';
        }).property(),

        clearFeedback: function clearFeedback() {
            this._super();
            this.get('targetObject').set('info', []);
        },

        resolveContents: function resolveContents(form) {
            form.get('store').unloadAll('upload');
            return new _ember["default"].RSVP.Promise(function (resolve, reject) {
                var store = form.get('store');
                var upload = store.peekAll('upload');

                _ember["default"].RSVP.Promise.all([upload]).then(function (result) {
                    var upload = result[0];

                    resolve(_ember["default"].Object.create({
                        excelFile: upload
                    }));
                });
            });
        },

        configure: function configure() {
            this.registerTransformer('excelFile', function (uuid) {

                return uuid;
            }, function (file) {

                return file;
            });
        },

        afterSubmit: function afterSubmit(response) {
            var store = this.get('store');
            store.unloadAll('base-price-list.ee-price-list-row-view-model');
            var data = response.command_output[this.get('submitModelNamespace')];

            if (!this.handleErrors(data)) {
                this.handleSuccess(data);
            }

            this.sendAction('refreshRoute');
        },

        handleErrors: function handleErrors(data) {
            if (data.error !== undefined) {
                this.get('applicationAlerts').addActionMessage('danger', data.error);

                return true;
            } else if (data.code && data.code.match(/\d{3}/)) {

                this.get('applicationAlerts').addActionMessage('danger', data.message);

                return true;
            }

            return false;
        },

        handleSuccess: function handleSuccess(data) {
            var model = this.get('content.rows');
            var store = this.get('store');

            var controller = this.get('targetObject');
            controller.set('selectedConditionProducts', this.get('formData.conditionalProducts'));
            controller.set('dateFrom', this.get('formData.dateStart'));

            data.forEach(function (row) {
                model.pushObject(store.createRecord('base-price-list.ee-price-list-row-view-model', {
                    rowNumber: row.rowNumber,
                    dateFrom: row.dateFrom,
                    dateTo: row.dateTo,
                    tariffGroup: row.tariffGroup,
                    basePriceArea1: row.basePriceArea1,
                    basePriceArea2: row.basePriceArea2,
                    basePriceArea3: row.basePriceArea3,
                    basePriceArea4: row.basePriceArea4,
                    flatPrice: row.flatPrice,
                    baseFee: row.baseFee,
                    errors: row.errors
                }));
            });
        },

        actions: {
            submit: function submit() {
                this._handleSubmit();
            }
        }
    });
});
define('ember-new/controllers/individual-contract-price-lists/vap/add', ['exports', 'ember', 'ember-new/mixins/individual-contract-price-lists/controllers/vap'], function (exports, _ember, _emberNewMixinsIndividualContractPriceListsControllersVap) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsIndividualContractPriceListsControllersVap['default'], {

        fillForm: function fillForm(form) {
            form.set('model.name', null);
            form.set('model.type', null);
            form.set('model.price', null);
            form.set('model.idUsys', null);
            form.set('model.isRequired', null);
            form.set('model.validityPeriod', null);
            form.set('model.numberOfMonths', null);
            form.set('model.pikaCode', null);
        },

        afterCommand: function afterCommand(response) {
            var ctrl = this;
            var route = this.container.lookup('route:individual-contract-price-lists.vap');
            route.refresh();
            setTimeout(function () {
                ctrl.transitionToRoute('individual-contract-price-lists.vap');
            }, 500);
        }

    });
});
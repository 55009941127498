define('ember-new/routes/individual-contract-price-lists/business-tariff', ['exports', 'ember', 'ember-new/routes/authenticated'], function (exports, _ember, _emberNewRoutesAuthenticated) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({

        beforeModel: function beforeModel(transition) {
            window.scrollTo(0, 0); // scroll to the top of application before reloading
        },

        model: function model(params) {
            this.store.unloadAll('IndividualContractBusinessTariffListViewModel');
            return this.store.query('IndividualContractBusinessTariffListViewModel', params);
        },

        setupController: function setupController(ctrl, model) {
            ctrl.set('model', model);
        },

        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            }

        }
    });
});
define('ember-new/components/tab-link', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'li',
        route: null,
        routeIdentifier: false,
        childRouteActive: false,
        classNameBindings: ['isActive:active'],

        routing: _ember['default'].inject.service('-routing'),

        active: false,
        isActive: (function () {
            var route = this.get('route').toString().toLowerCase();
            var currRouteName = this.get('routing').get('currentRouteName').toString().toLowerCase();

            var currRouteHasRoute = currRouteName === route || currRouteName === route + '.index';

            if (this.get('childRouteActive')) {
                return this.get('active') || currRouteHasRoute === true || currRouteName.indexOf(route) !== -1;
            }

            return this.get('active') || currRouteHasRoute === true;
        }).property('routing.currentRouteName')

    });
});
define("ember-new/templates/b2b-contract/administration/section-general", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 33,
                    "column": 47
                  },
                  "end": {
                    "line": 33,
                    "column": 121
                  }
                },
                "moduleName": "ember-new/templates/b2b-contract/administration/section-general.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["inline", "d-field-datepicker-new", [], ["key", "defaultDateOWUEE"], ["loc", [null, [33, 71], [33, 121]]]]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 34,
                    "column": 47
                  },
                  "end": {
                    "line": 34,
                    "column": 122
                  }
                },
                "moduleName": "ember-new/templates/b2b-contract/administration/section-general.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["inline", "d-field-datepicker-new", [], ["key", "defaultDateOWUGas"], ["loc", [null, [34, 71], [34, 122]]]]],
              locals: [],
              templates: []
            };
          })();
          var child2 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 38,
                    "column": 48
                  },
                  "end": {
                    "line": 38,
                    "column": 120
                  }
                },
                "moduleName": "ember-new/templates/b2b-contract/administration/section-general.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["inline", "d-field-text", [], ["key", "defaultCalculatingRateEE"], ["loc", [null, [38, 72], [38, 120]]]]],
              locals: [],
              templates: []
            };
          })();
          var child3 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 39,
                    "column": 47
                  },
                  "end": {
                    "line": 39,
                    "column": 120
                  }
                },
                "moduleName": "ember-new/templates/b2b-contract/administration/section-general.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["inline", "d-field-text", [], ["key", "defaultCalculatingRateGas"], ["loc", [null, [39, 71], [39, 120]]]]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 8,
                  "column": 24
                },
                "end": {
                  "line": 43,
                  "column": 24
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/administration/section-general.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("table");
              dom.setAttribute(el1, "class", "table table-bordered table-striped dataTable prices-table");
              dom.setAttribute(el1, "role", "grid");
              var el2 = dom.createTextNode("\n                                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("thead");
              var el3 = dom.createTextNode("\n                                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("tr");
              dom.setAttribute(el3, "role", "row");
              var el4 = dom.createTextNode("\n                                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("th");
              var el5 = dom.createTextNode("Pole");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("th");
              dom.setAttribute(el4, "style", "text-align: center !important");
              var el5 = dom.createTextNode("Wartość EE");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("th");
              dom.setAttribute(el4, "style", "text-align: center !important");
              var el5 = dom.createTextNode("Wartość Gaz");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                    ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("tbody");
              var el3 = dom.createTextNode("\n                                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("tr");
              var el4 = dom.createTextNode("\n                                           ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("td");
              var el5 = dom.createTextNode("Domyślna Data OWU");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                           ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("td");
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                           ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("td");
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                        ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("tr");
              var el4 = dom.createTextNode("\n                                            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("td");
              var el5 = dom.createTextNode("Domyśna stawka do wyliczenia zryczałtowanego odszkodowania [zł/MWh]");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("td");
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                           ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("td");
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                        ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [25, 3]);
              var element4 = dom.childAt(element3, [1]);
              var element5 = dom.childAt(element3, [3]);
              var morphs = new Array(16);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
              morphs[3] = dom.createMorphAt(fragment, 7, 7, contextualElement);
              morphs[4] = dom.createMorphAt(fragment, 9, 9, contextualElement);
              morphs[5] = dom.createMorphAt(fragment, 11, 11, contextualElement);
              morphs[6] = dom.createMorphAt(fragment, 13, 13, contextualElement);
              morphs[7] = dom.createMorphAt(fragment, 15, 15, contextualElement);
              morphs[8] = dom.createMorphAt(fragment, 17, 17, contextualElement);
              morphs[9] = dom.createMorphAt(fragment, 19, 19, contextualElement);
              morphs[10] = dom.createMorphAt(fragment, 21, 21, contextualElement);
              morphs[11] = dom.createMorphAt(fragment, 23, 23, contextualElement);
              morphs[12] = dom.createMorphAt(dom.childAt(element4, [3]), 0, 0);
              morphs[13] = dom.createMorphAt(dom.childAt(element4, [5]), 0, 0);
              morphs[14] = dom.createMorphAt(dom.childAt(element5, [3]), 0, 0);
              morphs[15] = dom.createMorphAt(dom.childAt(element5, [5]), 0, 0);
              return morphs;
            },
            statements: [["inline", "d-field-text", [], ["type", "hidden", "key", "completingConsumptionDeclarationsEE"], ["loc", [null, [10, 32], [10, 104]]]], ["inline", "d-field-text", [], ["type", "hidden", "key", "completingConsumptionDeclarationsGas"], ["loc", [null, [11, 32], [11, 105]]]], ["inline", "d-field-text", [], ["type", "hidden", "key", "fillingAttachmentsEE"], ["loc", [null, [12, 32], [12, 89]]]], ["inline", "d-field-text", [], ["type", "hidden", "key", "fillingAttachmentsGas"], ["loc", [null, [13, 32], [13, 90]]]], ["inline", "d-field-text", [], ["type", "hidden", "key", "fillingContractDocumentsEE"], ["loc", [null, [14, 32], [14, 95]]]], ["inline", "d-field-text", [], ["type", "hidden", "key", "fillingContractDocumentsGas"], ["loc", [null, [15, 32], [15, 96]]]], ["inline", "d-field-text", [], ["type", "hidden", "key", "confirmationLoyalistsEE"], ["loc", [null, [16, 32], [16, 92]]]], ["inline", "d-field-text", [], ["type", "hidden", "key", "confirmationLoyalistsGas"], ["loc", [null, [17, 32], [17, 93]]]], ["inline", "d-field-text", [], ["type", "hidden", "key", "obtainingDistributionEE"], ["loc", [null, [18, 32], [18, 92]]]], ["inline", "d-field-text", [], ["type", "hidden", "key", "obtainingDistributionGas"], ["loc", [null, [19, 32], [19, 93]]]], ["inline", "d-field-text", [], ["type", "hidden", "key", "failProvideApprovalsEE"], ["loc", [null, [20, 32], [20, 91]]]], ["inline", "d-field-text", [], ["type", "hidden", "key", "failProvideApprovalsGas"], ["loc", [null, [21, 32], [21, 92]]]], ["block", "d-form-row", [], ["label", ""], 0, null, ["loc", [null, [33, 47], [33, 136]]]], ["block", "d-form-row", [], ["label", ""], 1, null, ["loc", [null, [34, 47], [34, 137]]]], ["block", "d-form-row", [], ["label", ""], 2, null, ["loc", [null, [38, 48], [38, 135]]]], ["block", "d-form-row", [], ["label", ""], 3, null, ["loc", [null, [39, 47], [39, 135]]]]],
            locals: [],
            templates: [child0, child1, child2, child3]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 7,
                "column": 20
              },
              "end": {
                "line": 44,
                "column": 20
              }
            },
            "moduleName": "ember-new/templates/b2b-contract/administration/section-general.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "d-form-command", [], ["commandName", "EditAdministrationSettingsCommand", "fillCommandModel", ["subexpr", "@mut", [["get", "fillForm", ["loc", [null, [8, 107], [8, 115]]]]], [], []], "afterSuccessfulCommand", ["subexpr", "@mut", [["get", "afterCommand", ["loc", [null, [9, 65], [9, 77]]]]], [], []]], 0, null, ["loc", [null, [8, 24], [43, 43]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 45,
                  "column": 24
                },
                "end": {
                  "line": 67,
                  "column": 24
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/administration/section-general.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("table");
              dom.setAttribute(el1, "class", "table table-bordered table-striped dataTable prices-table");
              dom.setAttribute(el1, "role", "grid");
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("thead");
              var el3 = dom.createTextNode("\n                                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("tr");
              dom.setAttribute(el3, "role", "row");
              var el4 = dom.createTextNode("\n                                      ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("th");
              var el5 = dom.createTextNode("Pole");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                      ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("th");
              dom.setAttribute(el4, "style", "text-align: center !important");
              var el5 = dom.createTextNode("Wartość EE");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                      ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("th");
              dom.setAttribute(el4, "style", "text-align: center !important");
              var el5 = dom.createTextNode("Wartość Gaz");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                  ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("tbody");
              var el3 = dom.createTextNode("\n                                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("tr");
              var el4 = dom.createTextNode("\n                                       ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("td");
              var el5 = dom.createTextNode("Domyślna Data OWU");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                       ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("td");
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                       ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("td");
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                    ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("tr");
              var el4 = dom.createTextNode("\n                                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("td");
              var el5 = dom.createTextNode("Domyśna stawka do wyliczenia zryczłtowanego odszkodowania [zł/MWh]");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("td");
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                       ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("td");
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                    ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1, 3]);
              var element1 = dom.childAt(element0, [1]);
              var element2 = dom.childAt(element0, [3]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(dom.childAt(element1, [3]), 0, 0);
              morphs[1] = dom.createMorphAt(dom.childAt(element1, [5]), 0, 0);
              morphs[2] = dom.createMorphAt(dom.childAt(element2, [3]), 0, 0);
              morphs[3] = dom.createMorphAt(dom.childAt(element2, [5]), 0, 0);
              return morphs;
            },
            statements: [["inline", "cell-data-label", [], ["value", ["subexpr", "@mut", [["get", "model.defaultDateOWUEE", ["loc", [null, [57, 67], [57, 89]]]]], [], []]], ["loc", [null, [57, 43], [57, 91]]]], ["inline", "cell-data-label", [], ["value", ["subexpr", "@mut", [["get", "model.defaultDateOWUGas", ["loc", [null, [58, 67], [58, 90]]]]], [], []]], ["loc", [null, [58, 43], [58, 92]]]], ["inline", "cell-data-label", [], ["value", ["subexpr", "format-number", [["get", "model.defaultCalculatingRateEE", ["loc", [null, [62, 83], [62, 113]]]]], [], ["loc", [null, [62, 68], [62, 115]]]]], ["loc", [null, [62, 44], [62, 117]]]], ["inline", "cell-data-label", [], ["value", ["subexpr", "format-number", [["get", "model.defaultCalculatingRateGas", ["loc", [null, [63, 82], [63, 113]]]]], [], ["loc", [null, [63, 67], [63, 115]]]]], ["loc", [null, [63, 43], [63, 117]]]]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 67,
                  "column": 24
                },
                "end": {
                  "line": 69,
                  "column": 24
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/administration/section-general.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "callout callout-danger");
              var el2 = dom.createElement("p");
              var el3 = dom.createTextNode("Brak parametrów");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 44,
                "column": 20
              },
              "end": {
                "line": 70,
                "column": 20
              }
            },
            "moduleName": "ember-new/templates/b2b-contract/administration/section-general.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "model", ["loc", [null, [45, 30], [45, 35]]]]], [], 0, 1, ["loc", [null, [45, 24], [69, 31]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 8
            },
            "end": {
              "line": 73,
              "column": 8
            }
          },
          "moduleName": "ember-new/templates/b2b-contract/administration/section-general.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "clearfix");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "box box-primary");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "box-body");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3, 1]), 1, 1);
          return morphs;
        },
        statements: [["block", "if", [["get", "editState", ["loc", [null, [7, 26], [7, 35]]]]], [], 0, 1, ["loc", [null, [7, 20], [70, 27]]]]],
        locals: ["editState"],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes", "wrong-type"]
        },
        "revision": "Ember@2.3.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 77,
            "column": 10
          }
        },
        "moduleName": "ember-new/templates/b2b-contract/administration/section-general.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-md-12");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 1]), 1, 1);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "tab-content-for-form", [], ["title", "Obciążenia za Uzupełnienie EE i GAZ", "isAllowedEdit", true], 0, null, ["loc", [null, [3, 8], [73, 33]]]], ["content", "outlet", ["loc", [null, [77, 0], [77, 10]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});
define('ember-new/components/d-form/meeting-verification/import', ['exports', 'ember-new/components/d-form', 'ember-new/mixins/d-form/d-form-upload', 'ember-new/config/environment', 'ember'], function (exports, _emberNewComponentsDForm, _emberNewMixinsDFormDFormUpload, _emberNewConfigEnvironment, _ember) {
    exports['default'] = _emberNewComponentsDForm['default'].extend(_emberNewMixinsDFormDFormUpload['default'], {

        submitRestAsSimple: true,
        submitModelNamespace: 'MeetingVerificationThuliumImport',
        submitRestAsSimpleMethod: 'POST',
        submitButtonText: 'Wczytaj',
        _textAddingSuccess: "Wczytano pomyślnie!",
        _textAddingException: "Wystąpił błąd. Nie udało się wczytać pliku.",

        submitSimpleUrl: (function () {
            return _emberNewConfigEnvironment['default'].apiUrl + '/rest/meetingverificationthuliumimports';
        }).property(),

        resolveContents: function resolveContents(form) {
            form.get('store').unloadAll('upload');
            return new _ember['default'].RSVP.Promise(function (resolve, reject) {
                var upload = form.get('store').peekAll('upload');
                _ember['default'].RSVP.Promise.all([upload]).then(function (result) {
                    var upload = result[0];
                    resolve(_ember['default'].Object.create({
                        file: upload
                    }));
                });
            });
        },

        afterSubmit: function afterSubmit(response) {
            var store = this.get('store');

            store.unloadAll('MeetingVerificationThuliumImportRow');

            var data = response.command_output[this.get('submitModelNamespace')];

            if (!this.handleErrors(data)) {
                this.handleSuccess(data);
            }

            this.sendAction('refreshRoute');
        },

        handleErrors: function handleErrors(data) {
            if (data.error) {
                this.get('applicationAlerts').addActionMessage('danger', data.error);

                return true;
            }

            return false;
        },

        handleSuccess: function handleSuccess(data) {
            var model = this.get('model.rows');
            var store = this.get('store');

            data.forEach(function (row) {
                model.pushObject(store.createRecord('MeetingVerificationThuliumImportRow', {
                    isToImport: row.errors.length <= 0 && row.warnings.length <= 0,
                    disabled: row.disabled,
                    taskId: row.taskId,
                    taskUuid: row.taskUuid,
                    taskDescription: row.taskDescription,
                    verificationStatus: row.verificationStatus,
                    errors: row.errors,
                    warnings: row.warnings,
                    id: row.taskUuid
                }));
            });
        },

        configure: function configure() {
            this.registerTransformer('file', function (uuid) {
                return uuid;
            }, function (file) {
                if (typeof file === 'string') {
                    return file;
                } else if (file) {
                    return file.get('uuid');
                }
                return null;
            });
        },

        actions: {
            submit: function submit() {
                this._handleSubmit();
            }
        }
    });
});
define('ember-new/components/sort-data', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        sortKey: null,
        sortColumns: [],
        sortField: null,
        sortDirField: 'asc',
        placeholder: 'Sortuj po...',

        actions: {
            changeOrderDirection: function changeOrderDirection() {
                var dir = arguments.length <= 0 || arguments[0] === undefined ? 'asc' : arguments[0];

                this.set('sortDirField', dir);
            }
        }
    });
});
define("ember-new/controllers/meeting-verification/ordering", ["exports", "ember", "ember-new/config/environment", "ember-new/mixins/paginable-controller"], function (exports, _ember, _emberNewConfigEnvironment, _emberNewMixinsPaginableController) {
    exports["default"] = _ember["default"].Controller.extend(_emberNewMixinsPaginableController["default"], {

        ajax: _ember["default"].inject.service('ajax'),
        applicationAlerts: _ember["default"].inject.service('application-alerts-manager'),

        queryParams: ['page', 'limit', 'dateFrom', 'dateTo', 'managersExcluded'],
        page: 1,
        limit: 10,
        dateFrom: null,
        dateTo: null,
        dateFromFilter: null,
        dateToFilter: null,
        managersExcluded: true,

        errors: null,

        dateFromChanged: (function () {
            return this.get('dateFrom') !== this.get('dateFromFilter');
        }).property('dateFrom'),

        dateToChanged: (function () {
            return this.get('dateTo') !== this.get('dateToFilter');
        }).property('dateTo'),

        actions: {
            applyFilters: function applyFilters() {
                this.set('dateFromFilter', this.get('dateFrom'));
                this.set('dateToFilter', this.get('dateTo'));
            },
            saveCampaign: function saveCampaign() {
                var _this2 = this;

                var handleResponse = function handleResponse(res) {
                    _this2.get('applicationAlerts').addActionMessage('success', 'OK');
                    _this2.transitionToRoute('meeting-verification.list');
                };

                var handleError = function handleError(error) {
                    if (error.responseJSON && error.responseJSON.message) {
                        _this2.get('applicationAlerts').addActionMessage('warning', error.responseJSON.message);
                    } else if (error.errors) {
                        _this2.set('errors', error.errors);
                    } else {
                        _this2.get('applicationAlerts').addActionMessage('danger', 'Nieznany błąd!');
                    }
                };

                var _this = this;

                var data = {
                    dateFrom: _this.get('dateFrom'),
                    dateTo: _this.get('dateTo'),
                    includeManagers: !_this.get('managersExcluded')
                };

                var serializedData = {
                    MeetingVerificationCampaignCreateCommand: data
                };

                this.get('ajax').ajaxPost(_emberNewConfigEnvironment["default"].apiUrl + '/rest/meetingverificationcampaigncreatecommands', serializedData).then(function (res) {
                    return handleResponse(res);
                }, function (err) {
                    return handleError(err);
                });
            }
        }
    });
});
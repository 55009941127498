define('ember-new/mixins/filterable-controller', ['exports', 'ember', 'ember-new/config/environment', 'ember-new/mixins/paginable-controller'], function (exports, _ember, _emberNewConfigEnvironment, _emberNewMixinsPaginableController) {
    exports['default'] = _ember['default'].Mixin.create({

        hasAnyFilter: false,
        filterComponents: [],

        registerFilterComponent: function registerFilterComponent(component) {
            var _this = this;
            this.get('filterComponents').pushObject(component);
            this.checkHasAnyFilter();
            this.addObserver(component.get('field'), function () {
                _this.checkHasAnyFilter();
            });
        },

        checkHasAnyFilter: function checkHasAnyFilter() {
            var _this = this;
            var result = false;
            this.get('filterComponents').forEach(function (filter) {
                if (filter.get('field') && _this.get(filter.get('field'))) {
                    result = true;
                }
            });
            if (result) {
                _this.set('hasAnyFilter', true);
            } else {
                _this.set('hasAnyFilter', false);
            }
        },

        actions: {
            applyFilter: function applyFilter(field, value) {

                // if controller applies also PaginableMixin, then reset page to 1, after each change of filters
                if (_emberNewMixinsPaginableController['default'].detect(this)) {
                    // set without calling observes to avoid double reloading of route
                    console.log("SET PAGE");
                    this.set('page', 1);
                }

                console.log("SET FILTER");
                console.log(field, value);
                this.set(field, value);
            },
            resetAllFilters: function resetAllFilters() {
                var _this = this;
                this.get('filterComponents').forEach(function (filter) {
                    if (filter.get('field')) {
                        // @todo: dlaczego '' a nie null? ~KJ
                        _this.set(filter.get('field'), '');
                    }
                });
            }
        }

    });
});
define("ember-new/mixins/b2b-contract/components/d-form/administration/vas", ["exports", "ember-new/templates/components/d-form", "ember"], function (exports, _emberNewTemplatesComponentsDForm, _ember) {
    exports["default"] = _ember["default"].Mixin.create({
        layout: _emberNewTemplatesComponentsDForm["default"],

        valueTypeChanged: (function () {
            var controller = this.get('targetObject');

            if (this.get('formData.valueType') === 'percent') {
                if (this.get('formData.overheadTypeForMM') !== 'const') {
                    controller.set('overheadRatePerPercentPointVisibility', false);
                } else {
                    controller.set('overheadRatePerPercentPointVisibility', true);
                }
                controller.set('valueStep', 0.1);
            } else {
                controller.set('overheadRatePerPercentPoint', null);
                controller.set('overheadRatePerPercentPointVisibility', true);
                controller.set('valueStep', 0.01);
            }
        }).observes('formData.valueType'),

        inclusionTypeChanged: (function () {
            var controller = this.get('targetObject');

            if (this.get('formData.inclusionType') === 'discount') {
                controller.set('isDiscount', true);
                this.set('formData.overheadTypeForMM', 'const');
            } else if (this.get('formData.inclusionType') === 'increase') {
                controller.set('isDiscount', false);
                this.set('formData.overheadTypeForMM', 'auto');
            } else {
                controller.set('isDiscount', false);
            }
        }).observes('formData.inclusionType'),

        overheadTypeForMMChanged: (function () {
            var controller = this.get('targetObject');

            if (this.get('formData.overheadTypeForMM') === 'const') {
                controller.set('isConstOverheadTypeForMM', true);
                controller.set('overheadRatePerPercentPointVisibility', true);
            } else {
                controller.set('isConstOverheadTypeForMM', false);
                if (this.get('formData.valueType') === 'percent') {
                    controller.set('overheadRatePerPercentPointVisibility', false);
                }
            }
        }).observes('formData.overheadTypeForMM'),

        validateForm: function validateForm() {
            if (this.get('formData.overheadTypeForMM') !== 'const') {
                this.set('formData.overheadForMM', null);
            } else {
                this.set('formData.overheadRatePerPercentPoint', null);
                this.set('formData.baseValue', null);
            }

            if (this.get('formData.valueType') !== 'percent') {
                this.set('formData.overheadRatePerPercentPoint', null);
            }

            return true;
        },

        afterSuccessfulSubmit: function afterSuccessfulSubmit() {
            var targetController = this.get('targetObject');
            var mainRoute = targetController.container.lookup("route:b2b-contract.administration.vas");
            mainRoute.refresh();
            setTimeout(function () {
                targetController.transitionToRoute('b2b-contract.administration.vas');
            }, 500);
        }
    });
});
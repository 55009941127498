define('ember-new/components/d-field-select-uuid-multiple', ['exports', 'ember', 'ember-new/mixins/d-form/d-field', 'ember-new/mixins/d-form/d-field-select-multiple', 'ember-new/components/d-field-select'], function (exports, _ember, _emberNewMixinsDFormDField, _emberNewMixinsDFormDFieldSelectMultiple, _emberNewComponentsDFieldSelect) {
    exports['default'] = _emberNewComponentsDFieldSelect['default'].extend(_emberNewMixinsDFormDField['default'], _emberNewMixinsDFormDFieldSelectMultiple['default'], {
        classNames: ['form-control', 'width-100percent'],
        multiple: true,
        _preventOnChange: true,
        _preventValueObserver: true,

        preventSecondSelectionChange: false,

        selectionDidChange: (function () {
            //brzydki hack ale cos co mi drugi raz zmienia selectiona i ustawia wartosc na pusta tablice, nie moge zlokalizowac tego observera ~BH
            if (this.get('preventSecondSelectionChange')) {
                this.set('preventSecondSelectionChange', false);
                return;
            }
            this.set('preventSecondSelectionChange', true);
            var value = [];
            if (this.get('selection')) {
                this.get('selection').forEach(function (item) {
                    if (item) {
                        value.push(item.get('id'));
                    }
                });
            }
            this.set('value', value);
        }).observes('selection')

    });
});
define('ember-new/components/textarea-data-extractor', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend({
    rows: 10,
    cols: 50,
    plainData: null,
    handleDataAction: null,

    actions: {
      setVal: function setVal() {
        // debug....
        var val = 'GA0000000007PG\n' + 'GA0000000006EE\n' + 'GA0000000005PG\n' + 'GA0000000005PG\n' + 'GA0000000003EE\n' + 'GA0000000004PG\n' + 'GA0000000003PG\n' + 'GA000000003PG\n' + 'GA0000000003AG';
        this.set('plainData', val);
      },
      parseData: function parseData() {
        var plainData = this.get('plainData');

        // aplit and remove spaces in records/empty records
        var result = plainData.split('\n').map(function (item) {
          return item.trim();
        }).filter(function (item) {
          return item.length > 0;
        });

        this.get('handleDataAction')(result);
      }
    }
  });
});
define("ember-new/mixins/tasks/task-simplified-model", ["exports", "ember-data", "ember"], function (exports, _emberData, _ember) {
    exports["default"] = _ember["default"].Mixin.create({
        acqId: _emberData["default"].attr('string'),
        acqIsFavouriteSalesman: _emberData["default"].attr('boolean'),
        acqIsFavouriteManager: _emberData["default"].attr('boolean'),
        acqMainTariffGroup: _emberData["default"].attr('string'),
        acqMainTariffGroupId: _emberData["default"].attr('string'),
        acqGasMainTariffGroup: _emberData["default"].attr('string'),
        acqGasMainTariffGroupId: _emberData["default"].attr('string'),
        acqStatus: _emberData["default"].attr('string'),
        acqStatusId: _emberData["default"].attr('string'),
        acqStatusNumber: _emberData["default"].attr('number'),
        // acqHasLoyaltyContract: DS.attr('number'), // @TODO wywalic
        // acqLoyaltyContractExpired: DS.attr('date'), // @TODO wywalic
        acqLoyaltyStatus: _emberData["default"].attr('number'),
        acqLoyaltyStatusText: _emberData["default"].attr('string'),
        acqLoyaltyDate: _emberData["default"].attr('date'),
        acqOsd: _emberData["default"].attr('string'),
        acqCurrentVendor: _emberData["default"].attr('string'),
        acqMM: _emberData["default"].attr('string'),
        acqMMValue: _emberData["default"].attr('number'),
        acqUnreadCommentsCount: _emberData["default"].attr('number'),
        acqHasEeContract: _emberData["default"].attr('boolean'),
        // acqHasGasContract: DS.attr('boolean'), // @TODO wywalic
        // acqHasGasContract: DS.attr('boolean'),// @TODO wywalic
        acqType: _emberData["default"].attr('string'),

        tskType: _emberData["default"].attr('string'),
        tskTime: _emberData["default"].attr('date'),
        tskSource: _emberData["default"].attr('string'),
        tskSourceId: _emberData["default"].attr('string'),
        tskIsDone: _emberData["default"].attr('boolean'),
        tskIsResignation: _emberData["default"].attr('boolean'),
        tskForUserFullname: _emberData["default"].attr('string'),
        tskForUserLastName: _emberData["default"].attr('string'),
        tskForUserNick: _emberData["default"].attr('string'),
        tskForUserPhone: _emberData["default"].attr('string'),
        tskForUserRegion: _emberData["default"].attr('string'),
        tskCreatedByFullname: _emberData["default"].attr('string'),
        tskCreatedByPhone: _emberData["default"].attr('string'),
        tskCreatedByMainRole: _emberData["default"].attr('string'),
        tskDateCreated: _emberData["default"].attr('date'),
        tskMeetingAddress: _emberData["default"].attr('string'),

        tskCoachingFullname: _emberData["default"].attr('string'),

        clnId: _emberData["default"].attr('string'),
        clnCompanyName: _emberData["default"].attr('string'),
        clnFullCompanyName: _emberData["default"].attr('string'),
        clnNip: _emberData["default"].attr('string'),
        clnAddress: _emberData["default"].attr('string'),
        clnFullAddress: _emberData["default"].attr('string'),

        clnEeContactPersonFullName: _emberData["default"].attr('string'),
        clnEeContactPersonLastName: _emberData["default"].attr('string'),
        clnEeContactPersonPosition: _emberData["default"].attr('string'),
        clnEeContactPersonPhone: _emberData["default"].attr('string'),
        clnEeContactPersonEmail: _emberData["default"].attr('string'),
        clnGasContactPersonFullName: _emberData["default"].attr('string'),
        clnGasContactPersonLastName: _emberData["default"].attr('string'),
        clnGasContactPersonPosition: _emberData["default"].attr('string'),
        clnGasContactPersonPhone: _emberData["default"].attr('string'),
        clnGasContactPersonEmail: _emberData["default"].attr('string'),

        clnIsReserved: _emberData["default"].attr('boolean'),
        clnIsReservedEE: _emberData["default"].attr('boolean'),
        clnIsReservedGas: _emberData["default"].attr('boolean'),
        clnReservationFor: _emberData["default"].attr('string'),
        clnEeReservationFor: _emberData["default"].attr('string'),
        clnGasReservationFor: _emberData["default"].attr('string'),
        clnReservationExpireDate: _emberData["default"].attr('date'),
        clnEeReservationExpireDate: _emberData["default"].attr('date'),
        clnGasReservationExpireDate: _emberData["default"].attr('date'),

        verMeetingFileId: _emberData["default"].attr('string'),
        verMeetingResult: _emberData["default"].attr('string'),
        verMeetingResultId: _emberData["default"].attr('string'),

        isDoneOrder: (function () {
            var now = moment();
            now.hour(0);
            now.minute(0);
            now.second(0);
            if (!this.get('tskIsDone') && moment(this.get('tskTime')) < now) return 0;else if (this.get('tskIsDone')) return 2;else return 1;
        }).property('tskIsDone', 'tskTime'),

        isOwnSource: (function () {
            if (this.get('tskSourceId') == '542bc797e771ded34e8b459c') {
                return true;
            }
            return false;
        }).property('tskSourceId', 'tskSource'),

        isHotDeal: _emberData["default"].attr('boolean'),

        eeReservationType: _emberData["default"].attr('string'),
        gasReservationType: _emberData["default"].attr('string'),
        eeReservationChannel: _emberData["default"].attr('string'),
        gasReservationChannel: _emberData["default"].attr('string')

    });
});
define('ember-new/adapters/application', ['exports', 'ember-data', 'ember', 'ember-new/config/environment', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _emberData, _ember, _emberNewConfigEnvironment, _emberSimpleAuthMixinsDataAdapterMixin) {

  _ember['default'].warn = function () {}; // @TODO uncomment to turn off console warning notification

  exports['default'] = _emberData['default'].RESTAdapter.extend(_emberSimpleAuthMixinsDataAdapterMixin['default'], {
    namespace: _emberNewConfigEnvironment['default'].apiRestNamespace,
    host: _emberNewConfigEnvironment['default'].apiHost,
    authorizer: 'authorizer:custom',

    pathForType: function pathForType(type) {
      var camelized = _ember['default'].String.camelize(type);
      var lowercase = camelized.toLowerCase();
      return _ember['default'].String.pluralize(lowercase);
    },
    _convertObjectWithChildrenToEmberObject: function _convertObjectWithChildrenToEmberObject(object) {
      console.log('debug: ', object);
      var self = this;

      if (object instanceof Array || !(object instanceof Object)) {
        return;
      }

      Object.keys(object).forEach(function (key) {
        if (object[key] instanceof Object && !(object[key] instanceof Array)) {
          self._convertObjectWithChildrenToEmberObject(object[key]);
          _ember['default'].set(object, key, _ember['default'].Object.create(object[key]));
        }
      });
    },
    _prepareAjaxPayload: function _prepareAjaxPayload(recordData, attributesToUpdate, rootNamespace) {

      var ajaxData = {};
      ajaxData[rootNamespace] = {};

      if (attributesToUpdate.length > 0) {
        for (var i = 0; i < attributesToUpdate.length; i++) {

          var attrName = attributesToUpdate[i];

          if (attrName.indexOf('::') > 0) {
            attrName = attrName.replace(/\:\:/g, '.');
          }

          if (attrName.indexOf('.') > 0) {

            var parts = attrName.split(".");
            var pointer = ajaxData[rootNamespace];

            for (var _i = 0; _i < parts.length - 1; _i++) {
              var part = parts[_i];
              if (!pointer[part]) {
                pointer[part] = {};
              }

              pointer = pointer[part];
            }
            ;

            pointer[parts[parts.length - 1]] = recordData.get(attrName);
          } else {
            ajaxData[rootNamespace][attrName] = recordData.get(attrName);
          }
        }
      }

      return ajaxData;
    },
    serializeCustom: function serializeCustom(record, attributesToUpdate, rootNamespace) {
      var attributesToUpdateOnlyDirectAttrs = [];
      var directKey;
      _ember['default'].A(attributesToUpdate).forEach(function (key) {
        directKey = key.split('.')[0];

        if (attributesToUpdateOnlyDirectAttrs.indexOf(directKey) < 0) {
          attributesToUpdateOnlyDirectAttrs.push(directKey);
        }
      });

      var recordData = record.serialize({ includeId: false, forceExcludedAttributesSerialization: attributesToUpdateOnlyDirectAttrs });

      recordData = _ember['default'].Object.create(recordData);
      console.log('recordData', recordData);
      this._convertObjectWithChildrenToEmberObject(recordData);

      return this._prepareAjaxPayload(recordData, attributesToUpdate, rootNamespace);
    },
    updateRecordCustom: function updateRecordCustom(store, type, record, actionName, attributesToUpdate, metaData, customVarname, customRequestData) {
      var root = customVarname === undefined || !customVarname ? type.typeKey : customVarname;

      var ajaxData = this.serializeCustom(record, attributesToUpdate, root);

      if (metaData !== undefined && metaData !== null && metaData) {
        ajaxData.meta = metaData;
      }

      if (customRequestData !== undefined && typeof customRequestData == "object") {
        Object.keys(customRequestData).forEach(function (key) {

          if (key == customVarname) {
            throw new _ember['default'].Error("Key of `customRequestData` passed to ApplicationAdapter.updateRecordCustom cannot be the same as `customVarname` parameter");
          }

          ajaxData[key] = customRequestData[key];
        });
      }

      var id = record.get('id');
      var url = this.buildURL(type, id) + '/' + actionName;

      return this.ajax(url, "PATCH", { data: ajaxData });
    },
    postRecordCustom: function postRecordCustom(store, type, record, actionName, attributesToUpdate, metaData, customVarname, customRequestData) {

      var root = customVarname === undefined || !customVarname ? type.typeKey : customVarname;

      var ajaxData = this.serializeCustom(record, attributesToUpdate, root);

      if (metaData !== undefined && metaData !== null && metaData) {
        ajaxData.meta = metaData;
      }

      if (customRequestData !== undefined && typeof customRequestData == "object") {
        Object.keys(customRequestData).forEach(function (key) {

          if (key == customVarname) {
            throw new _ember['default'].Error("Key of `customRequestData` passed to ApplicationAdapter.postRecordCustom cannot be the same as `customVarname` parameter");
          }

          ajaxData[key] = customRequestData[key];
        });
      }

      var id = record.get('id');
      var url = this.buildURL(type, id) + (actionName ? '/' + actionName : '');

      return this.ajax(url, "POST", { data: ajaxData });
    },
    isInvalid: function isInvalid(status, headers, payload) {
      return false;
    }
  });
});
define("ember-new/models/base-price-list/ee-price-list-row-view-model", ["exports", "ember-new/models/abstract", "ember-data/attr", "ember-data"], function (exports, _emberNewModelsAbstract, _emberDataAttr, _emberData) {
    exports["default"] = _emberNewModelsAbstract["default"].extend({
        errors: (0, _emberDataAttr["default"])(),
        rowNumber: (0, _emberDataAttr["default"])('number'),
        dateFrom: (0, _emberDataAttr["default"])('string'),
        dateTo: (0, _emberDataAttr["default"])('string'),
        tariffGroup: (0, _emberDataAttr["default"])('string'),
        basePriceArea1: (0, _emberDataAttr["default"])('number'),
        basePriceArea2: (0, _emberDataAttr["default"])('number'),
        basePriceArea3: (0, _emberDataAttr["default"])('number'),
        basePriceArea4: (0, _emberDataAttr["default"])('number'),
        flatPrice: (0, _emberDataAttr["default"])('number'),
        baseFee: (0, _emberDataAttr["default"])('number')
    });
});
define("ember-new/routes/individual-contract/show-ee/anonymize", ["exports", "ember-new/routes/authenticated", "ember-new/mixins/routes/unsaved-alert"], function (exports, _emberNewRoutesAuthenticated, _emberNewMixinsRoutesUnsavedAlert) {
    exports["default"] = _emberNewRoutesAuthenticated["default"].extend(_emberNewMixinsRoutesUnsavedAlert["default"], {

        showLoading: false,
        restControllerName: 'relateddocumentsviewmodel',
        parentModelName: 'individual-contract.show-ee',

        beforeModel: function beforeModel() {
            window.scrollTo(0, 0);
        },

        model: function model() {
            this.store.unloadAll('RelatedDocumentsViewModel');
            var parentModel = this.modelFor('individual-contract.show-ee');
            return this.store.findRecord('RelatedDocumentsViewModel', parentModel.get('uuid'));
        }
    });
});
define('ember-new/components/bt-datepicker', ['exports', 'ember', 'ember-bootstrap-datetimepicker/components/bs-datetimepicker'], function (exports, _ember, _emberBootstrapDatetimepickerComponentsBsDatetimepicker) {
    /*
    * Komponent modyfikuje domyslne ustawienia datetime pickera:
    * Ember Version: https://github.com/soulim/ember-cli-bootstrap-datepicker
    * Based on: http://eonasdan.github.io/bootstrap-datetimepicker/
    * */
    exports['default'] = _emberBootstrapDatetimepickerComponentsBsDatetimepicker['default'].extend({
        // datetimepicker settings
        format: 'YYYY-MM-DD',
        outputFormat: 'YYYY-MM-DD',
        sideBySide: true,
        allowInputToggle: true,
        outputType: 'string', // what bind to value? moment object or string
        useCurrent: false,
        locale: 'pl',
        debug: false, // Will cause the date picker to stay open after a blur event.

        // date: null,
        // value: null,

        // additional
        disabled: false,
        placeholder: '',

        // formated date:
        initialValue: '',

        hasDate: (function () {
            if (this.get('value') !== null && this.get('value') !== undefined) {
                return true;
            }

            return false;
        }).property('value'),

        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);

            // pobiera date i formatuje, nastepnie wstawia ja w wartosc pola
            if (this.get('value')) {
                this.set('initialValue', moment(this.get('value')).format(this.get('format')));
            }
        },

        _setupChangeEvent: function _setupChangeEvent(bsDateTimePicker) {
            var _this = this;

            bsDateTimePicker.on('dp.change', function (ev) {
                _ember['default'].run(function () {
                    var value = undefined,
                        date = _this.bsDateTimePicker.date();

                    if (date === null) {
                        value = null;
                    } else if (_this.get('outputType') == "moment") {
                        value = date;
                    } else if (_this.get('outputType') == "string") {
                        value = date.format(_this.get('outputFormat'));
                    }
                    _this.set('value', value);
                });
            });
        },

        actions: {
            removeValue: function removeValue() {
                this.bsDateTimePicker.date(null);
            }

        }
    });
});
define('ember-new/components/file-upload', ['exports', 'ember', 'ember-new/config/environment'], function (exports, _ember, _emberNewConfigEnvironment) {

	var FileUploadView = _ember['default'].View.extend({
		templateName: 'file_upload_form',
		mapping: 'pdf',
		init: function init() {
			this._super();

			// parametry: https://github.com/blueimp/jQuery-File-Upload/wiki/Options
			var params = this.get('params');

			if (params && params.mapping) {
				this.set('mapping', params.mapping);
			}
		},

		isFileExist: (function () {

			if (this.get('value') !== undefined && this.get('value') !== null) {
				return true;
			} else {
				return false;
			}
		}).property('value'),

		// DO PRZEPISANIA PRZY REFACTORINGU UPLOADU!!
		reloadAllUploadPlugins: function reloadAllUploadPlugins() {
			$('.ambforms').fileupload('destroy');
			this.initFileUploadPlugin();
		},
		didInsertElement: function didInsertElement() {
			// https://github.com/blueimp/jQuery-File-Upload/wiki/Options
			this.initFileUploadPlugin();
		},
		initFileUploadPlugin: function initFileUploadPlugin() {
			var uploadedInfo = "";
			var _this = this;

			$('#' + this.get('elementId').replace(/(:|\.|\[|\]|,|=|@)/g, "\\$1")).fileupload({

				// @TODO - mapping - lista jest na /var/www/amb-crm-2.0/app/config/file_upload.yml

				url: _emberNewConfigEnvironment['default'].apiUrl + '/_uploader/' + _this.get('mapping') + '/upload',

				add: function add(e, data) {
					data.submit();
					$('#uploadedLoadingInfo_' + data.paramName).show();
				},
				done: function done(e, data) {
					var fileUploadErrors = {
						'error.maxsize': 'Zbyt duży plik',
						'error.whitelist': 'Zły typ pliku'
					};
					$.each(data.files, function (index, file) {
						var fileField = JSON.parse(data.result).fileField;
						console.log(data.result);
						var errors = JSON.parse(data.result).files;

						if (errors) {
							alert(fileUploadErrors[errors[0].error] + '. Spróbuj ponownie.');
						} else {
							var emberViewId = $("[name='" + fileField + "']").closest('.ember-view').attr("id");
							console.log(emberViewId);
							var viewRegistry = _this.get('container').lookup('-view-registry:main');
							var desiredEmberView = viewRegistry[emberViewId];

							var uploadedFiles = desiredEmberView.get('value');
							var newUploadedFiles = "";
							console.log('type: ' + jQuery.type(uploadedFiles));
							if (jQuery.type(uploadedFiles) === 'object' || jQuery.type(uploadedFiles) === 'undefined' || jQuery.type(uploadedFiles) === 'array' || jQuery.type(uploadedFiles) === 'null') {
								newUploadedFiles = JSON.parse(data.result).fileId;
							} else {
								newUploadedFiles = uploadedFiles + ',' + JSON.parse(data.result).fileId;
							}
							_this.get('controller').get('store').find('file', newUploadedFiles).then(function (response) {
								console.log('obiekt przekazany do formularza:');
								console.log(response);
								desiredEmberView.set('value', response);
								uploadedInfo = "Wybrano plik: " + file.name + "<br>";
								setTimeout(function () {
									$('#uploadedInfo_' + fileField).html(uploadedInfo);
								}, 100);

								$('#uploadedLoadingInfo_' + JSON.parse(data.result).fileField).hide();
							});
						}

						// @TODO kasowanie newUploadedFiles i uploadedInfo po wysłaniu forma
						// @TODO kasowanie newUploadedFiles i uploadedInfo po wysłaniu forma
						// @TODO kasowanie newUploadedFiles i uploadedInfo po wysłaniu forma
						// @TODO kasowanie newUploadedFiles i uploadedInfo po wysłaniu forma
					});
				}
			});
		}
	});

	exports['default'] = FileUploadView;
});
define('ember-new/mixins/base-document', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {

    var BaseDocumentMixin = _ember['default'].Mixin.create({
        dateCreated: _emberData['default'].attr('date'),
        dateModified: _emberData['default'].attr('date'),
        //dateDeleted: DS.attr('date'),
        //deletedBy: DS.belongsTo('User'),
        createdBy: _emberData['default'].belongsTo('user', { inverse: null }),
        lastModifiedBy: _emberData['default'].belongsTo('user', { inverse: null })
    });
    /**
     * Mixin dla BaseDocument z symfony
     * @author Kamil Jakubowski <k.jakubowski@kontaktenergia.pl>
     * @component CRM 2.0
     */

    var attr = _emberData['default'].attr;
    var referenceOne = _emberData['default'].belongsTo;

    exports['default'] = BaseDocumentMixin;
});
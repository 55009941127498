define('ember-new/controllers/individual-contract-partners/status-history', ['exports', 'ember', 'ember-new/config/environment', 'ember-new/mixins/comments/comments-controller'], function (exports, _ember, _emberNewConfigEnvironment, _emberNewMixinsCommentsCommentsController) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsCommentsCommentsController['default'], {
        ajax: _ember['default'].inject.service('ajax'),

        contractId: null,

        resetData: function resetData() {
            this.set('model', null);
        },

        addCommentApiUrl: (function () {
            return _emberNewConfigEnvironment['default'].apiUrl + '/rest/individualcontractpartneraddcommentcommands';
        }).property(),

        prepareDataToAddComment: function prepareDataToAddComment() {
            var data = {
                'IndividualContractPartnerAddCommentCommand': {
                    contractId: this.get('contractId'),
                    comment: this.get('comment')
                }
            };

            return data;
        },

        isEditable: (function () {
            var isAnonymized = this.get('contract.isAnonymized');
            return !isAnonymized;
        }).property('contract'),

        actions: {

            addComment: function addComment() {

                var _this = this;

                if (this.get('isAddingComment') === true || !this.validateData()) {
                    return false;
                }

                this.set('isAddingComment', true);

                var data = this.prepareDataToAddComment();

                var ajaxPromise = this.get('ajax').ajaxPost(this.get('addCommentApiUrl'), data);

                ajaxPromise.then(function (response) {
                    //_this.loadHistory();
                    _this.set('commentErrors', []);
                    _this.set('comment', '');
                    _this.set('boldMe', 0);

                    _this.store.query('individual-contract-partner-comments-view-model', { contractId: _this.get('contractId') }).then(function (res) {
                        _ember['default'].run.later(function () {
                            _this.set('model', res);
                            _this.set('isLoading', false);
                        });
                    });

                    _this.set('isAddingComment', false);
                    _this.set('contentChangedManually', new Date().getTime());
                }, function (response) {
                    _this.set('isAddingComment', false);
                    handleAjaxError(response, function () {
                        _this.set('commentErrors', response.responseJSON.errors.children.content.errors);
                    });
                });
            }
        }
    });
});
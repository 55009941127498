define('ember-new/models/correction-dictionary-entry', ['exports', 'ember-new/models/abstract'], function (exports, _emberNewModelsAbstract) {

	var CorrectionDictionaryEntry = _emberNewModelsAbstract['default'].extend({
		label: DS.attr('string'),
		name: DS.attr('string'),
		value: DS.attr('number'),
		isHidden: DS.attr('boolean'),
		uuid: DS.attr('string')
	});

	exports['default'] = CorrectionDictionaryEntry;
});
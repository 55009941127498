define("ember-new/templates/b2b-contract/settings/base-prices", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 27,
              "column": 48
            },
            "end": {
              "line": 28,
              "column": 74
            }
          },
          "moduleName": "ember-new/templates/b2b-contract/settings/base-prices.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("i");
          dom.setAttribute(el1, "class", "fa fa-plus");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" Dodaj\n                                                    nowy cennik bazowy GAZ");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 49,
                "column": 56
              },
              "end": {
                "line": 72,
                "column": 56
              }
            },
            "moduleName": "ember-new/templates/b2b-contract/settings/base-prices.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            dom.setAttribute(el1, "role", "row");
            var el2 = dom.createTextNode("\n                                                                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("typ");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                                                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n                                                                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "info-label grey");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                                                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                                                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("nazwa");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                                                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n                                                                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                                                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                                                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("data obowiazywania");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                                                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n                                                                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                                                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                                                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("produkt");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                                                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n                                                                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                                                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                                                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("termin płatności");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                                                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n                                                                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                                                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                                            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(5);
            morphs[0] = dom.createMorphAt(dom.childAt(element0, [3, 1]), 0, 0);
            morphs[1] = dom.createMorphAt(dom.childAt(element0, [7]), 1, 1);
            morphs[2] = dom.createMorphAt(dom.childAt(element0, [11]), 1, 1);
            morphs[3] = dom.createMorphAt(dom.childAt(element0, [15]), 1, 1);
            morphs[4] = dom.createMorphAt(dom.childAt(element0, [19]), 1, 1);
            return morphs;
          },
          statements: [["content", "item.type", ["loc", [null, [53, 98], [53, 111]]]], ["content", "item.name", ["loc", [null, [57, 68], [57, 81]]]], ["inline", "format-date", [["get", "item.dateFrom", ["loc", [null, [61, 82], [61, 95]]]]], ["format", "ll"], ["loc", [null, [61, 68], [61, 110]]]], ["content", "item.productName", ["loc", [null, [65, 68], [65, 88]]]], ["content", "item.paymentTerm", ["loc", [null, [69, 68], [69, 88]]]]],
          locals: ["item"],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 72,
                "column": 56
              },
              "end": {
                "line": 76,
                "column": 56
              }
            },
            "moduleName": "ember-new/templates/b2b-contract/settings/base-prices.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            dom.setAttribute(el1, "role", "row");
            var el2 = dom.createTextNode("\n                                                                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "colspan", "5");
            dom.setAttribute(el2, "class", "text-center");
            var el3 = dom.createTextNode("Brak rezultatów");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                                            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 48,
              "column": 52
            },
            "end": {
              "line": 77,
              "column": 52
            }
          },
          "moduleName": "ember-new/templates/b2b-contract/settings/base-prices.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "each", [["get", "model", ["loc", [null, [49, 64], [49, 69]]]]], ["key", "@index"], 0, 1, ["loc", [null, [49, 56], [76, 65]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 77,
              "column": 52
            },
            "end": {
              "line": 81,
              "column": 52
            }
          },
          "moduleName": "ember-new/templates/b2b-contract/settings/base-prices.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                                                        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("tr");
          dom.setAttribute(el1, "role", "row");
          var el2 = dom.createTextNode("\n                                                            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2, "colspan", "5");
          dom.setAttribute(el2, "class", "text-center");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                                                        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 0, 0);
          return morphs;
        },
        statements: [["inline", "loading-route-spinner", [], ["type", "dark"], ["loc", [null, [79, 96], [79, 133]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes", "wrong-type"]
        },
        "revision": "Ember@2.3.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 100,
            "column": 0
          }
        },
        "moduleName": "ember-new/templates/b2b-contract/settings/base-prices.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "hold-transition skin-blue layout-top-nav");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "wrapper");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "content-wrapper");
        dom.setAttribute(el3, "style", "min-height: 946px;");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "row");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "col-md-12 col-lg-offset-1 col-lg-10");
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("section");
        dom.setAttribute(el6, "class", "content-header");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h1");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("i");
        dom.setAttribute(el8, "class", "fa fa-suitcase");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" Cenniki bazowe\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("ol");
        dom.setAttribute(el7, "class", "breadcrumb");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        dom.setAttribute(el8, "class", "active");
        var el9 = dom.createElement("i");
        dom.setAttribute(el9, "class", "fa fa-files-o");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" Cenniki bazowe");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("section");
        dom.setAttribute(el6, "class", "content");
        var el7 = dom.createTextNode("\n\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7, "class", "box");
        var el8 = dom.createTextNode("\n\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8, "class", "box-body");
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9, "id", "clients-sales-admin-list");
        dom.setAttribute(el9, "class", "dataTables_wrapper form-inline dt-bootstrap");
        var el10 = dom.createTextNode("\n\n                                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10, "class", "row margin-bottom");
        var el11 = dom.createTextNode("\n                                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11, "class", "col-sm-12");
        var el12 = dom.createTextNode("\n\n                                            ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("div");
        dom.setAttribute(el12, "class", "pull-right margin-right");
        var el13 = dom.createTextNode("\n                                                ");
        dom.appendChild(el12, el13);
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode("\n                                            ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                                            ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("div");
        dom.setAttribute(el12, "class", "clearfix");
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n\n                                            ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("div");
        dom.setAttribute(el12, "class", "table-responsive");
        var el13 = dom.createTextNode("\n                                                ");
        dom.appendChild(el12, el13);
        var el13 = dom.createElement("table");
        dom.setAttribute(el13, "id", "example1");
        dom.setAttribute(el13, "class", "table table-bordered table-striped dataTable");
        dom.setAttribute(el13, "role", "grid");
        var el14 = dom.createTextNode("\n\n                                                    ");
        dom.appendChild(el13, el14);
        var el14 = dom.createElement("thead");
        var el15 = dom.createTextNode("\n                                                    ");
        dom.appendChild(el14, el15);
        var el15 = dom.createElement("tr");
        dom.setAttribute(el15, "role", "row");
        var el16 = dom.createTextNode("\n                                                        ");
        dom.appendChild(el15, el16);
        var el16 = dom.createElement("th");
        var el17 = dom.createTextNode("Typ");
        dom.appendChild(el16, el17);
        dom.appendChild(el15, el16);
        var el16 = dom.createTextNode("\n                                                        ");
        dom.appendChild(el15, el16);
        var el16 = dom.createElement("th");
        var el17 = dom.createTextNode("Nazwa");
        dom.appendChild(el16, el17);
        dom.appendChild(el15, el16);
        var el16 = dom.createTextNode("\n                                                        ");
        dom.appendChild(el15, el16);
        var el16 = dom.createElement("th");
        var el17 = dom.createTextNode("Data obowiązywania od");
        dom.appendChild(el16, el17);
        dom.appendChild(el15, el16);
        var el16 = dom.createTextNode("\n                                                        ");
        dom.appendChild(el15, el16);
        var el16 = dom.createElement("th");
        var el17 = dom.createTextNode("Produkt");
        dom.appendChild(el16, el17);
        dom.appendChild(el15, el16);
        var el16 = dom.createTextNode("\n                                                        ");
        dom.appendChild(el15, el16);
        var el16 = dom.createElement("th");
        var el17 = dom.createTextNode("Termin płatności");
        dom.appendChild(el16, el17);
        dom.appendChild(el15, el16);
        var el16 = dom.createTextNode("\n                                                    ");
        dom.appendChild(el15, el16);
        dom.appendChild(el14, el15);
        var el15 = dom.createTextNode("\n\n                                                    ");
        dom.appendChild(el14, el15);
        dom.appendChild(el13, el14);
        var el14 = dom.createTextNode("\n\n                                                    ");
        dom.appendChild(el13, el14);
        var el14 = dom.createElement("tbody");
        var el15 = dom.createTextNode("\n\n");
        dom.appendChild(el14, el15);
        var el15 = dom.createComment("");
        dom.appendChild(el14, el15);
        var el15 = dom.createTextNode("\n                                                    ");
        dom.appendChild(el14, el15);
        dom.appendChild(el13, el14);
        var el14 = dom.createTextNode("\n                                                ");
        dom.appendChild(el13, el14);
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode("\n                                            ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                                        ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                                    ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createComment(" /.box-body ");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createComment(" /.box ");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0, 1, 1, 1, 1, 3, 1, 1, 1, 1, 1]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(element1, [1]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element1, [5, 1, 3]), 1, 1);
        morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        return morphs;
      },
      statements: [["block", "link-to", ["b2b-contract.settings.base-prices.add-gas"], ["class", "btn btn-primary"], 0, null, ["loc", [null, [27, 48], [28, 86]]]], ["block", "unless", [["get", "isReloading", ["loc", [null, [48, 62], [48, 73]]]]], [], 1, 2, ["loc", [null, [48, 52], [81, 63]]]], ["content", "outlet", ["loc", [null, [99, 0], [99, 10]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});
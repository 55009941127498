define("ember-new/mixins/models/individual-client", ["exports", "ember", "ember-data", "ember-new/models/abstract"], function (exports, _ember, _emberData, _emberNewModelsAbstract) {

    var attr = _emberData["default"].attr;
    var refOne = _emberData["default"].belongsTo;

    exports["default"] = _ember["default"].Mixin.create({
        pesel: attr('string'),
        foreigner: attr('boolean'),
        firstName: attr('string'),
        lastName: attr('string'),
        identificationDocumentType: refOne('identification-document-type', { inverse: null }),
        identificationDocumentTypePikaText: attr('string'),
        identificationDocumentNumber: attr('string'),
        phone: attr('string'),
        phone2: attr('string'),
        email: attr('string'),
        telemedic: attr('string'),
        baseAddress: refOne('address', { embedded: true }),
        additionalAddress: refOne('address', { embedded: true }),
        age: attr('number'),
        isAnonymized: attr('boolean'),

        peselSecondClient: attr('string'),
        foreignerSecondClient: attr('boolean'),
        firstNameSecondClient: attr('string'),
        lastNameSecondClient: attr('string'),
        identificationDocumentTypeSecondClient: refOne('identification-document-type', { inverse: null }),
        identificationDocumentNumberSecondClient: attr('string'),
        phoneSecondClient: attr('string'),
        phone2SecondClient: attr('string'),
        emailSecondClient: attr('string'),
        ageSecondClient: attr('number'),

        fullName: (function () {
            return this.get('firstName') + ' ' + this.get('lastName');
        }).property('firstName', 'lastName'),

        fullNameSecondClient: (function () {
            return this.get('firstNameSecondClient') + ' ' + this.get('lastNameSecondClient');
        }).property('firstNameSecondClient', 'lastNameSecondClient')
    });
});
define('ember-new/components/users-import-form', ['exports', 'ember-new/components/amb-forms', 'ember-new/form/row/row', 'ember-new/views/file-upload'], function (exports, _emberNewComponentsAmbForms, _emberNewFormRowRow, _emberNewViewsFileUpload) {

	var UsersImportFormComponent = _emberNewComponentsAmbForms['default'].extend({
		formDefinition: [_emberNewFormRowRow['default'].create({ key: "sourceFile", label: "Plik xls", fieldView: _emberNewViewsFileUpload['default'], params: { mapping: 'usersImport' } })],
		hasViewState: false,
		updateMethodType: 'rest_post',
		formName: 'pricesList',
		layoutName: 'ambforms/layout-notswitchable',

		init: function init() {
			this._super();

			this.texts.submitValidationFailure = 'Znaleziono błędy w pliku';
			this.texts.buttonSubmitText = 'Wyślij';
		},

		afterUpdate: function afterUpdate(updatePromise) {
			var _this = this;
			updatePromise.then(function () {
				_this.get('currentController').set('showMsg', false);
			}, function (response) {
				_this.get('currentController').set('showMsg', true);
				_this.get('currentController').set('errors', response.errors);
			});
		},

		actions: {
			removeFileAction: function removeFileAction() {
				// var formDef = this.get('formDefinition');
				// var mapping = this.get('formDefinitionMapping');
				// var osView = formDef[mapping['sourceFile']].getFieldViewInstance();
				// osView.set('value', null);
				this.getFieldRow('sourceFile').set('value', null);
			}
		}
	});

	exports['default'] = UsersImportFormComponent;
});
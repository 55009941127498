define("ember-new/routes/b2b-contract/dzu/show-ee/vas", ["exports", "ember-new/routes/authenticated", "ember"], function (exports, _emberNewRoutesAuthenticated, _ember) {
    exports["default"] = _emberNewRoutesAuthenticated["default"].extend({

        applicationAlerts: _ember["default"].inject.service('application-alerts-manager'),
        responseErrorRedirectTo: 'b2b-contract.dzu.show-ee.client',
        responseErrorMessage: 'Nie masz uprawnień do wykonania tej akcji',

        model: function model() {
            var contract = this.modelFor('b2b-contract.dzu.show-ee');
            var _this = this;
            this.set('idContract', contract.get('id'));

            return _ember["default"].RSVP.hash({
                VasListToAddToContractViewModel: this.store.query('VasListToAddToContractViewModel', { contractId: contract.get('id') }, { reload: true })["catch"](function (error) {
                    _this.modelResponseHandler(error, _this, contract.get('id'));
                }),
                PpmVasViewModel: this.store.query('PpmVasViewModel', { contractId: contract.get('id') }, { reload: true })["catch"](function (error) {
                    _this.modelResponseHandler(error, _this, contract.get('id'));
                }),
                VasAssignedToContractListViewModel: this.store.query('VasAssignedToContractListViewModel', { id: contract.get('id') }, { reload: true })["catch"](function (error) {
                    _this.modelResponseHandler(error, _this, contract.get('id'));
                })
            });
        },

        modelResponseHandler: function modelResponseHandler(error, _this, contractId) {
            var i = 0;
            while (error.errors[i]) {
                if (error.errors[i].status == 403) {
                    _this.get('applicationAlerts').addActionMessage('warning', _this.get('responseErrorMessage'));
                    _this.transitionTo(_this.get('responseErrorRedirectTo', contractId));
                }
                i++;
            }
        },

        setupController: function setupController(controller, model) {
            controller.set('VasListToAddToContractViewModel', model.VasListToAddToContractViewModel);
            controller.set('PpmVasViewModel', model.PpmVasViewModel);
            controller.set('model', model.VasAssignedToContractListViewModel);
            controller.set('idContract', this.get('idContract'));
        },

        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            }
        }

    });
});
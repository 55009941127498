define("ember-new/components/d-form-command", ["exports", "ember", "ember-new/components/d-form", "ember-new/config/environment", "ember-new/templates/components/d-form"], function (exports, _ember, _emberNewComponentsDForm, _emberNewConfigEnvironment, _emberNewTemplatesComponentsDForm) {
	exports["default"] = _emberNewComponentsDForm["default"].extend({

		layout: _emberNewTemplatesComponentsDForm["default"],

		// additional options for command-d-form
		//_isCommandModel: true,

		// injected services

		// public options/properties
		commandName: null,
		sourceModel: null,
		createNewCommandModel: true,
		copyFromSourceModel: null,
		fillCommandModel: null,
		afterSuccessfulCommand: null,
		customUrl: null,

		submitSimpleUrl: (function () {
			if (this.get('customUrl')) {
				return _emberNewConfigEnvironment["default"].apiUrl + this.get('customUrl');
			}
			return _emberNewConfigEnvironment["default"].apiUrl + '/rest/' + this.get('commandName').toLowerCase() + 's';
		}).property('commandName', 'customUrl'),

		submitModelNamespace: (function () {
			return this.get('commandName');
		}).property('commandName'),

		/**
   * Extends base initialize method from d-form.
   * Add functionality of creating empty Ember.Object model for a command.
   * Copi
   */
		initialize: (function () {

			if (this.get('_devMode')) {
				console.log("INIT D-FORM-COMMAND");
			}

			var commandName = this.get('commandName');
			if (commandName === undefined || commandName === null || typeof commandName !== 'string') {
				throw new _ember["default"].Error('Component d-form-command requires `commandName` option.');
			} else if (commandName.length === 0) {
				throw new _ember["default"].Error('Option `commandName`  of d-form-command component can not be empty.');
			}

			this._initializeComponentProperties();

			if (this.get('createNewCommandModel') === true) {
				this._createCommandModel();
			}

			var fillCommandModelScope = this.get('fillCommandModelScope');

			if (fillCommandModelScope === undefined || fillCommandModelScope === null) {
				fillCommandModelScope = this.get('targetObject');
			}

			var fillCommandModel = this.get('fillCommandModel');

			if (typeof fillCommandModel === 'function') {
				fillCommandModel.call(fillCommandModelScope, this); // ctrl, form
			}
		}).on('init'),

		afterSuccessfulSubmit: function afterSuccessfulSubmit(response) {

			var afterSuccessfulCommandSkope = this.get('afterSuccessfulCommandSkope');

			if (afterSuccessfulCommandSkope === undefined || afterSuccessfulCommandSkope === null) {
				afterSuccessfulCommandSkope = this.get('targetObject');
			}

			var afterSuccessfulCommand = this.get('afterSuccessfulCommand');

			if (typeof afterSuccessfulCommand == 'function') {
				afterSuccessfulCommand.call(afterSuccessfulCommandSkope, this, response);
			}
		},

		/**
   * Setups a model for command
   * @private
   */
		_createCommandModel: function _createCommandModel() {

			var commandName = this.get('commandName');
			var model = _ember["default"].Object.create();
			this.set('model', model);
			this.set('_isModelBound', true);
		}

	});
});
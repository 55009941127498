define("ember-new/components/d-form/b2b-contract/dzu/add-ee-contract-ppe", ["exports", "ember-new/components/d-form-command", "ember-new/templates/components/d-form", "ember-new/mixins/b2b-contract/controllers/reload-contract-header", "ember-new/mixins/b2b-contract/components/d-form/tariffs/flatten-tariff-groups", "ember-new/mixins/b2b-contract/components/d-form/osd-section", "ember-new/mixins/b2b-contract/components/d-form/osd-os-observers-ee"], function (exports, _emberNewComponentsDFormCommand, _emberNewTemplatesComponentsDForm, _emberNewMixinsB2bContractControllersReloadContractHeader, _emberNewMixinsB2bContractComponentsDFormTariffsFlattenTariffGroups, _emberNewMixinsB2bContractComponentsDFormOsdSection, _emberNewMixinsB2bContractComponentsDFormOsdOsObserversEe) {
    exports["default"] = _emberNewComponentsDFormCommand["default"].extend(_emberNewMixinsB2bContractControllersReloadContractHeader["default"], _emberNewMixinsB2bContractComponentsDFormTariffsFlattenTariffGroups["default"], _emberNewMixinsB2bContractComponentsDFormOsdSection["default"], _emberNewMixinsB2bContractComponentsDFormOsdOsObserversEe["default"], {
        layout: _emberNewTemplatesComponentsDForm["default"],

        commandName: 'AddPpeToEeContractDzuCommand',

        loyaltyEndFieldValidation: (function () {

            var loyalty = undefined;
            var loyaltyEnd = undefined;
            var controller = undefined;

            controller = this.get('targetObject');
            loyalty = this.get('formData.loyalty');
            loyaltyEnd = this.get('formRows.loyaltyEnd');

            if (loyalty === "d676d2aa-3afe-4fcd-ac91-a3a80c80dd2c" && loyaltyEnd !== undefined && loyaltyEnd !== null && loyaltyEnd !== false && loyaltyEnd !== '') {
                controller.set('isLoyaltyEndFieldDisabled', false);
            } else if (loyalty !== "d676d2aa-3afe-4fcd-ac91-a3a80c80dd2c" && loyaltyEnd !== undefined && loyaltyEnd !== null && loyaltyEnd !== false && loyaltyEnd !== '') {
                controller.set('isLoyaltyEndFieldDisabled', true);
            }
        }).observes('formData.loyalty'),

        numberOfMonthsCounter: (function () {
            var form = this.get('formData');
            var startDate = null;
            var months = undefined;
            var endDate = new Date(form.get('endDate'));
            var controller = this.get('targetObject');
            var monthCounterType = controller.get('monthCounterType');
            var operation = undefined;

            if (form.get('startDate') !== undefined && form.get('startDate') !== null && form.get('startDate') !== false) {
                startDate = new Date(form.get('startDate'));
            }

            if (startDate && endDate && (monthCounterType === null || monthCounterType === 'onlyFullMonths')) {
                operation = startDate.getMonth() + 1;
            } else if (startDate && endDate && monthCounterType === 'firstOrLastMonthIncluded') {
                operation = startDate.getMonth();
            } else if (startDate && endDate && monthCounterType === 'firstAndLastMonthIncluded') {
                operation = startDate.getMonth() - 1;
            } else {}

            if (startDate && endDate) {
                months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
                months -= operation;
                months += endDate.getMonth();
                form.set('numberOfMonths', months);
            }
        }).observes('formData.endDate'),

        afterSuccessfulSubmit: function afterSuccessfulSubmit() {
            var targetController = this.get('targetObject');
            var mainController = targetController.container.lookup('controller:b2b-contract.dzu.show-ee.ppe');
            var mainRoute = mainController.get('target');
            this.reloadContractHeader();
            mainRoute.send('refreshModel');
            setTimeout(function () {
                targetController.transitionToRoute('b2b-contract.dzu.show-ee.ppe');
            }, 500);
        }
    });
});
/*
 * @author Paweł Roguski <pawel.roguski@fortum.com>
 */
define('ember-new/models/osd-dictionary-entry', ['exports', 'ember-new/models/abstract'], function (exports, _emberNewModelsAbstract) {

	var OsdDictionaryEntry = _emberNewModelsAbstract['default'].extend({
		label: DS.attr('string'),
		name: DS.attr('string'),
		isHidden: DS.attr('boolean'),
		uuid: DS.attr('string'),
		osUuids: DS.attr(),
		os: DS.attr(),
		usysExportId: DS.attr('string'),

		advicesAmountException: DS.attr('boolean'),
		allowedCharactersNumber: DS.attr(), // collection of strings
		ppeNumberStartString: DS.attr(), // collection of strings
		complexAllowedCharactersNumber: DS.attr(), // collection of strings
		complexPpeNumberStartString: DS.attr() });

	// collection of strings
	exports['default'] = OsdDictionaryEntry;
});
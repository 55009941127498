define("ember-new/templates/admin/anonymization-registry/partials/buttons/client-anonymize", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "modifiers",
          "modifiers": ["action"]
        },
        "revision": "Ember@2.3.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 102
          }
        },
        "moduleName": "ember-new/templates/admin/anonymization-registry/partials/buttons/client-anonymize.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("a");
        dom.setAttribute(el1, "class", "text-danger underline");
        var el2 = dom.createTextNode("Anonimizuj");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(1);
        morphs[0] = dom.createElementMorph(element0);
        return morphs;
      },
      statements: [["element", "action", ["anonymizeClient", ["get", "documentAction.options.id", ["loc", [null, [1, 30], [1, 55]]]]], [], ["loc", [null, [1, 3], [1, 57]]]]],
      locals: [],
      templates: []
    };
  })());
});
define('ember-new/mixins/routes/renouncement/anonymize', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Mixin.create({

        beforeModel: function beforeModel() {
            this.store.unloadAll('AnalyzeRenouncementBeforeAnonymizeViewModel');
        },

        model: function model(params) {
            return this.store.findRecord('AnalyzeRenouncementBeforeAnonymizeViewModel', params.renouncement_uuid, {
                reload: true
            });
        }
    });
});
define('ember-new/helpers/int-to-month', ['exports', 'ember'], function (exports, _ember) {
        var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

        exports['default'] = _ember['default'].Helper.helper(function (_ref) {
                var _ref2 = _slicedToArray(_ref, 1);

                var int = _ref2[0];

                switch (int) {
                        case 1:
                                return 'styczeń';
                        case 2:
                                return 'luty';
                        case 3:
                                return 'marzec';
                        case 4:
                                return 'kwiecień';
                        case 5:
                                return 'maj';
                        case 6:
                                return 'czerwiec';
                        case 7:
                                return 'lipiec';
                        case 8:
                                return 'sierpień';
                        case 9:
                                return 'wrzesień';
                        case 10:
                                return 'październik';
                        case 11:
                                return 'listopad';
                        case 12:
                                return 'grudzień';
                }
        });
});
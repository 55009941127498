define('ember-new/controllers/b2b-contract/dzu/show-gas/add-audio-recording', ['exports', 'ember', 'ember-new/mixins/b2b-contract/controllers/reload-contract-header'], function (exports, _ember, _emberNewMixinsB2bContractControllersReloadContractHeader) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsB2bContractControllersReloadContractHeader['default'], {

        fillForm: function fillForm(form) {
            form.set('model.contractUuid', this.get('idContract'));
            form.set('model.recordingUuid', null);
        },

        afterCommand: function afterCommand() {
            this.send('refreshModel');
            this.reloadContractHeader();
            this.transitionToRoute('b2b-contract.dzu.show-gas.documents');
        }

    });
});
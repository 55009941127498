define('ember-new/helpers/validity-period-transformer', ['exports', 'ember'], function (exports, _ember) {
  exports.validityPeriodTransformer = validityPeriodTransformer;

  function validityPeriodTransformer(period, options) {
    var toReturn = null;

    var validityPeriod = period[0];

    switch (validityPeriod) {
      case 'numberOfMonths':
        toReturn = 'Zdefiniowana liczba miesięcy';
        break;
      case 'entireContractPeriod':
        toReturn = 'Cała umowa';
        break;
      default:
        toReturn = ' - ';
        break;
    }

    return toReturn;
  }

  exports['default'] = _ember['default'].Helper.helper(validityPeriodTransformer);
});
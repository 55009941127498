define("ember-new/templates/components/bt-button", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 4
            },
            "end": {
              "line": 7,
              "column": 4
            }
          },
          "moduleName": "ember-new/templates/components/bt-button.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "btn-summary-amount");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("k");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["inline", "roundwithspaces", [["get", "typeFilter.mm", ["loc", [null, [6, 59], [6, 72]]]]], ["precision", 0, "multiplier", 1000], ["loc", [null, [6, 41], [6, 102]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "modifiers",
          "modifiers": ["action"]
        },
        "revision": "Ember@2.3.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 10,
            "column": 0
          }
        },
        "moduleName": "ember-new/templates/components/bt-button.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("a");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "btn-summary-content");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "btn-summary-badge");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(6);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createElementMorph(element0);
        morphs[2] = dom.createMorphAt(element0, 1, 1);
        morphs[3] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
        morphs[4] = dom.createMorphAt(dom.childAt(element0, [5]), 0, 0);
        morphs[5] = dom.createMorphAt(element0, 7, 7);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", [["subexpr", "if", [["get", "isActive", ["loc", [null, [1, 53], [1, 61]]]], "active"], [], ["loc", [null, [1, 47], [1, 72]]]], " btn btn-default"]]], ["element", "action", ["statusFilter", ["get", "typeFilter", ["loc", [null, [1, 27], [1, 37]]]]], [], ["loc", [null, [1, 3], [1, 39]]]], ["inline", "bs-tooltip", [], ["title", ["subexpr", "@mut", [["get", "typeFilter.name", ["loc", [null, [2, 23], [2, 38]]]]], [], []]], ["loc", [null, [2, 4], [2, 40]]]], ["content", "yield", ["loc", [null, [3, 38], [3, 47]]]], ["content", "typeFilter.sum", ["loc", [null, [4, 36], [4, 54]]]], ["block", "unless", [["get", "roleSalesDmt", ["loc", [null, [5, 14], [5, 26]]]]], [], 0, null, ["loc", [null, [5, 4], [7, 15]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});
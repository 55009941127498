define('ember-new/routes/b2b-contract/dzu/show-ee/contract', ['exports', 'ember-new/routes/authenticated', 'ember', 'ember-new/mixins/b2b-contract/routes/dzu-show-contract'], function (exports, _emberNewRoutesAuthenticated, _ember, _emberNewMixinsB2bContractRoutesDzuShowContract) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend(_emberNewMixinsB2bContractRoutesDzuShowContract['default'], {

        model: function model(params) {
            var contract = this.modelFor('b2b-contract.dzu.show-ee');

            var contractID = contract.get('id');
            this.set('idContract', contract.get('id'));

            this.store.unloadRecord(contract);

            var route = this;

            return _ember['default'].RSVP.hash({
                contract: route.get('store').findRecord('ContractContractDzuViewModel', contractID, { reload: true }),
                products: route.get('store').findAll('EeBusinessContractProductViewModel'),
                burdens: route.get('store').findRecord('BusinessSettingsViewModel', 'BusinessSettingsViewModel')
            });
        }

    });
});
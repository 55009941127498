define("ember-new/routes/b2b-contract/administration/section-pricelists/details", ["exports", "ember-new/routes/authenticated", "ember"], function (exports, _emberNewRoutesAuthenticated, _ember) {
    exports["default"] = _emberNewRoutesAuthenticated["default"].extend({

        model: function model(params) {

            return _ember["default"].RSVP.hash({
                basePricelist: this.store.findRecord('BasePriceSetListDzuViewModel', params.pricesetUuid),
                rows: this.store.query('BasePriceSetRowsListDzuViewModel', { pricesetUuid: params.pricesetUuid })
            });
        },

        setupController: function setupController(ctrl, model) {
            ctrl.set('model', model.basePricelist);
            ctrl.set('rows', model.rows);
        },

        actions: {
            refreshModelAction: function refreshModelAction() {
                this.refresh();
            }
        }

    });
});
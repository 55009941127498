define('ember-new/models/file', ['exports', 'ember-new/config/environment', 'ember-new/models/abstract', 'ember-data', 'ember-new/utils/utils', 'ember'], function (exports, _emberNewConfigEnvironment, _emberNewModelsAbstract, _emberData, _emberNewUtilsUtils, _ember) {

    var File = _emberNewModelsAbstract['default'].extend({

        cookies: _ember['default'].inject.service(),

        originalName: _emberData['default'].attr('string'),
        fileName: _emberData['default'].attr('string'),
        contentType: _emberData['default'].attr('string'),
        icon: _emberData['default'].attr('string'),
        size: _emberData['default'].attr('string'),
        path: _emberData['default'].attr('string'),
        mapping: _emberData['default'].attr('string'),
        isDraft: _emberData['default'].attr('boolean'),
        fileUrl: (function () {
            return _emberNewConfigEnvironment['default'].apiUrlIndex + '_getfile', '?', _emberNewConfigEnvironment['default'], _this.get('TheUser'), _this.get('cookies');
        }).property('id')

        // references
        //user:
    });

    exports['default'] = File;
});
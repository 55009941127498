define("ember-new/mixins/individual-contract/show/contract-comments-controller", ["exports", "ember", "ember-new/config/environment", "ember-new/mixins/contract/contract-comments-controller"], function (exports, _ember, _emberNewConfigEnvironment, _emberNewMixinsContractContractCommentsController) {
    exports["default"] = _ember["default"].Mixin.create(_emberNewMixinsContractContractCommentsController["default"], {

        visiblesFor: [{ value: "ROLE_DZU", label: "Do Doradcy" }, { value: "ROLE_DZU2", label: "Do DzU2" }],
        visibleFor: "ROLE_DZU",

        commentRecepientsOptions: (function () {
            var TheUser = this.get('TheUser');

            if (TheUser.isGranted(['ROLE_DE', 'ROLE_DG'])) {
                return [{ value: "SALES", label: "Do Doradcy" }, { value: "TELESALES", label: "Do Telesprzedaży" }, { value: "DZU", label: "Do DzU" }, { value: "DZU2", label: "Do DzU2" }];
            } else if (TheUser.isGranted(['ROLE_DZU'])) {
                return [{ value: "SALES", label: "Do Doradcy" }, { value: "TELESALES", label: "Do Telesprzedaży" }, { value: "DZU2", label: "Do DzU2" }, { value: "DS", label: "Do DS" }];
            } else if (TheUser.isGranted(['ROLE_DZU2'])) {
                return [{ value: "DZU", label: "Do DzU" }, { value: "DS", label: "Do DS" }];
            } else {
                return [];
            }
        }).property('TheUser'),
        commentsReceptientsValue: null,
        showCommentsReceptientsField: (function () {
            var TheUser = this.get('TheUser');

            if (TheUser.isGranted(['ROLE_DE', 'ROLE_DG', 'ROLE_DZU', 'ROLE_DZU2', 'ROLE_TELESALES'])) {
                return true;
            }

            return false;
        }).property('TheUser')

    });
});
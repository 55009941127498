define('ember-new/models/district-dictionary-entry', ['exports', 'ember-new/models/abstract'], function (exports, _emberNewModelsAbstract) {

    var DistrictDictionaryEntry = _emberNewModelsAbstract['default'].extend({
        label: DS.attr('string'),
        name: DS.attr('string'),
        isHidden: DS.attr('boolean'),
        uuid: DS.attr('string')
    });

    exports['default'] = DistrictDictionaryEntry;
});
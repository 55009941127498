define("ember-new/components/embed-individual-client", ["exports", "ember", "ember-data", "ember-new/models/abstract", "ember-new/mixins/models/individual-client"], function (exports, _ember, _emberData, _emberNewModelsAbstract, _emberNewMixinsModelsIndividualClient) {

    var attr = _emberData["default"].attr;
    var refOne = _emberData["default"].belongsTo;

    exports["default"] = _emberNewModelsAbstract["default"].extend({
        pesel: attr('string'),
        firstName: attr('string'),
        lastName: attr('string'),
        identificationDocumentType: refOne('identification-document-type', { inverse: null }),
        identificationDocumentNumber: attr('string'),
        phone: attr('string'),
        email: attr('string'),
        baseAddress: refOne('address', { embedded: true }),
        additionalAddress: refOne('address', { embedded: true }),

        peselSecondClient: attr('string'),
        firstNameSecondClient: attr('string'),
        lastNameSecondClient: attr('string'),
        phoneSecondClient: attr('string'),
        emailSecondClient: attr('string'),

        fullName: (function () {
            return this.get('firstName') + ' ' + this.get('lastName');
        }).property('firstName', 'lastName'),

        fullNameSecondClient: (function () {
            return this.get('firstNameSecondClient') + ' ' + this.get('lastNameSecondClient');
        }).property('firstNameSecondClient', 'lastNameSecondClient')
    });
});
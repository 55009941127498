define("ember-new/controllers/profile", ["exports", "ember-new/config/environment", "ember"], function (exports, _emberNewConfigEnvironment, _ember) {

  var ProfileController = _ember["default"].Controller.extend({

    fileId: null,
    customUrl: _emberNewConfigEnvironment["default"].apiUrl + '/_uploader/blogPostAttachment/upload',
    paramName: 'avatar'

  });

  exports["default"] = ProfileController;
});
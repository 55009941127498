define('ember-new/models/bulk-channel-reservation/client-content-row-view-model', ['exports', 'ember-new/models/abstract', 'ember-data'], function (exports, _emberNewModelsAbstract, _emberData) {

    var attr = _emberData['default'].attr;

    exports['default'] = _emberNewModelsAbstract['default'].extend({
        nip: attr('string'),
        status: attr('string'),
        message: attr('string'),
        isAllowProcess: attr('boolean'),
        eeReservation: attr(),
        gasReservation: attr(),
        isBlockedItem: attr('boolean')
    });
});
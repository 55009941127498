define("ember-new/routes/individual-contract-partners/edit-contract", ["exports", "ember", "ember-new/routes/authenticated", "ember-new/config/environment"], function (exports, _ember, _emberNewRoutesAuthenticated, _emberNewConfigEnvironment) {
    exports["default"] = _emberNewRoutesAuthenticated["default"].extend({
        model: function model(_ref) {
            var contractId = _ref.contractId;

            return this.store.findRecord('IndividualContractPartnerListItemViewModel', contractId).then(function (result) {
                return {
                    list: result
                };
            })["catch"](function () {
                return console.error('route model : error msg');
            });
        },

        setupController: function setupController(ctrl, _ref2) {
            var list = _ref2.list;

            ctrl.set('model', list);
        },

        resetController: function resetController(controller, isExiting, transition) {
            this._super.apply(this, arguments);

            if (isExiting) {
                controller.resetData();
            }
        },

        actions: {
            reload: function reload() {
                this.refresh();
            }
        }
    });
});
define('ember-new/models/gas-osd-dictionary-entry', ['exports', 'ember-new/models/abstract'], function (exports, _emberNewModelsAbstract) {

	var GasOsdDictionaryEntry = _emberNewModelsAbstract['default'].extend({
		label: DS.attr('string'),
		name: DS.attr('string'),
		tariffs: DS.hasMany('GasTariffGroupDictionaryEntry', { inverse: null }),
		isHidden: DS.attr('boolean'),
		uuid: DS.attr('string'),
		osUuids: DS.attr(),
		os: DS.attr(),
		usysExportId: DS.attr('string')
	});

	exports['default'] = GasOsdDictionaryEntry;
});
define('ember-new/components/entity-history-filters', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        filters: null,
        filtersNames: null,
        data: {},

        activatedFilters: [],

        setup: (function () {
            var _this = this;

            this.set('activatedFilters', []);
            var filters = this.get('filters');
            var ctrl = this.get('targetObject');
            Object.keys(filters).map(function (key) {
                if (ctrl.get(key) != null && ctrl.get(key) !== '' && ctrl.get(key) !== 'null' && ctrl.get(key) !== false && ctrl.get(key) !== 'false') {
                    _this.get('activatedFilters').push({ label: _this.get('filtersNames')[key], isBoolean: false });
                    _this.set('data.' + key, ctrl.get(key));
                } else {
                    _this.set('data.' + key, '');
                }
            });
        }).on('init'),

        actions: {
            resetFilters: function resetFilters() {
                var filters = this.get('filters');
                var ctrl = this.get('targetObject');
                Object.keys(filters).map(function (key) {
                    ctrl.set(key, '');
                });
            },

            applyFilters: function applyFilters() {
                var filters = this.get('filters');
                var data = this.get('data');
                var ctrl = this.get('targetObject');
                Object.keys(filters).map(function (key) {
                    if (data[key] != null && data[key] !== '' && data[key] !== 'null' && data[key] !== false && data[key] !== 'false') {
                        ctrl.set(key, data[key]);
                    } else {
                        ctrl.set(key, '');
                    }
                });
            }
        }
    });
});
define('ember-new/components/d-form/b2b-contract/administration/section-products/edit-product-to-contract', ['exports', 'ember-new/components/d-form-command', 'ember-new/mixins/b2b-contract/components/d-form/section-products/add-product-to-contract', 'ember'], function (exports, _emberNewComponentsDFormCommand, _emberNewMixinsB2bContractComponentsDFormSectionProductsAddProductToContract, _ember) {
    exports['default'] = _emberNewComponentsDFormCommand['default'].extend(_emberNewMixinsB2bContractComponentsDFormSectionProductsAddProductToContract['default'], {
        commandName: 'EditProductToContractCommand',

        isDefaultConditionProductObserver: (function () {
            if (this.get('formData') && this.get('formRows.isDefaultConditionProduct')) {
                if (this.get('formData.isDefaultConditionProduct')) {
                    this.set('formRows.defaultConditionProductName.hidden', false);
                } else {
                    this.set('formRows.defaultConditionProductName.hidden', true);
                }
            }
        }).observes('formData.isDefaultConditionProduct'),

        everyIndexObserver: _ember['default'].observer('formData.everyIndexAvailableInConditions', function () {
            var _this = this;

            var controller = this.get('targetObject');
            var valueEveryIndex = this.get('formData.everyIndexAvailableInConditions');
            var valueMedium = this.get('formData.medium');

            if (this.get('formData')) {
                if (this.get('formData.allowedConditionProduct')) {
                    var existingData = this.get('formData.allowedConditionProduct');
                    if (valueMedium === 'ee' && valueEveryIndex !== true) {
                        controller.set('filteredProducts', controller.get('eeProducts'));
                        controller.set('filteredDefaultProducts', controller.get('filteredEeProducts'));
                    } else if (valueMedium === 'ee' && valueEveryIndex === true) {
                        (function () {
                            var ctrlDataFiltered = controller.get('filteredEeProducts').map(function (a) {
                                return a.id;
                            });
                            var intersection = existingData.filter(function (element) {
                                return ctrlDataFiltered.includes(element);
                            });
                            _this.set('formData.allowedConditionProduct', intersection);
                            controller.set('filteredProducts', controller.get('filteredEeProducts'));
                            controller.set('filteredDefaultProducts', controller.get('eeProducts'));
                        })();
                    } else if (valueMedium === 'gas' && valueEveryIndex === true) {
                        (function () {
                            var ctrlDataFiltered = controller.get('filteredGasProducts').map(function (a) {
                                return a.id;
                            });
                            var intersection = existingData.filter(function (element) {
                                return ctrlDataFiltered.includes(element);
                            });
                            _this.set('formData.allowedConditionProduct', intersection);
                            controller.set('filteredProducts', controller.get('filteredGasProducts'));
                            controller.set('filteredDefaultProducts', controller.get('gasProducts'));
                        })();
                    } else if (valueMedium === 'gas' && valueEveryIndex !== true) {
                        controller.set('filteredProducts', controller.get('gasProducts'));
                        controller.set('filteredDefaultProducts', controller.get('filteredGasProducts'));
                    }
                } else {
                    if (valueMedium === 'ee' && valueEveryIndex !== true) {
                        controller.set('filteredProducts', controller.get('eeProducts'));
                        controller.set('filteredDefaultProducts', controller.get('filteredEeProducts'));
                    } else if (valueMedium === 'ee' && valueEveryIndex === true) {
                        controller.set('filteredProducts', controller.get('filteredEeProducts'));
                        controller.set('filteredDefaultProducts', controller.get('eeProducts'));
                    }
                }

                if (valueMedium === 'gas' && valueEveryIndex !== true) {
                    controller.set('filteredProducts', controller.get('gasProducts'));
                    controller.set('filteredDefaultProducts', controller.get('filteredGasProducts'));
                } else if (valueMedium === 'gas' && valueEveryIndex === true) {
                    controller.set('filteredProducts', controller.get('filteredGasProducts'));
                    controller.set('filteredDefaultProducts', controller.get('gasProducts'));
                }
            }
        })
    });
});
define('ember-new/mixins/b2b-contract/components/d-form/prices/ee-price', ['exports', 'ember', 'ember-new/templates/components/d-form'], function (exports, _ember, _emberNewTemplatesComponentsDForm) {
  exports['default'] = _ember['default'].Mixin.create({
    layout: _emberNewTemplatesComponentsDForm['default'],

    store: _ember['default'].inject.service('store'),

    periodTypeHider: (function () {
      if (this.get('formRows.periodType')) {
        if (this.get('formData.periodType') == 'year') {
          this.set('formRows.year.hidden', false);
          this.set('formRows.month.hidden', true);
          this.set('formRows.startMonth.hidden', true);
          this.set('formRows.endMonth.hidden', true);
          this.set('formRows.startYear.hidden', true);
          this.set('formRows.endYear.hidden', true);
        } else if (this.get('formData.periodType') == 'month') {
          this.set('formRows.year.hidden', false);
          this.set('formRows.month.hidden', false);
          this.set('formRows.startMonth.hidden', true);
          this.set('formRows.endMonth.hidden', true);
          this.set('formRows.startYear.hidden', true);
          this.set('formRows.endYear.hidden', true);
        } else if (this.get('formData.periodType') == 'months_range') {
          this.set('formRows.year.hidden', true);
          this.set('formRows.month.hidden', true);
          this.set('formRows.startMonth.hidden', false);
          this.set('formRows.endMonth.hidden', false);
          this.set('formRows.startYear.hidden', false);
          this.set('formRows.endYear.hidden', false);
        } else {
          if (this.get('formRows.year')) {
            this.set('formRows.year.hidden', true);
          }
          if (this.get('formRows.month')) {
            this.set('formRows.month.hidden', true);
          }
          if (this.get('formRows.startMonth')) {
            this.set('formRows.startMonth.hidden', true);
          }
          if (this.get('formRows.endMonth')) {
            this.set('formRows.endMonth.hidden', true);
          }
          if (this.get('formRows.startYear')) {
            this.set('formRows.startYear.hidden', true);
          }
          if (this.get('formRows.endYear')) {
            this.set('formRows.endYear.hidden', true);
          }
        }
      } else {
        if (this.get('formRows.year')) {
          this.set('formRows.year.hidden', true);
        }
        if (this.get('formRows.month')) {
          this.set('formRows.month.hidden', true);
        }
        if (this.get('formRows.startMonth')) {
          this.set('formRows.startMonth.hidden', true);
        }
        if (this.get('formRows.endMonth')) {
          this.set('formRows.endMonth.hidden', true);
        }
        if (this.get('formRows.startYear')) {
          this.set('formRows.startYear.hidden', true);
        }
        if (this.get('formRows.endYear')) {
          this.set('formRows.endYear.hidden', true);
        }
      }
    }).observes('formData.periodType'),

    productHider: (function () {
      var selectedProduct = this.store.peekRecord('eePriceConditionProductViewModel', this.get('formData.productId'));

      if (selectedProduct) {
        var periodType = selectedProduct.get('periodType');
        var requiresBasePrices = selectedProduct.get('requiresBasePrices');

        this.set('formRows.overheadArea1.hidden', true);
        this.set('formRows.overheadArea2.hidden', true);
        this.set('formRows.overheadArea3.hidden', true);

        if (periodType == 'contract_price') {
          this.set('formRows.fee.hidden', false);
          this.set('formRows.baseFee.hidden', true);
          this.set('formRows.valueArea1.hidden', false);
          this.set('formRows.valueArea2.hidden', false);
          this.set('formRows.valueArea3.hidden', false);
          this.set('formRows.baseValueArea1.hidden', true);
          this.set('formRows.baseValueArea2.hidden', true);
          this.set('formRows.baseValueArea3.hidden', true);
          this.set('formRows.valueArea1.label', 'Cena na umowie strefa 1 [zł/MWh]');
          this.set('formRows.valueArea2.label', 'Cena na umowie strefa 2 [zł/MWh]');
          this.set('formRows.valueArea3.label', 'Cena na umowie strefa 3 [zł/MWh]');
        } else if (periodType == 'contract_price_with_base') {
          this.set('formRows.fee.hidden', false);
          this.set('formRows.baseFee.hidden', false);
          this.set('formRows.valueArea1.hidden', false);
          this.set('formRows.valueArea2.hidden', false);
          this.set('formRows.valueArea3.hidden', false);
          this.set('formRows.baseValueArea1.hidden', false);
          this.set('formRows.baseValueArea2.hidden', false);
          this.set('formRows.baseValueArea3.hidden', false);
          this.set('formRows.valueArea1.label', 'Cena na umowie strefa 1 [zł/MWh]');
          this.set('formRows.valueArea2.label', 'Cena na umowie strefa 2 [zł/MWh]');
          this.set('formRows.valueArea3.label', 'Cena na umowie strefa 3 [zł/MWh]');
          this.set('formRows.baseValueArea1.label', 'Cena bazowa strefa 1 [zł/MWh]');
          this.set('formRows.baseValueArea2.label', 'Cena bazowa strefa 2 [zł/MWh]');
          this.set('formRows.baseValueArea3.label', 'Cena bazowa strefa 3 [zł/MWh]');
        } else if (periodType == 'index_ee') {
          this.set('formRows.fee.hidden', false);
          this.set('formRows.baseFee.hidden', false);
          this.set('formRows.valueArea1.hidden', false);
          this.set('formRows.valueArea2.hidden', false);
          this.set('formRows.valueArea3.hidden', false);
          this.set('formRows.baseValueArea1.hidden', false);
          this.set('formRows.baseValueArea2.hidden', false);
          this.set('formRows.baseValueArea3.hidden', false);
          this.set('formRows.valueArea1.label', 'M strefa 1 [%]');
          this.set('formRows.valueArea2.label', 'M strefa 2 [%]');
          this.set('formRows.valueArea3.label', 'M strefa 3 [%]');
          this.set('formRows.baseValueArea1.label', 'M bazowe strefa 1 [%]');
          this.set('formRows.baseValueArea2.label', 'M bazowe strefa 2 [%]');
          this.set('formRows.baseValueArea3.label', 'M bazowe strefa 3 [%]');
        } else if (periodType == 'overhead') {
          this.set('formRows.valueArea1.hidden', true);
          this.set('formRows.valueArea2.hidden', true);
          this.set('formRows.valueArea3.hidden', true);
          this.set('formRows.baseValueArea1.hidden', true);
          this.set('formRows.baseValueArea2.hidden', true);
          this.set('formRows.baseValueArea3.hidden', true);
          this.set('formRows.overheadArea1.hidden', false);
          this.set('formRows.overheadArea2.hidden', false);
          this.set('formRows.overheadArea3.hidden', false);
          this.set('formRows.fee.hidden', false);
          this.set('formRows.baseFee.hidden', false);
        } else {
          if (this.get('formRows.valueArea1')) {
            this.set('formRows.valueArea1.hidden', true);
          }
          if (this.get('formRows.valueArea2')) {
            this.set('formRows.valueArea2.hidden', true);
          }
          if (this.get('formRows.valueArea3')) {
            this.set('formRows.valueArea3.hidden', true);
          }
          if (this.get('formRows.baseValueArea1')) {
            this.set('formRows.baseValueArea1.hidden', true);
          }
          if (this.get('formRows.baseValueArea2')) {
            this.set('formRows.baseValueArea2.hidden', true);
          }
          if (this.get('formRows.baseValueArea3')) {
            this.set('formRows.baseValueArea3.hidden', true);
          }
          if (this.get('formRows.fee')) {
            this.set('formRows.fee.hidden', false);
          }
          if (this.get('formRows.baseFee')) {
            this.set('formRows.baseFee.hidden', false);
          }
        }

        console.log('requiresBasePrices', requiresBasePrices);

        var productId = this.get('formData.productId');
        var basePriceListsPromise = this.get('store').query('BasePriceByPriceConditionViewModel', { productId: productId });

        if (this.get('formData') && this.get('formRows.productId')) {
          var controller = this.get('targetObject');
          controller.set('basePrices', basePriceListsPromise);
          this.set('formData.basePrices', null);
        }

        if (this.get('formRows.baseFee') && requiresBasePrices) {
          this.set('formRows.baseFee.hidden', true);
        } else if (this.get('formRows.baseFee') && !requiresBasePrices) {
          this.set('formRows.baseFee.hidden', false);
        }

        if (selectedProduct.get('autoBasePrices') === true) {
          var _this = this;
          var contractId = this.get('targetObject.contractId');
          this.get('store').query('BaseAutoPriceByPriceConditionViewModel', { productId: productId, contractId: contractId }).then(function (product) {
            _this.set('formData.basePriceId', product.content[0].id);
          });
        }

        this.get('formRows.basePriceId.field').disabledState(!selectedProduct.get('priceSetSelectVisible'));
      } else {
        if (this.get('formRows.valueArea1')) {
          this.set('formRows.valueArea1.hidden', true);
        }
        if (this.get('formRows.valueArea2')) {
          this.set('formRows.valueArea2.hidden', true);
        }
        if (this.get('formRows.valueArea3')) {
          this.set('formRows.valueArea3.hidden', true);
        }
        if (this.get('formRows.baseValueArea1')) {
          this.set('formRows.baseValueArea1.hidden', true);
        }
        if (this.get('formRows.baseValueArea2')) {
          this.set('formRows.baseValueArea2.hidden', true);
        }
        if (this.get('formRows.baseValueArea3')) {
          this.set('formRows.baseValueArea3.hidden', true);
        }
        if (this.get('formRows.fee')) {
          this.set('formRows.fee.hidden', true);
        }
        if (this.get('formRows.baseFee')) {
          this.set('formRows.baseFee.hidden', true);
        }
      }
    }).observes('formData.productId')

  });
});
define('ember-new/models/individual-contract-bo-comments-view-model', ['exports', 'ember-new/models/abstract', 'ember-data'], function (exports, _emberNewModelsAbstract, _emberData) {
    var attr = _emberData['default'].attr;
    exports['default'] = _emberNewModelsAbstract['default'].extend({
        uuid: attr('string'),
        type: attr('string'),
        originalDateCreated: attr('string'),
        createdBy: attr('string'),
        content: attr('string')

    });
});
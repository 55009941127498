define('ember-new/components/task-move', ['exports', 'ember-new/components/amb-forms', 'ember-new/form/row/007-date-time-field-row', 'ember-new/form/row/gas-overhead-select-field-row', 'ember-new/form/row/000-field-row', 'ember-new/views/field/textarea-field', 'ember'], function (exports, _emberNewComponentsAmbForms, _emberNewFormRow007DateTimeFieldRow, _emberNewFormRowGasOverheadSelectFieldRow, _emberNewFormRow000FieldRow, _emberNewViewsFieldTextareaField, _ember) {
	exports['default'] = _emberNewComponentsAmbForms['default'].extend({
		formDefinition: [_emberNewFormRow007DateTimeFieldRow['default'].create({ key: "time", label: "Nowa data" }), _emberNewFormRow000FieldRow['default'].create({ key: "comment", label: "Uwagi", fieldView: _emberNewViewsFieldTextareaField['default'], isBoundToModel: false, bindToMeta: true })],
		action: 'move',
		hasViewState: false,
		layoutName: 'ambforms/layout-notswitchable',
		formName: 'task',

		isFieldVisible: function isFieldVisible(key) {
			if (key !== 'duration') {
				return true;
			} else if (key === 'duration' && this.get('model').get('type') != 'meeting') {
				return false;
			} else {
				return true;
			}
		},

		isMeeting: false,
		modelChanged: (function () {
			if (this.get('model').get('type') == 'meeting') {
				this.set('isMeeting', true);
			} else {
				this.set('isMeeting', false);
			}
		}).observes('model'),

		afterUpdate: function afterUpdate(promise) {
			var _this = this;
			var ctrl = _this.get('targetObject');
			promise.then(function () {
				ctrl.get('model').reload();
				alert('Zaktualizowano datę zadania.');
				ctrl.transitionToRoute('tasks');
				ctrl.get('target').send('refreshContractItemModel', ctrl.get('model.id'));
			});
		}
	});
});
define("ember-new/routes/individual-contract-partners/bulk-selected/mark-as-sent", ["exports", "ember-new/routes/authenticated"], function (exports, _emberNewRoutesAuthenticated) {
    exports["default"] = _emberNewRoutesAuthenticated["default"].extend({
        resetController: function resetController(controller, isExiting, transition) {
            this._super.apply(this, arguments);

            if (isExiting) {
                controller.resetData(); // or whatever function you want to call
            }
        }
    });
});
define("ember-new/mixins/models/individual-ee-gas-contract", ["exports", "ember-data", "ember"], function (exports, _emberData, _ember) {

			var attr = _emberData["default"].attr;
			var refOne = _emberData["default"].belongsTo;
			var refMany = _emberData["default"].hasMany;

			exports["default"] = _ember["default"].Mixin.create({

						client: refOne('IndividualClient', { inverse: null }),

						isInDualFuel: attr('boolean'),
						secondContract: attr(),

						variant: refOne('IndividualContractAdditionalVariant', { inverse: null }),
						variantPikaText: attr('string'),

						category: refOne('IndividualContractCategory', { inverse: null }),
						signingPlace: refOne('SigningPlace', { inverse: null }),
						contractNo: attr('string'),

						dateReceived: attr('string'),
						dateSigned: attr('string'),
						dateStart: attr('string'),
						dateEnd: attr('string'),
						numberOfMonths: attr('number'),

						paymentTerm: refOne('PaymentTermDictionaryEntry', { inverse: null }),
						productUuid: attr('string'),
						productLabel: attr('string'),
						tariff: attr(), // ref definition in child,
						newTariffGroupUuid: attr('string'),
						tariffGroupLabel: attr('string'),
						buildingArea: attr('number'),

						basePricesList: attr(), // jeszcze nie wiadomo

						dzuSpecialist: refOne('User', { inverse: null }),
						tpaSpecialist: refOne('User', { inverse: null }),
						partner: refOne('DuonPartnerDictionaryEntry', { inverse: null }),
						partnerAcquired: refOne('DuonPartnerDictionaryEntry', { inverse: null }),
						newDzuSpecialist: _emberData["default"].attr('string'),
						newTpaSpecialist: _emberData["default"].attr('string'),
						newSalesman: _emberData["default"].attr('string'),
						dzuSpecialistInfo: attr(),
						tpaSpecialistInfo: attr(),
						salesmanInfo: attr(),

						agreementInfoProcessing: _emberData["default"].attr('nullable-boolean'),
						agreementSendingCommercials: _emberData["default"].attr('nullable-boolean'),
						agreementReceiveCommercialsElectronically: _emberData["default"].attr('nullable-boolean'),
						agreementPhoneContactAndSms: _emberData["default"].attr('nullable-boolean'),
						eInvoice: attr('boolean'),
						eInvoiceEmail: attr('string'),
						verificationCallStatus: refOne('VerificationCallStatus', { inverse: null }),
						verificationCallType: refOne('VerificationCallType', { inverse: null }),
						vcRecordingStatus: attr('boolean'),

						fileDocumentContract: refOne('Upload', { inverse: null }),
						fileDocumentProxy: refOne('Upload', { inverse: null }),
						fileDocumentInvoices: refOne('Upload', { inverse: null }),
						fileDocumentDataUpdateRequest: refOne('Upload', { inverse: null }),
						fileDocumentChangeTariffRequest: refOne('Upload', { inverse: null }),
						fileDocumentOthers: refOne('Upload', { inverse: null }),
						fileDocumentProxySubstitution: refOne('Upload', { inverse: null }),
						fileDocumentCession: refOne('Upload', { inverse: null }),
						fileDocumentLawTitleToPremises: refOne('Upload', { inverse: null }),
						fileDocumentNewDevice: refOne('Upload', { inverse: null }),
						fileDocumentForm: refOne('Upload', { inverse: null }),
						fileDocumentRegulation: refOne('Upload', { inverse: null }),
						fileDocumentProxyReserve: refOne('Upload', { inverse: null }),

						fileDocumentContract2: refOne('Upload', { inverse: null }),
						fileDocumentProxy2: refOne('Upload', { inverse: null }),
						fileDocumentInvoices2: refOne('Upload', { inverse: null }),
						fileDocumentDataUpdateRequest2: refOne('Upload', { inverse: null }),
						fileDocumentChangeTariffRequest2: refOne('Upload', { inverse: null }),
						fileDocumentOthers2: refOne('Upload', { inverse: null }),
						fileDocumentProxySubstitution2: refOne('Upload', { inverse: null }),
						fileDocumentCession2: refOne('Upload', { inverse: null }),
						fileDocumentLawTitleToPremises2: refOne('Upload', { inverse: null }),
						fileDocumentNewDevice2: refOne('Upload', { inverse: null }),
						fileDocumentForm2: refOne('Upload', { inverse: null }),
						fileDocumentRegulation2: refOne('Upload', { inverse: null }),
						fileDocumentProxyReserve2: refOne('Upload', { inverse: null }),

						fileDocumentContract3: refOne('Upload', { inverse: null }),
						fileDocumentProxy3: refOne('Upload', { inverse: null }),
						fileDocumentInvoices3: refOne('Upload', { inverse: null }),
						fileDocumentDataUpdateRequest3: refOne('Upload', { inverse: null }),
						fileDocumentChangeTariffRequest3: refOne('Upload', { inverse: null }),
						fileDocumentOthers3: refOne('Upload', { inverse: null }),
						fileDocumentProxySubstitution3: refOne('Upload', { inverse: null }),
						fileDocumentCession3: refOne('Upload', { inverse: null }),
						fileDocumentLawTitleToPremises3: refOne('Upload', { inverse: null }),
						fileDocumentNewDevice3: refOne('Upload', { inverse: null }),
						fileDocumentForm3: refOne('Upload', { inverse: null }),
						fileDocumentRegulation3: refOne('Upload', { inverse: null }),
						fileDocumentProxyReserve3: refOne('Upload', { inverse: null }),
						fileAutenti: refOne('Upload', { inverse: null }),
						fileAutenti2: refOne('Upload', { inverse: null }),
						fileAutenti3: refOne('Upload', { inverse: null }),

						fileAudio1: refOne('Upload', { inverse: null }),
						fileAudio2: refOne('Upload', { inverse: null }),
						fileAudio3: refOne('Upload', { inverse: null }),

						partnerFile1: refOne('Upload', { inverse: null }),
						partnerFile2: refOne('Upload', { inverse: null }),
						partnerFile3: refOne('Upload', { inverse: null }),
						partnerFile4: refOne('Upload', { inverse: null }),
						partnerFile5: refOne('Upload', { inverse: null }),
						vcRecording: refOne('Upload', { inverse: null }),
						vcRecordingUuid: attr('string'),

						validationStatus: attr(),

						credentials: attr(),
						renouncement: attr('string'),
						renouncementInfo: attr(),
						counter: attr('string'),
						kindOfBulbs: refOne('SchLightBulb', { inverse: null }),
						kindOfBulbsPikaText: attr('string'),
						bulbsAmountE14: attr('number'),
						bulbsAmountE27: attr('number'),
						bulbsAmountE14ToSend: attr('number'),
						bulbsAmountE27ToSend: attr('number'),
						bulbsSent: attr('boolean'),
						pikaReasonOfIncomplete: attr('string'),
						pikaReasonOfIncompleteDescription: attr('string'),
						pikaReasonOfIncompleteType: attr('string'),

						counterClass: (function () {
									if (this.get('counter') == '--') {
												return '';
									}
									return this.get('counter') == '>17' ? ' label label-success' : ' label label-danger';
						}).property('counter'),

						statusTimeClass: (function () {
									var now = moment();
									var date = this.get('status.time');
									return now.diff(date, 'days') < 14 ? '' : ' label label-danger';
						}).property('status.time'),

						isEe: (function () {
									return this.get('type') === 'ee';
						}).property('type'),
						isGas: (function () {
									return this.get('type') === 'gas';
						}).property('type'),

						dateVerification: attr('string'),
						verificationStatus: attr('number'),
						dateDocumentsCollect: attr('string'),

						verificationStatusLabel: (function () {
									if (!this.get('verificationStatus')) {
												return '';
									}
									var status = this.get('verificationStatus').toString();
									switch (status) {
												case '1':
															return 'Kompletna';
												case '2':
															return 'Niekompletna';
												case '3':
															return 'Anulowana';
												default:
															return '';
									}
						}).property('verificationStatus'),

						vcInfo: attr('string'),
						clientSatisfaction: attr('number'),

						eeProduct: refOne('IndividualEeProductLine', { inverse: null }),
						gasProduct: refOne('IndividualGasProductLine', { inverse: null }),
						productLinePikaText: attr('string'),

						partnerName: attr('string'),
						creatorName: attr('string'),
						ppmAddress: refOne('address', { embedeed: true }),

						product: refOne('IndividualProduct', { inverse: null }),
						productPikaText: attr('string'),

						dateUsysGeneration: attr('string'),
						uuid: attr('string'),

						canBeAssignedRenouncement: (function () {
									return this.get('renouncement') === null;
						}).property('renouncement'),

						isAnonymized: attr('boolean'),

						isAllowedToAnonymized: (function () {
									return !this.get('isAnonymized') && this.get("TheUser").isGranted(['ROLE_GDPR_SPECIALIST']);
						}).property('isAllowedToAnonymized')

			});
});
define('ember-new/mixins/controllers/individual-contract/add-ee/set-complex', ['exports', 'ember', 'ember-new/utils/ajax/get'], function (exports, _ember, _emberNewUtilsAjaxGet) {
    exports['default'] = _ember['default'].Mixin.create({

        redirectBaseRoute: 'add',

        actions: {
            submit: function submit() {
                this.transitionToRoute('individual-contract.' + this.get('redirectBaseRoute') + '-ee');
            }
        }
    });
});
define('ember-new/controllers/tasks/clients-add-businessclient', ['exports', 'ember-new/config/environment', 'ember-new/controllers/tasks/clients-add', 'ember-new/models/representative', 'ember'], function (exports, _emberNewConfigEnvironment, _emberNewControllersTasksClientsAdd, _emberNewModelsRepresentative, _ember) {

    var ClientsAddBusinessclientController = _emberNewControllersTasksClientsAdd['default'].extend({
        actions: {
            addRepresentative: function addRepresentative() {
                reps = this.get('model').get('representatives');
                reps.pushObject(_emberNewModelsRepresentative['default'].create());
            },
            removeRepresentative: function removeRepresentative(representative) {
                reps = this.get('model').get('representatives');
                reps.removeObject(representative);
            },
            validate: function validate() {

                this.set('cerrors', null);

                this.get('model').get('representatives').forEach(function (item) {
                    item.set('errors', undefined);
                });

                var _this = this;

                this.model.save().then(function (response) {
                    _this.transitionToRoute('clients', _this.model.get('id'));
                }, function (response) {
                    _this.set('cerrors', _ember['default'].Object.create(response.responseJSON.errors.children));

                    if (response.responseJSON.errors.children.representatives.children) {
                        for (var i = 0; i < response.responseJSON.errors.children.representatives.children.length; i++) {
                            _this.get('model').get('representatives.' + i).set('errors', _ember['default'].Object.create(response.responseJSON.errors.children.representatives.children[i]));
                        }
                    }
                });
            }
        }
    });

    exports['default'] = ClientsAddBusinessclientController;
});
define('ember-new/mixins/individual-contract/show/client', ['exports', 'ember', 'ember-new/config/environment', 'ember-new/mixins/controllers/helpers/form-processing-controller', 'ember-new/mixins/controllers/unsaved-data'], function (exports, _ember, _emberNewConfigEnvironment, _emberNewMixinsControllersHelpersFormProcessingController, _emberNewMixinsControllersUnsavedData) {
    exports['default'] = _ember['default'].Mixin.create(_emberNewMixinsControllersHelpersFormProcessingController['default'], _emberNewMixinsControllersUnsavedData['default'], {

        applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),

        type: null,
        personsEditState: false,

        onModelChanged: (function () {
            var model = this.get('model');

            if (!model || !model._internalModel) {
                return;
            }
            var modelName = model._internalModel.modelName;

            if (modelName == 'individual-ee-contract') {
                this.set('type', 'ee');
            } else if (modelName == 'individual-gas-contract') {
                this.set('type', 'gas');
            } else {
                throw new _ember['default'].Error('Modelname: \'' + modelName + '\' not recognized');
            }
        }).observes('model'),

        name: 'individual-contract',
        activeMenuItem: (function () {
            return this.get('name') + '.show.client';
        }).property('name'),

        baseAddress: (function () {
            if (this.get('model.client.baseAddress')) {
                return this.get('model.client.baseAddress');
            } else {
                return this.store.createRecord('address');
            }
        }).property('model'),
        additionalAddress: (function () {
            if (this.get('model.client.additionalAddress')) {
                return this.get('model.client.additionalAddress');
            } else {
                return this.store.createRecord('address');
            }
        }).property('model'),

        isEditable: (function () {
            var isAnonymized = this.get('model.isAnonymized');
            return !isAnonymized;
        }).property('model')

    });
});
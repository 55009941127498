define('ember-new/components/d-field-select-dictionary', ['exports', 'ember', 'ember-new/components/d-field-select'], function (exports, _ember, _emberNewComponentsDFieldSelect) {
  exports['default'] = _emberNewComponentsDFieldSelect['default'].extend({

    dictionariesManager: _ember['default'].inject.service('dictionaries-manager'),
    optionLabelPath: "name",
    optionValuePath: "content",

    // requred options:
    dictionaryName: null,

    initialize: (function () {
      var dictionaryType = this.get('dictionaryName');

      if (dictionaryType === undefined || dictionaryType === null || typeof dictionaryType !== 'string') {
        throw new _ember['default'].Error('Component d-field-select-dictionary requires `dictionaryName` option.');
      }

      var dicManager = this.get('dictionariesManager');

      if (!dicManager.hasMainDictionary(dictionaryType)) {
        throw new _ember['default'].Error("Option 'dictionaryName` passed to d-field-select-dictionary component is wrong. There is no `" + dictionaryType + "` main-dictionary.");
      }

      var content = dicManager.getMainDictionary(dictionaryType);

      content = content.filter(function (item) {
        return item.get('isHidden') !== true;
      });

      if (typeof this.customActionBeforeSetContent == 'function') {
        content = this.customActionBeforeSetContent.call(this, content);
      }

      this.set('content', content);
    }).on('init'),

    actions: {
      setSelection: function setSelection(event) {
        var value = event.target.value;
        var component = this;
        this.get('content').forEach(function (item) {
          if (item.toString() === value) {
            component.set('value', item);
          }
        });
      }
    }

  });
});
define('ember-new/models/product-dictionary-entry', ['exports', 'ember-new/models/abstract'], function (exports, _emberNewModelsAbstract) {

    var ProductDictionaryEntry = _emberNewModelsAbstract['default'].extend({
        label: DS.attr('string'),
        name: DS.attr('string'),
        contractTypes: DS.hasMany('ContractTypeDictionaryEntry', { inverse: null }),
        isHidden: DS.attr('boolean'),
        isMMVisibleForSalesmen: DS.attr('boolean')
    });

    exports['default'] = ProductDictionaryEntry;
});
define('ember-new/utils/handle-key-event', ['exports', 'ember', 'ember-new/fixtures/key-map'], function (exports, _ember, _emberNewFixturesKeyMap) {
  exports['default'] = handleKeyEvent;
  var hasListeners = _ember['default'].hasListeners;

  // Transforms jquery events' `keyup` and `keydown` into Ember conventional `keyUp` and `keyDown`.
  var normalizeEventType = function normalizeEventType(key, event) {
    return 'key' + _ember['default'].String.capitalize(event.type.replace('key', '')) + ':' + key;
  };

  // Since app devs might define their modifier keys in any order (eg. `keyUp:shift+ctrl+a` or
  // `keyUp:ctrl+shift+a`), we must check for each variant until we find the correct one.
  var gatherEventNameVariants = function gatherEventNameVariants(event, keys, eventName) {
    if (keys.length === 0) {
      return [eventName];
    }

    return keys.reduce(function (variants, key) {
      var modifiedEventName = !eventName ? normalizeEventType(key, event) : eventName + '+' + key;
      var remainingKeys = keys.filter(function (keyName) {
        return keyName !== key;
      });

      return variants.pushObjects(gatherEventNameVariants(event, remainingKeys, modifiedEventName));
    }, _ember['default'].A());
  };

  // Check if any modifier keys are being held down.
  var gatherKeys = function gatherKeys(event) {
    var key = event.key || _emberNewFixturesKeyMap['default'][event.keyCode];

    return ['ctrl', 'meta', 'alt', 'shift'].reduce(function (keys, keyName) {
      if (event[keyName + 'Key']) {
        keys.pushObject(keyName);
      }

      return keys;
    }, _ember['default'].A([key]));
  };

  function handleKeyEvent(event, responderStack) {
    var triggeredVariant = undefined;
    var keys = gatherKeys(event);
    var variants = gatherEventNameVariants(event, keys);

    // Finds the first responder with a registered listener for one of the variants
    var responder = responderStack.find(function (responder) {
      return triggeredVariant = variants.find(function (variant) {
        return hasListeners(responder, variant);
      });
    });

    if (responder) {
      responder.trigger(triggeredVariant);
    }
  }
});
define('ember-new/controllers/b2b-contract/dzu/usys', ['exports', 'ember', 'ember-new/mixins/controllers/helpers/form-processing-controller', 'ember-new/config/environment', 'ember-new/utils/utils'], function (exports, _ember, _emberNewMixinsControllersHelpersFormProcessingController, _emberNewConfigEnvironment, _emberNewUtilsUtils) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsControllersHelpersFormProcessingController['default'], {

        reset: (function () {
            this.set('savingInProgress', false);
            this.set('cerrors', []);
            this.set('warn', []);
        }).observes('model'),

        ajax: _ember['default'].inject.service(),
        cookies: _ember['default'].inject.service(),

        actions: {
            generate: function generate() {

                this.set('cerrors', []);
                this.set('warn', []);

                var _this = this;

                if (!this.beforeSubmit()) {
                    return;
                }

                var contractId = this.get('model.contractId');

                var ajaxPromise = this.get('ajax').ajaxGet(_emberNewConfigEnvironment['default'].apiUrl + '/rest/generateusyspacks/' + contractId);

                ajaxPromise.then(function (response) {
                    _this.set('savingInProgress', false);
                    _this.handleSuccess(response);
                    if (response.errors) {
                        _this.set('cerrors', response.errors);
                    }
                    if (response.warnings) {
                        _this.set('warn', response.warnings);
                    }
                    if (!response.errors) {
                        window.location = _emberNewUtilsUtils['default'].generateSimpleUrl('/api/business-contract-usys/download/' + contractId, '?', _emberNewConfigEnvironment['default'], _this.get('TheUser'), _this.get('cookies'));
                    }
                }, function (response) {
                    _this.set('savingInProgress', false);
                });
            }
        }

    });
});
define('ember-new/controllers/admin', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        displayDicTab: (function () {
            return this.get('TheUser').isGranted(['ROLE_ADMIN']);
        }).property('TheUser.model'),

        displayRegistryTab: (function () {
            return this.get('TheUser').isGranted(['ROLE_ADMIN']) || this.get('TheUser').isGranted(['ROLE_GDPR_SPECIALIST']);
        }).property('TheUser.model')

    });
});
define('ember-new/routes/b2b-contract/dzu/show-gas/prices/add', ['exports', 'ember-new/routes/authenticated'], function (exports, _emberNewRoutesAuthenticated) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({

        applicationAlerts: Ember.inject.service('application-alerts-manager'),

        beforeModel: function beforeModel(transition) {
            if (!this.modelFor('b2b-contract.dzu.show-gas').get('productId')) {
                this.get('applicationAlerts').addActionMessage('danger', 'Produkt w umowie nie ustawiony, nie można dodawać grup cenowych.');
                transition.abort();
            }
        },

        model: function model(params) {

            this.store.unloadAll('AddGasPriceSetViewModel');
            var contract = this.modelFor('b2b-contract.dzu.show-gas');

            return this.store.query('AddGasPriceSetViewModel', { contract_id: contract.get('id') });
        },

        setupController: function setupController(ctrl, model) {
            ctrl.set('contractId', this.modelFor('b2b-contract.dzu.show-gas').get('id'));
            ctrl.set('model', model);
        }

    });
});
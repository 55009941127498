define("ember-new/routes/b2b-contract/dzu/change-status", ["exports", "ember-new/routes/authenticated", "ember-new/config/environment"], function (exports, _emberNewRoutesAuthenticated, _emberNewConfigEnvironment) {
    exports["default"] = _emberNewRoutesAuthenticated["default"].extend({

        model: function model(params) {
            return this.store.findRecord('ContractContractDzuViewModel', params.contractId);
        },

        setupController: function setupController(controller, model) {
            controller.set('model', model);
        }

    });
});
define('ember-new/controllers/tasks/search', ['exports', 'ember-new/config/environment', 'ember'], function (exports, _emberNewConfigEnvironment, _ember) {

	// kontroller do wyboru nowy klient / istniejacy klient ~KJ
	exports['default'] = _ember['default'].Controller.extend({

		ajax: _ember['default'].inject.service('ajax'),
		searchingInProgress: false,
		nip: null,
		filteredNip: (function () {
			if (this.get('nip')) {
				return this.get('nip').trim();
			}
			return '';
		}).property('nip'),

		msgClass: 'alert alert-success hover-danger',
		msgs: [],
		showAddClient: false,
		showAddTask: false,
		showAddReservation: false,
		showReservationBox: false,
		managerEmail: false,
		emailSubject: false,
		emailContent: false,
		salesMan: false,
		linkedMessage: false,
		cid: null,
		blockedButton: false,
		addMsg: function addMsg(type, msg, addClient, addTask, addReservation, cid, showReservationBox) {
			this.get('msgs').pushObject(_ember['default'].Object.create({
				msgClass: 'alert alert-' + type + ' hover-highlight',
				msg: msg
			}));

			if (addClient === true) {
				this.set('showAddClient', true);
			}
			if (addTask === true) {
				this.set('cid', cid);
				this.set('showAddTask', true);
			}
			if (addReservation === true) {
				this.set('cid', cid);
				this.set('showAddReservation', true);
			}
			if (showReservationBox === true) {
				this.set('cid', cid);
				this.set('showReservationBox', true);
			}
		},
		clearMsg: function clearMsg() {
			this.set('msgs', []);
			this.set('showAddClient', false);
			this.set('showAddTask', false);
			this.set('showAddReservation', false);
			this.set('showReservationBox', false);
		},
		resetCtrl: function resetCtrl() {
			this.clearMsg();
			this.set('cid', null);
			this.set('showAddClient', false);
			this.set('showAddTask', false);
			this.set('showAddReservation', false);
			this.set('showRequestEe', false);
			this.set('showRequestGas', false);
			this.set('msgs', []);
			this.set('nip', null);
		},
		handleSearchResponse: function handleSearchResponse(res) {
			console.log('res,', res);
			if (res[0]) {
				this.set('cid', res[0].id);
			}
			this.set('searchingInProgress', false);

			for (var i = 0; i < res.length; i++) {

				console.log('a');

				var body_line = encodeURI('\r\n'),
				    medium = res[i].medium,
				    regionName = res[i].region,
				    adminMode = res[i].admin;

				if (regionName) {
					regionName = regionName.toUpperCase();
				}

				if (medium) {
					medium = medium.toUpperCase();
				}

				var email_message = null;
				this.set('managerEmail', res[i].manager_email);
				this.set('salesMan', res[i].user_full_name);
				this.set('emailSubject', 'Próba rezerwacji mediów na numerze NIP: ' + res[i].nip + '. Doradca: ' + res[i].user_full_name + '.');

				// TODO: zrefaktorowac to to bo wygląda tragicznie :) ~lyczos
				switch (res[i].status) {

					// Jeżeli klient nie istnieje w CRMie, który wywołał żądanie
					case 'not_exists':
						this.addMsg('success', 'Klient nie występuje w systemie. Możesz go dodać.', true, false, false);
						break;
					case 'not_exists_all_reserved_other_crm':
						this.addMsg('danger', 'Klient nie występuje w systemie, jednak posiada rezerwacje na wszystkie media w innej sieci sprzedaży.', false, false, false);
						break;
					case 'not_exists_ee_reserved_other_crm':
						this.addMsg('success', 'Klient nie występuje w systemie. Możesz go dodać.', true, false, false);
						this.addMsg('danger', 'Klient posiada rezerwacje na EE w innej sieci sprzedaży.', true, false, false);
						break;
					case 'not_exists_gas_reserved_other_crm':
						this.addMsg('success', 'Klient nie występuje w systemie. Możesz go dodać.', true, false, false);
						this.addMsg('danger', 'Klient posiada rezerwacje na GAZ w innej sieci sprzedaży.', true, false, false);
						break;

					case 'allow_reserve':
						this.addMsg('success', 'Klient ma wolne rezerwacje na ' + medium + '. Możesz ją dodać.', false, false, true, res[i].id);
						break;
					case 'allow_add_task':
						this.addMsg('success', 'Możesz dodać zadanie do tego klienta na ' + medium, false, true, false, res[i].id);
						break;
					case 'this_crm_reservation':
						this.addMsg('danger', 'NIP ' + res[i].nip + ' jest zablokowany na medium ' + medium + ' w regionie ' + regionName + '. Skontaktuj się ze swoim Managerem. Kliknij w komunikat, aby wysłać do niego automatyczne powiadomienie oraz w razie potrzeby, dodać swoje uwagi.', false, false, false, res[i].id);
						this.set('emailContent', 'Twój doradca ' + res[i].user_full_name + ', próbował dokonać rezerwacji na numerze NIP ' + res[i].nip + '.' + body_line + 'Niestety na tym numerze istenieje rezerwacja na następujące media: EE, w regionie ' + regionName + '. Twoim zadaniem jest skontaktować się z Managerem wskazanego regionu i ustalić czy zdejmie tę blokadę NIPa poprzez wyszukanie Zadania, kliknięcie w Kłódkę i wybranie "Usuń rezerwację". Zadania na ten NIP zostaną zamknięte.' + body_line + body_line + 'Uwagi doradcy:' + body_line);
						this.set('linkedMessage', true);
						break;
					case 'this_crm_reservation_access_granted':
						this.addMsg('warning', 'NIP ' + res[i].nip + ' jest zablokowany na medium ' + medium + ' przez ' + res[i].reservationFor + ', rezerwacja do ' + res[i].reservationTo + ' przedłużana ' + res[i].reservationAmount + ' razy.', false, true, false, res[i].id, true);
						break;
					case 'this_crm_channel_reservation':
						this.addMsg('danger', 'NIP ' + res[i].nip + ' jest zarezerwowany na: ' + medium + ', skontaktuj się z Administracją Sprzedaży.', false, false, false, res[i].id);
						break;
					case 'this_crm_channel_reservation_access_granted':
						this.addMsg('warning', 'NIP ' + res[i].nip + ' jest zarezerwowany na: ' + medium + ', na kanał ' + res[i].channel + '.', false, false, false, res[i].id, true);
						break;
					case 'other_crm_reservation':
						this.addMsg('danger', 'NIP ' + res[i].nip + ' jest zablokowany przez inną sieć, na nastepujące media: ' + medium + '. Skontaktuj się ze swoim Managerem. Kliknij w komunikat, aby wysłać do niego automatyczne powiadomienie oraz w razie potrzeby, dodać swoje uwagi.', false, false, false, res[i].id);
						this.set('emailContent', 'Twój doradca ' + res[i].user_full_name + ', próbował dokonać rezerwacji na numerze NIP ' + res[i].nip + '.' + body_line + 'Niestety na tym numerze istenieje rezerwacja na ' + medium + ' w innej sieci sprzedaży. Twoim zadaniem jest skontaktować się z Sales Polska i ustalić czy zdejmą tę blokadę NIPa poprzez wyszukanie Zadania, kliknięcie w Kłódkę i wybranie "Usuń rezerwację".' + body_line + body_line + 'Uwagi doradcy:' + body_line);
						this.set('linkedMessage', true);
						break;
					case 'other_crm_channel_reservation':
						this.addMsg('danger', 'NIP ' + res[i].nip + ' jest zarezerwowany na: ' + medium + ', skontaktuj się z Administracją Sprzedaży.', false, false, false, res[i].id);
						break;
					case 'error_checking_other_crm':
						this.addMsg('danger', 'Błąd podczas sprawdzania rezerwacji w drugim CRMie.', false, false, false, res[i].id);
				}

				this.set('blockedButton', false);
			}
		},

		handleNipError: function handleNipError(err) {
			this.addMsg('danger', 'NIP jest niepoprawny');
			this.set('blockedButton', false);
			this.set('searchingInProgress', false);
		},
		actions: {
			searchAction: function searchAction() {
				var _this = this;

				if (this.get('searchingInProgress') === true) {
					return;
				}
				this.set('searchingInProgress', true);
				this.clearMsg();
				this.set('blockedButton', true);
				var nip = this.get('nip');

				if (!nip) {
					this.addMsg('danger', 'Podaj nip');
					this.set('blockedButton', false);
					return false;
				}

				this.get('ajax').ajaxGet(_emberNewConfigEnvironment['default'].apiUrl + '/ajax/amb_client/search_clients?nip=' + nip).then(function (res) {
					return _this.handleSearchResponse(res);
				}, function (err) {
					return _this.handleNipError(err);
				});
			},

			automaticSearchAction: function automaticSearchAction(value) {
				this.get('searchingInProgress');
				if (!value) return;

				var nip = value.replace(/\D/g, ''); // remove all excpet number (fix for NIP like: 727-27-20-111)
				// console.log('nip', nip)
				if (nip.length === 10) {
					this.set('nip', nip); //
					this.send('searchAction');
				}
			}
		}
	});
});
define("ember-new/models/individual-ppe", ["exports", "ember", "ember-data", "ember-new/models/abstract", "ember-new/models/base-statusable-document-mixin", "ember-new/mixins/models/individual-ppm"], function (exports, _ember, _emberData, _emberNewModelsAbstract, _emberNewModelsBaseStatusableDocumentMixin, _emberNewMixinsModelsIndividualPpm) {

    var attr = _emberData["default"].attr;
    var refOne = _emberData["default"].belongsTo;
    var refMany = _emberData["default"].hasMany;

    exports["default"] = _emberNewModelsAbstract["default"].extend(_emberNewModelsBaseStatusableDocumentMixin["default"], _emberNewMixinsModelsIndividualPpm["default"], {

        contract: refOne('individual-ee-contract'),

        tariff: refOne('individual-contract-ee-tariff-group', { inverse: null }),

        os: refOne('os-dictionary-entry', { inverse: null }),
        osd: refOne('osd-dictionary-entry', { inverse: null }),

        distributionRate: refOne('EeDistributionRate', { inverse: null }),

        priceOplataPrzejsciowa: attr('number'),
        priceOplataAbonamentowa: attr('number'),
        priceOplataSieciowa: attr('number'),

        oplataPrzejsciowa: refOne('OplataPrzejsciowa', { inverse: null }),
        oplataAbonamentowa: refOne('OplataAbonamentowa', { inverse: null }),
        oplataSieciowa: refOne('OplataSieciowa', { inverse: null }),

        yearConsumptionHT: attr('number'),
        yearConsumptionLT: attr('number')
    });
});
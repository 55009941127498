define("ember-new/components/d-form/individual-contract-price-lists/condition/add", ["exports", "ember-new/components/d-form-command", "ember-new/templates/components/d-form"], function (exports, _emberNewComponentsDFormCommand, _emberNewTemplatesComponentsDForm) {
    exports["default"] = _emberNewComponentsDFormCommand["default"].extend({
        layout: _emberNewTemplatesComponentsDForm["default"],

        year: 'Y',
        month: 'M',
        monthsRange: 'MP',
        openCondition: 'MPO',

        afterSuccessfulSubmit: function afterSuccessfulSubmit() {
            var targetController = this.get('targetObject');
            var mainRoute = targetController.container.lookup("route:individual-contract-price-lists.template.conditions");

            mainRoute.send('refreshModelAction');

            setTimeout(function () {
                targetController.transitionToRoute('individual-contract-price-lists.template.conditions');
            }, 100);
        },

        periodTypeObserver: (function () {
            var formDataPeriodType = this.get('formData.periodType');

            var formRowYearFrom = this.get('formRows.yearFrom');
            var formRowMonthFrom = this.get('formRows.monthFrom');
            var formRowYearTo = this.get('formRows.yearTo');
            var formRowMonthTo = this.get('formRows.monthTo');

            var year = this.get('year');
            var month = this.get('month');
            var monthRange = this.get('monthsRange');
            var openCondition = this.get('openCondition');

            if (formRowYearFrom && formRowMonthFrom && formRowYearTo && formRowMonthTo) {

                var yearFromIsDisable = formDataPeriodType === month || formDataPeriodType === monthRange || formDataPeriodType === openCondition || formDataPeriodType === year;
                var monthFromIsDisable = formDataPeriodType === month || formDataPeriodType === monthRange || formDataPeriodType === openCondition;
                var yearToIsDisable = formDataPeriodType === monthRange;
                var monthToIsDisable = formDataPeriodType === monthRange;

                formRowYearFrom.set('hidden', !yearFromIsDisable);
                formRowMonthFrom.set('hidden', !monthFromIsDisable);
                formRowYearTo.set('hidden', !yearToIsDisable);
                formRowMonthTo.set('hidden', !monthToIsDisable);
            }
        }).observes('formData.periodType')

    });
});
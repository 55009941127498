define('ember-new/controllers/b2b-contract/dzu/show-ee/ppe/add', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        isLoyaltyEndFieldDisabled: true,

        correctionRatioFieldValues: null,

        osdNull: true,
        contractTerminating: true,
        disableSellerChange: true,
        disableOs: true,
        disableFlattening: true,

        /* available options: firstOrLastMonthIncluded, firstAndLastMonthIncluded, onlyFullMonths */
        monthCounterType: 'firstOrLastMonthIncluded',

        actions: {
            copyAddressFromClient: function copyAddressFromClient() {

                var model = this.get('model');

                var address = {
                    street: this.get('model.street'),
                    streetNo: this.get('model.streetNo'),
                    aptNo: this.get('model.aptNo'),
                    postalCode: this.get('model.postalCode'),
                    postalCity: this.get('model.postalCity'),
                    city: this.get('model.city'),
                    district: this.get('model.districtUuid')
                };

                var clientAddress = _ember['default'].Object.create(address);

                model.set('clientAddress', address);
            }
        },

        loyaltyContent: [{ label: 'Lojalka - data znana', value: '34f0d211-f3f0-4783-8506-bcaade547dbc' }, { label: 'Brak lojalki', value: 'a45aebb5-9c31-4c41-b130-c91bbaccb6b4' }],

        switchValues: [{ label: 'tak', value: true }, { label: 'nie', value: false }],

        fillForm: function fillForm(form) {
            form.set('model.uuidContract', this.get('contractId'));
            form.set('model.aptNo', null);
            form.set('model.businessEeTariffGroup', null);
            form.set('model.city', null);
            form.set('model.consumptionMethodCalculation', null);
            form.set('model.correctionRatio', null);
            form.set('model.pointNumberForWinback', null);
            form.set('model.deliveredComprehensiveAgreement', null);
            form.set('model.deviceNumber', null);
            form.set('model.dismissPeriod', null);
            form.set('model.district', null);
            form.set('model.isContractTerminating', true);
            form.set('model.isNewConnection', null);
            form.set('model.loyalty', 'a45aebb5-9c31-4c41-b130-c91bbaccb6b4');
            form.set('model.loyaltyEnd', null);
            form.set('model.noticePeriodTime', null);
            form.set('model.os', null);
            form.set('model.osd', null);
            form.set('model.osdTariffGroup', null);
            form.set('model.pgnigTariffGroup', null);
            form.set('model.pointNumber', null);
            form.set('model.postalCode', null);
            form.set('model.postalCity', null);
            form.set('model.registrationNumber', null);
            form.set('model.reserveAgreement', null);
            form.set('model.startDate', null);
            form.set('model.street', null);
            form.set('model.streetNo', null);
            form.set('model.consumptionCalculationMethod', null);
            form.set('model.flattenTariffGroup', null);
            form.set('model.isCustomerCession', null);
            form.set('model.isDataUpdate', null);
            form.set('model.isTariffGroupChanged', null);
            form.set('model.osdSection', null);
            form.set('model.sellerChange', null);
            form.set('model.settlementsFrequencyFromOsdContract', null);
            form.set('model.tariffGroup', null);
            form.set('model.declaredYearConsumption', null);
        },

        loyaltyStatusDate: (function () {
            if (this.get('loyaltyStatus') != '34f0d211-f3f0-4783-8506-bcaade547dbc') {
                return true;
            } else {
                return false;
            }
        }).property('loyaltyStatus')

    });
});
define('ember-new/components/individual-contract-partners-list', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        selectAllChecked: false,

        actions: {
            handleCheck: function handleCheck(item) {
                this.get('handleCheck')(item);
            },
            handleToggleSelectAll: function handleToggleSelectAll() {
                this.set('selectAllChecked', !this.get('selectAllChecked'));
                this.get('handleToggleSelectAll')(this.get('selectAllChecked'));
            },
            showHistory: function showHistory(item) {
                this.get('showHistory')(item);
            },
            editContract: function editContract(item) {
                // wywolujemy metode z controllera na danej umowie
                this.get('editContract')(item);
            },
            undoContractCancel: function undoContractCancel(item) {
                this.get('undoContractCancel')(item);
            },
            addFiles: function addFiles(item) {
                this.get('addFiles')(item);
            },
            setContractCorrected: function setContractCorrected(item) {
                this.get('setContractCorrected')(item);
            },
            resetDetailedFilters: function resetDetailedFilters() {
                var controller = this.get('targetObject');
                var filters = controller.get('filters');

                Object.keys(filters).forEach(function (key) {
                    controller.set('filters.' + key, null);
                    controller.set(key, null);
                });
            }
        }
    });
});
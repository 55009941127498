define('ember-new/components/d-field-datetime-new', ['exports', 'ember', 'ember-new/components/d-field-datetime'], function (exports, _ember, _emberNewComponentsDFieldDatetime) {
    exports['default'] = _emberNewComponentsDFieldDatetime['default'].extend({
        format: 'YYYY-MM-DD HH:mm',
        outputFormat: 'YYYY-MM-DDTHH:mmTZ',
        sideBySide: true,
        outputType: 'string', // what bind to value? moment object or string
        inlineCancelIcon: true, // czy wysietlac "X" obok wartosci pola z data (w jednej linii)
        autoFocus: false
    });
});

// import FieldMixin from 'ember-new/mixins/d-form/d-field';
define("ember-new/utils/validators", ["exports"], function (exports) {
    /**
     *
     * @type {{}}
     */
    var Validators = {

        /**
         * Walidator do numeru PESEL.
         * @param pesel numer pesel
         * @returns {*}
         */
        pesel: function pesel(_pesel) {
            var reg = /^[0-9]{11}$/;
            if (reg.test(_pesel) === false) {
                throw "invalid_pesel_length";
            }

            var month = parseInt(_pesel.substring(2, 4));

            if (month >= 60) {
                month = month - 60;
            } else if (month >= 40) {
                month = month - 40;
            } else if (month >= 20) {
                month = month - 20;
            }

            if (parseInt(_pesel.substring(4, 6)) > 31 || month > 12) {
                throw "invalid_date_in_pesel";
            }

            var result = this._validatePeselHash.call(this, _pesel);

            if (!result) {
                throw "invalid_pesel_number";
            }

            return result;
        },

        /**
         * Walidator dla numeru umowy EE
         * @param number
         * @returns {boolean}
         */
        eeContractNumber: function eeContractNumber(number) {
            var reg = /^[a-zA-Z]{2}\d{2}\d{8}(EE)$/;
            return reg.test(number);
        },

        /**
         * Walidator dla numeru umowy GAZ
         * @param number
         * @returns {boolean}
         */
        gasContractNumber: function gasContractNumber(number) {
            var reg = /^[a-zA-Z]{2}\d{2}\d{8}(PG)$/;
            return reg.test(number);
        },

        /**
         * Walidator dla numeru umowy (bez suffixu EE/PG)
         * @param number
         * @returns {boolean}
         */
        contractNumber: function contractNumber(number) {
            var reg = /^[a-zA-Z]{2}\d{2}\d{8}$/;
            return reg.test(number);
        },

        oldContractNumber: function oldContractNumber(number) {
            var reg = /^IND-\d{1,6}-\w*-\d{0,12}-\d{4}$/;
            return reg.test(number);
        },

        bothContractNumbersEe: function bothContractNumbersEe(number) {
            if (this.oldContractNumber(number) || this.eeContractNumber(number)) {
                return true;
            }
            return false;
        },

        bothContractNumbersGas: function bothContractNumbersGas(number) {
            if (this.oldContractNumber(number) || this.gasContractNumber(number)) {
                return true;
            }
            return false;
        },

        _validatePeselHash: function _validatePeselHash(pesel) {
            // wagi
            // 1-3-7-9-1-3-7-9-1-3.
            var weights = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
            var digits = ("" + pesel).split("");

            var sum = 0;
            for (var i = 0; i < weights.length; i++) {
                sum += parseInt(digits[i]) * weights[i];
            }

            var checksum = sum % 10;
            if (checksum === 0) checksum = 10;

            checksum = 10 - checksum;

            return parseInt(digits[10]) === checksum;
        },

        minimumLength3: function minimumLength3(string) {
            return string.length >= 3;
        },

        notBlank: function notBlank(string) {
            if (!string) {
                return false;
            }
            return string.length >= 1;
        },

        positiveNumber: function positiveNumber(number) {
            if (number) {
                return number > 0;
            }
        }

    };
    exports["default"] = Validators;
});
define("ember-new/routes/tasks/tasks-client/reservation", ["exports", "ember-new/routes/authenticated", "ember-new/config/environment", "ember"], function (exports, _emberNewRoutesAuthenticated, _emberNewConfigEnvironment, _ember) {
	exports["default"] = _emberNewRoutesAuthenticated["default"].extend({
		ajax: _ember["default"].inject.service('ajax'),
		keyboard: _ember["default"].inject.service(),

		model: function model() {
			var client = this.modelFor('tasks.tasks-client');
			return this.get('ajax').ajaxGet(_emberNewConfigEnvironment["default"].apiUrl + '/ajax/client/get_meta/' + client.get('id'));
		},

		setupController: function setupController(ctrl, model) {
			var client = this.modelFor('tasks.tasks-client');
			this._super(ctrl, client);
			ctrl.set('clientMeta', model);
			ctrl.set('salesmen', model.salesmen);
		},

		closeModal: _ember["default"].on('keyUp:Escape', function () {
			this.controller.transitionToRoute("tasks");
		}),

		actions: {
			didTransition: function didTransition() {
				this.controller.reset();
				this.get('keyboard').activate(this);

				return true;
			},
			willTransition: function willTransition() {
				this._super.apply(this, arguments);
				this.get('keyboard').deactivate(this);
			}
		}
	});
});
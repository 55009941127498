define('ember-new/mixins/models/individual-business-rate', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {

    var attr = _emberData['default'].attr;
    var refOne = _emberData['default'].belongsTo;
    var refMany = _emberData['default'].hasMany;

    exports['default'] = _ember['default'].Mixin.create({
        name: attr('string'),
        einvoice: attr('boolean'),
        variant: refOne('individual-contract-additional-variant'),
        hasManualPrices: attr('boolean')
    });
});
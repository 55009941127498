define('ember-new/controllers/abstract-task-modal', ['exports', 'ember-new/config/environment', 'ember'], function (exports, _emberNewConfigEnvironment, _ember) {
	exports['default'] = _ember['default'].Controller.extend({
		tasksController: _ember['default'].inject.controller('tasks'),
		getMetaForTaskPatch: function getMetaForTaskPatch(idProcess) {
			var tasksCtrl = tasksController;
			return {
				'processTasksIds': tasksCtrl.getLoadedSimplifiedTasksIdsForProcess(this.get('model.acquisitionProcess.id'))
			};
		}
	});
});
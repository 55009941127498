define('ember-new/mixins/controllers/unsaved-data', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Mixin.create({

        lockedElements: [],

        addLock: function addLock(element) {
            return;
            if (!element) {
                element = this;
            }
            console.log('addLock', element);
            var elementStr = String(element);
            this.lockedElements[elementStr] = true;
        },

        removeLock: function removeLock(element) {

            if (!element) {
                element = this;
            }
            console.log('remLock', element);
            var elementStr = String(element);
            this.lockedElements[elementStr] = false;
        },

        removeAllLocks: function removeAllLocks() {
            console.log('remAllLocks', this.toString());
            this.lockedElements = {};
        },

        isLocked: function isLocked(element) {
            if (!element) {
                element = this;
            }

            var elementStr = String(element);
            if (!this.lockedElements.hasOwnProperty(elementStr)) {
                return false;
            }
            return this.lockedElements[elementStr];
        },

        hasLock: function hasLock(element) {
            return this.isLocked(element);
        },

        hasAnyLock: function hasAnyLock() {

            for (var key in this.lockedElements) {
                if (this.lockedElements.hasOwnProperty(key) && this.lockedElements[key] == true) {
                    return true;
                }
            }

            return false;
        }

    });
});
define("ember-new/templates/components/select-box-dictionary", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 4
            },
            "end": {
              "line": 12,
              "column": 4
            }
          },
          "moduleName": "ember-new/templates/components/select-box-dictionary.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "select-box__selected-label");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "select-box-get", [["get", "_selected", ["loc", [null, [10, 29], [10, 38]]]], ["get", "optionLabelKey", ["loc", [null, [10, 39], [10, 53]]]]], [], ["loc", [null, [10, 12], [10, 55]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 15,
                "column": 8
              },
              "end": {
                "line": 26,
                "column": 8
              }
            },
            "moduleName": "ember-new/templates/components/select-box-dictionary.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "select-box__selected-pill");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "select-box__selected-pill-text");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "select-box__selected-pill-clear");
            var el3 = dom.createTextNode("\n          ×\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1]);
            var element5 = dom.childAt(element4, [3]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(element4, [1]), 1, 1);
            morphs[1] = dom.createElementMorph(element5);
            return morphs;
          },
          statements: [["inline", "select-box-get", [["get", "selectedOption", ["loc", [null, [18, 29], [18, 43]]]], ["get", "optionLabelKey", ["loc", [null, [18, 44], [18, 58]]]]], [], ["loc", [null, [18, 12], [18, 60]]]], ["element", "action", ["removeOption", ["get", "selectedOption", ["loc", [null, [22, 36], [22, 50]]]]], ["bubbles", false], ["loc", [null, [22, 12], [22, 66]]]]],
          locals: ["selectedOption"],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 14,
              "column": 4
            },
            "end": {
              "line": 27,
              "column": 4
            }
          },
          "moduleName": "ember-new/templates/components/select-box-dictionary.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "each", [["get", "_selected", ["loc", [null, [15, 16], [15, 25]]]]], [], 0, null, ["loc", [null, [15, 8], [26, 17]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 29,
              "column": 4
            },
            "end": {
              "line": 31,
              "column": 4
            }
          },
          "moduleName": "ember-new/templates/components/select-box-dictionary.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "select-box__prompt");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["content", "prompt", ["loc", [null, [30, 41], [30, 51]]]]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 36,
                "column": 8
              },
              "end": {
                "line": 46,
                "column": 8
              }
            },
            "moduleName": "ember-new/templates/components/select-box-dictionary.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "select-box__search");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element2);
            morphs[1] = dom.createMorphAt(element2, 1, 1);
            return morphs;
          },
          statements: [["element", "action", ["noop"], ["bubbles", false], ["loc", [null, [37, 44], [37, 75]]]], ["inline", "input", [], ["class", "select-box__input", "type", "text", "placeholder", ["subexpr", "@mut", [["get", "searchPrompt", ["loc", [null, [41, 36], [41, 48]]]]], [], []], "key-up", "updateSearch", "tabindex", -1, "bubbles", false], ["loc", [null, [38, 16], [44, 39]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 48,
                "column": 8
              },
              "end": {
                "line": 58,
                "column": 8
              }
            },
            "moduleName": "ember-new/templates/components/select-box-dictionary.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "select-box__clear");
            dom.setAttribute(el1, "tabindex", "-1");
            dom.setAttribute(el1, "data-enter-key-action", "clear");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "select-box__icon select-box__icon--clear");
            var el3 = dom.createTextNode("\n          ×\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element1);
            morphs[1] = dom.createMorphAt(element1, 3, 3);
            return morphs;
          },
          statements: [["element", "action", ["clear"], ["bubbles", false], ["loc", [null, [52, 16], [52, 48]]]], ["content", "clearLabel", ["loc", [null, [56, 16], [56, 30]]]]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 60,
                "column": 8
              },
              "end": {
                "line": 67,
                "column": 8
              }
            },
            "moduleName": "ember-new/templates/components/select-box-dictionary.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "select-box__add-new");
            dom.setAttribute(el1, "tabindex", "-1");
            dom.setAttribute(el1, "data-enter-key-action", "addNew");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("em");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createElementMorph(element0);
            morphs[1] = dom.createMorphAt(element0, 1, 1);
            morphs[2] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
            return morphs;
          },
          statements: [["element", "action", ["addNew"], ["bubbles", false], ["loc", [null, [64, 16], [64, 49]]]], ["content", "addLabel", ["loc", [null, [65, 16], [65, 28]]]], ["content", "_searchText", ["loc", [null, [65, 33], [65, 48]]]]],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 69,
                "column": 8
              },
              "end": {
                "line": 73,
                "column": 8
              }
            },
            "moduleName": "ember-new/templates/components/select-box-dictionary.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "select-box__info");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["content", "noResultsMessage", ["loc", [null, [71, 16], [71, 36]]]]],
          locals: [],
          templates: []
        };
      })();
      var child4 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 74,
                "column": 8
              },
              "end": {
                "line": 107,
                "column": 8
              }
            },
            "moduleName": "ember-new/templates/components/select-box-dictionary.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "select-box__info");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment(" Loading SVG by Sam Herbert (@sherb), More @ http://goo.gl/7AJzbL ");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.setNamespace("http://www.w3.org/2000/svg");
            var el2 = dom.createElement("svg");
            dom.setAttribute(el2, "width", "12");
            dom.setAttribute(el2, "height", "12");
            dom.setAttribute(el2, "viewBox", "0 0 40 40");
            dom.setAttribute(el2, "xmlns", "http://www.w3.org/2000/svg");
            dom.setAttribute(el2, "stroke", "#9a9a9a");
            dom.setAttribute(el2, "class", "select-box__loader");
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("g");
            dom.setAttribute(el3, "fill", "none");
            dom.setAttribute(el3, "fill-rule", "evenod  d");
            var el4 = dom.createTextNode("\n                        ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("g");
            dom.setAttribute(el4, "transform", "translate(2 2)");
            dom.setAttribute(el4, "stroke-width", "4");
            var el5 = dom.createTextNode("\n                            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("circle");
            dom.setAttribute(el5, "stroke-opacity", ".6");
            dom.setAttribute(el5, "cx", "18");
            dom.setAttribute(el5, "cy", "18");
            dom.setAttribute(el5, "r", "18");
            dom.setAttribute(el5, "class", "select-box__loader-circle");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("path");
            dom.setAttribute(el5, "d", "M36 18c0-9.94-8.06-18-18-18");
            dom.setAttribute(el5, "class", "select-box__loader-spinner");
            var el6 = dom.createTextNode("\n                                ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("animateTransform");
            dom.setAttribute(el6, "attributeName", "transform");
            dom.setAttribute(el6, "type", "rotate");
            dom.setAttribute(el6, "from", "0 18 18");
            dom.setAttribute(el6, "to", "360 18 18");
            dom.setAttribute(el6, "dur", "0.8s");
            dom.setAttribute(el6, "repeatCount", "indefinite");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                            ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                        ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.setNamespace(null);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "select-box__loader-text");
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 5]), 1, 1);
            return morphs;
          },
          statements: [["content", "loadingMessage", ["loc", [null, [104, 20], [104, 38]]]]],
          locals: [],
          templates: []
        };
      })();
      var child5 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 111,
                  "column": 16
                },
                "end": {
                  "line": 119,
                  "column": 16
                }
              },
              "moduleName": "ember-new/templates/components/select-box-dictionary.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "select-box-option", [], ["option", ["subexpr", "@mut", [["get", "option", ["loc", [null, [113, 35], [113, 41]]]]], [], []], "selected", ["subexpr", "@mut", [["get", "_selected", ["loc", [null, [114, 37], [114, 46]]]]], [], []], "searchText", ["subexpr", "@mut", [["get", "_searchText", ["loc", [null, [115, 39], [115, 50]]]]], [], []], "optionLabelKey", ["subexpr", "@mut", [["get", "optionLabelKey", ["loc", [null, [116, 43], [116, 57]]]]], [], []], "optionDisabledKey", ["subexpr", "@mut", [["get", "optionDisabledKey", ["loc", [null, [117, 46], [117, 63]]]]], [], []], "select-item", ["subexpr", "action", ["selectItem"], [], ["loc", [null, [118, 40], [118, 61]]]]], ["loc", [null, [112, 20], [118, 64]]]]],
            locals: ["option"],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 109,
                "column": 8
              },
              "end": {
                "line": 121,
                "column": 8
              }
            },
            "moduleName": "ember-new/templates/components/select-box-dictionary.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("ul");
            dom.setAttribute(el1, "class", "select-box__options-list");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["block", "each", [["get", "_filteredContent", ["loc", [null, [111, 24], [111, 40]]]]], [], 0, null, ["loc", [null, [111, 16], [119, 25]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 34,
              "column": 0
            },
            "end": {
              "line": 124,
              "column": 0
            }
          },
          "moduleName": "ember-new/templates/components/select-box-dictionary.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "select-box__menu");
          dom.setAttribute(el1, "style", "z-index: 9999");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var morphs = new Array(6);
          morphs[0] = dom.createMorphAt(element3, 1, 1);
          morphs[1] = dom.createMorphAt(element3, 3, 3);
          morphs[2] = dom.createMorphAt(element3, 5, 5);
          morphs[3] = dom.createMorphAt(element3, 7, 7);
          morphs[4] = dom.createMorphAt(element3, 8, 8);
          morphs[5] = dom.createMorphAt(element3, 10, 10);
          return morphs;
        },
        statements: [["block", "if", [["get", "allowSearch", ["loc", [null, [36, 14], [36, 25]]]]], [], 0, null, ["loc", [null, [36, 8], [46, 15]]]], ["block", "if", [["get", "_isShowingClear", ["loc", [null, [48, 14], [48, 29]]]]], [], 1, null, ["loc", [null, [48, 8], [58, 15]]]], ["block", "if", [["get", "_isShowingAddNew", ["loc", [null, [60, 14], [60, 30]]]]], [], 2, null, ["loc", [null, [60, 8], [67, 15]]]], ["block", "if", [["get", "_isShowingNoResultsMessage", ["loc", [null, [69, 14], [69, 40]]]]], [], 3, null, ["loc", [null, [69, 8], [73, 15]]]], ["block", "if", [["get", "isLoading", ["loc", [null, [74, 14], [74, 23]]]]], [], 4, null, ["loc", [null, [74, 8], [107, 15]]]], ["block", "if", [["get", "_hasResults", ["loc", [null, [109, 14], [109, 25]]]]], [], 5, null, ["loc", [null, [109, 8], [121, 15]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes", "wrong-type"]
        },
        "revision": "Ember@2.3.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 125,
            "column": 0
          }
        },
        "moduleName": "ember-new/templates/components/select-box-dictionary.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("a");
        dom.setAttribute(el1, "href", "#");
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element6 = dom.childAt(fragment, [0]);
        var morphs = new Array(6);
        morphs[0] = dom.createAttrMorph(element6, 'class');
        morphs[1] = dom.createElementMorph(element6);
        morphs[2] = dom.createMorphAt(element6, 1, 1);
        morphs[3] = dom.createMorphAt(element6, 3, 3);
        morphs[4] = dom.createMorphAt(element6, 5, 5);
        morphs[5] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["select-box__label\n    ", ["subexpr", "if", [["get", "_isShowingMenu", ["loc", [null, [3, 9], [3, 23]]]], "select-box__label--menu-open"], [], ["loc", [null, [3, 4], [3, 56]]]], "\n       ", ["subexpr", "if", [["get", "multiple", ["loc", [null, [4, 12], [4, 20]]]], "select-box__label--multiple"], [], ["loc", [null, [4, 7], [4, 52]]]], "\n       ", ["subexpr", "if", [["get", "_hasMultipleSelection", ["loc", [null, [5, 12], [5, 33]]]], "select-box__label--multiple-selected"], [], ["loc", [null, [5, 7], [5, 74]]]]]]], ["element", "action", ["toggleMenu"], [], ["loc", [null, [6, 4], [6, 27]]]], ["block", "if", [["get", "_hasSingleSelection", ["loc", [null, [8, 10], [8, 29]]]]], [], 0, null, ["loc", [null, [8, 4], [12, 11]]]], ["block", "if", [["get", "_hasMultipleSelection", ["loc", [null, [14, 10], [14, 31]]]]], [], 1, null, ["loc", [null, [14, 4], [27, 11]]]], ["block", "unless", [["get", "_hasSelection", ["loc", [null, [29, 14], [29, 27]]]]], [], 2, null, ["loc", [null, [29, 4], [31, 15]]]], ["block", "if", [["get", "_isShowingMenu", ["loc", [null, [34, 6], [34, 20]]]]], [], 3, null, ["loc", [null, [34, 0], [124, 7]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  })());
});
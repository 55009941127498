define('ember-new/services/ajax', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),
    cookies: _ember['default'].inject.service('cookies'),

    ajaxGet: function ajaxGet(url) {

      return this.ajax(url, [], 'GET');
    },

    ajaxPost: function ajaxPost(url, payload) {
      return this.ajax(url, payload, 'POST');
    },

    ajax: function ajax(url, payload, type) {

      var self = this;

      if (type == "POST" || type == 'PATCH' || type == 'DELETE' || type == 'PUT') {
        payload = JSON.stringify(payload);
      }

      var updatePromise = $.ajax({
        url: url,
        contentType: "application/json; charset=utf-8",
        type: type,
        dataType: 'json',
        data: payload,
        headers: {
          "Authorization": "Bearer " + EmberNew.TheUser.get('token')
        },
        beforeSend: function beforeSend(xhr) {
          var user = self.get('cookies').read('impersonatingUser');
          if (user) {
            xhr.setRequestHeader('X-Switch-User', user);
          }
        }
      });

      var _this = this;

      var afterUpdatePromise = new _ember['default'].RSVP.Promise(function (resolve, reject) {

        updatePromise.then(function (response) {
          // returns server response JSON as a result of the promise
          resolve(response);
        }, function (jqXHR) {

          if (jqXHR.status == 500) {
            _this.handle500(jqXHR);
            reject(jqXHR, jqXHR.status);
            return;
          } else if (jqXHR.status == 403) {
            _this.handle403(jqXHR);
            reject(jqXHR, jqXHR.status);
            return;
          } else if (jqXHR.status == 400) {
            // do nothing validation will be resolved later in .then() of promise
            reject(jqXHR.responseJSON, jqXHR.status); // returns JSON with validation errors as a result of the promise
            return;
          } else if (jqXHR.status == 405) {
            _this.handle405(jqXHR);
            reject(jqXHR, jqXHR.status);
            return;
          } else {
            _this.handleUnknown(jqXHR);
            reject(jqXHR, jqXHR.status);
            return;
          }
        });
      }, updatePromise._label + " afterAjaxPost");

      return afterUpdatePromise;
    },

    handle500: function handle500(jqHXR) {
      this.get('applicationAlerts').addActionMessage('danger', "(500) Ciemność, ciemność widzę! Błąd systemu! Skontaktuj się z HelpDesk lub zgłoś swojemu Managerowi!");
    },
    handle403: function handle403(jqXHR, promiseRejectFunction) {
      var msg = '';

      if (jqXHR.responseJSON !== undefined) {
        msg = msg + "\nBrak dostępu: \n" + jqXHR.responseJSON.message;
      }

      this.get('applicationAlerts').addActionMessage('warning', msg);

      if (promiseRejectFunction !== undefined && typeof promiseRejectFunction === 'function') {
        promiseRejectFunction(jqXHR);
      }
    },
    handle422: function handle422(jqHXR) {
      this.get('applicationAlerts').addActionMessage('warning', "(422) Czuję pustkę! :-( Brakuje pewnych danych, aby dokonać tej akcji. Postaraliśmy się skompletować czego brakuje: \n" + "Daty dodania umowy, taryfy, loyalki.");
    },
    handleUnknown: function handleUnknown() {},

    handle405: function handle405(jqXHR) {
      var msg = '';

      if (jqXHR.responseJSON !== undefined) {
        msg = msg + jqXHR.responseJSON.message;
      }

      this.get('applicationAlerts').addActionMessage('warning', msg);
    },

    ajaxCustom: function ajaxCustom(url, payload, type) {

      var self = this;

      if (type == "POST" || type == 'PATCH' || type == 'DELETE' || type == 'PUT') {
        payload = JSON.stringify(payload);
      }

      var updatePromise = $.ajax({
        url: url,
        contentType: "application/json; charset=utf-8",
        type: type,
        dataType: 'json',
        data: payload,
        headers: {
          "Authorization": "Bearer " + EmberNew.TheUser.get('token')
        },
        beforeSend: function beforeSend(xhr) {
          var user = self.get('cookies').read('impersonatingUser');
          var ssid = self.get('cookies').read('sylius_ssid');
          if (user) {
            xhr.setRequestHeader('X-Switch-User', user);
          }
        }
      });

      var afterUpdatePromise = new _ember['default'].RSVP.Promise(function (resolve, reject) {

        updatePromise.then(function (response, result, xhr) {
          // returns server response JSON as a result of the promise
          resolve({ responseJSON: response, result: result, xhr: xhr });
        }, function (response, result, xhr) {
          reject(response);
        });
      }, updatePromise._label + " afterAjaxPost");

      return afterUpdatePromise;
    }
  });
});
define('ember-new/controllers/b2b-contract/dzu/show-ee/ppe', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        TheUser: _ember['default'].inject.service('theuser:main'),

        isDzuUser: (function () {
            return this.get("TheUser").isMainRoleIn(['ROLE_DZU2_SPECIALIST', 'ROLE_DZU2']);
        }).property(),

        actions: {
            showDetails: function showDetails(params) {
                this.transitionToRoute('b2b-contract.dzu.show-ee.ppe.details', params.get('uuid'));
            }
        }

    });
});
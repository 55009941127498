define('ember-new/models/analysis-view-models/renouncement-analysis-view-model', ['exports', 'ember-new/models/abstract', 'ember-data/attr'], function (exports, _emberNewModelsAbstract, _emberDataAttr) {
    exports['default'] = _emberNewModelsAbstract['default'].extend({
        medium: (0, _emberDataAttr['default'])('string'),
        mediumName: (0, _emberDataAttr['default'])('string'),
        receiptDate: (0, _emberDataAttr['default'])('string'),
        datePostmark: (0, _emberDataAttr['default'])('string'),
        renouncementStatus: (0, _emberDataAttr['default'])('string'),
        isOkToAnonymize: (0, _emberDataAttr['default'])('boolean'),
        alerts: (0, _emberDataAttr['default'])(),
        renouncementUuid: (0, _emberDataAttr['default'])('string'),
        ppeContractNo: (0, _emberDataAttr['default'])('string'),
        ppeCounterNo: (0, _emberDataAttr['default'])('string'),
        ppeNo: (0, _emberDataAttr['default'])('string'),
        ppgContractNo: (0, _emberDataAttr['default'])('string'),
        ppgCounterNo: (0, _emberDataAttr['default'])('string'),
        ppgNo: (0, _emberDataAttr['default'])('string'),
        ppmAddress: (0, _emberDataAttr['default'])(),
        source: (0, _emberDataAttr['default'])('string'),
        comment: (0, _emberDataAttr['default'])('string'),
        client: (0, _emberDataAttr['default'])(),

        contractNo: (function () {
            if (this.get('medium') == 'ee') {
                return this.get('ppeContractNo');
            }

            return this.get('ppgContractNo');
        }).property('medium', 'ppeContractNo', 'ppgContractNo')
    });
});
define('ember-new/controllers/meeting-verification/report', ['exports', 'ember', 'ember-new/config/environment', 'ember-new/themes/default-theme', 'ember-new/mixins/controllers/meeting-verification/authorization-checker'], function (exports, _ember, _emberNewConfigEnvironment, _emberNewThemesDefaultTheme, _emberNewMixinsControllersMeetingVerificationAuthorizationChecker) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsControllersMeetingVerificationAuthorizationChecker['default'], {
        ajax: _ember['default'].inject.service('ajax'),
        cookies: _ember['default'].inject.service(),

        isLoading: false,
        saleMonthValue: null,
        saleMonthLabel: null,
        ajaxError: null,
        theme: _emberNewThemesDefaultTheme['default'],
        chartData: [],
        categories: [],

        actions: {
            generate: function generate() {
                var _this = this;
                this.set('isLoading', true);

                var data = [];
                var selectedUuid = this.get('saleMonthValue');
                if (selectedUuid !== null && selectedUuid !== undefined && selectedUuid !== '') {
                    data = {
                        uuid: selectedUuid
                    };
                }

                this.get('ajax').ajaxCustom(_emberNewConfigEnvironment['default'].apiUrl + '/api/meetingverification/reportchartdata', data, 'GET').then(function (response) {
                    if (response.result === 'success') {
                        _this.set('categories', response.responseJSON.labels);
                        _this.set('chartData', response.responseJSON.series);
                        _this.titleForGeneratedReport(response.responseJSON.date);
                    }
                    _this.set('ajaxError', null);
                    _this.set('isLoading', false);
                }, function (response) {
                    _this.set('ajaxError', response.responseJSON.message);
                    _this.set('categories', []);
                    _this.set('chartData', []);
                    _this.set('isLoading', false);
                });
            }
        },

        titleForGeneratedReport: function titleForGeneratedReport(date) {
            var firstDate = date['dateFrom'];
            var lastDate = date['dateTo'];
            var title = 'Od ' + firstDate + ' do ' + lastDate;

            this.set('saleMonthLabel', title);
        }
    });
});
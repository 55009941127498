define('ember-new/controllers/b2b-contract/dzu/show-gas/prices/add', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        fillForm: function fillForm(form) {
            form.set('model.contractId', this.get('contractId'));
            form.set('model.tariffGroups', null);
        },

        afterCommand: function afterCommand(response) {
            var ctrl = this;
            var route = this.container.lookup("route:b2b-contract.dzu.show-gas.prices");
            route.refresh();
            setTimeout(function () {
                ctrl.transitionToRoute('b2b-contract.dzu.show-gas.prices');
            }, 500);
        }

    });
});
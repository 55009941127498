define('ember-new/routes/b2b-contract/dzu', ['exports', 'ember-new/routes/authenticated', 'ember'], function (exports, _emberNewRoutesAuthenticated, _ember) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({

        dictionariesManager: _ember['default'].inject.service('dictionaries-manager'),

        products: null,
        gasProducts: null,

        queryParams: {
            page: { refreshModel: true },
            itemsPerPage: { refreshModel: true },
            order: { refreshModel: true },
            orderDirection: { refreshModel: true }
        },

        beforeModel: function beforeModel(transition) {
            this._super.apply(this, arguments);
            window.scrollTo(0, 0); // scroll to the top of application before reloading
        },

        model: function model(params) {

            if (this.products === null) {
                return _ember['default'].RSVP.hash({
                    list: this.store.query('ContractDzuListItemViewModel', params),
                    statusesSummary: this.store.findAll('DzuStatusesSummary'),
                    products: this.store.findAll('EeBusinessContractProductViewModel'),
                    gasProducts: this.store.findAll('GasBusinessContractProductViewModel')
                });
            } else {
                return _ember['default'].RSVP.hash({
                    list: this.store.query('ContractDzuListItemViewModel', params),
                    statusesSummary: this.store.findAll('DzuStatusesSummary'),
                    products: this.products,
                    gasProducts: this.gasProducts
                });
            }
        },

        setupController: function setupController(ctrl, model) {
            ctrl.set('model', model.list);
            ctrl.set('statusesSummary', model.statusesSummary);
            ctrl.initializeTempFilters();
            ctrl.set('specialists', this.get('dictionariesManager').getUsersDictionary('users_handling_b2b_contracts'));
            ctrl.initializeProducts(model.products);
            ctrl.initializeGasProducts(model.gasProducts);
        },

        actions: {
            queryParamsDidChange: function queryParamsDidChange() {
                this.refresh();
                // this.send('refreshModel');
            },
            willTransition: function willTransition(params) {
                // this.refresh();

                console.log('willTransition params', params);
            },
            refreshContractItemModel: function refreshContractItemModel(contractId) {
                var ctrl = this.controllerFor('b2b-contract.dzu'),
                    model = ctrl.get('model');

                model.store.findRecord('ContractDzuListItemViewModel', contractId, { reload: true }).then(function (res) {
                    return console.log('response... ', res);
                });
            },
            refreshModel: function refreshModel() {
                this.store.unloadAll('ContractDzuListItemViewModel');
                this.refresh();
            }
        }
    });
});
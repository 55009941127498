define('ember-new/routes/b2b-contract/dzu/show-ee/client/remove-representative', ['exports', 'ember-new/routes/authenticated'], function (exports, _emberNewRoutesAuthenticated) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({
        model: function model(params) {
            var contractId = this.modelFor('b2b-contract.dzu.show-ee').get('id'),
                clientModel = this.modelFor('b2b-contract.dzu.show-ee.client');
            var data = { personId: params.personId, contractId: contractId, clientModel: clientModel };
            return data;
        },
        setupController: function setupController(controller, model) {
            controller.set("person", model.personId);
            controller.set("contract", model.contractId);
            controller.set("clientModel", model.clientModel);
        }
    });
});
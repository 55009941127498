define('ember-new/components/bulk-channel-reservation', ['exports', 'ember', 'ember-new/config/environment'], function (exports, _ember, _emberNewConfigEnvironment) {

    var FUEL_TYPE_EE = 'ee';
    var FUEL_TYPE_GAS = 'gas';
    var FUEL_TYPE_EE_GAS = 'ee_gas';

    var RESERVATION_CHANNEL = 'channel';
    var RESERVATION_USER = 'user';

    exports['default'] = _ember['default'].Component.extend({

        clientsContent: null,
        contentToSubmit: [],
        isOnlyEeReservation: true,
        isOnlyGasReservation: false,

        switchOnChannel: true,
        switchOnUser: false,

        isAllReservation: false,
        channelForReservation: null,
        userForReservation: null,
        dateReservation: null,
        isSubmitting: false,

        ajax: _ember['default'].inject.service('ajax'),
        applicationAlertsManager: _ember['default'].inject.service('application-alerts-manager'),

        onInit: (function () {
            var defaultDate = moment().add(1, "month").format('YYYY-MM-DD');
            this.set('dateReservation', defaultDate);
        }).on('init'),

        actions: {
            submit: function submit() {
                var _this = this;

                this.set('isSubmitting', true);
                var self = this;
                var ajax = this.get('ajax');
                var applicationAlertsManager = this.get('applicationAlertsManager');
                var clientsContent = this.get('clientsContent');
                var url = _emberNewConfigEnvironment['default'].apiUrl + '/rest/tasks/createormovechannelreservationcommands';
                var contentToProcess = {
                    'CreateOrMoveChannelReservationCommand': {
                        "clientsContent": [],
                        "reservationType": self.getFuelType(),
                        "reservationOwnerType": self.getOwnerType(),
                        'reservationForChannel': self.get('channelForReservation'),
                        'reservationForUser': self.get('userForReservation'),
                        'reservationDate': self.get('dateReservation')
                    }
                };

                if (moment(self.get('dateReservation')).isValid() === false) {
                    applicationAlertsManager.addActionMessage('danger', 'Data rezerwacji jest nieprawidłowa');
                    this.set('isSubmitting', false);
                    return;
                }

                if (!moment(self.get('dateReservation')).isAfter(moment())) {
                    applicationAlertsManager.addActionMessage('danger', 'Data rezerwacji musi być minimum jutrzejsza');
                    this.set('isSubmitting', false);
                    return;
                }

                if (self.isAllReservation === false && self.isOnlyEeReservation === false && self.isOnlyGasReservation === false) {
                    applicationAlertsManager.addActionMessage('danger', 'Nie wybrano medium dla rezerwacji');
                    this.set('isSubmitting', false);
                    return;
                }

                if (self.switchOnChannel === false && self.switchOnUser === false) {
                    applicationAlertsManager.addActionMessage('danger', 'Nie wybrano czy rezerwacja na kanał czy na użytkownika');
                    this.set('isSubmitting', false);
                    return;
                }

                if (self.switchOnChannel === true && (this.get('channelForReservation') === '' || this.get('channelForReservation') === null || this.get('channelForReservation') === undefined)) {
                    applicationAlertsManager.addActionMessage('danger', 'Nie wybrano kanału dla rezerwacji');
                    this.set('isSubmitting', false);
                    return;
                }

                if (self.switchOnUser === true && (this.get('userForReservation') === '' || this.get('userForReservation') === null || this.get('userForReservation') === undefined)) {
                    applicationAlertsManager.addActionMessage('danger', 'Nie wybrano użytkownika dla rezerwacji');
                    this.set('isSubmitting', false);
                    return;
                }

                clientsContent.forEach(function (item) {
                    if (item.get('isAllowProcess')) {
                        contentToProcess.CreateOrMoveChannelReservationCommand.clientsContent.push(item.get('nip'));
                    }
                });

                var promise = ajax.ajaxPost(url, contentToProcess);
                promise.then(function () {
                    applicationAlertsManager.addActionMessage('success', 'Rezerwacje zostały przepięte i / lub utworzone.');
                    _this.set('isSubmitting', false);
                }, function () {
                    applicationAlertsManager.addActionMessage('danger', 'Wystąpiły błędy!');
                    _this.set('isSubmitting', false);
                });
            },

            chooseFuelType: function chooseFuelType(type) {
                switch (type) {
                    case FUEL_TYPE_EE:
                        this.set('isOnlyEeReservation', true);
                        this.set('isOnlyGasReservation', false);
                        this.set('isAllReservation', false);
                        break;
                    case FUEL_TYPE_GAS:
                        this.set('isOnlyEeReservation', false);
                        this.set('isOnlyGasReservation', true);
                        this.set('isAllReservation', false);
                        break;
                    case FUEL_TYPE_EE_GAS:
                        this.set('isOnlyEeReservation', false);
                        this.set('isOnlyGasReservation', false);
                        this.set('isAllReservation', true);
                        break;
                }
            },

            chooseReservationType: function chooseReservationType(type) {
                switch (type) {
                    case RESERVATION_CHANNEL:
                        this.set('switchOnChannel', true);
                        this.set('switchOnUser', false);
                        break;
                    case RESERVATION_USER:
                        this.set('switchOnChannel', false);
                        this.set('switchOnUser', true);
                        break;
                }
            }
        },

        getFuelType: function getFuelType() {
            if (this.get('isOnlyEeReservation') && !this.get('isOnlyGasReservation') && !this.get('isAllReservation')) {
                return FUEL_TYPE_EE;
            } else if (this.get('isOnlyGasReservation') && !this.get('isOnlyEeReservation') && !this.get('isAllReservation')) {
                return FUEL_TYPE_GAS;
            }
            return FUEL_TYPE_EE_GAS;
        },
        getOwnerType: function getOwnerType() {
            if (this.get('switchOnChannel') && !this.get('switchOnUser')) {
                return RESERVATION_CHANNEL;
            } else if (this.get('switchOnUser') && !this.get('switchOnChannel')) {
                return RESERVATION_USER;
            } else {
                return null;
            }
        }

    });
});
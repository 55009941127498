define('ember-new/components/th-sort', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'th',
        classNameBindings: ['class'],
        'class': 'sorting',

        click: function click() {
            this._targetObject.send('sort', this.get('field'));
        },

        onInit: (function () {
            //wymuszamy refresh stanow bo w momencie ladowania komponentow nie ma jeszcze odpalonych obserwatorow kontrolera
            this.stateChanged();
        }).on('init'),

        stateChanged: (function () {
            var sortBy = this.get('_targetObject').get('sortBy');
            var sortModeAsc = this.get('_targetObject').get('sortModeAsc');

            if (this.get('field') == sortBy) {
                if (sortModeAsc) {
                    this.set('class', 'sorting_asc');
                } else {
                    this.set('class', 'sorting_desc');
                }
            } else {
                this.set('class', 'sorting');
            }
        }).observes('_targetObject.sortBy', '_targetObject.sortModeAsc')

    });
});
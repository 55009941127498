define('ember-new/models/business-product-list-view-model', ['exports', 'ember-new/models/abstract', 'ember-data'], function (exports, _emberNewModelsAbstract, _emberData) {
    exports['default'] = _emberNewModelsAbstract['default'].extend({
        type: _emberData['default'].attr('string'),
        name: _emberData['default'].attr('string'),
        kind: _emberData['default'].attr('string'),
        usysId: _emberData['default'].attr('string'),
        everyIndexAvailableInConditions: _emberData['default'].attr('boolean'),
        linkedProducts: _emberData['default'].attr(),
        mmVisibleForSalesman: _emberData['default'].attr('boolean'),
        productIndexed: _emberData['default'].attr('boolean'),
        defaultConditionProductName: _emberData['default'].attr('string'),
        allConditionProductsAvailableForPriceConditions: _emberData['default'].attr('boolean'),
        hasBasePriceSet: _emberData['default'].attr('boolean'),
        automaticBasePriceSet: _emberData['default'].attr('boolean'),
        productModel: _emberData['default'].attr('string'),
        valueType: _emberData['default'].attr('string'),
        volatilityPeriod: _emberData['default'].attr('string'),
        hasAssignedBasePriceSet: _emberData['default'].attr('boolean')
    });
});
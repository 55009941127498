define('ember-new/routes/b2b-contract/dzu/show-ee/ppe/details', ['exports', 'ember-new/routes/authenticated'], function (exports, _emberNewRoutesAuthenticated) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({

        model: function model(params) {
            this.store.unloadAll('PpeViewModel');
            return this.store.findRecord('PpeViewModel', params.ppeId, { reload: true });
        },

        actions: {
            updateModel: function updateModel() {
                alert("I'm updating the model mother!");
            },

            refreshModel: function refreshModel() {
                this.refresh();
            }
        }

    });
});
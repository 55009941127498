define('ember-new/routes/b2b-contract/dzu/show-ee/client', ['exports', 'ember-new/routes/authenticated'], function (exports, _emberNewRoutesAuthenticated) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({

        model: function model(params) {
            var contract = this.modelFor('b2b-contract.dzu.show-ee');
            this.set('idContract', contract.get('id'));

            return this.store.findRecord('ContractClientDzuViewModel', contract.get('id'), { reload: true });
        },

        setupController: function setupController(controller, model) {
            controller.set('model', model);
            controller.set('idContract', this.get('idContract'));
        },

        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            }
        }

    });
});
define('ember-new/helpers/xor', ['exports', 'ember-truth-helpers/helpers/xor'], function (exports, _emberTruthHelpersHelpersXor) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberTruthHelpersHelpersXor['default'];
    }
  });
  Object.defineProperty(exports, 'xor', {
    enumerable: true,
    get: function get() {
      return _emberTruthHelpersHelpersXor.xor;
    }
  });
});
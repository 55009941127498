define('ember-new/controllers/b2b-contract/dzu/show-ee/ppe/details', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        actions: {
            closePpeView: function closePpeView() {
                this.transitionToRoute('b2b-contract.dzu.show-ee.ppe');
            }
        }

    });
});
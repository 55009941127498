define("ember-new/components/d-form/individual-contract-price-lists/vap/add", ["exports", "ember-new/components/d-form-command", "ember-new/templates/components/d-form"], function (exports, _emberNewComponentsDFormCommand, _emberNewTemplatesComponentsDForm) {
    exports["default"] = _emberNewComponentsDFormCommand["default"].extend({
        layout: _emberNewTemplatesComponentsDForm["default"],

        commandName: 'CreateIndividualVapCommand',

        afterSuccessfulSubmit: function afterSuccessfulSubmit() {
            var targetController = this.get('targetObject');
            var mainRoute = targetController.container.lookup("route:individual-contract-price-lists.vap");
            mainRoute.refresh();

            setTimeout(function () {
                targetController.transitionToRoute('individual-contract-price-lists.vap');
            }, 100);
        },

        validityPeriodObserver: (function () {

            if (this.get('formRows.numberOfMonths')) {

                if (this.get('formData.validityPeriod') === 'entireContractPeriod' || this.get('formData.validityPeriod') === null) {
                    this.set('formRows.numberOfMonths.hidden', true);
                    this.set('formData.numberOfMonths', null);
                } else {
                    this.set('formRows.numberOfMonths.hidden', false);
                }
            }
        }).observes('formData.validityPeriod')
    });
});
define('ember-new/controllers/meeting-verification/ordering/mark-as-wrong-data', ['exports', 'ember', 'ember-new/config/environment'], function (exports, _ember, _emberNewConfigEnvironment) {
    exports['default'] = _ember['default'].Controller.extend({
        ajax: _ember['default'].inject.service('ajax'),
        applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),

        actions: {
            markWrongData: function markWrongData() {
                var _this = this;

                var handleResponse = function handleResponse(res) {
                    _this.get('applicationAlerts').addActionMessage('success', 'OK');
                    var route = _this.container.lookup('route:meeting-verification.ordering');
                    route.refresh();
                    _this.transitionToRoute('meeting-verification.ordering');
                };

                var handleError = function handleError(error) {
                    _this.get('applicationAlerts').addActionMessage('danger', 'Nieznany błąd!');
                };

                var serializedData = {
                    MarkAsIncorrectDataCommand: {
                        taskUuid: this.get('taskUuid')
                    }
                };

                this.get('ajax').ajaxPost(_emberNewConfigEnvironment['default'].apiUrl + '/rest/markasincorrectdatacommands', serializedData).then(function (res) {
                    return handleResponse(res);
                }, function (err) {
                    return handleError(err.error);
                });
            }
        }
    });
});
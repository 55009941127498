define('ember-new/models/edit-price-condition-view-model', ['exports', 'ember-new/models/abstract', 'ember-data'], function (exports, _emberNewModelsAbstract, _emberData) {

    var attr = _emberData['default'].attr;
    var refOne = _emberData['default'].belongsTo;

    exports['default'] = _emberNewModelsAbstract['default'].extend({
        periodType: _emberData['default'].attr('string'),
        valueType: _emberData['default'].attr('string'),
        productId: _emberData['default'].attr('string'),
        year: _emberData['default'].attr('string'),
        month: _emberData['default'].attr('string'),
        startYear: _emberData['default'].attr('string'),
        endYear: _emberData['default'].attr('string'),
        startMonth: _emberData['default'].attr('string'),
        endMonth: _emberData['default'].attr('string'),

        valueArea1: _emberData['default'].attr('string'),
        valueArea2: _emberData['default'].attr('string'),
        valueArea3: _emberData['default'].attr('string'),
        fee: _emberData['default'].attr('string'),

        baseValueArea1: _emberData['default'].attr('string'),
        baseValueArea2: _emberData['default'].attr('string'),
        baseValueArea3: _emberData['default'].attr('string'),
        baseFee: _emberData['default'].attr('string'),

        productLocked: _emberData['default'].attr('boolean'),
        basePricesLocked: _emberData['default'].attr('boolean'),
        basePriceId: _emberData['default'].attr('string'),

        overhead: _emberData['default'].attr('string'),
        discount: _emberData['default'].attr('string'),

        overheadArea1: _emberData['default'].attr('string'),
        overheadArea2: _emberData['default'].attr('string'),
        overheadArea3: _emberData['default'].attr('string'),

        subscription: _emberData['default'].attr('string'),
        baseSubscription: _emberData['default'].attr('string'),

        overheadFromLastKnownPeriod: _emberData['default'].attr('boolean')
    });
});
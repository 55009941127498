define('ember-new/controllers/individual-contract/show-gas/entity-change', ['exports', 'ember', 'ember-new/mixins/controllers/individual-contract/entity-change'], function (exports, _ember, _emberNewMixinsControllersIndividualContractEntityChange) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsControllersIndividualContractEntityChange['default'], Object.defineProperties({

        clientCB: true,
        contractCB: true,
        ppmCB: true,
        pricesCB: true,

        queryParams: {
            dateFrom: 'dateFrom',
            dateTo: 'dateTo',
            clientCB: 'clientCB',
            contractCB: 'contractCB',
            ppmCB: 'ppmCB',
            pricesCB: 'pricesCB',
            author: 'author'
        },

        queryParamsNames: {
            dateFrom: 'Data od',
            dateTo: 'Data do',
            clientCB: 'Klient',
            contractCB: 'Umowa',
            ppmCB: 'PPG',
            pricesCB: 'Cenniki',
            author: 'Autorzy'
        }

    }, {
        queryParamsVars: {
            get: function get() {
                return this.queryParams;
            },
            configurable: true,
            enumerable: true
        }
    }));
});
define('ember-new/components/div-filter', ['exports', 'ember', 'ember-new/mixins/d-form/d-field', 'ember-new/components/d-form'], function (exports, _ember, _emberNewMixinsDFormDField, _emberNewComponentsDForm) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'div',
        classNames: ['input-group input-group-sm'],

        yieldValue: null,
        currentValue: null,
        loaded: false,

        showOkButton: (function () {
            if (this.get('yieldValue') != this.get('currentValue') && (this.get('yieldValue') || this.get('yieldValue') == '')) {
                return true;
            }
            return false;
        }).property('yieldValue', 'currentValue'),

        showCancelButton: (function () {
            if (this.get('yieldValue') && this.get('yieldValue') == this.get('currentValue')) {
                return true;
            }
            return false;
        }).property('yieldValue', 'currentValue'),

        actions: {
            applyFilter: function applyFilter() {
                this.set('currentValue', this.get('yieldValue'));
                if (this.get('loaded')) {
                    this._targetObject.send('applyFilter', this.get('field'), this.get('yieldValue'));
                }
            },
            cancelFilter: function cancelFilter() {
                this.set('currentValue', '');
                this.set('yieldValue', '');
                this._targetObject.send('applyFilter', this.get('field'), '');
            }
        },

        onInit: (function () {
            this.get('_targetObject').registerFilterComponent(this);
            var _this = this;
            _this.set('yieldValue', _this.get('_targetObject').get(_this.get('field')));
            _this.set('currentValue', _this.get('_targetObject').get(_this.get('field')));
            //wymuszamy refresh stanow bo w momencie ladowania komponentow nie ma jeszcze odpalonych obserwatorow kontrolera
            this.get('_targetObject').addObserver(this.get('field'), function () {
                if (!(_this.get('isDestroyed') || _this.get('isDestroying')) && _this.get('yieldValue') && _this.get('field')) {
                    _this.set('yieldValue', _this.get('_targetObject').get(_this.get('field')));
                    _this.set('currentValue', _this.get('_targetObject').get(_this.get('field')));
                }
            });
            this.set('loaded', true);
        }).on('init')
    });
});
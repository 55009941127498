define("ember-new/routes/tasks/ordering-meetings", ["exports", "ember-new/routes/authenticated", "ember"], function (exports, _emberNewRoutesAuthenticated, _ember) {
    exports["default"] = _emberNewRoutesAuthenticated["default"].extend({
        keyboard: _ember["default"].inject.service(),

        model: function model() {
            return this.store.query('user', { salesmanList: 1, onlyActive: true });
        },

        setupController: function setupController(ctrl, model) {
            var meta = model.get('meta');
            var ids = meta.ids;
            var users = model.filter(function (item) {
                if (ids.indexOf(item.get('id')) > -1) {
                    return true;
                }
                return false;
            });
            ctrl.set('model', users);
        },

        closeModal: _ember["default"].on('keyUp:Escape', function () {
            this.controller.transitionToRoute("tasks");
        }),

        actions: {
            didTransition: function didTransition() {
                this._super.apply(this, arguments);
                this.get('keyboard').activate(this);

                return true;
            },
            willTransition: function willTransition() {
                this._super.apply(this, arguments);
                this.get('keyboard').deactivate(this);
            }
        }
    });
});
define('ember-new/mixins/b2b-contract/controllers/section-products/add-to-condition', ['exports', 'ember', 'ember-new/templates/components/d-form'], function (exports, _ember, _emberNewTemplatesComponentsDForm) {
    exports['default'] = _ember['default'].Mixin.create({
        layout: _emberNewTemplatesComponentsDForm['default'],

        medium: null,
        isIndex: false,
        isProductBasePrice: false,
        isAutomaticBasePrice: false,
        valueType: [],

        valueTypeEe: [{ label: 'Ceny umowne + cennik bazowy', value: 'contract_price' }, { label: 'Ceny umowne i bazowe', value: 'contract_price_with_base' }, { label: 'Narzut', value: 'overhead' }, { label: 'Indeks EE', value: 'index_ee' }],

        valueTypeGas: [{ label: 'Ceny umowne i bazowe', value: 'contract_price_with_base' }, { label: 'Narzut', value: 'overhead' }, { label: 'Narzut ze zniżką', value: 'discount_overhead' }, { label: 'Indeks GAZ', value: 'index_gas' }],

        mediumType: [{ label: 'EE', value: 'ee' }, { label: 'GAZ', value: 'gas' }],

        volatilityOfConditions: [{ label: 'Miesięczna', value: 'M' }, { label: 'Kwartalna', value: 'Q' }, { label: 'Roczna', value: 'Y' }, { label: 'Sezonowa', value: 'S' }],

        volatilityOfConditionsStart: 'M',

        productModel: [{ label: 'Oczekiwana cena', value: 'oczekiwana cena' }, { label: 'Obsługowy', value: 'obsługowy' }, { label: 'Premia rynkowa', value: 'premia rynkowa' }, { label: 'Flat', value: 'flat' }, { label: 'Save', value: 'save' }]
    });
});
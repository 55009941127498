define('ember-new/routes/reports/volume-by-region', ['exports', 'ember', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsApplicationRouteMixin) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsApplicationRouteMixin['default'], {

        ajax: _ember['default'].inject.service('ajax'),

        model: function model(params) {
            return _ember['default'].RSVP.hash({
                'regions': this.store.query('ContractByRegionReportViewModel', params, { reload: true }),
                'businessMonths': this.store.findAll('BusinessMonthListItemViewModel')
            });
        },

        setupController: function setupController(controller, model) {
            this._super(controller, model.list);

            controller.set('activatedFiltersCount', this.get('activatedFiltersCount'));
            controller.set('businessMonths', model.businessMonths);
            controller.set('regions', model.regions);
        },

        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            }
        }
    });
});
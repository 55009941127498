define('ember-new/models/sales-report-summary-view-model', ['exports', 'ember-data', 'ember-new/models/abstract'], function (exports, _emberData, _emberNewModelsAbstract) {
    exports['default'] = _emberNewModelsAbstract['default'].extend({
        numberOfMeetings: _emberData['default'].attr('number'),
        numberOfContractsReported: _emberData['default'].attr('number'),
        mmReported: _emberData['default'].attr('number'),
        volumeReported: _emberData['default'].attr('number'),
        numberOfContractsAccepted: _emberData['default'].attr('number'),
        mmAccepted: _emberData['default'].attr('number'),
        volumeAccepted: _emberData['default'].attr('number')
    });
});
define('ember-new/controllers/users/import', ['exports', 'ember-new/config/environment', 'ember'], function (exports, _emberNewConfigEnvironment, _ember) {

	var UsersImportController = _ember['default'].ObjectController.extend({
		showMsg: false,
		errors: [],

		apiHost: (function () {
			return _emberNewConfigEnvironment['default'].apiHost;
		}).property(),

		actions: {
			closeModal: function closeModal() {
				this.set('showMsg', false);
				this.set('errors', []);
				this.transitionToRoute('users');
			}
		}
	});

	exports['default'] = UsersImportController;
});
define('ember-new/controllers/b2b-contract/dzu/change-status', ['exports', 'ember', 'ember-new/config/environment', 'ember-new/mixins/b2b-contract/controllers/reload-contract-header'], function (exports, _ember, _emberNewConfigEnvironment, _emberNewMixinsB2bContractControllersReloadContractHeader) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsB2bContractControllersReloadContractHeader['default'], {

        fillForm: function fillForm(form) {

            form.set('model.contractId', this.get('model.id'));
            form.set('model.statusDefinitionId', null);
            form.set('model.userContent', null);
        },

        afterCommand: function afterCommand(response) {
            var ctrl = this;
            ctrl.transitionToRoute('b2b-contract.dzu');
            ctrl.get('model').reload();
            ctrl.get('target').send('refreshContractItemModel', ctrl.get('model.id'));
        },

        status11: (function () {
            if (this.get('choice') === '8d4eb167-7959-47e8-9f03-b6f927947131') {
                return true;
            }
            return false;
        }).property('choice')

    });
});
define('ember-new/controllers/b2b-contract/dzu/show-gas/ppg/add', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        osdNull: true,
        contractTerminating: true,
        disableSellerChange: true,
        disableOs: true,
        excisesSum: null,

        isLoyaltyEndFieldDisabled: true,

        correctionRatioFieldValues: null,

        osdTariffArray: ['dffa9569-62d7-4e25-a608-ddfda9b56bef', '5b7b5f20-b330-4097-894a-7ee4d452223a', '57c07d55-1b6e-4b7a-8715-9e36e2515da5', '771997c3-2be5-4b13-9f92-646f825128f6', '1727b666-3eb2-4c6f-87a6-50a340ed7784', '092c84c1-109f-4c0a-ab3e-0ca9962d7858', '8017cd14-ce69-4f67-8e44-61d25cdb5976', 'b11a0073-23d1-4b15-97a5-ee4bc7f2fdd7', 'b6cd29cc-6275-4459-b2ad-673a04aeb373', 'c3e938c1-4c83-4f96-80e7-b15608fedd6c'],

        /* available options: firstOrLastMonthIncluded, firstAndLastMonthIncluded, onlyFullMonths */
        monthCounterType: 'firstOrLastMonthIncluded',

        actions: {
            copyAddressFromClient: function copyAddressFromClient() {

                var model = this.get('model');

                var address = {
                    street: this.get('model.street'),
                    streetNo: this.get('model.streetNo'),
                    aptNo: this.get('model.aptNo'),
                    postalCode: this.get('model.postalCode'),
                    postalCity: this.get('model.postalCity'),
                    city: this.get('model.city'),
                    district: this.get('model.districtUuid')
                };

                var clientAddress = _ember['default'].Object.create(address);

                model.set('clientAddress', address);
            }
        },

        switchValues: [{ label: 'Tak', value: true }, { label: 'Nie', value: false }],

        loyaltyContent: [{ label: 'Lojalka - data znana', value: '34f0d211-f3f0-4783-8506-bcaade547dbc' }, { label: 'Brak lojalki', value: 'a45aebb5-9c31-4c41-b130-c91bbaccb6b4' }],

        fillForm: function fillForm(form) {
            form.set('model.uuidContract', this.get('contractId'));
            form.set('model.aptNo', null);
            form.set('model.fortumTariffGroup', null);
            form.set('model.city', null);
            form.set('model.consumptionMethodCalculation', null);
            form.set('model.contractualPower', null);
            form.set('model.correctionRatio', null);
            form.set('model.pointNumberForWinback', null);
            form.set('model.declaredMonthlyConsumption1', null);
            form.set('model.declaredMonthlyConsumption2', null);
            form.set('model.declaredMonthlyConsumption3', null);
            form.set('model.declaredMonthlyConsumption4', null);
            form.set('model.declaredMonthlyConsumption5', null);
            form.set('model.declaredMonthlyConsumption6', null);
            form.set('model.declaredMonthlyConsumption7', null);
            form.set('model.declaredMonthlyConsumption8', null);
            form.set('model.declaredMonthlyConsumption9', null);
            form.set('model.declaredMonthlyConsumption10', null);
            form.set('model.declaredMonthlyConsumption11', null);
            form.set('model.declaredMonthlyConsumption12', null);
            form.set('model.deliveredComprehensiveAgreement', null);
            form.set('model.deviceNumber', null);
            form.set('model.discount', null);
            form.set('model.dismissPeriod', null);
            form.set('model.district', null);
            form.set('model.energyDestiny', null);
            form.set('model.isContractTerminating', true);
            form.set('model.isNewConnection', null);
            form.set('model.loyalty', 'a45aebb5-9c31-4c41-b130-c91bbaccb6b4');
            form.set('model.loyaltyEnd', null);
            form.set('model.loyaltyStart', null);

            form.set('model.annexPriceFromLoyalty', null);
            form.set('model.discount', null);
            form.set('model.priceFromLoyalty', null);
            form.set('model.minimalPricePricelist', null);

            form.set('model.noticePeriodTime', null);
            form.set('model.os', null);
            form.set('model.osd', null);
            form.set('model.osdTariffGroup', null);
            form.set('model.pgnigTariffGroup', null);
            form.set('model.pointNumber', null);
            form.set('model.postalCode', null);
            form.set('model.postalCity', null);
            form.set('model.registrationNumber', null);
            form.set('model.settlementRules', null);
            form.set('model.startDate', null);
            form.set('model.street', null);
            form.set('model.streetNo', null);
            form.set('model.subscription', null);
            form.set('model.subscriptionValue', null);
            form.set('model.sellerChange', null);
            form.set('model.excise1', 0);
            form.set('model.excise2', 0);
            form.set('model.exciseA1', 0);
            form.set('model.exciseA2', 0);
            form.set('model.exciseA3', 0);
            form.set('model.exciseA4', 0);
            form.set('model.exciseA5', 0);
            form.set('model.exciseA6', 0);
            form.set('model.exciseA7', 0);
            form.set('model.exciseA8', 0);
            form.set('model.exciseB1', 0);
            form.set('model.exciseB2', 0);
            form.set('model.exciseB3', 0);
            form.set('model.exciseB4', 0);
            form.set('model.exciseB5', 0);
            form.set('model.exciseB6', 0);
            form.set('model.excisesSum', 0);
            this.set('osdTariff', null);
            this.set('subscriptionType', null);
            if (this.get('model.clientAddress.district') !== undefined || this.get('model.clientAddress.district') != null) {
                this.set('model.clientAddress.district', null);
            }
        },

        loyaltyStatusDate: (function () {
            if (this.get('loyaltyStatus') != '34f0d211-f3f0-4783-8506-bcaade547dbc') {
                return true;
            } else {
                return false;
            }
        }).property('loyaltyStatus'),

        contextualPowerValue: (function () {
            var powerArray = this.get('osdTariffArray');
            if (powerArray.indexOf(this.get('osdTariff')) !== -1) {
                return 110;
            } else {
                return null;
            }
        }).property('osdTariff'),

        isSubscriptionValueVisible: (function () {
            if (this.get('subscriptionType') == '92caf039-324e-496f-8603-26ef7d1863fc' || this.get('subscriptionType') == 'a8a80e31-8382-4593-8b24-f2ffb2f27a45') {
                return false;
            }
            return true;
        }).property('subscriptionType')

    });
});
define('ember-new/serializers/related-documents-view-model', ['exports', 'ember-new/serializers/application'], function (exports, _emberNewSerializersApplication) {
    exports['default'] = _emberNewSerializersApplication['default'].extend({
        primaryKey: 'clientUuid',
        relatedDocumentsViewModelSerializerInit: (function () {
            this.addAttrsConfig({
                client: { serialize: 'records', deserialize: 'records', noKey: true },
                contracts: { serialize: 'records', deserialize: 'records', noKey: true },
                renouncements: { serialize: 'records', deserialize: 'records', noKey: true }
            });
        }).on('init')
    });
});
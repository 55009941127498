define('ember-new/mixins/blog-post-serializer', ['exports', 'ember', 'ember-new/mixins/base-document-serializer'], function (exports, _ember, _emberNewMixinsBaseDocumentSerializer) {

    var BlogPostSerializerMixin = _ember['default'].Mixin.create(_emberNewMixinsBaseDocumentSerializer['default'], {

        onInit: (function () {
            this.addAttrsConfig({
                category: { serialize: 'id', deserialize: 'records' },
                content: { serialize: 'id', deserialize: 'records' }
            });
        }).on('init')

    });

    exports['default'] = BlogPostSerializerMixin;
});
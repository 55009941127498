define("ember-new/controllers/b2b-contract/administration/vas/edit", ["exports", "ember-new/mixins/controllers/b2b-contract/administration/add-vas", "ember"], function (exports, _emberNewMixinsControllersB2bContractAdministrationAddVas, _ember) {
    exports["default"] = _ember["default"].Controller.extend(_emberNewMixinsControllersB2bContractAdministrationAddVas["default"], {

        commandName: 'EditVasCommand',

        fillForm: function fillForm(form) {
            form.set('model.uuid', this.get('model.id'));
            form.set('model.name', this.get('model.vasName'));
            form.set('model.inclusionComponent', this.get('model.inclusionComponent'));
            form.set('model.idUsys', this.get('model.idUsys'));
            form.set('model.inclusionType', this.get('model.inclusionType'));
            form.set('model.valueType', this.get('model.valueType'));
            form.set('model.overheadRatePerPercentPoint', this.get('model.overheadRatePerPercentPoint'));
            form.set('model.baseValue', this.get('model.baseValue'));
            form.set('model.sellValue', this.get('model.sellValue'));
            form.set('model.overheadTypeForMM', this.get('model.overheadTypeForMM'));
            form.set('model.overheadForMM', this.get('model.overheadForMM'));
        }
    });
});
define('ember-new/controllers/tasks/add-reservation', ['exports', 'ember-new/config/environment', 'ember', 'ember-data'], function (exports, _emberNewConfigEnvironment, _ember, _emberData) {
	exports['default'] = _ember['default'].Controller.extend({
		applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),
		ajax: _ember['default'].inject.service('ajax'),

		savingInProgress: false,
		savingInProgress2: false,

		queryParams: ['cid'],
		cid: null,
		objectErrors: null,
		errors: null,
		rowErrors: null,
		users: [],

		eeReservationFor: null,
		gasReservationFor: null,

		client: null,

		onModel: (function () {
			if (this.get('model.users')) {
				this.set('users', this.store.peekAll('userForDropdown'));
			}

			this.set('client', this.store.peekRecord('ambBusinessClient', this.cid));
		}).observes('model'),

		reset: function reset() {
			this.set('objectErrors', null);
			this.set('errors', null);
			this.set('rowErrors', null);
			this.set('eeReservationFor', null);
			this.set('gasReservationFor', null);
		},

		actions: {
			save: function save(addTask) {

				if (addTask === true) {
					this.set('savingInProgress', false);
					this.set('savingInProgress2', true);
				} else {
					this.set('savingInProgress', true);
					this.set('savingInProgress2', false);
				}

				var _this = this;

				var data = { client: {} };

				if (this.get('model.allowReserveEe') === true) {
					data.client.eeReservationFor = this.get('eeReservationFor');
				}
				if (this.get('model.allowReserveGas') === true) {
					data.client.gasReservationFor = this.get('gasReservationFor');
				}

				var ajaxPromise = this.get('ajax').ajax(_emberNewConfigEnvironment['default'].apiUrl + '/rest/ambbusinessclients/' + this.cid + '/reservation', data, 'PATCH');

				ajaxPromise.then(function (response) {
					_this.set('savingInProgress', false);
					_this.set('savingInProgress2', false);
					_this.store.pushPayload(response);
					if (addTask !== undefined && addTask === true) {
						_this.transitionToRoute('tasks.add', _this.cid);
					} else {
						var route = _this.container.lookup("route:tasks");
						route.refresh();
						_this.transitionToRoute('tasks');
					}
				}, function (response) {
					_this.set('savingInProgress', false);
					_this.set('savingInProgress2', false);
					_this.set('errors', response.errors.children);
				});
			},
			saveAndAddTask: function saveAndAddTask() {
				this.send('save', true);
			}
		}
	});
});
define('ember-new/controllers/individual-contract-price-lists/template/conditions/edit', ['exports', 'ember', 'ember-new/mixins/individual-contract-price-lists/controllers/condition'], function (exports, _ember, _emberNewMixinsIndividualContractPriceListsControllersCondition) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsIndividualContractPriceListsControllersCondition['default'], {

        fillForm: function fillForm(form) {
            this.set('templateType', this.get('model.templateType'));

            form.set('model.uuid', this.get('model.uuid'));
            form.set('model.templateUuid', this.get('model.templateUuid'));
            form.set('model.type', this.get('model.type'));
            form.set('model.periodType', this.get('model.periodType'));
            form.set('model.yearFrom', this.get('model.yearFrom'));
            form.set('model.monthFrom', this.get('model.monthFrom'));
            form.set('model.yearTo', this.get('model.yearTo'));
            form.set('model.monthTo', this.get('model.monthTo'));
            form.set('model.priceG', this.get('model.priceG'));
            form.set('model.priceE1', this.get('model.priceE1'));
            form.set('model.priceE2', this.get('model.priceE2'));
            form.set('model.priceE3', this.get('model.priceE3'));
            form.set('model.priceE4', this.get('model.priceE4'));
            form.set('model.fee', this.get('model.fee'));
        }

    });
});
define('ember-new/controllers/b2b-contract/administration/vas', ['exports', 'ember', 'ember-new/mixins/paginable-controller'], function (exports, _ember, _emberNewMixinsPaginableController) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsPaginableController['default'], {

        valueTypePercent: 'percent',
        valueTypePLN: 'PLN',
        inclusionTypeDiscount: 'discount',
        inclusionTypeIncrease: 'increase',

        //składnik doliczenia
        inclusionEeMwh: 'ee_mwh',
        inclusionEeTradeFee: 'ee_trade_fee',
        inclusionGasMwh: 'gas_fuel_mwh',
        inclusionGasSubscription: 'gas_subscription',

        overheadAuto: 'auto',
        overheadConst: 'const'

    });
});
define('ember-new/routes/b2b-contract/dzu/show-gas/ppg/details/invoices/edit-invoice', ['exports', 'ember-new/routes/authenticated', 'ember'], function (exports, _emberNewRoutesAuthenticated, _ember) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({

        ppgId: null,

        model: function model(params) {
            var targetModel = this.modelFor('b2b-contract.dzu.show-gas.ppg.details');
            this.set('ppgId', targetModel.get('uuid'));
            this.set('invoiceId', params.invoiceId);

            return this.store.findRecord('BusinessGasInvoiceViewModel', params.invoiceId, { reload: true });
        },

        setupController: function setupController(ctrl, model) {
            ctrl.set('model', model);
            ctrl.set('invoiceId', this.get('invoiceId'));
            ctrl.set('ppgId', this.get('ppgId'));
        },

        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            }
        }

    });
});
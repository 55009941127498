define('ember-new/models/business-settings-view-model', ['exports', 'ember-new/models/abstract', 'ember-data'], function (exports, _emberNewModelsAbstract, _emberData) {

  var float = _emberData['default'].attr('number');
  var date = _emberData['default'].attr('string');

  exports['default'] = _emberNewModelsAbstract['default'].extend({
    completingConsumptionDeclarationsEE: float,
    completingConsumptionDeclarationsGas: float,
    fillingAttachmentsEE: float,
    fillingAttachmentsGas: float,
    fillingContractDocumentsEE: float,
    fillingContractDocumentsGas: float,
    confirmationLoyalistsEE: float,
    confirmationLoyalistsGas: float,
    obtainingDistributionEE: float,
    obtainingDistributionGas: float,
    failProvideApprovalsEE: float,
    failProvideApprovalsGas: float,
    defaultCalculatingRateEE: float,
    defaultCalculatingRateGas: float,
    defaultDateOWUEE: date,
    defaultDateOWUGas: date
  });
});
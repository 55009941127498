define('ember-new/models/business-ee-contract-salesman-mm-info', ['exports', 'ember-new/models/abstract'], function (exports, _emberNewModelsAbstract) {

    var string = DS.attr('string');

    exports['default'] = _emberNewModelsAbstract['default'].extend({
        consumptionByDzu: string,
        consumptionBySalesman: string,
        mmByDzu: string,
        mmBySalesman: string,
        overheadByDzu: string,
        overheadBySalesman: string,
        volumeByDzu: string,
        volumeBySalesman: string,
        numberOfMonthsBySalesman: string,
        ppeInfo: DS.hasMany('BusinessPpeInfo', { embedded: true })
    });
});
define("ember-new/templates/components/copy-contract", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 7,
                  "column": 8
                },
                "end": {
                  "line": 9,
                  "column": 8
                }
              },
              "moduleName": "ember-new/templates/components/copy-contract.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "d-field-text", [], ["type", "hidden", "key", "contractId"], ["loc", [null, [8, 12], [8, 59]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 4
              },
              "end": {
                "line": 16,
                "column": 4
              }
            },
            "moduleName": "ember-new/templates/components/copy-contract.hbs"
          },
          isEmpty: false,
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            var el2 = dom.createTextNode("Nowa umowa\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("strong");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            na podstawie umowy nr: ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("strong");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [2]);
            var element1 = dom.childAt(element0, [1]);
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(element1, 1, 1);
            morphs[2] = dom.createMorphAt(element1, 3, 3);
            morphs[3] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [["block", "d-form-row", [], ["hidden", true], 0, null, ["loc", [null, [7, 8], [9, 23]]]], ["inline", "if", [["subexpr", "eq", [["get", "contract.type", ["loc", [null, [12, 25], [12, 38]]]], "ee"], [], ["loc", [null, [12, 21], [12, 44]]]], "EE"], [], ["loc", [null, [12, 16], [12, 51]]]], ["inline", "if", [["subexpr", "eq", [["get", "contract.type", ["loc", [null, [13, 25], [13, 38]]]], "gas"], [], ["loc", [null, [13, 21], [13, 45]]]], "GAZ"], [], ["loc", [null, [13, 16], [13, 53]]]], ["inline", "if", [["get", "contract.contractNo", ["loc", [null, [15, 48], [15, 67]]]], ["get", "contract.contractNo", ["loc", [null, [15, 68], [15, 87]]]], "BRAK NR"], [], ["loc", [null, [15, 43], [15, 99]]]]],
          locals: ["formData", "formContents"],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "fragmentReason": {
            "name": "missing-wrapper",
            "problems": ["wrong-type"]
          },
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 18,
              "column": 0
            }
          },
          "moduleName": "ember-new/templates/components/copy-contract.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["block", "d-form-command", [], ["commandName", ["subexpr", "@mut", [["get", "commandName", ["loc", [null, [3, 34], [3, 45]]]]], [], []], "afterSuccessfulCommand", ["subexpr", "@mut", [["get", "afterCommand", ["loc", [null, [4, 45], [4, 57]]]]], [], []], "submitButtonText", "Skopiuj umowę", "fillCommandModel", ["subexpr", "@mut", [["get", "fillForm", ["loc", [null, [6, 39], [6, 47]]]]], [], []]], 0, null, ["loc", [null, [3, 4], [16, 23]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.3.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 18,
            "column": 18
          }
        },
        "moduleName": "ember-new/templates/components/copy-contract.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "subpage-modal", [], ["subpageTitle", "Kopiowanie umowy", "modalCloseRoute", ["subexpr", "@mut", [["get", "modalCloseRoute", ["loc", [null, [1, 65], [1, 80]]]]], [], []]], 0, null, ["loc", [null, [1, 0], [18, 18]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});
define('ember-new/components/loading-action-spinner', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        classNames: ['loading_action_spinner'],
        classNameBindings: ['floatClass', 'extraClasses'],
        tagName: 'div',
        isVisible: false,
        showText: true,
        float: null,
        floatClass: (function () {
            var float = this.get('float');
            if (float === 'left') {
                return 'pull-left';
            } else if (float === 'right') {
                return 'pull-right';
            }
            return '';
        }).property('float'),
        extraClasses: null,
        isVisibleChanged: (function () {
            var isVisible = this.get('isVisible');
            var componentDiv = _ember['default'].$(this.get('element'));

            if (isVisible) {
                componentDiv.show();
            } else {
                componentDiv.hide();
            }
        }).observes('isVisible'),
        spinnerClass: (function () {
            if (this.get('type') === 'dark') {
                return 'css_spinner_small_dark spinner';
            }

            return 'css_spinner_small spinner';
        }).property('type'),
        type: 'white'
    });
});
define('ember-new/controllers/b2b-contract/administration/section-pricelists/list/delete', ['exports', 'ember', 'ember-new/config/environment'], function (exports, _ember, _emberNewConfigEnvironment) {
    exports['default'] = _ember['default'].Controller.extend({
        ajax: _ember['default'].inject.service('ajax'),
        applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),

        actions: {
            'delete': function _delete() {
                var _this = this;

                var serializedData = {
                    DeleteBasePricelistCommand: {
                        uuid: this.get('idPriceSet')
                    }
                };

                this.get('ajax').ajaxPost(_emberNewConfigEnvironment['default'].apiUrl + '/rest/deletebasepricelistcommands', serializedData).then(function () {
                    var route = _this.container.lookup('route:b2b-contract.administration.section-pricelists');
                    route.refresh();
                    _this.get('applicationAlerts').addActionMessage('success', "Cennik bazowy został usunięty");
                    _this.transitionToRoute('b2b-contract.administration.section-pricelists.list');
                }, function () {
                    _this.get('applicationAlerts').addActionMessage('warning', "Wystąpił błąd, nie udało się usunąć cennika bazowego");
                });
            }
        }
    });
});
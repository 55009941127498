define('ember-new/components/d-field-select-dictionary-uuid', ['exports', 'ember', 'ember-new/components/d-field-select'], function (exports, _ember, _emberNewComponentsDFieldSelect) {
	exports['default'] = _emberNewComponentsDFieldSelect['default'].extend({

		dictionariesManager: _ember['default'].inject.service('dictionaries-manager'),
		optionLabelPath: "label",
		optionValuePath: "value",

		// Customization for non-standard collections (ex. status-definitions)
		optionLabelFieldName: "label", // default key-field name (for custom dictionaries: ex. 'numberAndName' for dictionary status-definition)
		optionValueFieldName: "uuid", // default key-field name to get item value (uuid/id etc..)
		additionalFilterPropName: null, // property to filter by
		additionalFilterPropValue: null, // property value,

		includeHidden: false, // true, false lub UUID
		excludedUuids: [],

		// requred options:
		dictionaryName: null,

		initialize: (function () {
			var dictionaryType = this.get('dictionaryName');

			if (dictionaryType === undefined || dictionaryType === null || typeof dictionaryType !== 'string') {
				throw new _ember['default'].Error('Component d-field-select-dictionary requires `dictionaryName` option.');
			}

			this.setupDictionaryContent();
		}).on('init'),

		setupDictionaryContent: (function () {
			var dictionaryType = this.get('dictionaryName');
			var includeHidden = this.get('includeHidden');
			var excludedUuids = this.get('excludedUuids');
			var labelName = this.get('optionLabelFieldName'),
			    valueName = this.get('optionValueFieldName'),
			    additionalFilterName = this.get('additionalFilterPropName'),
			    additionalFilterValue = this.get('additionalFilterPropValue'); // string

			var dicManager = this.get('dictionariesManager');

			if (!dicManager.hasMainDictionary(dictionaryType)) {
				throw new _ember['default'].Error("Option 'dictionaryName` passed to d-field-select-dictionary component is wrong. There is no `" + dictionaryType + "` main-dictionary.");
			}

			var content = dicManager.getMainDictionary(dictionaryType);
			var selectArray = [];

			// filtrowanie po customowym polu
			if (additionalFilterName && additionalFilterValue) {
				content = content.filter(function (item) {
					return item.get(additionalFilterName) === additionalFilterValue;
				});
			}
			content.forEach(function (item) {
				if (!includeHidden && item.get('isHidden') !== true && !excludedUuids.includes(item.get('uuid'))) {
					// wszystkie poza ukrytymi
					selectArray.push({ label: item.get(labelName), value: item.get(valueName) });
				} else if (item.get('uuid') === includeHidden && item.get('isHidden') === true) {
					// wszystkie poza ukrytymi + ukryty z konkretym ID
					selectArray.push({ label: item.get(labelName), value: item.get(valueName) });
				} else if (includeHidden) {
					selectArray.push({ label: item.get(labelName), value: item.get(valueName) });
				}
			});

			if (typeof this.customActionBeforeSetContent === 'function') {
				content = this.customActionBeforeSetContent.call(this, content);
			}
			this.set('content', selectArray);
		}).observes('dictionaryName')
	});
});
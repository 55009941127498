define('ember-new/controllers/individual-contract-partners/edit-contract', ['exports', 'ember', 'ember-new/mixins/controllers/individual-contract-partners/partner-edit-contract'], function (exports, _ember, _emberNewMixinsControllersIndividualContractPartnersPartnerEditContract) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsControllersIndividualContractPartnersPartnerEditContract['default'], {
        dictionariesManager: _ember['default'].inject.service('dictionaries-manager'),

        commandName: (function () {
            var duonPartnerId = this.get('TheUser').get('model.duonPartner');
            var isContractNumberGenerated = true;

            if (duonPartnerId) {
                isContractNumberGenerated = this.get('dictionariesManager').getMainDictionaryEntryByMongoId('duon_partner', duonPartnerId).get('isAutoGeneratedContractNumber');
            }

            if (isContractNumberGenerated === true) {
                return 'EditIndividualContractPartnerWithoutContractNumberCommand';
            } else {
                return 'EditIndividualContractPartnerEditCommand';
            }
        }).property(),

        isContractNumberDisabled: (function () {
            var duonPartnerId = this.get('TheUser').get('model.duonPartner');
            if (!duonPartnerId) return true;

            var duonPartnerData = this.get('dictionariesManager').getMainDictionaryEntryByMongoId('duon_partner', duonPartnerId);

            return duonPartnerData.get('isAutoGeneratedContractNumber');
        }).property(),

        getInfoByPesel: null, // override
        fillForm: function fillForm(form) {
            var _this = this;

            this.set('formData', form.get('formData'));
            var fields = this.get('formFieldsForMappingStates'); // see mixin...
            // set all fields
            fields.forEach(function (field) {
                form.set('model.' + field, _this.get('model.' + field));
            });

            // set custom named fields... (different name from list model and form endpoint) clientFirstName', 'clientLastName', 'clientPhone',
            form.set('model.clientUuid', this.get('model.clientUuid'));
            form.set('model.clientFirstName', this.get('model.clientName'));
            form.set('model.clientLastName', this.get('model.clientSurname'));
            form.set('model.clientPhone', this.get('model.clientPhoneNumber'));
            form.set('model.clientPhone2', this.get('model.clientPhoneNumber2'));
            form.set('model.clientPesel', this.get('model.clientPESEL'));
            form.set('model.contractId', this.get('model.uuid'));
            form.set('model.gasContractNo', this.get('model.contractNo'));
            form.set('model.tariffGroupUuid', this.get('model.tariffGroupUuid'));
            form.set('model.gasTariffUuid', this.get('model.tariffGroupUuid'));
            form.set('model.foreigner', this.get('model.foreigner'));
            form.set('model.partnerName', this.get('model.partnerName'));
            form.set('model.branch', this.get('model.userWhoSigned'));
            form.set('model.loyaltyDate', this.get('model.loyaltyDate'));
        },

        branchReadOnly: (function () {
            if (this.get('TheUser').isMainRoleIn(['ROLE_SALES_SALESMAN'])) {
                return true;
            }
            return false;
        }).property()
    });
});
define('ember-new/components/d-field-select', ['exports', 'ember', 'ember-new/mixins/d-form/d-field'], function (exports, _ember, _emberNewMixinsDFormDField) {
    exports['default'] = _ember['default'].Component.extend(_emberNewMixinsDFormDField['default'], {
        optionLabelPath: 'label',
        optionValuePath: 'value',
        attributeBindings: ['style'],
        allowClear: true,
        disabled: false,
        allowSearch: true, // display searchbox
        style: 'width:100%',
        content: null,
        tags: false, // allow combo select (new values from user)
        _select2: null,
        _select2Options: null,
        _select2Inited: false,
        _preventOnChange: false,
        _preventValueObserver: false,
        selectStyle: 'width:100% !important',
        multiValue: [],
        prompt: '-- wybierz --',

        initializeComponent: (function () {
            var selectOptions = {};
            var prompt = this.get('prompt');

            if (prompt != null) {
                selectOptions.placeholder = prompt;
                selectOptions.allowClear = this.get('allowClear');
                selectOptions.minimumResultsForSearch = this.get('allowSearch') ? 0 : -1;
            }

            selectOptions.multiple = this.get('isMultiple');
            selectOptions.tags = this.get('tags'); // combo select

            this.set('_select2Options', selectOptions);
        }).on('init'),

        initSelect2: (function () {
            var _this = this,
                options = this.get('_select2Options');
            var elementId = this.get('elementId');

            options.val = this.get('value');
            var select2 = $('#'.concat(elementId, ' select')).select2(options);
            select2.val([]);

            $(document).on('click', 'span.select2', function (e) {
                if (e.originalEvent) {
                    $(this).siblings('select:not([disabled])').select2('open');
                }
            });

            $('#'.concat(elementId, ' .select2')).attr('style', this.get('selectStyle'));

            this.set('_select2', select2);

            select2.on('select2:opening', function (e) {
                if (select2.data('unselecting')) {
                    select2.removeData('unselecting');

                    setTimeout(function () {
                        select2.select2('close');
                    }, 1);
                }
            }).on('select2:unselecting', function (e) {
                select2.data('unselecting', true);
            });

            select2.on('change', _ember['default'].run.bind(this, function (e) {
                if (true === _this.get('_preventOnChange')) {
                    return;
                }

                var isMultiple = _this.get('isMultiple');
                var value = _this.get('_select2').val();

                if (isMultiple) return;

                if (_this.get('optionValuePath') == 'content') {
                    var modelValue = null;

                    _this.get('content').forEach(function (item) {
                        if (typeof item === 'object' && item.toString() == value) {
                            modelValue = item;
                        }
                    });

                    value = modelValue;
                }

                _this.set('_preventValueObserver', true);
                _this.set('value', value);
                _this.set('_preventValueObserver', false);
            }));

            this.set('_select2Inited', true);
        }).on('didInsertElement'),

        closeSelect2: (function () {
            this.get('_select2').select2('close');
        }).on('willDestroyElement'),

        isMultiple: (function () {
            return this.get('multiple') === true ? true : false;
        }).property('multiple'),

        isTags: (function () {
            return this.get('tags') === true ? true : false;
        }).property('tags'),

        updateSelect2Value: (function () {
            if (true === this.get('_preventValueObserver')) {
                return;
            }

            var value = this.get('value');
            if (value instanceof DS.PromiseObject) {
                this.set('value', value.get('content'));
            }

            if (this.get('_select2Inited')) {
                this.set('_preventOnChange', true);
                this.get('_select2').val(this.get('value') ? this.get('value').toString() : null);
                this.get('_select2').trigger('change');
                this.set('_preventOnChange', false);
            }
        }).observes('value', '_select2Inited'),

        clearValue: function clearValue() {
            this.get('_select2', null).val(null).trigger('change');
        },

        disabledState: function disabledState(value) {
            this.set('disabled', value);
            $("#" + this.elementId + ' input').bootstrapSwitch('disabled', value, true);
        },

        actions: {
            highlightField: function highlightField() {
                this.get('_select2').data('select2').$container.find('.select2-selection').effect('highlight', {}, 1000);
            },

            setSelection: function setSelection(event) {
                if (this._state == 'inDOM') {
                    var value = event.target.value;
                    this.set('value', value);
                }
            }
        }
    });
});
define("ember-new/controllers/tasks/task/verification-status", ["exports", "ember-new/config/environment", "ember-new/controllers/abstract-task-modal", "ember"], function (exports, _emberNewConfigEnvironment, _emberNewControllersAbstractTaskModal, _ember) {

    var TaskVerificationStatusController = _emberNewControllersAbstractTaskModal["default"].extend({
        needs: ['tasks'],
        ajax: _ember["default"].inject.service('ajax'),

        cerrors: null,

        onInit: (function () {
            var _this = this;
            this.set('cerrors', null);
        }).on('init'),

        actions: {
            closeAction: function closeAction() {
                this.transitionToRoute('tasks');
            },

            save: function save() {
                var _this = this;
                this.set('savingInProgress', 'loading');

                var data = {
                    form: {
                        verificationstatus: this.get('verMeetingResult')
                    }
                };

                var ajaxPromise = this.get('ajax').ajax(_emberNewConfigEnvironment["default"].apiUrl + '/rest/tasks/' + this.get('model').get('id') + '/changeverificationstatus', data, 'PATCH');

                ajaxPromise.then(function (response) {
                    _this.set('savingInProgress', 'reset');
                    _this.store.pushPayload(response);
                    _this.transitionToRoute('tasks');
                    _this.get('target').send('refreshContractItemModel', _this.get('model').get('id'));
                    _this.set('savingInProgress', false);
                    _this.set('verMeetingResult', null);
                }, function (response) {
                    _this.set('savingInProgress', false);
                    _this.set('cerrors', _ember["default"].Object.create(response.errors.children));
                });
            }
        }

    });

    exports["default"] = TaskVerificationStatusController;
});
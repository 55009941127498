define('ember-new/routes/b2b-contract/administration/section-general', ['exports', 'ember-new/routes/authenticated'], function (exports, _emberNewRoutesAuthenticated) {
  exports['default'] = _emberNewRoutesAuthenticated['default'].extend({

    model: function model() {
      return this.store.findRecord('BusinessSettingsViewModel', '1bccaea9-3e9b-4c20-bb8b-de3b91c194c4', { reload: true });
    },

    setupController: function setupController(ctrl, model) {
      ctrl.set('model', model);
    },

    actions: {
      refreshModelAction: function refreshModelAction() {
        this.refresh();
      }
    }

  });
});
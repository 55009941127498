define('ember-new/mixins/sortable-controller', ['exports', 'ember', 'ember-new/config/environment'], function (exports, _ember, _emberNewConfigEnvironment) {
    exports['default'] = _ember['default'].Mixin.create({

        queryParams: ['sortBy', 'sortModeAsc'],

        sortBy: null,
        sortModeAsc: true,

        actions: {
            sort: function sort(field) {
                if (this.get('sortBy') === field) {
                    this.set('sortModeAsc', !this.get('sortModeAsc'));
                } else {
                    this.set('sortBy', field);
                    this.set('sortModeAsc', true);
                }
            }
        }

    });
});
define("ember-new/controllers/b2b-contract/dzu/show-gas/prices/remove-price", ["exports", "ember", "ember-new/mixins/controllers/simple-ajax-controller"], function (exports, _ember, _emberNewMixinsControllersSimpleAjaxController) {
    exports["default"] = _ember["default"].Controller.extend(_emberNewMixinsControllersSimpleAjaxController["default"], {

        clientModel: null,

        commandName: "RemovePriceConditionDzuCommand",
        commandUrl: '/rest/removepriceconditiondzucommands',
        command: function command() {
            return {
                RemovePriceConditionDzuCommand: {
                    id: this.get('model.id')
                }
            };
        },
        successInfo: 'Operacja zakończona poprawnie',
        errorInfo: 'Operacja zakończona niepoprawnie',

        afterSuccessfulCommand: function afterSuccessfulCommand() {
            var ctrl = this;
            var route = this.container.lookup('route:b2b-contract.dzu.show-gas.prices');
            route.refresh();
            setTimeout(function () {
                ctrl.transitionToRoute('b2b-contract.dzu.show-gas.prices');
            }, 500);
        }

    });
});
define('ember-new/mixins/base-statusable-document-serializer', ['exports', 'ember', 'ember-new/mixins/base-document-serializer'], function (exports, _ember, _emberNewMixinsBaseDocumentSerializer) {

    var BaseStatusableDocumentSerializerMixin = _ember['default'].Mixin.create(_emberNewMixinsBaseDocumentSerializer['default'], {
        baseStatusableDocumentSerializerInit: (function () {
            this.addAttrsConfig({
                status: { serialize: null, deserialize: 'records', noKey: true }
            });
        }).on('init')
    });

    exports['default'] = BaseStatusableDocumentSerializerMixin;
});
define('ember-new/routes/individual-contract-price-lists/template/vaps', ['exports', 'ember', 'ember-new/routes/authenticated'], function (exports, _ember, _emberNewRoutesAuthenticated) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({

        beforeModel: function beforeModel(transition) {
            window.scrollTo(0, 0); // scroll to the top of application before reloading
        },

        model: function model(params) {
            var route = this;

            return _ember['default'].RSVP.hash({
                template: route.get('store').findRecord('IndividualContractTemplateListViewModel', params.templateUuid, { reload: true }),
                assignedVaps: route.get('store').query('IndividualContractTemplateVapListViewModel', { templateUuid: params.templateUuid }, { reload: true }),
                vaps: route.get('store').query('IndividualContractVapListViewModel', {}, { reload: true })
            });
        },

        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            }

        }

    });
});
define('ember-new/routes/b2b-contract/dzu/show-gas/contract-status', ['exports', 'ember-new/routes/authenticated'], function (exports, _emberNewRoutesAuthenticated) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({
        model: function model() {
            this.store.unloadAll('ContractStatusViewModel');

            var contract = this.modelFor('b2b-contract.dzu.show-gas');
            this.set('idContract', contract.get('id'));

            return this.store.findRecord('ContractStatusViewModel', contract.get('id'), { reload: true });
        },

        setupController: function setupController(controller, model) {
            controller.set('model', model);
        }

    });
});
define('ember-new/controllers/individual-contract/show-gas/sells/unassign-renouncement', ['exports', 'ember', 'ember-new/mixins/controllers/simple-ajax-controller'], function (exports, _ember, _emberNewMixinsControllersSimpleAjaxController) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsControllersSimpleAjaxController['default'], {

        renouncementModel: null,

        commandModel: 'UnassignRenouncementCommand',
        commandUrl: '/rest/unassignrenouncementcommands',

        command: function command() {
            return {
                UnassignRenouncementCommand: {
                    uuid: this.get('model.renouncement_id')
                }
            };
        },

        successInfo: 'Odstąpienie zostało poprawnie odłączone',
        errorInfo: 'Operacja nie powiodła się',

        afterSuccessfulCommand: function afterSuccessfulCommand() {
            var controller = this;
            var route = this.container.lookup('route:individual-contract.show-gas.sells');
            route.send('refreshModel');
            setTimeout(function () {
                controller.transitionToRoute('individual-contract.show-gas.sells');
            }, 500);
        }
    });
});
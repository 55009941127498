define('ember-new/mixins/client-serializer', ['exports', 'ember', 'ember-new/mixins/base-document-serializer'], function (exports, _ember, _emberNewMixinsBaseDocumentSerializer) {
	exports['default'] = _ember['default'].Mixin.create(_emberNewMixinsBaseDocumentSerializer['default'], {

		AddressSerializerInit: (function () {
			this.addAttrsConfig({
				address: { serialize: 'records', deserialize: 'records', noKey: true },
				contactAddress: { serialize: 'records', deserialize: 'records', noKey: true },
				gasContactAddress: { serialize: 'records', deserialize: 'records', noKey: true },
				//contactPerson: {serialize: 'records', deserialize: 'records', noKey: true},
				//gasContactPerson: {serialize: 'records', deserialize: 'records', noKey: true},
				//settlementsContactPerson: {serialize: 'records', deserialize: 'records', noKey: true},
				boSpecialist: { serialize: 'id', deserialize: 'records' }
			});
		}).on('init'),
		AmbBusinessClientSerializerInit: (function () {
			this.addAttrsConfig({
				representatives: { embedded: 'always', noKey: true },
				gasRepresentatives: { embedded: 'always', noKey: true },
				contactPersons: { embedded: 'always', noKey: true },
				gasContactPersons: { embedded: 'always', noKey: true },
				settlementsContactPerson: { embedded: 'always', noKey: true },
				gasSettlementsContactPerson: { embedded: 'always', noKey: true },
				//reservedBy: {serialize: 'id', deserialize: 'records'},
				//reservedFor: {serialize: 'id', deserialize: 'records'},
				//reservationAcquisitionProcess: {serialize: 'id', deserialize: 'records'},
				//activeAcquisitionProcess: {serialize: 'id', deserialize: 'records'},
				companyDocKrs: { serialize: 'id', deserialize: 'records' },
				companyDocNip: { serialize: 'id', deserialize: 'records' },
				companyDocRegon: { serialize: 'id', deserialize: 'records' },
				companyDocBre: { serialize: 'id', deserialize: 'records' }
			});
		}).on('init')
	});
});
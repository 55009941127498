define("ember-new/routes/dashboard", ["exports", "ember-new/routes/authenticated", "ember"], function (exports, _emberNewRoutesAuthenticated, _ember) {

    //@todo authenticate
    var DashboardRoute = _emberNewRoutesAuthenticated["default"].extend({

        showLoading: false,

        session: _ember["default"].inject.service('session:main'),

        model: function model() {
            this.store.unloadAll('BlogPost');

            return _ember["default"].RSVP.hash({
                posts: this.store.findAll('BlogPost'),
                categories: this.store.findAll('BlogCategory'),
                sitebars: this.store.findAll('BlogSitebar'),
                redalertlogin: EmberNew.redalertlogin
            });
        },

        setupController: function setupController(ctrl, model) {
            this._super.apply(this, arguments);
            ctrl.set('posts', model.posts);
            ctrl.set('categories', model.categories);
            ctrl.set('sitebars', model.sitebars);
            ctrl.set('redalertlogin', model.redalertlogin);
        },

        actions: {
            refreshModel: function refreshModel() {
                this.store.unloadAll('BlogPost');
                this.set('showLoading', true);
                this.refresh();
                this.set('showLoading', false);
            }
        }
    });

    exports["default"] = DashboardRoute;
});
define("ember-new/templates/dashboard/add", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 9,
                  "column": 18
                },
                "end": {
                  "line": 12,
                  "column": 18
                }
              },
              "moduleName": "ember-new/templates/dashboard/add.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "d-field-select", [], ["prompt", "--", "key", "category", "content", ["subexpr", "@mut", [["get", "category", ["loc", [null, [11, 72], [11, 80]]]]], [], []]], ["loc", [null, [11, 20], [11, 82]]]]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 14,
                  "column": 18
                },
                "end": {
                  "line": 16,
                  "column": 18
                }
              },
              "moduleName": "ember-new/templates/dashboard/add.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "d-field-select", [], ["key", "contentType", "content", ["subexpr", "@mut", [["get", "attachmentType", ["loc", [null, [15, 63], [15, 77]]]]], [], []], "value", ["subexpr", "@mut", [["get", "contentType", ["loc", [null, [15, 84], [15, 95]]]]], [], []]], ["loc", [null, [15, 20], [15, 97]]]]],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 18,
                  "column": 18
                },
                "end": {
                  "line": 20,
                  "column": 18
                }
              },
              "moduleName": "ember-new/templates/dashboard/add.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "drop-zone", [], ["key", "dropzone", "fileTypes", "audio/*, image/*, application/pdf", "customUrl", ["subexpr", "@mut", [["get", "customUrl", ["loc", [null, [19, 103], [19, 112]]]]], [], []], "paramName", ["subexpr", "@mut", [["get", "paramName", ["loc", [null, [19, 123], [19, 132]]]]], [], []], "legacyMode", true], ["loc", [null, [19, 20], [19, 150]]]]],
            locals: [],
            templates: []
          };
        })();
        var child3 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 22,
                  "column": 18
                },
                "end": {
                  "line": 24,
                  "column": 18
                }
              },
              "moduleName": "ember-new/templates/dashboard/add.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "d-field-text", [], ["key", "content", "value", ["subexpr", "@mut", [["get", "fileId", ["loc", [null, [23, 55], [23, 61]]]]], [], []]], ["loc", [null, [23, 20], [23, 63]]]]],
            locals: ["value"],
            templates: []
          };
        })();
        var child4 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 26,
                  "column": 18
                },
                "end": {
                  "line": 28,
                  "column": 18
                }
              },
              "moduleName": "ember-new/templates/dashboard/add.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "d-field-text", [], ["key", "contentVimeo"], ["loc", [null, [27, 20], [27, 55]]]]],
            locals: [],
            templates: []
          };
        })();
        var child5 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 30,
                  "column": 18
                },
                "end": {
                  "line": 32,
                  "column": 18
                }
              },
              "moduleName": "ember-new/templates/dashboard/add.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "d-field-text", [], ["key", "contentYoutube"], ["loc", [null, [31, 20], [31, 57]]]]],
            locals: [],
            templates: []
          };
        })();
        var child6 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 34,
                  "column": 18
                },
                "end": {
                  "line": 36,
                  "column": 18
                }
              },
              "moduleName": "ember-new/templates/dashboard/add.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "d-field-text", [], ["key", "contentHtml"], ["loc", [null, [35, 20], [35, 54]]]]],
            locals: [],
            templates: []
          };
        })();
        var child7 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 38,
                  "column": 18
                },
                "end": {
                  "line": 40,
                  "column": 18
                }
              },
              "moduleName": "ember-new/templates/dashboard/add.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "d-field-textarea", [], ["key", "contentTextAttachment", "rows", 5], ["loc", [null, [39, 20], [39, 75]]]]],
            locals: [],
            templates: []
          };
        })();
        var child8 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 42,
                  "column": 18
                },
                "end": {
                  "line": 44,
                  "column": 18
                }
              },
              "moduleName": "ember-new/templates/dashboard/add.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "d-field-checkbox", [], ["key", "isArchived", "checked", false], ["loc", [null, [43, 20], [43, 71]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 2
              },
              "end": {
                "line": 50,
                "column": 2
              }
            },
            "moduleName": "ember-new/templates/dashboard/add.hbs"
          },
          isEmpty: false,
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-md-10");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("form");
            dom.setAttribute(el3, "class", "form-horizontal");
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "box-body");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1, 1, 1, 1]);
            var morphs = new Array(9);
            morphs[0] = dom.createMorphAt(element0, 1, 1);
            morphs[1] = dom.createMorphAt(element0, 3, 3);
            morphs[2] = dom.createMorphAt(element0, 5, 5);
            morphs[3] = dom.createMorphAt(element0, 7, 7);
            morphs[4] = dom.createMorphAt(element0, 9, 9);
            morphs[5] = dom.createMorphAt(element0, 11, 11);
            morphs[6] = dom.createMorphAt(element0, 13, 13);
            morphs[7] = dom.createMorphAt(element0, 15, 15);
            morphs[8] = dom.createMorphAt(element0, 17, 17);
            return morphs;
          },
          statements: [["block", "d-form-row", [], ["label", "Kategoria", "widthLabelClass", "col-md-4 col-sm-4", "widthFieldClass", "col-md-8 col-sm-8"], 0, null, ["loc", [null, [9, 18], [12, 33]]]], ["block", "d-form-row", [], ["label", "Rodzaj załącznika", "widthLabelClass", "col-md-4 col-sm-4", "widthFieldClass", "col-md-8 col-sm-8"], 1, null, ["loc", [null, [14, 18], [16, 33]]]], ["block", "d-form-row", [], ["label", "Załącznik", "hidden", ["subexpr", "@mut", [["get", "contentHidden", ["loc", [null, [18, 57], [18, 70]]]]], [], []]], 2, null, ["loc", [null, [18, 18], [20, 33]]]], ["block", "d-form-row", [], ["hidden", true], 3, null, ["loc", [null, [22, 18], [24, 33]]]], ["block", "d-form-row", [], ["label", "Link do filmu Vimeo", "widthLabelClass", "col-md-4 col-sm-4", "widthFieldClass", "col-md-8 col-sm-8", "hidden", ["subexpr", "@mut", [["get", "contentVimeoHidden", ["loc", [null, [26, 139], [26, 157]]]]], [], []]], 4, null, ["loc", [null, [26, 18], [28, 33]]]], ["block", "d-form-row", [], ["label", "Link do filmu Youtube", "widthLabelClass", "col-md-4 col-sm-4", "widthFieldClass", "col-md-8 col-sm-8", "hidden", ["subexpr", "@mut", [["get", "contentYoutubeHidden", ["loc", [null, [30, 141], [30, 161]]]]], [], []]], 5, null, ["loc", [null, [30, 18], [32, 33]]]], ["block", "d-form-row", [], ["label", "Tytuł", "widthLabelClass", "col-md-4 col-sm-4", "widthFieldClass", "col-md-8 col-sm-8", "hidden", ["subexpr", "@mut", [["get", "hideAttachmentField", ["loc", [null, [34, 125], [34, 144]]]]], [], []]], 6, null, ["loc", [null, [34, 18], [36, 33]]]], ["block", "d-form-row", [], ["hidden", ["subexpr", "@mut", [["get", "contentTextAttachmentHidden", ["loc", [null, [38, 39], [38, 66]]]]], [], []], "label", "Treść załącznika"], 7, null, ["loc", [null, [38, 18], [40, 33]]]], ["block", "d-form-row", [], ["label", "Archiwizuj post"], 8, null, ["loc", [null, [42, 18], [44, 33]]]]],
          locals: ["formData", "formContents"],
          templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8]
        };
      })();
      return {
        meta: {
          "fragmentReason": {
            "name": "missing-wrapper",
            "problems": ["wrong-type"]
          },
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 51,
              "column": 0
            }
          },
          "moduleName": "ember-new/templates/dashboard/add.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "d-form-command", [], ["commandName", "blogPost", "afterSuccessfulCommand", ["subexpr", "@mut", [["get", "afterCommand", ["loc", [null, [3, 66], [3, 78]]]]], [], []], "fillCommandModel", ["subexpr", "@mut", [["get", "fillForm", ["loc", [null, [3, 96], [3, 104]]]]], [], []]], 0, null, ["loc", [null, [3, 2], [50, 21]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.3.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 51,
            "column": 18
          }
        },
        "moduleName": "ember-new/templates/dashboard/add.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "subpage-modal", [], ["subpageTitle", "Dodaj post", "modalSize", "modal-lg", "extraClass", "margin-top-90", "modalCloseRoute", "dashboard"], 0, null, ["loc", [null, [1, 0], [51, 18]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});
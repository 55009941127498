define("ember-new/utils/get-query-params-values", ["exports"], function (exports) {
    exports["default"] = getQueryParamsValues;
    /**
     *
     * @param context kontekst na ktorym wyszukujemy wartosci (najczesciej this)
     * @param queryParamsObject
     * @returns {{}}
     */

    function getQueryParamsValues(context) {
        var queryParamsObject = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

        var result = {};

        if (!context) return result;

        Object.keys(queryParamsObject).map(function (queryParamName) {
            var val = context.get(queryParamsObject[queryParamName]);
            result[queryParamName] = val;
        });
        return result;
    }
});
define('ember-new/models/tariff-group-dictionary-entry', ['exports', 'ember-new/models/abstract'], function (exports, _emberNewModelsAbstract) {

	var TariffGroupDictionaryEntry = _emberNewModelsAbstract['default'].extend({
		label: DS.attr('string'),
		name: DS.attr('string'),
		areasQuantity: DS.attr('number'),
		isHidden: DS.attr('boolean'),
		uuid: DS.attr('string'),
		usysExportId: DS.attr('number')
	});

	exports['default'] = TariffGroupDictionaryEntry;
});
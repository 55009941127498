define("ember-new/mixins/models/business-contract-ppm-view-model", ["exports", "ember-data", "ember"], function (exports, _emberData, _ember) {

    var string = _emberData["default"].attr('string');
    var number = _emberData["default"].attr('number');
    var refOne = _emberData["default"].belongsTo;
    var refMany = _emberData["default"].hasMany;

    exports["default"] = _ember["default"].Mixin.create({

        uuid: string,
        address: refOne('address', { embedded: true }),
        pointNumber: string,
        registrationNumber: string,
        deviceNumber: string

    });
});
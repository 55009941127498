define("ember-new/components/d-form/b2b-contract/dzu/add-gas-contract-comment", ["exports", "ember-new/components/d-form-command", "ember-new/templates/components/d-form-b2b-contract-add-comment"], function (exports, _emberNewComponentsDFormCommand, _emberNewTemplatesComponentsDFormB2bContractAddComment) {
    exports["default"] = _emberNewComponentsDFormCommand["default"].extend({
        layout: _emberNewTemplatesComponentsDFormB2bContractAddComment["default"],

        commandName: 'AddContractCommentDzuCommand',

        afterSuccessfulSubmit: function afterSuccessfulSubmit() {
            var targetController = this.get('targetObject');
            var mainController = targetController.container.lookup('controller:b2b-contract.dzu.show-gas.comments');
            var mainRoute = mainController.get('target');
            mainRoute.send('refreshModel');
            setTimeout(function () {
                targetController.transitionToRoute('b2b-contract.dzu.show-gas.comments');
            }, 500);
        }
    });
});
/*
 * @author Paweł Roguski <pawel.roguski@fortum.com>
 */
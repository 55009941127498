define('ember-new/controllers/b2b-contract/administration/section-general', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    fillForm: function fillForm(form) {
      form.set('model.completingConsumptionDeclarationsEE', this.get('model.completingConsumptionDeclarationsEE'));
      form.set('model.completingConsumptionDeclarationsGas', this.get('model.completingConsumptionDeclarationsGas'));
      form.set('model.fillingAttachmentsEE', this.get('model.fillingAttachmentsEE'));
      form.set('model.fillingAttachmentsGas', this.get('model.fillingAttachmentsGas'));
      form.set('model.fillingContractDocumentsEE', this.get('model.fillingContractDocumentsEE'));
      form.set('model.fillingContractDocumentsGas', this.get('model.fillingContractDocumentsGas'));
      form.set('model.confirmationLoyalistsEE', this.get('model.confirmationLoyalistsEE'));
      form.set('model.confirmationLoyalistsGas', this.get('model.confirmationLoyalistsGas'));
      form.set('model.obtainingDistributionEE', this.get('model.obtainingDistributionEE'));
      form.set('model.obtainingDistributionGas', this.get('model.obtainingDistributionGas'));
      form.set('model.failProvideApprovalsEE', this.get('model.failProvideApprovalsEE'));
      form.set('model.failProvideApprovalsGas', this.get('model.failProvideApprovalsGas'));
      form.set('model.defaultDateOWUEE', this.get('model.defaultDateOWUEE'));
      form.set('model.defaultDateOWUGas', this.get('model.defaultDateOWUGas'));
      form.set('model.defaultCalculatingRateEE', this.get('model.defaultCalculatingRateEE'));
      form.set('model.defaultCalculatingRateGas', this.get('model.defaultCalculatingRateGas'));
    },

    afterCommand: function afterCommand(response) {
      this.send('refreshModelAction');
    }

  });
});
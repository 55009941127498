define('ember-new/routes/individual-contract-price-lists/template/add', ['exports', 'ember', 'ember-new/routes/authenticated', 'ember-new/mixins/individual-contract-price-lists/template'], function (exports, _ember, _emberNewRoutesAuthenticated, _emberNewMixinsIndividualContractPriceListsTemplate) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend(_emberNewMixinsIndividualContractPriceListsTemplate['default'], {

        model: function model(params) {

            return _ember['default'].RSVP.hash({
                products: this.store.query('IndividualContractProductListViewModel', params),
                tariffs: this.store.query('IndividualContractBusinessTariffListViewModel', params)
            });
        },

        setupController: function setupController(ctrl, model) {
            ctrl.set('products', model.products);
            ctrl.set('tariffs', model.tariffs);
        }
    });
});
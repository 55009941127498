define('ember-new/helpers/format-phone-number', ['exports', 'ember'], function (exports, _ember) {
    exports.formatPhoneNumber = formatPhoneNumber;

    /**
     * Formatuje podany nr tel (520123654) w formacie (520 123 654)
     * @param params
     * @returns {*}
     */

    function formatPhoneNumber(params /*, hash*/) {
        var num = ("" + params[0]).replace(/\D/g, ''); // usun jakikolwiek znak nieliczbowy
        var m = num.match(/^(\d{3})(\d{3})(\d{3})$/);
        if (!m) return params[0];
        var mobile = m[1] + " " + m[2] + " " + m[3];
        return !m ? null : mobile;
    }

    exports['default'] = _ember['default'].Helper.helper(formatPhoneNumber);
});
define("ember-new/components/d-form/individual-contract/partner/add-audio", ["exports", "ember-new/components/d-form-command", "ember-new/templates/components/d-form", "ember"], function (exports, _emberNewComponentsDFormCommand, _emberNewTemplatesComponentsDForm, _ember) {
    exports["default"] = _emberNewComponentsDFormCommand["default"].extend({
        layout: _emberNewTemplatesComponentsDForm["default"],

        commandName: 'AddAudioToContractCommand',

        resolveContents: function resolveContents(form) {
            return new _ember["default"].RSVP.Promise(function (resolve, reject) {

                var store = form.get('store');
                var upload = store.peekAll('upload');
                _ember["default"].RSVP.Promise.all([upload]).then(function (result) {
                    var upload = result[0];

                    resolve(_ember["default"].Object.create({
                        recordingUuid: upload
                    }));
                });
            });
        },

        configure: function configure() {
            this.registerTransformer('recordingUuid', function (uuid) {
                return uuid;
            }, function (uuid) {
                return uuid;
            });
        },

        afterSuccessfulSubmit: function afterSuccessfulSubmit() {
            var targetController = this.get('targetObject');
            var mainController = targetController.container.lookup('controller:individual-contract-partners.add-files');
            var mainRoute = mainController.get('target');
            mainRoute.send('reload');
        }
    });
});
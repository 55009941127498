define('ember-new/models/sales-region-dictionary-entry', ['exports', 'ember-new/models/abstract'], function (exports, _emberNewModelsAbstract) {

    var SalesRegionDictionaryEntry = _emberNewModelsAbstract['default'].extend({
        label: DS.attr('string'),
        uuid: DS.attr('string'),
        name: DS.attr('string'),
        isDeactivated: DS.attr('boolean', { defaultValue: false }),
        dateDeactivatedMonth: DS.attr('number', { defaultValue: null }),
        dateDeactivatedYear: DS.attr('number', { defaultValue: null }),
        isPartner: DS.attr('boolean', { defaultValue: false }),
        isMacro: DS.attr('boolean', { defaultValue: false }),
        isHidden: DS.attr('boolean')
    });

    exports['default'] = SalesRegionDictionaryEntry;
});
define('ember-new/components/ul-pagination', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'ul',
        classNames: ['pagination'],
        classNameBindings: ['inline'],
        inline: true, // dodane dla zachowania wstecznej kompatybilnosci

        page: 1,
        itemsPerPage: 10,
        totalItems: 348,

        totalPages: (function () {
            return Math.ceil(this.get('totalItems') / this.get('itemsPerPage'));
        }).property('itemsPerPage', 'totalItems'),

        pagesArray: (function () {
            var tp = this.get('totalPages');
            var p = this.get('page');
            var arr = [];
            for (var i = 1; i <= tp; i++) {
                arr.push({ label: i, value: i });
            }

            if (tp <= 5) {} else {
                if (p <= 3) {
                    arr = [{ label: 1, value: 1 }, { label: 2, value: 2 }, { label: 3, value: 3 }, { label: 4, value: 4 }, { label: '...', value: 'd' }, { label: tp, value: tp }];
                } else if (p >= tp - 2) {
                    arr = [{ label: 1, value: 1 }, { label: '...', value: 'd' }, { label: tp - 3, value: tp - 3 }, { label: tp - 2, value: tp - 2 }, { label: tp - 1, value: tp - 1 }, { label: tp, value: tp }];
                } else {
                    arr = [{ label: 1, value: 1 }, { label: '...', value: 'd' }, { label: p - 1, value: p - 1 }, { label: p, value: p }, { label: p + 1, value: p + 1 }, { label: '...', value: 'dd' }, { label: tp, value: tp }];
                }
            }
            return arr;
        }).property('totalPages', 'page'),

        actions: {
            goToPage: function goToPage(page) {
                this._targetObject.send('goToPage', page);
            },
            goToPrev: function goToPrev() {
                this._targetObject.send('goToPage', this.get('page') - 1);
            },
            goToNext: function goToNext() {
                this._targetObject.send('goToPage', this.get('page') + 1);
            }
        },

        haveToPaginate: (function () {
            if (this.get('totalItems') > 0) {
                return true;
            }
            return false;
        }).property('totalItems')

    });
});
define('ember-new/controllers/b2b-contract/dzu/show-ee/comments', ['exports', 'ember', 'ember-new/config/environment'], function (exports, _ember, _emberNewConfigEnvironment) {
    exports['default'] = _ember['default'].Controller.extend({
        ajax: _ember['default'].inject.service('ajax'),

        refreshContractListModel: false,

        fillForm: function fillForm(form) {
            form.set('model.contextId', this.get('idContract'));
            form.set('model.clientId', this.get('idClient'));
            form.set('model.type', 'contract');
            form.set('model.subtype', null);
            form.set('model.userContent', null);
        },

        fillFormDr: function fillFormDr(form) {
            form.set('model.contextId', this.get('idContract'));
            form.set('model.clientId', this.get('idClient'));
            form.set('model.type', 'contract');
            form.set('model.subtype', 'dr_dk');
            form.set('model.userContent', null);
        },

        commentSubtypeDK: [{ label: "Do Działu Realizującego", value: "dk_dr" }, { label: "Do Doradcy i Managera", value: "dk_sales" }],

        dk: (function () {
            return this.get('TheUser').isMainRoleIn(['ROLE_ADMIN', 'ROLE_DZU_SPECIALIST', 'ROLE_DZU_OFFICE', 'ROLE_DZU_MANAGER']);
        }).property(),

        dr: (function () {
            return this.get('TheUser').isMainRoleIn(['ROLE_DZU2_SPECIALIST', 'ROLE_DZU2_MANAGER', 'ROLE_DZU2_SUPERVISOR']);
        }).property(),

        actions: {
            clearCommentsNotifications: function clearCommentsNotifications(contractId, clientId, notificationsExist) {

                if (!notificationsExist) {
                    return;
                }

                var _this = this;
                var payload = {};

                payload['ClearCommentNotificationCommand'] = {};
                payload['ClearCommentNotificationCommand']['contractId'] = contractId;
                payload['ClearCommentNotificationCommand']['clientId'] = clientId;

                var ajaxPromise = this.get('ajax').ajaxPost(_emberNewConfigEnvironment['default'].apiUrl + '/rest/clearcommentsnotificationscommands', payload);

                ajaxPromise.then(function (response) {
                    notificationsExist = false;
                    _this.set('refreshContractListModel', true);
                    var ctrl = _this.get('contractCtrl');
                    ctrl.set('notificationsExist', false);
                }, function (response) {
                    console.log(response);
                });
            }
        }

    });
});
define("ember-new/components/d-form/dictionaries/add", ["exports", "ember-new/components/d-form-command", "ember-new/templates/components/d-form", "ember-new/models/dictionary-types-mappings", "ember-new/components/d-form-row", "ember-new/components/d-field-select-dictionary-uuid-multiple", "ember", "ember-new/config/environment"], function (exports, _emberNewComponentsDFormCommand, _emberNewTemplatesComponentsDForm, _emberNewModelsDictionaryTypesMappings, _emberNewComponentsDFormRow, _emberNewComponentsDFieldSelectDictionaryUuidMultiple, _ember, _emberNewConfigEnvironment) {
	exports["default"] = _emberNewComponentsDFormCommand["default"].extend({
		// configuration
		layout: _emberNewTemplatesComponentsDForm["default"],
		commandName: 'AddDictionaryCommand',
		// fields that are common for all types of dictionary - always visible
		commonFieldNames: ['label', 'type', 'isHidden'],

		duonPartnerType: 'duon_partner',
		crmType: _emberNewConfigEnvironment["default"].applicationType === 'DSP' ? 'TRA' : 'PART',

		// services
		dicManagerService: _ember["default"].inject.service('dictionaries-manager'),
		modelMetadataService: _ember["default"].inject.service('model-metadata'),

		// default field values:
		fillCommandModel: function fillCommandModel(form) {
			form.set('model.isHidden', false);

			// osd
			form.set('model.advicesAmountException', false);
			form.set('model.allowedCharactersNumber', _ember["default"].A());
			form.set('model.ppeNumberStartString', _ember["default"].A());
			form.set('model.complexAllowedCharactersNumber', _ember["default"].A());
			form.set('model.complexPpeNumberStartString', _ember["default"].A());

			// tariff group
			form.set('model.isKam', false);
			form.set('model.isAutoGeneratedContractNumber', false);
			// duon_package_product
			form.set('model.hasDedykowanaObsluga', false);
			// individual ee/gas product line
			form.set('model.isDualFuelProduct', false);

			// business_gas_tariff_group
			form.set('model.isGtW5', false);
		},

		// handle initial event to prepare initial forms data (d-form event handler)
		resolveContents: function resolveContents() {
			var types = this.get('dicManagerService').getDictionariesTypesListToAdd();

			this.hideAllNotCommonFields();

			return _ember["default"].Object.create({
				type: types,
				areasQuantity: [{ label: 1, value: 1 }, { label: 2, value: 2 }, { label: 3, value: 3 }, { label: 4, value: 4 }],

				partnerType: this.crmType === 'TRA' ? [{ label: 'B2B only', value: 1 }] : [{ label: 'B2B only', value: 1 }, { label: 'G only', value: 2 }, { label: 'BOTH: B2B+G', value: 3 }],

				individualType: [{ label: 'CC', value: 'cc' }, { label: 'D2D', value: 'd2d' }]
			});
		},

		// observer of type changes - shows/hides fields for selected dictionary type
		typeChangeObserver: (function () {
			var type = this.get('formData.type');

			// if type is empty - do nothing
			if (!type) {
				return;
			}

			var className = _emberNewModelsDictionaryTypesMappings["default"][type];

			if (!className) {
				throw new _ember["default"].Error('No such class in dictionaries-types-mapping', type);
			}

			var attributes = this.get('modelMetadataService').getModelAttributesAndRelationsNames(className);

			this.hideAllNotCommonFields();
			this.showFieldsByAttrs(attributes);
		}).observes('formData.type'),

		// d-form event handler
		afterSuccessfulSubmit: function afterSuccessfulSubmit() {
			var ctrl = this.get('targetObject');
			ctrl.transitionToRoute('admin.dictionaries');
			ctrl.get('model').reload();
			ctrl.get('target').send('refresh');
		},

		// HELPER METHODS

		/**
   * Hides all field except name and type
   */
		hideAllNotCommonFields: function hideAllNotCommonFields() {

			var fieldRowsKeys = _ember["default"].A(_ember["default"].keys(this.get('formRows')));
			fieldRowsKeys.removeObjects(this.commonFieldNames);
			var modelFieldsKeys = this.commonFieldNames.slice(0);
			this.set('_modelFieldsKeys', modelFieldsKeys);
			var ctrl = this;

			fieldRowsKeys.forEach(function (fieldName) {
				var row = ctrl.get('formRows.' + fieldName);

				if (row instanceof _emberNewComponentsDFormRow["default"]) {
					row.set('hidden', true);
				}
			});
		},

		/**
   * Search for rows aby given attributeNames and shows it if found rows with the same name
   * @param attributes
   */
		showFieldsByAttrs: function showFieldsByAttrs(attributeNames) {

			if (!(attributeNames instanceof Array) && !(attributeNames instanceof _ember["default"].NativeArray)) {
				throw new _ember["default"].Error("Parameters attributes has to be an array or Ember.NativeArray object");
			}

			var form = this;

			attributeNames.forEach(function (fieldName) {
				var row = form.get('formRows.' + fieldName);

				if (row && row instanceof _emberNewComponentsDFormRow["default"]) {
					if (form.get('_modelFieldsKeys').indexOf(fieldName) === -1) {
						form.get('_modelFieldsKeys').push(fieldName);
					}

					row.set('hidden', false);
				}

				if (form.get('formData.' + fieldName)) {
					form.getField(fieldName).clearValue();
				}
			});
		},

		/**
   * Switches content for shared fields with the same name
   * For example: "osd" & "gas_osd" has field 'osUuids' but with different dictionaries
   * This method is switching content of these common fields to a proper dictionary for a specific "formdata.type"
   */
		switchFieldsContent: (function () {
			var type = this.get('formData.type');

			switch (type) {
				case 'osd':
					if (this.get('fields.osUuids') instanceof _emberNewComponentsDFieldSelectDictionaryUuidMultiple["default"]) {
						this.get('fields.osUuids').set('dictionaryName', 'os');
					}
					break;
				case 'gas_osd':
					if (this.get('fields.osUuids') instanceof _emberNewComponentsDFieldSelectDictionaryUuidMultiple["default"]) {
						this.get('fields.osUuids').set('dictionaryName', 'gas_os');
					}
					break;
			}
		}).observes('formData.type'),

		switchFieldsByIndividualType: (function () {
			var type = this.get('formData.type');
			var individualType = this.get('formData.individualType');

			if (type === this.duonPartnerType && individualType === 'cc') {
				this.set('formData.isAutoGeneratedContractNumber', true);
				this.get('formRows.isAutoGeneratedContractNumber.field').disableStateAndSwitchChange(true);
			}

			if (type === this.duonPartnerType && individualType === 'd2d') {
				this.set('formData.isAutoGeneratedContractNumber', false);
				this.get('formRows.isAutoGeneratedContractNumber.field').disableStateAndSwitchChange(false);
			}
		}).observes('formData.individualType'),

		// extend d-form methodsp
		/**
   * Overwrite method to setup empty key in formData for every form field
   * This is to provide correct bounding of fields value to formData which is serialized into POST payload (commandName => array(fields))
   * Without this overwrite every "add-form" of d-form-command had to have defined in controller some "fillForm" method to manually set up model/formData fields
   *
   * @see dForm::registerField
   * @param key
   * @param instance
   */
		registerField: function registerField(key, instance) {

			this._super(key, instance);

			// if there is creating empty command model - we have to fill it - with fields keys
			if (this.get('createNewCommandModel') === true && this.get('model.' + key) === undefined) {
				this.set('model.' + key, null);
				this.set('formData' + key, null);
			}
		}
	});
});
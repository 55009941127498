define('ember-new/initializers/security', ['exports', 'ember-new/utils/the-user', 'ember-new/utils/security'], function (exports, _emberNewUtilsTheUser, _emberNewUtilsSecurity) {
    exports['default'] = {
        name: 'security',
        initialize: function initialize(application) {

            var TheUser = _emberNewUtilsTheUser['default'].create();
            application.TheUser = TheUser;
            application.TheUser.set('app', application);

            application.register('theuser:main', TheUser, { instantiate: false });
            application.inject('route', 'TheUser', 'theuser:main');
            application.inject('controller', 'TheUser', 'theuser:main');
            application.inject('component', 'TheUser', 'theuser:main');
            application.inject('model', 'TheUser', 'theuser:main');

            var Security = _emberNewUtilsSecurity['default'].create();
            application.Security = Security;
            application.Security.set('app', application);

            application.register('security:main', Security, { instantiate: false });
            application.inject('route', 'Security', 'security:main');
            application.inject('controller', 'Security', 'security:main');
            application.inject('component', 'Security', 'security:main');

            application.inject('route', 'session', 'session:main');
            application.inject('controller', 'session', 'session:main');
            application.inject('component', 'session', 'session:main');
        }
    };
});
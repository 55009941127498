define('ember-new/mixins/serializers/individual-ppm', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Mixin.create({
        IndividualPpmSerializerInit: (function () {
            this.addAttrsConfig({
                address: { serialize: 'records', deserialize: 'records', noKey: true },

                whichSellerChange: { serialize: 'id', deserialize: 'id' },
                osdBillingFrequency: { serialize: 'id', deserialize: 'id' },
                cession: { serialize: 'id', deserialize: 'id' },
                tariffChange: { serialize: 'id', deserialize: 'id' },
                dataUpdate: { serialize: 'id', deserialize: 'id' },
                loyalty: { serialize: 'id', deserialize: 'id' },
                tariff: { serialize: 'id', deserialize: 'id' }

            });
        }).on('init')
    });
});
define('ember-new/routes/individual-contract-price-lists/template', ['exports', 'ember', 'ember-new/routes/authenticated'], function (exports, _ember, _emberNewRoutesAuthenticated) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({

        beforeModel: function beforeModel(transition) {
            window.scrollTo(0, 0); // scroll to the top of application before reloading
        },

        model: function model(params) {

            return this.store.query('IndividualContractTemplateListViewModel', params);
        },

        setupController: function setupController(ctrl, model) {
            ctrl.set('model', model);
            // ctrl.set('meta', meta);
        },

        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            }

        }

    });
});
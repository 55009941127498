define('ember-new/mixins/individual-contract/show', ['exports', 'ember', 'ember-new/config/environment'], function (exports, _ember, _emberNewConfigEnvironment) {
    exports['default'] = _ember['default'].Mixin.create({

        type: null,

        onModelChanged: (function () {
            var model = this.get('model');

            if (!model || !model._internalModel) {
                return;
            }
            var modelName = model._internalModel.modelName;

            if (modelName == 'individual-ee-contract') {
                this.set('type', 'ee');
            } else if (modelName == 'individual-gas-contract') {
                this.set('type', 'gas');
            } else {
                throw new _ember['default'].Error('Modelname: \'' + modelName + '\' not recognized');
            }
        }).observes('model'),

        setIsPikaActive: (function () {
            var model = this.get('model');

            if (!model || !model._internalModel) {
                return;
            }

            if (model.get('variantPikaText') || model.get('kindOfBulbsPikaText') || model.get('productLinePikaText') || model.get('productPikaText')) {
                model.set('isPikaActive', true);
            }
        }).observes('model')

    });
});
define('ember-new/models/vas-assigned-to-contract-view-model', ['exports', 'ember-data'], function (exports, _emberData) {

    var attr = _emberData['default'].attr;

    exports['default'] = _emberData['default'].Model.extend({
        inclusionComponent: attr('string'),
        inclusionType: attr('string'),
        medium: attr('string'),
        valueType: attr('string'),
        overheadRatePerPercentPoint: attr('number'),
        overheadForMM: attr('number'),
        overheadTypeForMM: attr('string'),
        baseValue: attr('number'),
        sellValue: attr('number'),
        contractUuid: attr('string'),
        vasDefinitionUuid: attr('string'),
        name: attr('string'),
        idUsys: attr('number')
    });
});
define('ember-new/models/individual-ee-business-rate', ['exports', 'ember-new/models/abstract', 'ember-data', 'ember-new/mixins/models/individual-business-rate'], function (exports, _emberNewModelsAbstract, _emberData, _emberNewMixinsModelsIndividualBusinessRate) {

    var attr = _emberData['default'].attr;
    var refOne = _emberData['default'].belongsTo;
    var refMany = _emberData['default'].hasMany;

    exports['default'] = _emberNewModelsAbstract['default'].extend(_emberNewMixinsModelsIndividualBusinessRate['default'], {
        productLine: refOne('individual-ee-product-line', { inverse: null }),
        tariff: refOne('individual-contract-ee-tariff-group', { inverse: null }),
        pricesLists: refMany('individual-business-rate-ee-prices-list')
    });
});
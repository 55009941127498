define("ember-new/routes/tasks/add", ["exports", "ember-new/routes/authenticated", "ember-new/config/environment", "ember", "ember-new/utils/ajax/get"], function (exports, _emberNewRoutesAuthenticated, _emberNewConfigEnvironment, _ember, _emberNewUtilsAjaxGet) {
	exports["default"] = _emberNewRoutesAuthenticated["default"].extend({
		ajax: _ember["default"].inject.service('ajax'),
		model: function model(params) {
			var _this = this;
			var store = this.get('store');
			var promiseModel = new _ember["default"].RSVP.Promise(function (resolve, reject) {
				var promise = _this.get('ajax').ajaxGet(_emberNewConfigEnvironment["default"].apiUrl + '/ajax/task/check_add_task?cid=' + params.cid);

				promise.then(function (response) {
					if (response.status === true) {

						var allowAddTaskEE = response.allowAddTaskEE;
						var allowAddTaskGas = response.allowAddTaskGas;

						delete response.allowAddTaskEE;
						delete response.allowAddTaskGas;
						delete response.status;
						store.pushPayload(response);
						resolve({
							allowAddTaskEE: allowAddTaskEE,
							allowAddTaskGas: allowAddTaskGas,
							client: store.peekRecord('ambBusinessClient', params.cid)
						});
					} else {
						resolve(false);
					}
				});
			}, "LoadModelOnTasksAddRoute");

			return promiseModel;
		},
		afterModel: function afterModel(model, transition) {
			if (model === false) {
				alert('Nie możesz dodać zadania do tego klienta, gdyż nie ma on rezerwacji na żadne medium lub nie masz uprawnień użytkownika (lub managera) posiadającego rezerwację');
				this.transitionTo('tasks');
			}
		},
		setupController: function setupController(controller, model) {
			controller.setup(model.client, model.allowAddTaskEE, model.allowAddTaskGas);
		}
	});
});
define('ember-new/components/download-file-uuid', ['exports', 'ember', 'ember-new/config/environment', 'ember-new/utils/utils'], function (exports, _ember, _emberNewConfigEnvironment, _emberNewUtilsUtils) {
    exports['default'] = _ember['default'].Component.extend({

        cookies: _ember['default'].inject.service(),

        tagName: 'a',
        attributeBindings: ['href', 'target'],

        target: '_blank',

        href: (function () {
            return _emberNewUtilsUtils['default'].generateSimpleUrl('/download/' + this.get('uuid'), '?', _emberNewConfigEnvironment['default'], this.get('TheUser'), this.get('cookies'));
        }).property('file')

    });
});
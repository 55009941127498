define("ember-new/mixins/models/individual-ppm", ["exports", "ember-data", "ember"], function (exports, _emberData, _ember) {

  var attr = _emberData["default"].attr;
  var refOne = _emberData["default"].belongsTo;
  var refMany = _emberData["default"].hasMany;

  exports["default"] = _ember["default"].Mixin.create({

    contract: attr(),

    address: refOne('address', { embedded: true }),

    registrationNo: attr('string'),
    pointNo: attr('string'),
    counterNo: attr('string'),
    whichSellerChange: refOne('seller-change-dictionary-entry', { inverse: null }),
    osdBillingFrequency: refOne('billing-frequency-dictionary-entry', { inverse: null }),
    osdBillingFrequencyPikaText: attr('string'),
    dateStart: attr('string'),
    dateEnd: attr('string'),
    consumption: attr('string'),
    cession: refOne('yes-no-dictionary-entry', { inverse: null }),
    tariffChange: refOne('yes-no-dictionary-entry', { inverse: null }),
    dataUpdate: refOne('yes-no-dictionary-entry', { inverse: null }),
    loyalty: refOne('loyalty', { inverse: null }),
    loyaltyPikaText: attr('string'),
    dateLoyalty: attr('string'),
    noticePeriodMonths: attr('string', { defaultValue: 1 }),
    monthlyConsumptions: attr(),

    osPikaText: attr('string'),
    osdPikaText: attr('string'),
    tariffPikaText: attr('string')

  });
});
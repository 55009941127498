define('ember-new/components/tasks-list', ['exports', 'ember', 'ember-new/config/environment', 'ember-new/utils/ajax/get'], function (exports, _ember, _emberNewConfigEnvironment, _emberNewUtilsAjaxGet) {
    exports['default'] = _ember['default'].Component.extend({
        router: _ember['default'].inject.service("-routing"),
        ajax: _ember['default'].inject.service('ajax'),
        applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),
        isLoading: false,

        filter: null, // Filter gets passed in or you might want to manage it otherwise

        // privileges
        displayChangeClientstatusAction: (function () {
            // Manager sprzedazy, makro manager, doradca
            return this.get('TheUser').isMainRoleIn(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_MANAGEMENT', 'ROLE_SALES_SALESMAN', 'ROLE_SALES_TEST_MANAGER', 'ROLE_SALES_MANAGER', 'ROLE_SALES_HEADMANAGER', 'ROLE_SALES_SALESMAN_DMT', 'ROLE_SALES_MANAGER_DMT', 'ROLE_SALES_HEADMANAGER_DMT']);
        }).property(),

        displayChangeLoyaltyAction: (function () {
            // Manager sprzedazy, makro manager, doradca
            return this.get('TheUser').isMainRoleIn(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_MANAGEMENT', 'ROLE_SALES_SALESMAN', 'ROLE_SALES_TEST_MANAGER', 'ROLE_SALES_MANAGER', 'ROLE_SALES_HEADMANAGER', 'ROLE_SALES_SALESMAN_DMT', 'ROLE_SALES_MANAGER_DMT', 'ROLE_SALES_HEADMANAGER_DMT']);
        }).property(),

        displayResolveAction: (function () {
            // Manager sprzedazy, makro manager, doradca
            return this.get('TheUser').isMainRoleIn(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_MANAGEMENT', 'ROLE_SALES_SALESMAN', 'ROLE_SALES_TEST_MANAGER', 'ROLE_SALES_MANAGER', 'ROLE_SALES_HEADMANAGER', 'ROLE_SALES_SALESMAN_DMT', 'ROLE_SALES_MANAGER_DMT', 'ROLE_SALES_HEADMANAGER_DMT']);
        }).property(),

        displayEditAction: (function () {
            // Manager sprzedazy, makro manager, doradca
            return this.get('TheUser').isMainRoleIn(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_MANAGEMENT', 'ROLE_SALES_SALESMAN', 'ROLE_SALES_TEST_MANAGER', 'ROLE_SALES_MANAGER', 'ROLE_SALES_HEADMANAGER', 'ROLE_SALES_SALESMAN_DMT', 'ROLE_SALES_MANAGER_DMT', 'ROLE_SALES_HEADMANAGER_DMT']);
        }).property(),

        displayEditMMAction: (function () {
            // Manager sprzedazy, makro manager, doradca
            return this.get('TheUser').isMainRoleIn(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_MANAGEMENT', 'ROLE_SALES_SALESMAN', 'ROLE_SALES_TEST_MANAGER', 'ROLE_SALES_MANAGER', 'ROLE_SALES_HEADMANAGER', 'ROLE_SALES_SALESMAN_DMT', 'ROLE_SALES_MANAGER_DMT', 'ROLE_SALES_HEADMANAGER_DMT']);
        }).property(),

        displayEditTariffAction: (function () {
            // Manager sprzedazy, makro manager, doradca
            return this.get('TheUser').isMainRoleIn(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_MANAGEMENT', 'ROLE_SALES_SALESMAN', 'ROLE_SALES_TEST_MANAGER', 'ROLE_SALES_MANAGER', 'ROLE_SALES_HEADMANAGER', 'ROLE_SALES_SALESMAN_DMT', 'ROLE_SALES_MANAGER_DMT', 'ROLE_SALES_HEADMANAGER_DMT']);
        }).property(),

        displayEditReservationAction: (function () {
            // Manager sprzedazy, makro manager, doradca
            return this.get('TheUser').isMainRoleIn(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_MANAGEMENT', 'ROLE_SALES_SALESMAN', 'ROLE_SALES_TEST_MANAGER', 'ROLE_SALES_MANAGER', 'ROLE_SALES_HEADMANAGER', 'ROLE_CC_MANAGER', 'ROLE_CC_HEADMANAGER', 'ROLE_SALES_SALESMAN_DMT', 'ROLE_SALES_MANAGER_DMT', 'ROLE_SALES_HEADMANAGER_DMT']);
        }).property(),

        displayOpenOrderingMeetingsAction: (function () {
            // Manager sprzedazy, makro manager, doradca
            return this.get('TheUser').isMainRoleIn(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_MANAGEMENT', 'ROLE_SALES_SALESMAN', 'ROLE_SALES_TEST_MANAGER', 'ROLE_SALES_MANAGER', 'ROLE_SALES_HEADMANAGER', 'ROLE_SALES_SALESMAN_DMT', 'ROLE_SALES_MANAGER_DMT', 'ROLE_SALES_HEADMANAGER_DMT']);
        }).property(),

        displayBlockClientButton: (function () {
            return this.get('TheUser').isGranted(['ROLE_DZU_MANAGER']);
        }).property(),

        isNotCc: (function () {
            return this.get('TheUser').model.mainRole !== 'ROLE_CC_CONSULTANT' && this.get('TheUser').model.mainRole !== 'ROLE_CC_MANAGER' && this.get('TheUser').model.mainRole !== 'ROLE_CC_HEADMANAGER';
        }).property(),

        isCcManager: (function () {
            return this.get('TheUser').isMainRoleIn(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_MANAGEMENT', 'ROLE_CC_MANAGER', 'ROLE_CC_HEADMANAGER']);
        }).property(),

        displayExportButton: (function () {
            return this.get('TheUser').isGranted(['ROLE_CENTRALA', 'ROLE_MANAGEMENT', 'ROLE_DG', 'ROLE_DE']);
        }).property(),

        canChangeVerificationStatus: (function () {
            return this.get('TheUser').isGranted(['ROLE_ADMIN']);
        }).property(),

        displayMoveTaskUnconditionallyButton: (function () {
            return this.get('TheUser').isGranted(['ROLE_ADMIN', 'ROLE_DG', 'ROLE_DE']);
        }).property(),
        //end privileges

        isAbleToEditClient: _ember['default'].computed(function () {
            if (!this.get('TheUser').isMainRoleIn(['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_SALES_SALESMAN', 'ROLE_SALES_TEST_MANAGER', 'ROLE_SALES_MANAGER', 'ROLE_SALES_HEADMANAGER', 'ROLE_CENTRALA', 'ROLE_CC_SPECIALIST', 'ROLE_CC_MANAGER', 'ROLE_CC_HEADMANAGER', 'ROLE_SALES_SALESMAN_DMT', 'ROLE_SALES_MANAGER_DMT', 'ROLE_SALES_HEADMANAGER_DMT'])) {
                this.get('applicationAlerts').addActionMessage('danger', 'Brak uprawnień do wykonania tej akcji.');
                return false;
            }
            return true;
        }),
        displayVerificationRecordingColumn: (function () {
            return this.get('TheUser').isGranted(['ROLE_SALES']);
        }).property(),

        actions: {
            refreshModel: function refreshModel() {},

            // Only sync values with query parameters once a button is pressed
            updateFilter: function updateFilter() {
                this.sendAction('updateFilterAction', 'limit', this.get('filter.limit'));
                this.sendAction('updateFilterAction', 'page', this.get('filter.page'));
            },

            resetDetailedFilters: function resetDetailedFilters() {
                this.sendAction('resetFiltersAction');
            },

            toggleTaskCollapse: function toggleTaskCollapse(task) {
                task.set('isExpanded', !task.get('isExpanded'));
            },

            moveTaskAction: function moveTaskAction(task) {
                if (!this.get('displayEditAction')) {
                    return false;
                }

                var now = new Date();
                var taskDate = new Date(task.get('tskTime'));
                now.setHours(23, 59, 59);
                var timeDiff = Math.abs(now.getTime() - taskDate.getTime()),
                    diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)),
                    rightDiffDays = diffDays - 1;

                if (taskDate < now && rightDiffDays > 2) {
                    this.get('applicationAlerts').addActionMessage('danger', "Nie możesz rozliczyć/ zmienić daty zadania starszego niż dwa dni");
                    return false;
                }

                this.get('router').transitionTo('tasks.task.move', [task.get('id')], {
                    queryParams: {
                        taskUuid: task.uuid,
                        taskId: task.id
                    }
                });
            },

            /**
             * Akcje dla pojedynczych wpisow w taskach
             */
            editClient: function editClient(task) {
                if (!this.get('TheUser').isMainRoleIn(['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_SALES_SALESMAN', 'ROLE_SALES_TEST_MANAGER', 'ROLE_SALES_MANAGER', 'ROLE_SALES_HEADMANAGER', 'ROLE_CENTRALA', 'ROLE_CC_SPECIALIST', 'ROLE_CC_MANAGER', 'ROLE_CC_HEADMANAGER', 'ROLE_SALES_SALESMAN_DMT', 'ROLE_SALES_MANAGER_DMT', 'ROLE_SALES_HEADMANAGER_DMT'])) {
                    this.get('applicationAlerts').addActionMessage('danger', 'Brak uprawnień do wykonania tej akcji.');
                    return false;
                }
                console.log('task.id ', task.id);
                this.get('router').transitionTo('tasks.tasksClient.company', [task.get('clnId')], {
                    queryParams: {
                        taskUuid: task.uuid,
                        taskId: task.id
                    }
                });
            },

            editEeContactPerson: function editEeContactPerson(task) {
                if (!this.get('TheUser').isMainRoleIn(['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_SALES_SALESMAN', 'ROLE_SALES_TEST_MANAGER', 'ROLE_SALES_MANAGER', 'ROLE_SALES_HEADMANAGER', 'ROLE_CENTRALA', 'ROLE_CC_SPECIALIST', 'ROLE_CC_MANAGER', 'ROLE_CC_HEADMANAGER', 'ROLE_SALES_SALESMAN_DMT', 'ROLE_SALES_MANAGER_DMT', 'ROLE_SALES_HEADMANAGER_DMT'])) {
                    this.get('applicationAlerts').addActionMessage('danger', 'Brak uprawnień do wykonania tej akcji.');
                    return false;
                }
                this.get('router').transitionTo('tasks.tasksClient.eeContact', [task.get('clnId')], {
                    queryParams: {
                        taskUuid: task.uuid,
                        taskId: task.id
                    }
                });
            },

            editGasContactPerson: function editGasContactPerson(task) {
                if (!this.get('TheUser').isMainRoleIn(['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_SALES_SALESMAN', 'ROLE_SALES_TEST_MANAGER', 'ROLE_SALES_MANAGER', 'ROLE_SALES_HEADMANAGER', 'ROLE_CENTRALA', 'ROLE_CC_SPECIALIST', 'ROLE_CC_MANAGER', 'ROLE_CC_HEADMANAGER', 'ROLE_SALES_SALESMAN_DMT', 'ROLE_SALES_MANAGER_DMT', 'ROLE_SALES_HEADMANAGER_DMT'])) {
                    this.get('applicationAlerts').addActionMessage('danger', 'Brak uprawnień do wykonania tej akcji.');
                    return false;
                }
                this.get('router').transitionTo('tasks.tasksClient.gasContact', [task.get('clnId')], {
                    queryParams: {
                        taskUuid: task.uuid,
                        taskId: task.id
                    }
                });
            },

            changeClientStatus: function changeClientStatus(task) {
                this.resolveTask.call(this, [task]);
            },

            resolveTask: function resolveTask(task) {
                if (!this.get('displayResolveAction')) {
                    return false;
                }

                if (task.get('acqStatusNumber') === 3) {
                    this.get('applicationAlerts').addActionMessage('danger', "Nie można rozliczać zadań, które mają zamkniętą akwizycję.");
                } else {
                    this.get('router').transitionTo('tasks.task.resolve', [task.get('id')]);
                }
            },

            changeLoyalty: function changeLoyalty(task) {
                var roles = ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_MANAGEMENT', 'ROLE_SALES_MANAGER', 'ROLE_SALES_HEADMANAGER', 'ROLE_SALES_SALESMAN', 'ROLE_SALES_TEST_MANAGER', 'ROLE_SALES_SALESMAN_DMT', 'ROLE_SALES_MANAGER_DMT', 'ROLE_SALES_HEADMANAGER_DMT'];

                if (!this.get('TheUser').isMainRoleIn(roles)) {
                    return;
                }

                if (task.get('acqStatusNumber') !== 3 || task.get('acqHasEeContract') === false || task.get('acqHasGasContract') === false) {
                    this.get('router').transitionTo('task.loyalty', [task.get('id')]);
                } else {
                    this.get('applicationAlerts').addActionMessage('danger', 'Klient ma już umowę, nie można go edytować.');
                }
            },

            showVerificationMeetingRecord: function showVerificationMeetingRecord(task) {
                this.get('router').transitionTo('tasks.task.verificationMeetingRecord', [task.get('id')]);
            },

            changeVerificationStatus: function changeVerificationStatus(task) {
                this.get('router').transitionTo('tasks.task.verificationStatus', [task.get('id')]);
            },

            showAcquisitionComments: function showAcquisitionComments(task) {
                var acqType = task.get('acqType');
                if (acqType === 'ee') {
                    this.get('router').transitionTo('tasks.eeAcquisition.comments', [task.get('acqId')]);
                }
                if (acqType === 'gas') {
                    this.get('router').transitionTo('tasks.gasAcquisition.comments', [task.get('acqId')]);
                }
            },

            showReservationsModal: function showReservationsModal(task) {
                this.get('router').transitionTo('tasks.tasksClient.reservation', [task.get('clnId')]);
            },

            addReservation: function addReservation(task) {
                this.get('router').transitionTo('tasks.tasksClient.reservation', [task.get('clnId')]);
            },

            toggleFavourite: function toggleFavourite(task) {
                var _this = this;

                task.set('isStarLoading', true);

                /**
                 * Zmiana statusu acqIsFavouriteSalesman/acqIsFavouriteManager na bazie odpowiedzi z API.
                 * @param response
                 */
                var setTaskFavAfterResponse = function setTaskFavAfterResponse(response) {

                    var toggle = response.toggle;

                    if (toggle === 'both') {
                        task.set('acqIsFavouriteSalesman', !task.get('acqIsFavouriteSalesman'));
                        task.set('acqIsFavouriteManager', !task.get('acqIsFavouriteManager'));
                    } else if (toggle === 'salesman') {
                        task.set('acqIsFavouriteSalesman', !task.get('acqIsFavouriteSalesman'));
                    } else if (toggle === 'manager') {
                        task.set('acqIsFavouriteManager', !task.get('acqIsFavouriteManager'));
                    }

                    task.set('isStarLoading', false);
                };

                /**
                 * todo: dopisac nowa obsluge bledow(ta ze starej wersji... ~lyczos
                 * @param error
                 */
                var handleError = function handleError(error) {
                    console.log('err', error);
                    switch (error) {
                        case 'salesman_no_reservation':
                            _this.get('applicationAlerts').addActionMessage('danger', 'Klient musi być na Ciebie zarezerwowany aby móc zaznaczać/odznaczać go jako ulubionego.');
                            break;
                        case 'manager_no_reservation':
                            _this.get('applicationAlerts').addActionMessage('danger', 'Klient musi być na zarezerwowany aby móc zaznaczać/odznaczać go jako ulubionego.');
                            break;
                        default:
                            _this.get('applicationAlerts').addActionMessage('danger', 'Nieznany błąd!');
                            break;
                    }
                };

                var acqId = task.get('acqId');
                if (!this.get('TheUser').isGranted(['ROLE_SALES_MANAGER']) && !task.get('acqIsFavouriteSalesman')) {
                    if (task.get('acqType') === 'ee' && task.get('clnIsReservedEE') || task.get('acqType') === 'gas' && task.get('clnIsReservedGas')) {
                        this.get('router').transitionTo('tasks.task.favourite', [task.id]);
                    } else {
                        this.get('applicationAlerts').addActionMessage('danger', 'Nie masz uprawnień do procesu akwizycyjnego tego klienta. Aby dodać gwiazdkę zarezerwuj go, jeżeli rezerwacja jest wolna.');
                        task.set('isStarLoading', false);
                    }
                } else {
                    if (task.get('acqType') === 'ee' && !task.get('clnIsReservedEE') || task.get('acqType') === 'gas' && !task.get('clnIsReservedGas')) {
                        this.get('applicationAlerts').addActionMessage('danger', 'Nie masz uprawnień do procesu akwizycyjnego tego klienta. Aby dodać gwiazdkę zarezerwuj go, jeżeli rezerwacja jest wolna.');
                        task.set('isStarLoading', false);
                    } else {
                        this.get('ajax').ajaxGet(_emberNewConfigEnvironment['default'].apiUrl + '/ajax/amb_client/favourite?acquisitionProcessId=' + acqId).then(function (res) {
                            return setTaskFavAfterResponse(res);
                        }, function (err) {
                            return handleError(err.error);
                        });
                    }
                }
            },

            addTaskSameClientSameType: function addTaskSameClientSameType(task) {
                this.get('router').transitionTo('tasks.add', [task.get('clnId')], {
                    queryParams: {
                        cid: task.get('clnId'),
                        type: task.get('tskType')
                    }
                });
            }
        }
    });
});
define('ember-new/components/d-form/individual-contract/add-ee', ['exports', 'ember', 'ember-new/components/d-form', 'ember-new/templates/components/d-form', 'ember-new/config/environment'], function (exports, _ember, _emberNewComponentsDForm, _emberNewTemplatesComponentsDForm, _emberNewConfigEnvironment) {
    exports['default'] = _emberNewComponentsDForm['default'].extend({
        layout: _emberNewTemplatesComponentsDForm['default'],

        showFormHeader: false,

        submitRestAction: null,
        submitRestAsSimple: false,
        submitModelNamespace: 'eeIndividualContract'

    });
});
define('ember-new/models/representative', ['exports', 'ember-new/models/abstract', 'ember-data'], function (exports, _emberNewModelsAbstract, _emberData) {

    var Representative = _emberNewModelsAbstract['default'].extend({
        firstName: _emberData['default'].attr('string'),
        lastName: _emberData['default'].attr('string'),
        email: _emberData['default'].attr('string'),
        position: _emberData['default'].attr('string'),
        phoneMobile: _emberData['default'].attr('string'),
        phoneLandline: _emberData['default'].attr('string')
    });
    // user model
    //Representative = Ember.Object.extend({
    //    firstName: null,
    //    lastName: null,
    //    email: null,
    //    position: null,
    //    phoneMobile: null,
    //    phoneLandline: null
    //});

    exports['default'] = Representative;
});
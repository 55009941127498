define('ember-new/routes/b2b-contract/dzu/show-gas/vas/edit', ['exports', 'ember-new/routes/authenticated'], function (exports, _emberNewRoutesAuthenticated) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({

        model: function model(params) {
            return this.store.queryRecord('VasAssignedToContractViewModel', { id: params.id }, { reload: true });
        },

        setupController: function setupController(controller, model) {
            controller.set('model', model);
        },

        actions: {
            refreshModelAction: function refreshModelAction() {
                this.refresh();
            }
        }
    });
});
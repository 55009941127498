define('ember-new/models/blog-sitebar', ['exports', 'ember-new/models/abstract', 'ember-new/mixins/base-document'], function (exports, _emberNewModelsAbstract, _emberNewMixinsBaseDocument) {

     var BlogSitebar = _emberNewModelsAbstract['default'].extend(_emberNewMixinsBaseDocument['default'], {

          _attributesExcludeBySerializer: ['contentImage'],

          // virtual property
          contentImage: DS.attr('string'),

          // relations
          content: DS.belongsTo('File', { inverse: null })

     });

     exports['default'] = BlogSitebar;
});
define('ember-new/routes/b2b-contract/administration/gas-business-tariff/edit', ['exports', 'ember-new/routes/authenticated'], function (exports, _emberNewRoutesAuthenticated) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({

        model: function model(params) {
            return Ember.RSVP.hash({
                model: this.store.findRecord('GasBusinessTariffEntryViewModel', params.tariffUuid),
                gasProducts: this.store.findAll('GasBusinessContractProductViewModel')
            });
        },

        setupController: function setupController(ctrl, model) {
            ctrl.set('model', model.model);
            ctrl.initializeGasProducts(model.gasProducts);
        },

        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            }
        }
    });
});
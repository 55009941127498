define('ember-new/routes/b2b-contract/salesman/show-ee/volumes', ['exports', 'ember-new/routes/authenticated'], function (exports, _emberNewRoutesAuthenticated) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({

        model: function model() {
            var contract = this.modelFor('b2b-contract/salesman/show-ee');

            return this.store.findRecord('BusinessEeContractSalesmanMmInfo', contract.get('id'), { reload: true });
        },

        setupController: function setupController(controller, model) {
            controller.set('model', model);
        },

        actions: {
            updateModel: function updateModel() {
                alert("I'm updating the model mother!");
            },

            refreshModel: function refreshModel() {
                this.refresh();
            }
        }

    });
});
define('ember-new/routes/b2b-contract/salesman', ['exports', 'ember', 'ember-new/routes/authenticated'], function (exports, _ember, _emberNewRoutesAuthenticated) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({
        beforeModel: function beforeModel() {
            window.scrollTo(0, 0); // scroll to the top of application before reloading
        },

        model: function model(params) {
            this.store.unloadAll('ContractSalesmanListItemViewModel');
            return _ember['default'].RSVP.hash({
                list: this.store.query('ContractSalesmanListItemViewModel', params),
                statusesSummary: this.store.query('SalesmanStatusesSummary', params)
            });
        },

        setupController: function setupController(ctrl, model) {

            ctrl.set('model', model.list);
            ctrl.set('statusesSummary', model.statusesSummary);
            ctrl.initializeTempFilters();
        },

        actions: {
            queryParamsDidChange: function queryParamsDidChange() {

                this.refresh();
            }
        }

    });
});
define('ember-new/models/plain-text-dictionary-entry', ['exports', 'ember-new/models/abstract', 'ember-data'], function (exports, _emberNewModelsAbstract, _emberData) {

    var PlainTextDictionaryEntry = _emberNewModelsAbstract['default'].extend({
        label: _emberData['default'].attr('string'),
        name: _emberData['default'].attr('string'),
        category: _emberData['default'].attr('string')
    });

    PlainTextDictionaryEntry.availableCategories = {
        task_source: 'task_source'
    };

    //seller_change: 'seller_change'
    exports['default'] = PlainTextDictionaryEntry;
});
define('ember-new/mixins/b2b-contract/components/d-form/osd-os-observers-gas', ['exports', 'ember', 'ember-new/templates/components/d-form'], function (exports, _ember, _emberNewTemplatesComponentsDForm) {
  exports['default'] = _ember['default'].Mixin.create({
    layout: _emberNewTemplatesComponentsDForm['default'],
    first: true,
    counter: 0,

    osdObserver: (function () {
      if (this.first === true) {
        return;
      }
      var osd = this.get('formData.osd');
      var controller = this.get('targetObject');

      if (osd) {
        if (this.get('formRows.isNewConnection') !== undefined) {
          this.get('formRows.isNewConnection.field').disabledState(false);
        }
        if (this.get('formRows.isContractTerminating') !== undefined) {
          this.get('formRows.isContractTerminating.field').disabledState(false);
        }
        controller.set('disableSellerChange', false);

        // filtrowanie wartości os w zależności od osd
        // gdy czy wypowiadamy na tak i pierwsza zmiana
        if (this.get('formData.isNewConnection') === true || this.get('formData.isContractTerminating') === true && this.get('formData.sellerChange') === "a1579e40-b192-4143-b97a-b73665646f3e") {
          controller.set('disableOs', false);

          /** Zmiana nastąpiła w tasku DCRM-7486-walidacja-gaz **/
          // jeśli pierwszy sprzedawca zawsze fortum
          //   if (this.get('formData.sellerChange') === '54da2441-86a9-47c6-95dd-67df496ebb94') {
          //       this.set('formData.os', 'e1604539-b06f-4756-b395-7f69fccda76f');
          //       controller.set('disableOs', true);
          //   } else {
          //       let selectedOsdUuid = this.get('formData.osd');
          //       let selectedOsd = this.get('store').peekAll('gas-osd-dictionary-entry')
          //           .find(record => record.get('uuid') === selectedOsdUuid);
          //       // osUuid z urzędu lub FORTUM
          //       let osUuid = selectedOsd.get('osUuids')[0] ? selectedOsd.get('osUuids')[0] : "e1604539-b06f-4756-b395-7f69fccda76f";
          //       this.set('formData.os', osUuid);
          //   }

          if (!this.get('formData.isContractTerminating')) {
            controller.set('disableOs', true);
            controller.set('disableSellerChange', true);
          }
        }

        return true;

        //    zresetowanie OSD gdy już było wcześniej wybrane
      } else if (osd === '') {
          //zresetowanie nowe przyłącze - zablokowanie i ustawienia domyślne (N)
          this.set('formData.isNewConnection', false);
          this.get('formRows.isNewConnection.field').valueChangedExternally();
          this.get('formRows.isNewConnection.field').disabledState(true);

          //zresetowanie czy wypowaidamy umowę - zablokowanie i ustawienia domyślne (T)
          this.get('formRows.isContractTerminating.field').disabledState(false);
          this.set('formData.isContractTerminating', true);
          this.get('formRows.isContractTerminating.field').valueChangedExternally();
          this.get('formRows.isContractTerminating.field').disabledState(true);

          //zresetowanie zmiana sprzedawcy i sprzedawca przed Fortum
          this.set('formData.sellerChange', null);

          /** Zmiana nastąpiła w tasku DCRM-7486-walidacja-gaz **/
          // this.set('formData.os', null);
          controller.set('disableSellerChange', true);
          controller.set('disableOs', true);

          return false;
        }

      return false;
    }).observes('formData.osd'),

    isContractTerminatingFieldValue: (function () {
      if (this.first === true) {
        return;
      }
      var controller = this.get('targetObject');

      var isNewConnection = this.get('formData.isNewConnection');

      if (this.osdObserver()) {
        //gdy nowe przyłącze
        if (isNewConnection === true) {

          this.get('formRows.isContractTerminating.field').disabledState(false);
          this.set('formData.isContractTerminating', false);
          this.get('formRows.isContractTerminating.field').valueChangedExternally();
          this.get('formRows.isContractTerminating.field').disabledState(true);
          this.set('formData.sellerChange', "54da2441-86a9-47c6-95dd-67df496ebb94");
          controller.set('disableSellerChange', true);

          controller.set('disableOs', true);
          return true;
        } else {
          this.get('formRows.isContractTerminating.field').disabledState(false);
          this.set('formData.isContractTerminating', true);
          this.get('formRows.isContractTerminating.field').valueChangedExternally();
          this.set('formData.sellerChange', null);
          /** Zmiana nastąpiła w tasku DCRM-7486-walidacja-gaz **/
          // this.set('formData.os', null);
          controller.set('disableOs', false);

          return false;
        }
      }
    }).observes('formData.isNewConnection'),

    osFieldValue: (function () {
      if (this.first === true) {
        return;
      }
      var controller = this.get('targetObject');

      if (this.get('formData.isContractTerminating') === true && this.get('formData.sellerChange') != null) {

        controller.set('disableOs', false);

        /** Zmiana nastąpiła w tasku DCRM-7486-walidacja-gaz **/
        //kiedy PIERWSZA ZMIANA
        // if (this.get('formData.sellerChange') === "a1579e40-b192-4143-b97a-b73665646f3e") {
        //   let selectedOsdUuid = this.get('formData.osd');
        //   let selectedOsd = this.get('store').peekAll('gas-osd-dictionary-entry')
        //     .find(record => record.get('uuid') === selectedOsdUuid);
        //   let osUuid = selectedOsd.get('osUuids')[0] ? selectedOsd.get('osUuids')[0] : "07885ae4-bcc0-44f6-95dc-f62be3f19a6e";
        //
        //   this.set('formData.os', osUuid);
        // }
      }

      //zawsze kiedy pierwszy sprzedawca, to FORTUM
      // if (this.get('formData.sellerChange') == '54da2441-86a9-47c6-95dd-67df496ebb94') {
      //   this.set('formData.os', 'e1604539-b06f-4756-b395-7f69fccda76f');
      //   controller.set('disableOs', true);
      // }
    }).observes('formData.isContractTerminating', 'formData.sellerChange'),

    /**
     * funkcja, która blokuje odpalanie się observerów przy pierwszym wejściu w formularz - w przypadku gdy isContractTerminating == false. pozwala to na wyświetlenie się prawdziwych wartości zanim zostaną nadpisane przez observery. counter potrzebny, bo observery uruchamiają się klika razy, zanim zostanie dokonana jakakolwiek zmiana na formularzu.
     * --- na przyszłość - używać computedProperites zamiast observerów w takich przypadkach
     */
    checkDefaultValues: (function () {

      this.counter++;

      var controller = this.get('targetObject');
      if (controller.get('model.isContractTerminating') === true) {
        this.first = false;
      }
      if (this.first && this.counter === 6) {
        if (controller.get('model.isContractTerminating') === false) {
          if (controller.get('model.isNewConnection') === true) {
            this.get('formRows.isContractTerminating.field').disabledState(true);
          } else {
            this.get('formRows.isContractTerminating.field').disabledState(false);
          }
          this.set('formData.isContractTerminating', false);
          this.get('formRows.isNewConnection.field').disabledState(false);
        }

        this.first = false;
      }
    }).observes('formData.isContractTerminating', 'formData.sellerChange', 'formData.isNewConnection')

  });
});
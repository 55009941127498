define('ember-new/mixins/blog-sitebar-serializer', ['exports', 'ember', 'ember-new/mixins/base-document-serializer'], function (exports, _ember, _emberNewMixinsBaseDocumentSerializer) {

    var BlogSitebarSerializerMixin = _ember['default'].Mixin.create(_emberNewMixinsBaseDocumentSerializer['default'], {

        onInit: (function () {
            this.addAttrsConfig({
                content: { serialize: 'id', deserialize: 'records' }
            });
        }).on('init')

    });

    exports['default'] = BlogSitebarSerializerMixin;
});
define("ember-new/routes/individual-contract/show-ee/client", ["exports", "ember-new/routes/authenticated", "ember-new/mixins/routes/unsaved-alert"], function (exports, _emberNewRoutesAuthenticated, _emberNewMixinsRoutesUnsavedAlert) {
    exports["default"] = _emberNewRoutesAuthenticated["default"].extend(_emberNewMixinsRoutesUnsavedAlert["default"], {

        actions: {
            refreshRoute: function refreshRoute() {
                this.store.unloadAll('individual-client');
                this.refresh();
            }
        }

    });
});
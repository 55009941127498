define('ember-new/mixins/controllers/individual-contract-partners/mark-as-sent', ['exports', 'ember', 'ember-new/config/environment', 'ember-new/utils/utils'], function (exports, _ember, _emberNewConfigEnvironment, _emberNewUtilsUtils) {
    var computed = _ember['default'].computed;
    exports['default'] = _ember['default'].Mixin.create({
        cookies: _ember['default'].inject.service(),
        reportUuid: null,
        downloadContractLink: computed('reportUuid', function () {
            var _this = this;
            var uuid = this.get('reportUuid');
            var url = '';
            if (!uuid) {
                return false;
            }
            url = '/download/pikachecklist/' + uuid;
            return _emberNewUtilsUtils['default'].generateSimpleUrl(url, '?', _emberNewConfigEnvironment['default'], _this.get('TheUser'), _this.get('cookies'));
        }),

        resetData: function resetData() {
            this.set('reportUuid', null);
        },

        actions: {
            afterMarkAsSent: function afterMarkAsSent(output) {
                var uuid = output.command_output.uuid;
                if (uuid) {
                    this.set('reportUuid', uuid);
                }
            },
            downloadReport: function downloadReport() {
                if (this.get('downloadContractLink')) {
                    window.open(this.get('downloadContractLink'), '_blank');
                }
            }
        }
    });
});
define('ember-new/controllers/b2b-contract/dzu/show-gas/documents', ['exports', 'ember', 'ember-new/config/environment', 'ember-new/mixins/b2b-contract/controllers/reload-contract-header', 'ember-new/utils/utils'], function (exports, _ember, _emberNewConfigEnvironment, _emberNewMixinsB2bContractControllersReloadContractHeader, _emberNewUtilsUtils) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsB2bContractControllersReloadContractHeader['default'], {

        applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),
        cookies: _ember['default'].inject.service(),

        fillForm: function fillForm(form) {
            form.set('model.contractUuid', this.get('idContract'));
            form.set('model.contractFUuid', this.get('model.contractFile'));
            form.set('model.authorizationUuid', this.get('model.authorizationFile'));
            form.set('model.PPMsUuid', this.get('model.ppmsFile'));
            form.set('model.saleTermsUuid', this.get('model.saleTermsFile'));
            form.set('model.osdInvoiceUuid', this.get('model.osdInvoiceFile'));
            form.set('model.salesInvoiceUuid', this.get('model.salesInvoiceFile'));
            form.set('model.presentContractUuid', this.get('model.presentContractFile'));
            form.set('model.newDeviceDocumentsUuid', this.get('model.newDeviceDocumentsFile'));
            form.set('model.CEIDGUuid', this.get('model.ceidgFile'));
            form.set('model.NIPUuid', this.get('model.nipFile'));
            form.set('model.REGONUuid', this.get('model.regonFile'));
            form.set('model.KRSUuid', this.get('model.krsFile'));
            form.set('model.companyContractUuid', this.get('model.companyContractFile'));
            form.set('model.legalTitleToPremisesUuid', this.get('model.legalTitleToPremisesFile'));
            form.set('model.otherRegisterDocumentsUuid', this.get('model.otherRegisterDocumentsFile'));
            form.set('model.additionalAuthorizationsUuid', this.get('model.additionalAuthorizationsFile'));
            form.set('model.statementUuid', this.get('model.statementFile'));
            form.set('model.dataUpdateUuid', this.get('model.dataUpdateFile'));
            form.set('model.cessionUuid', this.get('model.cessionFile'));
            form.set('model.changeTariffRequestUuid', this.get('model.changeTariffRequestFile'));
            form.set('model.otherDocumentsUuid', this.get('model.otherDocumentsFile'));
            form.set('model.linkTermsUuid', this.get('model.linkTermsFile'));
            form.set('model.linkContractUuid', this.get('model.linkContractFile'));
            form.set('model.declaredConsumptionUuid', this.get('model.declaredConsumptionFile'));
            form.set('model.changePowerRequestUuid', this.get('model.changePowerRequestFile'));

            form.set('model.EGAgreementDocumentUuid', this.get('model.egAgreementDocumentFile'));
            form.set('model.EGAgreementUuid', this.get('model.egAgreementFile'));
        },

        afterCommand: function afterCommand(response) {
            var route = this.container.lookup("route:b2b-contract.dzu.show-gas.documents");
            this.reloadContractHeader();
            route.refresh();
        },

        actions: {
            downloadZIP: function downloadZIP(contractUuid) {
                var url = _emberNewUtilsUtils['default'].generateSimpleUrl('/export/zip/' + contractUuid, '?', _emberNewConfigEnvironment['default'], this.get('TheUser'), this.get('cookies'));

                if (this.get('model.atLeastOneDocument')) {
                    window.open(url);
                } else {
                    this.get('applicationAlerts').addActionMessage('danger', 'Brak dokumentów do pobrania.');
                }
            }
        }

    });
});
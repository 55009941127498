define('ember-new/components/dashboard-posts', ['exports', 'ember'], function (exports, _ember) {

    var DashboardPostsComponent = _ember['default'].Component.extend({

        showLoading: false,
        sessionFlag: false,
        sessionFlag2: false,
        isMoreShowed: false,
        isShowingYouTubeModal: false,
        isShowingUsersLikesModal: false,
        currentController: null,

        canEdit: (function () {
            return this.get('canPublish');
        }).property(),

        mayEdit: (function () {
            // alias
            return this.get('canEdit');
        }).property(),

        createdBy: (function () {
            return this.get('model.createdBy');
        }).property(),

        content: (function () {
            return this.get('model');
        }).property(),

        dateCreated: (function () {
            return this.get('model.dateCreated');
        }).property(),

        avatarImage: (function () {
            var avatarImage = this.get('createdBy.avatarImage');

            if (this.get('sessionFlag2') != true) {
                this.set('sessionFlag2', true);
                return this.get('createdBy.avatarImage');
            }
        }).property(),

        likesCountText: (function () {
            var count = this.get('model.likesCount');
            var people = "osób";

            if (count > 1 && count < 5) {
                people = 'osoby';
            } else if (count === 1) {
                people = 'osoba';
            }

            return count + ' ' + people;
        }).property('model.likesCount'),

        likesUsersList: (function () {
            return this.get('model.likesUsersList');
        }).property('model.likesUsersList'),

        likeText: (function () {
            var count = this.get('model.likesCount');
            var text = "lubi";

            if (count > 1 && count < 5) {
                text = 'lubią';
            }

            return text;
        }).property('model.likesCount'),

        isPublished: (function () {
            return this.get('model.isPublished');
        }).property('model.isPublished'),

        contentImage: (function () {
            return this.get('model.contentImage');
        }).property(),

        contentYoutube: (function () {
            return this.get('model.contentYoutube');
        }).property(),

        modalSrc: (function () {
            var originalSrc = this.get('contentYoutube');
            var newSrc = originalSrc.replace('watch?v=', 'embed/');
            newSrc = newSrc + '?rel=0&controls=0&showinfo=0&autoplay=1';
            return newSrc;
        }).property(),

        youtubeImage: (function () {
            var url = this.get('contentYoutube');
            var youtubeId = url.split('=');
            var imageId = youtubeId[1];
            var fullLink = 'http://img.youtube.com/vi/' + imageId + '/0.jpg';

            return fullLink;
        }).property(),

        contentFile: (function () {
            var contentFile = this.get('model.contentFile');
            if (this.get('sessionFlag') != true) {
                this.set('sessionFlag', true);
                this.set('model.contentFile', contentFile);
            }
            return this.get('model.contentFile');
        }).property(),

        // virtual property to add JWT Token to the link to attachment image
        linkToImage: _ember['default'].computed('model.contentImage', function () {
            var token = this.get('TheUser.token');
            return this.get('model.contentImage') + '?bearer=' + token;
        }),

        hasAttachment: (function () {
            return this.get('model.contentType') != null;
        }).property('contentType'),

        imageAttachment: (function () {
            return this.get('model.contentType') == 'image';
        }).property('contentType'),

        pdfAttachment: (function () {
            return this.get('model.contentType') == 'pdf';
        }).property('contentType'),

        audioAttachment: (function () {
            return this.get('model.contentType') == 'mp3';
        }).property('contentType'),

        youtubeAttachment: (function () {
            return this.get('model.contentType') == 'youtube';
        }).property('contentType'),

        vimeoAttachment: (function () {
            return this.get('model.contentType') == 'vimeo';
        }).property('contentType'),

        textAttachment: (function () {
            return this.get('model.contentType') == 'text';
        }).property('contentType'),

        loggedUserLike: (function () {
            return this.get('model.likesUsers').contains(EmberNew.TheUser.model.id);
        }).property('model.likesUsers'),

        showFullName: (function () {
            var sales = ['ROLE_SALES_SALESMAN', 'ROLE_SALES_MANAGER', 'ROLE_SALES_HEADMANAGER'];
            if (EmberNew.TheUser.isMainRoleIn(sales)) {

                var author = this.get('model.createdBy');
                if (EmberNew.TheUser.userRoleIn(author, sales)) {
                    var authorRegions = author.get('regions');
                    var userRegions = EmberNew.TheUser.model.get('regions');

                    return userRegions.any(function (item) {

                        if (authorRegions.any(function (item2) {
                            return item == item2.get('id');
                        })) {
                            return true;
                        } else {
                            return false;
                        }
                    });

                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        }).property(),

        contentFull: (function () {
            var content = this.get('model.contentHtml') ? this.get('model.contentHtml') : ' ';

            if (this.get('model.title') && this.get('model.title').length) {
                content = this.get('model.title') + ' ' + content;
            }

            return content;
        }).property('model.title', 'model.contentHtml'),

        contentCut: (function () {
            return this.get('contentFull').substring(0, 200) + '...';
        }).property('contentFull'),

        isContentTooLong: (function () {
            return this.get('contentFull').length > 200;
        }).property('contentFull'),

        actions: {
            toggleMore: function toggleMore() {
                this.set('isMoreShowed', !this.get('isMoreShowed'));
            },

            toggleYouTubeModal: function toggleYouTubeModal() {
                this.toggleProperty('isShowingYouTubeModal');
            },

            usersLikesModal: function usersLikesModal() {
                this.toggleProperty('isShowingUsersLikesModal');
            },

            removeAction: function removeAction() {
                if (confirm("Czy na pewno chcesz usunąć ten post?")) {
                    var model = this.get('model');
                    var _this = this;
                    model.destroyRecord().then(function () {

                        _this.set('showLoading', true);

                        setTimeout(function () {
                            _this.sendAction('refreshModel');
                            _this.set('showLoading', false);

                            var route = _this.container.lookup("route:dashboard");
                            route.send('refreshModel');
                        }, 500);
                    });
                }
            },

            like: function like() {
                this.get('model').customUpdate('like', [], null, null).then(function (response) {}, function (response) {});
            },

            publish: function publish() {
                var _this = this;
                this.get('model').customUpdate('publish', [], null, null).then(function (response) {
                    _this.store.pushPayload(response);
                }, function (response) {});
            }
        }

    });

    exports['default'] = DashboardPostsComponent;
});
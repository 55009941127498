define('ember-new/models/analyze-renouncement-before-anonymize-view-model', ['exports', 'ember-data', 'ember-new/models/abstract'], function (exports, _emberData, _emberNewModelsAbstract) {

    var attr = _emberData['default'].attr;

    exports['default'] = _emberNewModelsAbstract['default'].extend({
        clientInfo: attr(),
        isOkToAnonymize: attr('boolean'),
        alerts: attr(),
        dateEnter: attr('string'),
        datePostmark: attr('string'),
        renouncementStatus: attr('string'),
        comment: attr('string'),
        contractNo: attr('string'),
        ppmAddress: attr(),
        ppmNo: attr('string'),
        ppmCounterNo: attr('string'),
        type: attr('string'),
        source: attr('string'),

        ppeNo: (function () {
            if (this.get('type') == 'ee') {
                return this.get('ppmNo');
            }

            return '';
        }).property('ppmNo', 'type'),
        ppeCounterNo: (function () {
            if (this.get('type') == 'ee') {
                return this.get('ppmCounterNo');
            }

            return '';
        }).property('ppmCounterNo', 'type'),

        ppgNo: (function () {
            if (this.get('type') == 'gas') {
                return this.get('ppmNo');
            }

            return '';
        }).property('ppmNo', 'type'),
        ppgCounterNo: (function () {
            if (this.get('type') == 'gas') {
                return this.get('ppmCounterNo');
            }

            return '';
        }).property('ppmCounterNo', 'type')
    });
});
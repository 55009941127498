define('ember-new/components/d-field-select-tag', ['exports', 'ember', 'ember-new/components/d-field-select'], function (exports, _ember, _emberNewComponentsDFieldSelect) {
    exports['default'] = _emberNewComponentsDFieldSelect['default'].extend({

        multiple: true,
        tags: true,

        onSelection: (function () {

            console.log(this.get('selection'));
            var _this = this;
            var value = [];
            this.get('selection').forEach(function (item) {
                value.push(item.value);
            });
            this.set('value', value);
        }).observes('selection.@each'),

        onSelect: (function () {
            console.log(this.get('selection'));
        }).observes('selection')

    });
});
define('ember-new/models/upload', ['exports', 'ember-new/config/environment', 'ember-new/models/abstract', 'ember-data'], function (exports, _emberNewConfigEnvironment, _emberNewModelsAbstract, _emberData) {

    var Upload = _emberNewModelsAbstract['default'].extend({
        uuid: _emberData['default'].attr('string'),
        originalName: _emberData['default'].attr('string'),
        fileName: _emberData['default'].attr('string'),
        contentType: _emberData['default'].attr('string'),
        icon: _emberData['default'].attr('string'),
        size: _emberData['default'].attr('string'),
        path: _emberData['default'].attr('string'),
        isBound: _emberData['default'].attr('boolean')
    });

    exports['default'] = Upload;
});
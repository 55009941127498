define('ember-new/mixins/b2b-contract/controllers/volumes-gas', ['exports', 'ember', 'ember-new/config/environment', 'ember-new/mixins/b2b-contract/controllers/reload-contract-header'], function (exports, _ember, _emberNewConfigEnvironment, _emberNewMixinsB2bContractControllersReloadContractHeader) {
    exports['default'] = _ember['default'].Mixin.create(_emberNewMixinsB2bContractControllersReloadContractHeader['default'], {
        TheUser: _ember['default'].inject.service('theuser:main'),

        disableForDzuSpecialist: (function () {
            return !this.get('TheUser').isMainRoleIn(['ROLE_DZU_MANAGER', 'ROLE_DZU2_MANAGER', 'ROLE_DZU2_SPECIALIST', 'ROLE_DZU2', 'ROLE_ADMIN']);
        }).property(),

        fillForm: function fillForm(form) {
            form.set('model.contractId', this.get('model.id'));
            form.set('model.numberOfMonthsBySalesman', this.get('model.numberOfMonthsBySalesman'));
            form.set('model.marginBySalesman', this.get('model.mmBySalesman'));
            form.set('model.volumeBySalesman', this.get('model.volumeBySalesman'));
            form.set('model.consumptionBySalesman', this.get('model.consumptionBySalesman'));
            form.set('model.overheadBySalesman', this.get('model.overheadBySalesman'));
        },

        actions: {
            toggleShowMorePpgInformation: function toggleShowMorePpgInformation(id) {
                var item = _ember['default'].$('[data-element=' + id + ']');
                var button = _ember['default'].$('[data-element=button-' + id + ']');

                this.colorEverySecondRowWithMonth();
                item.toggleClass('hide');
                button.toggleClass('clicked');
                button.empty();

                if (button.hasClass('clicked')) {
                    button.append('Ukryj zawartość');
                } else {
                    button.append('Pokaż więcej');
                }
            }
        },

        afterCommand: function afterCommand() {
            var ctrl = this;
            var route = this.container.lookup('route:b2b-contract.dzu.show-gas.volumes');

            route.refresh();
            this.reloadContractHeader();
            setTimeout(function () {
                ctrl.transitionToRoute('b2b-contract.dzu.show-gas.volumes');
            }, 500);
        },

        colorEverySecondRowWithMonth: function colorEverySecondRowWithMonth() {
            $("table tr").filter(function () {
                return $(this).children().length == 3;
            }).filter(':odd').addClass('alt');
        }
    });
});
define('ember-new/mixins/routes/individual-contract/status-history', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Mixin.create({

        setupController: function setupController(ctrl, model) {
            var id = this.get('contractId');
            ctrl.set('isLoading', true);

            // pobrac dane z endpointa
            // i zapisac do modelu
            // robimy w setupController zeby nie czekac na zaladownie routa
            this.store.query('individual-contract-bo-comments-view-model', { contractId: id }).then(function (res) {
                _ember['default'].run.later(function () {
                    ctrl.set('model', res);
                    ctrl.set('isLoading', false);
                });
            });
        },

        resetController: function resetController(controller, isExiting, transition) {
            this._super.apply(this, arguments);

            if (isExiting) {
                controller.resetData(); // or whatever function you want to call
            }
        }

    });
});
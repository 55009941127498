define('ember-new/controllers/renouncement/add', ['exports', 'ember', 'ember-new/mixins/controllers/helpers/form-processing-controller', 'ember-new/config/environment'], function (exports, _ember, _emberNewMixinsControllersHelpersFormProcessingController, _emberNewConfigEnvironment) {
	exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsControllersHelpersFormProcessingController['default'], {

		ajax: _ember['default'].inject.service('ajax'),
		applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),

		isSecondStep: false, // if false step1 - a form, if true - step2 - confirmation and linking to searched contracts

		isForEe: false,
		isForGas: false,
		isPakietMedyczny: false,

		eeContracts: null,
		gasContracts: null,
		foundEeContract: false,
		foundGasContract: false,
		chosenEeContract: null,
		chosenGasContract: null,
		eeRenouncementStatus: '59a952b4e7c7ffae2fbcb3dc',
		gasRenouncementStatus: '59a952b4e7c7ffae2fbcb3dc',

		eeRenouncementWarning: false,
		gasRenouncementWarning: false,

		isSourcePost: (function () {
			return this.get('model.source') && this.get('model.source.id') == '5799e4d4cce826f6646d1abc';
		}).property('model.source.id'),

		fieldsNeedFeedback: ['clientInfo::firstName', 'clientInfo::lastName', 'clientInfo::phone', 'clientInfo::email', 'clientInfo::pesel', 'clientInfo::baseAddress::street', 'clientInfo::baseAddress::streetNo', 'clientInfo::baseAddress::aptNo', 'clientInfo::baseAddress::city', 'clientInfo::baseAddress::postalCode', 'clientInfo::baseAddress::postalCity', 'clientInfo::baseAddress::district', 'ppmAddress::street', 'ppmAddress::streetNo', 'ppmAddress::aptNo', 'ppmAddress::city', 'ppmAddress::postalCode', 'ppmAddress::postalCity', 'ppmAddress::district', 'eeContractNo', 'gasContractNo', 'pmContractNo', 'renouncementStatus', 'contractDateSigned', 'source', 'dateEnter', 'datePostmark', 'requiresResponse', 'comment', 'ppeNo', 'ppgNo', 'ppeCounterNo', 'ppgCounterNo', 'eeContract', 'gasContract'],

		clear: function clear() {
			this.set('eeContracts', null);
			this.set('gasContracts', null);
			this.set('foundEeContract', false);
			this.set('foundGasContract', false);
			this.set('chosenEeContract', null);
			this.set('chosenGasContract', null);
			this.set('eeRenouncementStatus', '59a952b900287fbd4cf4985a');
			this.set('gasRenouncementStatus', '59a952b900287fbd4cf4985a');
			this.set('isSecondStep', false);
		},

		_handleValidateAndSearchContracts: function _handleValidateAndSearchContracts() {

			var controller = this;

			controller.set('eeContracts', null);
			controller.set('gasContracts', null);
			controller.set('foundEeContract', false);
			controller.set('foundGasContract', false);
			controller.set('chosenEeContract', null);
			controller.set('chosenGasContract', null);
			controller.set('eeRenouncementStatus', '59a952b900287fbd4cf4985a');
			controller.set('gasRenouncementStatus', '59a952b900287fbd4cf4985a');

			var renouncement = this.get('model');
			var renouncementRestAdapter = this.store.adapterFor(renouncement._internalModel.modelName);
			var serializedData = renouncementRestAdapter.serializeCustom(renouncement, this.get('fieldsNeedFeedback'), 'renouncement');

			serializedData.isForEe = controller.get('isForEe');
			serializedData.isForGas = controller.get('isForGas');
			serializedData.isPakietMedyczny = controller.get('isPakietMedyczny');

			var promise = this.get('ajax').ajaxPost(_emberNewConfigEnvironment['default'].apiUrl + '/api/renouncements/validate_and_search_contracts', serializedData);

			promise.then(function (response) {

				var eeContractIds = response.eeIds;
				var gasContractIds = response.gasIds;
				delete response.eeIds;
				delete response.gasIds;

				controller.store.unloadAll('individual-ee-contract');
				controller.store.unloadAll('individual-gas-contract');
				controller.store.pushPayload(response);

				var eeContracts = controller.store.peekAll('individual-ee-contract');
				controller.set('eeContracts', eeContracts);
				controller.set('foundEeContract', eeContractIds.length > 0);

				var gasContracts = controller.store.peekAll('individual-gas-contract');
				controller.set('gasContracts', gasContracts);
				controller.set('foundGasContract', gasContractIds.length > 0);

				controller.handleSuccess();
				controller.set('submitResult', "Dane są poprawne! Przejrzyj znalezione umowy i zatwierdź odstąpienie.");

				if (controller.get('isSecondStep', false) === false) {
					controller.set('isSecondStep', true);
				}
			}, function (response) {
				controller.handleNotValid(response);
				controller.get('applicationAlerts').addActionMessage('warning', 'Formularz zawiera błędy.');
			});
		},

		_handleConfirmRenouncement: function _handleConfirmRenouncement() {
			var controller = this;

			var parameters = {
				isForEe: controller.get('isForEe'),
				isForGas: controller.get('isForGas'),
				isPakietMedyczny: controller.get('isPakietMedyczny'),
				eeRenouncementStatus: controller.get('eeRenouncementStatus'),
				gasRenouncementStatus: controller.get('gasRenouncementStatus')
			};

			var eeContract = this.get('chosenEeContract') ? this.store.peekRecord('individual-ee-contract', this.get('chosenEeContract')) : null;
			var gasContract = this.get('chosenGasContract') ? this.store.peekRecord('individual-gas-contract', this.get('chosenGasContract')) : null;
			var renouncement = this.get('model');

			renouncement.set('eeContract', eeContract);
			renouncement.set('gasContract', gasContract);

			var promise = renouncement.customPost(null, this.get('fieldsNeedFeedback'), {}, 'renouncement', parameters);

			promise.then(function (response) {

				var renouncementStatement = response.renouncementStatement;

				if (renouncementStatement && renouncementStatement.isVisible === true) {
					alert(renouncementStatement.message);
				}

				var text;
				if (controller.get('isForEe') && controller.get('isForGas') && controller.get('isPakietMedyczny')) {
					text = "Dodano trzy odstąpienia. Dla EE, dla GAZ, dla Pakiet Medyczny i powiązano je z wybranymi umowami!";
				} else if (controller.get('isForEe') && controller.get('isForGas')) {
					text = "Dodano dwa osobne odstąpienia. Jedno dla EE, drugie dla GAZ i powiązano je z wybranymi umowami!";
				} else if (controller.get('isForEe') && controller.get('isPakietMedyczny')) {
					text = "Dodano dwa osobne odstąpienia. Jedno dla EE, drugie dla Pakiet Medyczny i powiązano je z wybranymi umowami!";
				} else if (controller.get('isForGas') && controller.get('isPakietMedyczny')) {
					text = "Dodano dwa osobne odstąpienia. Jedno dla GAZ, drugie dla Pakiet Medyczny i powiązano je z wybranymi umowami!";
				} else if (controller.get('isForEe')) {
					text = "Dodano odstąpienie dla EE i powiązano je z wybranymi umowami!";
				} else if (controller.get('isForGas')) {
					text = "Dodano odstąpienie dla GAZ i powiązano je z wybranymi umowami!";
				} else {
					text = "Dodano odstąpienie";
				}

				controller.handleSuccess();
				controller.set('submitResult', text);

				controller.get('applicationAlerts').addActionMessage('success', text);

				setTimeout(function () {
					controller.transitionToRoute('renouncement.show', renouncement);
				}, 500);
			}, function (response) {
				controller.handleNotValid(response);
				controller.get('applicationAlerts').addActionMessage('warning', 'Formularz zawiera błędy.');
			});
		},

		chosenEeContractChanged: (function () {
			var contract = this.get('chosenEeContract') ? this.store.peekRecord('individual-ee-contract', this.get('chosenEeContract')) : null;

			if (contract && contract.get('type') === 'ee' && contract.get('renouncement') === null) {
				if (contract && contract.get('signingPlace.id') === '57cd4af8e771dead2262576a') {
					this.set('eeRenouncementStatus', '59a952b86876d26d4debb978');
				} else {
					this.set('eeRenouncementStatus', '59a952b4e7c7ffae2fbcb3dc');
				}
			} else {
				this.set('chosenEeContract', null);
			}
		}).observes('chosenEeContract'),

		chosenGasContractChanged: (function () {
			var contract = this.get('chosenGasContract') ? this.store.peekRecord('individual-gas-contract', this.get('chosenGasContract')) : null;

			if (contract && contract.get('type') === 'gas' && contract.get('renouncement') === null) {
				if (contract && contract.get('signingPlace.id') === '57cd4af8e771dead2262576a') {
					this.set('gasRenouncementStatus', '59a952b86876d26d4debb978');
				} else {
					this.set('gasRenouncementStatus', '59a952b4e7c7ffae2fbcb3dc');
				}
			} else {
				this.set('chosenGasContract', null);
			}
		}).observes('chosenGasContract'),

		eeRenouncementStatusChanged: (function () {
			var contract = this.get('chosenEeContract') ? this.store.peekRecord('individual-ee-contract', this.get('chosenEeContract')) : null;

			if (contract && contract.get('signingPlace.id') === '57cd4af8e771dead2262576a' && this.get('eeRenouncementStatus') == '59a952b4e7c7ffae2fbcb3dc') {
				this.set('eeRenouncementWarning', true);
			} else {
				this.set('eeRenouncementWarning', false);
			}
		}).observes('eeRenouncementStatus', 'chosenEeContract'),

		gasRenouncementStatusChanged: (function () {
			var contract = this.get('chosenGasContract') ? this.store.peekRecord('individual-gas-contract', this.get('chosenGasContract')) : null;

			if (contract && contract.get('signingPlace.id') === '57cd4af8e771dead2262576a' && this.get('gasRenouncementStatus') == '59a952b4e7c7ffae2fbcb3dc') {
				this.set('gasRenouncementWarning', true);
			} else {
				this.set('gasRenouncementWarning', false);
			}
		}).observes('gasRenouncementStatus', 'chosenGasContract'),

		actions: {
			submitForm: function submitForm() {

				var controller = this;

				if (!this.beforeSubmit()) {
					return;
				}

				var isForEe = this.get('isForEe');
				var isForGas = this.get('isForGas');
				var isPakietMedyczny = this.get('isPakietMedyczny');

				if (false === isForEe && false === isForGas && false === isPakietMedyczny) {
					controller.set('isValid', false);
					controller.set('isSubmitting', false);
					controller.set('submitResult', "Musisz wybrać typ odstąpienia (EE lub/i GAZ i/lub Pakiet Medyczny)");
					controller.set('submitResultType', 'warning');
					return;
				}

				if (false === this.get('isSecondStep')) {
					controller._handleValidateAndSearchContracts();
				} else {
					controller._handleConfirmRenouncement();
				}
			},
			backToStep1: function backToStep1() {
				this.set('isSecondStep', false);
			},
			fillTestData: function fillTestData() {
				var model = this.get('model');

				model.set('clientInfo.pesel', '55072302275');
				model.set('clientInfo.firstName', 'Jan');
				model.set('clientInfo.lastName', 'Kowalski');
				model.set('clientInfo.phone', '555666777');
				model.set('clientInfo.email', 'jan@kowalski.cooom');
				model.set('clientInfo.identificationDocumentType', this.store.peekRecord('identification-document-type', '5799e38ecce826f6646d1ab9'));
				model.set('clientInfo.identificationDocumentNumber', 'AMF100424');

				model.set('clientInfo.baseAddress.street', 'Ulica');
				model.set('clientInfo.baseAddress.streetNo', '1');
				model.set('clientInfo.baseAddress.aptNo', '2');
				model.set('clientInfo.baseAddress.postalCode', '00-000');
				model.set('clientInfo.baseAddress.city', 'Miasto');
				model.set('clientInfo.baseAddress.district', this.store.peekRecord('district-dictionary-entry', '542e98d4e771de5d2b8b4567'));

				model.set('source', this.store.peekRecord('renouncement-source', '5799e4f6cce826f6646d1abf'));
				model.set('dateEnter', '2016-08-01');

				this.set('isForEe', true);
				this.set('isForGas', true);
			},

			copyPpeAddress: function copyPpeAddress() {
				var model = this.get('model');
				model.set('clientInfo.baseAddress.street', model.get('ppmAddress.street'));
				model.set('clientInfo.baseAddress.streetNo', model.get('ppmAddress.streetNo'));
				model.set('clientInfo.baseAddress.aptNo', model.get('ppmAddress.aptNo'));
				model.set('clientInfo.baseAddress.postalCode', model.get('ppmAddress.postalCode'));
				model.set('clientInfo.baseAddress.city', model.get('ppmAddress.city'));
				model.set('clientInfo.baseAddress.postalCity', model.get('ppmAddress.postalCity'));
				model.set('clientInfo.baseAddress.district', model.get('ppmAddress.district'));
			}

		}
	});
});
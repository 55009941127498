define('ember-new/components/d-field/d-field-select-dictionary-duon-partner-g', ['exports', 'ember', 'ember-new/components/d-field-select-dictionary'], function (exports, _ember, _emberNewComponentsDFieldSelectDictionary) {
    exports['default'] = _emberNewComponentsDFieldSelectDictionary['default'].extend({

        dictionaryName: 'duon_partner',

        customActionBeforeSetContent: function customActionBeforeSetContent(content) {
            content = content.filter(function (item) {
                return item.get('isIndividual');
            });
            return content;
        }
    });
});
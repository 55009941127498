define('ember-new/serializers/application', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {

    // Assign REST Serializer to Application
    var Serializer = _emberData['default'].RESTSerializer.extend(_emberData['default'].EmbeddedRecordsMixin);

    var get = _ember['default'].get;
    var ApplicationSerializer = Serializer.extend({
        modelFieldsToExclude: ['dateCreated', 'dateModified', 'createdBy', 'lastModifiedBy', 'deletedBy', 'revision', 'isDeleted', 'dateDeleted', 'status', 'type', 'uuid'],
        serialize: function serialize(snapshot, options) {
            var json = {};

            if (options && options.includeId) {
                var id = snapshot.id;

                if (id) {
                    json[get(this, "primaryKey")] = id;
                }
            }

            var forceSerializationAttrs = options && options.forceExcludedAttributesSerialization !== undefined && options.forceExcludedAttributesSerialization instanceof Array ? options.forceExcludedAttributesSerialization : false;
            var forceSerialization, mainKey;

            snapshot.eachAttribute(function (key, attribute) {

                mainKey = key.split(".")[0];
                forceSerialization = false;

                if (forceSerializationAttrs !== false && forceSerializationAttrs.indexOf(mainKey) >= 0) {
                    forceSerialization = true;
                }

                this.serializeAttribute(snapshot, json, key, attribute, forceSerialization);
            }, this);

            snapshot.eachRelationship(function (key, relationship) {
                mainKey = key.split(".")[0];
                forceSerialization = false;

                if (forceSerializationAttrs !== false && forceSerializationAttrs.indexOf(mainKey) >= 0) {
                    forceSerialization = true;
                }

                if (relationship.kind === "belongsTo") {
                    this.serializeBelongsTo(snapshot, json, relationship, forceSerialization);
                } else if (relationship.kind === "hasMany") {
                    this.serializeHasMany(snapshot, json, relationship, forceSerialization);
                }
            }, this);

            return json;
        },

        serializeAttribute: function serializeAttribute(snapshot, json, key, attribute, forceSerialization) {

            if (forceSerialization === undefined) {
                var forceSerialization = false;
            }

            var record = snapshot.record;

            if (!forceSerialization) {
                if (this.modelFieldsToExclude !== undefined && this.modelFieldsToExclude.indexOf(attribute.name) >= 0 || record._attributesExcludeBySerializer !== undefined && record._attributesExcludeBySerializer.indexOf(attribute.name) >= 0) {
                    return;
                }
            }

            this._super(snapshot, json, key, attribute);
        },
        serializeBelongsTo: function serializeBelongsTo(snapshot, json, relationship, forceSerialization) {

            if (forceSerialization === undefined) {
                var forceSerialization = false;
            }

            var record = snapshot.record;

            if (!forceSerialization) {
                if (this.modelFieldsToExclude && this.modelFieldsToExclude.indexOf(relationship.key) >= 0 || record._attributesExcludeBySerializer && record._attributesExcludeBySerializer.indexOf(relationship.key) >= 0) {

                    return;
                }
            }

            this._super(snapshot, json, relationship);
        },
        serializeHasMany: function serializeHasMany(snapshot, json, relationship, forceSerialization) {

            if (forceSerialization === undefined) {
                var forceSerialization = false;
            }

            var record = snapshot.record;

            if (!forceSerialization) {
                if (this.modelFieldsToExclude && this.modelFieldsToExclude.indexOf(relationship.key) >= 0 || record._attributesExcludeBySerializer && record._attributesExcludeBySerializer.indexOf(relationship.key) >= 0) {
                    return;
                }
            }

            this._super(snapshot, json, relationship);
        },
        attrs: {},
        /**
         * Merge attributes config object with current
         * @author Kamil Jakubowski <k.jakubowski@kontaktenergia.pl>
         * @param Object attrs
         */
        addAttrsConfig: function addAttrsConfig(attrs) {
            if (attrs instanceof Object) {
                var attributes = this.get('attrs');
                for (var index in attrs) {
                    if (attrs.hasOwnProperty(index)) {
                        attributes[index] = attrs[index];
                    }
                }
                this.set('attrs', attributes);
            }
        }
    });

    exports['default'] = ApplicationSerializer;
});
define('ember-new/routes/application', ['exports', 'ember', 'ember-new/config/environment', 'ic-ajax', 'ember-simple-auth/mixins/application-route-mixin', 'ember-new/mixins/declarations-helper'], function (exports, _ember, _emberNewConfigEnvironment, _icAjax, _emberSimpleAuthMixinsApplicationRouteMixin, _emberNewMixinsDeclarationsHelper) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsApplicationRouteMixin['default'], _emberNewMixinsDeclarationsHelper['default'], {

    checkDeclaration: function checkDeclaration() {
      if (this.isNeedToSignDeclaration()) {
        //
        this.transitionTo('declaration');
      }
    },

    actions: {
      didTransition: function didTransition() {
        this.checkDeclaration();
      },
      willTransition: function willTransition(transition) {
        this.checkDeclaration();
      }
    }

  });
});
define('ember-new/models/contract-data-salesman-view-model', ['exports', 'ember-data', 'ember-new/models/abstract'], function (exports, _emberData, _emberNewModelsAbstract) {

    var attr = _emberData['default'].attr;

    exports['default'] = _emberData['default'].Model.extend({

        uuid: attr('string'),

        contractNo: attr('string'),
        dateContractSigned: attr('string'),
        acceptDate: attr('string'),
        dateContractReported: attr('string'),
        dateStart: attr('string'),
        dateEnd: attr('string'),
        monthsNo: attr('string'),

        product: attr('string'),
        productUuid: attr('string'),

        mainTariff: attr('string'),
        mainTariffUuid: attr('string'),

        contractType: attr('string'),
        contractTypeUuid: attr('string'),

        salesmanUuid: attr('string'),
        salesman: attr('string'),
        clientSource: attr('string'),

        ccConsultant: attr('string'),
        ccConsultantUuid: attr('string'),

        idPartner: attr('string'),
        region: attr('string'),

        contractStatusUuid: attr('string'),
        contractStatusName: attr('string'),
        statusTime: attr('string'),
        statusNumber: attr('string'),
        previousContractStatusUuid: attr('string'),
        previousContractStatusName: attr('string'),
        previousStatusNumber: attr('string'),

        newDevice: attr('boolean'),
        ppeCount: attr('number'),

        isAutenti: attr('boolean')

    });
});
define('ember-new/mixins/d-form/d-field-select-multiple-ind', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Mixin.create({

        multiple: true,
        _preventValueObserver: true,
        prompt: null,

        selectionDidChange: (function () {
            //brzydki hack ale cos co mi drugi raz zmienia selectiona i ustawia wartosc na pusta tablice, nie moge zlokalizowac tego observera ~BH
            if (this.get('preventSecondSelectionChange')) {
                this.set('preventSecondSelectionChange', false);
                return;
            }
            this.set('preventSecondSelectionChange', true);
            var value = [];
            if (this.get('selection')) {
                this.get('selection').forEach(function (item) {
                    if (item) {
                        var itemVal = item.value || item.get('value');
                        value.push(itemVal);
                    }
                });
            }
            console.log('vvvv', value);
            this.set('value', value);
        }).observes('selection')

    });

    /*
     import Ember from 'ember';
    
     export default Ember.Mixin.create({
    
     multiple: true,
     _preventValueObserver: true,
     prompt: null,
    
     selectionDidChange: function () {
     //brzydki hack ale cos co mi drugi raz zmienia selectiona i ustawia wartosc na pusta tablice, nie moge zlokalizowac tego observera ~BH
     if (this.get('preventSecondSelectionChange')) {
     this.set('preventSecondSelectionChange', false);
     return;
     }
     this.set('preventSecondSelectionChange', true);
    
     const selection = this.get('selection'),
     contentIds = this.get('content').map(item => item.get('id'));
     let values = [];
    
     console.log('content', contentIds);
    
     if (selection) {
     values = selection.filter(item => typeof item !== 'undefined')
     .filter(item => item.get('value'));
     //.map(item => item.get('value'));
     // values = selection.filter((item) => {
     //     if (item && item.get('value')) {
     //         return item.get('value');
     //     }
     // });
     }
     console.log('values', values);
     this.set('value', values);
     }.observes('selection')
    
     });
     */
});
define('ember-new/mixins/models/individual-gas-prices-list', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {

    var attr = _emberData['default'].attr;
    var refOne = _emberData['default'].belongsTo;
    var refMany = _emberData['default'].hasMany;

    exports['default'] = _ember['default'].Mixin.create({
        price: attr('number'),
        fee: attr('number'),
        dateFrom: attr('string'),
        dateTo: attr('string')
    });
});
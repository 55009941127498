define('ember-new/helpers/in', ['exports', 'ember'], function (exports, _ember) {

    /**
     * Helper, który sprawdza czy wartość zawarta jest string array (format string array: "one,two,three" )
     * Uzycie: {{#if (in contract.type "ee,gas")}} .... {{/if}}
     * @param params do porównania
     */
    var inStringArray = function inStringArray(params) {
        if (!params[1]) {
            return false;
        }

        try {
            return params[1].includes(params[0]);
        } catch (e) {
            return '';
        }
    };
    exports['default'] = _ember['default'].Helper.helper(inStringArray);
});
define("ember-new/models/task-simplified-list-view-model", ["exports", "ember-new/models/abstract", "ember-data", "ember"], function (exports, _emberNewModelsAbstract, _emberData, _ember) {
    exports["default"] = _emberNewModelsAbstract["default"].extend({
        uuid: _emberData["default"].attr('string'),
        forUser: _emberData["default"].attr('string'),
        region: _emberData["default"].attr('string'),
        taskTarget: _emberData["default"].attr('string'),
        time: _emberData["default"].attr('string'),
        client: _emberData["default"].attr(),
        address: _emberData["default"].attr(),

        taskTargetEe: (function () {
            return this.get('taskTarget') === 'ee';
        }).property('taskTarget'),

        taskTargetGas: (function () {
            return this.get('taskTarget') === 'gas';
        }).property('taskTarget')
    });
});
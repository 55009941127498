define('ember-new/components/d-form/individual-contract/sells', ['exports', 'ember', 'ember-new/components/d-form', 'ember-new/templates/components/d-form'], function (exports, _ember, _emberNewComponentsDForm, _emberNewTemplatesComponentsDForm) {
    exports['default'] = _emberNewComponentsDForm['default'].extend({
        layout: _emberNewTemplatesComponentsDForm['default'],

        customActionAfterSuccessfulSubmit: function customActionAfterSuccessfulSubmit() {
            this.get('model').reload();
        },

        eInvoiceChanged: (function () {
            if (!this.get('model.client.email') && this.get('formData.eInvoice') == true) {
                this.get('applicationAlerts').addActionMessage('warning', 'Pole E-Faktury można zaznaczyć tylko, jeżeli klient ma wpisany adres e-mail.');
                this.set('formData.eInvoice', false);
                this.get('formRows.eInvoice.field').valueChangedExternally();
            }
        }).observes('formData.eInvoice')

    });
});
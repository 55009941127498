define('ember-new/models/individual-contract-partner-files-view-model', ['exports', 'ember-data'], function (exports, _emberData) {

    var attr = _emberData['default'].attr;
    var refOne = _emberData['default'].belongsTo;

    exports['default'] = _emberData['default'].Model.extend({

        partnerFile1: refOne('Upload', { inverse: null }),
        partnerFile2: refOne('Upload', { inverse: null }),
        partnerFile3: refOne('Upload', { inverse: null }),
        partnerFile4: refOne('Upload', { inverse: null }),
        partnerFile5: refOne('Upload', { inverse: null }),
        vcRecording: refOne('Upload', { inverse: null }),

        uuid: attr('string'),
        partnerFile1Uuid: attr('string'),
        partnerFile2Uuid: attr('string'),
        partnerFile3Uuid: attr('string'),
        partnerFile4Uuid: attr('string'),
        partnerFile5Uuid: attr('string'),
        vcRecordingUuid: attr('string')

    });
});
define('ember-new/controllers/individual-contract/validate', ['exports', 'ember', 'ember-new/mixins/controllers/helpers/form-processing-controller', 'ember-new/config/environment'], function (exports, _ember, _emberNewMixinsControllersHelpersFormProcessingController, _emberNewConfigEnvironment) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsControllersHelpersFormProcessingController['default'], {

        ajax: _ember['default'].inject.service('ajax'),

        reset: (function () {
            this.set('cerrors', []);
            this.set('warn', []);
            this.set('success', '');
        }).observes('model'),

        actions: {
            validate: function validate() {

                this.set('cerrors', []);
                this.set('warn', []);
                this.set('success', '');

                var _this = this;

                if (!this.beforeSubmit()) {
                    return;
                }

                var model = this.get('model');

                var ajaxPromise = this.get('ajax').ajaxCustom(_emberNewConfigEnvironment['default'].apiUrl + '/api/individual-contract/validate/' + model.get('id'), [], 'GET');

                ajaxPromise.then(function (response) {
                    _this.handleSuccess(response);
                    if (response.warn) {
                        _this.set('warn', response.warn);
                    }
                    if (response.responseJSON.success) {
                        _this.set('success', 'SUKCES, umowa poprawna, można wygenerować USYS');
                    }
                }, function (response) {
                    _this.handleNotValid(response);
                    _this.set('savingInProgress', false);
                    handleAjaxError(response, function (response) {
                        _this.set('cerrors', response.responseJSON);
                    });
                });
            }
        }

    });
});
define("ember-new/routes/b2b-contract/settings/base-prices", ["exports", "ember-new/routes/authenticated"], function (exports, _emberNewRoutesAuthenticated) {
    exports["default"] = _emberNewRoutesAuthenticated["default"].extend({

        model: function model() {
            return this.store.findAll('BasePriceSetListDzuViewModel');
        },

        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            }
        }

    });
});
define('ember-new/mixins/individual-contract-price-lists/controllers/condition', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Mixin.create({

        fillForm: function fillForm(form) {

            this.set('templateType', this.get('model.template.type'));
            this.set('areasQuantity', this.get('model.template.tariffGroupAreasQuantity'));
            form.set('model.templateUuid', this.get('model.template.uuid'));
            form.set('model.type', null);
            form.set('model.periodType', null);
            form.set('model.yearFrom', null);
            form.set('model.monthFrom', null);
            form.set('model.yearTo', null);
            form.set('model.monthTo', null);
            form.set('model.priceG', null);
            form.set('model.priceE1', null);
            form.set('model.priceE2', null);
            form.set('model.priceE3', null);
            form.set('model.priceE4', null);
            form.set('model.fee', null);
        },

        afterCommand: function afterCommand(response) {
            var ctrl = this;
            var route = this.container.lookup('route:individual-contract-price-lists.template');
            route.refresh();
            setTimeout(function () {
                ctrl.transitionToRoute('individual-contract-price-lists.template');
            }, 500);
        },

        typeContent: [{ value: 'S', label: 'Cennik standardowy' }, { value: 'P', label: 'Cennik promocyjny' }],

        periodTypeContent: [{ value: 'Y', label: 'Rok' }, { value: 'M', label: 'Miesiąc' }, { value: 'MP', label: 'Zakres miesięcy' }, { value: 'MPO', label: 'Warunek otwarty (bez daty końca)' }],

        monthFromHidden: (function () {
            return this.get('periodType') === 'Y';
        }).property('periodType'),

        yearToHidden: (function () {
            return this.get('periodType') !== 'MP';
        }).property('periodType'),

        priceGHidden: (function () {
            return this.get('templateType') !== 'GAS';
        }).property('templateType'),

        priceE1Hidden: (function () {
            return this.get('templateType') !== 'EE';
        }).property('templateType'),

        priceE2Hidden: (function () {
            return this.get('templateType') !== 'EE' || this.get('areasQuantity') === '1';
        }).property('templateType', 'areasQuantity'),

        priceE3Hidden: (function () {
            return this.get('templateType') !== 'EE' || this.get('areasQuantity') === '1' || this.get('areasQuantity') === '2';
        }).property('templateType', 'areasQuantity'),

        priceE4Hidden: (function () {
            return this.get('templateType') !== 'EE' || this.get('areasQuantity') === '1' || this.get('areasQuantity') === '2' || this.get('areasQuantity') === '3';
        }).property('templateType', 'areasQuantity')

    });
});
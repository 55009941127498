define('ember-new/controllers/tasks/channel-reservations', ['exports', 'ember-new/config/environment', 'ember'], function (exports, _emberNewConfigEnvironment, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        isResultReceived: false,
        isSubmitting: false,
        clientsContent: null,

        ajax: _ember['default'].inject.service('ajax'),
        applicationAlertsManager: _ember['default'].inject.service('application-alerts-manager'),
        oldContent: null,

        actions: {
            checkNip: function checkNip(nips) {
                if (nips === null || nips === '' || nips === undefined) {
                    this.get('applicationAlertsManager').addActionMessage('warning', 'Żaden nip nie został podany.');
                    return;
                }

                // if (this.get('oldContent') === nips) { return; } // prevention for multi submit

                this.set('isSubmitting', true);
                this.set('oldContent', nips);
                var self = this;
                var model = this.get('model.rows');
                var store = this.get('store');
                var nipCollection = nips.split('\n');

                store.unloadAll('bulk-channel-reservation.client-content-row-view-model');

                var nipsPayload = {
                    'ChannelReservationClientContentViewModel': []
                };

                nipCollection.forEach(function (singleNip) {
                    singleNip = singleNip.trim();
                    nipsPayload.ChannelReservationClientContentViewModel.push(singleNip);
                });

                var ajax = this.get('ajax');
                var promise = ajax.ajax(_emberNewConfigEnvironment['default'].apiUrl + '/rest/tasks/channelreservationclientcontentviewmodels', nipsPayload, 'POST');

                promise.then(function (response) {
                    var data = response.ChannelReservationClientContentViewModel;

                    data.forEach(function (row) {
                        model.pushObject(store.createRecord('bulk-channel-reservation.client-content-row-view-model', {
                            nip: row.nip,
                            status: row.status,
                            message: row.message,
                            isAllowProcess: row.isAllowProcess,
                            eeReservation: row.eeReservation,
                            gasReservation: row.gasReservation,
                            isBlockedItem: row.isBlockedItem
                        }));
                    });

                    self.set('isResultReceived', true);
                    self.set('isSubmitting', false);
                }, function (reject) {
                    console.log(reject);
                    self.set('isSubmitting', false);
                });
            }
        }
    });
});
define('ember-new/models/abstract', ['exports', 'ember-data'], function (exports, _emberData) {

    var Model = _emberData['default'].Model.extend({
        applicationAlerts: Ember.inject.service('application-alerts-manager'),

        customUpdate: function customUpdate(actionName, attributesToUpdate, metaData, customVarname, customRequestData, beforePushPayload) {
            return this.store.doCustomUpdate(this, actionName, attributesToUpdate, metaData, customVarname, customRequestData, beforePushPayload);
        },
        customPost: function customPost(actionName, attributesToUpdate, metaData, customVarname, customRequestData, beforePushPayload) {
            return this.store.doCustomPost(this, actionName, attributesToUpdate, metaData, customVarname, customRequestData, beforePushPayload);
        },
        saveOnlyModified: function saveOnlyModified() {
            throw new Error("Method hasn't been implemented yet!");
        },
        _attributesExcludeBySerializer: [],
        save: function save() {
            var promise = this._super();
            var _this = this;
            promise.then(function () {}, function (response) {

                if (response instanceof _emberData['default'].InvalidError) {
                    console.log('validation errors:');
                    // do nothing validation will be resolved later in .then() of promise
                    //reject(response); // returns JSON with validation errors as a result of the promise
                } else if (response instanceof _emberData['default'].AdapterError || response instanceof _emberData['default'].AbortError || response instanceof _emberData['default'].TimeoutError || response instanceof Error) {
                        _this.get('applicationAlerts').addActionMessage('danger', 'Wystąpił błąd. Skontaktuj się z administratorem.');
                        return;
                    }

                //else if (jqXHR.status == 403) {
                //    handleBackend403(jqXHR);
                //}
                //else {
                //    console.log("jqXHR", jqXHR);
                //    if (jqXHR.status.toString().substring(0, 1) === "2") {
                //        console.log("Server has returned 200 status code, so it seems response JSON to be broken.");
                //    }
                //    else {
                //        console.error("Unknown ajax error to : " + url);
                //    }
                //
                //    if (!devMode) {
                //        alert('Wystąpił błąd. Skontaktuj się z administratorem.');
                //    }
                //}
            });

            return promise;
        }
    });

    exports['default'] = Model;
});
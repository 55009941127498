define('ember-new/routes/tasks/search', ['exports', 'ember-new/routes/authenticated'], function (exports, _emberNewRoutesAuthenticated) {
	exports['default'] = _emberNewRoutesAuthenticated['default'].extend({

		actions: {
			didTransition: function didTransition() {
				this.controller.resetCtrl();
				return true;
			}
		}

	});
});
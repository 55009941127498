define('ember-new/serializers/individual-ppe', ['exports', 'ember-new/serializers/application', 'ember-new/mixins/base-statusable-document-serializer', 'ember-new/mixins/serializers/individual-ppm'], function (exports, _emberNewSerializersApplication, _emberNewMixinsBaseStatusableDocumentSerializer, _emberNewMixinsSerializersIndividualPpm) {
    exports['default'] = _emberNewSerializersApplication['default'].extend(_emberNewMixinsBaseStatusableDocumentSerializer['default'], _emberNewMixinsSerializersIndividualPpm['default'], {
        IndividualPpeSerializerInit: function IndividualPpeSerializerInit() {
            this.addAttrsConfig({

                os: { serialize: 'id', deserialize: 'id' },
                osd: { serialize: 'id', deserialize: 'id' }

            }).on('init');
        }
    });
});
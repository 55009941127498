define('ember-new/routes/b2b-contract/dzu/show-ee/prices/edit-price', ['exports', 'ember-new/routes/authenticated'], function (exports, _emberNewRoutesAuthenticated) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({
        model: function model(params) {
            var route = this;
            var contract = this.modelFor('b2b-contract.dzu.show-ee');

            var promiseModel = new Ember.RSVP.Promise(function (resolve, reject) {
                var products = route.store.query('EePriceConditionProductViewModel', { contractProductId: contract.get('productId') });

                products.then(function (response) {
                    var basePrices = route.store.query('BasePriceSetSelectDzuViewModel', { productId: contract.get('productId') });
                    basePrices.then(function (response2) {
                        var priceCondition = route.store.findRecord('EditPriceConditionViewModel', params.id, { reload: true });
                        priceCondition.then(function (response3) {
                            resolve(response3);
                        });
                    });
                });
            }, "LoadModelEeEditPrice");

            return promiseModel;
        },

        setupController: function setupController(ctrl, model) {
            ctrl.set('contractId', this.modelFor('b2b-contract.dzu.show-ee').get('id'));
            ctrl.set('model', model);
        }
    });
});
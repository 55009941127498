define("ember-new/controllers/individual-contract-partners/filters", ["exports", "ember", "ember-new/mixins/paginable-controller"], function (exports, _ember, _emberNewMixinsPaginableController) {
	exports["default"] = _ember["default"].Controller.extend({

		/**
   * Obiekt z filtrami przekazywany pozniej do queryParams.
   * Ustawione w oddzielnym obiekcie zeby potem przeleciec foreach/map,
   * a nie wypisywac recznie kazdy filtr.
   */
		filters: {
			contractNo: null,
			fuelType: null,
			contractStatus: null,
			statusByPartner: null,
			vcStatus: null,
			dateReportedFrom: null,
			dateReportedTo: null,
			clientLastName: null,
			dateSignedFrom: null,
			dateSignedTo: null,
			pesel: null,
			vcRecordingStatus: null
		},

		init: function init() {
			this._super();
			_ember["default"].run.schedule("afterRender", this, function () {
				this.send("setupQueryParamsToForm");
			});
		},

		staticSelectsContent: {
			fuelType: [{ label: 'wszystkie', value: '' }, { label: 'Gaz', value: 'gas' }, { label: 'EE', value: 'ee' }]
		},

		vcByBoSelectContent: {
			status: [{ label: 'bez wartości', value: 'no-value' }, { label: 'TAK', value: true }, { label: 'NIE', value: false }]
		},

		reset: function reset() {
			var _this = this;

			var filters = this.get('filters');
			Object.keys(filters).forEach(function (key) {
				_this.set("filters." + key, null);
			});
		},

		actions: {
			applyFilters: function applyFilters() {
				this.set('filters.page', 1); // reset page
				console.log(' this.get(\'filters\')', this.get('filters'));
				this.transitionToRoute('individual-contract-partners', { queryParams: this.get('filters') });
			},
			resetFilters: function resetFilters() {
				this.reset();
			},

			/**
    * Ustawianie wartosci filtrow na podstawie queryParams z URL.
    * qParams - ustawiany w Route.
    */
			setupQueryParamsToForm: function setupQueryParamsToForm() {
				var _this2 = this;

				var qp = this.get('qParams');
				Object.keys(qp).forEach(function (param) {
					// sprawdzamy czy dany param istnieje w naszym obiekcie 'filters'
					// aby uniknac nadpisania innych queryParams (np. order, orderDirection etc..)
					// ktorych nie ustawiamy w filtrach ~lyczos
					// console.log(`filters.${param} hasOwnProp`, this.get('filters').hasOwnProperty(param));
					if (_this2.get('filters').hasOwnProperty(param)) {
						_this2.set("filters." + param, qp[param]);
					}
				});
			}

		}
	});
});
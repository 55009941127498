define('ember-new/routes/tasks/tasks-client/gas-contact', ['exports', 'ember-new/routes/authenticated'], function (exports, _emberNewRoutesAuthenticated) {
	exports['default'] = _emberNewRoutesAuthenticated['default'].extend({
		model: function model() {
			return this.modelFor('tasks.tasks-client');
		},

		setupController: function setupController(ctrl, model) {
			model.reload();
			ctrl.set('model', model);
		},

		actions: {
			refreshModel: function refreshModel() {
				this.store.unloadAll('ambBusinessClient');
				this.refresh();
			}
		}
	});
});
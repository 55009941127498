define('ember-new/components/d-form/individual-contract/recordings', ['exports', 'ember', 'ember-new/components/d-form', 'ember-new/templates/components/d-form', 'ember-new/mixins/d-form/d-form-upload', 'ember-new/config/environment'], function (exports, _ember, _emberNewComponentsDForm, _emberNewTemplatesComponentsDForm, _emberNewMixinsDFormDFormUpload, _emberNewConfigEnvironment) {
    exports['default'] = _emberNewComponentsDForm['default'].extend(_emberNewMixinsDFormDFormUpload['default'], {
        layout: _emberNewTemplatesComponentsDForm['default'],

        contractId: null,
        type: null,

        submitRestAsSimple: true,
        submitModelNamespace: 'individualContract',
        submitRestAsSimpleMethod: 'PATCH',

        submitSimpleUrl: (function () {
            return _emberNewConfigEnvironment['default'].apiUrl + '/rest/individual' + this.get('type') + 'contracts/' + this.get('contractId') + '/recordings';
        }).property(),

        resolveContents: function resolveContents(form) {
            return new _ember['default'].RSVP.Promise(function (resolve, reject) {

                var store = form.get('store');
                var upload = store.peekAll('upload');
                _ember['default'].RSVP.Promise.all([upload]).then(function (result) {
                    var upload = result[0];
                    resolve(_ember['default'].Object.create({
                        fileAudio1: upload,
                        fileAudio2: upload,
                        fileAudio3: upload
                    }));
                });
            });
        }
    });
});
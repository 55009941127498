define('ember-new/components/d-form/renouncement/documents', ['exports', 'ember', 'ember-new/components/d-form', 'ember-new/templates/components/d-form', 'ember-new/mixins/d-form/d-form-upload'], function (exports, _ember, _emberNewComponentsDForm, _emberNewTemplatesComponentsDForm, _emberNewMixinsDFormDFormUpload) {
    exports['default'] = _emberNewComponentsDForm['default'].extend(_emberNewMixinsDFormDFormUpload['default'], {
        layout: _emberNewTemplatesComponentsDForm['default'],

        resolveContents: function resolveContents(form) {
            return new _ember['default'].RSVP.Promise(function (resolve, reject) {

                var store = form.get('store');
                var upload = store.peekAll('upload');
                _ember['default'].RSVP.Promise.all([upload]).then(function (result) {
                    var upload = result[0];
                    resolve(_ember['default'].Object.create({
                        scannedDocuments: upload,
                        scannedDocuments2: upload,
                        scannedDocuments3: upload,
                        scannedDocuments4: upload,
                        scannedDocuments5: upload
                    }));
                });
            });
        }
    });
});
define('ember-new/mixins/b2b-contract/components/d-form/gas-business-tariff/edit-form', ['exports', 'ember', 'ember-new/templates/components/d-form'], function (exports, _ember, _emberNewTemplatesComponentsDForm) {
    exports['default'] = _ember['default'].Mixin.create({
        layout: _emberNewTemplatesComponentsDForm['default'],
        dictionariesManager: _ember['default'].inject.service('dictionaries-manager'),

        excises: {
            0: 'BEZ AKCYZY',
            1: 'OPAŁOWY',
            2: 'SILNIKI SPALINOWE'
        },

        exciseChanged: _ember['default'].observer('formData.excise', 'formData.tariff', 'formData.tariffPgnig', function () {
            var _this = this;

            var name = '';

            if (this.get('formData.tariff')) {
                (function () {
                    var content = _this.get('dictionariesManager').getMainDictionary('business_gas_tariff_group_fortum');
                    var uuid = _this.get('formData.tariff');
                    var t = content.filter(function (item) {
                        return item.get('uuid') == uuid;
                    });
                    name += t[0].get('name') + '-TARYFA FORTUM-';
                })();
            } else if (this.get('formData.tariffPgnig')) {
                (function () {
                    var content = _this.get('dictionariesManager').getMainDictionary('business_gas_tariff_group_pgnig');
                    var uuid = _this.get('formData.tariffPgnig');
                    var t = content.filter(function (item) {
                        return item.get('uuid') == uuid;
                    });
                    name += t[0].get('name') + '-TARYFA PGNIG-';
                })();
            }

            if (this.get('formData.excise')) {
                name += this.get('excises')[this.get('formData.excise')];
            }

            this.set('formData.name', name);
        }),

        afterSuccessfulSubmit: function afterSuccessfulSubmit() {
            var targetController = this.get('targetObject');
            var mainController = targetController.container.lookup('controller:b2b-contract.administration.gas-business-tariff.list');
            var mainRoute = mainController.get('target');

            mainRoute.send('refreshModel');

            setTimeout(function () {
                targetController.transitionToRoute('b2b-contract.administration.gas-business-tariff.list');
            }, 500);
        }
    });
});
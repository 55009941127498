define('ember-new/components/sticky-table', ['exports', 'ember'], function (exports, _ember) {

    var Component = _ember['default'].Component.extend({
        tagName: 'div',
        classNames: ['table-responsive scrolled'],

        initStickyTables: (function () {
            var topScroll = document.getElementsByClassName('topScroll');

            if (topScroll != null) {
                this.setTopScrollbarWidth();
                this.connectBottomAndTopScrollbars();
            }
        }).on('didInsertElement')
    });

    Component.prototype.getScrollbarWidth = function () {
        var outer = document.createElement('div');
        outer.style.visibility = 'hidden';
        outer.style.overflow = 'scroll';
        outer.style.msOverflowStyle = 'scrollbar';

        document.body.appendChild(outer);

        var inner = document.createElement('div');

        outer.appendChild(inner);

        var scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

        outer.parentNode.removeChild(outer);

        return scrollbarWidth;
    };

    // Ustawia długość górnego scrollbara
    Component.prototype.setTopScrollbarWidth = function () {
        var width = $(".table").width();
        // 4px różnica wynikająca pomiędzy dolnym a górnym scrollbarem.
        var topScrollbarWidth = width + this.getScrollbarWidth() + 4;
        $(".topScrollWidth").width(topScrollbarWidth);
    };

    //Łączy oba scrollbare.
    Component.prototype.connectBottomAndTopScrollbars = function () {
        $(function () {
            $(".topScroll").scroll(function () {
                $(".mainScroll").scrollLeft($(".topScroll").scrollLeft());
            });
            $(".mainScroll").scroll(function () {
                $(".topScroll").scrollLeft($(".mainScroll").scrollLeft());
            });
        });
    };

    exports['default'] = Component;
});
/* http://getbootstrap.com/css/?#helper-classes-colors */
define('ember-new/controllers/b2b-contract/salesman/show-gas/comments', ['exports', 'ember', 'ember-new/config/environment'], function (exports, _ember, _emberNewConfigEnvironment) {
    exports['default'] = _ember['default'].Controller.extend({
        ajax: _ember['default'].inject.service('ajax'),

        fillForm: function fillForm(form) {
            form.set('model.contextId', this.get('idContract'));
            form.set('model.clientId', this.get('idClient'));
            form.set('model.type', 'contract');
            form.set('model.subtype', 'sales_dk');
            form.set('model.userContent', null);
        },

        isCcManager: (function () {
            if (this.get('TheUser').isGranted('ROLE_CC_MANAGER')) {
                return true;
            }

            return false;
        }).property(),

        actions: {
            clearCommentsNotifications: function clearCommentsNotifications(contractId, clientId, notificationsExist) {

                if (!notificationsExist) {
                    return;
                }

                var _this = this;
                var payload = {};

                payload['ClearSalesmanCommentNotificationCommand'] = {};
                payload['ClearSalesmanCommentNotificationCommand']['contractId'] = contractId;
                payload['ClearSalesmanCommentNotificationCommand']['clientId'] = clientId;

                var ajaxPromise = this.get('ajax').ajaxPost(_emberNewConfigEnvironment['default'].apiUrl + '/rest/clearsalesmancommentnotificationcommands', payload);

                ajaxPromise.then(function () {
                    notificationsExist = false;
                    _this.set('refreshContractListModel', true);
                    var ctrl = _this.get('contractCtrl');
                    ctrl.set('notificationsExist', false);
                });
            }

        }

    });
});
define('ember-new/components/d-field-text-autocomplete', ['exports', 'ember', 'ember-new/components/d-field-text'], function (exports, _ember, _emberNewComponentsDFieldText) {
    exports['default'] = _emberNewComponentsDFieldText['default'].extend({
        attributeBindings: ['isAutocomplete:autocomplete', 'nameValue:name'],

        isAutocomplete: true,
        nameValue: '',

        setAutocomplete: function setAutocomplete(value) {
            if (value === true) {
                this.set('isAutocomplete', true);
            } else {
                this.set('isAutocomplete', null);
            }
        },

        setNameValue: function setNameValue(value) {
            this.set('nameValue', value);
        }
    });
});
define('ember-new/mixins/client', ['exports', 'ember', 'ember-new/mixins/base-document', 'ember-data'], function (exports, _ember, _emberNewMixinsBaseDocument, _emberData) {
				exports['default'] = _ember['default'].Mixin.create(_emberNewMixinsBaseDocument['default'], {

								_attributesExcludeBySerializer: ['isReservedEE', 'isReservedGas', 'eeReservationBy', 'reservationAcquisitionProcess', 'reservationExpireDate', 'activeAcquisitionProcess', 'companyDocKrs', 'companyDocNip', 'companyDocRegon', 'companyDocBre', 'boSpecialist'],

								// relations:
								boSpecialist: _emberData['default'].belongsTo('user', { inverse: null }),

								// embedded noKey relations

								address: _emberData['default'].belongsTo('Address', { embedded: true }),

								// special properties
								type: _emberData['default'].attr('string'), // distriminator @VirtualProperty

								// options
								_childrenMixinsSuffix: 'Mixin',
								_childrenSuffix: 'Client'
				});

				// user model
				var attr = _emberData['default'].attr;

				/* to na później
    KePersonalClientMixin = Ember.Mixin.create({
        firstName: DS.attr('string'),
        lastName: DS.attr('string'),
        pesel: DS.attr('string'),
        fullName: function() {
            return this.get('firstName') + ' ' + this.get('lastName');
        }.property('firstName', 'lastName')
    });
    
    KePersonalClient = Client.extend(KePersonalClientMixin);
    */
				var referenceOne = _emberData['default'].belongsTo;
				var referenceMany = _emberData['default'].hasMany;
});
define("ember-new/controllers/b2b-contract/salesman", ["exports", "ember-new/mixins/sortable-controller", "ember-new/mixins/filterable-controller", "ember-new/mixins/paginable-controller", "ember", "ember-new/config/environment", "ember-new/utils/utils", "ember-new/utils/encode-object-to-uri", "ember-new/utils/get-query-params-values"], function (exports, _emberNewMixinsSortableController, _emberNewMixinsFilterableController, _emberNewMixinsPaginableController, _ember, _emberNewConfigEnvironment, _emberNewUtilsUtils, _emberNewUtilsEncodeObjectToUri, _emberNewUtilsGetQueryParamsValues) {
    exports["default"] = _ember["default"].Controller.extend(_emberNewMixinsSortableController["default"], _emberNewMixinsFilterableController["default"], _emberNewMixinsPaginableController["default"], Object.defineProperties({

        applicationAlerts: _ember["default"].inject.service('application-alerts-manager'),
        cookies: _ember["default"].inject.service(),

        isReloading: false,

        queryParams: {
            contractStatusDateFilterFrom: 'statusFrom',
            contractStatusDateFilterTo: 'statusTo',
            contractStatusFilter: 'status',
            nipFilter: 'nip',
            companyFilter: 'company',
            contractTypeFilter: 'contractType',
            readCommentsFilter: 'readComments',
            regionFilter: 'region',
            salesmanFilter: 'salesman',
            contractReportedDateFromFilter: 'contractReportedFrom',
            contractReportedDateToFilter: 'contractReportedTo'
        },

        //ustawienie query paramsów na null
        contractStatusDateFilterFrom: null,
        contractStatusDateFilterTo: null,
        contractStatusFilter: null,
        nipFilter: null,
        companyFilter: null,
        contractTypeFilter: null,
        readCommentsFilter: null,
        regionFilter: null,
        salesmanFilter: null,
        contractReportedDateFromFilter: null,
        contractReportedDateToFilter: null,

        // ustawienie wartości z forma na null
        statusFrom: null,
        statusTo: null,
        nip: null,
        company: null,
        readComments: null,
        region: null,
        salesman: null,

        isPart: (function () {
            return _emberNewConfigEnvironment["default"].applicationType === 'DMT';
        }).property(),

        isTra: (function () {
            return _emberNewConfigEnvironment["default"].applicationType === 'DSP';
        }).property(),

        exportAllowed: (function () {
            if (this.get('isTra') && (this.get('TheUser').isGranted('ROLE_SALES_HEADMANAGER') || this.get('TheUser').isGranted('ROLE_CC_MANAGER') || this.get('TheUser').isGranted('ROLE_ADMIN')) || this.get('isPart') && (this.get('TheUser').isGranted('ROLE_SALES_HEADMANAGER') || this.get('TheUser').isGranted('ROLE_CC_MANAGER') || this.get('TheUser').isGranted('ROLE_SALES_MANAGER') || this.get('TheUser').isGranted('ROLE_ADMIN'))) {

                return true;
            }

            return false;
        }).property(),

        filtersLabels: {},

        hasContracts: _ember["default"].computed('model', function () {
            return this.get('model.length') > 0;
        }),

        initializeTempFilters: function initializeTempFilters() {
            this.set('status', this.get('contractStatusFilter'));
            this.set('contractType', this.get('contractTypeFilter'));
            this.set('nip', this.get('nipFilter'));
            this.set('company', this.get('companyFilter'));
            this.set('readComments', this.get('readCommentsFilter'));

            this.set('statusFrom', this.get('contractStatusDateFilterFrom'));
            this.set('statusTo', this.get('contractStatusDateFilterTo'));

            this.set('contractReportedFrom', this.get('contractReportedDateFromFilter'));
            this.set('contractReportedTo', this.get('contractReportedDateToFilter'));

            this.set('region', this.get('regionFilter'));
            this.set('salesman', this.get('salesmanFilter'));
        },

        contractReportedFromChosen: (function () {
            return this.get('contractReportedFrom') !== this.get('contractReportedDateFromFilter');
        }).property('contractReportedFrom'),

        contractReportedToChosen: (function () {
            return this.get('contractReportedTo') !== this.get('contractReportedDateToFilter');
        }).property('contractReportedTo'),

        statusDateFromChosen: (function () {
            return this.get('statusFrom') !== this.get('contractStatusDateFilterFrom');
        }).property('statusFrom'),

        statusDateToChosen: (function () {
            return this.get('statusTo') !== this.get('contractStatusDateFilterTo');
        }).property('statusTo'),

        // Interface:
        typeFilterIsAll: (function () {
            return this.get('contractTypeFilter') === null;
        }).property('contractTypeFilter'),

        typeFilterIsGas: (function () {
            return this.get('contractTypeFilter') === 'gas';
        }).property('contractTypeFilter'),

        typeFilterIsEe: (function () {
            return this.get('contractTypeFilter') === 'ee';
        }).property('contractTypeFilter'),

        // sprawdza czy w statusFilterArray sa wlaczone jakies filtry
        statusFilterIsAll: (function () {
            return this.get('statusFilterArray').length === 0;
        }).property('statusFilterArray'),

        readCommentsFilterValues: [{ label: 'przeczytane', value: true }, { label: 'nieprzeczytane', value: false }],

        /**
         * Zmienia obiekt z lista aktywowanych filtrow
         */
        setActivatedFilters: function setActivatedFilters() {
            var _this = this;

            var filtersLabels = this.get('filtersLabels'),
                // labelki z opisami filtrow
            filtersQueryParams = this.queryParamsVars; // queryParams z URL - klucz(backend) : wartosc (frontend)
            // dicManager = this.get('dictionariesManager');

            var activatedFilters = []; // tmp na czas mapowania

            Object.keys(filtersQueryParams).map(function (filterName) {
                var filterValue = _this.get(filterName),
                    // wartosc filtra
                filterKey = filtersQueryParams[filterName]; // nazwa filtra

                // sprawdzamy czy dany filtr jest rozny od null lub czy istnieje
                if (filterValue !== null && filterValue !== undefined && filterValue !== '') {
                    // szukamy Labelki w obiekcie filtersLabels po nazwie filtra z wartości własciwosci z queryParams
                    // przyklad dla nazwy firmy:
                    // companyFilter - nazwa wlasciwosci w queryParams (idzie na backend)
                    // company - wartość własciwosci z queryParams (to co w URL na frontend)
                    // filtersLabels - obiekt z opisami na potrzeby wyswtelenia
                    // filtersLabels.company - Nazwa do wyswietlenia

                    // let dicManagerVal  = dicManager.getMainDictionaryEntryNameByUuid(filterValue);
                    // console.log('dicManagerVal',dicManagerVal);

                    var currentFilter = {
                        label: filtersLabels[filterKey],
                        // jezeli znajduje wartosc(uuid) w slowniku to podstawia jego name, jezeli nie to ustawia wartosc z URL
                        value: filterValue,
                        // value: dicManagerVal ? dicManagerVal : filterValue,
                        isBoolean: filterValue === 'true' || filterValue === 'false' // uproszczone rzutowanie, na potrzeby HBS
                    };
                    activatedFilters.push(currentFilter);
                }
            });
            this.set('activatedFilters', activatedFilters);
        },

        resetStatusFilters: function resetStatusFilters() {
            this.get('statusesSummary').forEach(function (obj) {
                return obj.set('isActive', false);
            });
        },

        actions: {

            submitStatusDateFilters: function submitStatusDateFilters() {
                // TODO: Przenieść do DZU... i zmienic nazwy parametrow na te same... ~Lyczos
                //  console.log('contractStatusDateFilterFrom before', this.get('contractStatusDateFilterFrom'));
                //   console.log('statusFrom before', this.get('statusFrom'));
                this.set('contractStatusDateFilterFrom', this.get('statusFrom'));
                this.set('contractStatusDateFilterTo', this.get('statusTo'));
                // console.log('contractStatusDateFilterFrom after', this.get('contractStatusDateFilterFrom'));
                // console.log('statusFrom after', this.get('statusFrom'));

                this.set('statusFrom');
                this.set('statusTo');

                if (_emberNewMixinsPaginableController["default"].detect(this)) {
                    this.set('page', 1);
                }
            },

            typeFilterEe: function typeFilterEe() {
                this.set('contractTypeFilter', 'ee');

                return this.get('contractTypeFilter');
            },

            typeFilterGas: function typeFilterGas() {
                this.set('contractTypeFilter', 'gas');

                return this.get('contractTypeFilter');
            },

            typeFilterAll: function typeFilterAll() {
                this.set('contractTypeFilter', null);

                return this.get('contractTypeFilter');
            },

            /**
             * Przełącza filtr statusu umowy
             * @param filter obiekt z filtrem statusu umowy
             */
            applyStatusFilter: function applyStatusFilter(filter) {
                var filterId = _ember["default"].get(filter, 'id');
                this.set('status', filterId);
                this.send('applyFilters');
            },

            /**
             * Resetuje  (wylacza) wszystkie filtry statusu umowy
             */
            applyStatusFilterAll: function applyStatusFilterAll() {
                this.set('status', null);
                this.send('applyFilters');
            },

            /**
             * Zastosowanie wybranych filtrów
             */
            applyFilters: function applyFilters() {
                var _this2 = this;

                var filters = this.queryParamsVars;
                Object.keys(filters).map(function (filterParamKey) {
                    // pobieramy wartosc z pola formularza i ustawiamy jego wartosc dla filtrow
                    // jezeli wartosc filtru jest pusta to ustaw na null (usuwa z URL, fix na potrzeby sprawdzania aktywnych filtrow)
                    if (_this2.get(filters[filterParamKey]) === '') {
                        _this2.set(filterParamKey, null);
                    } else {
                        _this2.set(filterParamKey, _this2.get(filters[filterParamKey]));
                    }
                });
                this.setActivatedFilters();
                if (_emberNewMixinsPaginableController["default"].detect(this)) {
                    this.set('page', 1);
                }
            },

            /**
             * Resetowanie wszystkich filtrów
             */
            resetDetailedFilters: function resetDetailedFilters() {
                var _this3 = this;

                var filters = this.queryParamsVars;
                // przelatuje po wszsystkich kluczach z queryParams i ustawia je na NULL
                Object.keys(filters).map(function (filterParamKey) {
                    _this3.set(filterParamKey, null);
                });
                this.resetStatusFilters();
                if (_emberNewMixinsPaginableController["default"].detect(this)) {
                    this.set('page', 1);
                }
            },

            contractsSalesmanExport: function contractsSalesmanExport() {
                if (!this.get('exportAllowed')) {
                    this.get('applicationAlerts').addActionMessage('danger', "Nie masz uprawnień do wykonania tej akcji");
                    return false;
                }

                var queryParamsValues = (0, _emberNewUtilsGetQueryParamsValues["default"])(this, this.queryParamsVars),
                    encodedURIFilters = (0, _emberNewUtilsEncodeObjectToUri["default"])(queryParamsValues),
                    encodedURI = _emberNewUtilsUtils["default"].generateSimpleUrl('/export/newContractsSalesman?' + encodedURIFilters, '&', _emberNewConfigEnvironment["default"], this.get('TheUser'), this.get('cookies'));

                window.open(encodedURI);
            }

        }

    }, {
        queryParamsVars: { /**
                            * getter zwracajacy queryParams zainicjowane wczesniej
                            * uzywane do inicjalizacji filtrow (m.in. wypelnienia pol z adresu url)
                            * pobierane w ten sposob bo przy probie pobrania przez this.get('queryParams')
                            * dostaje juz zmodyfikowany obiekt przez mixin
                            * i nie można dostac się bezpośrednio do samych kluczy/wartości filtrow
                            * @returns {SortableControllerMixin.queryParams|{contractStatusFilter, contractTypeFilter, contractNoFilter, nipFilter, companyFilter, newConnectionFilter, dateContractReportedFilterFrom, dateContractReportedFilterTo, dateOfficeReceivedFilterFrom, dateOfficeReceivedFilterTo, dateOfficeReceivedLastDocumentFilterFrom, dateOfficeReceivedLastDocumentFilterTo, dateStartFilterFrom, dateStartFilterTo, tariffFilter, salesmanFilter, partnerFilter, regionFilter, contractVersionFilter, completeDepartmentFilter, completeSpecialistFilter, implementDepartmentFilter, implementSpecialistFilter, mmVolumeFilter, ppmNumberFilter, reserveAgreementFilter, validationStatusFilter, readCommentsFilter, statusFromFilter, statusToFilter, productFilter}}
                            */

            get: function get() {
                return this.queryParams;
            },
            configurable: true,
            enumerable: true
        }
    }));
});
define("ember-new/routes/individual-contract/show-gas/sells/add-renouncement", ["exports", "ember-new/mixins/routes/individual-contract/add-renouncement", "ember-new/routes/authenticated"], function (exports, _emberNewMixinsRoutesIndividualContractAddRenouncement, _emberNewRoutesAuthenticated) {
    exports["default"] = _emberNewRoutesAuthenticated["default"].extend(_emberNewMixinsRoutesIndividualContractAddRenouncement["default"], {
        model: function model() {
            return this.modelFor('individual-contract.show-gas');
        },

        setupController: function setupController(controller, model) {
            controller.set('contract', this.modelFor('individual-contract.show-gas'));
            this._super(controller, model);
        }
    });
});
define('ember-new/controllers/individual-contract-price-lists/template/add-from-code', ['exports', 'ember', 'ember-new/mixins/individual-contract-price-lists/controllers/template'], function (exports, _ember, _emberNewMixinsIndividualContractPriceListsControllersTemplate) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsIndividualContractPriceListsControllersTemplate['default'], {

        form: false,

        fillForm: function fillForm(form) {
            this.set('form', form);
            form.set('model.code', null);
            form.set('model.usysId', null);
        },

        afterCommand: function afterCommand(response) {
            this.get('form').set('formData.code', null);
            this.get('form').set('formData.usysId', null);
            var route = this.container.lookup('route:individual-contract-price-lists.template');
            route.refresh();
        }

    });
});
define("ember-new/components/d-form/individual-contract-price-lists/vaps/add", ["exports", "ember-new/components/d-form-command", "ember-new/templates/components/d-form"], function (exports, _emberNewComponentsDFormCommand, _emberNewTemplatesComponentsDForm) {
    exports["default"] = _emberNewComponentsDFormCommand["default"].extend({
        layout: _emberNewTemplatesComponentsDForm["default"],
        monthsValue: null,
        vapChanged: false,

        afterSuccessfulSubmit: function afterSuccessfulSubmit() {
            var targetController = this.get('targetObject');
            var mainRoute = targetController.container.lookup("route:individual-contract-price-lists.template.vaps");
            mainRoute.refresh();

            setTimeout(function () {
                targetController.transitionToRoute('individual-contract-price-lists.template.vaps');
            }, 100);
        },

        vapSelectObserver: (function () {

            if (this.get('formData.vapUuid')) {
                var formData = this.get('formData');
                var controller = this.get('targetObject');
                var vapList = controller.get('model.vaps');
                var vapUuid = this.get('formData.vapUuid');

                var singleVap = this.getVapByUuid(vapList, vapUuid);

                formData.set('type', singleVap.get('type'));
                formData.set('customPrice', singleVap.get('price'));
                formData.set('validityPeriod', singleVap.get('validityPeriod'));
                formData.set('numberOfMonths', singleVap.get('numberOfMonths'));
            }
        }).observes('formData.vapUuid'),

        getVapByUuid: function getVapByUuid(vapList, uuid) {

            var toReturn = null;
            var _this = this;

            vapList.forEach(function (vap) {
                if (vap.get('id') === uuid) {
                    toReturn = vap;
                    _this.set('vapChanged', true);
                    return true;
                }
            });

            return toReturn;
        },

        validityPeriodObserver: (function () {

            if ((this.get('monthsValue') === null || this.get('monthsValue') === undefined) && this.get('vapChanged') !== false) {
                this.set('monthsValue', this.get('formData.numberOfMonths'));
            }

            if (this.get('formRows.numberOfMonths')) {

                if (this.get('formData.validityPeriod') === 'entireContractPeriod' || this.get('formData.validityPeriod') === null) {
                    this.set('formRows.numberOfMonths.hidden', true);
                    this.set('formData.numberOfMonths', null);
                } else {
                    this.set('formRows.numberOfMonths.hidden', false);
                    this.set('formData.numberOfMonths', this.get('monthsValue'));
                }
            }
        }).observes('formData.validityPeriod')

    });
});
define('ember-new/components/div-filter-new', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'div',
        classNames: ['input-group input-group-sm'],

        yieldValue: null,
        currentValue: null,

        init: function init() {
            this._super.apply(this, arguments);
            this._onInit();
        },

        _parseObjectValuesToString: function _parseObjectValuesToString(objVals) {
            if (_ember['default'].isArray(objVals)) {
                return objVals.mapBy('value').join(',');
            } else if (objVals && objVals.value) {
                return objVals.value;
            }
        },
        _onInit: function _onInit() {
            this.get('_targetObject').registerFilterComponent(this);
            var _this = this;
            _this.set('yieldValue', _this.get('_targetObject').get(_this.get('field')));
            _this.set('currentValue', _this.get('_targetObject').get(_this.get('field')));
            //wymuszamy refresh stanow bo w momencie ladowania komponentow nie ma jeszcze odpalonych obserwatorow kontrolera
            this.get('_targetObject').addObserver(this.get('field'), function () {
                if (!(_this.get('isDestroyed') || _this.get('isDestroying')) && _this.get('yieldValue') && _this.get('field')) {
                    _this.set('yieldValue', _this.get('_targetObject').get(_this.get('field')));
                    _this.set('currentValue', _this.get('_targetObject').get(_this.get('field')));
                }
            });
        },

        showOkButton: _ember['default'].computed('yieldValue', 'currentValue', function () {
            var yieldValue = this.get('yieldValue'),
                currentValue = this.get('currentValue'),
                parsedYieldValue = this._parseObjectValuesToString(yieldValue);
            // jezeli wyczyscimy liste X obiekt nie jest nullem tylko pustym obiektem
            // wtedy pokazujemy przycisk zatwierdzenia (filtry sie zmienily ale są puste)
            if (typeof yieldValue === 'object' && yieldValue.length === 0 && parsedYieldValue !== currentValue) {
                return true;
            }
            return parsedYieldValue !== currentValue && parsedYieldValue || parsedYieldValue === null;
        }),

        showCancelButton: _ember['default'].computed('yieldValue', 'currentValue', function () {
            var parsedYieldValue = this._parseObjectValuesToString(this.get('yieldValue')),
                currentValue = this.get('currentValue');

            return parsedYieldValue && parsedYieldValue === currentValue || currentValue;
        }),

        actions: {
            applyFilter: function applyFilter() {
                var parsedYieldValue = this._parseObjectValuesToString(this.get('yieldValue'));
                this.set('currentValue', parsedYieldValue);
                this._targetObject.send('applyFilter', this.get('field'), parsedYieldValue);
            },
            cancelFilter: function cancelFilter() {
                this.set('currentValue', '');
                this.set('yieldValue', '');
                this._targetObject.send('applyFilter', this.get('field'), '');
            }
        }

    });
});
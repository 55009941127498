define('ember-new/models/base-price-set-list-dzu-view-model', ['exports', 'ember-new/models/abstract', 'ember-data'], function (exports, _emberNewModelsAbstract, _emberData) {
    exports['default'] = _emberNewModelsAbstract['default'].extend({
        type: _emberData['default'].attr('string'),
        dateFrom: _emberData['default'].attr('string'),
        name: _emberData['default'].attr('string'),
        productName: _emberData['default'].attr('string'),
        productUuid: _emberData['default'].attr('string'),
        paymentTerm: _emberData['default'].attr('number'),
        products: _emberData['default'].attr()
    });
});
define('ember-new/controllers/individual-contract/show-gas/prices', ['exports', 'ember-new/config/environment', 'ember', 'ember-new/mixins/individual-contract/show/prices'], function (exports, _emberNewConfigEnvironment, _ember, _emberNewMixinsIndividualContractShowPrices) {
    var computed = _ember['default'].computed;
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsIndividualContractShowPrices['default'], {
        ajax: service(),

        isAssigned: false,

        templateCode: (function () {
            var ceny = this.get('prices');
            var last = ceny.get('lastObject');
            if (last) {
                return last.get('templateCode');
            }
            return null;
        }).property('prices'),

        selectedItems: computed('vaps.@each.assignedToContract', function () {
            return this.get('vaps').filterBy('assignedToContract', true);
        }),
        selectedItemsUuids: computed('selectedItems', function () {
            return this.get('selectedItems').mapBy('uuid');
        }),

        isAssignedToContract: function isAssignedToContract(isAssigned) {
            var vap = this.get('vaps');
        },
        actions: {
            handleCheck: function handleCheck(item) {
                item.set('assignedToContract', !item.get('assignedToContract'));
            },
            handleToggleSelectAll: function handleToggleSelectAll(state) {
                this.get('model').forEach(function (item) {
                    return item.set('assignedToContract', state);
                });
            },
            submit: function submit() {
                var _this = this;
                var serializedData = {
                    AssignVapToContractCommand: {
                        contractUuid: _this.get('contract.uuid'),
                        vapsUuids: _this.get('selectedItemsUuids')
                    }
                };

                var promise = this.get('ajax').ajaxPost(_emberNewConfigEnvironment['default'].apiUrl + '/rest/assignvaptocontractcommands', serializedData);

                promise.then(function (response) {
                    _this.set('showLoading', true);
                    _this.get('applicationAlerts').addActionMessage('success', "Dane zostały zmienione");
                    setTimeout(function () {
                        _this.send('refreshModel');
                        _this.set('showLoading', false);
                    }, 500);
                }, function (response) {
                    _this.get('applicationAlerts').addActionMessage('warning', "Wystąpił błąd, nie udało się edytować umowy");
                });
            }
        },

        isAllowedToAssignVapy: (function () {
            var permission = this.get('TheUser').isMainRoleIn(['ROLE_GDPR_SPECIALIST']);
            var templateCode = this.get('templateCode');

            return !permission && templateCode;
        }).property(),

        isEditable: (function () {
            var isAnonymized = this.get('contract.isAnonymized');
            return !isAnonymized;
        }).property('contract')
    });
});
define('ember-new/components/d-field-select-uploaded-uuid', ['exports', 'ember', 'ember-new/components/d-field-select'], function (exports, _ember, _emberNewComponentsDFieldSelect) {
	exports['default'] = _emberNewComponentsDFieldSelect['default'].extend({

		optionLabelPath: "originalName",
		optionValuePath: "id",

		content: [],

		// requred options:
		dropzoneKey: null,

		initialize: (function () {
			var dropzoneKey = this.get('dropzoneKey');

			if (dropzoneKey === undefined || dropzoneKey === null || typeof dropzoneKey !== 'string') {
				throw new _ember['default'].Error('Component d-field-select-uploaded requires `dropzoneKey` option.');
			}

			//var content = [{label: 'll', value: 'vv'}];
			//this.set('content', content);
		}).on('init'),

		formBound: (function () {
			var key = this.get('key');
			if (this.get('form').get('fields')[this.get('dropzoneKey')]) {
				var ufk = this.get('form').get('fields')[this.get('dropzoneKey')].get('uploadedFieldKeys');
				if (!ufk.contains(key)) {
					ufk.pushObject(key);
				}
			}
		}).observes('form'),

		updateSelect2Value: (function () {

			if (true === this.get('_preventValueObserver')) {
				return;
			}

			var value = this.get('value');
			if (value instanceof DS.PromiseObject) {
				this.set('value', value.get('content'));
			}
		}).observes('value', '_select2Inited')
	});
});
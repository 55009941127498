define('ember-new/models/analysis-view-models/contract-analysis-view-model', ['exports', 'ember-new/models/abstract', 'ember-data/attr'], function (exports, _emberNewModelsAbstract, _emberDataAttr) {
    exports['default'] = _emberNewModelsAbstract['default'].extend({
        alerts: (0, _emberDataAttr['default'])(),
        contractNumber: (0, _emberDataAttr['default'])('string'),
        contractStatus: (0, _emberDataAttr['default'])('string'),
        dateSigned: (0, _emberDataAttr['default'])('string'),
        isOkToAnonymize: (0, _emberDataAttr['default'])('boolean'),
        isInDualFuel: (0, _emberDataAttr['default'])('string'),
        type: (0, _emberDataAttr['default'])('string'),
        variant: (0, _emberDataAttr['default'])('string'),
        category: (0, _emberDataAttr['default'])('string'),
        signingPlace: (0, _emberDataAttr['default'])('string'),
        product: (0, _emberDataAttr['default'])('string'),
        dateReceived: (0, _emberDataAttr['default'])('string'),
        dateStart: (0, _emberDataAttr['default'])('string'),
        dateEnd: (0, _emberDataAttr['default'])('string'),
        numberOfMonths: (0, _emberDataAttr['default'])('string'),
        paymentTerm: (0, _emberDataAttr['default'])('string'),
        tariff: (0, _emberDataAttr['default'])('string'),
        basePricesList: (0, _emberDataAttr['default'])('string'),
        ppms: (0, _emberDataAttr['default'])('')
    });
});
define('ember-new/models/gas-tariff-group-duon-dictionary-entry', ['exports', 'ember-new/models/abstract'], function (exports, _emberNewModelsAbstract) {

    var GasTariffGroupDuonDictionaryEntry = _emberNewModelsAbstract['default'].extend({
        label: DS.attr('string'),
        name: DS.attr('string'),
        isHidden: DS.attr('boolean'),
        uuid: DS.attr('string')
    });

    exports['default'] = GasTariffGroupDuonDictionaryEntry;
});
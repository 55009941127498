define('ember-new/controllers/reports/volume-by-region', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend(Object.defineProperties({

        activatedFilters: [],

        queryParams: {
            businessMonth: 'businessMonth',
            aboveMargin: 'aboveMargin',
            salesFilter: 'salesFilter',
            meetingSource: 'meetingSource',
            contractType: 'contractType'
        },

        selectedBusinessMonth: null,
        businessMonth: null,
        aboveMargin: null,
        salesFilter: null,
        meetingSource: null,
        contractType: null,

        filtersLabels: {
            businessMonth: 'Miesiąc akceptacji umowy',
            salesFilter: 'Sprzedaż',
            aboveMargin: 'Masa marży',
            meetingSource: 'Źródło',
            contractType: 'Produkt'
        },

        contractTypes: [{ label: 'Prąd', value: 'ee' }, { label: 'Gaz', value: 'gas' }],

        salesFilters: [{ label: 'Bez obecnego miesiąca sprzedażowego', value: 'without_current_sales_month' }],

        aboveMargins: [{ label: ">100K", value: 100000 }, { label: ">50k", value: 50000 }, { label: ">20k", value: 20000 }, { label: ">10k", value: 10000 }, { label: ">2k", value: 2000 }],

        setActivatedFilters: function setActivatedFilters() {
            var _this = this;

            var filtersLabels = this.get('filtersLabels'),
                filtersQueryParams = this.queryParamsVars;

            var activatedFilters = [];

            Object.keys(filtersQueryParams).map(function (filterName) {
                var filterValue = _this.get(filterName),
                    filterKey = filtersQueryParams[filterName];

                if (filterValue !== null && filterValue !== undefined && filterValue !== '') {
                    var currentFilter = {
                        label: filtersLabels[filterKey],
                        value: filterValue,
                        isBoolean: filterValue === 'true' || filterValue === 'false'
                    };
                    activatedFilters.push(currentFilter);
                }
            });
            this.set('activatedFilters', activatedFilters);
        },

        actions: {
            applyFilters: function applyFilters() {
                var _this2 = this;

                var filters = this.queryParamsVars;

                Object.keys(filters).map(function (filterParamKey) {
                    if (_this2.get(filters[filterParamKey]) === '') {
                        _this2.set(filterParamKey, null);
                    } else {
                        _this2.set(filterParamKey, _this2.get(filters[filterParamKey]));
                    }
                });
                var selectedUuid = this.get('businessMonth'),
                    selectedBusinessMonth = this.get('businessMonths').find(function (e) {
                    return e.get('uuid') === selectedUuid;
                });

                this.set('selectedBusinessMonth', selectedBusinessMonth);
                this.setActivatedFilters();
                this.send('refreshModel');
            },
            resetFilters: function resetFilters() {
                var _this3 = this;

                var filters = this.queryParamsVars;

                Object.keys(filters).map(function (filterParamKey) {
                    _this3.set(filterParamKey, null);
                });

                this.send('refreshModel');
            }
        }
    }, {
        queryParamsVars: {
            get: function get() {
                return this.queryParams;
            },
            configurable: true,
            enumerable: true
        }
    }));
});
define('ember-new/components/fab-menu-item', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'a',
        classNames: 'menu-item'.w(),
        title: null,
        badge: null, // additional content
        attributeBindings: ["titleAndBadge:data-title"],
        icon: null,

        click: function click() {
            this.sendAction('action', this.get('param'));
        },

        titleAndBadge: (function () {
            if (!this.get('badge')) return this.get('title');

            return this.get('title') + ' (' + this.get('badge') + ')';
        }).property('title', 'badge'),

        actions: {}

    });
});
define('ember-new/serializers/renouncement', ['exports', 'ember-new/serializers/application', 'ember-new/mixins/base-document-serializer'], function (exports, _emberNewSerializersApplication, _emberNewMixinsBaseDocumentSerializer) {
    exports['default'] = _emberNewSerializersApplication['default'].extend(_emberNewMixinsBaseDocumentSerializer['default'], {
        RenouncementSerializerInit: (function () {
            this.addAttrsConfig({
                scannedDocuments: { serialize: 'id', deserialize: 'records' },
                scannedDocuments2: { serialize: 'id', deserialize: 'records' },
                scannedDocuments3: { serialize: 'id', deserialize: 'records' },
                scannedDocuments4: { serialize: 'id', deserialize: 'records' },
                scannedDocuments5: { serialize: 'id', deserialize: 'records' },
                eeContract: { serialize: 'id', deserialize: 'records' },
                gasContract: { serialize: 'id', deserialize: 'records' },
                clientInfo: { serialize: 'records', deserialize: 'records', noKey: true },
                ppmAddress: { serialize: 'records', deserialize: 'records', noKey: true },
                renouncementStatus: { serialize: 'id', deserialize: 'id' }
            });
        }).on('init')
    });
});
define('ember-new/controllers/b2b-contract/administration/gas-business-tariff/delete', ['exports', 'ember', 'ember-new/config/environment'], function (exports, _ember, _emberNewConfigEnvironment) {
    exports['default'] = _ember['default'].Controller.extend({
        ajax: _ember['default'].inject.service('ajax'),
        applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),
        isSubmitting: false,

        actions: {
            'delete': function _delete() {
                var _this = this;

                this.set('isSubmitting', true);
                var serializedData = {
                    DeleteGasBusinessTariffCommand: {
                        uuid: this.get('uuid')
                    }
                };
                this.get('ajax').ajaxPost(_emberNewConfigEnvironment['default'].apiUrl + '/rest/deletegasbusinesstariffcommands', serializedData).then(function () {
                    var route = _this.container.lookup('route:b2b-contract.administration.gas-business-tariff.list');
                    route.refresh();
                    _this.get('applicationAlerts').addActionMessage('success', "Taryfa biznesowa GAZ została pomyślnie usunięta");
                    _this.transitionToRoute('b2b-contract.administration.gas-business-tariff.list');
                }, function () {
                    _this.set('isSubmitting', false);
                    _this.get('applicationAlerts').addActionMessage('warning', "Wystąpił błąd, nie udało się usunąć taryfy biznesowej GAZ");
                });
            }
        }
    });
});
define('ember-new/utils/ajax/get', ['exports', 'ember', 'ember-new/utils/ajax/error-handlers'], function (exports, _ember, _emberNewUtilsAjaxErrorHandlers) {

  var ajaxGet = function ajaxGet(url, token) {
    var user = arguments.length <= 2 || arguments[2] === undefined ? null : arguments[2];

    var getPromise = _ember['default'].$.ajax({
      url: url,
      type: 'GET',
      dataType: 'json',
      headers: {
        "Authorization": "Bearer " + token
      },
      beforeSend: function beforeSend(xhr) {
        if (user) {
          xhr.setRequestHeader('X-Switch-User', user);
        }
      }
    });
    var afterGetPromise = new _ember['default'].RSVP.Promise(function (resolve, reject) {

      getPromise.then(function (response) {
        // returns server response JSON as a result of the promise
        resolve(response);
      }, function (jqXHR) {

        console.log('xhr', jqXHR);

        if (jqXHR.status == 500) {
          _emberNewUtilsAjaxErrorHandlers['default'].handleBackendException(jqXHR);
          return;
        } else if (jqXHR.status == 403) {
          _emberNewUtilsAjaxErrorHandlers['default'].handleBackend403(jqXHR, reject);
        } else if (jqXHR.status == 400) {
          // do nothing validation will be resolved later in .then() of promise
          reject(jqXHR.responseJSON); // returns JSON with validation errors as a result of the promise
        } else {
            _emberNewUtilsAjaxErrorHandlers['default'].handleUnknownAjaxError(jqXHR);
          }
      });
    }, getPromise._label + " postAjaxGet");

    return afterGetPromise;
  };

  exports['default'] = ajaxGet;
});
/**
 * @deprecated
 * Nie używaj tego. Użyj services/ajax
 * Zostawione for backward compatibility
 *  ~KJ
 */
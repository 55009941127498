define('ember-new/mixins/b2b-contract/controllers/reload-contract-header', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Mixin.create({

        reloadContractHeader: function reloadContractHeader() {
            var path = this.get('container').lookup('controller:application').currentPath;

            if (path.match(/^(b2b-contract.dzu.show-ee)/i)) {
                var routeHeader = this.container.lookup("route:b2b-contract.dzu.show-ee");
                routeHeader.refresh();
                var routeStatus = this.container.lookup("route:b2b-contract.dzu.show-ee.contract-status");
                routeStatus.refresh();
            } else if (path.match(/^(b2b-contract.dzu.show-gas)/i)) {
                var routeHeader = this.container.lookup("route:b2b-contract.dzu.show-gas");
                routeHeader.refresh();
                var routeStatus = this.container.lookup("route:b2b-contract.dzu.show-gas.contract-status");
                routeStatus.refresh();
            } else if (path.match(/^(b2b-contract.salesman.show-ee)/i)) {
                var routeHeader = this.container.lookup("route:b2b-contract.salesman.show-ee");
                routeHeader.refresh();
                var routeStatus = this.container.lookup("route:b2b-contract.salesman.show-ee.contract-status");
                routeStatus.refresh();
            } else if (path.match(/^(b2b-contract.salesman.show-gas)/i)) {
                var routeHeader = this.container.lookup("route:b2b-contract.salesman.show-gas");
                routeHeader.refresh();
                var routeStatus = this.container.lookup("route:b2b-contract.salesman.show-gas.contract-status");
                routeStatus.refresh();
            }
        }

    });
});
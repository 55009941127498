define('ember-new/controllers/b2b-contract/dzu/show-gas/ppg/details/invoices', ['exports', 'ember-new/config/environment', 'ember', 'ember-new/utils/ajax/post'], function (exports, _emberNewConfigEnvironment, _ember, _emberNewUtilsAjaxPost) {
    exports['default'] = _ember['default'].Controller.extend({
        ajax: _ember['default'].inject.service('ajax'),

        isDeleting: false,

        actions: {
            submitForm: function submitForm() {
                this.get('form').send('submit');
            },
            deleteInvoice: function deleteInvoice(invoice, ppg) {

                var _this = this;
                this.set('isDeleting', true);

                var payload = {};
                payload['DeleteInvoiceDzuCommand'] = {};
                payload['DeleteInvoiceDzuCommand']['ppeId'] = ppg;
                payload['DeleteInvoiceDzuCommand']['invoiceId'] = invoice;

                var ajaxPromise = this.get('ajax').ajaxPost(_emberNewConfigEnvironment['default'].apiUrl + '/rest/deleteinvoicedzucommands', payload);

                ajaxPromise.then(function (response) {
                    setTimeout(function () {
                        var route = _this.container.lookup("route:b2b-contract.dzu.show-gas.ppg");
                        route.refresh();
                        _this.send('refreshModel');
                    }, 200);
                }, function (response) {});
            }
        },

        fillForm: function fillForm(form) {
            this.set('form', form); // uzywane w submitForm()...
            form.set('model.ppgId', this.get('ppgId'));
            form.set('model.consumption', null);
            form.set('model.dateFrom', null);
            form.set('model.dateTo', null);
        },

        onInit: (function () {
            var invoicesQty = this.get('invoicesQty');
            this.set('isInvoicesExists', invoicesQty > 0);
        }).on('init')

    });
});
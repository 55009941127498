define('ember-new/models/ee-business-contract-product-view-model', ['exports', 'ember-new/models/abstract', 'ember-data'], function (exports, _emberNewModelsAbstract, _emberData) {

    var attr = _emberData['default'].attr;
    var refOne = _emberData['default'].belongsTo;

    exports['default'] = _emberNewModelsAbstract['default'].extend({
        name: attr('string'),
        periodType: attr('string'),
        isHidden: attr('boolean'),
        mongoid: attr('string'),
        isIndex: attr('boolean')
    });
});
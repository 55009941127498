define('ember-new/mixins/controllers/individual-contract/add', ['exports', 'ember', 'ember-new/config/environment'], function (exports, _ember, _emberNewConfigEnvironment) {
    exports['default'] = _ember['default'].Mixin.create({

        ajax: _ember['default'].inject.service('ajax'),

        redirectBaseRoute: 'add',

        isSubmitting: false,

        actions: {
            openSingleEE: function openSingleEE() {
                this.checkPesel('individual-contract.' + this.get('redirectBaseRoute') + '-ee');
            },
            openSingleGas: function openSingleGas() {
                this.checkPesel('individual-contract.' + this.get('redirectBaseRoute') + '-gas');
            },
            openDualFuel: function openDualFuel() {
                this.checkPesel('individual-contract.' + this.get('redirectBaseRoute') + '-df');
            }
        },

        checkPesel: function checkPesel(route) {
            this.set('isSubmitting', true);
            this.set('errors', {});
            var _this = this;
            var data = { form: { pesel: this.get('pesel'), foreigner: this.get('foreigner') } };
            var promise = this.get('ajax').ajaxPost(_emberNewConfigEnvironment['default'].apiUrl + '/api/individual-client/find_client_by_pesel', data);

            promise.then(function (response) {
                _this.set('isSubmitting', false);
                var userResponse = true;
                if (!_this.get('foreigner') && _this.getAgeWarning(_this.get('pesel'))) {
                    userResponse = confirm('Klient ma ponad 70 lat, czy na pewno chcesz dodać ten Pesel?');
                }
                if (userResponse) {
                    _this.transitionToRoute(route, _this.get('pesel') ? _this.get('pesel') : 'null');
                }
            }, function (response) {
                _this.set('isSubmitting', false);
                _this.set('errors', { type: 'error', message: response.responseJSON.errors.children.pesel.errors[0] });
            });
        },

        getAgeFromPesel: function getAgeFromPesel(pesel) {
            var age = parseInt(pesel.substr(0, 2));
            var third = pesel.substr(2, 1);

            if (third == '8' || third == '9') {
                age += 1800;
            } else if (third == '2' || third == '3') {
                age += 2000;
            } else {
                age += 1900;
            }

            var curYear = new Date().getFullYear();

            return parseInt(curYear) - age;
        },

        getAgeWarning: function getAgeWarning(pesel) {
            return this.getAgeFromPesel(pesel) >= 70 ? true : false;
        }
    });
});
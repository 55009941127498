define('ember-new/controllers/dashboard-post', ['exports', 'ember'], function (exports, _ember) {

    var DashboardPostController = _ember['default'].ObjectController.extend({

        canPublish: (function () {
            return this.get('target').get('canPublish');
        }).property(),

        canEdit: (function () {
            return this.get('target').get('canPublish');
        }).property(),

        mayEdit: (function () {
            // alias
            return this.get('canEdit');
        }).property(),

        showFullName: (function () {
            var sales = ['ROLE_SALES_SALESMAN', 'ROLE_SALES_MANAGER', 'ROLE_SALES_HEADMANAGER'];
            if (EmberNew.TheUser.isMainRoleIn(sales)) {

                var author = this.get('model.model.createdBy');
                if (EmberNew.TheUser.userRoleIn(author, sales)) {
                    var authorRegions = author.get('regions');
                    var userRegions = EmberNew.TheUser.model.get('regions');

                    return userRegions.any(function (item) {

                        if (authorRegions.any(function (item2) {
                            return item == item2.get('id');
                        })) {
                            return true;
                        } else {
                            return false;
                        }
                    });

                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        }).property(),

        contentFull: (function () {
            var content = this.get('model.model.contentHtml') ? this.get('model.model.contentHtml') : ' ';

            if (this.get('model.model.title') && this.get('model.model.title').length) {
                content = this.get('model.model.title') + ' ' + content;
            }

            return content;
        }).property('model.model.title', 'model.model.contentHtml'),
        contentCut: (function () {
            return this.get('contentFull').substring(0, 200) + '...';
        }).property('contentFull'),
        isContentTooLong: (function () {

            return this.get('contentFull').length > 200;
        }).property('contentFull'),
        isMoreShowed: false,

        actions: {
            toggleMore: function toggleMore() {
                this.set('isMoreShowed', !this.get('isMoreShowed'));
            },
            // removeAction: function() {
            //     var model = this.get('model.model');
            //     var _this = this;
            //     model.destroyRecord().then(function(){
            //         _this.send('refreshModel');
            //     });
            //
            // },

            like: function like() {
                this.get('model.model').customUpdate('like', [], null, null).then(function (response) {}, function (response) {});
            },

            publish: function publish() {
                var _this = this;
                this.get('model.model').customUpdate('publish', [], null, null).then(function (response) {
                    _this.store.pushPayload(response);
                }, function (response) {});
            }
        }

    });

    exports['default'] = DashboardPostController;
});
define('ember-new/models/business-ppe-info', ['exports', 'ember-new/models/abstract'], function (exports, _emberNewModelsAbstract) {

    var string = DS.attr('string');

    exports['default'] = _emberNewModelsAbstract['default'].extend({
        pointNumber: string,
        mm: string,
        mmFromPrices: string,
        mmFromVas: string,
        volume: string,
        consumption: string,
        overhead: string,
        monthlyInfo: DS.hasMany('BusinessPpeMonthlyInfo', { embedded: true })
    });
});
define('ember-new/routes/individual-contract-partners', ['exports', 'ember', 'ember-new/routes/authenticated'], function (exports, _ember, _emberNewRoutesAuthenticated) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({

        beforeModel: function beforeModel(transition) {
            window.scrollTo(0, 0); // scroll to the top of application before reloading
            this.set('filters', transition.queryParams);
        },

        model: function model(params) {
            this.store.unloadAll('IndividualContractPartnerListItemViewModel');
            return this.store.query('IndividualContractPartnerListItemViewModel', params).then(function (result) {
                return {
                    list: result,
                    meta: result.get('meta')
                };
            })['catch'](function () {
                return console.error('route model : error msg');
            });
        },

        setupController: function setupController(ctrl, _ref) {
            var list = _ref.list;
            var meta = _ref.meta;

            ctrl.set('filters', this.get('filters'));
            ctrl.set('model', list);
            ctrl.set('meta', meta);
        },

        actions: {
            queryParamsDidChange: function queryParamsDidChange() {
                this.refresh();
            },
            refresh: function refresh() {
                this.refresh();
            }

        }

    });
});
define('ember-new/mixins/serializers/individual-business-rate', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Mixin.create({
        IndividualBusinessRateSerializerInit: (function () {
            this.addAttrsConfig({
                variant: { serialize: 'id', deserialize: 'id' },
                product: { serialize: 'id', deserialize: 'id' },
                tariff: { serialize: 'id', deserialize: 'id' }
            });
        }).on('init')
    });
});
define('ember-new/mixins/individual-contract/show/documents', ['exports', 'ember', 'ember-new/config/environment', 'ember-new/mixins/controllers/unsaved-data'], function (exports, _ember, _emberNewConfigEnvironment, _emberNewMixinsControllersUnsavedData) {
    exports['default'] = _ember['default'].Mixin.create(_emberNewMixinsControllersUnsavedData['default'], {

        name: null,
        activeMenuItem: (function () {
            return this.get('name') + '.show.documents';
        }).property('name'),

        fillForm: function fillForm(form) {
            form.set('model.contractId', this.get('model.uuid'));
            form.set('model.recordingUuid', this.get('model.vcRecording'));
        },

        isEditable: (function () {
            var editSells = this.get('model.credentials.editSells');
            var isAnonymized = this.get('model.isAnonymized');
            return editSells && !isAnonymized;
        }).property()

    });
});
define("ember-new/components/d-form/b2b-contract/dzu/administration/base-price-lists/copy", ["exports", "ember-new/components/d-form-command", "ember-new/templates/components/d-form"], function (exports, _emberNewComponentsDFormCommand, _emberNewTemplatesComponentsDForm) {
    exports["default"] = _emberNewComponentsDFormCommand["default"].extend({
        layout: _emberNewTemplatesComponentsDForm["default"],

        commandName: 'CopyBasePricelistCommand',

        changeNameObserver: (function () {
            var targetController = this.get('targetObject');
            if (this.get('formData')) {
                this.set('formData.priceListName', targetController.get('productName') + ' | ' + this.get('formData.dateStart'));
            }
        }).observes('formData.dateStart'),

        afterSuccessfulSubmit: function afterSuccessfulSubmit() {
            var targetController = this.get('targetObject');
            var mainController = targetController.container.lookup('controller:b2b-contract.administration.section-pricelists.list');
            var mainRoute = mainController.get('target');
            mainRoute.send('refreshModel');
            setTimeout(function () {
                targetController.transitionToRoute('b2b-contract.administration.section-pricelists.list');
            }, 500);
        }
    });
});
define('ember-new/models/anonymization-registry-entry-view-model', ['exports', 'ember-data'], function (exports, _emberData) {

    var attr = _emberData['default'].attr;

    exports['default'] = _emberData['default'].Model.extend({
        uuid: attr('string'),
        date: attr('string'),
        author: attr('string'),
        authorUuid: attr('string'),
        entryType: attr('string'),
        documentsUuid: attr(),
        documentsType: attr(),
        documentsDescription: attr(),
        documentsAction: attr()
    });
});
define('ember-new/models/business-gas-contract-salesman-mm-info', ['exports', 'ember-new/models/abstract', 'ember-data'], function (exports, _emberNewModelsAbstract, _emberData) {

    var string = _emberData['default'].attr('string');

    exports['default'] = _emberNewModelsAbstract['default'].extend({
        consumptionByDzu: string,
        consumptionBySalesman: string,
        mmByDzu: string,
        mmBySalesman: string,
        overheadByDzu: string,
        overheadBySalesman: string,
        volumeByDzu: string,
        volumeBySalesman: string,
        numberOfMonthsBySalesman: string,
        ppgInfo: _emberData['default'].hasMany('BusinessPpgInfo', { embedded: true })
    });
});
define('ember-new/controllers/individual-contract/show-ee/sells/search-renouncement', ['exports', 'ember-new/config/environment', 'ember', 'ember-new/mixins/individual-contract/show/sells/search-renouncement'], function (exports, _emberNewConfigEnvironment, _ember, _emberNewMixinsIndividualContractShowSellsSearchRenouncement) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsIndividualContractShowSellsSearchRenouncement['default'], {

        ajax: _ember['default'].inject.service('ajax'),

        isLoaded: false,

        loadRenouncements: (function () {
            this.set('isLoaded', false);
            var _this = this;
            var store = this.get('store');

            store.unloadAll('renouncement');

            var promise = this.get('ajax').ajaxGet(_emberNewConfigEnvironment['default'].apiUrl + '/api/individual-contract/search_renouncements/' + _this.get('contract.id'));

            promise.then(function (response) {
                delete response.eeIds;
                delete response.gasIds;
                store.pushPayload(response);
                _this.set('isLoaded', true);
            });

            this.set('ren', this.store.peekAll('renouncement'));
        }).observes('contract'),

        renouncementDetailsRoute: 'individual-contract.show-ee.sells.search-renouncement.details',

        actions: {
            chooseRenouncement: function chooseRenouncement(renouncement) {
                var _this = this;

                var serializedData = {
                    id: renouncement.get('id')
                };

                var promise = this.get('ajax').ajaxPost(_emberNewConfigEnvironment['default'].apiUrl + '/api/individual-contract/assign_renouncement/' + _this.get('contract.id'), serializedData);

                promise.then(function (response) {
                    var renouncementStatement = response.renouncementStatement;
                    _this.get('store').pushPayload(response);

                    if (renouncementStatement && renouncementStatement.isVisible === true) {
                        alert(renouncementStatement.message);
                    }

                    _this.transitionToRoute('individual-contract.show-ee.sells');
                }, function (response) {});
            }
        }

    });
});
define("ember-new/routes/b2b-contract/administration/section-products/list/delete-product", ["exports", "ember-new/routes/authenticated"], function (exports, _emberNewRoutesAuthenticated) {
    "use strict";
    exports["default"] = _emberNewRoutesAuthenticated["default"].extend({

        model: function model(params) {
            var products = this.modelFor('b2b-contract.administration.section-products.list');
            var singleProduct = products.productsList.filter(function (item) {
                return item.get('id') === params.productUuid;
            });

            return singleProduct[0];
        }
    });
});
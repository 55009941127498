define('ember-new/components/d-field-select-user', ['exports', 'ember', 'ember-new/components/d-field-select'], function (exports, _ember, _emberNewComponentsDFieldSelect) {
  exports['default'] = _emberNewComponentsDFieldSelect['default'].extend({

    layoutName: 'd-field-select',

    dictionariesManager: _ember['default'].inject.service('dictionaries-manager'),
    optionLabelPath: "fullNameWithRole",
    optionValuePath: "id",

    // requred options:
    userDictionaryName: null,

    initialize: (function () {
      var userDictionaryName = this.get('userDictionaryName');

      if (userDictionaryName === undefined || userDictionaryName === null || typeof userDictionaryName !== 'string') {
        throw new _ember['default'].Error('Component d-field-select-user requires `userDictionaryName` option.');
      }

      var dicManager = this.get('dictionariesManager');

      if (!dicManager.hasUsersDictionary(userDictionaryName)) {
        throw new _ember['default'].Error("Option 'userDictionaryName` passed to d-field-select-user component is wrong. There is no `" + userDictionaryName + "` user-dictionary.");
      }

      var users = dicManager.getUsersDictionary(userDictionaryName);
      this.set('content', users);
    }).on('init')
  });
});
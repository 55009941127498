define("ember-new/components/d-form/b2b-contract/salesman/add-gas-contract-comment", ["exports", "ember-new/components/d-form-command", "ember-new/templates/components/d-form-b2b-contract-add-comment"], function (exports, _emberNewComponentsDFormCommand, _emberNewTemplatesComponentsDFormB2bContractAddComment) {
    exports["default"] = _emberNewComponentsDFormCommand["default"].extend({
        layout: _emberNewTemplatesComponentsDFormB2bContractAddComment["default"],

        commandName: 'AddContractCommentSalesmanCommand',

        afterSuccessfulSubmit: function afterSuccessfulSubmit() {
            var targetController = this.get('targetObject');
            var mainController = targetController.container.lookup('controller:b2b-contract.salesman.show-gas.comments');
            var mainRoute = mainController.get('target');
            mainRoute.send('refreshModel');
            setTimeout(function () {
                targetController.transitionToRoute('b2b-contract.salesman.show-gas.comments');
            }, 500);
        }
    });
});
define('ember-new/models/contract-documents-dzu-view-model', ['exports', 'ember-new/models/abstract', 'ember-data'], function (exports, _emberNewModelsAbstract, _emberData) {

    var attr = _emberData['default'].attr;
    var refOne = _emberData['default'].belongsTo;

    exports['default'] = _emberNewModelsAbstract['default'].extend({

        atLeastOneDocument: attr('boolean'),

        contractUuid: attr('string'),

        contractFileUuid: attr('string'),
        contractFUuid: attr('string'),
        authorizationFileUuid: attr('string'),
        ppmsFileUuid: attr('string'),
        saleTermsFileUuid: attr('string'),

        osdInvoiceFileUuid: attr('string'),
        salesInvoiceFileUuid: attr('string'),
        presentContractFileUuid: attr('string'),
        newDeviceDocumentsFileUuid: attr('string'),

        ceidgFileUuid: attr('string'),
        nipFileUuid: attr('string'),
        regonFileUuid: attr('string'),
        krsFileUuid: attr('string'),
        companyContractFileUuid: attr('string'),
        legalTitleToPremisesFileUuid: attr('string'),
        otherRegisterDocumentsFileUuid: attr('string'),
        additionalAuthorizationsFileUuid: attr('string'),
        statementFileUuid: attr('string'),
        dataUpdateFileUuid: attr('string'),
        cessionFileUuid: attr('string'),
        changeTariffRequestFileUuid: attr('string'),
        otherDocumentsFileUuid: attr('string'),

        linkTermsUuid: attr('string'),
        linkContractUuid: attr('string'),
        declaredConsumptionUuid: attr('string'),
        changePowerRequestUuid: attr('string'),

        contractFile: refOne('Upload', { inverse: null }),
        authorizationFile: refOne('Upload', { inverse: null }),
        ppmsFile: refOne('Upload', { inverse: null }),
        saleTermsFile: refOne('Upload', { inverse: null }),

        osdInvoiceFile: refOne('Upload', { inverse: null }),
        salesInvoiceFile: refOne('Upload', { inverse: null }),
        presentContractFile: refOne('Upload', { inverse: null }),
        newDeviceDocumentsFile: refOne('Upload', { inverse: null }),

        ceidgFile: refOne('Upload', { inverse: null }),
        nipFile: refOne('Upload', { inverse: null }),
        regonFile: refOne('Upload', { inverse: null }),
        krsFile: refOne('Upload', { inverse: null }),
        companyContractFile: refOne('Upload', { inverse: null }),
        legalTitleToPremisesFile: refOne('Upload', { inverse: null }),
        otherRegisterDocumentsFile: refOne('Upload', { inverse: null }),
        additionalAuthorizationsFile: refOne('Upload', { inverse: null }),
        statementFile: refOne('Upload', { inverse: null }),
        dataUpdateFile: refOne('Upload', { inverse: null }),
        cessionFile: refOne('Upload', { inverse: null }),
        changeTariffRequestFile: refOne('Upload', { inverse: null }),
        otherDocumentsFile: refOne('Upload', { inverse: null }),

        linkTermsFile: refOne('Upload', { inverse: null }),
        linkContractFile: refOne('Upload', { inverse: null }),
        declaredConsumptionFile: refOne('Upload', { inverse: null }),
        changePowerRequestFile: refOne('Upload', { inverse: null }),

        egAgreementDocumentFileUuid: attr('string'),
        egAgreementFileUuid: attr('string'),

        egAgreementDocumentFile: refOne('Upload', { inverse: null }),
        egAgreementFile: refOne('Upload', { inverse: null }),

        phoneRecordingsFiles: _emberData['default'].hasMany('Upload', { inverse: null }),
        phoneRecordingsFilesUuids: attr('array')

    });
});
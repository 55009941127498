define('ember-new/models/edit-price-condition-params-view-model', ['exports', 'ember-new/models/abstract', 'ember-data'], function (exports, _emberNewModelsAbstract, _emberData) {

    var attr = _emberData['default'].attr;
    var refOne = _emberData['default'].belongsTo;

    exports['default'] = _emberNewModelsAbstract['default'].extend({
        indexCapLevel: _emberData['default'].attr('string'),
        indexProfileFactor: _emberData['default'].attr('string'),
        indexExpectedPrice: _emberData['default'].attr('string'),
        subscriptionType: _emberData['default'].attr('string'),
        subscriptionTypeUuid: _emberData['default'].attr('string'),
        subscriptionValue: _emberData['default'].attr('string'),
        orderInvoiceNumber: _emberData['default'].attr('string'),
        contractedPower: _emberData['default'].attr('string'),
        powerMultiplier: _emberData['default'].attr('string'),
        discountType: _emberData['default'].attr('string'),
        discountDivision: _emberData['default'].attr('string'),
        discountBoundry: _emberData['default'].attr('string'),
        whiteRedeemption: _emberData['default'].attr('boolean'),
        whiteCalculationMethod: _emberData['default'].attr('string'),
        whiteInPriceCost: _emberData['default'].attr('string'),
        whiteActualCost: _emberData['default'].attr('string'),
        productType: _emberData['default'].attr('string'),
        indexExpectedPriceVisible: _emberData['default'].attr('boolean')
    });
});
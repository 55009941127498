define('ember-new/mixins/b2b-contract/controllers/gas-business-tariff/edit-controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Mixin.create({

        excises: [{ label: 'Bez akcyzy', value: '0' }, { label: 'Opałowy', value: '1' }, { label: 'Silniki spalinowe', value: '2' }],

        initializeGasProducts: function initializeGasProducts(gasProducts) {
            var ctrl = this;

            gasProducts = gasProducts.filter(function (item) {
                return item.get('isHidden') !== true || item.get('id') === ctrl.get('model').get('productUuid');
            });

            this.set('gasProducts', gasProducts);
        }

    });
});
define('ember-new/mixins/user-extensions', ['exports', 'ember'], function (exports, _ember) {

	var UserExtensionsMixin = _ember['default'].Mixin.create({

		isDzuUser: (function () {
			return EmberNew.TheUser.isMainRoleIn(['ROLE_DZU2_SPECIALIST', 'ROLE_DZU2']);
		}).property(),

		isDisabledForDzuSpecialist: (function () {
			return !EmberNew.TheUser.isMainRoleIn(['ROLE_DZU_MANAGER', 'ROLE_DZU2_MANAGER', 'ROLE_ADMIN']);
		}).property(),

		isNotDzuManagerDEDGUSer: (function () {
			return !EmberNew.TheUser.isMainRoleIn(['ROLE_DZU_MANAGER', 'ROLE_DZU2_MANAGER', 'ROLE_DE', 'ROLE_DG', 'ROLE_ADMIN']);
		}).property()
	});

	exports['default'] = UserExtensionsMixin;
});
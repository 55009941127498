define('ember-new/mixins/b2b-contract/components/d-form/section-products/add-to-condition', ['exports', 'ember', 'ember-new/templates/components/d-form'], function (exports, _ember, _emberNewTemplatesComponentsDForm) {
    exports['default'] = _ember['default'].Mixin.create({
        layout: _emberNewTemplatesComponentsDForm['default'],
        contractPrice: 'contract_price',

        indexObserver: (function () {
            if (this.get('formData') && this.get('formRows.productModel')) {
                if (this.get('formData.isIndex')) {
                    this.set('formRows.productModel.hidden', false);
                } else {
                    this.set('formRows.productModel.hidden', true);
                    this.set('formData.productModel', null);
                }
            }
        }).observes('formData.isIndex'),

        basePricesObserver: (function () {
            if (this.get('formData') && this.get('formRows.isAutomaticBasePrice')) {
                if (this.get('formData.isProductBasePrice')) {
                    this.set('formData.isAutomaticBasePrice', true);
                    this.get('formRows.isAutomaticBasePrice.field').disableStateAndSwitchChange(false);
                } else {
                    this.set('formData.isAutomaticBasePrice', false);
                    this.get('formRows.isAutomaticBasePrice.field').disableStateAndSwitchChange(true);
                }
            }
        }).observes('formData.isProductBasePrice'),

        mediumObserver: (function () {
            if (this.get('formData') && this.get('formRows.medium')) {
                var medium = this.get('formData.medium');
                var controller = this.get('targetObject');

                switch (medium) {
                    case 'ee':
                        controller.set('valueType', controller.get('valueTypeEe'));
                        this.set('formData.valueType', null);

                        break;
                    case 'gas':
                        controller.set('valueType', controller.get('valueTypeGas'));
                        this.set('formData.valueType', null);

                        break;
                    default:
                        controller.set('valueType', null);
                        this.set('formData.valueType', null);
                }
            }
        }).observes('formData.medium'),

        valueTypeObserver: (function () {
            if (this.get('formData') && this.get('formRows.valueType')) {
                if (this.get('formData.valueType') === this.contractPrice || this.get('formData.valueType') === 'overhead' && this.get('formData.medium') === 'ee') {
                    this.set('formData.isProductBasePrice', true);
                    this.get('formRows.isProductBasePrice.field').switchChange();
                } else {
                    this.set('formData.isProductBasePrice', false);
                    this.get('formRows.isProductBasePrice.field').switchChange();
                }
            }
        }).observes('formData.valueType', 'formData.medium'),

        afterSuccessfulSubmit: function afterSuccessfulSubmit() {
            var targetController = this.get('targetObject');
            var mainRoute = targetController.container.lookup('route:b2b-contract.administration.section-products.add-to-condition');
            mainRoute.send('refreshModel');
            setTimeout(function () {
                targetController.transitionToRoute('b2b-contract.administration.section-products.list');
            }, 500);
        }
    });
});
define('ember-new/controllers/b2b-contract/dzu/show-gas/contract', ['exports', 'ember', 'ember-new/mixins/b2b-contract/controllers/dzu-show-contract', 'ember-new/mixins/b2b-contract/controllers/reload-contract-header'], function (exports, _ember, _emberNewMixinsB2bContractControllersDzuShowContract, _emberNewMixinsB2bContractControllersReloadContractHeader) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsB2bContractControllersDzuShowContract['default'], _emberNewMixinsB2bContractControllersReloadContractHeader['default'], {
        TheUser: _ember['default'].inject.service('theuser:main'),

        products: [],

        afterCommand: function afterCommand(response) {
            var ctrl = this;
            var route = this.container.lookup("route:b2b-contract.dzu.show-gas.contract");
            route.refresh();
            this.reloadContractHeader();
            setTimeout(function () {
                ctrl.transitionToRoute('b2b-contract.dzu.show-gas.contract');
            }, 500);
        },

        disableForDzuSpecialist: (function () {
            if (this.get("TheUser").isMainRoleIn(['ROLE_DZU_MANAGER', 'ROLE_DZU2_MANAGER', 'ROLE_ADMIN', 'ROLE_DZU2_SPECIALIST'])) {
                return false;
            }

            return true;
        }).property(),

        isDzuUser: (function () {
            return this.get("TheUser").isMainRoleIn(['ROLE_DZU2_SPECIALIST', 'ROLE_DZU2']);
        }).property(),

        isDisabledForDzuSpecialist: (function () {
            return !this.get("TheUser").isMainRoleIn(['ROLE_DZU_MANAGER', 'ROLE_DZU2_MANAGER', 'ROLE_ADMIN']);
        }).property(),

        fillFormPreinvoices: function fillFormPreinvoices(form) {
            form.set('model.contractId', this.get('model.contractUuid'));
            form.set('model.invoiceDays', this.get('model.invoiceDays'));
            form.set('model.preinvoicesAmount', this.get('model.preinvoicesAmount'));
            form.set('model.preinvoicesPercent', this.get('model.preinvoicesPercent'));
            form.set('model.preinvoice1Days', this.get('model.preinvoice1Days'));
            form.set('model.preinvoice1Details', this.get('model.preinvoice1DetailsUuid'));
            form.set('model.preinvoice2Days', this.get('model.preinvoice2Days'));
            form.set('model.preinvoice2Details', this.get('model.preinvoice2DetailsUuid'));
            form.set('model.preinvoice3Days', this.get('model.preinvoice3Days'));
            form.set('model.preinvoice3Details', this.get('model.preinvoice3DetailsUuid'));
        },

        preinvoice1hidden: (function () {
            if (this.get('preinvoices') === '1' || this.get('preinvoices') === '2' || this.get('preinvoices') === '3') {
                return false;
            } else {
                this.set('preinvoice1Days', '');
                this.set('preinvoice1Details', null);

                return true;
            }
        }).property('preinvoices'),

        preinvoice2hidden: (function () {
            if (this.get('preinvoices') === '2' || this.get('preinvoices') === '3') {

                return false;
            } else {
                this.set('preinvoice2Days', '');
                this.set('preinvoice2Details', null);

                return true;
            }
        }).property('preinvoices'),

        preinvoice3hidden: (function () {
            if (this.get('preinvoices') === '3') {

                return false;
            } else {
                this.set('preinvoice3Days', '');
                this.set('preinvoice3Details', null);

                return true;
            }
        }).property('preinvoices'),

        preinvoicesAmount: [{ label: '1', value: '1' }, { label: '2', value: '2' }, { label: '3', value: '3' }],

        automaticExtensionNoticePeriodContent: [{ label: '45 dni kalendarzowych przed rokiem gazowym', value: '45 dni kalendarzowych przed rokiem gazowym' }, { label: '45 dni roboczych przed rokiem gazowym', value: '45 dni roboczych przed rokiem gazowym' }, { label: '45 dni roboczych przed rokiem kalendarzowym', value: '45 dni roboczych przed rokiem kalendarzowym' }, { label: '60 dni roboczych przed rokiem gazowym', value: '60 dni roboczych przed rokiem gazowym' }, { label: '60 dni roboczych przed rokiem kalendarzowym', value: '60 dni roboczych przed rokiem kalendarzowym' }],

        isManualMmVal: (function () {
            if (this.get('isManualMmValue') !== true) {
                this.set('isManualMmValue', false);
            }
            return !this.get('isManualMmValue');
        }).property('isManualMmValue'),

        fillFormBurden: function fillFormBurden(form) {
            form.set('model.contractId', this.get('model.contractUuid'));
            form.set('model.burdenCompletingConsumptionDeclarationsExist', this.get('model.burdenCompletingConsumptionDeclarationsExist'));
            form.set('model.burdenCompletingConsumptionDeclarationsMultiplier', this.get('model.burdenCompletingConsumptionDeclarationsMultiplier'));
            form.set('model.burdenCompletingConsumptionDeclarationsCharged', this.get('burdens.completingConsumptionDeclarationsGas') * this.get('model.burdenCompletingConsumptionDeclarationsMultiplier'));
            form.set('model.burdenFillingAttachmentsExist', this.get('model.burdenFillingAttachmentsExist'));
            form.set('model.burdenFillingAttachmentsMultiplier', this.get('model.burdenFillingAttachmentsMultiplier'));
            form.set('model.burdenFillingAttachmentsCharged', this.get('burdens.fillingAttachmentsGas') * this.get('model.burdenFillingAttachmentsMultiplier'));
            form.set('model.burdenFillingContractDocumentsExist', this.get('model.burdenFillingContractDocumentsExist'));
            form.set('model.burdenFillingContractDocumentsMultiplier', this.get('model.burdenFillingContractDocumentsMultiplier'));
            form.set('model.burdenFillingContractDocumentsCharged', this.get('burdens.fillingContractDocumentsGas') * this.get('model.burdenFillingContractDocumentsMultiplier'));
            form.set('model.burdenConfirmationLoyalistsExist', this.get('model.burdenConfirmationLoyalistsExist'));
            form.set('model.burdenConfirmationLoyalistsMultiplier', this.get('model.burdenConfirmationLoyalistsMultiplier'));
            form.set('model.burdenConfirmationLoyalistsCharged', this.get('burdens.confirmationLoyalistsGas') * this.get('model.burdenConfirmationLoyalistsMultiplier'));
            form.set('model.burdenObtainingDistributionExist', this.get('model.burdenObtainingDistributionExist'));
            form.set('model.burdenObtainingDistributionMultiplier', this.get('model.burdenObtainingDistributionMultiplier'));
            form.set('model.burdenObtainingDistributionCharged', this.get('burdens.obtainingDistributionGas') * this.get('model.burdenObtainingDistributionMultiplier'));
            form.set('model.burdenFailProvideApprovalsExist', this.get('model.burdenFailProvideApprovalsExist'));
            form.set('model.burdenFailProvideApprovalsMultiplier', this.get('model.burdenFailProvideApprovalsMultiplier'));
            form.set('model.burdenFailProvideApprovalsCharged', this.get('burdens.failProvideApprovalsGas') * this.get('model.burdenFailProvideApprovalsMultiplier'));

            if (this.get('model.statusNumber') < 7) {
                form.set('model.burdenCompletingConsumptionDeclarationsSingleAmount', this.get('burdens.completingConsumptionDeclarationsGas'));
                form.set('model.burdenFillingAttachmentsSingleAmount', this.get('burdens.fillingAttachmentsGas'));
                form.set('model.burdenFillingContractDocumentsSingleAmount', this.get('burdens.fillingContractDocumentsGas'));
                form.set('model.burdenConfirmationLoyalistsSingleAmount', this.get('burdens.confirmationLoyalistsGas'));
                form.set('model.burdenObtainingDistributionSingleAmount', this.get('burdens.obtainingDistributionGas'));
                form.set('model.burdenFailProvideApprovalsSingleAmount', this.get('burdens.failProvideApprovalsGas'));
            } else {
                form.set('model.burdenCompletingConsumptionDeclarationsSingleAmount', this.get('model.burdenCompletingConsumptionDeclarationsSingleAmount'));
                form.set('model.burdenFillingAttachmentsSingleAmount', this.get('model.burdenFillingAttachmentsSingleAmount'));
                form.set('model.burdenFillingContractDocumentsSingleAmount', this.get('model.burdenFillingContractDocumentsSingleAmount'));
                form.set('model.burdenConfirmationLoyalistsSingleAmount', this.get('model.burdenConfirmationLoyalistsSingleAmount'));
                form.set('model.burdenObtainingDistributionSingleAmount', this.get('model.burdenObtainingDistributionSingleAmount'));
                form.set('model.burdenFailProvideApprovalsSingleAmount', this.get('model.burdenFailProvideApprovalsSingleAmount'));
            }
        },

        burdenCompletingConsumptionDeclarationsSum: (function () {
            return this.get('model.burdenCompletingConsumptionDeclarationsMultiplier') * this.get('burdens.completingConsumptionDeclarationsGas');
        }).property('model.burdenCompletingConsumptionDeclarationsMultiplier'),

        burdenFillingAttachmentsSum: (function () {
            return this.get('model.burdenFillingAttachmentsMultiplier') * this.get('burdens.fillingAttachmentsGas');
        }).property('model.burdenFillingAttachmentsMultiplier'),

        burdenFillingContractDocumentsSum: (function () {
            return this.get('model.burdenFillingContractDocumentsMultiplier') * this.get('burdens.fillingContractDocumentsGas');
        }).property('model.burdenFillingContractDocumentsMultiplier'),

        burdenConfirmationLoyalistsSum: (function () {
            return this.get('model.burdenConfirmationLoyalistsMultiplier') * this.get('burdens.confirmationLoyalistsGas');
        }).property('model.burdenConfirmationLoyalistsMultiplier'),

        burdenObtainingDistributionSum: (function () {
            return this.get('model.burdenObtainingDistributionMultiplier') * this.get('burdens.obtainingDistributionGas');
        }).property('model.burdenObtainingDistributionMultiplier'),

        burdenFailProvideApprovalsSum: (function () {
            return this.get('model.burdenFailProvideApprovalsMultiplier') * this.get('burdens.failProvideApprovalsGas');
        }).property('model.burdenFailProvideApprovalsMultiplier')

    });
});
define('ember-new/models/contract-client-dzu-view-model', ['exports', 'ember-new/models/abstract', 'ember-data'], function (exports, _emberNewModelsAbstract, _emberData) {

    var attr = _emberData['default'].attr;

    exports['default'] = _emberNewModelsAbstract['default'].extend({
        clientUuid: attr('string'),

        companyName: attr('string'),

        regon: attr('string'),
        krs: attr('string'),
        pkd: attr('string'),
        pkdUuid: attr('string'),
        nip: attr('string'),
        legalForm: attr('string'),
        legalFormUuid: attr('string'),

        //address
        street: attr('string'),
        streetNo: attr('string'),
        aptNo: attr('string'),
        postalCode: attr('string'),
        postalCity: attr('string'),
        city: attr('string'),
        district: attr('string'),
        districtUuid: attr('string'),
        county: attr('string'),
        countyUuid: attr('string'),

        //contactAddress
        contactStreet: attr('string'),
        contactStreetNo: attr('string'),
        contactAptNo: attr('string'),
        contactPostalCode: attr('string'),
        contactPostalCity: attr('string'),
        contactCity: attr('string'),
        contactDistrict: attr('string'),
        contactDistrictUuid: attr('string'),
        contactCounty: attr('string'),
        contactCountyUuid: attr('string'),

        contactPersons: _emberData['default'].hasMany('IndividualContractContactPersonViewModel', { async: false }),
        contactSettlementPersons: _emberData['default'].hasMany('IndividualContractSettlementContactPersonViewModel', { async: false }),
        representativePersons: _emberData['default'].hasMany('IndividualContractRepresentativePersonViewModel', { async: false }),

        substitutePowerOfAttorney: attr('boolean'),
        substitutePowerOfAttorneyComment: attr('string'),

        manyContractsByClientWarning: attr('boolean'),

        consumerType: attr('string'),
        consumerTypeUuid: attr('string'),

        clientCategory: attr('string'),
        clientCategoryInfo: attr('string'),
        clientCategoryEeVolume: attr('string'),
        clientCategoryGasVolume: attr('string'),
        isClientCategoryFrozen: attr('boolean'),

        clientType: attr('string'),
        clientTypeUuid: attr('string'),
        clientTypeName: attr('string'),
        clientVatCentralization: attr('boolean'),
        clientVatCentralizationCompanyName: attr('string'),
        clientVatCentralizationNip: attr('string'),
        clientVatCentralizationStreet: attr('string'),
        clientVatCentralizationStreetNo: attr('string'),
        clientVatCentralizationAptNo: attr('string'),
        clientVatCentralizationPostalCode: attr('string'),
        clientVatCentralizationPostalCity: attr('string'),
        clientVatCentralizationCity: attr('string'),
        clientVatCentralizationDistrict: attr('string'),
        clientVatCentralizationDistrictUuid: attr('string'),
        clientCreditVerification: attr('boolean')
    });
});
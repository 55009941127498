define('ember-new/controllers/meeting-verification/list', ['exports', 'ember-new/config/environment', 'ember'], function (exports, _emberNewConfigEnvironment, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        isAllowedToModify: (function () {
            return this.get('TheUser').isGranted(['ROLE_CC_VERIFICATOR', 'ROLE_DE', 'ROLE_DG', 'ROLE_SALES_SUPPORT']);
        }).property(),

        isAllowedToApprove: (function () {
            return this.get('TheUser').isGranted(['ROLE_CC_VERIFICATOR', 'ROLE_DE', 'ROLE_DG', 'ROLE_SALES_SUPPORT']);
        }).property(),

        isAllowedToDelete: (function () {
            return this.get('TheUser').isGranted(['ROLE_CC_VERIFICATOR', 'ROLE_DE', 'ROLE_DG', 'ROLE_SALES_SUPPORT']);
        }).property(),

        isAllowedToExport: (function () {
            return this.get('TheUser').isGranted(['ROLE_CC_VERIFICATOR', 'ROLE_DE', 'ROLE_DG', 'ROLE_SALES_SUPPORT']);
        }).property()

    });
});
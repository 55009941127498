define('ember-new/controllers/b2b-contract/dzu/show-gas/client', ['exports', 'ember', 'ember-new/config/environment', 'ember-new/mixins/b2b-contract/controllers/reload-contract-header', 'ember-new/mixins/b2b-contract/controllers/client-info'], function (exports, _ember, _emberNewConfigEnvironment, _emberNewMixinsB2bContractControllersReloadContractHeader, _emberNewMixinsB2bContractControllersClientInfo) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsB2bContractControllersReloadContractHeader['default'], _emberNewMixinsB2bContractControllersClientInfo['default'], {

        ajax: _ember['default'].inject.service('ajax'),
        applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),

        fillForm: function fillForm(form) {
            form.set('model.contractId', this.get('idContract'));
            form.set('model.clientStreet', this.get('model.street'));
            form.set('model.clientStreetNo', this.get('model.streetNo'));
            form.set('model.clientAptNo', this.get('model.aptNo'));
            form.set('model.clientCity', this.get('model.city'));
            form.set('model.postalCode', this.get('model.postalCode'));
            form.set('model.clientPostalCity', this.get('model.postalCity'));
            form.set('model.clientDistrict', this.get('model.districtUuid'));
            form.set('model.clientKrs', this.get('model.krs'));
            form.set('model.clientRegon', this.get('model.regon'));
            form.set('model.clientPkd', this.get('model.pkdUuid'));
            form.set('model.clientCounty', this.get('model.countyUuid'));
            form.set('model.clientLegalForm', this.get('model.legalFormUuid'));
            form.set('model.clientNip', this.get('model.nip'));
            form.set('model.clientCompanyName', this.get('model.companyName'));
            form.set('model.consumerType', this.get('model.consumerTypeUuid'));
        },

        fillFormContactAddress: function fillFormContactAddress(form) {
            form.set('model.contractId', this.get('idContract'));
            form.set('model.street', this.get('model.contactStreet'));
            form.set('model.streetNo', this.get('model.contactStreetNo'));
            form.set('model.aptNo', this.get('model.contactAptNo'));
            form.set('model.city', this.get('model.contactCity'));
            form.set('model.postalCode', this.get('model.contactPostalCode'));
            form.set('model.postalCity', this.get('model.contactPostalCity'));
            form.set('model.district', this.get('model.contactDistrictUuid'));
            form.set('model.county', this.get('model.contactCountyUuid'));
        },
        fillFormSubstitutePowerOfAttorney: function fillFormSubstitutePowerOfAttorney(form) {
            form.set('model.contractId', this.get('idContract'));
            form.set('model.substitutePowerOfAttorneyF', this.get('model.substitutePowerOfAttorney'));
            form.set('model.comment', this.get('model.substitutePowerOfAttorneyComment'));
        },

        fillFormNip: function fillFormNip(form) {
            form.set('model.contractId', this.get('idContract'));
            form.set('model.nip', null);
        },

        fillFormClientCategory: function fillFormClientCategory(form) {
            form.set('model.clientUuid', this.get('model.clientUuid'));
            form.set('model.isFrozen', this.get('model.isClientCategoryFrozen'));
            form.set('model.category', this.get('model.clientCategory'));
        },

        clientCategory: [{ label: "Masowy", value: "mass" }, { label: "Korporacyjny", value: "corporate" }, { label: "Kluczowy", value: "key" }],

        afterCommand: function afterCommand(response) {
            var ctrl = this;
            var route = this.container.lookup("route:b2b-contract.dzu.show-gas.client");
            route.refresh();
            this.reloadContractHeader();
            setTimeout(function () {
                ctrl.transitionToRoute('b2b-contract.dzu.show-gas.client');
            }, 500);
        },

        showCommentField: (function () {
            if (this.get('choice') === true) {
                return false;
            }
            this.set('comment', '');
            return true;
        }).property('choice'),

        actions: {
            editPerson: function editPerson(id, firstName, lastName, email, phoneMobile, phoneLandline, uuid, person) {
                var serializedData = {
                    EditContactPersonCommand: {
                        personId: id,
                        contactPersonFirstName: firstName,
                        contactPersonLastName: lastName,
                        contactPersonEmail: email,
                        contactPersonPhoneMobile: phoneMobile,
                        contactPersonPhoneLandLine: phoneLandline,
                        contractUuid: uuid
                    }
                };

                var _this = this;
                var promise = this.get('ajax').ajaxPost(_emberNewConfigEnvironment['default'].apiUrl + '/rest/editcontactpersoncommands', serializedData);

                promise.then(function (response) {
                    _this.get('applicationAlerts').addActionMessage('success', "Dane zostały zmienione");
                    setTimeout(function () {
                        _this.send('refreshModel');
                    }, 500);
                }, function (response) {
                    person.set('errors', response.errors);
                    _this.get('applicationAlerts').addActionMessage('warning', "Wystąpił błąd, nie udało się zmienić danych");
                });
            },

            editSettlementPerson: function editSettlementPerson(id, firstName, lastName, email, phoneMobile, phoneLandline, uuid, person) {
                var serializedData = {
                    EditSettlementPersonCommand: {
                        personId: id,
                        settlementContactPersonFirstName: firstName,
                        settlementContactPersonLastName: lastName,
                        settlementContactPersonEmail: email,
                        settlementContactPersonPhoneMobile: phoneMobile,
                        settlementContactPersonPhoneLandLine: phoneLandline,
                        contractUuid: uuid
                    }
                };

                var _this = this;
                var promise = this.get('ajax').ajaxPost(_emberNewConfigEnvironment['default'].apiUrl + '/rest/editsettlementpersoncommands', serializedData);

                promise.then(function (response) {
                    _this.get('applicationAlerts').addActionMessage('success', "Dane zostały zmienione");
                    setTimeout(function () {
                        _this.send('refreshModel');
                    }, 500);
                }, function (response) {
                    person.set('errors', response.errors);
                    _this.get('applicationAlerts').addActionMessage('warning', "Wystąpił błąd, nie udało się zmienić danych");
                });
            },

            editRepresentative: function editRepresentative(id, firstName, lastName, email, phoneMobile, phoneLandline, uuid, person) {
                var serializedData = {
                    EditRepresentativePersonCommand: {
                        personId: id,
                        representativePersonFirstName: firstName,
                        representativePersonLastName: lastName,
                        representativePersonEmail: email,
                        representativePersonPhoneMobile: phoneMobile,
                        representativePersonPhoneLandLine: phoneLandline,
                        contractUuid: uuid
                    }
                };

                var _this = this;
                var promise = this.get('ajax').ajaxPost(_emberNewConfigEnvironment['default'].apiUrl + '/rest/editrepresentativepersoncommands', serializedData);

                promise.then(function (response) {
                    _this.get('applicationAlerts').addActionMessage('success', "Dane zostały zmienione");
                    setTimeout(function () {
                        _this.send('refreshModel');
                    }, 500);
                }, function (response) {
                    person.set('errors', response.errors);
                    _this.get('applicationAlerts').addActionMessage('warning', "Wystąpił błąd, nie udało się zmienić danych");
                });
            },

            afterSuccessfulCommand: function afterSuccessfulCommand() {
                this.get('ctrl.model').reload();
            },

            /*
            Kopiowanie danych firmowych (z modelu) do danych korespondencyjnych (aktywne pola formularza).
            addressForm - aktywne pola formularza.
             */
            copyAddressFromCompanyData: function copyAddressFromCompanyData() {
                var _this2 = this;

                var address = {
                    street: this.get('model.street'),
                    streetNo: this.get('model.streetNo'),
                    aptNo: this.get('model.aptNo'),
                    postalCode: this.get('model.postalCode'),
                    postalCity: this.get('model.postalCity'),
                    city: this.get('model.city'),
                    district: this.get('model.districtUuid'),
                    county: this.get('model.countyUuid')
                };

                // Przypisanie wartosci do pol formularza.
                Object.keys(address).map(function (key) {
                    _this2.set('addressForm.' + key, address[key]);
                });
            }
        }

    });
});
define("ember-new/mixins/individual-contract-price-lists/d-form/template", ["exports", "ember"], function (exports, _ember) {
    var observer = _ember["default"].observer;
    var _Ember$inject = _ember["default"].inject;
    var service = _Ember$inject.service;
    var controller = _Ember$inject.controller;
    exports["default"] = _ember["default"].Mixin.create({

        afterSuccessfulSubmit: function afterSuccessfulSubmit() {
            var targetController = this.get('targetObject');
            var mainRoute = targetController.container.lookup("route:individual-contract-price-lists.template");
            mainRoute.refresh();
            this.set('formData.type', null);

            setTimeout(function () {
                targetController.transitionToRoute('individual-contract-price-lists.template');
            }, 100);
        },

        tariffClear: (function () {

            if (this.get('formData.type') === 'EE') {
                this.set('formData.gasTariffGroupUuid', null);
            } else if (this.get('formData.type') === 'GAS') {
                this.set('formData.eeTariffGroupUuid', null);
                this.set('formData.promoFeeType', null);
            } else {
                this.set('formData.gasTariffGroupUuid', null);
                this.set('formData.eeTariffGroupUuid', null);
            }
        }).observes('formData.type'),

        _getModelFieldsPayload: function _getModelFieldsPayload() {
            var payload = this._super();

            if (payload.type === 'EE') {
                payload.tariffGroupUuid = payload.eeTariffGroupUuid;
            } else if (payload.type === 'GAS') {
                payload.tariffGroupUuid = payload.gasTariffGroupUuid;
            }

            delete payload.eeTariffGroupUuid;
            delete payload.gasTariffGroupUuid;

            if (payload.endDate) {
                payload.endDate = payload.endDate + "-12-31";
            }

            if (payload.discountPriceDate) {
                payload.discountPriceDate = payload.discountPriceDate + "-12-31";
            }

            if (payload.discountFeeDate) {
                payload.discountFeeDate = payload.discountFeeDate + "-12-31";
            }

            return payload;
        },

        fillNameValue: observer('formData.productUuid', 'formData.eeTariffGroupUuid', 'formData.gasTariffGroupUuid', function () {

            var name = this.getProductName();
            var selectedTariffLabel = this.getSelectedTariffLabel();

            this.set('formData.name', name + ' ' + selectedTariffLabel);
        }),

        fillCodeValue: observer('formData.productUuid', 'formData.eeTariffGroupUuid', 'formData.gasTariffGroupUuid', 'formData.discountFeeMonths', 'formData.discountPriceMonths', 'formData.contractEndType', 'formData.endMonths', 'formData.endDate', 'formData.discountPriceDate', 'formData.discountFeeDate', function () {

            var m = this.getM();
            var oh = this.getOh();
            var code = this.getCode();
            var selectedTariffLabel = this.getSelectedTariffLabel();
            var endType = this.getEndType();

            var fullTemplateCode = code + '_' + selectedTariffLabel + '_cu' + endType + '_' + m;
            if (oh) {
                fullTemplateCode = fullTemplateCode + '_' + oh;
            }

            this.set('formData.code', fullTemplateCode);
        }),

        getEndType: function getEndType() {
            var endValue = '';
            if (this.get('formData.contractEndType') == 'N') {
                endValue = 'N';
            }
            if (this.get('formData.contractEndType') == 'FM') {
                endValue = this.get('formData.endMonths');
            }
            if (this.get('formData.contractEndType') == 'DD') {
                endValue = this.get('formData.endDate');
            }

            return endValue;
        },

        getOh: function getOh() {
            var oh = '';

            if (this.get('formData.type') == 'GAS') {
                return null;
            }

            if (this.get('formData.promoFeeType') == 'FM') {
                oh = this.get('formData.discountFeeMonths');
            }
            if (this.get('formData.promoFeeType') == 'DD') {
                oh = this.get('formData.discountFeeDate');
            }

            return oh + 'OH';
        },

        getM: function getM() {
            var m = '';

            if (this.get('formData.promoPriceType') == 'FM') {
                m = this.get('formData.discountPriceMonths');
            }
            if (this.get('formData.promoPriceType') == 'DD') {
                m = this.get('formData.discountPriceDate');
            }

            return m + 'M';
        },

        getProduct: function getProduct() {
            var targetController = this.get('targetObject');

            var productUuid = this.get('formData.productUuid');
            var products = targetController.get('products');
            var productObject = products.filter(function (product) {
                return product.get('uuid') == productUuid;
            });

            return productObject;
        },

        getProductName: function getProductName() {
            var name = '';

            var productObject = this.getProduct();

            if (productObject[0]) {
                name = productObject[0].get('name');
            }

            return name;
        },

        getCode: function getCode() {
            var code = '';

            var productObject = this.getProduct();

            if (productObject[0]) {
                code = productObject[0].get('code');
            }

            return code;
        },

        getSelectedTariffLabel: function getSelectedTariffLabel() {
            var selectedTariffLabel = '';

            var tariffGroupUuid = this.get('formData.eeTariffGroupUuid') || this.get('formData.gasTariffGroupUuid') ? this.get('formData.eeTariffGroupUuid') || this.get('formData.gasTariffGroupUuid') : '';

            if (tariffGroupUuid) {

                var selectedEeTariff = this.get('store').peekAll('individual-contract-ee-tariff-group').find(function (record) {
                    return record.get('uuid') === tariffGroupUuid;
                });

                var selectedGasTariff = this.get('store').peekAll('individual-contract-gas-tariff-group').find(function (record) {
                    return record.get('uuid') === tariffGroupUuid;
                });

                if (selectedEeTariff) {
                    selectedTariffLabel = selectedEeTariff.get('label');
                } else if (selectedGasTariff) {
                    selectedTariffLabel = selectedGasTariff.get('label');
                }
            }

            return selectedTariffLabel;
        },

        disableFieldsDependingOnContractEndType: (function () {
            var controller = this.get('targetObject');

            var contractEndType = this.get('formData.contractEndType');

            if (contractEndType === 'FM') {
                controller.set('disableEndMonths', false);
                this.set('formData.endDate', null);
                controller.set('disableEndDate', true);
            } else if (contractEndType === 'DD') {
                this.set('formData.endMonths', null);
                controller.set('disableEndMonths', true);
                controller.set('disableEndDate', false);
            } else {
                this.set('formData.endDate', null);
                this.set('formData.endMonths', null);
                controller.set('disableEndMonths', true);
                controller.set('disableEndDate', true);
            }
        }).observes('formData.contractEndType'),

        disableFieldsDependingOnPromoPriceType: (function () {
            var controller = this.get('targetObject');

            var promoPriceType = this.get('formData.promoPriceType');

            if (promoPriceType === 'FM') {
                controller.set('disableDiscountPriceMonths', false);
                this.set('formData.discountPriceDate', null);
                controller.set('disableDiscountPriceDate', true);
            } else if (promoPriceType === 'DD') {
                this.set('formData.discountPriceMonths', null);
                controller.set('disableDiscountPriceMonths', true);
                controller.set('disableDiscountPriceDate', false);
            } else {
                this.set('formData.discountPriceMonths', null);
                this.set('formData.discountPriceDate', null);
                controller.set('disableDiscountPriceMonths', true);
                controller.set('disableDiscountPriceDate', true);
            }
        }).observes('formData.promoPriceType'),

        disableFieldsDependingOnPromoFeeType: (function () {
            var controller = this.get('targetObject');

            var promoFeeType = this.get('formData.promoFeeType');

            if (promoFeeType === 'FM') {
                controller.set('disableDiscountFeeMonths', false);
                this.set('formData.discountFeeDate', null);
                controller.set('disableDiscountFeeDate', true);
            } else if (promoFeeType === 'DD') {
                this.set('formData.discountFeeMonths', null);
                controller.set('disableDiscountFeeMonths', true);
                controller.set('disableDiscountFeeDate', false);
            } else {
                this.set('formData.discountFeeMonths', null);
                this.set('formData.discountFeeDate', null);
                controller.set('disableDiscountFeeMonths', true);
                controller.set('disableDiscountFeeDate', true);
            }
        }).observes('formData.promoFeeType'),

        disablePromoFeeType: (function () {
            var controller = this.get('targetObject');
            var type = this.get('formData.type');

            if (type === "GAS") {
                controller.set('disablePromoFeeType', true);
            } else {
                controller.set('disablePromoFeeType', false);
            }
        }).observes('formData.type')

    });
});
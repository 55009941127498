define('ember-new/mixins/controllers/individual-contract-partners/bulk-actions-selected', ['exports', 'ember'], function (exports, _ember) {
    var computed = _ember['default'].computed;
    var controller = _ember['default'].inject.controller;
    exports['default'] = _ember['default'].Mixin.create({
        individualContractPartners: controller(),
        selectedItems: computed('individualContractPartners.selectedItems', function () {
            return this.get('individualContractPartners.selectedItems');
        }),
        uuids: computed('selectedItems', function () {
            return this.get('selectedItems').mapBy('uuid');
        }),
        bulkSelected: controller('individual-contract-partners.bulk-selected'),
        actions: {
            afterBulkAction: function afterBulkAction() {
                this.get('bulkSelected').send('afterBulkAction');
            }
        }
    });
});
define("ember-new/routes/tasks/task/resolve", ["exports", "ember-new/routes/authenticated", "ember-new/config/environment", "ember", "ember-new/utils/ajax/get"], function (exports, _emberNewRoutesAuthenticated, _emberNewConfigEnvironment, _ember, _emberNewUtilsAjaxGet) {
	exports["default"] = _emberNewRoutesAuthenticated["default"].extend({

		ajax: _ember["default"].inject.service('ajax'),

		afterModel: function afterModel(model, transition) {
			var _this = this;
			var promiseModel = new _ember["default"].RSVP.Promise(function (resolve, reject) {
				var promise = _this.get('ajax').ajaxGet(_emberNewConfigEnvironment["default"].apiUrl + '/ajax/tasks/check_allow_resolve?id=' + model.id);

				promise.then(function (response) {
					_this.get('store').unloadAll('user');
					_this.get('store').pushPayload({
						user: response.users,
						ambBusinessClient: response.client
					});

					var promise2 = _this.get('store').findAll('EeBusinessContractProductViewModel');

					promise2.then(function (respons2) {
						var promise3 = _this.get('store').findAll('GasBusinessContractProductViewModel');

						promise3.then(function (respons3) {
							resolve(response);
						});
					});
				});
			}, "LoadModelOnSalesRoute");

			promiseModel.then(function (resp) {
				if (resp.status !== true) {
					if (resp.desc) {
						alert(resp.desc);
					} else {
						alert('Nie możesz rozliczyć tego klienta');
					}
					_this.transitionTo('tasks');
				} else {
					_this.controllerFor('tasks.task.resolve').set('formData', resp);
				}
			});
		},

		actions: {
			didTransition: function didTransition() {
				this.controller.reset();
				return true;
			}
		}

	});
});
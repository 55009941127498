define('ember-new/routes/b2b-contract/dzu/show-gas/vas', ['exports', 'ember-new/routes/authenticated'], function (exports, _emberNewRoutesAuthenticated) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({

        model: function model(params) {
            var contract = this.modelFor('b2b-contract.dzu.show-gas');
            this.set('idContract', contract.get('id'));

            return Ember.RSVP.hash({
                VasListToAddToContractViewModel: this.store.query('VasListToAddToContractViewModel', { contractId: contract.get('id') }, { reload: true }),
                PpmVasViewModel: this.store.query('PpmVasViewModel', { contractId: contract.get('id') }, { reload: true }),
                VasAssignedToContractListViewModel: this.store.query('VasAssignedToContractListViewModel', { id: contract.get('id') })
            });
        },

        setupController: function setupController(controller, model) {
            controller.set('VasListToAddToContractViewModel', model.VasListToAddToContractViewModel);
            controller.set('PpmVasViewModel', model.PpmVasViewModel);
            controller.set('model', model.VasAssignedToContractListViewModel);
            controller.set('idContract', this.get('idContract'));
        },

        actions: {
            refreshModelAction: function refreshModelAction() {
                this.refresh();
            }
        }

    });
});
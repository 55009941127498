define('ember-new/mixins/b2b-contract/components/d-form/specialists/specialists-by-department', ['exports', 'ember', 'ember-new/templates/components/d-form'], function (exports, _ember, _emberNewTemplatesComponentsDForm) {
    exports['default'] = _ember['default'].Mixin.create({
        layout: _emberNewTemplatesComponentsDForm['default'],

        configure: function configure() {
            var _this = this;

            /**
             * Filtrowanie pola completeSpecialist
             */
            this.registerContentFilter('completeSpecialist', function (originalContent) {
                return originalContent.filter(function (record) {
                    return record.departmentUuid === _this.get('formData.completeSpecialistDepartment');
                });
            });

            this.registerContentFilter('implementSpecialist', function (originalContent) {
                return originalContent.filter(function (record) {
                    return record.departmentUuid === _this.get('formData.implementSpecialistDepartment');
                });
            });

            /**
             * Przy zmianie wartosci pola `completeSpecialistDepartment` przefiltruj `completeSpecialist`
             */
            this.addObserver('formData.completeSpecialistDepartment', function () {
                _this.filterContentFor('completeSpecialist');
            });
            this.addObserver('formData.implementSpecialistDepartment', function () {
                _this.filterContentFor('implementSpecialist');
            });
        }
    });
});
define('ember-new/routes/tasks', ['exports', 'ember', 'ember-new/routes/authenticated'], function (exports, _ember, _emberNewRoutesAuthenticated) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({

        queryParams: {
            page: { refreshModel: true },
            limit: { refreshModel: true },
            order: { refreshModel: true },
            orderDirection: { refreshModel: true },
            taskType: { refreshModel: true },
            taskTarget: { refreshModel: true },
            favourite: { refreshModel: true },
            dateFrom: { refreshModel: true },
            dateTo: { refreshModel: true },
            company: { refreshModel: true },
            salesman: { refreshModel: true },
            coaching: { refreshModel: true },
            contact: { refreshModel: true },
            source: { refreshModel: true },
            taskStatus: { refreshModel: true },
            hotDeal: { refreshModel: true },
            tariffGroup: { refreshModel: true },
            verificationMeeting: { refreshModel: true },
            gasTariffGroup: { refreshModel: true },
            status: { refreshModel: true },
            margin: { refreshModel: true },
            reservation: { refreshModel: true },
            reservationTime: { refreshModel: true },
            includeArchivalTasks: { refreshModel: true }
        },

        activatedFiltersCount: null,

        model: function model(params, transition) {
            // console.log('model PARAMS', params, params.dateTo);
            // console.log('model TRANSITION', transition, transition.queryParams.dateTo);
            return this.store.query('TaskListItemViewModel', params, { reload: true }).then(function (result) {
                return {
                    tasks: result,
                    meta: result.get('meta')
                };
            })['catch'](function () {
                return console.error('route model : error msg');
            });
        },

        setupController: function setupController(controller, _ref) {
            var tasks = _ref.tasks;
            var meta = _ref.meta;

            this._super(controller, tasks);
            controller.set('meta', meta);
            controller.set('activatedFiltersCount', this.get('activatedFiltersCount'));
        },

        actions: {
            queryParamsDidChange: function queryParamsDidChange(changed, totalPresent, removed) {
                this._super(changed, totalPresent, removed);
                // liczba aktywnych filtorw z pominieciem filtrow z tablicy:
                var filtersToExclude = ['page', 'limit', 'order', 'orderDirection', 'includeArchivalTasks'];
                var activatedFiltersCount = Object.keys(totalPresent).filter(function (key) {
                    return !filtersToExclude.includes(key);
                }).length;

                this.set('activatedFiltersCount', activatedFiltersCount);
            },

            refreshModel: function refreshModel() {
                this.refresh();
            },

            refreshContractItemModel: function refreshContractItemModel(taskId) {
                console.log('refreshContractItemModel');
                var ctrl = this.controllerFor('tasks');
                var model = ctrl.get('model');

                var taskListItem = model.store.findRecord('TaskListItemViewModel', taskId, { reload: true });
                taskListItem.then(function (res) {
                    return console.log('response... ', res);
                });
            },

            hideStartLoading: function hideStartLoading(taskId) {
                var taskViewModel = this.store.peekRecord('TaskListItemViewModel', taskId);
                taskViewModel.set('isStarLoading', false);
            }

        }

    });
});
define('ember-new/components/dynamic-high-charts', ['exports', 'ember', 'ember-highcharts/components/high-charts'], function (exports, _ember, _emberHighchartsComponentsHighCharts) {
    exports['default'] = _emberHighchartsComponentsHighCharts['default'].extend({

        type: 'bar',
        categories: [],
        title: '',
        subtitle: '',
        chartOptions: {
            chart: {
                type: 'bar'
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: []
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            legend: {
                reversed: true
            },
            plotOptions: {
                series: {
                    stacking: 'normal'
                },
                bar: {
                    dataLabels: {
                        enabled: true,
                        formatter: function formatter() {
                            return this.y === 0 ? '' : this.y;
                        }
                    }
                }
            }
        },
        chartBaseHeight: 150,
        chartBarHeight: 35,
        contentDidChange: _ember['default'].observer('content.@each.isLoaded', function () {
            var chart = this.get('chart');
            chart.setTitle(null, { text: this.get('title') }, false);
            chart.setSubtitle(null, { text: this.get('subtitle'), 'false': false });
            chart.setSize(null, this.chartBaseHeight + this.get('categories').length * this.chartBarHeight);
            this.set('chartOptions.chart.type', this.get('type'));
            chart.xAxis[0].setCategories(this.get('categories'));
            chart.redraw();
        })
    });
});
define('ember-new/models/business-ppg-monthly-info', ['exports', 'ember-new/models/abstract'], function (exports, _emberNewModelsAbstract) {

    var string = DS.attr('string');

    exports['default'] = _emberNewModelsAbstract['default'].extend({
        year: string,
        month: string,
        volume: DS.belongsTo('BusinessPpgVolumeInfo', { embedded: true }),
        overhead: DS.belongsTo('BusinessPpgOverheadInfo', { embedded: true }),
        basePrice: DS.belongsTo('BusinessPpgBasePriceInfo', { embedded: true }),
        contractPrice: DS.belongsTo('BusinessPpgContractPriceInfo', { embedded: true }),
        mm: DS.belongsTo('BusinessPpgMmInfo', { embedded: true }),
        mmBasePrices: DS.belongsTo('BusinessPpgMmBasePrices', { embedded: true }),
        mmFuel: DS.belongsTo('BusinessPpgMmFuel', { embedded: true }),
        mmPerMonth: DS.belongsTo('BusinessPpgMmPerMonthInfo', { embedded: true })
    });
});
define('ember-new/models/ppe-view-model', ['exports', 'ember-new/models/abstract', 'ember-data'], function (exports, _emberNewModelsAbstract, _emberData) {

    var attr = _emberData['default'].attr;
    var refOne = _emberData['default'].belongsTo;

    exports['default'] = _emberNewModelsAbstract['default'].extend({
        uuid: attr('string'),

        // Adres
        streetName: attr('string'),
        streetNumber: attr('string'),
        postalCode: attr('string'),
        postalCity: attr('string'),
        apartmentNumber: attr('string'),
        city: attr('string'),
        districtUuid: attr('string'),
        district: attr('string'),

        // Dane PPE
        pointNumber: attr('string'),
        deviceNumber: attr('string'),
        registrationNumber: attr('string'),
        pointNumberForWinback: attr('string'),
        isNewConnection: attr('boolean'),
        osdSectionUuid: attr('string'),
        osdName: attr('string'),
        osdSectionName: attr('string'),
        billingFrequencyName: attr('string'),
        billingFrequencyUuid: attr('string'),

        // Dane sprzedażowe
        tariffGroupName: attr('string'),
        tariffGroup: attr('string'),
        flattenTariffGroup: attr('string'),
        flattenTariffGroupName: attr('string'),
        osdSection: refOne('osdSectionDictionaryEntry'),
        tariffGroupChange: attr('boolean'),
        billingFrequency: refOne('billingFrequencyDictionaryEntry'),
        sellerChange: attr('string'),
        sellerChangeLabel: attr('string'),
        customerCession: attr('boolean'),
        isDataUpdated: attr('boolean'),
        osName: attr('string'),
        osUuid: attr('string'),
        osd: refOne('osdDictionaryEntry'),
        osdUuid: attr('string'),
        startDate: attr('string'),
        endDate: attr('string'),
        numberOfMonths: attr('number'),
        deliveredComprehensiveAgreement: attr('boolean'),
        correctionRatio: attr('string'),
        correctionRatioUuid: attr('string'),
        loyalty: attr('string'),
        loyaltyUuid: attr('string'),
        loyaltyDisplayLabel: attr('string'),
        loyaltyEnd: attr('string'),
        dismissPeriod: attr('number'),
        consumptionCalculationMethod: attr('string'),
        consumptionCalculationMethodUuid: attr('string'),
        reserveAgreement: attr('boolean'),
        isContractTerminating: attr('boolean'),
        energyDestiny: attr('string'),
        energyDestinyUuid: attr('string'),
        noticePeriodResultUuid: attr('string'),
        noticePeriodResult: attr('string'),
        isTariffGroupSet: attr('boolean'),
        areasQuantity: attr('number'),
        isManualMm: attr('boolean'),
        manualMm: attr('string'),
        isManualMmByContract: attr('boolean'),
        terminationDate: attr('string'),
        dateChooseTypeUuid: attr('string'),
        dateChooseType: attr('string'),
        declaredYearConsumption: attr('number')

    });
});
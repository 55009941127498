define('ember-new/routes/individual-contract-price-lists/template/add-from-code', ['exports', 'ember', 'ember-new/routes/authenticated'], function (exports, _ember, _emberNewRoutesAuthenticated) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({

        beforeModel: function beforeModel(transition) {
            window.scrollTo(0, 0); // scroll to the top of application before reloading
        },

        model: function model(params) {
            return _ember['default'].RSVP.hash({
                tariffs: this.store.query('IndividualContractBusinessTariffListViewModel', params)
            });
        },

        setupController: function setupController(ctrl, model) {
            ctrl.set('tariffs', model.tariffs);
        }

    });
});
define('ember-new/controllers/individual-contract-price-lists/business-tariff/edit', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        fillForm: function fillForm(form) {
            form.set('model.name', this.get('model.name'));
            form.set('model.uuid', this.get('model.uuid'));
            form.set('model.idUsys', this.get('model.idUsys'));
        }

    });
});
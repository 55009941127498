define("ember-new/mixins/b2b-contract/controllers/dzu-show-contract", ["exports", "ember", "ember-new/config/environment", "ember-new/mixins/user-extensions", "ember-new/utils/utils"], function (exports, _ember, _emberNewConfigEnvironment, _emberNewMixinsUserExtensions, _emberNewUtilsUtils) {
    exports["default"] = _ember["default"].Mixin.create(_emberNewMixinsUserExtensions["default"], {

        cookies: _ember["default"].inject.service(),

        dictionaryName: null,

        source: null,

        hasCC: null,

        sourceValue: _ember["default"].computed('hasCC', function () {
            return "" + this.get('hasCC');
        }),

        actions: {
            oneContractExport: function oneContractExport(uuid) {
                var uuidToSend = JSON.stringify(uuid);
                var url = _emberNewUtilsUtils["default"].generateSimpleUrl('/export/newPpe/uuid/' + uuidToSend, '?', _emberNewConfigEnvironment["default"], this.get('TheUser'), this.get('cookies'));
                window.open(url);
            }
        },

        initializeProducts: function initializeProducts(products) {
            var ctrl = this;

            products = products.filter(function (item) {
                return item.get('isHidden') !== true || item.get('id') === ctrl.get('model').get('productUuid');
            });

            this.set('products', products);
        },

        fillForm: function fillForm(form) {
            form.set('model.contractId', this.get('idContract'));

            form.set('model.contractorNoForm', this.get('model.contractorNo'));

            form.set('model.contractDateSigned', this.get('model.dateContractSigned'));
            form.set('model.contractAndPpgDateEnd', this.get('model.contractAndPpgDateEnd'));

            form.set('model.owuDate', this.get('model.dateOwu'));
            form.set('model.automaticExtensionForm', this.get('model.automaticExtension'));
            form.set('model.monthsNo', this.get('model.monthsNo'));

            form.set('model.eeTariffForm', this.get('model.eeTariffUuid'));
            form.set('model.gasTariffForm', this.get('model.gasTariffUuid'));

            form.set('model.usysTemplateUuid', this.get('model.usysTemplateUuid'));
            if (this.get('model.dateChooseTypeUuid') === undefined || this.get('model.dateChooseTypeUuid') === null) {
                this.set('model.dateChooseTypeUuid', '5d702e72-810c-4657-bc69-02892a635e0c');
                form.set('model.dateStart', this.get('model.ppgMin'));
                form.set('model.dateEnd', this.get('model.ppgMax'));
            }
            // Data startu per Punkt Poboru, liczba miesięcy per umowa
            else if (this.get('model.dateChooseTypeUuid') == '5d702e72-810c-4657-bc69-02892a635e0c') {
                    form.set('model.dateStart', this.get('model.ppgMin'));
                    form.set('model.dateEnd', this.get('model.ppgMax'));
                }
                // Data startu per Punkt Poboru, data końca per umowa
                else if (this.get('model.dateChooseTypeUuid') == 'a389c54c-25cb-495a-8b4b-093cea862d55') {
                        form.set('model.dateStart', this.get('model.ppgMin'));
                        form.set('model.dateEnd', this.get('model.dateEnd'));
                    }
                    // daty ręcznie
                    else {
                            form.set('model.dateStart', this.get('model.dateStart'));
                            form.set('model.dateEnd', this.get('model.dateEnd'));
                        }
            form.set('model.ppgDatesType', this.get('model.dateChooseTypeUuid'));

            form.set('model.isManualMm', this.get('model.isManualMm'));
            form.set('model.manualMm', this.get('model.manualMm'));

            if (this.get('model.type') === 'ee') {
                form.set('model.isAntiSmogPackage', this.get('model.isAntiSmogPackage'));
                form.set('model.compensionCalculatingRate', this.get('model.compensionCalculatingRate'));
                form.set('model.compensionCalculatingRateMonths', this.get('model.compensionCalculatingRateMonths'));
                form.set('model.compensionCalculatingRateConsumption', this.get('model.compensionCalculatingRateConsumption'));
                form.set('model.compensionCalculatingRateKind', this.get('model.compensionCalculatingRateKind'));
            }
            form.set('model.compensionCalculatingRateValue', this.get('model.compensionCalculatingRateValue'));

            form.set('model.fixedTermOrOpenEndedForm', this.get('model.fixedTermOrOpenEnded'));
            form.set('model.contractTypeLength', this.get('model.contractTypeLength'));
            form.set('model.automaticExtensionFormChoice', this.get('model.automaticExtensionFormChoice'));
            form.set('model.automaticExtensionNoticePeriod', this.get('model.automaticExtensionNoticePeriod'));
            form.set('model.automaticExtensionNoticePeriodMonths', this.get('model.automaticExtensionNoticePeriodMonths'));
            form.set('model.gazPrad', this.get('model.gazPrad'));
            form.set('model.audit', this.get('model.audit'));
            form.set('model.vip', this.get('model.vip'));
        },

        fillFormAgreements: function fillFormAgreements(form) {
            form.set('model.contractIdForm', this.get('idContract'));
            form.set('model.eInvoiceForm', this.get('model.eInvoice'));
            form.set('model.eInvoiceEmailForm', this.get('model.eInvoiceEmail'));
            form.set('model.agreementProcessingForm', this.get('model.agreementProcessing'));
            form.set('model.agreementSharingForm', this.get('model.agreementSharing'));
            form.set('model.agreementSendingForm', this.get('model.agreementSending'));
            form.set('model.agreementLogoForm', this.get('model.agreementLogo'));
        },

        fillFormSalesData: function fillFormSalesData(form) {
            form.set('model.contractId', this.get('idContract'));
            form.set('model.contractTypeForm', this.get('model.contractTypeUuid'));
            form.set('model.salesmanForm', this.get('model.salesmanUuid'));
            form.set('model.ccConsultantForm', this.get('model.ccConsultantUuid'));
            form.set('model.consumerTypeForm', this.get('model.consumerTypeUuid'));
            form.set('model.isCoaching', this.get('model.isCoaching'));
            form.set('model.coachedBy', this.get('model.coachingUuid'));
            form.set('model.isAutenti', this.get('model.isAutenti'));
        },

        fillFormSpecialist: function fillFormSpecialist(form) {
            form.set('model.contractId', this.get('model.id'));
            form.set('model.completeSpecialist', this.get('model.completeSpecialistUuid'));
            form.set('model.implementSpecialist', this.get('model.implementSpecialistUuid'));
            form.set('model.implementSpecialistDepartment', this.get('model.implementSpecialistDepartmentUuid'));
            form.set('model.completeSpecialistDepartment', this.get('model.completeSpecialistDepartmentUuid'));
        },

        fillFormStatus: function fillFormStatus(form) {
            form.set('model.contractId', this.get('idContract'));
            form.set('model.statusDefinitionId', null);
            form.set('model.userContent', null);
        },

        fillProductForm: function fillProductForm(form) {
            form.set('model.contractUuid', this.get('idContract'));
            form.set('model.productUuid', this.get('model.productUuid'));
        },

        /*eInvoiceFalse: function () {
             if (this.get('choice') === false) {
                this.set('e-mail', '');
                 return true;
            }
             return false;
        }.property('choice'),*/

        isCoachingVisible: (function () {
            if (this.get('coaching') === false) {
                this.set('coachedBy', null);
                return true;
            }
            return false;
        }).property('coaching'),

        clientSource: [{ label: "Własne", value: "Własne" }, { label: "CC", value: "CC" }],

        coaching: [{ label: "Tak", value: true }, { label: "Nie", value: false }],

        isFixedTerm: [{ label: "Umowa na czas określony", value: "Umowa na czas określony" }, { label: "Umowa na czas nieokreślony", value: "Umowa na czas nieokreślony" }],

        contractTypeLengthContent: [{ label: "Podpisana na ilość miesięcy", value: "Podpisana na ilość miesięcy" }, { label: "Podpisana na konkretną datę", value: "Podpisana na konkretną datę" }],

        automaticExtensionFormContent: [{ label: "Automatyczne przedłużenie 1", value: "Automatyczne przedłużenie 1" }, { label: "Automatyczne przedłużenie 12", value: "Automatyczne przedłużenie 12" }, { label: "Brak", value: "Brak" }],

        specialistCompleteDepartment: [{ label: "DzU", value: "8f135291-e227-420d-8951-8fc49ada0923" }, { label: "TPA", value: "120e1873-177c-4ab9-a6e1-4f25182a2f0f" }],

        specialistImplementDepartment: [{ label: 'BOK', value: 'b977bb2f-a174-47ed-8a01-a4ab1e062164' }, { label: "DG", value: "4439ae3e-6109-4ac9-8e51-cc147c3ffee4" }, { label: "TPA", value: "120e1873-177c-4ab9-a6e1-4f25182a2f0f" }, { label: "DzU", value: "8f135291-e227-420d-8951-8fc49ada0923" }],

        yesNoContent: [{ label: "Tak", value: true }, { label: "Nie", value: false }],

        configureForm: function configureForm(form) {
            var _this = this;

            /**
             * Filtruje zawartosc
             * @param dependsOnFieldName - nazwa pola na podstawie ktorego ma byc filtrowane
             * @param contentToFilter (array) zawartosc do przefiltrowania
             * @returns []
             */
            var getFilteredContentByChosenDepartment = function getFilteredContentByChosenDepartment(dependsOnFieldName, contentToFilter) {
                var parentFieldValue = _this.get("formData." + dependsOnFieldName);

                if (!parentFieldValue) return contentToFilter;

                return contentToFilter.filter(function (record) {
                    return record.departmentUuid === parentFieldValue;
                });
            };

            form.registerContentFilter('completeSpecialist', // pole dla ktorego stosujemy filtr
            function (originalContent) {
                return getFilteredContentByChosenDepartment( // f-cja filtrujaca
                'completeSpecialistDepartment', // pole na podstawie ktorego filtrujemy
                originalContent // tablica do przefiltrowania
                );
            });

            // j.w.
            form.registerContentFilter('implementSpecialist', function (originalContent) {
                return getFilteredContentByChosenDepartment('implementSpecialistDepartment', originalContent);
            });

            form.addObserver('formData.completeSpecialistDepartment', function (form) {
                if (form.getValue('completeSpecialistDepartment') === null) {
                    this.set('formRows.completeSpecialist.hidden', true);
                } else {
                    this.set('formRows.completeSpecialist.hidden', false);
                }
                form.filterContentFor('completeSpecialist');
            });

            form.addObserver('formData.implementSpecialistDepartment', function (form) {
                if (form.getValue('implementSpecialistDepartment') === null) {
                    this.set('formRows.implementSpecialist.hidden', true);
                } else {
                    this.set('formRows.implementSpecialist.hidden', false);
                }
                form.filterContentFor('implementSpecialist');
            });
        },

        configureFormDk: function configureFormDk(form) {
            var _this2 = this;

            /**
             * Filtruje zawartosc
             * @param dependsOnFieldName - nazwa pola na podstawie ktorego ma byc filtrowane
             * @param contentToFilter (array) zawartosc do przefiltrowania
             * @returns []
             */
            var getFilteredContentByChosenDepartment = function getFilteredContentByChosenDepartment(dependsOnFieldName, contentToFilter) {
                var parentFieldValue = _this2.get("formData." + dependsOnFieldName);

                if (!parentFieldValue) return contentToFilter;

                return contentToFilter.filter(function (record) {
                    return record.departmentUuid === parentFieldValue;
                });
            };

            form.registerContentFilter('completeSpecialist', // pole dla ktorego stosujemy filtr
            function (originalContent) {
                return getFilteredContentByChosenDepartment( // f-cja filtrujaca
                'completeSpecialistDepartment', // pole na podstawie ktorego filtrujemy
                originalContent // tablica do przefiltrowania
                );
            });

            // j.w.
            form.registerContentFilter('implementSpecialist', function (originalContent) {
                return getFilteredContentByChosenDepartment('implementSpecialistDepartment', originalContent);
            });

            form.addObserver('formData.completeSpecialistDepartment', function (form) {
                if (form.getValue('completeSpecialistDepartment') === null) {
                    this.set('formRows.completeSpecialist.hidden', true);
                } else {
                    this.set('formRows.completeSpecialist.hidden', false);
                }
                form.filterContentFor('completeSpecialist');
            });
        },

        configureFormDr: function configureFormDr(form) {
            var _this3 = this;

            /**
             * Filtruje zawartosc
             * @param dependsOnFieldName - nazwa pola na podstawie ktorego ma byc filtrowane
             * @param contentToFilter (array) zawartosc do przefiltrowania
             * @returns []
             */
            var getFilteredContentByChosenDepartment = function getFilteredContentByChosenDepartment(dependsOnFieldName, contentToFilter) {
                var parentFieldValue = _this3.get("formData." + dependsOnFieldName);

                if (!parentFieldValue) return contentToFilter;

                return contentToFilter.filter(function (record) {
                    return record.departmentUuid === parentFieldValue;
                });
            };

            form.registerContentFilter('completeSpecialist', // pole dla ktorego stosujemy filtr
            function (originalContent) {
                return getFilteredContentByChosenDepartment( // f-cja filtrujaca
                'completeSpecialistDepartment', // pole na podstawie ktorego filtrujemy
                originalContent // tablica do przefiltrowania
                );
            });

            // j.w.
            form.registerContentFilter('implementSpecialist', function (originalContent) {
                return getFilteredContentByChosenDepartment('implementSpecialistDepartment', originalContent);
            });

            form.addObserver('formData.implementSpecialistDepartment', function (form) {
                if (form.getValue('implementSpecialistDepartment') === null) {
                    this.set('formRows.implementSpecialist.hidden', true);
                } else {
                    this.set('formRows.implementSpecialist.hidden', false);
                }
                form.filterContentFor('implementSpecialist');
            });
        },

        configureContractDataForm: function configureContractDataForm(form) {

            form.addObserver('formData.ppgDatesType', function (form) {
                if (form.getValue('ppgDatesType') == '5d702e72-810c-4657-bc69-02892a635e0c') {
                    this.set('formRows.dateStart.hidden', true);
                    this.set('formRows.dateEnd.hidden', true);
                    this.set('formRows.monthsNo.hidden', false);
                    this.set('formData.monthsNo', null);
                    this.set('formRows.monthsNo.label', 'Długość trwania umowy/PPG');
                } else if (form.getValue('ppgDatesType') == 'a389c54c-25cb-495a-8b4b-093cea862d55') {
                    this.set('formRows.dateStart.hidden', true);
                    this.set('formRows.monthsNo.hidden', true);
                    this.set('formRows.dateEnd.hidden', false);
                    this.set('formRows.dateEnd.label', 'Data końca każdego PPG');
                } else {
                    this.set('formRows.monthsNo.hidden', true);
                    this.set('formRows.dateStart.label', 'Data startu umowy');
                    this.set('formRows.dateEnd.label', 'Data końca umowy');
                    this.set('formRows.dateEnd.hidden', false);
                    this.set('formRows.dateStart.hidden', false);
                }
            });
        },

        configureContractDataFormEe: function configureContractDataFormEe(form) {

            form.addObserver('formData.ppgDatesType', function (form) {
                if (form.getValue('ppgDatesType') == '5d702e72-810c-4657-bc69-02892a635e0c') {
                    this.set('formRows.dateStart.hidden', true);
                    this.set('formRows.dateEnd.hidden', true);
                    this.set('formRows.monthsNo.hidden', false);
                    this.set('formData.monthsNo', null);
                    this.set('formRows.monthsNo.label', 'Długość trwania umowy/PPE');
                } else if (form.getValue('ppgDatesType') == 'a389c54c-25cb-495a-8b4b-093cea862d55') {
                    this.set('formRows.dateStart.hidden', true);
                    this.set('formRows.monthsNo.hidden', true);
                    this.set('formRows.dateEnd.hidden', false);
                    this.set('formRows.dateEnd.label', 'Data końca każdego PPE');
                } else {
                    this.set('formRows.monthsNo.hidden', true);
                    this.set('formRows.dateStart.label', 'Data startu umowy');
                    this.set('formRows.dateEnd.label', 'Data końca umowy');
                    this.set('formRows.dateEnd.hidden', false);
                    this.set('formRows.dateStart.hidden', false);
                }
            });
        },

        configureSalesDataForm: function configureSalesDataForm(form) {

            form.addObserver('formData.ccConsultantForm', function (form) {
                var controller = this.get('targetObject');
                if (form.getValue('ccConsultantForm') === '' || form.getValue('ccConsultantForm') === undefined || form.getValue('ccConsultantForm') === null) {
                    controller.set('hasCC', 'Własne');
                } else {
                    controller.set('hasCC', 'CC');
                }
            });
        },

        contractInconsistentWithRepresentationTrueObserver: _ember["default"].observer('model.contractInconsistentWithRepresentationTrue', function () {
            if (this.get('model.contractInconsistentWithRepresentationTrue') === false) {
                this.get('model').set('contractInconsistentWithRepresentationAgreement', false);
                this.get('model').set('contractInconsistentWithRepresentationConsultation', false);
            }
        }),
        noSubstitutionTrueObserver: _ember["default"].observer('model.noSubstitutionTrue', function () {
            if (this.get('model.noSubstitutionTrue') === false) {
                this.get('model').set('noSubstitutionAgreement', false);
                this.get('model').set('noSubstitutionConsultation', false);
            }
        }),

        noInvoicesTrueObserver: _ember["default"].observer('model.noInvoicesTrue', function () {
            if (this.get('model.noInvoicesTrue') === false) {
                this.get('model').set('noInvoicesAgreement', false);
                this.get('model').set('noInvoicesConsultation', false);
            }
        }),

        noFvInvoiceTrueObserver: _ember["default"].observer('model.noFvInvoiceTrue', function () {
            if (this.get('model.noFvInvoiceTrue') === false) {
                this.get('model').set('noFvInvoiceAgreement', false);
                this.get('model').set('noFvInvoiceConsultation', false);
            }
        }),

        noFvOsdInvoiceTrueObserver: _ember["default"].observer('model.noFvOsdInvoiceTrue', function () {
            if (this.get('model.noFvOsdInvoiceTrue') === false) {
                this.get('model').set('noFvOsdInvoiceAgreement', false);
                this.get('model').set('noFvOsdInvoiceConsultation', false);
            }
        }),

        noContractTrueObserver: _ember["default"].observer('model.noContractTrue', function () {
            if (this.get('model.noContractTrue') === false) {
                this.get('model').set('noContractAgreement', false);
                this.get('model').set('noContractConsultation', false);
            }
        }),

        lackingAttachmentTrueObserver: _ember["default"].observer('model.lackingAttachmentTrue', function () {
            if (this.get('model.lackingAttachmentTrue') === false) {
                this.get('model').set('lackingAttachmentAgreement', false);
                this.get('model').set('lackingAttachmentConsultation', false);
            }
        }),

        installationNotReadyTrueObserver: _ember["default"].observer('model.installationNotReadyTrue', function () {
            if (this.get('model.installationNotReadyTrue') === false) {
                this.get('model').set('installationNotReadyAgreement', false);
                this.get('model').set('installationNotReadyConsultation', false);
            }
        }),

        noNewLinkDocumentsTrueObserver: _ember["default"].observer('model.noNewLinkDocumentsTrue', function () {
            if (this.get('model.noNewLinkDocumentsTrue') === false) {
                this.get('model').set('noNewLinkDocumentsAgreement', false);
                this.get('model').set('noNewLinkDocumentsConsultation', false);
            }
        }),
        noCessionDocumentsTrueObserver: _ember["default"].observer('model.noCessionDocumentsTrue', function () {
            if (this.get('model.noCessionDocumentsTrue') === false) {
                this.get('model').set('noCessionDocumentsAgreement', false);
                this.get('model').set('noCessionDocumentsConsultation', false);
            }
        }),
        pricesBelowBasicTrueObserver: _ember["default"].observer('model.pricesBelowBasicTrue', function () {
            if (this.get('model.pricesBelowBasicTrue') === false) {
                this.get('model').set('pricesBelowBasicAgreement', false);
                this.get('model').set('pricesBelowBasicConsultation', false);
            }
        }),

        notAcceptedChangesTrueObserver: _ember["default"].observer('model.notAcceptedChangesTrue', function () {
            if (this.get('model.noNewLinkDocumentsTrue') === false) {
                this.get('model').set('notAcceptedChangesAgreement', false);
                this.get('model').set('notAcceptedChangesConsultation', false);
            }
        }),

        noCreditworthinessAssessmentTrueObserver: _ember["default"].observer('model.noCreditworthinessAssessmentTrue', function () {
            if (this.get('model.noCreditworthinessAssessmentTrue') === false) {
                this.get('model').set('noCreditworthinessAssessmentAgreement', false);
                this.get('model').set('noCreditworthinessAssessmentConsultation', false);
            }
        }),

        contractNotCompleteTrueObserver: _ember["default"].observer('model.contractNotCompleteTrue', function () {
            if (this.get('model.contractNotCompleteTrue') === false) {
                this.get('model').set('contractNotCompleteAgreement', false);
                this.get('model').set('contractNotCompleteConsultation', false);
            }
        }),

        contractInconsistentWithProductTrueObserver: _ember["default"].observer('model.contractInconsistentWithProductTrue', function () {
            if (this.get('model.contractInconsistentWithProductTrue') === false) {
                this.get('model').set('contractInconsistentWithProductAgreement', false);
                this.get('model').set('contractInconsistentWithProductConsultation', false);
            }
        }),

        illegibleSignatureTrueeObserver: _ember["default"].observer('model.illegibleSignatureTrue', function () {
            if (this.get('model.illegibleSignatureTrue') === false) {
                this.get('model').set('illegibleSignatureAgreement', false);
                this.get('model').set('illegibleSignatureConsultation', false);
            }
        }),

        noOWUTrueObserver: _ember["default"].observer('model.noOWUTrue', function () {
            if (this.get('model.noOWUTrue') === false) {
                this.get('model').set('noOWUAgreement', false);
                this.get('model').set('noOWUConsultation', false);
            }
        }),

        pricesAboveBasicTrueObserver: _ember["default"].observer('model.pricesAboveBasicTrue', function () {
            if (this.get('model.pricesAboveBasicTrue') === false) {
                this.get('model').set('pricesAboveBasicAgreement', false);
                this.get('model').set('pricesAboveBasicConsultation', false);
            }
        }),

        negativeCreditworthinessAssessmentTrueObserver: _ember["default"].observer('model.negativeCreditworthinessAssessmentTrue', function () {
            if (this.get('model.negativeCreditworthinessAssessmentTrue') === false) {
                this.get('model').set('negativeCreditworthinessAssessmentAgreement', false);
                this.get('model').set('negativeCreditworthinessAssessmentConsultation', false);
            }
        }),

        otherTrueObserver: _ember["default"].observer('model.otherTrue', function () {
            if (this.get('model.otherTrue') === false) {
                this.set('otherReasonValue', '');
            }
        }),

        _isSubmittingVerificationSummary: false

    });
});
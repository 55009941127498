define('ember-new/controllers/b2b-contract/administration/section-products/list/delete-product', ['exports', 'ember', 'ember-new/config/environment'], function (exports, _ember, _emberNewConfigEnvironment) {

    var successMessage = 'Produkt został usunięty!';
    var warrningMessage = 'Produkt nie został usunięty!';

    var forContract = 'do umowy';
    var forCondition = 'do warunku';

    var contractProductCommand = 'DeleteContractProductCommand';
    var conditionProductCommand = 'DeleteConditionProductCommand';

    exports['default'] = _ember['default'].Controller.extend({

        ajax: _ember['default'].inject.service('ajax'),
        applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),

        actions: {

            'delete': function _delete() {
                var self = this;
                var ajax = this.get('ajax');
                var applicationAlerts = this.get('applicationAlerts');
                var command = this.selectApiCommand(this.get('model.kind'));

                var productUuid = this.get('model.id');
                var url = _emberNewConfigEnvironment['default'].apiUrl + '/rest/' + command.toLowerCase() + 's';

                var payload = { DeleteContractProductCommand: { uuid: productUuid } };

                if (command === conditionProductCommand) {
                    payload = { DeleteConditionProductCommand: { uuid: productUuid } };
                }

                ajax.ajaxPost(url, payload).then(function () {
                    var route = self.container.lookup('route:b2b-contract.administration.section-products');
                    applicationAlerts.addActionMessage('success', successMessage);
                    route.refresh();
                    self.transitionToRoute('b2b-contract.administration.section-products.list');
                }, function () {
                    applicationAlerts.addActionMessage('warning', warrningMessage);
                    setTimeout(function () {
                        self.transitionToRoute('b2b-contract.administration.section-products.list');
                    }, 3000);
                });
            }
        },

        selectApiCommand: function selectApiCommand(item) {
            switch (item) {
                case forContract:
                    return contractProductCommand;
                case forCondition:
                    return conditionProductCommand;
            }
        }

    });
});
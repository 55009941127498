define('ember-new/controllers/individual-contract', ['exports', 'ember-new/config/environment', 'ember', 'ember-new/mixins/sortable-controller', 'ember-new/mixins/filterable-controller', 'ember-new/mixins/paginable-controller', 'ember-new/mixins/batch-actions-controller', 'ember-new/utils/utils'], function (exports, _emberNewConfigEnvironment, _ember, _emberNewMixinsSortableController, _emberNewMixinsFilterableController, _emberNewMixinsPaginableController, _emberNewMixinsBatchActionsController, _emberNewUtilsUtils) {

    var IndividualClientController = _ember['default'].Controller.extend(_emberNewMixinsSortableController['default'], _emberNewMixinsFilterableController['default'], _emberNewMixinsPaginableController['default'], _emberNewMixinsBatchActionsController['default'], {

        init: function init() {
            this._super.apply(this, arguments);

            // initialize select-filters content:
            this._initPartnersToFilter();
            this._initStatusesToFilter();
            this._initStatusesBoToFilter();
            this._initStatusesPartnerToFilter();
            this._initMainStatusesPartnerToFilter();
            this._initProductsToFilter();
            this._initTpaSpecialistsToFilter();
        },
        dictionariesManager: _ember['default'].inject.service('dictionaries-manager'),
        cookies: _ember['default'].inject.service(),

        queryParams: ['dzuSpecialist', 'tpaSpecialist', 'ph', 'phAcquired', 'type', 'clientFirstName', 'clientLastName', 'clientPesel', 'clientPhoneNumber', 'contractNo', 'status', 'dateCreatedFrom', 'dateCreatedTo', 'dateSignedFrom', 'dateSignedTo', 'dateStartFrom', 'dateStartTo', 'statusLastChangedDateFrom', 'statusLastChangedDateTo', 'dateUsysGenerationFrom', 'dateUsysGenerationTo', 'counter', 'renouncementStatus', 'cession', 'tariffChange', 'dataUpdate', 'signingPlace', 'bulbsSent', 'vcRecordingStatus', 'contractCategory', 'fromPika', 'datePikaSynchronizationFrom', 'datePikaSynchronizationTo', 'isSearchedRenouncement', 'product', 'statusByPartner', 'mainStatusBo', 'mainStatusPartner', 'acceptDateFrom', 'acceptDateTo', 'ppmPointNo', 'ppmCounterNo', 'isAnonymized', 'whichSellerChange', 'eeTariffGroup', 'gasTariffGroup', 'onlyAgreementsWithFirstSeller'],

        dzuSpecialist: '',
        tpaSpecialist: '',
        ph: '',
        phAcquired: '',
        type: '',
        clientFirstName: '',
        clientLastName: '',
        clientPesel: '',
        clientPhoneNumber: '',
        contractNo: '',
        status: '',
        dateCreatedFrom: '',
        dateCreatedTo: '',
        dateSignedFrom: '',
        dateSignedTo: '',
        dateStartFrom: '',
        dateStartTo: '',
        dateUsysGenerationFrom: '',
        dateUsysGenerationTo: '',
        counter: '',
        renouncementStatus: '',
        cession: '',
        tariffChange: '',
        dataUpdate: '',
        signingPlace: '',
        bulbsSent: '',
        vcRecordingStatus: '',
        contractCategory: '',
        isSearchedRenouncement: '',
        fromPika: '',
        datePikaSynchronizationFrom: '',
        datePikaSynchronizationTo: '',
        product: '',
        statusByPartner: '',
        mainStatusBo: '',
        mainStatusPartner: '',
        acceptDateFrom: '',
        acceptDateTo: '',
        ppmPointNo: '',
        ppmCounterNo: '',
        isAnonymized: '',
        whichSellerChange: '',
        eeTariffGroup: '',
        gasTariffGroup: '',

        sortBy: 'dateCreated',
        sortModeAsc: false,
        onlyAgreementsWithFirstSeller: false,

        contractCategoryToSelected: _ember['default'].computed('contractCategory', function () {
            var _this2 = this;

            var allCategories = this.get('dictionariesManager').getMainDictionary('individual_contract_category');
            return allCategories.find(function (item) {
                return item.get('id') === _this2.get('contractCategory');
            });
        }),

        _makeCounter: function _makeCounter(fromValue, toValue) {
            var includeMoreValue = arguments.length <= 2 || arguments[2] === undefined ? true : arguments[2];

            var arr = [];
            for (var i = fromValue; i <= toValue; i++) {
                arr.push({ label: i, value: i });
            }
            if (includeMoreValue) {
                arr.push({ label: '>' + toValue, value: '>' + toValue });
            }
            return arr;
        },

        vcRecordingStatusToSelected: _ember['default'].computed('vcRecordingStatus', function () {
            var _this3 = this;

            return this.get('yesNoNullToFilter').find(function (item) {
                return item.value === _this3.get('vcRecordingStatus').toString();
            });
        }),
        bulbsSentSelected: _ember['default'].computed('bulbsSent', function () {
            var _this4 = this;

            return this.get('yesNoToFilter').find(function (item) {
                return item.value === _this4.get('bulbsSent').toString();
            });
        }),
        dataUpdateSelected: _ember['default'].computed('dataUpdate', function () {
            var _this5 = this;

            return this.get('yesNoToFilter').find(function (item) {
                return item.value === _this5.get('dataUpdate').toString();
            });
        }),
        tariffChangeSelected: _ember['default'].computed('tariffChange', function () {
            var _this6 = this;

            return this.get('yesNoToFilter').find(function (item) {
                return item.value === _this6.get('tariffChange').toString();
            });
        }),
        cessionSelected: _ember['default'].computed('cession', function () {
            var _this7 = this;

            return this.get('yesNoToFilter').find(function (item) {
                return item.value === _this7.get('cession').toString();
            });
        }),
        // signingPlace
        signingPlaceToSelected: _ember['default'].computed('signingPlace', function () {
            var _this8 = this;

            var allCategories = this.get('dictionariesManager').getMainDictionary('signing_place');
            return allCategories.find(function (item) {
                return item.get('id') === _this8.get('signingPlace');
            });
        }),
        isSearchedRenouncementSelected: _ember['default'].computed('isSearchedRenouncement', function () {
            var _this9 = this;

            return this.get('yesNoToFilter').find(function (item) {
                return item.value === _this9.get('isSearchedRenouncement').toString();
            });
        }),

        counterToFilter: _ember['default'].computed(function () {
            return this._makeCounter(1, 17);
        }),
        counterToSelected: _ember['default'].computed('counter', function () {
            var _this10 = this;

            var types = this.get('counterToFilter');
            return types.find(function (item) {
                return item.value === _this10.get('counter');
            });
        }),

        renouncementStatusToFilter: [{ label: 'skuteczne', value: 's' }, { label: 'nieskuteczne', value: 'n' }, { label: 'brak', value: 'b' }],

        renouncementStatusToSelected: _ember['default'].computed('renouncementStatus', function () {
            var _this11 = this;

            var allCategories = this.get('dictionariesManager').getMainDictionary('renouncement_status');
            return allCategories.find(function (item) {
                return item.get('id') === _this11.get('renouncementStatus');
            });
        }),

        whichSellerChangeSelected: _ember['default'].computed('whichSellerChange', function () {
            var _this12 = this;

            var allCategories = this.get('dictionariesManager').getMainDictionary('seller_change');
            return allCategories.find(function (item) {
                return item.get('id') === _this12.get('whichSellerChange');
            });
        }),

        eeTariffGroupSelected: _ember['default'].computed('eeTariffGroup', function () {
            var _this13 = this;

            var allCategories = this.get('dictionariesManager').getMainDictionary('individual_ee_tariff_group');
            return allCategories.find(function (item) {
                return item.get('id') === _this13.get('eeTariffGroup');
            });
        }),

        gasTariffGroupSelected: _ember['default'].computed('gasTariffGroup', function () {
            var _this14 = this;

            var allCategories = this.get('dictionariesManager').getMainDictionary('individual_gas_tariff_group');
            return allCategories.find(function (item) {
                return item.get('id') === _this14.get('gasTariffGroup');
            });
        }),

        yesNoToFilter: [{ label: 'tak', value: 'true' }, { label: 'nie', value: 'false' }],

        yesNoNullToFilter: [{ label: 'tak', value: 'true' }, { label: 'nie', value: 'false' }, { label: 'bez wartości', value: null }],

        typeToFilter: [{ label: 'EE', value: 'ee' }, { label: 'Gaz', value: 'gas' }],
        typeToSelected: _ember['default'].computed('type', function () {
            var _this15 = this;

            var types = this.get('typeToFilter');
            return types.find(function (item) {
                return item.value === _this15.get('type');
            });
        }),

        productToSelected: _ember['default'].computed('product', function () {
            var _this16 = this;

            var allCategories = this.get('dictionariesManager').getMainDictionary('individual_product');
            return allCategories.find(function (item) {
                return item.get('id') === _this16.get('product');
            });
        }),

        batchActions: [{ label: 'Zmień status', value: 'batchStatus' }, { label: 'Zmień Przedstawiciela Handlowego', value: 'batchPh' }, { label: 'Zmień Opiekuna TPA', value: 'batchTpa' }, { label: 'Zmień status Verification Call', value: 'batchVc' }, { label: 'Zmień datę startu', value: 'batchStart' }, { label: 'Zmień rabat', value: 'batchDiscount' }, { label: 'Dodaj komentarz', value: 'batchComment' }, { label: 'Wygeneruj i pobierz Usys', value: 'batchUsys' }],

        isAllowedToSettings: (function () {
            return this.get('TheUser').isGranted(['ROLE_ADMIN', 'ROLE_DZU2', 'ROLE_DG']);
        }).property(),

        isAllowedToAddAsTpa: (function () {
            return this.get('TheUser').isGranted(['ROLE_ADMIN', 'ROLE_DZU2']);
        }).property(),

        isAllowedToModifyDate: (function () {
            return !this.get('TheUser').isMainRoleIn(['ROLE_GDPR_SPECIALIST']);
        }).property(),

        isAllowedToModifyContractParameters: (function () {
            return !this.get('TheUser').isMainRoleIn(['ROLE_GDPR_SPECIALIST']);
        }).property(),

        isAllowedToModifyRecordingVC: (function () {
            return !this.get('TheUser').isMainRoleIn(['ROLE_GDPR_SPECIALIST']);
        }).property(),

        isAllowedToPikaSync: (function () {
            return !this.get('TheUser').isMainRoleIn(['ROLE_GDPR_SPECIALIST']);
        }).property(),

        isAllowedToMark: (function () {
            return !this.get('TheUser').isMainRoleIn(['ROLE_GDPR_SPECIALIST']);
        }).property(),

        isAllowedToValidate: (function () {
            return !this.get('TheUser').isMainRoleIn(['ROLE_GDPR_SPECIALIST']);
        }).property(),

        isAllowedToGenerateUsys: (function () {
            return !this.get('TheUser').isMainRoleIn(['ROLE_GDPR_SPECIALIST']);
        }).property(),

        isAllowedToEditStatusTPA: (function () {
            return !this.get('TheUser').isMainRoleIn(['ROLE_GDPR_SPECIALIST']);
        }).property(),

        isAllowedToAddAsPartner: (function () {
            return this.get('TheUser').isGranted(['ROLE_ADMIN']) || this.get('TheUser').isGranted(['ROLE_SALES']) && this.get('TheUser.emberModel.duonPartner.isIndividual');
        }).property(),

        isAllowedToAnonymizationExport: (function () {
            return true;
        }).property(),

        displayViewForPartners: (function () {
            return this.get('TheUser').isGranted(['ROLE_SALES']) && !this.get('TheUser').isGranted('ROLE_ADMIN');
        }).property(),

        partnersToFilter: [],
        _initPartnersToFilter: function _initPartnersToFilter() {
            var _this = this;
            this.get('store').peekAll('DuonPartnerDictionaryEntry').forEach(function (item) {
                if (item.get('isHidden') !== true && (item.get('partnerType') === 2 || item.get('partnerType') === 3)) {
                    _this.get('partnersToFilter').push({
                        value: item.get('id'),
                        label: '#' + item.get('idPartner') + ' ' + item.get('label')
                    });
                }
            });
        },
        partnersToSelected: _ember['default'].computed('ph', function () {
            var content = this.get('partnersToFilter'),
                selectedIds = this.get('ph').split(',');
            return content.filter(function (item) {
                return selectedIds.includes(item.value);
            });
        }),
        partnersAcquiredToSelected: _ember['default'].computed('phAcquired', function () {
            var _this17 = this;

            return this.get('partnersToFilter').filter(function (item) {
                return _this17.get('phAcquired').split(',').includes(item.value);
            });
        }),

        tpaSpecialistsToFilter: [],
        _initTpaSpecialistsToFilter: function _initTpaSpecialistsToFilter() {
            var _this18 = this;

            this.get('dictionariesManager').getUsersDictionary('dzu2_users_by_user').forEach(function (item) {
                _this18.get('tpaSpecialistsToFilter').push({
                    value: item.id,
                    label: item.fullNameWithRole
                });
            });
        },
        tpaSpecialistsToSelected: _ember['default'].computed('tpaSpecialist', function () {
            var _this19 = this;

            return this.get('tpaSpecialistsToFilter').filter(function (item) {
                return _this19.get('tpaSpecialist').split(',').includes(item.value);
            });
        }),
        _initStatusesToFilter: function _initStatusesToFilter() {
            var _this20 = this;

            var dicManager = this.get('dictionariesManager');
            dicManager.getStatusCollection('individual_contract').forEach(function (item) {
                _this20.get('statusesToFilter').push({ value: item.get('id'), label: item.get('numberAndName') });
            });
        },
        statusesToFilter: [],
        statusesToSelected: _ember['default'].computed('status', function () {
            var content = this.get('statusesToFilter'),
                selectedIds = this.get('status').split(',');
            return content.filter(function (item) {
                return selectedIds.includes(item.value);
            });
        }),

        _initStatusesBoToFilter: function _initStatusesBoToFilter() {
            var _this21 = this;

            var dicManager = this.get('dictionariesManager');
            dicManager.getStatusCollection('visible_for_BO').forEach(function (item) {
                _this21.get('statusesBoToFilter').push({ value: item.get('uuid'), label: item.get('name') });
            });
        },

        statusesBoToFilter: [],
        statusesBoToSelected: _ember['default'].computed('mainStatusBo', function () {
            var content = this.get('statusesBoToFilter'),
                selectedIds = this.get('mainStatusBo').split(',');
            return content.filter(function (item) {
                return selectedIds.includes(item.value);
            });
        }),

        _initStatusesPartnerToFilter: function _initStatusesPartnerToFilter() {
            var _this22 = this;

            var dicManager = this.get('dictionariesManager');
            dicManager.getStatusCollection('set_by_partner').forEach(function (item) {
                _this22.get('statusesPartnerToFilter').push({ value: item.get('uuid'), label: item.get('name') });
            });
        },

        statusesPartnerToFilter: [],
        statusesPartnerToSelected: _ember['default'].computed('statusByPartner', function () {
            var content = this.get('statusesPartnerToFilter'),
                selectedIds = this.get('statusByPartner').split(',');
            return content.filter(function (item) {
                return selectedIds.includes(item.value);
            });
        }),

        _initMainStatusesPartnerToFilter: function _initMainStatusesPartnerToFilter() {
            var _this23 = this;

            var dicManager = this.get('dictionariesManager');
            dicManager.getStatusCollection('visible_for_partner').forEach(function (item) {
                _this23.get('statusesMainPartnerToFilter').push({ value: item.get('uuid'), label: item.get('name') });
            });
        },

        statusesMainPartnerToFilter: [],
        statusesMainPartnerToSelected: _ember['default'].computed('mainStatusPartner', function () {
            var content = this.get('statusesMainPartnerToFilter'),
                selectedIds = this.get('mainStatusPartner').split(',');
            return content.filter(function (item) {
                return selectedIds.includes(item.value);
            });
        }),

        _initProductsToFilter: function _initProductsToFilter() {
            var _this24 = this;

            var dicManager = this.get('dictionariesManager');
            dicManager.getMainDictionary('individual_product').forEach(function (item) {
                _this24.get('productsToFilter').push({ value: item.get('id'), label: item.get('label') });
            });
        },

        productsToFilter: [],
        productsToSelected: _ember['default'].computed('product', function () {
            var content = this.get('productsToFilter'),
                selectedIds = this.get('product').split(',');
            return content.filter(function (item) {
                return selectedIds.includes(item.value);
            });
        }),

        productContent: (function () {
            var products = [];
            this.get('products').forEach(function (product) {
                products.push({ label: product.get('name'), value: product.get('uuid') });
            });

            return products;
        }).property(),

        overnightBoExportPrimary: _ember['default'].computed(function () {
            return _emberNewUtilsUtils['default'].generateSimpleUrl('/export/individual/overnight/bo-primary', '?', _emberNewConfigEnvironment['default'], this.get('TheUser'), this.get('cookies'));
        }),

        overnightBoExportSecondary: _ember['default'].computed(function () {
            return _emberNewUtilsUtils['default'].generateSimpleUrl('/export/individual/overnight/bo-secondary', '?', _emberNewConfigEnvironment['default'], this.get('TheUser'), this.get('cookies'));
        }),

        overnightBoDedicatedExportPrimary: _ember['default'].computed(function () {
            return _emberNewUtilsUtils['default'].generateSimpleUrl('/export/individual/overnight/bo-dedicated-primary', '?', _emberNewConfigEnvironment['default'], this.get('TheUser'), this.get('cookies'));
        }),

        overnightBoDedicatedExportSecondary: _ember['default'].computed(function () {
            return _emberNewUtilsUtils['default'].generateSimpleUrl('/export/individual/overnight/bo-dedicated-secondary', '?', _emberNewConfigEnvironment['default'], this.get('TheUser'), this.get('cookies'));
        }),

        actions: {
            filterBy: function filterBy(filterName, selection) {
                var val = selection ? selection.value : '';
                this.set(filterName, val);
            },
            dictionaryFilterBy: function dictionaryFilterBy(filterName, selection) {
                var val = selection ? selection.get('value') : '';
                this.set(filterName, val);

                if (filterName === 'gasTariffGroup') {
                    this.set('eeTariffGroup', '');
                }
                if (filterName === 'eeTariffGroup') {
                    this.set('gasTariffGroup', '');
                }
            },

            refresh: function refresh() {
                this.send('refreshRoute');
            },
            pikaSync: function pikaSync() {
                this.transitionToRoute('individual-contract.pika-sync');
            },

            batchStatus: function batchStatus() {
                this.transitionToRoute('individual-contract.batch.status');
            },
            batchPh: function batchPh() {
                this.transitionToRoute('individual-contract.batch.ph');
            },
            batchVc: function batchVc() {
                this.transitionToRoute('individual-contract.batch.vc');
            },
            batchUsys: function batchUsys() {
                this.transitionToRoute('individual-contract.batch.usys');
            },
            batchTpa: function batchTpa() {
                this.transitionToRoute('individual-contract.batch.tpa');
            },
            batchStart: function batchStart() {
                this.transitionToRoute('individual-contract.batch.start');
            },
            batchDiscount: function batchDiscount() {
                this.transitionToRoute('individual-contract.batch.discount');
            },
            batchComment: function batchComment() {
                this.transitionToRoute('individual-contract.batch.comment');
            },
            openModalSetAcceptDate: function openModalSetAcceptDate() {
                this.transitionToRoute('individual-contract.setAcceptDate');
            },
            openModalChangeContractParameters: function openModalChangeContractParameters() {
                this.transitionToRoute('individual-contract.changeContractParameters');
            },
            openModalExportFiltered: function openModalExportFiltered() {
                this.transitionToRoute('individual-contract.exportFiltered');
            },
            openModalSetVcStatus: function openModalSetVcStatus() {
                this.transitionToRoute('individual-contract.setVcStatus');
            },
            changeFilterForFirstSellerAction: function changeFilterForFirstSellerAction(contracts) {
                var _this = this;
                this.get('queryParams').forEach(function (p) {
                    if (!['itemsPerPage', 'sortBy', 'page'].includes(p)) {
                        _this.set(p, '');
                    }
                    _this.set('contractNo', contracts);
                });
            }
        },

        isAnonymizedToSelected: _ember['default'].computed('isAnonymized', function () {
            var _this25 = this;

            return this.get('yesNoToFilter').find(function (item) {
                return item.value === _this25.get('isAnonymized');
            });
        })

    });

    // onlyAgreementsWithFirstSellerSelected: Ember.computed('onlyAgreementsWithFirstSeller', function () {
    //     return this.get('yesNoToFilter').find(item => item.value === this.get('onlyAgreementsWithFirstSeller'));
    // }),

    exports['default'] = IndividualClientController;
});
define('ember-new/models/duon-package-product-dictionary-entry', ['exports', 'ember-new/models/abstract'], function (exports, _emberNewModelsAbstract) {

    var DuonPackageProductDictionaryEntry = _emberNewModelsAbstract['default'].extend({
        label: DS.attr('string'),
        name: DS.attr('string'),
        hasDedykowanaObsluga: DS.attr('boolean'),
        isHidden: DS.attr('boolean')
    });

    exports['default'] = DuonPackageProductDictionaryEntry;
});
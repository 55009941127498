define("ember-new/routes/renouncement/show/history", ["exports", "ember-new/routes/authenticated", "ember"], function (exports, _emberNewRoutesAuthenticated, _ember) {
    exports["default"] = _emberNewRoutesAuthenticated["default"].extend({

        model: function model(params) {
            var renouncement = this.modelFor('renouncement.show');
            params['renouncementUuid'] = renouncement.get('uuid');

            return _ember["default"].RSVP.hash({
                history: this.get('store').query('renouncement-entity-change-view-model', params),
                authors: this.get('store').query('renouncement-change-authors-view-model', { uuid: renouncement.get('uuid') }, { reload: true })
            });
        },

        setupController: function setupController(ctrl, model) {
            ctrl.set('authors', model.authors);
            ctrl.set('model', model.history);
        }
    });
});
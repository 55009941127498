define('ember-new/components/task-favourite', ['exports', 'ember-new/components/amb-forms', 'ember-new/form/row/005-date-field-row', 'ember-new/form/row/gas-overhead-select-field-row', 'ember-new/form/row/000-field-row', 'ember-new/views/field/textarea-field', 'ember'], function (exports, _emberNewComponentsAmbForms, _emberNewFormRow005DateFieldRow, _emberNewFormRowGasOverheadSelectFieldRow, _emberNewFormRow000FieldRow, _emberNewViewsFieldTextareaField, _ember) {

	var TaskFavouriteComponent = _emberNewComponentsAmbForms['default'].extend({
		formDefinition: [_emberNewFormRow005DateFieldRow['default'].create({ key: "favouriteSalesmanSigningExpectedTime", label: "Przewidywana data podpisania umowy" }), _emberNewFormRow000FieldRow['default'].create({ key: "favouriteSalesmanComment", label: "Komentarz", fieldView: _emberNewViewsFieldTextareaField['default'] })],
		action: 'favourite',
		//layoutName: 'components/main-data-client',
		hasViewState: false,
		layoutName: 'ambforms/layout-notswitchable',
		formName: 'acquisitionProcess',

		afterUpdate: function afterUpdate(promise) {
			var _this = this;
			promise.then(function (response) {
				_this.get('targetObject').get('target').send('refreshModel');
				alert('Dodano gwiazdkę.');
				_this.get('targetObject').transitionToRoute('tasks');
			});
		}
	});

	exports['default'] = TaskFavouriteComponent;
});
define('ember-new/routes/tasks/ee-acquisition/update', ['exports', 'ember-new/routes/authenticated', 'ember'], function (exports, _emberNewRoutesAuthenticated, _ember) {
	exports['default'] = _emberNewRoutesAuthenticated['default'].extend({
		applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),

		afterModel: function afterModel(model, transition) {
			if (model.get('isOpen') !== true && model.get('isAgreement') !== true) {
				this.get('applicationAlerts').addActionMessage('danger', 'Nie można edytować zamkniętych procesów akwizycyjnych.');
				this.transitionTo('tasks');
				return false;
			}
		},

		actions: {
			refreshModel: function refreshModel() {
				this.store.unloadAll('eeAcquisitionProcess');
				this.refresh();
			}
		}
	});
});
define("ember-new/templates/b2b-contract/dzu/partials/contract/client-category", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 5,
                    "column": 12
                  },
                  "end": {
                    "line": 7,
                    "column": 12
                  }
                },
                "moduleName": "ember-new/templates/b2b-contract/dzu/partials/contract/client-category.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "d-field-text", [], ["type", "hidden", "key", "clientUuid"], ["loc", [null, [6, 16], [6, 63]]]]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 8,
                    "column": 12
                  },
                  "end": {
                    "line": 10,
                    "column": 12
                  }
                },
                "moduleName": "ember-new/templates/b2b-contract/dzu/partials/contract/client-category.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "d-field-switch", [], ["key", "isFrozen", "value", ["subexpr", "@mut", [["get", "isFrozen", ["loc", [null, [9, 54], [9, 62]]]]], [], []]], ["loc", [null, [9, 16], [9, 64]]]]],
              locals: [],
              templates: []
            };
          })();
          var child2 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 11,
                    "column": 12
                  },
                  "end": {
                    "line": 13,
                    "column": 12
                  }
                },
                "moduleName": "ember-new/templates/b2b-contract/dzu/partials/contract/client-category.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "d-field-select", [], ["content", ["subexpr", "@mut", [["get", "clientCategory", ["loc", [null, [12, 41], [12, 55]]]]], [], []], "key", "category", "disabled", ["subexpr", "not", [["get", "isFrozen", ["loc", [null, [12, 85], [12, 93]]]]], [], ["loc", [null, [12, 80], [12, 94]]]]], ["loc", [null, [12, 16], [12, 96]]]]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 3,
                  "column": 8
                },
                "end": {
                  "line": 14,
                  "column": 8
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/dzu/partials/contract/client-category.hbs"
            },
            isEmpty: false,
            arity: 2,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "d-form-row", [], ["hidden", true], 0, null, ["loc", [null, [5, 12], [7, 27]]]], ["block", "d-form-row", [], ["label", "Zamrożona kategoria klienta"], 1, null, ["loc", [null, [8, 12], [10, 27]]]], ["block", "d-form-row", [], ["label", "Kategoria Klienta"], 2, null, ["loc", [null, [11, 12], [13, 27]]]]],
            locals: ["formData", "formContents"],
            templates: [child0, child1, child2]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 4
              },
              "end": {
                "line": 15,
                "column": 4
              }
            },
            "moduleName": "ember-new/templates/b2b-contract/dzu/partials/contract/client-category.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "d-form-command", [], ["commandName", "EditClientCategoryProcedure", "afterSuccessfulCommand", ["subexpr", "@mut", [["get", "afterCommand", ["loc", [null, [3, 91], [3, 103]]]]], [], []], "fillCommandModel", ["subexpr", "@mut", [["get", "fillFormClientCategory", ["loc", [null, [4, 43], [4, 65]]]]], [], []]], 0, null, ["loc", [null, [3, 8], [14, 27]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 18,
                  "column": 16
                },
                "end": {
                  "line": 18,
                  "column": 55
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/dzu/partials/contract/client-category.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("tak");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 18,
                  "column": 55
                },
                "end": {
                  "line": 18,
                  "column": 66
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/dzu/partials/contract/client-category.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("nie");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 22,
                  "column": 16
                },
                "end": {
                  "line": 22,
                  "column": 85
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/dzu/partials/contract/client-category.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["content", "model.clientCategoryEeVolume", ["loc", [null, [22, 53], [22, 85]]]]],
            locals: [],
            templates: []
          };
        })();
        var child3 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 22,
                  "column": 85
                },
                "end": {
                  "line": 22,
                  "column": 98
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/dzu/partials/contract/client-category.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode(" brak");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child4 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 25,
                  "column": 16
                },
                "end": {
                  "line": 25,
                  "column": 87
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/dzu/partials/contract/client-category.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["content", "model.clientCategoryGasVolume", ["loc", [null, [25, 54], [25, 87]]]]],
            locals: [],
            templates: []
          };
        })();
        var child5 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 25,
                  "column": 87
                },
                "end": {
                  "line": 25,
                  "column": 100
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/dzu/partials/contract/client-category.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode(" brak");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 15,
                "column": 4
              },
              "end": {
                "line": 28,
                "column": 4
              }
            },
            "moduleName": "ember-new/templates/b2b-contract/dzu/partials/contract/client-category.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("ul");
            dom.setAttribute(el1, "class", "todo-list");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("Zamrożona kategoria Klienta: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("Kategoria Klienta: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("Suma wolumenów EE: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("Suma wolumenów GAZ: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 0]), 2, 2);
            morphs[1] = dom.createMorphAt(dom.childAt(element0, [3, 0]), 1, 1);
            morphs[2] = dom.createMorphAt(dom.childAt(element0, [5, 0]), 2, 2);
            morphs[3] = dom.createMorphAt(dom.childAt(element0, [7, 0]), 2, 2);
            return morphs;
          },
          statements: [["block", "if", [["get", "model.isClientCategoryFrozen", ["loc", [null, [18, 22], [18, 50]]]]], [], 0, 1, ["loc", [null, [18, 16], [18, 73]]]], ["content", "model.clientCategoryInfo", ["loc", [null, [20, 71], [20, 99]]]], ["block", "if", [["get", "model.clientCategoryEeVolume", ["loc", [null, [22, 22], [22, 50]]]]], [], 2, 3, ["loc", [null, [22, 16], [22, 105]]]], ["block", "if", [["get", "model.clientCategoryGasVolume", ["loc", [null, [25, 22], [25, 51]]]]], [], 4, 5, ["loc", [null, [25, 16], [25, 107]]]]],
          locals: [],
          templates: [child0, child1, child2, child3, child4, child5]
        };
      })();
      return {
        meta: {
          "fragmentReason": {
            "name": "missing-wrapper",
            "problems": ["wrong-type"]
          },
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 29,
              "column": 0
            }
          },
          "moduleName": "ember-new/templates/b2b-contract/dzu/partials/contract/client-category.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "editState", ["loc", [null, [2, 10], [2, 19]]]]], [], 0, 1, ["loc", [null, [2, 4], [28, 11]]]]],
        locals: ["editState"],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.3.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 29,
            "column": 17
          }
        },
        "moduleName": "ember-new/templates/b2b-contract/dzu/partials/contract/client-category.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "box-for-form", [], ["title", "Kategoria klienta", "isAllowedEdit", true], 0, null, ["loc", [null, [1, 0], [29, 17]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});
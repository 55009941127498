define('ember-new/mixins/b2b-contract/controllers/edit-contract-vas', ['exports', 'ember', 'ember-new/config/environment'], function (exports, _ember, _emberNewConfigEnvironment) {
    exports['default'] = _ember['default'].Mixin.create({

        applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),

        overheadTypeForMMContent: [{ label: 'Automatyczny', value: 'auto' }, { label: 'Stały', value: 'const' }],

        inclusionComponentValues: [{ label: 'EE – Energia elektryczna/MWH ', value: 'ee_mwh' }, { label: 'EE – Opłata handlowa/m-c', value: 'ee_trade_fee' }, { label: 'GAZ – Paliwo/MWH', value: 'gas_fuel_mwh' }, { label: 'GAZ – Abonament/m-c', value: 'gas_subscription' }],

        mediumValues: [{ label: 'EE', value: 'ee' }, { label: 'Gaz', value: 'gas' }],

        inclusionTypeValues: [{ label: 'Zniżka', value: 'discount' }, { label: 'Zwyżka', value: 'increase' }],

        valueTypeValues: [{ label: 'Złotówki', value: 'PLN' }, { label: 'Procent', value: 'percent' }],

        overheadRatePerPercentPointVisibility: false,
        valueStep: 0.1,

        isConstOverheadTypeForMM: true,
        isDiscount: false,

        fillForm: function fillForm(form) {
            form.set('model.id', this.get('model.id'));
            form.set('model.name', this.get('model.name'));
            form.set('model.inclusionComponent', this.get('model.inclusionComponent'));
            form.set('model.idUsys', this.get('model.idUsys'));
            form.set('model.inclusionType', this.get('model.inclusionType'));
            form.set('model.valueType', this.get('model.valueType'));
            form.set('model.overheadRatePerPercentPoint', this.get('model.overheadRatePerPercentPoint'));
            form.set('model.baseValue', this.get('model.baseValue'));
            form.set('model.sellValue', this.get('model.sellValue'));
            form.set('model.overheadTypeForMM', this.get('model.overheadTypeForMM'));
            form.set('model.overheadForMM', this.get('model.overheadForMM'));
        },

        afterCommandEe: function afterCommandEe() {
            var targetController = this;

            var mainRoute = targetController.container.lookup("route:b2b-contract.dzu.show-ee.vas");

            mainRoute.send('refreshModel');

            setTimeout(function () {
                targetController.transitionToRoute('b2b-contract.dzu.show-ee.vas');
            }, 100);
        },

        afterCommandGas: function afterCommandGas() {
            var targetController = this;
            var mainRoute = targetController.container.lookup("route:b2b-contract.dzu.show-gas.vas");

            mainRoute.send('refreshModel');

            setTimeout(function () {
                targetController.transitionToRoute('b2b-contract.dzu.show-gas.vas');
            }, 100);
        }

    });
});
define('ember-new/controllers/b2b-contract/dzu/show-gas/ppg', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        actions: {
            showDetails: function showDetails(params) {
                this.transitionToRoute('b2b-contract.dzu.show-gas.ppg.details', params.get('uuid'));
            }
        }

    });
});
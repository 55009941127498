define('ember-new/routes/b2b-contract/dzu/change-departments-specialists-dr', ['exports', 'ember-new/routes/authenticated'], function (exports, _emberNewRoutesAuthenticated) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({
        model: function model(params) {
            return this.store.findRecord('ContractContractDzuViewModel', params.contractId);
        },

        setupController: function setupController(controller, model) {
            controller.set('model', model);
        }
    });
});
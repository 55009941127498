define('ember-new/routes/individual-contract/settings/products', ['exports', 'ember-new/routes/authenticated'], function (exports, _emberNewRoutesAuthenticated) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({
        setupController: function setupController(ctrl, model) {
            var products = this.store.peekAll('individual-product');
            var eeProductLines = this.store.peekAll('individual-ee-product-line');
            var gasProductLines = this.store.peekAll('individual-gas-product-line');

            ctrl.set('products', products);
            ctrl.set('eeProductLines', eeProductLines);
            ctrl.set('gasProductLines', gasProductLines);
        }
    });
});
define('ember-new/helpers/eq', ['exports', 'ember'], function (exports, _ember) {

  /**
   * Helper, który sprawdza czy porównywane wartości sa równe
   * Uzycie: {{#if (eq contract.type "ee")}} .... {{/if}}
   * @param params do porównania
   */
  var eq = function eq(params) {
    return params[0] === params[1];
  };
  exports['default'] = _ember['default'].Helper.helper(eq);
});
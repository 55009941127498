define('ember-new/components/d-form/b2b-contract/dzu/add-vas-contract', ['exports', 'ember-new/components/d-form-command', 'ember-new/templates/components/d-form'], function (exports, _emberNewComponentsDFormCommand, _emberNewTemplatesComponentsDForm) {
    exports['default'] = _emberNewComponentsDFormCommand['default'].extend({
        layout: _emberNewTemplatesComponentsDForm['default'],

        commandName: 'AddVasToContractCommand',
        medium: null,

        afterSuccessfulSubmit: function afterSuccessfulSubmit() {
            var targetController = this.get('targetObject');
            var mainController = targetController.container.lookup('controller:b2b-contract.dzu.show-' + this.medium);
            var mainRoute = mainController.get('target');
            mainRoute.send('refreshModel');
            var medium = this.get('medium');
            setTimeout(function () {
                targetController.transitionToRoute('b2b-contract.dzu.show-'.medium);
            }, 500);
        }
    });
});
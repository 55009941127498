define("ember-new/routes/individual-contract-price-lists/template/conditions", ["exports", "ember", "ember-new/routes/authenticated"], function (exports, _ember, _emberNewRoutesAuthenticated) {
    exports["default"] = _emberNewRoutesAuthenticated["default"].extend({

        standard: null,
        promo: null,

        beforeModel: function beforeModel(transition) {
            window.scrollTo(0, 0); // scroll to the top of application before reloading
        },

        model: function model(params) {

            var standardParams = { "templateUuid": params.templateUuid, "type": 'S' };
            var promoParams = { "templateUuid": params.templateUuid, "type": 'P' };

            var route = this;

            return _ember["default"].RSVP.hash({
                template: route.get('store').findRecord('IndividualContractTemplateListViewModel', params.templateUuid, { reload: true }),
                standard: route.get('store').query('IndividualContractTemplateConditionListViewModel', standardParams, { reload: true }),
                promo: route.get('store').query('IndividualContractTemplateConditionListViewModel', promoParams, { reload: true })
            });
        },

        actions: {
            refreshModelAction: function refreshModelAction() {
                this.store.unloadAll('IndividualContractTemplateConditionListViewModel');
                this.refresh();
            }

        }

    });
});
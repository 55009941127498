define('ember-new/components/d-field-select-dictionary-uuid-multiple', ['exports', 'ember', 'ember-new/components/d-field-select-dictionary-uuid'], function (exports, _ember, _emberNewComponentsDFieldSelectDictionaryUuid) {
    exports['default'] = _emberNewComponentsDFieldSelectDictionaryUuid['default'].extend({
        multiple: true,
        _preventValueObserver: true,
        prompt: '',

        actions: {
            setSelection: function setSelection(event) {
                var elementId = this.get('elementId');
                var select2 = $('#'.concat(elementId, ' select'));
                var values = select2.val();

                this.set('value', values);
            }
        }
    });
});
define('ember-new/components/bs-text', ['exports', 'ember'], function (exports, _ember) {

    var Component = _ember['default'].Component.extend({
        tagName: 'div',
        type: 'info',

        classNameBindings: ['extraClasses', 'typeClass'],
        extraClasses: null,
        typeClass: (function () {
            return 'text-' + this.get('type');
        }).property('type'),
        iconClass: (function () {
            switch (this.get('type')) {
                case 'danger':
                    return 'fa fa-ban';
                    break;
                case 'warning':
                    return 'fa fa-warning';
                    break;
                case 'success':
                    return 'fa fa-check';
                    break;
                case 'info':default:
                    return 'fa fa-ban';
                    break;
            }
        }).property('type')
    });

    exports['default'] = Component;
});
/* http://getbootstrap.com/css/?#helper-classes-colors */
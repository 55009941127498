define('ember-new/controllers/b2b-contract/dzu/show-gas/client/remove-representative', ['exports', 'ember', 'ember-new/config/environment'], function (exports, _ember, _emberNewConfigEnvironment) {
    exports['default'] = _ember['default'].Controller.extend({
        ajax: _ember['default'].inject.service('ajax'),
        applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),

        actions: {
            'delete': function _delete() {
                var _this = this;

                var serializedData = {
                    removeRepresentativePersonCommand: {
                        idPerson: this.get('person'),
                        contractUuid: this.get('contract')
                    }
                };

                this.get('ajax').ajaxPost(_emberNewConfigEnvironment['default'].apiUrl + '/rest/removerepresentativepersoncommands', serializedData).then(function () {
                    _this.get('clientModel').reload();
                    _this.get('applicationAlerts').addActionMessage('success', "Osoba została usunięta");
                    _this.transitionToRoute('b2b-contract.dzu.show-gas.client');
                }, function () {
                    _this.get('applicationAlerts').addActionMessage('warning', "Wystąpił błąd, nie udało się usunąć osoby");
                });
            }
        }
    });
});
define('ember-new/routes/individual-contract/settings/business-rate', ['exports', 'ember-new/routes/authenticated', 'ember'], function (exports, _emberNewRoutesAuthenticated, _ember) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({
        model: function model() {

            return _ember['default'].RSVP.all([this.store.findAll('individualEeBusinessRate'), this.store.findAll('individualGasBusinessRate')]);
        },
        setupController: function setupController(ctrl, model) {
            ctrl.set('eeRates', model[0]);
            ctrl.set('gasRates', model[1]);
        }
    });
});
define('ember-new/controllers/reports', ['exports', 'ember-new/config/environment', 'ember'], function (exports, _emberNewConfigEnvironment, _ember) {

    var ReportsController = _ember['default'].Controller.extend({

        salesVisible: false,
        volumeVisible: false,
        volumeSalesmenVisible: false,
        acquisitionVisible: false,
        ccVisible: false,
        ratingsVisible: false,
        salesmanagersVisible: false,
        weeklyMeetingsReportVisible: false,

        volumeSubmenuVisible: false,
        salesSubmenuVisible: false,
        verificationMeetingsVisible: false,

        onInit: (function () {

            var userModel = this.get('TheUser').emberModel;
            var ratingsVisible = false;
            var hasPrivsToSeeReportsInTra = this.get('TheUser').isGranted(['ROLE_DZU_MANAGER', 'ROLE_CC_HEADMANAGER', 'ROLE_AUDYTY_MANAGER', 'ROLE_CENTRALA', 'ROLE_HR', 'ROLE_SALES_SUPPORT', 'ROLE_DZU2_SUPERVISOR']) || this.get('TheUser').isGranted('ROLE_SALES') && !userModel.get('isFromPartnerRegion');
            if (_emberNewConfigEnvironment['default'].applicationType === 'DSP') {
                ratingsVisible = hasPrivsToSeeReportsInTra;
            } else {
                ratingsVisible = this.get('TheUser').isGranted(['ROLE_ADMIN', 'ROLE_CENTRALA', 'ROLE_DG', 'ROLE_DE', 'ROLE_SALES_SUPPORT', 'ROLE_MANAGEMENT']);
            }

            this.set('ratingsVisible', ratingsVisible);

            if (this.get('TheUser').isGranted(['ROLE_SALES', 'ROLE_DZU_MANAGER', 'ROLE_CC_HEADMANAGER', 'ROLE_AUDYTY_MANAGER', 'ROLE_CENTRALA', 'ROLE_HR', 'ROLE_SALES_SUPPORT', 'ROLE_DZU2_SUPERVISOR'])) {
                this.set('salesVisible', true);
                this.set('volumeVisible', true);
                this.set('acquisitionVisible', true);
                this.set('ccVisible', true);
            } else if (this.get('TheUser').isMainRoleIn(['ROLE_CC_MANAGER'])) {
                this.set('ccVisible', true);
            } else if (this.get('TheUser').isMainRoleIn(['ROLE_DZU2_SPECIALIST', 'ROLE_DZU2_MANAGER', 'ROLE_DZU_DZU2_MANAGER'])) {
                this.set('salesVisible', true);
                this.set('volumeVisible', true);
            }

            if (this.get('TheUser').isGranted(['ROLE_DZU_OFFICE'])) {
                this.set('volumeVisible', true);
            }

            if (this.get('TheUser').isGranted(['ROLE_SALES_MANAGER', 'ROLE_DZU_MANAGER', 'ROLE_CC_HEADMANAGER', 'ROLE_AUDYTY_MANAGER', 'ROLE_CENTRALA', 'ROLE_DZU2_SUPERVISOR'])) {
                this.set('salesmanagersVisible', true);
            }

            if (this.get('TheUser').isGranted(['ROLE_SALES_TEST_MANAGER'])) {
                this.set('volumeSalesmenVisible', true);
            }

            if (this.get('TheUser').isGranted(['ROLE_SALES_MANAGER', 'ROLE_SALES_HEADMANAGER', 'ROLE_MANAGEMENT'])) {
                this.set('weeklyMeetingsReportVisible', true);
            }

            if (_emberNewConfigEnvironment['default'].applicationType == "DMT") {
                this.set('acquisitionVisible', false);
                this.set('ccVisible', false);
            }

            if (this.get('TheUser').isGranted(['ROLE_SALES'])) {
                this.set('verificationMeetingsVisible', true);
            }
        }).on('init'),

        actions: {
            volumeSubmenuVisibleEnable: function volumeSubmenuVisibleEnable() {
                this.set('volumeSubmenuVisible', true);
                this.set('salesSubmenuVisible', false);
                // this.set('volumeSalesmenVisible', true);
            },
            salesSubMenuVisibleEnable: function salesSubMenuVisibleEnable() {
                this.set('salesSubmenuVisible', true);
                this.set('volumeSubmenuVisible', false);
                // this.set('volumeSalesmenVisible', false);
            }
        }

    });

    exports['default'] = ReportsController;
});
define("ember-new/routes/individual-contract/validate", ["exports", "ember-new/routes/authenticated", "ember"], function (exports, _emberNewRoutesAuthenticated, _ember) {
    exports["default"] = _emberNewRoutesAuthenticated["default"].extend({

        type: null,

        model: function model(params) {
            if (params.individual_contract_type == 'ee') {
                return this.store.findRecord('individual-ee-contract', params.individual_contract_id);
            } else if (params.individual_contract_type == 'gas') {
                return this.store.findRecord('individual-gas-contract', params.individual_contract_id);
            } else {
                throw new _ember["default"].Error("Unknown Contract type: '" + params.individual_contract_type + "'");
            }
        }

    });
});
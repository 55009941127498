define("ember-new/controllers/tasks", ["exports", "ember", "ember-new/config/environment", "ember-new/mixins/paginable-controller", "ember-new/utils/utils"], function (exports, _ember, _emberNewConfigEnvironment, _emberNewMixinsPaginableController, _emberNewUtilsUtils) {
	exports["default"] = _ember["default"].Controller.extend(_emberNewMixinsPaginableController["default"], {

		cookies: _ember["default"].inject.service('cookies'),
		/**
  * BEGIN: Query params
  */
		queryParams: ['page', 'limit', 'order', 'orderDirection', 'taskType', 'taskTarget', 'favourite', 'dateFrom', 'dateTo', 'company', 'salesman', 'coaching', 'contact', 'source', 'tariffGroup', 'gasTariffGroup', 'status', 'margin', 'taskStatus', 'reservation', 'reservationTime', 'verificationMeeting', 'hotDeal', 'includeArchivalTasks'],
		page: 1,
		limit: 10,
		order: 'time',
		orderDirection: 'desc',
		taskType: null,
		taskTarget: null,
		favourite: null,
		dateFrom: null,
		dateTo: null,
		company: null,
		salesman: null,
		coaching: null,
		contact: null,
		source: null,
		taskStatus: null,
		hotDeal: null,
		tariffGroup: null,
		verificationMeeting: null,
		gasTariffGroup: null,
		status: null,
		margin: null,
		reservation: null,
		reservationTime: null,
		includeArchivalTasks: false,
		/**
   * END: Query params
   */
		currentViewMode: 'normal',

		activatedFiltersCount: null, // set in route...
		dateFormat: 'YYYY-MM-DD',

		submenuItems: _ember["default"].A([_ember["default"].Object.create({ id: 'addTaskMenuItem', icon: 'plus', title: 'Dodaj zadanie', action: 'openModalAddTask' }), _ember["default"].Object.create({
			id: 'orderMeetingMenuItem',
			icon: 'shopping-cart',
			title: 'Zamawianie spotkań CC',
			action: 'openModalOrderMeeting'
		}), _ember["default"].Object.create({ id: 'filtersMenuItem', icon: 'filter', title: 'Filtrowanie', action: 'openModalFilters' })]),

		/**
   * observeFiltersCount
   * przy zmianie wartosci activatedFiltersCount aktualizuje 'badge' w submenuItems => filtersMenuItem
   * dzięki temu mamy informacje o aktualnej liczbie aktwnych filtrow w obiekcie menu (badge)
   * */
		observeFiltersCount: (function () {
			var activatedFiltersCount = this.get('activatedFiltersCount');
			this.get('submenuItems').find(function (item) {
				return item.get('id') === 'filtersMenuItem';
			}).set('badge', activatedFiltersCount);
		}).observes('activatedFiltersCount'),

		orderColumns: [{ label: 'Termin', value: 'time' }, { label: 'Doradca', value: 'salesman' }, { label: 'Coaching', value: 'coaching' }, { label: 'Firma', value: 'company' },
		// {label: 'Kontakt', value: 'contact'},
		{ label: 'Źródło', value: 'source' }, { label: 'MM', value: 'margin' }, { label: 'Taryfa', value: 'acqMainTariffGroup' }, { label: 'Status', value: 'status' }, { label: 'Lojalka', value: 'loyalty' }],

		isDateAllFilterActivated: (function () {
			return !this.get('dateTo') && !this.get('dateFrom');
		}).property('dateFrom', 'dateTo'),

		isDatePastFilterActivated: (function () {
			var dateTo = this.get('dateTo');
			if (!dateTo) return false;

			var past = moment().subtract(2, 'days'),
			    currentFilterValue = moment(dateTo, this.get('dateFormat'));

			// porównuje czy ustawiona data w filtrze === przeszle
			return past.isSame(currentFilterValue, 'day') && !this.get('dateFrom');
		}).property('dateFrom', 'dateTo'),

		isDateYesterdayFilterActivated: (function () {
			var dateTo = this.get('dateTo'),
			    dateFrom = this.get('dateFrom');
			if (!dateTo || !dateFrom) return false;

			var yesterday = moment().subtract(1, 'days'),
			    currentDateToFilterValue = moment(dateTo, this.get('dateFormat')),
			    currentDateFromFilterValue = moment(dateFrom, this.get('dateFormat'));

			// porównuje czy ustawiona data w filtrze === wczoraj
			return yesterday.isSame(currentDateToFilterValue, 'day') && yesterday.isSame(currentDateFromFilterValue, 'day');
		}).property('dateFrom', 'dateTo'),

		isDateTodayFilterActivated: (function () {
			var dateTo = this.get('dateTo'),
			    dateFrom = this.get('dateFrom');
			if (!dateTo || !dateFrom) return false;

			var date = moment(),
			    // today
			currentDateToValue = moment(dateTo, this.get('dateFormat')),
			    // konwertuje na moment
			currentDateFromValue = moment(dateFrom, this.get('dateFormat'));

			if (date.isSame(currentDateFromValue, 'day') && date.isSame(currentDateToValue, 'day')) {
				return true;
			}
		}).property('dateFrom', 'dateTo'),

		isDateTomorrowFilterActivated: (function () {
			var dateTo = this.get('dateTo'),
			    dateFrom = this.get('dateFrom');
			if (!dateTo || !dateFrom) return false;

			var tomorrow = moment().add(1, 'days'),
			    currentDateToValue = moment(dateTo, this.get('dateFormat')),
			    // konwertuje na moment
			currentDateFromValue = moment(dateFrom, this.get('dateFormat'));

			if (tomorrow.isSame(currentDateFromValue, 'day') && tomorrow.isSame(currentDateToValue, 'day')) {
				return true;
			}
		}).property('dateFrom', 'dateTo'),

		isDateAfter2DaysFilterActivated: (function () {
			var dateTo = this.get('dateTo'),
			    dateFrom = this.get('dateFrom');
			if (!dateTo || !dateFrom) return false;

			var dayAfterTomorrow = moment().add(2, 'days'),
			    currentDateToValue = moment(dateTo, this.get('dateFormat')),
			    // konwertuje na moment
			currentDateFromValue = moment(dateFrom, this.get('dateFormat'));

			if (dayAfterTomorrow.isSame(currentDateFromValue, 'day') && dayAfterTomorrow.isSame(currentDateToValue, 'day')) {
				return true;
			}
		}).property('dateFrom', 'dateTo'),

		isDateFutureFilterActivated: (function () {
			var dateFrom = this.get('dateFrom');
			if (!dateFrom) return false;

			var future = moment().add(3, 'days'),
			    currentFilterValue = moment(dateFrom, this.get('dateFormat'));

			// porównuje czy ustawiona data w filtrze === wczoraj (przeszle )
			return future.isSame(currentFilterValue, 'day') && !this.get('dateTo');
		}).property('dateFrom', 'dateTo'),

		exportOvernightTasksLink: _ember["default"].computed(function () {
			return _emberNewConfigEnvironment["default"].apiUrl + '/export/tasks/fullexportgeneratedatnight?bearer=' + this.get('TheUser').get('token');
		}),

		exportActualTasksLink: _ember["default"].computed(function () {
			return _emberNewConfigEnvironment["default"].apiUrl + '/export/tasks/full?bearer=' + this.get('TheUser').get('token');
		}),

		exportBookedTasksLink: _ember["default"].computed(function () {
			return _emberNewConfigEnvironment["default"].apiUrl + '/export/tasks/ordered?bearer=' + this.get('TheUser').get('token');
		}),

		exportSimplifiedTasksLink: _ember["default"].computed(function () {
			return _emberNewConfigEnvironment["default"].apiUrl + '/export/tasks/simple?bearer=' + this.get('TheUser').get('token');
		}),

		exportReservationsLink: _ember["default"].computed(function () {
			return _emberNewConfigEnvironment["default"].apiUrl + '/export/reservations/full?bearer=' + this.get('TheUser').get('token');
		}),

		exportPHReservationsLink: _ember["default"].computed(function () {
			var url = _emberNewUtilsUtils["default"].generateSimpleUrl('/export/reservations/phfull', '?', _emberNewConfigEnvironment["default"], this.get('TheUser'), this.get('cookies'));
			return url;
		}),

		/**
   * Uzywane do ukrywania labelek counta filtrow po dacie (dzis/wczoraj/..../)
   */
		isCountHiddenForCurrentRole: _ember["default"].computed(function () {
			var roles = ['ROLE_CENTRALA', 'ROLE_DE', 'ROLE_DG', 'ROLE_MANAGEMENT'];
			return this.get('TheUser').isMainRoleIn(roles);
		}),

		actions: {
			filterTasksByDate: function filterTasksByDate(type) {
				var format = this.get('dateFormat');
				var date = moment();
				if (type === 'all') {
					this.set('dateFrom', null);
					this.set('dateTo', null);
				} else if (type === 'past') {
					this.set('dateFrom', null);
					this.set('dateTo', date.subtract(2, 'days').format(format));
				} else if (type === 'yesterday') {
					this.set('dateFrom', date.subtract(1, 'days').format(format));
					this.set('dateTo', date.format(format));
				} else if (type === 'today') {
					this.set('dateFrom', date.format(format));
					this.set('dateTo', date.format(format));
				} else if (type === 'tomorrow') {
					this.set('dateFrom', date.add(1, 'days').format(format));
					this.set('dateTo', date.format(format));
				} else if (type === 'after2days') {
					this.set('dateFrom', date.add(2, 'days').format(format));
					this.set('dateTo', date.format(format));
				} else if (type === 'future') {
					this.set('dateFrom', date.add(3, 'days').format(format));
					this.set('dateTo', null);
				}
				this.set('page', 1);
			},

			changeOrderDirection: function changeOrderDirection() {
				var direction = arguments.length <= 0 || arguments[0] === undefined ? 'desc' : arguments[0];

				this.set('orderDirection', direction);
			},

			updateViewMode: function updateViewMode(mode) {
				this.set('currentViewMode', mode);
			},

			openModalFilters: function openModalFilters() {
				this.transitionToRoute('tasks.filters');
			},

			openModalAddTask: function openModalAddTask() {
				this.transitionToRoute('tasks.search');
			},
			openModalOrderMeeting: function openModalOrderMeeting() {
				this.transitionToRoute('tasks.orderingMeetings');
			},

			resetFilters: function resetFilters() {
				this.transitionToRoute('/tasks/');
			}

		},

		canDownloadExports: _ember["default"].computed(function () {
			var roles = ['ROLE_CENTRALA', 'ROLE_DE', 'ROLE_DG', 'ROLE_ADMIN'];
			return this.get('TheUser').isMainRoleIn(roles);
		}),

		canBulkAction: _ember["default"].computed(function () {
			var roles = ['ROLE_DE', 'ROLE_DG', 'ROLE_ADMIN'];
			return this.get('TheUser').isMainRoleIn(roles);
		}),

		canDownloadPHExports: _ember["default"].computed(function () {
			var roles = ['ROLE_SALES_SALESMAN', 'ROLE_SALES_MANAGER', 'ROLE_SALES_HEADMANAGER'];
			return this.get('TheUser').isMainRoleIn(roles);
		})
	});
});
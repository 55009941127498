define('ember-new/routes/b2b-contract/dzu/show-ee/prices/add', ['exports', 'ember-new/routes/authenticated'], function (exports, _emberNewRoutesAuthenticated) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({

        model: function model(params) {

            this.store.unloadAll('AddEePriceSetViewModel');

            var contract = this.modelFor('b2b-contract.dzu.show-ee');

            return this.store.query('AddEePriceSetViewModel', { contract_id: contract.get('id') });
        },

        setupController: function setupController(ctrl, model) {

            ctrl.set('contractId', this.modelFor('b2b-contract.dzu.show-ee').get('id'));
            ctrl.set('model', model);
        }
    });
});
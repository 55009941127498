define('ember-new/controllers/b2b-contract/administration/section-products/add-product-to-contract', ['exports', 'ember', 'ember-new/mixins/b2b-contract/controllers/section-products/add-product-to-contract'], function (exports, _ember, _emberNewMixinsB2bContractControllersSectionProductsAddProductToContract) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsB2bContractControllersSectionProductsAddProductToContract['default'], {

        fillForm: function fillForm(form) {
            form.set('model.productName', null);
            form.set('model.medium', null);
            form.set('model.usysId', null);
            form.set('model.isMmVisible', true);
            form.set('model.isIndex', false);
            form.set('model.isDefaultConditionProduct', false);
            form.set('model.everyIndexAvailableInConditions', false);
            form.set('model.allowedConditionProduct', null);
            form.set('model.defaultConditionProductName', null);
        }
    });
});
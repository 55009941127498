define('ember-new/controllers/individual-contract/pika-sync', ['exports', 'ember', 'ember-new/config/environment'], function (exports, _ember, _emberNewConfigEnvironment) {
    exports['default'] = _ember['default'].Controller.extend({
        ajax: _ember['default'].inject.service('ajax'),
        isLoading: false,
        message: null,
        lockAt: null,
        actions: {
            syncBriefcases: function syncBriefcases() {
                // blokada
                if (this.get('isLoading')) {
                    return;
                }

                var ctrl = this;
                ctrl.set('isLoading', true);
                ctrl.set('message', null);
                ctrl.set('lockAt', null);
                this.get('ajax').ajaxGet(_emberNewConfigEnvironment['default'].apiUrl + '/pika/sync/contracts').then(function (data) {
                    ctrl.set('message', data.message);
                    ctrl.set('lockAt', data.lockAt);
                    ctrl.set('isLoading', false);
                    ctrl.send('refreshRoute');
                }, function () {
                    ctrl.set('message', "Synchronizacja nie powiodla sie - blad");
                    ctrl.set('isLoading', false);
                });
            },
            syncRenouncements: function syncRenouncements() {
                // blokada
                if (this.get('isLoading')) {
                    return;
                }

                var ctrl = this;
                ctrl.set('isLoading', true);
                ctrl.set('message', null);
                ctrl.set('lockAt', null);
                this.get('ajax').ajaxGet(_emberNewConfigEnvironment['default'].apiUrl + '/pika/sync/renouncements').then(function (data) {
                    ctrl.set('message', data.message);
                    ctrl.set('lockAt', data.lockAt);
                    ctrl.set('isLoading', false);
                    ctrl.send('refreshRoute');
                }, function () {
                    ctrl.set('message', "Synchronizacja nie powiodla sie - blad");
                    ctrl.set('isLoading', false);
                });
            }
        }
    });
});
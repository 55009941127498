define('ember-new/components/bstp-button', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'button',
        type: null,
        clicked: null,
        classNameBindings: ['className', 'classTypeName'],
        className: 'btn',
        classTypeName: (function () {
            switch (this.get('type')) {
                case 'success':
                    return 'btn-success';break;
                case 'warning':
                    return 'btn-warning';break;
                case 'danger':
                    return 'btn-warning';break;
                case 'info':default:
                    return 'btn-info';break;
            }
        }).property('type'),
        click: function click() {

            if (this.get('loading')) {
                return;
            }

            if (this.get('clicked') !== null) {
                this.sendAction('clicked');
            }
        },
        setup: (function () {
            this.$().click(function (e) {
                e.preventDefault();
            });
        }).on('didInsertElement'),

        visible: (function () {
            return this.get('loading') == false;
        }).property('loading')

    });
});
define("ember-new/mixins/individual-contract-price-lists/vap", ["exports", "ember", "ember-new/routes/authenticated", "ember-new/config/environment"], function (exports, _ember, _emberNewRoutesAuthenticated, _emberNewConfigEnvironment) {
    exports["default"] = _ember["default"].Mixin.create({

        model: function model(params) {
            this.set('vapUuid', params.vapUuid);

            return this.store.findRecord('IndividualContractVapListViewModel', params.vapUuid, { reload: true });
        },

        setupController: function setupController(ctrl, model) {
            ctrl.set('model', model);
            ctrl.set('vapUuid', this.get('vapUuid'));
        },

        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            }
        }

    });
});
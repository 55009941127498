define("ember-new/templates/dashboard/dashboard-post-edit", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 10,
                  "column": 20
                },
                "end": {
                  "line": 13,
                  "column": 20
                }
              },
              "moduleName": "ember-new/templates/dashboard/dashboard-post-edit.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "d-field-select", [], ["prompt", "--", "key", "category", "content", ["subexpr", "@mut", [["get", "category", ["loc", [null, [12, 74], [12, 82]]]]], [], []]], ["loc", [null, [12, 22], [12, 84]]]]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 15,
                  "column": 20
                },
                "end": {
                  "line": 17,
                  "column": 20
                }
              },
              "moduleName": "ember-new/templates/dashboard/dashboard-post-edit.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "d-field-select", [], ["key", "contentType", "content", ["subexpr", "@mut", [["get", "attachmentType", ["loc", [null, [16, 65], [16, 79]]]]], [], []], "value", ["subexpr", "@mut", [["get", "contentType", ["loc", [null, [16, 86], [16, 97]]]]], [], []]], ["loc", [null, [16, 22], [16, 99]]]]],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 19,
                  "column": 20
                },
                "end": {
                  "line": 21,
                  "column": 20
                }
              },
              "moduleName": "ember-new/templates/dashboard/dashboard-post-edit.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "drop-zone", [], ["key", "dropzone", "fileTypes", "audio/*, image/*, application/pdf", "customUrl", ["subexpr", "@mut", [["get", "customUrl", ["loc", [null, [20, 105], [20, 114]]]]], [], []], "paramName", ["subexpr", "@mut", [["get", "paramName", ["loc", [null, [20, 125], [20, 134]]]]], [], []], "legacyMode", true], ["loc", [null, [20, 22], [20, 152]]]]],
            locals: [],
            templates: []
          };
        })();
        var child3 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 23,
                  "column": 20
                },
                "end": {
                  "line": 25,
                  "column": 20
                }
              },
              "moduleName": "ember-new/templates/dashboard/dashboard-post-edit.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "d-field-text", [], ["key", "content", "value", ["subexpr", "@mut", [["get", "fileId", ["loc", [null, [24, 57], [24, 63]]]]], [], []]], ["loc", [null, [24, 22], [24, 65]]]]],
            locals: ["value"],
            templates: []
          };
        })();
        var child4 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 27,
                  "column": 20
                },
                "end": {
                  "line": 29,
                  "column": 20
                }
              },
              "moduleName": "ember-new/templates/dashboard/dashboard-post-edit.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "d-field-text", [], ["key", "contentVimeo"], ["loc", [null, [28, 22], [28, 57]]]]],
            locals: [],
            templates: []
          };
        })();
        var child5 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 31,
                  "column": 20
                },
                "end": {
                  "line": 33,
                  "column": 20
                }
              },
              "moduleName": "ember-new/templates/dashboard/dashboard-post-edit.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "d-field-text", [], ["key", "contentYoutube"], ["loc", [null, [32, 22], [32, 59]]]]],
            locals: [],
            templates: []
          };
        })();
        var child6 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 35,
                  "column": 20
                },
                "end": {
                  "line": 37,
                  "column": 20
                }
              },
              "moduleName": "ember-new/templates/dashboard/dashboard-post-edit.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "d-field-text", [], ["key", "contentHtml"], ["loc", [null, [36, 22], [36, 56]]]]],
            locals: [],
            templates: []
          };
        })();
        var child7 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 39,
                  "column": 20
                },
                "end": {
                  "line": 41,
                  "column": 20
                }
              },
              "moduleName": "ember-new/templates/dashboard/dashboard-post-edit.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "d-field-textarea", [], ["key", "contentTextAttachment", "rows", 5], ["loc", [null, [40, 22], [40, 77]]]]],
            locals: [],
            templates: []
          };
        })();
        var child8 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 43,
                  "column": 20
                },
                "end": {
                  "line": 45,
                  "column": 20
                }
              },
              "moduleName": "ember-new/templates/dashboard/dashboard-post-edit.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "d-field-checkbox", [], ["key", "isArchived", "checked", false], ["loc", [null, [44, 22], [44, 73]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 2
              },
              "end": {
                "line": 51,
                "column": 2
              }
            },
            "moduleName": "ember-new/templates/dashboard/dashboard-post-edit.hbs"
          },
          isEmpty: false,
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-md-10");
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("form");
            dom.setAttribute(el3, "class", "form-horizontal");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "box-body");
            var el5 = dom.createTextNode("\n\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                  ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1, 1, 1, 1]);
            var morphs = new Array(9);
            morphs[0] = dom.createMorphAt(element0, 1, 1);
            morphs[1] = dom.createMorphAt(element0, 3, 3);
            morphs[2] = dom.createMorphAt(element0, 5, 5);
            morphs[3] = dom.createMorphAt(element0, 7, 7);
            morphs[4] = dom.createMorphAt(element0, 9, 9);
            morphs[5] = dom.createMorphAt(element0, 11, 11);
            morphs[6] = dom.createMorphAt(element0, 13, 13);
            morphs[7] = dom.createMorphAt(element0, 15, 15);
            morphs[8] = dom.createMorphAt(element0, 17, 17);
            return morphs;
          },
          statements: [["block", "d-form-row", [], ["label", "Kategoria", "widthLabelClass", "col-md-4 col-sm-4", "widthFieldClass", "col-md-8 col-sm-8"], 0, null, ["loc", [null, [10, 20], [13, 35]]]], ["block", "d-form-row", [], ["label", "Rodzaj załącznika", "widthLabelClass", "col-md-4 col-sm-4", "widthFieldClass", "col-md-8 col-sm-8"], 1, null, ["loc", [null, [15, 20], [17, 35]]]], ["block", "d-form-row", [], ["label", "Załącznik", "hidden", ["subexpr", "@mut", [["get", "contentHidden", ["loc", [null, [19, 59], [19, 72]]]]], [], []]], 2, null, ["loc", [null, [19, 20], [21, 35]]]], ["block", "d-form-row", [], ["hidden", true], 3, null, ["loc", [null, [23, 20], [25, 35]]]], ["block", "d-form-row", [], ["label", "Link do filmu Vimeo", "widthLabelClass", "col-md-4 col-sm-4", "widthFieldClass", "col-md-8 col-sm-8", "hidden", ["subexpr", "@mut", [["get", "contentVimeoHidden", ["loc", [null, [27, 141], [27, 159]]]]], [], []]], 4, null, ["loc", [null, [27, 20], [29, 35]]]], ["block", "d-form-row", [], ["label", "Link do filmu Youtube", "widthLabelClass", "col-md-4 col-sm-4", "widthFieldClass", "col-md-8 col-sm-8", "hidden", ["subexpr", "@mut", [["get", "contentYoutubeHidden", ["loc", [null, [31, 143], [31, 163]]]]], [], []]], 5, null, ["loc", [null, [31, 20], [33, 35]]]], ["block", "d-form-row", [], ["label", "Tytuł", "widthLabelClass", "col-md-4 col-sm-4", "widthFieldClass", "col-md-8 col-sm-8", "hidden", ["subexpr", "@mut", [["get", "hideAttachmentField", ["loc", [null, [35, 127], [35, 146]]]]], [], []]], 6, null, ["loc", [null, [35, 20], [37, 35]]]], ["block", "d-form-row", [], ["hidden", ["subexpr", "@mut", [["get", "contentTextAttachmentHidden", ["loc", [null, [39, 41], [39, 68]]]]], [], []], "label", "Treść załącznika"], 7, null, ["loc", [null, [39, 20], [41, 35]]]], ["block", "d-form-row", [], ["label", "Archiwizuj post"], 8, null, ["loc", [null, [43, 20], [45, 35]]]]],
          locals: ["formData", "formContents"],
          templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8]
        };
      })();
      return {
        meta: {
          "fragmentReason": {
            "name": "missing-wrapper",
            "problems": ["wrong-type"]
          },
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 52,
              "column": 0
            }
          },
          "moduleName": "ember-new/templates/dashboard/dashboard-post-edit.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "d-form-command", [], ["commandName", "blogPost", "customUrl", ["subexpr", "@mut", [["get", "postEditUrl", ["loc", [null, [3, 53], [3, 64]]]]], [], []], "afterSuccessfulCommand", ["subexpr", "@mut", [["get", "afterCommand", ["loc", [null, [3, 88], [3, 100]]]]], [], []], "fillCommandModel", ["subexpr", "@mut", [["get", "fillForm", ["loc", [null, [3, 118], [3, 126]]]]], [], []]], 0, null, ["loc", [null, [3, 2], [51, 21]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.3.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 52,
            "column": 18
          }
        },
        "moduleName": "ember-new/templates/dashboard/dashboard-post-edit.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "subpage-modal", [], ["subpageTitle", "Edytuj post", "modalSize", "modal-lg", "extraClass", "margin-top-90", "modalCloseRoute", "dashboard"], 0, null, ["loc", [null, [1, 0], [52, 18]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});
define('ember-new/mixins/individual-contract-price-lists/controllers/vap', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Mixin.create({

        vapType: [{ label: 'Zwyżka', value: 'A' }, { label: 'Upust', value: 'D' }],

        validityPeriodValues: [{ label: 'Cała umowa', value: 'entireContractPeriod' }, { label: 'Liczba miesięcy', value: 'numberOfMonths' }],

        months1To99: (function () {
            var values = [];
            for (var i = 1; i < 100; i++) {
                values.push({ label: i, value: i });
            }

            return values;
        }).property(),

        afterCommand: function afterCommand(response) {
            var ctrl = this;
            var route = this.container.lookup('route:individual-contract-price-lists.vap');
            route.refresh();
            setTimeout(function () {
                ctrl.transitionToRoute('individual-contract-price-lists.vap');
            }, 500);
        },

        setupController: function setupController(ctrl, model) {
            ctrl.set('model', model);
            ctrl.set('vapList', (function () {
                var vaps = [];
                this.get('model.vaps').forEach(function (vap) {
                    vaps.push({ label: vap.get('name'), value: vap.get('uuid') });
                });

                return vaps;
            }).property());
        }

    });
});
define('ember-new/mixins/task-serializer', ['exports', 'ember', 'ember-new/mixins/base-document-serializer'], function (exports, _ember, _emberNewMixinsBaseDocumentSerializer) {
	exports['default'] = _ember['default'].Mixin.create(_emberNewMixinsBaseDocumentSerializer['default'], {
		taskSerializerInit: (function () {
			this.addAttrsConfig({
				//forUser: {serialize: 'id', deserialize: 'records'},
				//client: {serialize: 'id', deserialize: 'records'},
				source: { serialize: 'id', deserialize: 'id' },
				eeAcquisitionProcess: { serialize: 'id', deserialize: 'id' },
				gasAcquisitionProcess: { serialize: 'id', deserialize: 'id' },
				//coaching: {serialize: 'id', deserialize: 'records'},
				address: { serialize: 'records', deserialize: 'records', noKey: true }
			});
		}).on('init')
	});
});
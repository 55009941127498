define('ember-new/models/os-dictionary-entry', ['exports', 'ember-new/models/abstract'], function (exports, _emberNewModelsAbstract) {

	var OsDictionaryEntry = _emberNewModelsAbstract['default'].extend({
		label: DS.attr('string'),
		name: DS.attr('string'),
		isHidden: DS.attr('boolean'),
		uuid: DS.attr('string'),
		usysExportId: DS.attr('string')
	});

	exports['default'] = OsDictionaryEntry;
});
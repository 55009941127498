define('ember-new/mixins/individual-contract/show/sells/add-renouncement', ['exports', 'ember', 'ember-new/config/environment'], function (exports, _ember, _emberNewConfigEnvironment) {
    exports['default'] = _ember['default'].Mixin.create({

        type: null,

        renouncementWarning: false,

        renouncement: (function () {
            return this.get('store').createRecord('renouncement');
        }).property(),

        onModelChanged: (function () {
            var model = this.get('model');

            if (!model || !model._internalModel) {
                return;
            }
            var modelName = model._internalModel.modelName;

            if (modelName == 'individual-ee-contract') {
                this.set('type', 'ee');
            } else if (modelName == 'individual-gas-contract') {
                this.set('type', 'gas');
            } else {
                throw new _ember['default'].Error('Modelname: \'' + modelName + '\' not recognized');
            }

            if (model && model.get('signingPlace.id') === '57cd4af8e771dead2262576a') {
                this.get('renouncement').set('renouncementStatus', '59a952b86876d26d4debb978');
            } else {
                this.get('renouncement').set('renouncementStatus', '59a952b4e7c7ffae2fbcb3dc');
            }
        }).observes('model'),

        renouncementStatusChanged: function renouncementStatusChanged(renouncementStatus) {
            var contract = this.get('model');
            if (contract && contract.get('signingPlace.id') === '57cd4af8e771dead2262576a' && renouncementStatus == '59a952b4e7c7ffae2fbcb3dc') {
                this.set('renouncementWarning', true);
            } else {
                this.set('renouncementWarning', false);
            }
        }

    });
});
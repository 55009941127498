define('ember-new/routes/admin/anonymization-registry', ['exports', 'ember-new/routes/authenticated'], function (exports, _emberNewRoutesAuthenticated) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({

        model: function model(params) {
            this.store.unloadAll('AnonymizationRegistryEntryViewModel');

            return this.store.query('AnonymizationRegistryEntryViewModel', params);
        },

        actions: {
            queryParamsDidChange: function queryParamsDidChange() {
                this.refresh();
            },
            refreshRoute: function refreshRoute() {
                this.refresh();
            },
            refreshAsync: function refreshAsync() {
                this.store.unloadAll('AnonymizationRegistryEntryViewModel');

                return this.store.query('AnonymizationRegistryEntryViewModel', this.get('params'));
            }
        }
    });
});
define('ember-new/mixins/base-document-serializer', ['exports', 'ember'], function (exports, _ember) {

    var BaseDocumentSerializerMixin = _ember['default'].Mixin.create({
        baseDocumentSerializerInit: (function () {
            this.addAttrsConfig({
                //            createdBy: {serialize: null, deserialize: 'records'},
                //            lastModifiedBy: {serialize: null, deserialize: 'records'}
            });
        }).on('init')
    });

    exports['default'] = BaseDocumentSerializerMixin;
});
define('ember-new/controllers/b2b-contract/administration/section-products/list/edit-condition-product', ['exports', 'ember', 'ember-new/mixins/b2b-contract/controllers/section-products/add-to-condition'], function (exports, _ember, _emberNewMixinsB2bContractControllersSectionProductsAddToCondition) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsB2bContractControllersSectionProductsAddToCondition['default'], {

        typeKey: null,

        typeFilterIsGas: (function () {
            return this.get('typeKey') === 'gas';
        }).property('typeKey'),

        typeFilterIsEe: (function () {
            return this.get('typeKey') === 'ee';
        }).property('typeKey'),

        valueTypes: [{ label: 'Ceny umowne + cennik bazowy', value: 'contract_price' }, { label: 'Ceny umowne i bazowe', value: 'contract_price_with_base' }, { label: 'Narzut', value: 'overhead' }, { label: 'Narzut ze zniżką', value: 'discount_overhead' }, { label: 'Indeks EE', value: 'index_ee' }, { label: 'Indeks GAZ', value: 'index_gas' }],

        fillForm: function fillForm(form) {
            form.set('model.uuid', this.get('model.id'));
            form.set('model.productName', this.get('model.name'));
            this.set('typeKey', this.get('model.typeKey'));
            form.set('model.isIndex', this.get('model.isIndex'));
            form.set('model.valueType', this.get('model.valueType'));
            form.set('model.periodType', this.get('model.volatilityPeriod'));
            form.set('model.productModel', this.get('model.productModel'));
            form.set('model.medium', this.get('model.typeKey'));
            form.set('model.isProductBasePrice', this.get('model.requiresBasePrices'));
            form.set('model.isAutomaticBasePrice', this.get('model.autoBasePrices'));
        },

        afterCommand: function afterCommand() {
            this.send('refreshModelAction');
        }
    });
});
define('ember-new/serializers/address', ['exports', 'ember-new/serializers/application'], function (exports, _emberNewSerializersApplication) {

  var AddressSerializer = _emberNewSerializersApplication['default'].extend({
    AddressSerializerInit: (function () {
      this.addAttrsConfig({
        district: { serialize: 'id', deserialize: 'id' },
        county: { serialize: 'id', deserialize: 'id' }
      });
    }).on('init')
  });

  exports['default'] = AddressSerializer;
});
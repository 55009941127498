define('ember-new/mixins/paginable-controller', ['exports', 'ember', 'ember-new/config/environment'], function (exports, _ember, _emberNewConfigEnvironment) {
    exports['default'] = _ember['default'].Mixin.create({

        queryParams: ['page', 'itemsPerPage'],

        page: 1,
        itemsPerPage: 10,
        totalItems: 333,
        totalPagesProp: 1,

        loaded: false,

        resetPageable: (function () {
            if (this.get('loaded') && this.get('page') > this.get('totalPagesProp')) {
                this.set('page', 1);
            }
        }).observes('page', 'totalPagesProp'),

        totalPages: (function () {
            var ret = Math.ceil(this.get('totalItems') / this.get('itemsPerPage'));
            this.set('totalPagesProp', ret);
            this.set('loaded', true);
            return ret;
        }).property('itemsPerPage', 'totalItems'),

        showPagination: (function () {
            return this.get('totalPages') > 1;
        }).property('totalPages'),

        itemsPerPageChanged: (function () {
            this.set('page', 1);
        }).observes('itemsPerPage'),

        pagesToSelect: [{ label: 10, value: 10 }, { label: 25, value: 25 }, { label: 50, value: 50 }, { label: 100, value: 100 }, { label: 1000, value: 1000 }],

        actions: {
            goToPage: function goToPage(page) {
                if (page >= 1 && page <= this.get('totalPages')) {
                    this.set('page', page);
                }
            }
        },

        onChangeModel: (function () {
            this.set('totalItems', this.get('model.meta.count'));
        }).observes('model')

    });
});
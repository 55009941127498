define('ember-new/components/d-field-select-model', ['exports', 'ember', 'ember-new/components/d-field-select'], function (exports, _ember, _emberNewComponentsDFieldSelect) {
    exports['default'] = _emberNewComponentsDFieldSelect['default'].extend({

        optionLabelPath: null,

        validateAttrs: (function () {
            var optionLabelPath = this.get('optionLabelPath');

            if (optionLabelPath === undefined || optionLabelPath === null) {
                throw new _ember['default'].Error("D-field-select-model requires to specify `optionLabelPath` option");
            }
        }).on('didReceiveAttributes')
    });
});
define('ember-new/models/gas-energy-destiny-dictionary-entry', ['exports', 'ember-new/models/abstract'], function (exports, _emberNewModelsAbstract) {

    var GasEnergyDestinyDictionaryEntry = _emberNewModelsAbstract['default'].extend({
        label: DS.attr('string'),
        name: DS.attr('string'),
        isHidden: DS.attr('boolean'),
        uuid: DS.attr('string')
    });

    exports['default'] = GasEnergyDestinyDictionaryEntry;
});
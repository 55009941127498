define('ember-new/components/navigation/nav-navbar', ['exports', 'ember', 'ember-new/config/environment'], function (exports, _ember, _emberNewConfigEnvironment) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'ul',
    classNames: 'nav navbar-nav',

    displayHomeTab: (function () {
      return true;
    }).property(),

    displayZadaniaTab: (function () {
      return this.get('TheUser').isGranted(['ROLE_SALES', 'ROLE_CENTRALA', 'ROLE_DZU_MANAGER']);
    }).property('TheUser.model'),

    displayCCTab: (function () {
      return this.get('TheUser').isGranted(['ROLE_CC']);
    }).property('TheUser.model'),

    displayCCVerificationTab: (function () {
      return this.get('TheUser').isGranted(['ROLE_CC_VERIFICATOR', 'ROLE_DE', 'ROLE_DG', 'ROLE_SALES_SUPPORT']);
    }).property('TheUser.model'),

    displayUmowyTab: (function () {
      return this.get('TheUser').isGranted(['ROLE_SALES', 'ROLE_SALES_MAINTENANCE', 'ROLE_DZU', 'ROLE_DZU2', 'ROLE_AUDYTY', 'ROLE_CENTRALA', 'ROLE_CC_MANAGER']);
    }).property('TheUser.model'),

    displayRaportyTab: (function () {
      return this.get('TheUser').isGranted(['ROLE_SALES', 'ROLE_DZU_OFFICE', 'ROLE_DZU_MANAGER', 'ROLE_DZU2_MANAGER', 'ROLE_CC_MANAGER', 'ROLE_AUDYTY_MANAGER', 'ROLE_CENTRALA', 'ROLE_HR', 'ROLE_SALES_SUPPORT']);
    }).property('TheUser.model'),

    displayUsersTab: (function () {
      return this.get('TheUser').isGranted(['ROLE_DZU2_MANAGER', 'ROLE_SALES_MANAGER', 'ROLE_SALES_TEST_MANAGER', 'ROLE_CENTRALA', 'ROLE_HR', 'ROLE_CC_MANAGER', 'ROLE_BOK_MANAGER']);
    }).property('TheUser.model'),

    displayKnowledgeTab: (function () {
      return this.get('TheUser').isGranted(['ROLE_USER']);
    }).property('TheUser.model'),

    displayTestsTab: (function () {
      return true; //this.get('TheUser').isGranted(['ROLE_CENTRALA', 'ROLE_HR', 'ROLE_ADMIN']);
    }).property('TheUser.model'),

    displayTestsAdminTab: (function () {
      return this.get('TheUser').isGranted(['ROLE_CENTRALA', 'ROLE_HR', 'ROLE_ADMIN']);
    }).property('TheUser.model'),

    displayShopAdminTab: (function () {
      return this.get('TheUser').isGranted(['ROLE_CENTRALA', 'ROLE_ADMIN']);
    }).property('TheUser.model'),

    displayIndividualContractTab: (function () {
      return _emberNewConfigEnvironment['default'].applicationType == "DMT" && this.get('TheUser').isGranted(['ROLE_DZU2', 'ROLE_TELESALES', 'ROLE_BTOK', 'ROLE_GDPR_SPECIALIST']); //TODO podpiac prawidlowe role
    }).property('TheUser.model'),

    displayIndividualContractTabForPartners: (function () {
      return _emberNewConfigEnvironment['default'].applicationType == "DMT" && this.get('TheUser').isGranted(['ROLE_SALES']) && this.get('TheUser.emberModel.duonPartner.isIndividual');
    }).property('TheUser.model'),

    displayIndividualClientPortfolioTab: (function () {
      return this.get('TheUser').isGranted(['ROLE_DZU', 'ROLE_DZU2', 'ROLE_TELESALES', 'ROLE_TBOK', 'ROLE_SALES', 'ROLE_SALES_MAINTENANCE']); //TODO podpiac prawidlowe role
    }).property('TheUser.model'),

    displayRenouncementTab: (function () {
      return _emberNewConfigEnvironment['default'].applicationType == "DMT" && this.get('TheUser').isGranted(['ROLE_DZU2', 'ROLE_TELESALES', 'ROLE_BTOK', 'ROLE_GDPR_SPECIALIST']); //TODO podpiac prawidlowe role
    }).property('TheUser.model'),

    displayClientsSalesmanTab: (function () {
      return this.get('TheUser').isMainRoleIn(['ROLE_SALES_TEST_MANAGER', 'ROLE_SALES_MANAGER', 'ROLE_SALES_HEADMANAGER']);
    }).property('TheUser.model'),

    displayClientsSalesAdminTab: (function () {
      return this.get('TheUser').isGranted(['ROLE_DG', 'ROLE_DE']);
    }).property('TheUser.model'),

    displayB2BContractsDZU: (function () {
      return this.get('TheUser').isGranted(['ROLE_DZU', 'ROLE_DZU2', 'ROLE_DE', 'ROLE_ADMIN', 'ROLE_DG', 'ROLE_CENTRALA']);
    }).property('TheUser.model'),

    displayB2BContractsSales: (function () {
      return this.get('TheUser').isMainRoleIn(['ROLE_SALES_TEST_MANAGER', 'ROLE_SALES_MANAGER', 'ROLE_SALES_HEADMANAGER', 'ROLE_SALES_SALESMAN', 'ROLE_CC_MANAGER']);
    }).property('TheUser.model'),

    shopAdminLink: (function () {
      return _emberNewConfigEnvironment['default'].shopApiHost + '/admin?bearer=' + this.get('TheUser').get('token');
    }).property(),

    displayAdminTab: (function () {
      return this.get('TheUser').isMainRoleIn(['ROLE_ADMIN', 'ROLE_GDPR_SPECIALIST', 'ROLE_DZU2_MANAGER']);
    }).property('TheUser.model')
  });
});
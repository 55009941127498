define('ember-new/components/d-form-row', ['exports', 'ember', 'ember-new/mixins/d-form/d-field', 'ember-new/components/d-form'], function (exports, _ember, _emberNewMixinsDFormDField, _emberNewComponentsDForm) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'div',
        classNames: ['form-group'],
        classNameBindings: ['hasFeedback', 'feedbackTypeClass', 'hidden', 'inRowClass'],

        form: null,
        field: null,
        key: null,
        hidden: false,

        hasField: (function () {
            return this.get('field') !== null && this.get('field') instanceof _emberNewMixinsDFormDField['default'];
        }).property('field'),
        hasForm: (function () {
            return this.get('form') !== null && this.get('form') instanceof _emberNewComponentsDForm['default'];
        }).property('form'),

        forObject: null,

        inRow: false,

        widthLabelClass: "col-md-4 col-sm-3",
        widthFieldClass: "col-md-8 col-sm-9",

        initialize: (function () {
            //if (this.get('key') === null) {
            //    throw new Ember.Error("Param 'key' is required in d-field-row component");
            //}

            //if (this.get('hasFormInstance')) {
            //    var form = this.get('form');
            //    form.registerFormRow(this.get('key'), this);
            //}
        }).on('init'),
        connectField: function connectField(key, field) {

            if (!_emberNewMixinsDFormDField['default'].detect(field)) {
                throw new _ember['default'].Error("Field connecting to FormRow has to apply d-field mixin");
            }

            this.set('field', field);
            this.set('key', key);

            if (field.get('hasForm')) {
                this.set('form', field.get('form'));
            }
        },

        hasLabel: true,
        label: "Label",
        helpText: null,
        hasHelpText: (function () {
            var text = this.get('helpText');
            if (text !== undefined && text !== null && text.length > 0) {
                return true;
            }

            return false;
        }).property('helpText'),

        hasFeedback: false,
        feedbackType: null,
        feedbackText: null,
        feedbackTypeClass: (function () {
            switch (this.get('feedbackType')) {
                case 'error':
                    return 'has-error';break;
                case 'warning':
                    return 'has-warning';break;
                case 'success':
                    return 'has-success';break;
                default:
                    return '';break;
            }
        }).property('feedbackType'),

        // dodaje klase 'row' do form-group jezeli inRow === true (odstepy miedzy wierszami formularza)
        inRowClass: (function () {
            if (this.get('inRow')) return 'row';
        }).property('inRow'),

        setFeedback: function setFeedback(type, msg) {

            var text = msg;

            if (msg instanceof Array) {
                text = msg.join("<br>");
            } else if (typeof msg === 'string') {
                text = msg;
            }

            this.set('hasFeedback', true);
            this.set('feedbackType', type);
            this.set('feedbackText', text);
        },
        clearFeedback: function clearFeedback() {
            this.set('hasFeedback', false);
            this.set('feedbackType', null);
            this.set('feedbackText', null);
        },
        fieldId: (function () {
            if (this.get('hasFieldInstance')) {
                return this.get('field.id');
            }

            return null;
        }).property('field', 'field.id')
    });
});
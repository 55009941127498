define('ember-new/routes/b2b-contract/dzu/show-gas/ppg/details/invoices', ['exports', 'ember-new/routes/authenticated', 'ember'], function (exports, _emberNewRoutesAuthenticated, _ember) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({

        ppgId: null,

        model: function model(params) {
            var targetModel = this.modelFor('b2b-contract.dzu.show-gas.ppg.details');
            this.set('ppgId', targetModel.get('uuid'));

            return this.store.query('BusinessContractGasInvoiceDzuViewModel', { ppg_id: targetModel.get('uuid') });
        },

        setupController: function setupController(ctrl, model) {
            var content = model.content;

            ctrl.set('model', model);
            ctrl.set('ppgId', this.get('ppgId'));
            ctrl.set('isInvoicesExists', content.length > 0);
            ctrl.set('isDeleting', false);
        },

        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            }
        }

    });
});
define('ember-new/initializers/no-key-mixin-initialize', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {

    var alreadyRun = false;

    exports['default'] = {
        name: 'no-key-mixin-initialize',
        initialize: function initialize() {
            if (alreadyRun) {
                return;
            } else {
                alreadyRun = true;
            }

            var get = _ember['default'].get;
            function setKey(record, type) {
                var id = _ember['default'].generateGuid(null, type);
                record.id = id;
                record.autoGenerated = true;
            }
            // chooses a relationship kind to branch which function is used to update payload
            // does not change payload if attr is not embedded
            function extractEmbeddedRecords(serializer, store, type, partial) {

                var parentSerializer = store.serializerFor(type.typeKey);

                type.eachRelationship(function (key, relationship) {
                    if (serializer.hasDeserializeRecordsOption(key)) {
                        var embeddedType = store.modelFor(relationship.type);

                        if (relationship.kind === "hasMany") {
                            extractEmbeddedHasMany(store, key, embeddedType, relationship, partial, parentSerializer);
                        }
                        if (relationship.kind === "belongsTo") {
                            extractEmbeddedBelongsTo(store, key, embeddedType, relationship, partial, parentSerializer);
                        }
                    }
                });

                return partial;
            }
            // handles embedding for `hasMany` relationship
            function extractEmbeddedHasMany(store, key, embeddedType, relationship, hash, parentSerializer) {
                if (!hash[key]) {
                    return hash;
                }

                var ids = [];
                var embeddedSerializer = store.serializerFor(embeddedType.typeKey);
                var noKeyOnEmbeddedRecord = parentSerializer.hasNoKeyOption(key);

                hash[key].forEach(function (data) {
                    var embeddedRecord = embeddedSerializer.normalize(embeddedType, data, null);

                    if (noKeyOnEmbeddedRecord && embeddedRecord.id == null) {
                        setKey(embeddedRecord, embeddedType.typeKey);
                    }

                    store.push(embeddedType.typeKey, embeddedRecord);
                    ids.push(embeddedRecord.id);
                });

                hash[key] = ids;
                return hash;
            }
            function extractEmbeddedBelongsTo(store, key, embeddedType, relationship, hash, parentSerializer) {
                if (!hash[key]) {
                    return hash;
                }

                var embeddedSerializer = store.serializerFor(embeddedType.typeKey);
                var noKeyOnEmbeddedRecord = parentSerializer.hasNoKeyOption(key);
                var embeddedRecord = embeddedSerializer.normalize(embeddedType, hash[key], null);

                if (noKeyOnEmbeddedRecord && embeddedRecord.id == null) {
                    setKey(embeddedRecord, embeddedType.typeKey);
                }

                if (typeof embeddedRecord === "string") {
                    return hash;
                }

                store.push(embeddedType.typeKey, embeddedRecord);

                hash[key] = embeddedRecord.id;

                return hash;
            }

            _emberData['default'].NoKeyMixin = _ember['default'].Mixin.create({
                normalize: function normalize(type, hash, prop) {
                    if (!hash) {
                        return hash;
                    }

                    console.log('this', this);

                    this.normalizeId(hash);
                    this.normalizeAttributes(type, hash);
                    this.normalizeRelationships(type, hash);
                    this.normalizeUsingDeclaredMapping(type, hash);
                    this.applyTransforms(type, hash);

                    var normalizedHash = hash;

                    return extractEmbeddedRecords(this, this.store, type, normalizedHash);
                },

                serializeBelongsTo: function serializeBelongsTo(record, json, relationship) {
                    var attr = relationship.key;
                    if (this.noSerializeOptionSpecified(attr)) {
                        this._super(record, json, relationship);
                        return;
                    }
                    var includeIds = this.hasSerializeIdsOption(attr);
                    var includeRecords = this.hasSerializeRecordsOption(attr);
                    var embeddedRecord = record.get(attr);
                    var key, includeId;
                    if (includeIds) {
                        key = this.keyForRelationship(attr, relationship.kind);
                        if (!embeddedRecord) {
                            json[key] = null;
                        } else {
                            json[key] = get(embeddedRecord, 'id');
                        }
                    } else if (includeRecords) {
                        key = this.keyForAttribute(attr);
                        if (!embeddedRecord) {
                            json[key] = null;
                        } else {
                            includeId = this.hasNoKeyOption(key) === false;
                            json[key] = embeddedRecord.serialize({ includeId: includeId });
                            this.removeEmbeddedForeignKey(record, embeddedRecord, relationship, json[key]);
                        }
                    }
                },
                serializeHasMany: function serializeHasMany(record, json, relationship) {
                    var attr = relationship.key;
                    if (this.noSerializeOptionSpecified(attr)) {
                        this._super(record, json, relationship);
                        return;
                    }
                    var includeIds = this.hasSerializeIdsOption(attr);
                    var includeRecords = this.hasSerializeRecordsOption(attr);
                    var key, includeId;
                    if (includeIds) {
                        key = this.keyForRelationship(attr, relationship.kind);
                        json[key] = get(record, attr).mapBy('id');
                    } else if (includeRecords) {
                        key = this.keyForAttribute(attr);
                        json[key] = get(record, attr).map(function (embeddedRecord) {
                            includeId = this.hasNoKeyOption(key) === false;
                            var serializedEmbeddedRecord = embeddedRecord.serialize({ includeId: includeId });
                            this.removeEmbeddedForeignKey(record, embeddedRecord, relationship, serializedEmbeddedRecord);
                            return serializedEmbeddedRecord;
                        }, this);
                    }
                },
                hasNoKeyOption: function hasNoKeyOption(attr) {
                    var option = this.attrsOption(attr);
                    return option && option.noKey === true;
                }
            });
        }
    };
});
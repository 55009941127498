define("ember-new/routes/b2b-contract/administration/section-products/details", ["exports", "ember-new/routes/authenticated", "ember"], function (exports, _emberNewRoutesAuthenticated, _ember) {
    exports["default"] = _emberNewRoutesAuthenticated["default"].extend({

        model: function model(params) {
            this.store.unloadAll('BusinessProductListViewModel');
            return _ember["default"].RSVP.hash({
                product: this.store.findRecord('BusinessProductListViewModel', params.productUuid)
            });
        },

        setupController: function setupController(ctrl, model) {
            ctrl.set('model', model.product);
        },

        actions: {
            refreshModelAction: function refreshModelAction() {
                this.refresh();
            }
        }
    });
});
define('ember-new/controllers/individual-contract/show-gas/client', ['exports', 'ember-new/config/environment', 'ember', 'ember-new/mixins/individual-contract/show/client'], function (exports, _emberNewConfigEnvironment, _ember, _emberNewMixinsIndividualContractShowClient) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsIndividualContractShowClient['default'], {
        'delete': function _delete() {
            this.transitionToRoute('individual-contract.show-gas.client.delete');
        },

        actions: {
            copyAddressFromMainAddress: function copyAddressFromMainAddress() {
                var _this = this;

                var address = {
                    street: this.get('model.client.baseAddress.street'),
                    streetNo: this.get('model.client.baseAddress.streetNo'),
                    aptNo: this.get('model.client.baseAddress.aptNo'),
                    postalCode: this.get('model.client.baseAddress.postalCode'),
                    postalCity: this.get('model.client.baseAddress.postalCity'),
                    city: this.get('model.client.baseAddress.city')
                };

                Object.keys(address).map(function (key) {
                    _this.set('addressForm.' + key, address[key]);
                });
            }
        },

        isEditable: (function () {
            var editSells = this.get('model.credentials.editSells');
            var isAnonymized = this.get('model.client.isAnonymized');
            var permission = this.get('TheUser').isMainRoleIn(['ROLE_GDPR_SPECIALIST']);

            return editSells && !isAnonymized && !permission;
        }).property('model'),

        isAllowedToDelete: (function () {
            var isAnonymized = this.get('model.client.isAnonymized');
            var permission = this.get('TheUser').isMainRoleIn(['ROLE_GDPR_SPECIALIST']);

            return !isAnonymized && !permission;
        }).property('model')
    });
});
define('ember-new/controllers/b2b-contract/administration/section-products/details', ['exports', 'ember'], function (exports, _ember) {

    var contractProduct = 'do umowy';
    var priceConditionProduct = 'do warunku';

    var computed = _ember['default'].computed;
    exports['default'] = _ember['default'].Controller.extend({

        applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),

        isReloading: false,

        isGrantedToEdit: (function () {
            return this.get('TheUser').isGranted(['ROLE_ADMIN', 'ROLE_DG', 'ROLE_DE', 'ROLE_SALES_SUPPORT', 'ROLE_DZU_MANAGER', 'ROLE_DZU2_MANAGER']);
        }).property(),

        isContractProduct: _ember['default'].computed('model', function () {
            return this.get('model.kind') === contractProduct;
        }),

        isPriceConditionProduct: _ember['default'].computed('model', function () {
            return this.get('model.kind') === priceConditionProduct;
        }),

        afterCommand: function afterCommand() {
            this.send('refreshModelAction');
        },

        showNoBasePriceListNotification: computed(function () {
            var hasBasePriceSet = this.get('model.hasBasePriceSet');
            var hasAssignedBasePriceSet = this.get('model.hasAssignedBasePriceSet');
            var correctProductKind = this.get('model.kind') === priceConditionProduct;

            return hasBasePriceSet && !hasAssignedBasePriceSet && correctProductKind;
        }).property('model.hasBasePriceSet', 'model.hasAssignedBasePriceSet', 'model.kind')

    });
});
define('ember-new/controllers/individual-contract-partners/add-files', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        fillForm1: function fillForm1(form) {
            form.set('model.contractId', this.get('model.uuid'));
            form.set('model.file1Uuid', this.get('model.partnerFile1'));
            form.set('model.file2Uuid', this.get('model.partnerFile2'));
            form.set('model.file3Uuid', this.get('model.partnerFile3'));
            form.set('model.file4Uuid', this.get('model.partnerFile4'));
            form.set('model.file5Uuid', this.get('model.partnerFile5'));
        },

        fillForm2: function fillForm2(form) {
            form.set('model.contractId', this.get('model.uuid'));
            form.set('model.recordingUuid', this.get('model.vcRecording'));
        },

        afterCommand: function afterCommand() {
            var ctrl = this;
            ctrl.transitionToRoute('individual-contract-partners');
        },

        hasAnyFile: (function () {
            if (!this.get('model.partnerFile1') && !this.get('model.partnerFile2') && !this.get('model.partnerFile3') && !this.get('model.partnerFile4') && !this.get('model.partnerFile5')) {
                return false;
            }

            return true;
        }).property('model'),

        isEditable: (function () {
            var isAnonymized = this.get('contract.isAnonymized');
            return !isAnonymized;
        }).property('contract')

    });
});
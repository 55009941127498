define('ember-new/routes/b2b-contract/salesman/show-gas/comments', ['exports', 'ember-new/routes/authenticated'], function (exports, _emberNewRoutesAuthenticated) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({

        idContract: null,
        idClient: null,

        beforeModel: function beforeModel() {
            var contract = this.modelFor('b2b-contract.salesman.show-gas');
            var contractCtrl = this.controllerFor('b2b-contract.salesman.show-gas');
            var notificationsExist = contractCtrl.get('notificationsExist');

            if (notificationsExist) {
                var controller = this.controllerFor('b2b-contract.salesman.show-gas.comments');
                controller.send('clearCommentsNotifications', contract.get('id'), contract.get('clientId'), notificationsExist);
            }
        },

        model: function model() {
            var contract = this.modelFor('b2b-contract.salesman.show-gas');
            var contractCtrl = this.controllerFor('b2b-contract.salesman.show-gas');
            this.set('idContract', contract.get('id'));
            this.set('idClient', contract.get('clientId'));
            this.set('contractCtrl', contractCtrl);

            return this.store.query('ContractCommentsSalesmanViewModel', { contract_id: contract.get('id') });
        },

        setupController: function setupController(controller, model) {
            controller.set('model', model);
            controller.set('idClient', this.get('idClient'));
            controller.set('idContract', this.get('idContract'));
            controller.set('contractCtrl', this.get('contractCtrl'));
        },

        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            }

        }
    });
});
// refreshModelFromCommentController() {
//     this.store.unloadAll('ContractHeaderSalesmanViewModel');
//     this.refresh();
// }
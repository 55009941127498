define('ember-new/components/d-form', ['exports', 'ember', 'ember-data', 'ember-new/components/d-form-row', 'ember-new/mixins/d-form/d-field', 'ember-new/components/box-for-form', 'ember-new/mixins/controllers/unsaved-data'], function (exports, _ember, _emberData, _emberNewComponentsDFormRow, _emberNewMixinsDFormDField, _emberNewComponentsBoxForForm, _emberNewMixinsControllersUnsavedData) {

    var FormComponent = _ember['default'].Component.extend({
        // injects
        ajax: _ember['default'].inject.service('ajax'),
        applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),

        // internal flags
        _isInited: false,
        _isLoading: false,
        _isSubmitting: false,
        _canSubmit: true,
        _areContentsSetUp: false,
        _modeShow: false,
        _modeEdit: true,
        _devMode: false,

        // options
        formTitle: null,
        hasEditMode: true,
        hasShowMode: true,
        formType: null,

        submitButtonText: 'Zapisz',
        isDisabledApplyButton: false,

        // funkcja ktora mozemy nadpisac jesli chcemy w niej zadecydowac czy uzytkownik moze edytowac formularz czy tylko go widziec
        hasPrivsToEdit: (function () {
            return true;
        }).property(),

        showFormHeader: (function () {
            return this.get('formTitle') ? true : false;
        }).property('formTitle'),

        // submit options
        submitUrl: null,
        submitRestAction: null,
        submitModelNamespace: 'form',
        submitRestAsSimpleMethod: 'POST',

        // submit private properties
        _submitResult: null,
        _submitResultType: null,
        _showSubmitResult: false,

        // texts
        _textAddingSuccess: "Dodano pomyślnie!",
        _textAddingException: "Wystąpił błąd. Nie udało się dodać.",
        _textUpdatingSuccess: "Zapisano zmiany pomyślnie!",
        _textUpdatingException: "Wystąpił błąd. Nie udało się zaktualizować danych.",
        _textNotValid: "Oops! Coś jest niepoprawne.",

        // virtual info
        formId: (function () {
            return this.constructor._toString;
        }).property(),

        // fields and rows instances, registered in the form
        fields: {},
        formRows: {},

        // model and formData properties
        _isModelBound: false,
        _isRestModel: false,
        _fieldsKeys: null,
        _modelFieldsKeys: null,
        _notModelFieldsKeys: null,
        _embeddedFieldsKeys: null,
        model: null,
        formData: null,

        // coonfiguration callbacks
        configure: null,
        resolveContents: null,

        // form contents
        contents: null,
        filteredContents: null,
        contentFilters: null, // will be replaced to Ember.Object instance in initalize function

        // definition of data transformers beetwen model and formData and vice versa
        dataTransformers: null,

        // properties to formErrors
        fieldErrors: null,
        commonFormErrors: null,

        // initalize the form:
        initialize: (function () {
            if (this.get('_devMode')) {
                console.log("INIT FORM");
            }

            this._initializeComponentProperties();
            this._checkIfModelIsBound();

            if (typeof this.customInit === 'function') {
                this.customInit.call();
            }
        }).on('init'),

        /*
           _assignFormDataToVar:
           Dane z formularza dostępne na zewnątrz w this.get('currentFormData')
            przykladowe uzycie w d-form-command:
            currentFormData=addressForm
            Dzieki temu mozemy edytować pola formularza przes this.set('addressForm.input', nowaWartosc);
         */
        _assignFormDataToVar: (function () {
            this.set('currentFormData', this.get('formData')); // companyAddressData - nowe property obiektu
        }).on('didInsertElement'),

        _initializeComponentProperties: function _initializeComponentProperties() {
            this.set('_fieldsKeys', []);
            this.set('_modelFieldsKeys', []);
            this.set('_notModelFieldsKeys', []);
            this.set('_embeddedFieldsKeys', []);
            this.set('formData', _ember['default'].Object.create(this.get('formData')));
            this.set('fields', _ember['default'].Object.create());
            this.set('formRows', _ember['default'].Object.create());
            this.set('dataTransformers', _ember['default'].Object.create());
            this.set('fieldErrors', _ember['default'].Object.create());
            this.set('commonFormErrors', _ember['default'].Object.create());
            this.set('contentFilters', _ember['default'].Object.create());
            this.set('filteredContents', _ember['default'].Object.create());
            this.set('contents', _ember['default'].Object.create());
        },
        _checkIfModelIsBound: function _checkIfModelIsBound() {
            var model = this.get('model');

            if (model !== null) {
                if (!(model instanceof _ember['default'].Object)) {
                    throw new _ember['default'].Error("Model bound to d-form must be Ember.Object instance");
                }
                if (model instanceof _emberData['default'].PromiseObject) {
                    this.set('model', model.get('content'));
                }
                this.set("_isModelBound", true);
            }
        },

        _setupFormAndResolveContents: (function () {
            //if (this.get('_devMode')) {
            console.log("WILL INSERT FORM");
            //}

            var fields = this.get('fields');
            var formData = this.get('formData');
            var form = this;

            Object.keys(fields).forEach(function (key) {
                var field = fields.get(key);

                field.addObserver('value', function () {
                    formData.set(key, field.get('value'));
                });

                form.addObserver('formData.' + key, function () {
                    field.set('value', form.get('formData.' + key));
                    if (form.get('_isInited')) {
                        form.dataChanged();
                    }
                });
                form.get('_fieldsKeys').push(key);
            });

            // configure
            if (typeof this.configure == 'function') {
                this.configure.call(this, this);
            }

            // copy data from model to formData
            var model = this.get('model');
            if (model !== undefined && model !== null) {
                if (!(model instanceof _ember['default'].Object)) {
                    throw new _ember['default'].Error("Model bound to d-form '" + this.get('formId') + "' has to be Ember.Object instance or child");
                }

                var isRestModel = model instanceof _emberData['default'].Model;
                this.set('_isRestModel', isRestModel);

                Object.keys(fields).forEach(function (key) {

                    var hasAttributeInModel = false;
                    var isOneRelationship = false;
                    var isManyRelationship = false;

                    if (isRestModel) {
                        isOneRelationship = _ember['default'].get(model.constructor, 'relationshipNames').belongsTo.indexOf(key) > -1;
                        isManyRelationship = _ember['default'].get(model.constructor, 'relationshipNames').hasMany.indexOf(key) > -1;

                        hasAttributeInModel = _ember['default'].get(model.constructor, 'attributes').has(key) || isOneRelationship || isManyRelationship;
                    } else {
                        hasAttributeInModel = model.hasOwnProperty(key);
                    }

                    if (hasAttributeInModel) {

                        form.get('_modelFieldsKeys').push(key);

                        var value;
                        if (false && form.hasTransformerFor(key)) {
                            var transformer = form.getTransformerFor(key);
                            value = transformer.transform.call(form, model.get(key));
                        } else {
                            value = model.get(key);
                        }

                        formData.set(key, value);
                    } else {
                        form.get('_notModelFieldsKeys').push(key);
                    }
                });
            }

            // resolve contents if method to load provided
            var resolveContents = this.get("resolveContents");

            var contentsPromise = false;

            if (resolveContents !== null && typeof resolveContents === 'function') {
                contentsPromise = resolveContents.call(this, this);
                var form = this;

                if (contentsPromise instanceof _ember['default'].RSVP.Promise) {
                    contentsPromise.then(function (data) {

                        if (typeof data !== "object" || !(data instanceof _ember['default'].Object)) {
                            throw new _ember['default'].Error("Contents resolved by promise from `resolveContents` must be Ember.Object instance");
                        }

                        form.setupContents(data);
                        form.set("_isInited", true);
                    }, function () {
                        form.get('applicationAlerts').addActionMessage('danger', "(500) Ciemność, ciemność widzę! Błąd systemu! Skontaktuj się z HelpDesk lub zgłoś swojemu Managerowi!");
                        throw new _ember['default'].Error("Nie udało się zainicjować formularza - nie udało się załadować resolveContents");
                    });
                } else if (typeof contentsPromise === 'object') {
                    form.setupContents(contentsPromise);
                    form.set("_isInited", true);
                    contentsPromise = null;
                }
            } else {
                form.setupContents(_ember['default'].Object.create());
                this.set('_isInited', true);
                this.set('_areContentsSetUp', true);
            }
        }).on('willInsertElement'), // to zapewnia, że wykona się po tym jak fieldy i rowy się zarejestrują
        didInsertForm: (function () {
            if (this.get('_devMode')) {
                console.log("DID INSERT FORM");
            }
        }).on('didInsertElement'),

        // content functions
        hasContentForKey: function hasContentForKey(key) {
            var contents = this.get('contents');

            if (typeof contents !== 'object' || !(contents instanceof _ember['default'].Object)) {
                return false;
            }

            return Object.keys(contents).contains(key);
        },
        // end content functions

        // content filters functions
        setupContents: function setupContents(contents) {

            if (typeof contents !== "object" || !(contents instanceof _ember['default'].Object)) {
                console.log(typeof contents, contents);
                return;
            }

            var keys = Object.keys(contents);
            var filteredContents = this.get('filteredContents');
            var form = this;

            var mergedContents = _ember['default'].Object.create({});

            // get contents loaded in fields
            this.get('_fieldsKeys').forEach(function (fieldKey) {
                var field = form.getField(fieldKey);
                var fieldContent = field.get('content');

                if (typeof fieldContent === 'object' && (fieldContent instanceof Array || fieldContent instanceof _ember['default'].ArrayProxy)) {
                    mergedContents.set(fieldKey, fieldContent);
                }
            });

            keys.forEach(function (contentKey) {
                var con = contents.get(contentKey);

                if (typeof con === 'object' && (con instanceof Array || con instanceof _ember['default'].ArrayProxy)) {
                    mergedContents.set(contentKey, con);
                }
            });

            this.set('contents', mergedContents);
            var mergedKeys = Object.keys(mergedContents);

            mergedKeys.forEach(function (mergedKey) {

                // create bindings if field exist
                if (form.hasField(mergedKey)) {
                    var field = form.getField(mergedKey);

                    field.addObserver('content', function () {
                        var con = field.get('content');

                        if (typeof con === 'object' && (con instanceof Array || con instanceof _ember['default'].ArrayProxy)) {
                            form.set('filteredContents.' + mergedKey, con);
                        }
                    });

                    form.addObserver('filteredContents.' + mergedKey, function () {
                        var con = filteredContents.get(mergedKey);

                        if (typeof con === 'object' && (con instanceof Array || con instanceof _ember['default'].ArrayProxy)) {
                            field.set('content', con);
                        }
                    });
                }

                var filteredContent;

                if (form.hasContentFilter(mergedKey)) {
                    filteredContent = form._getFilteredSingleContent(mergedKey);
                } else {
                    filteredContent = mergedContents.get(mergedKey);
                }

                filteredContents.set(mergedKey, filteredContent);
            });

            form.set('_areContentsSetUp', true);
        },

        setupContentFor: function setupContentFor(key, content) {
            if (!this.hasField(key)) {
                throw new _ember['default'].Error("There is no field: '`" + key + '`');
            }

            this.get('contents').set(key, content);
        },

        addContentFor: function addContentFor(key, content) {
            if (!this.hasField(key)) {
                throw new _ember['default'].Error("There is no field: '`" + key + '`');
            }

            if (!this.get('contents').hasOwnProperty(key)) {
                this.get('contents').set(key, _ember['default'].A());
            }
            this.get('contents').get(key).pushObject(content);
        },

        hasContentFilter: function hasContentFilter(key) {
            var filters = this.get('contentFilters');

            if (filters === undefined || typeof filters !== "object" || !(filters instanceof _ember['default'].Object)) {
                return false;
            }

            return Object.keys(filters).contains(key);
        },
        _getFilteredSingleContent: function _getFilteredSingleContent(key) {
            if (!this.hasContentForKey(key)) {
                throw new _ember['default'].Error("There is no content for `" + key + "` field.");
            }

            if (!this.hasContentFilter(key)) {
                throw new _ember['default'].Error("There is no content filter for `" + key + "` field.");
            }

            var filters = this.get('contentFilters');
            var filterFunction = filters.get(key);

            var content = this.get('contents.' + key);
            return filterFunction.call(this, content);
        },
        filterContentFor: function filterContentFor(key) {
            var doResetValue = arguments.length <= 1 || arguments[1] === undefined ? true : arguments[1];

            if (!this.get('_areContentsSetUp')) {
                return false;
            }

            var filteredContent;

            if (!this.hasContentFilter(key)) {
                filteredContent = this.get('contents').hasOwnProperty(key) ? this.get('contents').get(key) : [];
            } else {
                filteredContent = this._getFilteredSingleContent(key);
            }
            this.get('filteredContents').set(key, filteredContent);
            if (doResetValue) {
                this.getField(key).set('value', null);
            }
            this.getField(key).send('highlightField');
        },
        registerContentFilter: function registerContentFilter(key, callback) {

            if (!this.hasField(key)) {
                throw new _ember['default'].Error("Field `" + key + "` does not exist in the d-form.");
            }

            if (typeof callback !== 'function') {
                throw new _ember['default'].Error("Filter's callback passed to `registerContentFilter` of d-form component must be a function which receives field contents and formData, and returns filtered field's content.");
            }

            var filters = this.get('contentFilters');

            if (filters === null || typeof filters !== 'object' || !(filters instanceof _ember['default'].Object)) {
                filters = _ember['default'].Object.create();
                this.set('contentFilters', filters);
            }

            filters.set(key, callback);
        },
        // END content filters functions

        // content filters observers
        contentFiltersBindingValidation: (function () {

            var filters = this.get('contentFilters');

            if (filters === null) {
                return;
            }

            if (filters === undefined || typeof filters !== 'object') {
                throw new _ember['default'].Error("Property `contentFilters` bound to the d-form, must be object/Ember.Object/null");
            }

            if (!(filters instanceof _ember['default'].Object)) {
                this.set('contentFilters', _ember['default'].Object.create(filters));
            }
        }).observes('contentFilters'),
        // END content filters observes

        // public functions to register rows and fields
        registerField: function registerField(key, instance) {

            if (!_emberNewMixinsDFormDField['default'].detect(instance)) {
                throw new _ember['default'].Error("A field registering in Form has to apply d-field mixin.");
            }

            this.set('fields.' + key, instance);
        },
        registerFormRow: function registerFormRow(key, instance) {
            if (!(instance instanceof _emberNewComponentsDFormRow['default'])) {
                throw new _ember['default'].Error("FormRow setting in FormRow has to be d-form-row instance");
            }

            var obj = this.get('formRows');
            obj[key] = instance;
            this.set('formRows', obj);
        },

        // getters of rows and fields instances
        hasFormRow: function hasFormRow(key) {
            var obj = this.get('formRows');
            return Object.keys(obj).contains(key);
        },
        getFormRow: function getFormRow(key) {
            var obj = this.get('formRows');

            if (!Object.keys(obj).contains(key)) {
                throw new _ember['default'].Error("There is no d-form-row instance of key '" + key + "' in the form. Make sure that you passed Form instance to the FormRow.");
                return;
            }

            return obj[key];
        },
        hasField: function hasField(key) {
            var obj = this.get('fields');
            return Object.keys(obj).contains(key);
        },
        getField: function getField(key) {
            var obj = this.get('fields');

            if (!Object.keys(obj).contains(key)) {
                throw new _ember['default'].Error("There is no field instance of key '" + key + "' in the form. Make sure thar d-field of this field component is inserted into yield of form component or you passed form object to field manually by {{d-field-** form=someFormInstance}}");
                return;
            }

            return obj[key];
        },
        getTransformerFor: function getTransformerFor(key) {
            var dataTransformers = this.get('dataTransformers');
            var transformer = dataTransformers.get(key);

            if (transformer === undefined || typeof transformer !== 'object') {
                throw new _ember['default'].Error("Data transformer for d-form field key '" + key + "' has to be Object with 'transform' and 'reverseTransform' properties");
            }

            if (transformer.transform === undefined || typeof transformer.transform !== 'function') {
                throw new _ember['default'].Error("Data transformer for d-form field key '" + key + "' must have 'transform' property set as a function");
            }

            if (transformer.reverseTransform === undefined || typeof transformer.reverseTransform !== 'function') {
                throw new _ember['default'].Error("Data transformer! for d-form field key '" + key + "' must have 'reverseTransform' property set as a function");
            }

            return transformer;
        },
        hasTransformerFor: function hasTransformerFor(key) {
            var dataTransformers = this.get('dataTransformers');
            return Object.keys(dataTransformers).contains(key);
        },
        registerTransformer: function registerTransformer(key, transform, reverseTransform) {
            var dataTransformers = this.get('dataTransformers');

            if (transform === undefined || typeof transform !== 'function') {
                throw new _ember['default'].Error("Second parameter passed to d-form::registerTransformer (for key `" + key + "`) must be a function which makes `transform from model to form`");
            }

            if (reverseTransform === undefined || typeof reverseTransform !== 'function') {
                throw new _ember['default'].Error("Third parameter passed to d-form::registerTransformer (for key `" + key + "`) must be a function which makes `reverseTransform from form to model`");
            }

            dataTransformers.set(key, {
                transform: transform,
                reverseTransform: reverseTransform
            });
        },

        getValue: function getValue(key) {
            if (!this.hasField(key)) {
                throw new _ember['default'].Error("Field `" + key + "` does not exist in the d-form.");
            }

            return this.get('formData.' + key);
        },

        // funkcja do zaladowania wszystkich dependencies przed ladowaniem formularza
        resolveDependencies: function resolveDependencies() {},

        _copyDataFromFormToModel: function _copyDataFromFormToModel() {

            var model = this.get('model');
            var fields = this.get('fields');
            var formData = this.get('formData');
            var _this = this;
            var form = this;

            Object.keys(fields).forEach(function (key) {
                var value;

                var hasAttributeInModel = false;

                if (_this.get('_isRestModel')) {
                    hasAttributeInModel = _ember['default'].get(model.constructor, 'attributes').has(key) || _ember['default'].get(model.constructor, 'relationshipNames').belongsTo.indexOf(key) > -1 || _ember['default'].get(model.constructor, 'relationshipNames').hasMany.indexOf(key) > -1;
                } else {
                    hasAttributeInModel = model.hasOwnProperty(key);
                }

                if (hasAttributeInModel) {

                    if (_this.hasTransformerFor(key)) {
                        var transformer = _this.getTransformerFor(key);
                        value = transformer.reverseTransform.call(form, formData.get(key));
                    } else {
                        value = formData.get(key);
                    }

                    model.set(key, value);
                }
            });
        },
        _getModelFieldsPayload: function _getModelFieldsPayload() {
            var keys = this.get('_modelFieldsKeys');
            var payload = {};
            var formData = this.get('formData');
            var form = this;

            if (this.get('_isModelBound') && this.get('_isRestModel')) {
                var serializedData = this.get('model').serialize();
                var serializedDataKeys = Object.keys(serializedData);

                keys.forEach(function (key) {
                    payload[key] = serializedDataKeys.contains(key) ? serializedData[key] : null;
                });
            } else {
                keys.forEach(function (key) {

                    var value = null;

                    if (form.hasTransformerFor(key)) {
                        var transformer = form.getTransformerFor(key);
                        value = transformer.reverseTransform.call(form, formData.get(key));
                    } else {
                        value = formData.get(key);
                    }

                    payload[key] = value;
                });
            }

            return payload;
        },
        _getNotModelFieldsPayload: function _getNotModelFieldsPayload() {
            var notModelFieldsKeys = this.get('_notModelFieldsKeys');
            var notModelFieldsData = {};
            var formData = this.get('formData');

            notModelFieldsKeys.forEach(function (key) {
                notModelFieldsData[key] = formData.get(key) ? formData.get(key) : null;
            });

            return notModelFieldsData;
        },
        _handleSubmit: function _handleSubmit() {
            this.set('_isSubmitting', true);
            this.clearFeedback();
            var isValid = true;

            // validation
            if (typeof this.validateForm == 'function') {
                isValid = this.validateForm.call(this);
            }

            if (!isValid) {
                this.set('_isSubmitting', false);
                return;
            }

            // configure
            if (typeof this.beforeSubmit == 'function') {
                this.beforeSubmit.call();
            }

            var hasModel = this.get('_isModelBound');
            var isSimpleModel = hasModel && !this.get('_isRestModel');
            var isRestModel = hasModel && this.get('_isRestModel');
            var submitRestAsSimple = this.get('submitRestAsSimple');
            var form = this;

            if (!hasModel) {
                throw new _ember['default'].Error("A model has to be bound to the d-form to submit it.");
            }

            //  we have to bind form contents to model before submitting. (with data transformations)
            this._copyDataFromFormToModel();

            var promise;

            if (isRestModel && !submitRestAsSimple) {
                promise = this._handleRestSubmit();
            } else {
                promise = this._handleSimpleSubmit();
            }

            var model = this.get('model');
            var isNewModel = model.get('isNew');
            var successText = isNewModel ? form.get('_textAddingSuccess') : form.get('_textUpdatingSuccess');

            promise.then(function (response) {

                if (typeof form.afterSubmit == 'function') {
                    form.afterSubmit.call(form, response);
                }

                form.set('_submitResult', successText);
                form.set('_submitResultType', 'success');
                form.set('_showSubmitResult', true);
                form.set('_isSubmitting', false);

                form.get('applicationAlerts').addActionMessage('success', successText);

                if (isNewModel) {
                    // jesli to jest nowy model i zostal poprawnie zapisany to uniemożliwiamy kolejny submit
                    form.set('_canSubmit', false);
                }

                // configure
                if (typeof form.customActionAfterSuccessfulSubmit == 'function') {
                    form.customActionAfterSuccessfulSubmit.call(form, response);
                } else if (typeof form.afterSuccessfulSubmit == 'function') {
                    form.afterSuccessfulSubmit.call(form, response);
                }

                if (_emberNewMixinsControllersUnsavedData['default'].detect(form.get('targetObject'))) {
                    form.get('targetObject').removeLock(form.get('parentView'));
                }

                setTimeout(function () {
                    var parentView = form.get('parentView');
                    if (parentView && parentView instanceof _emberNewComponentsBoxForForm['default'] && !parentView.isDestroyed && !parentView.isDestroying) {
                        parentView.set('editState', false);
                    }
                }, 500);
            }, function (response) {
                form._handleFormErrors(response);
            });
        },
        _handleRestSubmit: function _handleRestSubmit() {
            var model = this.get('model');
            var form = this;
            var formData = this.get('formData');

            var isNewModel = model.get('isNew');
            var exceptionText = isNewModel ? form.get('_textAddingException') : form.get('_textUpdatingException');

            var promise;

            if (isNewModel) {
                promise = model.save();
            } else {
                if (form.get('submitRestAction') === undefined || form.get('submitRestAction') == null) {
                    throw new _ember['default'].Error("No submitRestAction defined in d-form. It is required when model is REST and not new");
                }

                if (form.get('submitModelNamespace') === undefined || form.get('submitModelNamespace') == null) {
                    throw new _ember['default'].Error("No submitModelNamespace defined in d-form. It is required when model is REST and not new");
                }

                var notModelFieldsData = this._getNotModelFieldsPayload();

                promise = model.customUpdate(this.get('submitRestAction'), this.get('_modelFieldsKeys'), {}, this.get('submitModelNamespace'), notModelFieldsData);
            }

            promise.then(function () {}, function (response) {

                // czyścimy zbindowane zmiany w modelu (tylko jeśli nie jest on nowy (bez id))
                if (!isNewModel) {
                    model.rollbackAttributes();
                }

                // validation error
                if (response instanceof _emberData['default'].InvalidError) {
                    form.set('_submitResult', form.get('_textValidationError'));
                    form.set('_submitResultType', 'danger');

                    form._handleFormErrors(response);
                } else {
                    form.set('_submitResult', exceptionText);
                    form.set('_submitResultType', 'danger');
                }

                form.set('_showSubmitResult', true);
                form.set('_isSubmitting', false);
            });

            return promise;
        },
        _handleSimpleSubmit: function _handleSimpleSubmit() {
            var form = this;
            var model = this.get('model');

            var isNewModel;

            if (this.get('_isRestModel')) {
                isNewModel = model.get('isNew');
            } else {
                isNewModel = model.hasOwnProperty('id') && model.get('id') != null;
            }

            var successText = isNewModel ? form.get('_textAddingSuccess') : form.get('_textUpdatingSuccess');
            var exceptionText = isNewModel ? form.get('_textAddingException') : form.get('_textUpdatingException');

            var hasModel = this.get('_isModelBound');
            var isSimpleModel = hasModel && !this.get('_isRestModel');
            var isRestModel = hasModel && this.get('_isRestModel');

            var modelPayload = this._getModelFieldsPayload();
            var payload = this._getNotModelFieldsPayload();
            var url = this.get('submitSimpleUrl');

            if (url === undefined || url === null) {
                throw new _ember['default'].Error("When model is not a rest model, then 'submitSimpleUrl' option of d-form is required");
            }

            var modelNamespace = this.get('submitModelNamespace');
            payload[modelNamespace] = modelPayload;

            if (this.get('_devMode')) {
                console.log('payload: ', payload);
            }

            var promise = this.get('ajax').ajax(url, payload, this.submitRestAsSimpleMethod);

            promise.then(function (response) {}, function (response, responseStatus) {

                // czyścimy zbindowane zmiany w modelu (tylko jeśli nie jest on nowy (bez id))
                // oraz jesli to jest restModel, bo w tym przypadku moze byc tez Ember.Object
                if (isRestModel && !isNewModel) {
                    model.rollbackAttributes();
                }

                // validation error
                if (response.code === 400) {
                    form.set('_submitResult', form.get('_textValidationError'));
                    form.set('_submitResultType', 'danger');

                    if (response.message !== undefined) {
                        form.set('_submitResult', response.message);
                        form.set('_submitResultType', 'danger');
                    }

                    form._handleFormErrors(response);
                } else if (response.code === 403) {
                    var message = response.message ? response.message : 'Brak dostępu';
                    form.set('_submitResult', message);
                    form.set('_submitResultType', 'warning');
                } else if (response.code === 405) {
                    var message = response.message ? response.message : 'Metoda niedozwolona';
                    form.set('_submitResult', message);
                    form.set('_submitResultType', 'warning');
                } else {
                    form.set('_submitResult', exceptionText);
                    form.set('_submitResultType', 'danger');
                }

                form.set('_showSubmitResult', true);
                form.set('_isSubmitting', false);
            });

            return promise;
        },
        _handleFormErrors: function _handleFormErrors(formErrors) {

            var form = this;

            if (formErrors instanceof _emberData['default'].AdapterError) {
                if (formErrors.errors.children === undefined) {
                    throw new _ember['default'].Error("No form-errors in formErrors.errors.children");
                }

                var fieldsErrors = formErrors.errors.children;
                if (fieldsErrors instanceof Object) {
                    form.get('_fieldsKeys').forEach(function (key) {
                        if (form.hasFormRow(key)) {
                            var formRow = form.getFormRow(key);
                            if (fieldsErrors[key] !== undefined && typeof fieldsErrors[key] === 'object' && typeof fieldsErrors[key]['errors'] === 'object' && fieldsErrors[key]['errors'] instanceof Array && fieldsErrors[key]['errors'].length > 0) {
                                var fieldEr = fieldsErrors[key]['errors'];
                                formRow.setFeedback('error', fieldEr);
                            } else {}
                        }
                    });
                } else if (formErrors.errors instanceof Array) {
                    formErrors.errors.forEach(function (errorInfo) {
                        if (errorInfo.property_path == 'global') {
                            form.get('applicationAlerts').addActionMessage('danger', errorInfo.message);
                        } else {
                            if (form.hasFormRow(errorInfo.property_path)) {
                                var formRow = form.getFormRow(errorInfo.property_path);
                                formRow.setFeedback('error', errorInfo.message);
                            }
                        }
                    });
                } else {
                    throw new _ember['default'].Error("Cannot recognize form-errors format.");
                }
            } else if (typeof formErrors === "object" && formErrors.code !== undefined && formErrors.errors instanceof Object && formErrors.errors.children) {
                (function () {
                    var errors = formErrors.errors.children;

                    if (errors !== undefined && typeof errors === "object") {
                        (function () {
                            var fieldsWithErrorsKeys = Object.keys(errors);
                            form.get('_fieldsKeys').forEach(function (key) {
                                if (form.hasFormRow(key) && fieldsWithErrorsKeys.contains(key)) {
                                    var formRow = form.getFormRow(key);
                                    var fieldEr = errors[key].errors && errors[key].errors[0] ? errors[key].errors : null;

                                    if (fieldEr) {
                                        formRow.setFeedback('error', fieldEr);
                                        console.log(fieldEr);
                                    }
                                }
                            });
                        })();
                    }
                })();
            } else if (typeof formErrors === "object" && formErrors.code !== undefined && formErrors.errors instanceof Object) {

                var errors = formErrors.errors;

                /** jesli pierwszy błąd w tablicy posiada 'property_path' ustawiony na 'global', przerywamny sprawdzanie i pokazujemy komunikat **/
                if (errors[0].property_path === 'global') {
                    form.get('applicationAlerts').addActionMessage('danger', errors[0].message);
                } else {

                    form.get('_fieldsKeys').forEach(function (key) {

                        var formRow = form.getFormRow(key);
                        var fieldEr = [];

                        errors.forEach(function (item) {
                            if (item.property_path != undefined && item.property_path == key) {
                                fieldEr.push(item.message);
                            } else {
                                if (form.hasFormRow(item.property_path)) {
                                    var formRow = form.getFormRow(item.property_path);
                                    formRow.setFeedback('error', item.message);
                                }
                            }
                        });

                        if (fieldEr.length > 0) {
                            formRow.setFeedback('error', fieldEr);
                        }
                    });
                }
            }
        },

        // methods
        clearFeedback: function clearFeedback() {
            var rows = this.get('formRows');
            var rowsKeys = Object.keys(rows);

            rowsKeys.forEach(function (key) {
                var row = rows[key];
                if (row && key !== 'toString') {
                    row.clearFeedback();
                }
            });
        },

        dataChanged: function dataChanged() {
            if (_emberNewMixinsControllersUnsavedData['default'].detect(this.get('targetObject'))) {
                this.get('targetObject').addLock(this.get('parentView'));
            }
        },

        actions: {
            submit: function submit() {
                if (!this.get('_canSubmit')) {
                    this.set('_submitResult', "Nie można zapisać tego formularza.");
                    this.set('_submitResultType', 'danger');
                    return;
                }

                this._handleSubmit();
            },
            cancel: function cancel() {
                this.set('_isSubmitting', false);
            },
            edit: function edit() {}
        }
    });

    exports['default'] = FormComponent;
});
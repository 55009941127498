define('ember-new/mixins/b2b-contract/components/d-form/section-products/edit-to-condition', ['exports', 'ember', 'ember-new/templates/components/d-form'], function (exports, _ember, _emberNewTemplatesComponentsDForm) {
    exports['default'] = _ember['default'].Mixin.create({
        layout: _emberNewTemplatesComponentsDForm['default'],
        contractPrice: 'contract_price',

        afterSuccessfulSubmit: function afterSuccessfulSubmit() {
            var targetController = this.get('targetObject'); //TODO wywołanie przeładownia listy - jak będzie lista
            setTimeout(function () {
                targetController.transitionToRoute('b2b-contract.administration.section-products.list');
            }, 500);
        }
    });
});
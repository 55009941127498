define("ember-new/mixins/dashboard/d-form/blog-post-form", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Mixin.create({

    beforeModel: function beforeModel(transition) {
      window.scrollTo(0, 0); // scroll to the top of application before reloading
    },

    actions: {
      refreshModel: function refreshModel() {
        console.log('test333');
        this.refresh();
      }
    }
  });
});
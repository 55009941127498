define('ember-new/components/d-form/individual-contract/add-renouncement', ['exports', 'ember', 'ember-new/components/d-form', 'ember-new/templates/components/d-form', 'ember-new/config/environment'], function (exports, _ember, _emberNewComponentsDForm, _emberNewTemplatesComponentsDForm, _emberNewConfigEnvironment) {
    exports['default'] = _emberNewComponentsDForm['default'].extend({
        routing: _ember['default'].inject.service('-routing'),

        layout: _emberNewTemplatesComponentsDForm['default'],

        contractId: null,
        type: null,

        submitRestAsSimple: true,
        submitModelNamespace: 'renouncement',
        submitRestAsSimpleMethod: 'POST',

        submitSimpleUrl: (function () {
            return _emberNewConfigEnvironment['default'].apiUrl + '/rest/individual' + this.get('type') + 'contracts/' + this.get('contractId') + '/renouncements';
        }).property(),

        resolveContents: function resolveContents(form) {
            return new _ember['default'].RSVP.Promise(function (resolve, reject) {

                var store = form.get('store');
                var upload = store.peekAll('upload');

                _ember['default'].RSVP.Promise.all([upload]).then(function (result) {
                    var upload = result[0];
                    resolve(_ember['default'].Object.create({
                        scannedDocuments: upload,
                        scannedDocuments2: upload,
                        scannedDocuments3: upload,
                        scannedDocuments4: upload,
                        scannedDocuments5: upload
                    }));
                });
            });
        },

        customActionAfterSuccessfulSubmit: function customActionAfterSuccessfulSubmit(response) {
            var renouncementStatement = response.renouncementStatement;

            if (renouncementStatement && renouncementStatement.isVisible === true) {
                alert(renouncementStatement.message);
            }
            this.get('store').pushPayload(response);

            var controller = this.get('targetObject');
            var routeName = this.get('routing.currentRouteName');

            setTimeout(function () {
                controller.transitionToRoute(routeName.replace('.add-renouncement', ''));
            }, 1000);
        },

        isEffectualChanged: (function () {
            this.get('targetObject').renouncementStatusChanged(this.get('formData.renouncementStatus'));
        }).observes('formData.renouncementStatus'),

        _copyDataFromFormToModel: function _copyDataFromFormToModel() {

            console.log('copy');

            var model = this.get('model');
            var fields = this.get('fields');
            var uploadFields = {
                'scannedDocuments': 'scannedDocuments',
                'scannedDocuments2': 'scannedDocuments2',
                'scannedDocuments3': 'scannedDocuments3',
                'scannedDocuments4': 'scannedDocuments4',
                'scannedDocuments5': 'scannedDocuments5'
            };
            var formData = this.get('formData');
            var _this = this;
            var form = this;

            Object.keys(fields).forEach(function (key) {
                var value;

                var hasAttributeInModel = false;

                if (_this.get('_isRestModel')) {
                    hasAttributeInModel = _ember['default'].get(model.constructor, 'attributes').has(key) || _ember['default'].get(model.constructor, 'relationshipNames').belongsTo.indexOf(key) > -1 || _ember['default'].get(model.constructor, 'relationshipNames').hasMany.indexOf(key) > -1;
                } else {
                    hasAttributeInModel = model.hasOwnProperty(key);
                }

                if (hasAttributeInModel) {

                    if (_this.hasTransformerFor(key)) {
                        var transformer = _this.getTransformerFor(key);
                        value = transformer.reverseTransform.call(form, formData.get(key));
                    } else {
                        value = formData.get(key);
                    }

                    model.set(key, value);
                }
            });

            Object.keys(uploadFields).forEach(function (key) {
                var value;

                var hasAttributeInModel = false;

                if (_this.get('_isRestModel')) {
                    hasAttributeInModel = _ember['default'].get(model.constructor, 'attributes').has(key) || _ember['default'].get(model.constructor, 'relationshipNames').belongsTo.indexOf(key) > -1 || _ember['default'].get(model.constructor, 'relationshipNames').hasMany.indexOf(key) > -1;
                } else {
                    hasAttributeInModel = model.hasOwnProperty(key);
                }

                if (hasAttributeInModel) {

                    if (_this.hasTransformerFor(key)) {
                        var transformer = _this.getTransformerFor(key);
                        value = transformer.reverseTransform.call(form, formData.get(key));
                    } else {
                        value = formData.get(key);
                    }

                    if (typeof value === 'string') {
                        model.set(key, _this.get('store').peekRecord('upload', value));
                    }
                }
            });
        }

    });
});
define('ember-new/components/bt-panel', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        icon: '',
        type: 'default', // default, primary, success, info, warning or danger

        // Extra CSS classes...
        panelClasses: '',
        headingClasses: '',
        bodyClasses: '',

        title: false

    });
});
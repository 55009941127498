define('ember-new/mixins/controllers/individual-contract/add-gas', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Mixin.create({
        isGas: true,
        renouncementDetailsRoute: 'individual-contract.add-gas.renouncement-details',

        customRequestData: {
            makeDualFuel: false
        },
        afterSuccess: function afterSuccess(response) {
            var contract = this.get('model');

            this.transitionToRoute('individual-contract.show-gas.client', contract.get('id'));
        }
    });
});
define('ember-new/controllers/individual-contract/change-contract-parameters', ['exports', 'ember', 'ember-new/utils/validators', 'ember-new/config/environment', 'ember-new/mixins/individual-contract/multiple-contract-changes'], function (exports, _ember, _emberNewUtilsValidators, _emberNewConfigEnvironment, _emberNewMixinsIndividualContractMultipleContractChanges) {
    var computed = _ember['default'].computed;
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsIndividualContractMultipleContractChanges['default'], {

        init: function init() {
            this._super.apply(this, arguments);

            this._initTpaSpecialistsToSelect();
        },

        dictionariesManager: _ember['default'].inject.service('dictionaries-manager'),

        productBool: false,
        signDateBool: false,
        discountBool: false,
        startDateBool: false,
        statusBool: false,
        tpaSpecialistBool: false,
        commentBool: false,

        product: null,
        signDate: moment(),
        startDate: moment(),
        status: null,
        discountUuid: null,
        discountString: null,

        showFormForCopied: false,
        discountType: null,
        tpaSpecialist: null,
        commentVisibility: null,
        commentText: null,

        setParamsUrl: computed(function () {
            return _emberNewConfigEnvironment['default'].apiUrl + '/rest/changecontractsparameters';
        }),
        checkParamsUrl: computed(function () {
            return _emberNewConfigEnvironment['default'].apiUrl + '/rest/changecontractsparametersbulkactionconfirmationviewmodels';
        }),

        discountTypeList: computed('discountType', function () {
            var discountType = this.get('discountType');
            if (discountType === '1') {
                this.set('discountString', null);
                return true;
            }
            return false;
        }),

        discountTypeString: computed('discountType', function () {
            var discountType = this.get('discountType');
            if (discountType === '2') {
                this.set('discountUuid', null);
                return true;
            }
            return false;
        }),

        canDoBulkActionsFiltered: computed('filters', function () {
            var filters = this.get('filters');

            if (filters && Object.keys(filters).length > 0) {
                return true;
            }
            return false;
        }),

        // do przycisku akcji zmiany parametrów umowy
        canCheckAction: computed('validDataCount', 'productBool', 'product', 'signDateBool', 'signDate', 'discountBool', 'discountUuid', 'discountString', 'startDateBool', 'startDate', 'statusBool', 'status', 'tpaSpecfialistBool', 'tpaSpecialist', 'commentBool', 'commentVisibility', 'comment', function () {
            return this.get('validDataCount') > 0 && (this.get('productBool') && this.get('product') || this.get('signDateBool') && this.get('signDate') || this.get('discountBool') && (this.get('discountUuid') || this.get('discountString')) || this.get('startDateBool') && this.get('startDate') || this.get('statusBool') && this.get('status') || this.get('tpaSpecialistBool') && this.get('tpaSpecialist') || this.get('commentBool') && this.get('commentVisibility') && this.get('comment'));
        }),

        discountTypesToSelect: [{ label: 'rabat z listy', value: 1 }, { label: 'rabat inny', value: 2 }],

        tpaSpecialistsToSelect: [],
        _initTpaSpecialistsToSelect: function _initTpaSpecialistsToSelect() {
            var _this = this;

            this.get('dictionariesManager').getUsersDictionary('dzu2_users_by_user').forEach(function (item) {
                _this.get('tpaSpecialistsToSelect').push({
                    value: item.id,
                    label: item.fullNameWithRole
                });
            });
        },

        commentVisibilityToSelect: [{ value: 'only_for_bo', label: 'Tylko dla BO' }, { value: 'ph_and_bo', label: 'Dla PH i dla BO' }],

        setValues: function setValues() {

            if (!this.get('productBool')) {
                this.set('product', null);
            }

            if (!this.get('signDateBool')) {
                this.set('signDate', '');
            }

            if (!this.get('discountBool')) {
                this.set('discountUuid', null);
                this.set('discountString', null);
            }

            if (!this.get('startDateBool')) {
                this.set('startDate', '');
            }

            if (!this.get('statusBool')) {
                this.set('status', null);
            }

            if (!this.get('tpaSpecialistBool')) {
                this.set('tpaSpecialist', null);
            }

            if (!this.get('commentBool')) {
                this.set('commentBool', null);
            }
        },

        checkParams: function checkParams(contractNos, filters) {
            var _this2 = this;

            var url = this.get('checkParamsUrl');
            var requestObject = { contractNos: contractNos, filters: filters };

            this.clearErrors();
            this.set('isLoadingCheck', true);
            this.set('checkResult', null);

            return this.get('ajax').ajaxPost(url, requestObject).then(function (res) {
                return _this2.handleCheckResponse(res);
            })['catch'](function (err) {
                return _this2.handleError(err);
            });
        },

        /**
         * CHECK:
         * @returns {*}
         */

        checkFilteredParams: function checkFilteredParams() {
            if (!this.get('canDoBulkActionsFiltered')) {
                this.get('applicationAlerts').addActionMessage('warning', 'Wybierz filtry!');
                return;
            }
            this.set('showFormForCopied', false);
            var contractNos = []; //reset ewentualnie wprowadzonych numerów
            var filters = this.get('filters');

            this.checkParams(contractNos, filters);
        },

        checkNumbersParams: function checkNumbersParams() {
            var contractNos = this.get('validData').mapBy('number'); // poprawne numery umow
            // const url = this.get('checkParamsUrl');
            var filters = null;

            if (this.get('canDoBulkActionsFiltered')) {
                this.set('showFormForCopied', false);
            }

            this.checkParams(contractNos, filters);
        },

        /**
         * SET:
         */
        setParams: function setParams() {
            var _this3 = this;

            this.setValues();
            var uuids = this.get('contractsToSetUuids');
            var reqObjName = 'ChangeContractsParams';
            var url = this.get('setParamsUrl');

            var product = this.get('product');
            var discountUuid = this.get('discountUuid');
            var discountString = this.get('discountString');
            var signDate = moment(this.get('signDate')).format('YYYY-MM-DD');
            var startDate = moment(this.get('startDate')).format('YYYY-MM-DD');
            var status = this.get('status');
            var tpaSpecialist = this.get('tpaSpecialist');
            var commentVisibility = this.get('commentVisibility');
            var commentText = this.get('commentText');

            var requestObject = {};
            requestObject[reqObjName] = {
                uuids: uuids,
                product: product,
                signDate: signDate,
                discountUuid: discountUuid,
                discountString: discountString,
                startDate: startDate,
                status: status,
                tpaSpecialist: tpaSpecialist,
                commentVisibility: commentVisibility,
                commentText: commentText
            };

            this.clearErrors();
            this.set('isLoadingSet', true);
            return this.get('ajax').ajaxPost(url, requestObject).then(function (res) {
                return _this3.handleSetResponse(res);
            })['catch'](function (err) {
                return _this3.handleError(err);
            });
        },

        clear: function clear() {
            this.clearErrors();
            this.set('checkResult', null);
            this.set('inputDataArray', []);
            this.set('productBool', false);
            this.set('signDateBool', false);
            this.set('discountBool', false);
            this.set('startDateBool', false);
            this.set('statusBool', false);
            this.set('tpaSpecialistBool', false);
            this.set('commentBool', false);
        },

        actions: {
            checkFilteredParams: function checkFilteredParams() {
                this.checkFilteredParams();
            },
            checkNumbersParams: function checkNumbersParams() {
                this.checkNumbersParams();
            },
            setParams: function setParams() {
                this.setParams();
            },

            showFormForCopied: function showFormForCopied() {
                this.set('showFormForCopied', true);
            },

            getParsedData: function getParsedData(data) {
                // this.clear();
                this.set('inputDataArray', data);
            }

        }
    });
});
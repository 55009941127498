define('ember-new/controllers/tasks/ordering-meetings', ['exports', 'ember-new/config/environment', 'ember'], function (exports, _emberNewConfigEnvironment, _ember) {
	exports['default'] = _ember['default'].Controller.extend({
		ajax: _ember['default'].inject.service('ajax'),
		savingInProgress: null,
		errors: {},
		actions: {
			save: function save() {
				var _this = this;
				this.set('savingInProgress', 'loading');
				var data = {};

				this.get('content').forEach(function (user) {
					if (Object.keys(user.changedAttributes()).length > 0) {
						data[user.get('id')] = user.get('contractedMeetingsLimit');
					}
				});

				var ajaxPromise = this.get('ajax').ajaxPost(_emberNewConfigEnvironment['default'].apiUrl + '/ajax/user/edit_ordered_meetings', { data: data });

				ajaxPromise.then(function (response) {
					_this.set('savingInProgress', null);
					alert('Zaktualizowano ilość zamówionych spotkań.');
					_this.transitionToRoute('tasks');
					//                    var affectedUsers = Ember.A(data);
					//                    affectedUsers.forEach(function(user){
					//                       user
					//                    });
				}, function (jq) {
					_this.set('savingInProgress', null);
					handleAjaxError(jq, function (jq) {
						_this.set('errors', jq.responseJSON.errors);
					});
				});
			}
		}
	});
});
define('ember-new/controllers/b2b-contract/dzu/show-ee/vas/delete', ['exports', 'ember', 'ember-new/config/environment'], function (exports, _ember, _emberNewConfigEnvironment) {
    exports['default'] = _ember['default'].Controller.extend({
        ajax: _ember['default'].inject.service('ajax'),
        applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),

        actions: {
            'delete': function _delete() {
                var serializedData = {
                    RemoveContractVasCommand: {
                        contractVasUuid: this.get('contractVasUuid')
                    }
                };

                var _this = this;
                var promise = this.get('ajax').ajaxPost(_emberNewConfigEnvironment['default'].apiUrl + '/rest/removecontractvascommands', serializedData);

                promise.then(function () {
                    _this.get('applicationAlerts').addActionMessage('success', "VAS został usunięty z umowy");

                    var targetController = _this;
                    var mainRoute = targetController.container.lookup("route:b2b-contract.dzu.show-ee.vas");
                    mainRoute.send('refreshModel');
                    setTimeout(function () {
                        targetController.transitionToRoute('b2b-contract.dzu.show-ee.vas');
                    }, 100);
                }, function () {
                    _this.get('applicationAlerts').addActionMessage('warning', "Wystąpił błąd, nie udało się usunąć VAS");
                });
            }
        }
    });
});
define('ember-new/models/individual-contract-business-tariff-list-view-model', ['exports', 'ember-data'], function (exports, _emberData) {

    var attr = _emberData['default'].attr;

    exports['default'] = _emberData['default'].Model.extend({
        uuid: attr('string'),
        name: attr('string'),
        tariffName: attr('string'),
        type: attr('string'),
        idUsys: attr('number')
    });
});
define('ember-new/components/select-box-dictionary', ['exports', 'ember', 'ember-new/components/select-box'], function (exports, _ember, _emberNewComponentsSelectBox) {
    exports['default'] = _emberNewComponentsSelectBox['default'].extend({
        classNames: ['select-box'],

        dictionariesManager: _ember['default'].inject.service('dictionaries-manager'),
        optionLabelPath: "label",
        optionValuePath: "id",

        // required options:
        dictionaryName: null,
        selected: null,

        init: function init() {
            this._super.apply(this, arguments);
            var dictionary = this._getDictionary(this.get('dictionaryName'));
            this._fillContentFromDictionary(dictionary);
            this._setSelected(dictionary);
        },

        /**
         *
         * @param dictionaryName
         * @returns {*}
         * @private
         */
        _getDictionary: function _getDictionary(dictionaryName) {
            if (dictionaryName === undefined || dictionaryName === null || typeof dictionaryName !== 'string') {
                throw new _ember['default'].Error('Component select-box-dictionary requires `dictionaryName` option.');
            }

            var dicManager = this.get('dictionariesManager');

            if (!dicManager.hasMainDictionary(dictionaryName)) {
                throw new _ember['default'].Error("Option 'dictionaryName` passed to select-box-dictionary component is wrong. " + "There is no `" + dictionaryName + "` main-dictionary.");
            }

            return dicManager.getMainDictionary(dictionaryName);
        },

        /**
         *
         * @param dictionary
         * @private
         */
        _fillContentFromDictionary: function _fillContentFromDictionary(dictionary) {
            var _this = this;

            var content = dictionary.filter(function (item) {
                return item.get('isHidden') !== true;
            }).map(function (item) {
                return _ember['default'].Object.create({
                    value: item.get(_this.get('optionValuePath')),
                    label: item.get(_this.get('optionLabelPath'))
                });
            });
            this.set('content', content);
        },
        _setSelected: function _setSelected() {
            var content = this.get('content');
        }
    });
});
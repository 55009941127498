define('ember-new/utils/ember-share-local-storage', ['exports', 'ember', 'ember-new/config/environment', 'ember-new/utils/ajax/get', 'ic-ajax'], function (exports, _ember, _emberNewConfigEnvironment, _emberNewUtilsAjaxGet, _icAjax) {

    var EmberShareLocalStorage = _ember['default'].Object.extend({
        getToken: function getToken() {
            return localStorage.getItem('emberJWToken');
        },
        setToken: function setToken(token) {
            localStorage.setItem('emberJWToken', token);
        }
    });

    exports['default'] = EmberShareLocalStorage;
});
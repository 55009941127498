define('ember-new/controllers/b2b-contract/dzu/show-gas/prices/edit-condition', ['exports', 'ember', 'ember-new/mixins/b2b-contract/controllers/dzu-show-prices-add-price'], function (exports, _ember, _emberNewMixinsB2bContractControllersDzuShowPricesAddPrice) {
  exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsB2bContractControllersDzuShowPricesAddPrice['default'], {

    indexVisible: (function () {
      return this.get('model.productType') == 'index_gas';
    }).property('model.productType'),

    indexExpectedPriceVisible: (function () {
      return this.get('model.indexExpectedPriceVisible');
    }).property('model.indexExpectedPriceVisible'),

    discountVisible: (function () {
      return this.get('model.productType') == 'constantPrice';
    }).property('model.productType'),

    discountTypes: [{ id: 'discount_type_eex', name: 'EEX' }, { id: 'discount_type_tge', name: 'TGE' }],

    whiteCalculationMethodTypes: [{ id: 'calculation_method_standard', name: 'Standard' }, { id: 'calculation_method_individual', name: 'Indywidualny' }, { id: 'calculation_method_0', name: 'O' }],

    isSubscriptionValueHidden: (function () {
      if (this.get('subscriptionType') === '92caf039-324e-496f-8603-26ef7d1863fc' || this.get('subscriptionType') === 'a8a80e31-8382-4593-8b24-f2ffb2f27a45' || this.get('subscriptionType') === 'd5ba7d3a-fb8d-4978-820d-1f8ff72635d7') {
        return false;
      }
      this.set('subscriptionValue', '');

      return true;
    }).property('subscriptionType'),

    fillForm: function fillForm(form) {
      form.set('model.id', this.get('model.id'));
      form.set('model.resignation', this.get('model.resignation'));
      form.set('model.indexProfileFactor', this.get('model.indexProfileFactor'));
      form.set('model.indexExpectedPrice', this.get('model.indexExpectedPrice'));
      form.set('model.subscriptionType', this.get('model.subscriptionTypeUuid'));
      form.set('model.subscriptionValue', this.get('model.subscriptionValue'));
      form.set('model.orderInvoiceNumber', this.get('model.orderInvoiceNumber'));
      form.set('model.discountType', this.get('model.discountType'));
      form.set('model.discountDivision', this.get('model.discountDivision'));
      form.set('model.discountBoundry', this.get('model.discountBoundry'));
      form.set('model.whiteRedeemption', this.get('model.whiteRedeemption'));
      form.set('model.whiteCalculationMethod', this.get('model.whiteCalculationMethod'));
      form.set('model.whiteInPriceCost', this.get('model.whiteInPriceCost'));
      form.set('model.whiteActualCost', this.get('model.whiteActualCost'));
    },

    afterCommand: function afterCommand(response) {
      var ctrl = this;
      var route = this.container.lookup("route:b2b-contract.dzu.show-gas.prices");
      route.refresh();
      setTimeout(function () {
        ctrl.transitionToRoute('b2b-contract.dzu.show-gas.prices');
      }, 500);
    }

  });
});
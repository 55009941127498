define('ember-new/models/ppg-view-model', ['exports', 'ember-new/models/abstract', 'ember-data'], function (exports, _emberNewModelsAbstract, _emberData) {

    var attr = _emberData['default'].attr;
    var refOne = _emberData['default'].belongsTo;

    exports['default'] = _emberNewModelsAbstract['default'].extend({
        uuid: attr('string'),

        // Adres
        streetName: attr('string'),
        streetNumber: attr('string'),
        postalCode: attr('string'),
        postalCity: attr('string'),
        apartmentNumber: attr('string'),
        city: attr('string'),
        districtUuid: attr('string'),
        district: attr('string'),

        // Dane PPE
        pointNumber: attr('string'),
        deviceNumber: attr('string'),
        registrationNumber: attr('string'),
        pointNumberForWinback: attr('string'),
        isNewConnection: attr('boolean'),
        osdName: attr('string'),
        osdSectionName: attr('string'),
        billingFrequencyName: attr('string'),
        sellerChangeUuid: attr('string'),
        sellerChange: attr('string'),

        // Dane sprzedażowe
        tariffGroupName: attr('string'),
        tariffGroupChange: attr('boolean'),
        isDataUpdated: attr('boolean'),
        os: refOne('osDictionaryEntry'),
        osUuid: attr('string'),
        osName: attr('string'),
        osd: attr('string'),
        osdUuid: attr('string'),
        osdTariffGroup: attr('string'),
        osdTariffGroupUuid: attr('string'),
        pgnigTariffGroup: attr('string'),
        pgnigTariffGroupUuid: attr('string'),
        fortumTariffGroup: attr('string'),
        fortumTariffGroupUuid: attr('string'),
        contractualPower: attr('string'),
        subscription: attr('string'),
        subscriptionUuid: attr('string'),
        subscriptionValue: attr('number'),
        subscriptionLabel: attr('string'),
        startDate: attr('string'),
        endDate: attr('string'),
        ppgDatesType: attr('string'),
        dateChooseTypeUuid: attr('string'),
        dateChooseType: attr('string'),
        numberOfMonths: attr('number'),
        contractMonths: attr('number'),
        deliveredComprehensiveAgreement: attr('boolean'),
        annexPriceFromLoyalty: attr('number'),
        discount: attr("boolean"),
        priceFromLoyalty: attr('number'),
        minimalPricePricelist: attr('number'),
        correctionRatio: attr('string'),
        correctionUuid: attr('string'),
        loyalty: attr('string'),
        loyaltyUuid: attr('string'),
        loyaltyDisplayLabel: attr('string'),
        loyaltyEnd: attr('string'),
        loyaltyStart: attr('string'),
        dismissPeriod: attr('number'),
        consumptionCalculationMethod: attr('string'),
        consumptionCalculationMethodUuid: attr('string'),
        reserveAgreement: attr('string'),
        isContractTerminating: attr('boolean'),
        energyDestiny: attr('string'),
        energyDestinyUuid: attr('string'),
        noticePeriodTimeUuid: attr('string'),
        noticePeriodTime: attr('string'),
        settlementRule: attr('string'),
        settlementRuleUuid: attr('string'),
        contractAndPpmEndDate: attr('string'),
        isDateChooseFromEndOfContract: attr('boolean'),
        isManualMm: attr('boolean'),
        manualMm: attr('string'),
        isManualMmByContract: attr('boolean'),
        terminationDate: attr('string'),

        //miesiące

        january: attr('number'),
        february: attr('number'),
        march: attr('number'),
        april: attr('number'),
        may: attr('number'),
        june: attr('number'),
        july: attr('number'),
        august: attr('number'),
        september: attr('number'),
        october: attr('number'),
        november: attr('number'),
        december: attr('number'),

        //akcyzy
        fuelExcise: attr('number'),
        engineExcise: attr('number'),
        exciseLeaseholdA1: attr('number'),
        exciseLeaseholdA2: attr('number'),
        exciseLeaseholdA3: attr('number'),
        exciseLeaseholdA4: attr('number'),
        exciseLeaseholdA5: attr('number'),
        exciseLeaseholdA6: attr('number'),
        exciseLeaseholdA7: attr('number'),
        exciseLeaseholdA8: attr('number'),
        exciseLeaseholdB1: attr('number'),
        exciseLeaseholdB2: attr('number'),
        exciseLeaseholdB3: attr('number'),
        exciseLeaseholdB4: attr('number'),
        exciseLeaseholdB5: attr('number'),
        exciseLeaseholdB6: attr('number'),
        excisesSum: attr('number')

    });
});
define('ember-new/models/individual-contract-template-vap-list-view-model', ['exports', 'ember-data'], function (exports, _emberData) {

    var attr = _emberData['default'].attr;

    exports['default'] = _emberData['default'].Model.extend({
        uuid: attr('string'),
        templateUuid: attr('string'),
        vapUuid: attr('string'),
        name: attr('string'),
        type: attr('string'),
        typeString: attr('string'),
        basePrice: attr('number'),
        customPrice: attr('number'),
        idUsys: attr('number'),
        isRequired: attr('boolean'),
        validityPeriod: attr('string'),
        numberOfMonths: attr('number'),
        pikaCode: attr('string')
    });
});
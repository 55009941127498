define('ember-new/services/dictionaries-manager', ['exports', 'ember', 'ember-new/models/dictionary-types-mappings'], function (exports, _ember, _emberNewModelsDictionaryTypesMappings) {
	exports['default'] = _ember['default'].Service.extend({
		store: _ember['default'].inject.service(),

		excludedDictionaryTypesToAdd: [// deprecated dictionaries - not to add a new one entry
		'plain', 'default', 'sales_region', 'product', 'gas_product', 'individual_contract_category', 'individual_ee_tariff_group', 'individual_gas_tariff_group', 'status_definition'],

		usersDictionaries: {},
		mainDictionariesTypes: null,
		statusTypes: ['individual_contract', 'process_contract', 'amb_process_acq', 'visible_for_BO', 'set_by_partner', 'visible_for_partner'],
		mainDictionariesEntriesNamesByUuid: [],
		setup: function setup(mainDictionaries, statusDictionaries, periodsDictionaries, usersDictionaries, weeksDictionaries, mainDictionariesForUser) {
			var store = this.get('store');

			store.pushPayload(mainDictionaries);
			store.pushPayload(statusDictionaries);
			store.pushPayload(periodsDictionaries);
			store.pushPayload(weeksDictionaries);
			store.pushPayload(mainDictionariesForUser);

			this.set('usersDictionaries', usersDictionaries);
			this.set('mainDictionariesTypes', Object.keys(_emberNewModelsDictionaryTypesMappings['default']));
		},
		getUsersDictionary: function getUsersDictionary(name) {
			var dictionaries = this.get('usersDictionaries');
			if (this.hasUsersDictionary(name)) {
				return dictionaries[name];
			} else {
				return [];
			}
		},
		hasUsersDictionary: function hasUsersDictionary(name) {
			var dictionaries = this.get('usersDictionaries');
			return dictionaries.hasOwnProperty(name);
		},
		hasMainDictionary: function hasMainDictionary(type) {
			return this.get('mainDictionariesTypes').contains(type);
		},
		hasStatusCollection: function hasStatusCollection(type) {
			return this.get('statusTypes').contains(type);
		},
		getMainDictionary: function getMainDictionary(type, query) {
			if (!this.hasMainDictionary(type)) {
				throw new _ember['default'].Error("There is no main-dictionary such as '" + type + "'");
			}

			var modelName = _emberNewModelsDictionaryTypesMappings['default'][type];
			var entries = this.get('store').peekAll(modelName);
			return entries;
		},
		getMainDictionaryEntryByMongoId: function getMainDictionaryEntryByMongoId(type, id) {
			if (!this.hasMainDictionary(type)) {
				throw new _ember['default'].Error("There is no main-dictionary such as '" + type + "'");
			}

			var modelName = _emberNewModelsDictionaryTypesMappings['default'][type];
			return this.get("store").peekRecord(modelName, id);
		},
		getPeriodsDictionary: function getPeriodsDictionary() {},
		getStatusCollection: function getStatusCollection(type) {
			if (!this.hasStatusCollection(type)) {
				throw new _ember['default'].Error("There is no status-dictionary such as '" + type + "'");
			}

			var store = this.get('store');

			return store.peekAll('status-definition').filter(function (item) {
				return item.get('category') === type;
			});
		},
		getWeeksDictionary: function getWeeksDictionary() {
			return this.get('store').peekAll('Week');
		},
		getWeek: function getWeek(id) {
			return this.get('store').peekRecord('Week', id);
		},
		getCurrentWeek: function getCurrentWeek() {
			var weeks = this.getWeeksDictionary();
			return weeks.objectAt(0);
		},

		getDictionariesTypesListToAdd: function getDictionariesTypesListToAdd() {
			var types = _ember['default'].A();
			var typesToChoose = _ember['default'].A(Object.keys(_emberNewModelsDictionaryTypesMappings['default']));
			typesToChoose.removeObjects(this.get('excludedDictionaryTypesToAdd')); // exclude some configured dictionary types

			typesToChoose.forEach(function (value) {
				types.pushObject({
					label: value,
					value: value
				});
			});

			return types;
		},

		getDictionariesTypesListToFilter: function getDictionariesTypesListToFilter() {
			var types = _ember['default'].A();
			var typesToChoose = _ember['default'].A(Object.keys(_emberNewModelsDictionaryTypesMappings['default']));

			typesToChoose.forEach(function (value) {
				types.pushObject({
					label: value,
					value: value
				});
			});

			return types;
		}

	});
});
define("ember-new/controllers/admin/dictionaries", ["exports", "ember-new/mixins/filterable-controller", "ember-new/mixins/paginable-controller", "ember-new/config/environment", "ember", "ember-new/-extensions/array-controller-evented"], function (exports, _emberNewMixinsFilterableController, _emberNewMixinsPaginableController, _emberNewConfigEnvironment, _ember, _emberNewExtensionsArrayControllerEvented) {
				exports["default"] = _emberNewExtensionsArrayControllerEvented["default"].extend(_emberNewMixinsFilterableController["default"], _emberNewMixinsPaginableController["default"], {

								// properties for filter params
								uuid: '',
								mongoId: '',
								name: '',
								type: '',

								typesList: [],

								// maps query params to class properties
								queryParams: {
												uuid: 'uuid',
												mongoId: 'mongoId',
												name: 'name',
												type: 'type'
								},

								// virtual/computed property - boolean - tells if there some results or not
								hasContracts: _ember["default"].computed('model', function () {
												return this.get('model.length') > 0;
								}),

								// set default itemsPerPage (property pro PaginableControllerMixin)
								itemsPerPage: 25,

								isLoading: true,

								actions: {
												filterBy: function filterBy(filterName, selection) {
																var val = selection ? selection.value : '';
																this.set(filterName, val);
												},
												showLoading: function showLoading() {
																this.set('isLoading', true);
												},
												hideLoading: function hideLoading() {
																this.set('isLoading', false);
												}
								}
				});
});
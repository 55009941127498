define('ember-new/routes/individual-contract-price-lists/template/vaps/edit', ['exports', 'ember', 'ember-new/routes/authenticated', 'ember-new/mixins/individual-contract-price-lists/controllers/vap'], function (exports, _ember, _emberNewRoutesAuthenticated, _emberNewMixinsIndividualContractPriceListsControllersVap) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend(_emberNewMixinsIndividualContractPriceListsControllersVap['default'], {
        model: function model(params) {
            var content = this.store.query('IndividualContractTemplateVapListViewModel', { templateUuid: this.modelFor('individual-contract-price-lists.template.vaps').template.id, vapUuid: params.vapUuid }, { reload: true });

            return content;
        },

        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            }
        }
    });
});
define('ember-new/components/drop-zone-uuid', ['exports', 'ember', 'ember-new/config/environment', 'ember-new/mixins/d-form/d-field'], function (exports, _ember, _emberNewConfigEnvironment, _emberNewMixinsDFormDField) {
    exports['default'] = _ember['default'].Component.extend(_emberNewMixinsDFormDField['default'], {

        cookies: _ember['default'].inject.service(),
        applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),

        layoutName: 'components/drop-zone',
        classNames: [],
        tagName: 'div',
        dropzone: null,
        uploadUrl: null,
        fileTypes: 'image/*,application/pdf',

        uploadedFieldKeys: [],

        onInit: (function () {
            this.set('uploadedFieldKeys', []);
        }).on('init'),

        initDropzone: (function () {

            var headers = { "Authorization": 'Bearer ' + this.get('TheUser').get('token') };

            var impersonatingUser = this.get('cookies').read('impersonatingUser');
            if (impersonatingUser) {
                headers['X-Switch-User'] = impersonatingUser;
            }

            var component = this;
            var domElement = this.get('element');
            var dropzoneElement = $(domElement).find('.dropzone');
            var dropzone = new window.Dropzone(dropzoneElement.get(0), {
                url: _emberNewConfigEnvironment['default'].apiUrl + '/upload/uuid',
                method: 'post',
                paramName: 'upload[file]',
                maxFilesize: 512,
                acceptedFiles: component.fileTypes,
                uploadMultiple: false,
                autoProcessQueue: true,
                //translation
                dictDefaultMessage: "Upuść tutaj pliki lub kliknij aby wybrać",
                headers: headers
            });
            this.set('dropzone', dropzone);
            var _this = this;
            dropzone.on('success', function (file) {
                _this.notifyObserverSelects(file);
            });
            dropzone.on('error', function (file, msg, err) {
                _this.get('applicationAlerts').addActionMessage('danger', msg.error);
                console.log(file, msg, err);

                // Zostawiam w wypadku jakby klient chciał usuwać automatycznie niepoprawne pliki.
                // return (_ref = file.previewElement) != null ? _ref.parentNode.removeChild(file.previewElement) : void 0;
            });
        }).on('didInsertElement'),
        actions: {
            uploadFiles: function uploadFiles() {
                this.get('dropzone').processQueue();
            }
        },

        notifyObserverSelects: function notifyObserverSelects(file) {
            var responsetext = JSON.parse(file.xhr.responseText);
            var form = this.get('form');
            var _this = this;
            this.get('uploadedFieldKeys').forEach(function (item) {
                _this.get('store').pushPayload(responsetext);
                // var record = _this.get('store').peekRecord('upload', responsetext.upload[0].id);
                //form.addContentFor(item, record);
                form.filterContentFor(item, false);
            });
        }
    });
});
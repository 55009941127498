define('ember-new/models/contract-header-salesman-view-model', ['exports', 'ember-data', 'ember-new/models/abstract'], function (exports, _emberData, _emberNewModelsAbstract) {

    var attr = _emberData['default'].attr;

    exports['default'] = _emberData['default'].Model.extend({
        companyName: attr('string'),
        nip: attr('string'),
        status: attr('string'),
        clientId: attr('string')
    });
});
define('ember-new/components/navigation/li-logged-user', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'li',
    classNames: 'dropdown user user-menu',

    actions: {
      logout: function logout() {
        this.get('session').invalidate();
      }
    }
  });
});
define('ember-new/models/edit-ee-price-condition-params-view-model', ['exports', 'ember-new/models/abstract', 'ember-data'], function (exports, _emberNewModelsAbstract, _emberData) {

    var attr = _emberData['default'].attr;
    var refOne = _emberData['default'].belongsTo;

    exports['default'] = _emberNewModelsAbstract['default'].extend({
        propertyRights: _emberData['default'].attr('string'),
        indexExpectedPrice: _emberData['default'].attr('string'),
        indexMarketBonus: _emberData['default'].attr('string'),
        wbFactor: _emberData['default'].attr('string'),
        indexExpectedPriceVisible: _emberData['default'].attr('boolean'),
        indexMarketBonusVisible: _emberData['default'].attr('boolean')
    });
});
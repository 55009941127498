define("ember-new/routes/b2b-contract/administration/section-products/add-product-to-contract", ["exports", "ember-new/routes/authenticated", "ember"], function (exports, _emberNewRoutesAuthenticated, _ember) {
    exports["default"] = _emberNewRoutesAuthenticated["default"].extend({

        model: function model() {
            // this.store.unloadAll('EePriceConditionProductListViewModel');
            // this.store.unloadAll('GasPriceConditionProductListViewModel');
            return _ember["default"].RSVP.hash({
                eeProducts: this.store.findAll('EePriceConditionProductListViewModel'),
                gasProducts: this.store.findAll('GasPriceConditionProductListViewModel')
            });
        },

        setupController: function setupController(ctrl, model) {
            ctrl.set('filteredProducts', null);
            ctrl.initializeEeProducts(this.store.peekAll('ee-price-condition-product-list-view-model'));
            ctrl.initializeGasProducts(this.store.peekAll('gas-price-condition-product-list-view-model'));
            ctrl.initializeEeIsIndex(this.store.peekAll('ee-price-condition-product-list-view-model'));
            ctrl.initializeGasIsIndex(this.store.peekAll('gas-price-condition-product-list-view-model'));
        }

    });
});
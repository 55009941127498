define('ember-new/controllers/application', ['exports', 'ember'], function (exports, _ember) {

    var ApplicationController = _ember['default'].Controller.extend({

        applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),

        isAdmin: (function () {
            return this.get('TheUser').model.get('mainRole') === 'ROLE_SUPER_ADMIN';
        }).property(),

        isDashboard: (function () {
            return this.get('currentPath').match(/^dashboard/i) ? true : false;
        }).property('currentPath'),

        routeChanged: (function () {
            if (this.get('currentPath').match(/^dashboard/i)) {
                $('body').attr('id', 'new_white');
            } else {
                $('body').attr('id', 'blue2');
            }
        }).observes('currentPath'),

        actionMessages: (function () {
            return this.get('applicationAlerts').get('actionMessages');
        }).property(),

        actions: {
            closeActionMessage: function closeActionMessage(index) {
                this.get('applicationAlerts').closeActionMessage(index);
            }
        }

    });

    exports['default'] = ApplicationController;
});
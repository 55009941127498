define('ember-new/models/business-contract-gas-invoice-dzu-view-model', ['exports', 'ember-new/models/abstract', 'ember-new/mixins/models/business-contract-ppm-view-model', 'ember-new/models/base-statusable-document-mixin', 'ember-data'], function (exports, _emberNewModelsAbstract, _emberNewMixinsModelsBusinessContractPpmViewModel, _emberNewModelsBaseStatusableDocumentMixin, _emberData) {

    var string = _emberData['default'].attr('string');
    var number = _emberData['default'].attr('number');

    exports['default'] = _emberNewModelsAbstract['default'].extend(_emberNewModelsBaseStatusableDocumentMixin['default'], _emberNewMixinsModelsBusinessContractPpmViewModel['default'], {
        uuid: string,
        consumption: number,
        dateFrom: string,
        dateTo: string
    });
});
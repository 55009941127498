define('ember-new/components/profile-password-form', ['exports', 'ember'], function (exports, _ember) {

  var ProfilePasswordFormComponent = _ember['default'].Component.extend({

    fillForm: function fillForm(form) {
      form.set('model.oldPassword', null);
      form.set('model.plainPassword', null);
      form.set('model.plainPassword2', null);
    },

    profilePasswordUrl: (function () {
      return '/ajax/profile/edit/password';
    }).property()

  });

  exports['default'] = ProfilePasswordFormComponent;
});
define("ember-new/routes/b2b-contract/dzu/show-gas/vas/delete", ["exports", "ember-new/routes/authenticated"], function (exports, _emberNewRoutesAuthenticated) {
    exports["default"] = _emberNewRoutesAuthenticated["default"].extend({

        model: function model(params) {
            return params.vasUuid;
        },

        setupController: function setupController(controller, model) {
            controller.set('contractVasUuid', model);
        }
    });
});
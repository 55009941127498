define('ember-new/controllers/renouncement/show', ['exports', 'ember', 'ember-new/config/environment'], function (exports, _ember, _emberNewConfigEnvironment) {
    exports['default'] = _ember['default'].Controller.extend({

        renouncementStatement: null,

        init: (function () {
            if (this.get('renouncementStatement') && this.get('renouncementStatement.isVisible')) {
                alert(this.get('renouncementStatement.message'));
            }
        }).observes('renouncementStatement'),

        isEditable: (function () {
            var editSells = this.get('model.credentials.edit');
            var isAnonymized = this.get('model.isAnonymized');
            return editSells && !isAnonymized;
        }).property('model'),

        isAllowedToSearchContract: (function () {
            return !this.get('TheUser').isMainRoleIn(['ROLE_GDPR_SPECIALIST']) && this.isEditable;
        }).property(),

        isAllowedToAnonymized: (function () {
            return !this.get('model.isAnonymized') && this.get("TheUser").isGranted(['ROLE_GDPR_SPECIALIST']);
        }).property('isAllowedToAnonymized')
    });
});
define('ember-new/components/my-select', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        content: [],
        prompt: null,
        optionValuePath: 'value',
        optionLabelPath: 'label',
        selection: null,

        init: function init() {
            this._super();
            if (!this.get('content')) {
                this.set('content', []);
            }
        },

        onDidInsertElement: (function () {
            this.sendAction('change');
        }).on('didInsertElement'),

        nothingSelected: (function () {
            if (!this.get('selection')) {
                return true;
            }
            return false;
        }).property('selection'),

        actions: {
            change: function change() {
                var selectedIndex = this.$('select')[0].selectedIndex;
                var content = this.get('content');

                // decrement index by 1 if we have a prompt
                var hasPrompt = !!this.get('prompt');
                var contentIndex = hasPrompt ? selectedIndex - 1 : selectedIndex;
                var _selection = content[contentIndex];

                this.sendAction('willChangeAction', _selection);

                if (this.get('optionValuePath')) {
                    this.set('selection', _selection[this.get('optionValuePath')]);
                } else {
                    this.set('selection', _selection);
                }

                this.sendAction('didChangeAction', _selection);
            }
        }
    });
});
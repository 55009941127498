define('ember-new/mixins/b2b-contract/controllers/contract-vas', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Mixin.create({

        valueTypePercent: 'percent',
        valueTypePLN: 'PLN',
        inclusionTypeDiscount: 'discount',
        inclusionTypeIncrease: 'increase',
        overheadAuto: 'auto',
        overheadConst: 'const',

        fillForm: function fillForm(form) {
            form.set('model.idContract', this.get('idContract'));
            form.set('model.vas', null);
        }
    });
});
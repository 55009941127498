define("ember-new/routes/meeting-verification", ["exports", "ember-new/routes/authenticated", "ember-new/config/environment", "ember"], function (exports, _emberNewRoutesAuthenticated, _emberNewConfigEnvironment, _ember) {
    exports["default"] = _emberNewRoutesAuthenticated["default"].extend({
        // model: function (params) {
        //     return this.store.query('renouncementSimplified', params);
        // },
        // actions: {
        //     queryParamsDidChange: function () {
        //         this.refresh();
        //     },
        //
        //     refreshRoute: function () {
        //         this.refresh();
        //     }
        // }

        refreshModel: function refreshModel() {
            this.get('store').unloadAll('MeetingVerificationThuliumImport');
            this.get('store').unloadAll('MeetingVerificationThuliumImportRow');
            this.refresh();
        }
    });
});
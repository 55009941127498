define('ember-new/controllers/individual-contract/anonymization-export', ['exports', 'ember', 'ember-new/utils/encode-object-to-uri', 'ember-new/config/environment', 'ember-new/utils/utils'], function (exports, _ember, _emberNewUtilsEncodeObjectToUri, _emberNewConfigEnvironment, _emberNewUtilsUtils) {
    var computed = _ember['default'].computed;
    exports['default'] = _ember['default'].Controller.extend({

        cookies: _ember['default'].inject.service('cookies'),

        urlFields: ['source', 'dateFrom', 'dateTo'],

        source: null,
        dateFrom: null,
        dateTo: null,
        exportDisabled: true,

        sources: [{ label: 'Umowy', value: 'IndividualContract' }, { label: 'Odstąpienia', value: 'Renouncement' }],

        onInit: (function () {
            var currentDate = new Date();

            this.set('dateFrom', [currentDate.getFullYear(), ("0" + (currentDate.getMonth() + 1)).slice(-2), '01'].join('-'));
            this.set('dateTo', [currentDate.getFullYear(), ("0" + (currentDate.getMonth() + 1)).slice(-2), ("0" + currentDate.getDate()).slice(-2)].join('-'));
        }).on('init'),

        isDisabledExportButton: (function () {
            var filters = this.prepareCurrentFilters();

            this.set('exportDisabled', Object.keys(filters).length !== this.get('urlFields').length);
        }).observes('source', 'dateFrom', 'dateTo'),

        prepareCurrentFilters: function prepareCurrentFilters() {
            var _this = this;

            var fields = this.get('urlFields');
            var filters = {};

            fields.forEach(function (fieldName) {
                if (_this.get(fieldName)) {
                    filters[fieldName] = _this.get(fieldName);
                }
            });

            return filters;
        },

        buildUrl: function buildUrl(endpoint, params) {
            return endpoint + '?' + (0, _emberNewUtilsEncodeObjectToUri['default'])(params);
        },

        actions: {
            'export': function _export() {
                var filters = this.prepareCurrentFilters();

                if (!filters) {
                    return false;
                }

                window.open(_emberNewUtilsUtils['default'].generateSimpleUrl(this.buildUrl('/individual/export/anonymization', filters), '&', _emberNewConfigEnvironment['default'], this.get('TheUser'), this.get('cookies')));
            }
        }

    });
});
define('ember-new/routes/b2b-contract/dzu/show-gas/client/add-settlement-contact-person', ['exports', 'ember-new/routes/authenticated'], function (exports, _emberNewRoutesAuthenticated) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({

        model: function model(params) {
            var contract = this.modelFor('b2b-contract.dzu.show-gas');
            this.set('idContract', contract.get('id'));

            return this.store.findRecord('ContractClientDzuViewModel', contract.get('id'));
        },

        setupController: function setupController(controller, model) {
            controller.set('idContract', this.get('idContract'));
            controller.set('model', model);
        }

    });
});
define("ember-new/templates/individual-contract/partials/add-renouncement", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 9,
                "column": 24
              },
              "end": {
                "line": 11,
                "column": 24
              }
            },
            "moduleName": "ember-new/templates/individual-contract/partials/add-renouncement.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "d-field-select-dictionary", [], ["dictionaryName", "renouncement_status", "key", "renouncementStatus"], ["loc", [null, [10, 28], [10, 119]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 13,
                "column": 24
              },
              "end": {
                "line": 18,
                "column": 24
              }
            },
            "moduleName": "ember-new/templates/individual-contract/partials/add-renouncement.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "alert alert-warning");
            var el2 = dom.createTextNode("\n                                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h4");
            var el3 = dom.createElement("i");
            dom.setAttribute(el3, "class", "fa fa-warning");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" Uwaga!");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                Odstąpienie powinno być nieskuteczne, bo klient nie może odstapić - patrz miejsce zawarcia umowy.\n                            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 24
              },
              "end": {
                "line": 22,
                "column": 24
              }
            },
            "moduleName": "ember-new/templates/individual-contract/partials/add-renouncement.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "d-field-select-dictionary", [], ["key", "source", "dictionaryName", "renouncement_source"], ["loc", [null, [21, 28], [21, 107]]]]],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 23,
                "column": 24
              },
              "end": {
                "line": 25,
                "column": 24
              }
            },
            "moduleName": "ember-new/templates/individual-contract/partials/add-renouncement.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "d-field-date", [], ["key", "dateEnter"], ["loc", [null, [24, 28], [24, 60]]]]],
          locals: [],
          templates: []
        };
      })();
      var child4 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 26,
                "column": 24
              },
              "end": {
                "line": 28,
                "column": 24
              }
            },
            "moduleName": "ember-new/templates/individual-contract/partials/add-renouncement.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "d-field-date", [], ["key", "datePostmark"], ["loc", [null, [27, 28], [27, 63]]]]],
          locals: [],
          templates: []
        };
      })();
      var child5 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 29,
                "column": 24
              },
              "end": {
                "line": 31,
                "column": 24
              }
            },
            "moduleName": "ember-new/templates/individual-contract/partials/add-renouncement.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "d-field-switch", [], ["key", "requiresResponse"], ["loc", [null, [30, 28], [30, 69]]]]],
          locals: [],
          templates: []
        };
      })();
      var child6 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 32,
                "column": 24
              },
              "end": {
                "line": 34,
                "column": 24
              }
            },
            "moduleName": "ember-new/templates/individual-contract/partials/add-renouncement.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "d-field-switch", [], ["key", "hasResponse"], ["loc", [null, [33, 28], [33, 64]]]]],
          locals: [],
          templates: []
        };
      })();
      var child7 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 35,
                "column": 24
              },
              "end": {
                "line": 37,
                "column": 24
              }
            },
            "moduleName": "ember-new/templates/individual-contract/partials/add-renouncement.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "d-field-text", [], ["key", "comment"], ["loc", [null, [36, 28], [36, 58]]]]],
          locals: [],
          templates: []
        };
      })();
      var child8 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 38,
                "column": 24
              },
              "end": {
                "line": 40,
                "column": 24
              }
            },
            "moduleName": "ember-new/templates/individual-contract/partials/add-renouncement.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "drop-zone", [], ["key", "contractRenouncementAddDropzone"], ["loc", [null, [39, 28], [39, 79]]]]],
          locals: [],
          templates: []
        };
      })();
      var child9 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 41,
                "column": 24
              },
              "end": {
                "line": 43,
                "column": 24
              }
            },
            "moduleName": "ember-new/templates/individual-contract/partials/add-renouncement.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "d-field-select-uploaded", [], ["key", "scannedDocuments", "dropzoneKey", "contractRenouncementAddDropzone"], ["loc", [null, [42, 28], [42, 124]]]]],
          locals: [],
          templates: []
        };
      })();
      var child10 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 44,
                "column": 24
              },
              "end": {
                "line": 47,
                "column": 24
              }
            },
            "moduleName": "ember-new/templates/individual-contract/partials/add-renouncement.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "d-field-select-uploaded", [], ["key", "scannedDocuments2", "dropzoneKey", "contractRenouncementAddDropzone"], ["loc", [null, [45, 28], [46, 101]]]]],
          locals: [],
          templates: []
        };
      })();
      var child11 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 48,
                "column": 24
              },
              "end": {
                "line": 51,
                "column": 24
              }
            },
            "moduleName": "ember-new/templates/individual-contract/partials/add-renouncement.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "d-field-select-uploaded", [], ["key", "scannedDocuments3", "dropzoneKey", "contractRenouncementAddDropzone"], ["loc", [null, [49, 28], [50, 101]]]]],
          locals: [],
          templates: []
        };
      })();
      var child12 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 52,
                "column": 24
              },
              "end": {
                "line": 55,
                "column": 24
              }
            },
            "moduleName": "ember-new/templates/individual-contract/partials/add-renouncement.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "d-field-select-uploaded", [], ["key", "scannedDocuments4", "dropzoneKey", "contractRenouncementAddDropzone"], ["loc", [null, [53, 28], [54, 101]]]]],
          locals: [],
          templates: []
        };
      })();
      var child13 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 56,
                "column": 24
              },
              "end": {
                "line": 59,
                "column": 24
              }
            },
            "moduleName": "ember-new/templates/individual-contract/partials/add-renouncement.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "d-field-select-uploaded", [], ["key", "scannedDocuments5", "dropzoneKey", "contractRenouncementAddDropzone"], ["loc", [null, [57, 28], [58, 101]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 20
            },
            "end": {
              "line": 60,
              "column": 20
            }
          },
          "moduleName": "ember-new/templates/individual-contract/partials/add-renouncement.hbs"
        },
        isEmpty: false,
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(14);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          morphs[3] = dom.createMorphAt(fragment, 5, 5, contextualElement);
          morphs[4] = dom.createMorphAt(fragment, 6, 6, contextualElement);
          morphs[5] = dom.createMorphAt(fragment, 7, 7, contextualElement);
          morphs[6] = dom.createMorphAt(fragment, 8, 8, contextualElement);
          morphs[7] = dom.createMorphAt(fragment, 9, 9, contextualElement);
          morphs[8] = dom.createMorphAt(fragment, 10, 10, contextualElement);
          morphs[9] = dom.createMorphAt(fragment, 11, 11, contextualElement);
          morphs[10] = dom.createMorphAt(fragment, 12, 12, contextualElement);
          morphs[11] = dom.createMorphAt(fragment, 13, 13, contextualElement);
          morphs[12] = dom.createMorphAt(fragment, 14, 14, contextualElement);
          morphs[13] = dom.createMorphAt(fragment, 15, 15, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "d-form-row", [], ["label", "Skuteczne"], 0, null, ["loc", [null, [9, 24], [11, 39]]]], ["block", "if", [["get", "renouncementWarning", ["loc", [null, [13, 30], [13, 49]]]]], [], 1, null, ["loc", [null, [13, 24], [18, 31]]]], ["block", "d-form-row", [], ["label", "Źródło"], 2, null, ["loc", [null, [20, 24], [22, 39]]]], ["block", "d-form-row", [], ["label", "Data wpływu"], 3, null, ["loc", [null, [23, 24], [25, 39]]]], ["block", "d-form-row", [], ["label", "Data stempla"], 4, null, ["loc", [null, [26, 24], [28, 39]]]], ["block", "d-form-row", [], ["label", "Czy trzeba odpowiedzieć"], 5, null, ["loc", [null, [29, 24], [31, 39]]]], ["block", "d-form-row", [], ["label", "Czy odpowiedź udzielona"], 6, null, ["loc", [null, [32, 24], [34, 39]]]], ["block", "d-form-row", [], ["label", "Komentarz"], 7, null, ["loc", [null, [35, 24], [37, 39]]]], ["block", "d-form-row", [], ["label", "Upload"], 8, null, ["loc", [null, [38, 24], [40, 39]]]], ["block", "d-form-row", [], ["label", "Skany dokumentów"], 9, null, ["loc", [null, [41, 24], [43, 39]]]], ["block", "d-form-row", [], ["label", "Skany dokumentów 2"], 10, null, ["loc", [null, [44, 24], [47, 39]]]], ["block", "d-form-row", [], ["label", "Skany dokumentów 3"], 11, null, ["loc", [null, [48, 24], [51, 39]]]], ["block", "d-form-row", [], ["label", "Skany dokumentów 4"], 12, null, ["loc", [null, [52, 24], [55, 39]]]], ["block", "d-form-row", [], ["label", "Skany dokumentów 5"], 13, null, ["loc", [null, [56, 24], [59, 39]]]]],
        locals: ["formData", "formContents"],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.3.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 65,
            "column": 7
          }
        },
        "moduleName": "ember-new/templates/individual-contract/partials/add-renouncement.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("form");
        dom.setAttribute(el1, "class", "d-form form-horizontal");
        dom.setAttribute(el1, "role", "form");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment(" /.box-header ");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "box-body");
        var el3 = dom.createTextNode("\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "row");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "col-md-12 col-xs-12");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("fieldset");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 3, 1, 1, 1]), 1, 1);
        return morphs;
      },
      statements: [["block", "d-form/individual-contract/add-renouncement", [], ["model", ["subexpr", "@mut", [["get", "renouncement", ["loc", [null, [8, 73], [8, 85]]]]], [], []], "contractId", ["subexpr", "@mut", [["get", "model.id", ["loc", [null, [8, 97], [8, 105]]]]], [], []], "type", ["subexpr", "@mut", [["get", "type", ["loc", [null, [8, 111], [8, 115]]]]], [], []]], 0, null, ["loc", [null, [8, 20], [60, 68]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});
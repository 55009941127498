define('ember-new/components/calculated-value-wrapper', ['exports', 'ember'], function (exports, _ember) {

  /**
   * komponent stylizujacy wartosc wyliczona automatycznie
   * dodaje ikonke kalkulatora i tooltip
   */
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['auto-calculated-field'],
    content: null
  });
});
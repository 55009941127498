define('ember-new/mixins/routes/unsaved-alert', ['exports', 'ember', 'ember-new/mixins/controllers/unsaved-data'], function (exports, _ember, _emberNewMixinsControllersUnsavedData) {
    exports['default'] = _ember['default'].Mixin.create({

        actions: {
            willTransition: function willTransition(transition) {

                if (_emberNewMixinsControllersUnsavedData['default'].detect(this.controller) && this.controller.hasAnyLock()) {
                    if (!confirm('Czy jesteś pewien, że chcesz wyjść w trakcie dodawania danych do umowy? Umowa będzie niekompletna!')) {
                        transition.abort();
                    } else {
                        this.controller.removeAllLocks();
                    }
                }
            }
        }

    });
});
define('ember-new/controllers/b2b-contract/dzu/show-gas/prices/add-price', ['exports', 'ember', 'ember-new/mixins/b2b-contract/controllers/dzu-show-prices-add-price'], function (exports, _ember, _emberNewMixinsB2bContractControllersDzuShowPricesAddPrice) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsB2bContractControllersDzuShowPricesAddPrice['default'], {

        products: [],

        basePrices: [],

        setProductsAndBasePrices: (function () {
            this.set('products', this.store.peekAll('GasPriceConditionProductViewModel'));
            this.set('basePrices', this.store.peekAll('BasePriceSetSelectDzuViewModel'));
        }).on('init'),

        fillForm: function fillForm(form) {
            form.set('model.priceSetId', this.get('model.priceSetUuid'));
            form.set('model.basePriceId', this.get('model.basePriceId'));
            form.set('model.productId', this.get('model.defaultConditionProduct'));
            form.set('model.periodType', null);
            form.set('model.year', null);
            form.set('model.month', null);
            form.set('model.startYear', null);
            form.set('model.endYear', null);
            form.set('model.startMonth', null);
            form.set('model.endMonth', null);
            form.set('model.valueType', null);
            form.set('model.value', null);
            form.set('model.fee', null);
            form.set('model.baseValue', null);
            form.set('model.baseFee', null);
            form.set('model.overheadFromLastKnownPeriod', null);
            form.set('model.overhead', null);
            form.set('model.discount', null);
            form.set('model.subscription', null);
            form.set('model.baseSubscription', null);
        },

        afterCommand: function afterCommand(response) {
            var ctrl = this;
            var route = this.container.lookup("route:b2b-contract.dzu.show-gas.prices");
            route.refresh();
            setTimeout(function () {
                ctrl.transitionToRoute('b2b-contract.dzu.show-gas.prices');
            }, 500);
        }

    });
});
define('ember-new/routes/b2b-contract/administration/vas', ['exports', 'ember-new/routes/authenticated'], function (exports, _emberNewRoutesAuthenticated) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({

        queryParams: {
            page: { refreshModel: true },
            itemsPerPage: { refreshModel: true }
        },

        beforeModel: function beforeModel() {
            window.scrollTo(0, 0);
        },

        model: function model(params) {
            return this.store.query('VasListDzuViewModel', params);
        },

        setupController: function setupController(ctrl, model) {
            ctrl.set('model', model);
        },

        actions: {
            refreshModelAction: function refreshModelAction() {
                this.refresh();
            }
        }
    });
});
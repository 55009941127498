define('ember-new/mixins/controllers/b2b-contract/administration/add-vas', ['exports', 'ember', 'ember-new/templates/components/d-form'], function (exports, _ember, _emberNewTemplatesComponentsDForm) {
    exports['default'] = _ember['default'].Mixin.create({

        layout: _emberNewTemplatesComponentsDForm['default'],

        inclusionComponentValues: [{ label: 'EE – Energia elektryczna/MWH ', value: 'ee_mwh' }, { label: 'EE – Opłata handlowa/m-c', value: 'ee_trade_fee' }, { label: 'GAZ – Paliwo/MWH', value: 'gas_fuel_mwh' }, { label: 'GAZ – Abonament/m-c', value: 'gas_subscription' }],

        mediumValues: [{ label: 'EE', value: 'ee' }, { label: 'Gaz', value: 'gas' }],

        inclusionTypeValues: [{ label: 'Zniżka', value: 'discount' }, { label: 'Zwyżka', value: 'increase' }],

        valueTypeValues: [{ label: 'Złotówki', value: 'PLN' }, { label: 'Procent', value: 'percent' }],

        overheadTypeForMMValues: [{ label: 'Automatyczny', value: 'auto' }, { label: 'Stały', value: 'const' }],

        overheadRatePerPercentPointVisibility: false,
        valueStep: 0.1,
        isDiscount: true,

        isConstOverheadTypeForMM: true
    });
});
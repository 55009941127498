define('ember-new/components/d-form/b2b-contract/ppm-prices/gas/edit-condition', ['exports', 'ember', 'ember-new/components/d-form-command', 'ember-new/templates/components/d-form', 'ember-new/config/environment'], function (exports, _ember, _emberNewComponentsDFormCommand, _emberNewTemplatesComponentsDForm, _emberNewConfigEnvironment) {
    exports['default'] = _emberNewComponentsDFormCommand['default'].extend({

        // subscriptionValueHider: function() {
        //     if (this.get('formRows.subscriptionValue')) {
        //         this.set('formRows.subscriptionValue.hidden', this.get('formData.subscriptionType') != 'subscription_type_individual');
        //     }
        // }.observes('formData.subscriptionType')

    });
});
define('ember-new/models/decision-hr-dictionary-entry', ['exports', 'ember-new/models/abstract'], function (exports, _emberNewModelsAbstract) {

    var DecisionHrDictionaryEntry = _emberNewModelsAbstract['default'].extend({
        label: DS.attr('string'),
        name: DS.attr('string'),
        priority: DS.attr('number'),
        isHidden: DS.attr('boolean')
    });

    exports['default'] = DecisionHrDictionaryEntry;
});
define("ember-new/models/individual-ppg", ["exports", "ember", "ember-data", "ember-new/models/abstract", "ember-new/models/base-statusable-document-mixin", "ember-new/mixins/models/individual-ppm"], function (exports, _ember, _emberData, _emberNewModelsAbstract, _emberNewModelsBaseStatusableDocumentMixin, _emberNewMixinsModelsIndividualPpm) {

    var attr = _emberData["default"].attr;
    var refOne = _emberData["default"].belongsTo;
    var refMany = _emberData["default"].hasMany;

    exports["default"] = _emberNewModelsAbstract["default"].extend(_emberNewModelsBaseStatusableDocumentMixin["default"], _emberNewMixinsModelsIndividualPpm["default"], {

        contract: refOne('individual-gas-contract'),

        tariff: refOne('individual-contract-gas-tariff-group', { inverse: null }),
        os: refOne('gas-os-dictionary-entry', { inverse: null }),
        osd: refOne('gas-osd-dictionary-entry', { inverse: null })

    });
});
define("ember-new/routes/individual-contract/show-gas/ppm", ["exports", "ember", "ember-new/routes/authenticated", "ember-new/mixins/routes/unsaved-alert"], function (exports, _ember, _emberNewRoutesAuthenticated, _emberNewMixinsRoutesUnsavedAlert) {
    exports["default"] = _emberNewRoutesAuthenticated["default"].extend(_emberNewMixinsRoutesUnsavedAlert["default"], {
        model: function model(params) {
            return this.store.findRecord('individual-ppg', params.ppm_id);
        },

        actions: {
            refreshModel: function refreshModel() {
                this.store.unloadAll('individual-ppg');
                this.refresh();
            }
        }
    });
});
define('ember-new/controllers/individual-contract/show-ee/ppm', ['exports', 'ember', 'ember-new/config/environment'], function (exports, _ember, _emberNewConfigEnvironment) {
    exports['default'] = _ember['default'].Controller.extend({

        secondAreaDisabled: (function () {

            if (this.get('model.tariff')) {
                return this.get('model.tariff.areasQuantity') < 2;
            }
            return true;
        }).property('model.tariff', 'model.tariff.areasQuantity'),

        thirdAreaDisabled: (function () {
            if (this.get('model.tariff')) {
                return this.get('model.tariff.areasQuantity') < 3;
            }
            return true;
        }).property('model.tariff', 'model.tariff.areasQuantity'),

        setIsPikaActive: (function () {
            var model = this.get('model');

            if (!model || !model._internalModel) {
                return;
            }

            if (model.get('osdBillingFrequencyPikaText') || model.get('loyaltyPikaText') || model.get('discountPikaText') || model.get('osPikaText') || model.get('osdPikaText') || model.get('tariffPikaText')) {
                model.set('isPikaActive', true);
            } else {
                model.set('isPikaActive', false);
            }
        }).observes('model'),

        isEditable: (function () {
            var isAnonymized = this.get('model.contract.isAnonymized');
            var permission = this.get('TheUser').isMainRoleIn(['ROLE_GDPR_SPECIALIST']);

            return !isAnonymized && !permission;
        }).property('model')
    });
});
define("ember-new/routes/tasks/add-client", ["exports", "ember-new/routes/authenticated", "ember-new/config/environment", "ember"], function (exports, _emberNewRoutesAuthenticated, _emberNewConfigEnvironment, _ember) {

	var TasksAddClientRoute = _emberNewRoutesAuthenticated["default"].extend({

		model: function model() {
			var client = this.store.createRecord('ambBusinessClient');
			return client;
		},
		actions: {
			didTransition: function didTransition() {
				this.controller.send('setup');
				return true;
			}
		}
	});

	exports["default"] = TasksAddClientRoute;
});
define("ember-new/mixins/individual-contract-price-lists/condition", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Mixin.create({

        beforeModel: function beforeModel(transition) {
            window.scrollTo(0, 0); // scroll to the top of application before reloading
        },

        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            }
        }
    });
});
define('ember-new/utils/utils', ['exports'], function (exports) {
    /**
     * Funkcje przydatne przy sprawdzaniu obiektow/tablic, sortowaniu .. itd..
     */
    var Utils = {

        /**
         * Sprawdza czy obiekt JS jest pusty
         * @param obj
         * @returns {boolean}
         */
        isEmpty: function isEmpty(obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) return false;
            }
            return true;
        },

        /**
         * Funkcja przesuwa element tablicy na podany index
         * @param arr
         * @param old_index numer indeksu z ktorego przesunac element
         * @param new_index numer indeksu na ktory przesunac element
         * @returns {*}
         */
        moveArrayElement: function moveArrayElement(arr, old_index, new_index) {
            while (old_index < 0) {
                old_index += arr.length;
            }
            while (new_index < 0) {
                new_index += arr.length;
            }
            if (new_index >= arr.length) {
                var k = new_index - arr.length;
                while (k-- + 1) {
                    arr.push(undefined);
                }
            }
            arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
            return arr;
        },

        dasherizedToUppercase: function dasherizedToUppercase(input) {
            var index, word, words, _i, _len;
            words = input.split("-");
            for (index = _i = 1, _len = words.length; _i < _len; index = ++_i) {
                word = words[index].charAt(0).toUpperCase();
                words[index] = word + words[index].substr(1);
            }
            return words.join('');
        },

        generateSimpleUrl: function generateSimpleUrl(url, divider, config, theUser, cookies) {
            var impersonatingUserUrl = '';
            var impersonatingUser = cookies.read('impersonatingUser');
            if (impersonatingUser) {
                impersonatingUserUrl = '&x-switch-user=' + impersonatingUser;
            }

            return config.apiUrl + url + divider + 'bearer=' + theUser.get('token') + impersonatingUserUrl;
        }

    };

    exports['default'] = Utils;
});
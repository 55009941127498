define('ember-new/controllers/individual-contract-price-lists/vap/edit', ['exports', 'ember', 'ember-new/mixins/individual-contract-price-lists/controllers/vap'], function (exports, _ember, _emberNewMixinsIndividualContractPriceListsControllersVap) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsIndividualContractPriceListsControllersVap['default'], {

        fillForm: function fillForm(form) {
            form.set('model.name', this.get('model.name'));
            form.set('model.type', this.get('model.type'));
            form.set('model.price', this.get('model.price'));
            form.set('model.uuid', this.get('model.uuid'));
            form.set('model.idUsys', this.get('model.idUsys'));
            form.set('model.isRequired', this.get('model.isRequired'));
            form.set('model.validityPeriod', this.get('model.validityPeriod'));
            form.set('model.numberOfMonths', this.get('model.numberOfMonths'));
            form.set('model.pikaCode', this.get('model.pikaCode'));
        }

    });
});
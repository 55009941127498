define('ember-new/mixins/amb-business-client', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
	exports['default'] = _ember['default'].Mixin.create({

		_attributesExcludeBySerializer: ['isReservedEE', 'eeReservationFor', 'eeReservationBy', 'isReservedGas', 'gasReservationFor', 'gasReservationBy', 'eeReservationAcquisitionProcess', 'gasReservationAcquisitionProcess', 'eeReservationExpireDate', 'gasReservationExpireDate', 'reservationsQuantity', 'activeEeAcquisitionProcess', 'activeGasAcquisitionProcess', 'companyDocKrs', 'companyDocNip', 'companyDocRegon', 'companyDocBre', 'boSpecialist', 'agreementEFaktura', 'agreementPrzetwarzanieOsobowych', 'agreementUdostepnienieOsobowych', 'agreementInformacjeHandlowe', 'flagAudytEE', 'flagAudytEECykl', 'flagAudytGas', 'flagAudytGasCykl', 'flagAudytFT', 'packagePradDlaDomu', 'packageGazPNP', 'packageDedykowanaObslugaKlienta', 'legalForm', 'isExternalCrmStatus', 'isReservedEeInExternalCrm', 'isReservedGasInExternalCrm', 'gasReservationChannel', 'eeReservationChannel'],

		// client info
		companyName: _emberData['default'].attr('string'),
		nip: _emberData['default'].attr('string'),
		regon: _emberData['default'].attr('string'),
		krs: _emberData['default'].attr('string'),
		pkd: _emberData['default'].belongsTo('PkdDictionaryEntry', { inverse: null, async: false }),
		legalForm: _emberData['default'].belongsTo('LegalFormDictionaryEntry', { inverse: null, async: false }),
		businessRegisterEntry: _emberData['default'].attr('string'),
		businessType: _emberData['default'].attr('string'),
		uuid: _emberData['default'].attr('string'),

		// reservation
		isReservedEE: _emberData['default'].attr('boolean'),
		eeReservationFor: _emberData['default'].belongsTo('User', { inverse: null, async: false }),
		eeReservationBy: _emberData['default'].belongsTo('User', { inverse: null, async: false }),
		eeReservationAcquisitionProcess: _emberData['default'].belongsTo('eeAcquisitionProcess', { inverse: null, async: false }),
		eeReservationExpireDate: _emberData['default'].attr('date'),
		isReservedGas: _emberData['default'].attr('boolean'),
		gasReservationFor: _emberData['default'].belongsTo('User', { inverse: null, async: false }),
		gasReservationBy: _emberData['default'].belongsTo('User', { inverse: null, async: false }),
		gasReservationAcquisitionProcess: _emberData['default'].belongsTo('gasAcquisitionProcess', { inverse: null, async: false }),
		gasReservationExpireDate: _emberData['default'].attr('date'),
		reservationsQuantity: _emberData['default'].attr('number'),

		eeReservationOwnerType: _emberData['default'].attr('string'),
		gasReservationOwnerType: _emberData['default'].attr('string'),
		eeReservationChannel: _emberData['default'].belongsTo('clientChannel', { inverse: false }),
		gasReservationChannel: _emberData['default'].belongsTo('clientChannel', { inverse: false }),

		//eeReservationRequestFor: DS.belongsTo('User', {inverse: null, async: false}),
		//gasReservationRequestFor: DS.belongsTo('User', {inverse: null, async: false}),
		//eeReservationRequestComment: DS.attr('string'),
		//gasReservationRequestComment: DS.attr('string'),

		// relations
		activeEeAcquisitionProcess: _emberData['default'].belongsTo('eeAcquisitionProcess', { inverse: null, async: false }),
		activeGasAcquisitionProcess: _emberData['default'].belongsTo('gasAcquisitionProcess', { inverse: null, async: false }),

		// embedded noKey relations
		contactPerson: _emberData['default'].attr(),
		eeContactPerson: _emberData['default'].attr(),
		gasContactPerson: _emberData['default'].attr(),

		// files
		companyDocKrs: _emberData['default'].belongsTo('file', { inverse: null }),
		companyDocNip: _emberData['default'].belongsTo('file', { inverse: null }),
		companyDocRegon: _emberData['default'].belongsTo('file', { inverse: null }),
		companyDocBre: _emberData['default'].belongsTo('file', { inverse: null }),

		// flags & packages & audits
		agreementEFaktura: _emberData['default'].attr('boolean'),
		agreementPrzetwarzanieOsobowych: _emberData['default'].attr('boolean'),
		agreementUdostepnienieOsobowych: _emberData['default'].attr('boolean'),
		agreementInformacjeHandlowe: _emberData['default'].attr('boolean'),
		flagAudytEE: _emberData['default'].attr('boolean'),
		flagAudytEECykl: _emberData['default'].attr('boolean'),
		flagAudytGas: _emberData['default'].attr('boolean'),
		flagAudytGasCykl: _emberData['default'].attr('boolean'),
		flagAudytFT: _emberData['default'].attr('boolean'),
		packageGazPrad: _emberData['default'].attr(),
		packagePradDlaDomu: _emberData['default'].attr('boolean'),
		packageGazPNP: _emberData['default'].attr('boolean'),
		packageDedykowanaObslugaKlienta: _emberData['default'].attr('boolean'),

		// documents
		documentPorozumienieGazPrad: _emberData['default'].belongsTo('File', { inverse: null }),
		documentPorozumienieGazPradZdjecie: _emberData['default'].belongsTo('File', { inverse: null }),
		documentVoucher: _emberData['default'].belongsTo('File', { inverse: null }),

		isExternalCrmStatus: _emberData['default'].attr('boolean'),
		isReservedEeInExternalCrm: _emberData['default'].attr('boolean'),
		isReservedGasInExternalCrm: _emberData['default'].attr('boolean')
	});
});
define('ember-new/components/filter-select', ['exports', 'ember', 'ember-new/components/d-field-select'], function (exports, _ember, _emberNewComponentsDFieldSelect) {
    exports['default'] = _emberNewComponentsDFieldSelect['default'].extend({
        allowClear: false,
        allowSearch: false,
        initializeComponent: (function () {

            var selectOptions = {};
            var prompt = this.get('prompt');
            if (prompt != null) {
                //this.set('prompt', false);
                selectOptions.placeholder = prompt;
                selectOptions.allowClear = this.get('allowClear');
                selectOptions.minimumResultsForSearch = this.get('allowSearch') ? 0 : -1;
            }

            selectOptions.multiple = this.get('isMultiple');

            this.set('_select2Options', selectOptions);
        }).on('init')
    });
});
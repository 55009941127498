define("ember-new/controllers/individual-contract-partners", ["exports", "ember-new/mixins/sortable-controller", "ember-new/mixins/filterable-controller", "ember-new/mixins/paginable-controller", "ember", "ember-new/config/environment", "ember-new/utils/utils", "ember-new/utils/encode-object-to-uri", "ember-new/utils/get-query-params-values"], function (exports, _emberNewMixinsSortableController, _emberNewMixinsFilterableController, _emberNewMixinsPaginableController, _ember, _emberNewConfigEnvironment, _emberNewUtilsUtils, _emberNewUtilsEncodeObjectToUri, _emberNewUtilsGetQueryParamsValues) {
    var computed = _ember["default"].computed;
    var service = _ember["default"].inject.service;
    exports["default"] = _ember["default"].Controller.extend(_emberNewMixinsSortableController["default"], _emberNewMixinsFilterableController["default"], _emberNewMixinsPaginableController["default"], {

        applicationAlerts: service('application-alerts-manager'),
        cookies: _ember["default"].inject.service('cookies'),

        isReloading: false,

        currentViewMode: 'normal',
        queryParams: ["page", "itemsPerPage", "contractNo", "fuelType", "contractStatus", "statusByPartner", "vcStatus", "dateReportedFrom", "dateReportedTo", "clientLastName", "dateSignedTo", "dateSignedFrom", "pesel", "branch", "vcRecordingStatus"],

        page: 1,
        // order: 'time',
        // orderDirection: 'desc',
        //ustawienie query paramsów na null
        contractNo: null,
        fuelType: null,
        contractStatus: null,
        statusByPartner: null,
        vcStatus: null,
        dateReportedFrom: null,
        dateReportedTo: null,
        clientLastName: null,
        dateSignedFrom: null,
        dateSignedTo: null,
        pesel: null,
        branch: null,
        vcRecordingStatus: null,

        selectedItems: computed('model.@each.selected', function () {
            return this.get('model').filterBy('selected', true);
        }),
        selectedItemsUuids: computed('selectedItems', function () {
            return this.get('selectedItems').mapBy('uuid');
        }),

        canDoBulkActionsFiltered: computed('filters', function () {
            var filters = this.get('filters');

            if (filters && Object.keys(filters).length > 0) {
                return true;
            }
            return false;
        }),

        bulkActionsFilteredLimit: 1000, // limit umow do exportu za pomocą filtrów
        canDoBulkActionsFilteredByLimit: computed('filters', 'meta.count', function () {
            return this.get('bulkActionsFilteredLimit') >= this.get('meta.count');
        }),
        hasContracts: computed('meta', function () {
            return this.get('meta.count') > 0;
        }),

        canDoBulkActionsSelected: computed('selectedItems', function () {
            var selectedItems = this.get('selectedItems');
            return selectedItems && selectedItems.length > 0;
        }),

        submenuItems: _ember["default"].A([
        // extend obj 'couse of deporecation:
        // Ember.Object.create no longer supports defining computed properties.
        // Define computed properties using extend() or reopen() before calling create().
        // Ember.Object.extend({
        //     id: 'addPartnerContract',
        //     icon: 'plus',
        //     title: 'Dodaj umowę PARTNER',
        //     action: 'openModalAddPartnerContract',
        //     // hidden: Ember.computed('isAllowedToAddAsPartner',  () => {
        //     //     console.log('hidden this.get(\'isAllowedToAddAsPartner\')', this.get('isAllowedToAddAsPartner'))
        //     //     return this.get('isAllowedToAddAsPartner');
        //     // })
        // }).create(),
        _ember["default"].Object.create({
            id: 'filtersMenuItem',
            icon: 'filter',
            title: 'Filtrowanie',
            action: 'openModalFilters'
        }), _ember["default"].Object.create({
            id: 'filtersClearMenuItem',
            icon: 'times',
            title: 'Wyczyść filtry',
            action: 'resetFilters'
        })]),

        /*Ember.Object.create({id: 'bulkFiltered', icon: 'cogs', title: 'Akcje masowe - umowy wyfiltorwane', action: 'openModalBulkFiltered'}),*/
        orderColumns: [{ label: 'Data zaraportowania', value: 'dateContractReported' }, { label: 'Data akceptu do rozliczenia', value: 'dateOfMainContractStatusLastChange' }],

        isPart: computed(function () {
            return _emberNewConfigEnvironment["default"].applicationType === 'DMT';
        }),

        isTra: computed(function () {
            return _emberNewConfigEnvironment["default"].applicationType === 'DSP';
        }),

        isAllowedToAddAsPartner: computed(function () {
            var result = this.get('TheUser').isGranted(['ROLE_ADMIN']) || this.get('TheUser').isGranted(['ROLE_SALES']) && this.get('TheUser.emberModel.duonPartner.isIndividual');
            return result;
        }),

        actions: {
            updateViewMode: function updateViewMode(mode) {
                this.set('currentViewMode', mode);
            },
            openModalAddPartnerContract: function openModalAddPartnerContract() {
                this.transitionToRoute('individual-contract-partners.partner-add-all');
            },
            openModalFilters: function openModalFilters() {
                this.transitionToRoute('individual-contract-partners.filters');
            },
            openModalBulkFiltered: function openModalBulkFiltered() {
                this.transitionToRoute('individual-contract-partners.bulkFiltered');
            },
            openModalBulkSelected: function openModalBulkSelected() {
                this.transitionToRoute('individual-contract-partners.bulkSelected');
            },
            openModalChangeContractParameters: function openModalChangeContractParameters() {
                this.transitionToRoute('individual-contract-partners.changeContractParameters');
            },

            contractsExport: function contractsExport() {
                var queryParamsValues = this.get('filters');
                var _this = this;

                var encodedURIFilters = (0, _emberNewUtilsEncodeObjectToUri["default"])(queryParamsValues),
                    encodedURI = _emberNewUtilsUtils["default"].generateSimpleUrl('/export/partnerFiltered?' + encodedURIFilters, '&', _emberNewConfigEnvironment["default"], _this.get('TheUser'), _this.get('cookies'));
                window.open(encodedURI);
            },
            handleCheck: function handleCheck(item) {
                item.set('selected', !item.get('selected'));
            },
            handleToggleSelectAll: function handleToggleSelectAll(state) {
                this.get('model').forEach(function (item) {
                    return item.set('selected', state);
                });
            },
            showHistory: function showHistory(item) {
                this.transitionToRoute('individual-contract-partners.statusHistory', item.id);
            },
            editContract: function editContract(item) {
                this.transitionToRoute('individual-contract-partners.editContract', item.id);
            },
            undoContractCancel: function undoContractCancel(item) {
                this.transitionToRoute('individual-contract-partners.undoContractCancel', item.id);
            },
            addFiles: function addFiles(item) {
                this.transitionToRoute('individual-contract-partners.addFiles', item.id);
            },
            setContractCorrected: function setContractCorrected(item) {
                this.transitionToRoute('individual-contract-partners.setContractCorrected', item.id);
            },

            resetFilters: function resetFilters() {
                var _this2 = this;

                var filters = this.get('filters');
                Object.keys(filters).forEach(function (key) {
                    _this2.set("filters." + key, null);
                    _this2.set(key, null);
                });
            },

            downloadLastReport: function downloadLastReport() {

                var _this = this;

                var promise = $.ajax({
                    url: _emberNewConfigEnvironment["default"].apiUrl + '/download/last/pikachecklist',
                    contentType: "application/json; charset=utf-8",
                    type: 'GET',
                    dataType: 'html',
                    headers: {
                        "Authorization": "Bearer " + EmberNew.TheUser.get('token')
                    },
                    beforeSend: function beforeSend(xhr) {
                        var user = _this.get('cookies').read('impersonatingUser');
                        if (user) {
                            xhr.setRequestHeader('X-Switch-User', user);
                        }
                    }
                });

                promise.then(function (data) {
                    window.open(_emberNewUtilsUtils["default"].generateSimpleUrl('/download/last/pikachecklist', '?', _emberNewConfigEnvironment["default"], _this.get('TheUser'), _this.get('cookies')));
                }, function (xhr) {
                    _this.get('applicationAlerts').addActionMessage('warning', "Brak listy kontrolnej do pobrania");
                });
            }

        }

    });
});
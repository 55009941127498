define('ember-new/models/gas-product-dictionary-entry', ['exports', 'ember-new/models/abstract'], function (exports, _emberNewModelsAbstract) {

    var GasProductDictionaryEntry = _emberNewModelsAbstract['default'].extend({
        label: DS.attr('string'),
        name: DS.attr('string'),
        contractTypes: DS.hasMany('GasContractTypeDictionaryEntry', { inverse: null }),
        isHidden: DS.attr('boolean')
    });

    exports['default'] = GasProductDictionaryEntry;
});
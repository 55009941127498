define('ember-new/models/yes-no-dictionary-entry', ['exports', 'ember-new/models/abstract'], function (exports, _emberNewModelsAbstract) {

    var YesNoDictionaryEntry = _emberNewModelsAbstract['default'].extend({
        label: DS.attr('string'),
        name: DS.attr('string'),
        shortLabel: (function () {
            return this.get('label').substring(0, 1);
        }).property('label'),
        isHidden: DS.attr('boolean'),
        uuid: DS.attr('string')
    });

    exports['default'] = YesNoDictionaryEntry;
});
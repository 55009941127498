define('ember-new/components/dashboard-drop-zone', ['exports', 'ember', 'ember-new/config/environment'], function (exports, _ember, _emberNewConfigEnvironment) {
    exports['default'] = _ember['default'].Component.extend({
        layoutName: 'components/drop-zone',
        classNames: [],
        tagName: 'div',
        dropzone: null,
        uploadUrl: null,
        val: null,

        initDropzone: (function () {
            var domElement = this.get('element');
            var dropzoneElement = $(domElement).find('.dropzone');
            var token = this.get('TheUser').get('token');
            var dropzone = new window.Dropzone(dropzoneElement.get(0), {
                url: _emberNewConfigEnvironment['default'].apiUrl + '/upload?bearer=' + token,
                method: 'post',
                paramName: 'upload[file]',
                maxFilesize: 512,
                acceptedFiles: 'image/*,application/pdf',
                uploadMultiple: false,
                autoProcessQueue: true,
                //translation
                dictDefaultMessage: "Upuść tutaj pliki lub kliknij aby wybrać"
            });
            this.set('dropzone', dropzone);
            var _this = this;
            dropzone.on('success', function (file) {
                var responsetext = JSON.parse(file.xhr.responseText);
                var upload = responsetext.upload[0];
                _this.set('targetObject.' + _this.get('val'), upload.id);
            });
        }).on('didInsertElement'),
        actions: {
            uploadFiles: function uploadFiles() {
                this.get('dropzone').processQueue();
            }
        }
    });
});
define('ember-new/models/county-dictionary-entry', ['exports', 'ember-new/models/abstract'], function (exports, _emberNewModelsAbstract) {

    var CountyDictionaryEntry = _emberNewModelsAbstract['default'].extend({
        label: DS.attr('string'),
        name: DS.attr('string'),
        district: DS.belongsTo('DistrictDictionaryEntry', { inverse: null }),
        isHidden: DS.attr('boolean'),
        uuid: DS.attr('string'),
        districtUuid: DS.attr('string')
    });

    exports['default'] = CountyDictionaryEntry;
});
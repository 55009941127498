define('ember-new/models/business-product-view-model', ['exports', 'ember-new/models/abstract', 'ember-data'], function (exports, _emberNewModelsAbstract, _emberData) {
    exports['default'] = _emberNewModelsAbstract['default'].extend({
        name: _emberData['default'].attr('string'),
        requiresBasePrices: _emberData['default'].attr('boolean'),
        autoBasePrices: _emberData['default'].attr('boolean'),
        isHidden: _emberData['default'].attr('boolean'),
        isIndex: _emberData['default'].attr('boolean'),
        isDeleted: _emberData['default'].attr('boolean'),
        allowedConditionProductsUuids: _emberData['default'].attr(),
        isMmVisibleToSalesman: _emberData['default'].attr('boolean'),
        usysId: _emberData['default'].attr('string'),
        everyIndexAvailableInConditions: _emberData['default'].attr('boolean'),
        linkedProducts: _emberData['default'].attr(),
        mmVisibleForSalesman: _emberData['default'].attr('boolean'),
        productIndexed: _emberData['default'].attr('boolean'),
        defaultConditionProductName: _emberData['default'].attr('string'),
        allConditionProductsAvailableForPriceConditions: _emberData['default'].attr('boolean'),
        hasBasePriceSet: _emberData['default'].attr('boolean'),
        automaticBasePriceSet: _emberData['default'].attr('boolean'),
        productModel: _emberData['default'].attr('string'),
        type: _emberData['default'].attr('string'),
        typeKey: _emberData['default'].attr('string'),
        defaultProduct: _emberData['default'].attr(),
        valueType: _emberData['default'].attr('string'),
        volatilityPeriod: _emberData['default'].attr('string'),
        kind: _emberData['default'].attr('string')
    });
});
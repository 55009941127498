define("ember-new/routes/individual-contract-partners/partner-add-all", ["exports", "ember", "ember-new/routes/authenticated", "ember-new/config/environment"], function (exports, _ember, _emberNewRoutesAuthenticated, _emberNewConfigEnvironment) {

    // export default AuthenticatedRoute.extend(UnsavedDataRoute, BaseAddDfRouteMixin, {
    exports["default"] = _emberNewRoutesAuthenticated["default"].extend({

        dictionariesManager: _ember["default"].inject.service('dictionaries-manager'),

        setupController: function setupController(ctrl) {
            ctrl.set('salesUsers', this.get('dictionariesManager').getUsersDictionary('sales_users_active_only'));
        },

        resetController: function resetController(controller, isExiting, transition) {
            this._super.apply(this, arguments);

            if (isExiting) {
                controller.resetData(); // or whatever function you want to call
            }
        },

        actions: {
            reload: function reload() {
                this.refresh();
            }
        }
    });
});

// import UnsavedDataRoute from "ember-new/mixins/routes/unsaved-alert";
// import BaseAddDfRouteMixin from "ember-new/mixins/routes/individual-contract/add-df";
define('ember-new/components/subpage-absolute-layer', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        onInsert: (function () {
            var absoluteContentHeight = $('#' + this.elementId + ' .pos-absolute .wrapper-content').innerHeight(),
                parentContentHeight = $('#admin_lte_layout').innerHeight();

            $('#admin_lte_layout').parent().css('height', (absoluteContentHeight > parentContentHeight ? absoluteContentHeight : parentContentHeight) + 'px');
        }).on('didInsertElement'),

        onDestroy: (function () {
            $('#admin_lte_layout').parent().css('height', 'auto');
        }).on('willDestroyElement')
    });
});
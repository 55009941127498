define('ember-new/routes/tasks/tasks-client', ['exports', 'ember-new/routes/authenticated'], function (exports, _emberNewRoutesAuthenticated) {
	exports['default'] = _emberNewRoutesAuthenticated['default'].extend({
		model: function model(params) {
			return this.store.find('ambBusinessClient', params.client_id);
		},

		action: {
			closeActionMessage: function closeActionMessage() {
				console.log('closeActionMessage');
			}
		}
	});
});
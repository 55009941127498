define('ember-new/routes/b2b-contract/dzu/show-gas/ppg/details', ['exports', 'ember-new/routes/authenticated', 'ember'], function (exports, _emberNewRoutesAuthenticated, _ember) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({

        model: function model(params) {
            this.store.unloadAll('PpgViewModel');
            return this.store.findRecord('PpgViewModel', params.ppgId, { reload: true });
        },

        actions: {
            updateModel: function updateModel() {
                alert("I'm updating the model mother!");
            },

            refreshModel: function refreshModel() {
                this.refresh();
            }
        }

    });
});
define('ember-new/mixins/b2b-contract/components/d-form/tariffs/gas-tariffs', ['exports', 'ember', 'ember-new/templates/components/d-form'], function (exports, _ember, _emberNewTemplatesComponentsDForm) {
    exports['default'] = _ember['default'].Mixin.create({
        layout: _emberNewTemplatesComponentsDForm['default'],

        configure: function configure() {
            var _this = this;

            /**
             *
             * @param uuid grupy taryfowej
             * @param dictionaryName nazwa slownika grupy taryfowej
             * @returns {object | boolean} grupe jezeli grupa taryfowa jest archiwalna, false jezeli nie jest archiwalna lub nie istnieje
             */
            var isArchivedTariffGroup = function isArchivedTariffGroup(uuid, dictionaryName) {
                var originalContentTariffGroup = _this.get('store').peekAll(dictionaryName).find(function (record) {
                    return record.get('uuid') === uuid;
                });

                if (originalContentTariffGroup && originalContentTariffGroup.get('isHidden')) {
                    return originalContentTariffGroup;
                }
                return false;
            };

            /**
             *
             * @param originalContent zawartosc do przefiltrowania na podstawie wybranej grupy trayfowej OSD
             * @param tariffGroupUuidsObjectName nazwa tablicy z Uuid z modelu (pgnigTariffUuids || fortumTariffUuids)
             * @param additionalGroup grupa taryfowa ktora ma byc dolaczona do przefiltrowanych wynikow (na potrzeby taryf archiwalnych)
             * @returns {*}
             */
            var getFilteredContent = function getFilteredContent(originalContent, tariffGroupUuidsObjectName) {
                var additionalGroup = arguments.length <= 2 || arguments[2] === undefined ? null : arguments[2];

                var selectedOsdId = _this.get('formData.osdTariffGroup'),
                    // aktualnie wybrane Osd ID
                selectedOsd = _this.get('store').peekAll('business-gas-tariff-group').find(function (record) {
                    return record.get('uuid') === selectedOsdId;
                }); // obiekt z wybrany OSD

                // jezeli nie jest wybrane zadne OSD zwraca oryginalne
                if (!selectedOsd) return originalContent;

                var selectedOsdUuids = selectedOsd.get(tariffGroupUuidsObjectName);

                var filteredContent = undefined;
                filteredContent = originalContent.filter(function (record) {
                    if (additionalGroup) {
                        // na potrzeby archiwalnych taryf......
                        return record.value === additionalGroup.get('uuid') || selectedOsdUuids.includes(record.value);
                    }
                    return selectedOsdUuids.includes(record.value);
                });
                return filteredContent;
            };

            /**
             * Filtrowanie pola pgnigTariffGroup
             */
            this.registerContentFilter('pgnigTariffGroup', function (originalContent) {
                // na potrzeby archiwalnych taryf w slownikach...
                var archivedGroup = isArchivedTariffGroup(_this.get('formData.pgnigTariffGroup'), 'business-gas-tariff-group-pgnig');
                if (archivedGroup) {
                    return getFilteredContent(originalContent, 'pgnigTariffUuids', archivedGroup);
                }

                return getFilteredContent(originalContent, 'pgnigTariffUuids');
            });

            /**
             * j.w.
             */
            this.registerContentFilter('fortumTariffGroup', function (originalContent) {
                // na potrzeby archiwalnych taryf w slownikach...
                var archivedGroup = isArchivedTariffGroup(_this.get('formData.fortumTariffGroup'), 'business-gas-tariff-group-fortum');
                if (archivedGroup) {
                    return getFilteredContent(originalContent, 'fortumTariffUuids', archivedGroup);
                }

                return getFilteredContent(originalContent, 'fortumTariffUuids');
            });

            /**
             * Przy zmianie wartosci pola `osdTariffGroup` przefiltruj `pgnigTariffGroup` i `fortumTariffGroup`
             */
            this.addObserver('formData.osdTariffGroup', function () {
                _this.filterContentFor('pgnigTariffGroup');
                _this.filterContentFor('fortumTariffGroup');
            });
        }
    });
});
define("ember-new/routes/dashboard/add", ["exports", "ember-new/routes/authenticated", "ember-new/mixins/dashboard/d-form/blog-post-form"], function (exports, _emberNewRoutesAuthenticated, _emberNewMixinsDashboardDFormBlogPostForm) {
  exports["default"] = _emberNewRoutesAuthenticated["default"].extend(_emberNewMixinsDashboardDFormBlogPostForm["default"], {

    model: function model() {
      return this.store.findAll('BlogCategory');
    },

    setupController: function setupController(ctrl, model) {
      ctrl.set('categories', model);
    }
  });
});
define('ember-new/controllers/individual-contract-partners/change-contract-parameters', ['exports', 'ember', 'ember-new/config/environment', 'ember-new/mixins/individual-contract/multiple-contract-changes'], function (exports, _ember, _emberNewConfigEnvironment, _emberNewMixinsIndividualContractMultipleContractChanges) {
    var computed = _ember['default'].computed;
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsIndividualContractMultipleContractChanges['default'], {

        dictionariesManager: _ember['default'].inject.service('dictionaries-manager'),

        showFormForCopied: false,

        checkParamsUrl: computed(function () {
            return _emberNewConfigEnvironment['default'].apiUrl + '/rest/getpartnercontractsuuidbynumbersviewmodels';
        }),

        checkParams: function checkParams(contractNos) {
            var _this = this;

            var url = this.get('checkParamsUrl');
            var requestObject = { contractNos: contractNos };

            this.clearErrors();
            this.set('isLoadingCheck', true);
            this.set('checkResult', null);

            return this.get('ajax').ajaxPost(url, requestObject).then(function (res) {
                return _this.handleCheckResponse(res);
            })['catch'](function (err) {
                return _this.handleError(err);
            });
        },

        checkNumbersParams: function checkNumbersParams() {
            var contractNos = this.get('validData').mapBy('number');

            if (this.get('canDoBulkActionsFiltered')) {
                this.set('showFormForCopied', false);
            }

            this.checkParams(contractNos);
        },

        clear: function clear() {
            this.clearErrors();
            this.set('checkResult', null);
            this.set('inputDataArray', []);
        },

        actions: {
            checkNumbersParams: function checkNumbersParams() {
                this.checkNumbersParams();
            },
            getParsedData: function getParsedData(data) {
                this.set('inputDataArray', data);
            },
            afterBulkAction: function afterBulkAction() {
                this.get('target').send('refresh');
                this.transitionToRoute('individual-contract-partners');
                this.get('applicationAlerts').addActionMessage('success', 'Pomyślnie wykonano akcje.');
            }
        }
    });
});
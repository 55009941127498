define('ember-new/components/navigation/li-settings', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'li',
    classNames: 'dropdown user user-menu',

    dictionaryManager: _ember['default'].inject.service('dictionaries-manager'),

    usersToSwitchDictionary: (function () {
      return this.get('dictionaryManager').getUsersDictionary('users_to_switch');
    }).property(),

    stopPropagation: function stopPropagation() {
      this.$('a.dropdown-toggle').click(function (e) {
        e.stopPropagation();
      }).on('didInsertElement');
    }
  });
});
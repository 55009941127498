define('ember-new/controllers/b2b-contract/administration/section-pricelists/details', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),

        isReloading: false,

        afterCommand: function afterCommand() {
            this.send('refreshModelAction');
        }

    });
});
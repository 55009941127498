define('ember-new/models/distribution-rate-simplified', ['exports', 'ember-new/models/abstract', 'ember-data'], function (exports, _emberNewModelsAbstract, _emberData) {

    var attr = _emberData['default'].attr;
    var refOne = _emberData['default'].belongsTo;
    var refMany = _emberData['default'].hasMany;

    exports['default'] = _emberNewModelsAbstract['default'].extend({
        usysExportId: attr('string'),
        osd: attr('string'),
        tariff: attr('string'),
        name: attr('string'),
        oplataAbonamentowa: attr('string'),
        priceOplataAbonamentowa: attr('number'),
        oplataPrzejsciowa: attr('string'),
        priceOplataPrzejsciowa: attr('number'),
        oplataSieciowa: attr('string'),
        priceOplataSieciowa: attr('number'),
        ami: attr('boolean'),
        year: attr('string')
    });
});
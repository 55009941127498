define("ember-new/controllers/dashboard", ["exports", "ember-new/config/environment", "ember"], function (exports, _emberNewConfigEnvironment, _ember) {

    var DashboardController = _ember["default"].Controller.extend({

        flag: false,
        mainPostsFlag: false,
        mainAvatarFlag: false,
        isDev: _emberNewConfigEnvironment["default"].isDev,
        navEdit: false,
        catArr: [],
        theUserModel: null,
        expertCorner: false,
        needs: ['popupImageModal'],
        counter: 5,

        breakpoints: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        }, {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        }, {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }],

        contractStatusesAccept: ['21f74708-69a8-4911-b6ab-f10babc5bdb2'],

        //'546c82b23ea51932178b4567'
        contractStatusesDzu: ['a2795f89-9621-40d0-a3e9-9435d3143a3c', //'5447aa33e771de005d8b4567',
        'eccbf69f-fbef-49f8-940d-900f7e376b69', //'5447aa58e771de55598b4567',
        'e9dc98e3-18c5-4d34-9b56-cf733b3fc08b', //'5447aab9e771ded9048b4567',
        '63e59ae7-6362-4296-a35f-1137a1b51f49', //'5447aad7e771de8d3f8b4567',
        'e9062a53-671b-4072-816e-e42cc3cfa123', //'54c10931e771de66238b4567',
        '7e37bcf7-b244-4cc0-8fea-d54d91610c6e', //'5447ab00e771de0e568b4568',
        'dcba5db6-1581-4d24-8cb9-6e34857673e3'],

        //'5477563d729a0e9a774d39a3',
        contractStatusesCorrect: ['5794e1ba-1db2-4378-a392-f8640bce9beb', //'541477dae771de5d048b4567',
        '7700b342-48f8-4250-b783-28c053d5697e', //'5447aa70e771de0e568b4567',
        'eebd0eb1-5677-4f4b-a828-053b4aa29273', //'5447aa86e771de28688b4567',
        '470da4a6-9df2-4b6c-94a5-8a0529e62dbd', //'5447aaa7e771de005d8b4568',
        '52dc7d9a-4857-464a-8cde-b4d1e8284acd'],

        //'5447aae6e771de55598b4568',
        contractsToCorrectHref: (function () {
            return '/business/contract/dzu?status=' + this.get('contractStatusesCorrect').join('%2C');
        }).property(),

        contractsCurrentlyApprovedHref: (function () {
            return '/business/contract/dzu?currentSalesMonth=1&status=' + this.get('contractStatusesAccept').join('%2C');
        }).property(),

        contractsDzuHref: (function () {
            return '/business/contract/dzu?status=' + this.get('contractStatusesDzu').join('%2C');
        }).property(),

        redAlertLoginUsers: (function () {
            return this.get('redalertlogin');
        }).property('redalertlogin'),

        redAlertLoginUsersExists: (function () {
            return this.get('redalertlogin.users') && this.get('redalertlogin.users').length > 0;
        }).property('redalertlogin'),

        postsLazy: (function () {
            var posts = this.get('posts');

            posts = posts.filter(function (post) {
                return post.get('category.id') != '54f88d5d7f8b9a7d22735502' && post.get('category.id') != '552cca726df9afdf1e023225';
            });

            return posts.slice(0, this.get('counter'));
        }).property('content', 'counter'),

        canPublish: (function () {
            return this.get('TheUser').isGranted('ROLE_SALES_SUPPORT');
        }).property(),

        canEdit: (function () {
            return this.get('TheUser').isGranted('ROLE_SALES_SUPPORT');
        }).property(),

        canSuggestNewPost: (function () {
            return !this.get('TheUser').isGranted('ROLE_SALES_SUPPORT') && this.get('TheUser').isGranted('ROLE_SALES_SALESMAN');
        }).property(),

        canAddNewPost: (function () {
            return this.get('TheUser').isGranted('ROLE_SALES_SUPPORT');
        }).property(),

        canEditSetting: (function () {
            return this.get('TheUser').isGranted('ROLE_ADMIN') || this.get('TheUser').isGranted('ROLE_SALES_SUPPORT') || this.get('TheUser').isGranted('ROLE_DG') || this.get('TheUser').isGranted('ROLE_DE');
        }).property(),

        canViewRedAlertLogin: (function () {
            return this.get('TheUser').isMainRoleIn(['ROLE_SALES_MANAGER', 'ROLE_SALES_HEADMANAGER']) && _emberNewConfigEnvironment["default"].applicationType === 'DSP';
        }).property(),

        onInit: (function () {

            var _this = this;

            this.set('catArr', []);

            if (this.get('categories')) {
                this.get('categories').forEach(function (item) {
                    _this.get('catArr').pushObject(_ember["default"].Object.create({ model: item, expanded: false }));
                });
            }
        }).observes('categories'),

        setup: (function () {

            this.set('theUserModel', this.get('TheUser').model);
            var userModel = this.get('theUserModel');
            var avatar = this.get('theUserModel').avatarImage;

            if (this.get('mainAvatarFlag') != true) {
                userModel.set('avatarImage', avatar);
            }

            this.set('mainAvatarFlag', true);

            if (this.get('TheUser').popupImage) {
                this.openModalWithUserPopup();
            }
        }).on('init'),

        actions: {

            toggleNavEditAction: function toggleNavEditAction() {
                this.toggleProperty('navEdit');
            },

            saveNavAction: function saveNavAction() {
                this.get('categories').save();
            },

            expandAction: function expandAction(item) {
                this.get('catArr').forEach(function (item) {
                    item.set('expanded', false);
                });

                item.set('expanded', true);
            },

            editSitebarAction: function editSitebarAction(item) {
                this.transitionToRoute('dashboard.editSitebar', item.get('id'));
            },

            more: function more() {
                if (this.get('counter') < this.get('posts.content.length')) {
                    this.set('counter', this.get('counter') + 5);
                }
            }
        },

        mayEdit: (function () {
            return this.get('TheUser').isGranted('ROLE_SALES_SUPPORT');
        }).property(),

        crmChangesPosts: (function () {
            var posts = this.get('posts');
            return posts.filter(function (item) {
                return item.get('category.id') === '54f88d5d7f8b9a7d22735502';
            });
        }).property('content', 'counter'),

        theUserRangeClass: (function () {
            var classs = 'salesman_position ';
            switch (this.get('TheUser').get('model.range')) {
                case 1:
                    classs += 'salesman_position_przedstawiciel';
                    break;
                case 2:
                    classs += 'salesman_position_doradca';
                    break;
                case 3:
                    classs += 'salesman_position_ekspert';
                    break;
            }
            return classs;
        }).property(),

        theUserRangeText: (function () {
            if (this.get('TheUser').isMainRoleIn(['ROLE_SALES_MANAGER', 'ROLE_SALES_HEADMANAGER', 'ROLES_SALES_TEST_MANAGER'])) {
                switch (this.get('TheUser').get('model').get('range')) {
                    case 1:
                        return 'Program managerski';
                    case 2:
                        return 'Manager';
                    case 3:
                        return 'Makro Dyrektor';
                }
            } else if (this.get('TheUser').isMainRoleIn(['ROLE_SALES_SALESMAN'])) {
                switch (this.get('TheUser').get('model').get('range')) {
                    case 1:
                        return 'Młodszy Specjalista';
                    case 2:
                        return 'Specjalista';
                    case 3:
                        return 'Ekspert';
                    case 4:
                        return 'Przedstawiciel';
                    case 5:
                        return 'Senior Expert';
                    case 6:
                        return 'JUNIOR KAM';
                    case 7:
                        return 'KAM';
                    case 8:
                        return 'SENIOR KAM';
                }
            }
            return '';
        }).property(),

        openModalWithUserPopup: function openModalWithUserPopup(imageLink) {
            var buttons = [_ember["default"].Object.create({ title: 'Zamknij', dismiss: 'modal' })];
            var ctrl = this.get('controllers.popupImageModal');
            ctrl.setup(this.get('TheUser').popupImage);
            Bootstrap.ModalManager.open('modal', '.', 'PopupImageModal', buttons, ctrl);
        }

    });

    exports["default"] = DashboardController;
});
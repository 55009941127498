define("ember-new/routes/b2b-contract/administration/section-pricelists/list", ["exports", "ember-new/routes/authenticated", "ember"], function (exports, _emberNewRoutesAuthenticated, _ember) {
    exports["default"] = _emberNewRoutesAuthenticated["default"].extend({

        queryParams: {
            page: { refreshModel: true },
            itemsPerPage: { refreshModel: true }
        },

        model: function model(params) {
            return _ember["default"].RSVP.hash({
                list: this.store.query('BasePriceSetListDzuViewModel', params),
                eeProducts: this.store.findAll('EePriceConditionProductListViewModel'),
                gasProducts: this.store.findAll('GasPriceConditionProductListViewModel')
            });
        },

        setupController: function setupController(ctrl, model) {
            ctrl.set('model', model.list);
            ctrl.initializeProducts(model.eeProducts, model.gasProducts);
        },

        actions: {
            queryParamsDidChange: function queryParamsDidChange() {
                this.refresh();
            },

            refreshModelAction: function refreshModelAction() {
                this.refresh();
            }
        }
    });
});
define("ember-new/templates/components/switch-table-view-mode", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.3.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 20,
            "column": 6
          }
        },
        "moduleName": "ember-new/templates/components/switch-table-view-mode.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("Widok:\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "switch-mode-wrapper");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("i");
        dom.setAttribute(el3, "class", "fa fa-table");
        dom.setAttribute(el3, "aria-hidden", "true");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("i");
        dom.setAttribute(el3, "class", "fa fa-th-large");
        dom.setAttribute(el3, "aria-hidden", "true");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("i");
        dom.setAttribute(el3, "class", "fa fa-th-list");
        dom.setAttribute(el3, "aria-hidden", "true");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [1]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element0, [3]);
        var element3 = dom.childAt(element0, [5]);
        var morphs = new Array(6);
        morphs[0] = dom.createAttrMorph(element1, 'class');
        morphs[1] = dom.createElementMorph(element1);
        morphs[2] = dom.createAttrMorph(element2, 'class');
        morphs[3] = dom.createElementMorph(element2);
        morphs[4] = dom.createAttrMorph(element3, 'class');
        morphs[5] = dom.createElementMorph(element3);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["btn ", ["subexpr", "if", [["subexpr", "eq", [["get", "currentViewMode", ["loc", [null, [3, 32], [3, 47]]]], "normal"], [], ["loc", [null, [3, 28], [3, 57]]]], "btn-success", "btn-default"], [], ["loc", [null, [3, 23], [4, 43]]]]]]], ["element", "action", ["viewModeChanged", "normal"], [], ["loc", [null, [4, 45], [5, 40]]]], ["attribute", "class", ["concat", ["btn ", ["subexpr", "if", [["subexpr", "eq", [["get", "currentViewMode", ["loc", [null, [9, 32], [9, 47]]]], "cards"], [], ["loc", [null, [9, 28], [9, 56]]]], "btn-success", "btn-default"], [], ["loc", [null, [9, 23], [10, 43]]]]]]], ["element", "action", ["viewModeChanged", "cards"], [], ["loc", [null, [10, 46], [11, 39]]]], ["attribute", "class", ["concat", ["btn ", ["subexpr", "if", [["subexpr", "eq", [["get", "currentViewMode", ["loc", [null, [15, 32], [15, 47]]]], "scroll"], [], ["loc", [null, [15, 28], [15, 57]]]], "btn-success", "btn-default"], [], ["loc", [null, [15, 23], [16, 43]]]]]]], ["element", "action", ["viewModeChanged", "scroll"], [], ["loc", [null, [16, 46], [17, 40]]]]],
      locals: [],
      templates: []
    };
  })());
});
define("ember-new/controllers/dashboard/dashboard-post-edit", ["exports", "ember", "ember-new/mixins/dashboard/d-form/controllers/blog-post-form", "ember-new/config/environment"], function (exports, _ember, _emberNewMixinsDashboardDFormControllersBlogPostForm, _emberNewConfigEnvironment) {
  exports["default"] = _ember["default"].Controller.extend(_emberNewMixinsDashboardDFormControllersBlogPostForm["default"], {

    applicationAlerts: _ember["default"].inject.service('application-alerts-manager'),

    fileId: null,
    customUrl: _emberNewConfigEnvironment["default"].apiUrl + '/_uploader/blogPostAttachment/upload',
    paramName: 'content',

    fillForm: function fillForm(form) {
      form.set('model.category', this.get('model.category.id'));
      form.set('model.contentType', this.get('model.contentType'));
      form.set('model.content', this.get('fileId') ? this.get('fileId') : this.get('model.content.id'));
      form.set('model.contentVimeo', this.get('model.contentVimeo'));
      form.set('model.contentYoutube', this.get('model.contentYoutube'));
      form.set('model.contentHtml', this.get('model.contentHtml'));
      form.set('model.contentTextAttachment', this.get('model.contentTextAttachment'));
      form.set('model.isArchived', this.get('model.isArchived'));
    },

    postEditUrl: (function () {
      var id = this.get('model.id');

      return '/rest/blogposts/' + id + '/editpost';
    }).property()

  });
});
define('ember-new/controllers/b2b-contract/administration/gas-business-tariff/edit', ['exports', 'ember', 'ember-new/mixins/b2b-contract/controllers/gas-business-tariff/edit-controller'], function (exports, _ember, _emberNewMixinsB2bContractControllersGasBusinessTariffEditController) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsB2bContractControllersGasBusinessTariffEditController['default'], {

        fillForm: function fillForm(form) {
            form.set('model.uuid', this.get('model.uuid'));
            form.set('model.name', this.get('model.name'));
            form.set('model.usysId', this.get('model.usysId'));
            form.set('model.excise', this.get('model.exciseId'));
            form.set('model.tariff', this.get('model.tariffFortumUuid'));
            form.set('model.tariffPgnig', this.get('model.tariffPgnigUuid'));
            form.set('model.contractProduct', this.get('model.productUuid'));
        }
    });
});
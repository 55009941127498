define('ember-new/components/action-messages', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'div',
        classNames: ['component_action_messages'],
        messages: [],
        closeActionMessageParentAction: 'closeActionMessage',

        actions: {
            closeActionMessage: function closeActionMessage(index) {
                this.sendAction('closeActionMessageParentAction', index);
            }
        }
    });
});
define('ember-new/components/favourite-star', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		tagName: 'i',
		classNames: 'fa fa-2x'.w(),
		classNameBindings: ['isActive:is-active:is-not-active', 'isActiveSecond:is-active-second:is-not-active-second', 'disabled:disabled', 'isGray:is-gray'],
		isActive: false,
		isActiveSecond: false,

		// jezeli podany typ to ustawia flagi isActive i isActiveSecond według podanego typu
		type: null, // salesman, manager, both
		setType: _ember['default'].on('didReceiveAttrs', _ember['default'].observer('type', function () {
			var type = this.get('type'),
			    isActive = this.get('isActive'),
			    isActiveSecond = this.get('isActiveSecond');
			if (!type) return false;

			if (type && (isActive || isActiveSecond)) {
				console.error('Warring! \'type\' attr is set to "' + type + '", so \'isActive\' and \'isActiveSecond\' will be ignored.');
			}

			if (type === 'salesman') {
				this.set('isActive', true);
				this.set('isActiveSecond', false);
			} else if (type === 'manager') {
				this.set('isActive', false);
				this.set('isActiveSecond', true);
			} else if (type === 'both') {
				this.set('isActive', true);
				this.set('isActiveSecond', true);
			} else {
				throw new Error("Wrong 'type' value.");
			}
		})),

		actions: {}
	});
});
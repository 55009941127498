define('ember-new/components/action-message', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'div',
        classNames: ['alert'],
        classNameBindings: ['alertTypeClass'],
        closeActionMessageParentAction: 'closeActionMessage',
        message: null,
        type: 'success',
        index: null,
        alertTypeClass: (function () {
            return 'alert-' + this.get('type');
        }).property('type'),

        actions: {
            close: function close() {
                this.sendAction('closeActionMessageParentAction', this.get('index'));
            }

        }
    });
});
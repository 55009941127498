define('ember-new/components/bt-comment', ['exports', 'ember'], function (exports, _ember) {
    // komponent do wyswietlania komentarza uzytkownika
    exports['default'] = _ember['default'].Component.extend({
        avatar: 'https://ssl.gstatic.com/accounts/ui/avatar_2x.png',
        authorRole: false,
        authorDept: false, // department
        dateFormat: false, //
        systemComment: false,

        // zwraca dzial nadawcy komentarza
        getAuthorDept: _ember['default'].computed('authorDept', function () {
            var author = this.get('authorDept');

            var sender = author.split("_")[0],
                recipient = author.split("_")[1];

            if (!author) return null;
            if (sender === 'sales') return 'Doradcy';else if (sender === 'dk') return 'Dział kompletujący';else if (sender === 'dr') return 'Dział realizujący';else if (sender === 'ds') return 'Wsparcie sprzedaży';else return author;
        }),

        getRecipientDept: _ember['default'].computed('authorDept', function () {
            var author = this.get('authorDept');

            if (!author) return null;

            var sender = author.split("_")[0],
                recipient = author.split("_")[1];

            if (recipient === 'sales') return 'Doradcy';else if (recipient === 'dk') return 'Dział kompletujący';else if (recipient === 'dr') return 'Dział realizujący';else if (recipient === 'ds') return 'Wsparcie sprzedaży';else return null;
        })

    });
});
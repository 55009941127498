define('ember-new/controllers/b2b-contract/dzu/show-ee/prices/edit-price', ['exports', 'ember', 'ember-new/mixins/b2b-contract/controllers/dzu-show-prices-add-price'], function (exports, _ember, _emberNewMixinsB2bContractControllersDzuShowPricesAddPrice) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsB2bContractControllersDzuShowPricesAddPrice['default'], {

        products: [],
        basePrices: [],

        setProductsAndBasePrices: (function () {
            this.set('products', this.store.peekAll('EePriceConditionProductViewModel'));
            this.set('basePrices', this.store.peekAll('BasePriceSetSelectDzuViewModel'));
        }).on('init'),

        fillForm: function fillForm(form) {
            form.set('model.id', this.get('model.id'));
            form.set('model.basePriceId', this.get('model.basePriceId'));
            form.set('model.productId', this.get('model.productId'));
            form.set('model.periodType', this.get('model.periodType'));
            form.set('model.year', this.get('model.year'));
            form.set('model.month', this.get('model.month'));
            form.set('model.startYear', this.get('model.startYear'));
            form.set('model.endYear', this.get('model.endYear'));
            form.set('model.startMonth', this.get('model.startMonth'));
            form.set('model.endMonth', this.get('model.endMonth'));
            form.set('model.valueType', this.get('model.valueType'));
            form.set('model.valueArea1', this.get('model.valueArea1'));
            form.set('model.valueArea2', this.get('model.valueArea2'));
            form.set('model.valueArea3', this.get('model.valueArea3'));
            form.set('model.fee', this.get('model.fee'));
            form.set('model.baseValueArea1', this.get('model.baseValueArea1'));
            form.set('model.baseValueArea2', this.get('model.baseValueArea2'));
            form.set('model.baseValueArea3', this.get('model.baseValueArea3'));
            form.set('model.overheadArea1', this.get('model.overheadArea1'));
            form.set('model.overheadArea2', this.get('model.overheadArea2'));
            form.set('model.overheadArea3', this.get('model.overheadArea3'));
            form.set('model.baseFee', this.get('model.baseFee'));
            form.set('model.overheadFromLastKnownPeriod', this.get('model.overheadFromLastKnownPeriod'));
        },

        afterCommand: function afterCommand(response) {
            var ctrl = this;
            var route = this.container.lookup("route:b2b-contract.dzu.show-ee.prices");
            route.refresh();
            setTimeout(function () {
                ctrl.transitionToRoute('b2b-contract.dzu.show-ee.prices');
            }, 500);
        }
    });
});
define('ember-new/routes/dashboard/dashboard-post-edit', ['exports', 'ember-new/routes/authenticated'], function (exports, _emberNewRoutesAuthenticated) {

  var DashboardPostEditRoute = _emberNewRoutesAuthenticated['default'].extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        categories: this.store.findAll('BlogCategory'),
        post: this.store.peekRecord('BlogPost', params.post_id)
      });
    },

    setupController: function setupController(ctrl, model) {
      ctrl.set('model', model.post);
      ctrl.set('categories', model.categories);
    }

  });

  exports['default'] = DashboardPostEditRoute;
});
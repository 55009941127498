define('ember-new/controllers/b2b-contract/dzu/show-gas/prices/edit-price', ['exports', 'ember', 'ember-new/mixins/b2b-contract/controllers/dzu-show-prices-add-price'], function (exports, _ember, _emberNewMixinsB2bContractControllersDzuShowPricesAddPrice) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsB2bContractControllersDzuShowPricesAddPrice['default'], {

        products: [],

        basePrices: [],

        setProductsAndBasePrices: (function () {
            this.set('products', this.store.peekAll('GasPriceConditionProductViewModel'));
            this.set('basePrices', this.store.peekAll('BasePriceSetSelectDzuViewModel'));
        }).on('init'),

        fillForm: function fillForm(form) {
            form.set('model.id', this.get('model.id'));
            form.set('model.basePriceId', this.get('model.basePriceId'));
            form.set('model.productId', this.get('model.productId'));
            form.set('model.periodType', this.get('model.periodType'));
            form.set('model.year', this.get('model.year'));
            form.set('model.month', this.get('model.month'));
            form.set('model.startYear', this.get('model.startYear'));
            form.set('model.endYear', this.get('model.endYear'));
            form.set('model.startMonth', this.get('model.startMonth'));
            form.set('model.endMonth', this.get('model.endMonth'));
            form.set('model.valueType', this.get('model.valueType'));
            form.set('model.value', this.get('model.valueArea1'));
            form.set('model.baseValue', this.get('model.baseValueArea1'));
            form.set('model.baseFee', this.get('model.baseFee'));
            form.set('model.overheadFromLastKnownPeriod', this.get('model.overheadFromLastKnownPeriod'));
            form.set('model.overhead', this.get('model.overhead'));
            form.set('model.discount', this.get('model.discount'));
            form.set('model.subscription', this.get('model.subscription'));
            form.set('model.baseSubscription', this.get('model.baseSubscription'));
        },

        afterCommand: function afterCommand(response) {
            var ctrl = this;
            var route = this.container.lookup("route:b2b-contract.dzu.show-gas.prices");
            route.refresh();
            setTimeout(function () {
                ctrl.transitionToRoute('b2b-contract.dzu.show-gas.prices');
            }, 500);
        }

    });
});
define('ember-new/routes/b2b-contract/dzu/show-gas/ppg/add', ['exports', 'ember-new/routes/authenticated', 'ember'], function (exports, _emberNewRoutesAuthenticated, _ember) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({

        contractId: null,

        model: function model() {
            var contract = this.modelFor('b2b-contract.dzu.show-gas');
            this.set('contractId', contract.get('id'));

            var route = this;

            //return this.store.findRecord('ContractClientDzuViewModel', contract.get('id'));
            return _ember['default'].RSVP.hash({
                contract: route.get('store').findRecord('ContractContractDzuViewModel', contract.get('id'), { reload: true }),
                model: route.get('store').findRecord('ContractClientDzuViewModel', contract.get('id'), { reload: true })
            });
        },

        setupController: function setupController(ctrl, model) {
            ctrl.set('model', model.model);
            ctrl.set('contract', model.contract);
            ctrl.set('contractId', this.get('contractId'));
        }

    });
});
define("ember-new/mixins/b2b-contract/routes/dzu-show-contract", ["exports", "ember", "ember-new/routes/authenticated", "ember-new/config/environment"], function (exports, _ember, _emberNewRoutesAuthenticated, _emberNewConfigEnvironment) {
  exports["default"] = _ember["default"].Mixin.create({

    ajax: _ember["default"].inject.service('ajax'),
    applicationAlerts: _ember["default"].inject.service('application-alerts-manager'),

    setupController: function setupController(controller, model) {
      controller.set('model', model.contract);
      controller.set('burdens', model.burdens);
      controller.set('idContract', this.get('idContract'));
      controller.initializeProducts(model.products);
    },

    actions: {
      updateModel: function updateModel() {
        alert("I'm updating the model mother!");
      },

      refreshModel: function refreshModel() {
        this.refresh();
      },

      submitVerificationSummary: function submitVerificationSummary() {

        // prevent to submit more than once by clicking one that one time
        if (this.controller.get('_isSubmittingVerificationSummary') === true) {
          return false;
        }

        // show loader and set loading
        this.controller.set('_isSubmittingVerificationSummary', true);

        var serializedData = {
          EditVerificationSummaryProcedure: {

            contractUuid: this.controller.get('idContract'),

            verificationSummary: {
              isContractInconsistentWithRepresentation: {
                "true": this.controller.get('model.contractInconsistentWithRepresentationTrue'),
                agreement: this.controller.get('model.contractInconsistentWithRepresentationAgreement'),
                consultation: this.controller.get('model.contractInconsistentWithRepresentationConsultation')
              },
              noSubstitution: {
                "true": this.controller.get('model.noSubstitutionTrue'),
                agreement: this.controller.get('model.noSubstitutionAgreement'),
                consultation: this.controller.get('model.noSubstitutionConsultation')
              },
              noInvoices: {
                "true": this.controller.get('model.noInvoicesTrue'),
                agreement: this.controller.get('model.noInvoicesAgreement'),
                consultation: this.controller.get('model.noInvoicesConsultation')
              },
              noFvInvoice: {
                "true": this.controller.get('model.noFvInvoiceTrue'),
                agreement: this.controller.get('model.noFvInvoiceAgreement'),
                consultation: this.controller.get('model.noFvInvoiceConsultation')
              },
              noFvOsdInvoice: {
                "true": this.controller.get('model.noFvOsdInvoiceTrue'),
                agreement: this.controller.get('model.noFvOsdInvoiceAgreement'),
                consultation: this.controller.get('model.noFvOsdInvoiceConsultation')
              },
              noContract: {
                "true": this.controller.get('model.noContractTrue'),
                agreement: this.controller.get('model.noContractAgreement'),
                consultation: this.controller.get('model.noContractConsultation')
              },
              lackingAttachment: {
                "true": this.controller.get('model.lackingAttachmentTrue'),
                agreement: this.controller.get('model.lackingAttachmentAgreement'),
                consultation: this.controller.get('model.lackingAttachmentConsultation')
              },
              installationNotReady: {
                "true": this.controller.get('model.installationNotReadyTrue'),
                agreement: this.controller.get('model.installationNotReadyAgreement'),
                consultation: this.controller.get('model.installationNotReadyConsultation')
              },
              noNewLinkDocuments: {
                "true": this.controller.get('model.noNewLinkDocumentsTrue'),
                agreement: this.controller.get('model.noNewLinkDocumentsAgreement'),
                consultation: this.controller.get('model.noNewLinkDocumentsConsultation')
              },
              noCessionDocuments: {
                "true": this.controller.get('model.noCessionDocumentsTrue'),
                agreement: this.controller.get('model.noCessionDocumentsAgreement'),
                consultation: this.controller.get('model.noCessionDocumentsConsultation')
              },
              pricesBelowBasic: {
                "true": this.controller.get('model.pricesBelowBasicTrue'),
                agreement: this.controller.get('model.pricesBelowBasicAgreement'),
                consultation: this.controller.get('model.pricesBelowBasicConsultation')
              },
              notAcceptedChanges: {
                "true": this.controller.get('model.notAcceptedChangesTrue'),
                agreement: this.controller.get('model.notAcceptedChangesAgreement'),
                consultation: this.controller.get('model.notAcceptedChangesConsultation')
              },
              noCreditworthinessAssessment: {
                "true": this.controller.get('model.noCreditworthinessAssessmentTrue'),
                agreement: this.controller.get('model.noCreditworthinessAssessmentAgreement'),
                consultation: this.controller.get('model.noCreditworthinessAssessmentConsultation')
              },
              contractNotComplete: {
                "true": this.controller.get('model.contractNotCompleteTrue'),
                agreement: this.controller.get('model.contractNotCompleteAgreement'),
                consultation: this.controller.get('model.contractNotCompleteConsultation')
              },
              contractInconsistentWithProduct: {
                "true": this.controller.get('model.contractInconsistentWithProductTrue'),
                agreement: this.controller.get('model.contractInconsistentWithProductAgreement'),
                consultation: this.controller.get('model.contractInconsistentWithProductConsultation')
              },
              illegibleSignature: {
                "true": this.controller.get('model.illegibleSignatureTrue'),
                agreement: this.controller.get('model.illegibleSignatureAgreement'),
                consultation: this.controller.get('model.illegibleSignatureConsultation')
              },
              noOWU: {
                "true": this.controller.get('model.noOWUTrue'),
                agreement: this.controller.get('model.noOWUAgreement'),
                consultation: this.controller.get('model.noOWUConsultation')
              },
              pricesAboveBasic: {
                "true": this.controller.get('model.pricesAboveBasicTrue'),
                agreement: this.controller.get('model.pricesAboveBasicAgreement'),
                consultation: this.controller.get('model.pricesAboveBasicConsultation')
              },
              negativeCreditworthinessAssessment: {
                "true": this.controller.get('model.negativeCreditworthinessAssessmentTrue'),
                agreement: this.controller.get('model.negativeCreditworthinessAssessmentAgreement'),
                consultation: this.controller.get('model.negativeCreditworthinessAssessmentConsultation')
              },
              otherOn: this.controller.get('model.otherTrue'),
              otherReason: this.controller.get('otherReasonValue')
            }
          }
        };

        var _this = this;
        var promise = this.get('ajax').ajaxPost(_emberNewConfigEnvironment["default"].apiUrl + '/rest/editverificationsummaryprocedures', serializedData);

        promise.then(function () {
          _this.get('applicationAlerts').addActionMessage('success', "Dane zostały zmienione");
          _this.controller.set('_isSubmittingVerificationSummary', false); // hide loading
          setTimeout(function () {
            _this.refresh();
          }, 500);
        }, function (response) {
          _this.get('applicationAlerts').addActionMessage('warning', "Wystąpił błąd, nie udało się zapisać");
          _this.controller.set('_isSubmittingVerificationSummary', false); // hide loading
        });
      }

    }
  });
});
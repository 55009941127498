define('ember-new/routes/meeting-verification/ordering', ['exports', 'ember-new/routes/authenticated'], function (exports, _emberNewRoutesAuthenticated) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({

        queryParams: {
            page: { refreshModel: true },
            limit: { refreshModel: true },
            dateFrom: { refreshModel: true },
            dateTo: { refreshModel: true },
            managersExcluded: { refreshModel: true }
        },

        model: function model(params, transition) {
            // console.log('model for ordering',this.store.query('TaskSimplifiedListViewModel', params));
            // return this.store.query('TaskSimplifiedListViewModel', params);
            return this.store.query('TaskSimplifiedListViewModel', params, { reload: true }).then(function (result) {
                return {
                    tasks: result,
                    meta: result.get('meta')
                };
            })['catch'](function () {
                return console.error('route model : error msg');
            });
        },

        setupController: function setupController(controller, _ref) {
            var tasks = _ref.tasks;
            var meta = _ref.meta;

            this._super(controller, tasks);
            controller.set('meta', meta);
        }

    });
});
define('ember-new/controllers/admin/anonymization-registry', ['exports', 'ember', 'ember-new/config/environment', 'ember-new/mixins/sortable-controller', 'ember-new/mixins/filterable-controller', 'ember-new/mixins/paginable-controller'], function (exports, _ember, _emberNewConfigEnvironment, _emberNewMixinsSortableController, _emberNewMixinsFilterableController, _emberNewMixinsPaginableController) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsSortableController['default'], _emberNewMixinsFilterableController['default'], _emberNewMixinsPaginableController['default'], {

        init: function init() {
            this._super.apply(this, arguments);

            this._initAuthorsToFilter();
        },

        ajax: _ember['default'].inject.service('ajax'),
        applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),
        dictionariesManager: _ember['default'].inject.service('dictionaries-manager'),
        returnRoute: 'admin.anonymization-registry',
        sortBy: 'date',
        sortModeAsc: false,
        itemsPerPage: 25,

        queryParams: ['dateFrom', 'dateTo', 'authors', 'entryTypes', 'documentTypes', 'documentDescription', 'documentDescriptionNotContains'],

        dateFrom: '',
        dateTo: '',
        authors: '',
        entryTypes: '',
        documentTypes: '',
        documentDescription: '',
        documentDescriptionNotContains: '',

        entryTypesToFilter: [{ label: 'Sukces - zanonimizowano', value: 'success' }, { label: 'Sukces - zanonimizowano ręcznie', value: 'manual_success' }, { label: 'Alert - nie zanonimizowano', value: 'alert' }, { label: 'Błąd - nie zanonimizowano', value: 'error' }],
        documentTypesToFilter: [{ label: 'Klient', value: 'client' }, { label: 'Umowa', value: 'contract' }, { label: 'Odstapienie', value: 'renouncement' }],
        authorsToFilter: [],

        _initAuthorsToFilter: function _initAuthorsToFilter() {
            var _this = this;

            this.get('dictionariesManager').getUsersDictionary('can_anonymize_users_by_user').forEach(function (item) {
                _this.get('authorsToFilter').push({
                    value: item.uuid,
                    label: item.fullNameWithRole
                });
            });
        },

        entryTypesToSelected: _ember['default'].computed('entryTypes', function () {
            var _this2 = this;

            return this.get('entryTypesToFilter').filter(function (item) {
                return _this2.get('entryTypes').split(',').includes(item.value);
            });
        }),
        documentTypesToSelected: _ember['default'].computed('documentTypes', function () {
            var _this3 = this;

            return this.get('documentTypesToFilter').filter(function (item) {
                return _this3.get('documentTypes').split(',').includes(item.value);
            });
        }),
        authorsToSelected: _ember['default'].computed('authors', function () {
            var _this4 = this;

            return this.get('authorsToFilter').filter(function (item) {
                return _this4.get('authors').split(',').includes(item.value);
            });
        }),

        actions: {
            refresh: function refresh() {
                this.send('refreshRoute');
            },
            anonymizeClient: function anonymizeClient(uuid) {
                var _this5 = this;

                //TODO copy FROM 'ember-new/mixins/individual-contract/anonymize.js
                if (!confirm("Czy jesteś pewny? Anonimizacja danych klienta, jego umów i odstąpień jest nieodwracalna.")) {
                    return;
                }

                var applicationAlerts = this.get('applicationAlerts');
                var url = _emberNewConfigEnvironment['default'].apiUrl + '/rest/anonymizeclientandrelatedelementscommands';
                var payload = {
                    'AnonymizeClientAndRelatedElementsCommand': {
                        clientUuid: uuid
                    }
                };

                this.get('ajax').ajaxPost(url, payload).then(function () {
                    applicationAlerts.addActionMessage('success', 'Dane klienta zostały pomyślnie zanonimizowane!');
                    setTimeout(function () {
                        _this5.send('refreshRoute');
                        _this5.transitionToRoute(_this5.get('returnRoute'));
                    }, 3000);
                }, function (reject) {
                    // wywalone żeby się error dwa razy nie wyświetlał
                    //applicationAlerts.addActionMessage('danger', reject.responseJSON.message);
                });
            },
            anonymizeRenouncement: function anonymizeRenouncement(uuid) {
                var _this6 = this;

                //TODO copy FROM 'ember-new/mixins/renouncement/anonymize.js
                if (!confirm("Czy jesteś pewny? Anonimizacja danych odstąpienia jest nieodwracalna")) {
                    return;
                }

                var applicationAlerts = this.get('applicationAlerts');
                var url = _emberNewConfigEnvironment['default'].apiUrl + '/rest/anonymizestandalonerenouncementcommands';
                var payload = {
                    'AnonymizeStandaloneRenouncementCommand': {
                        renouncementUuid: uuid
                    }
                };

                this.get('ajax').ajaxPost(url, payload).then(function () {
                    applicationAlerts.addActionMessage('success', 'Dane odstąpienia zostały pomyślnie zanonimizowane!');
                    setTimeout(function () {
                        _this6.send('refreshRoute');
                        _this6.transitionToRoute(_this6.get('returnRoute'));
                    }, 3000);
                }, function (reject) {
                    applicationAlerts.addActionMessage('danger', reject.responseJSON.message);
                });
            },
            errorShow: function errorShow(fullError) {
                confirm(fullError);
            }
        }
    });
});
define('ember-new/controllers/tasks/tasks-client/reservation', ['exports', 'ember', 'ember-data', 'ember-new/mixins/action-messages', 'ember-new/utils/ajax/post', 'ember-new/config/environment', 'ember-new/templates/components/d-form-tasks-datepicker'], function (exports, _ember, _emberData, _emberNewMixinsActionMessages, _emberNewUtilsAjaxPost, _emberNewConfigEnvironment, _emberNewTemplatesComponentsDFormTasksDatepicker) {
	exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsActionMessages['default'], {

		ajax: _ember['default'].inject.service('ajax'),
		dateMoveEE: null,
		dateMoveGas: null,

		userToSwitchEE: null,
		userToSwitchGas: null,

		formMoveGas: null,
		formMoveEe: null,

		error: null,

		reset: function reset() {
			this.clearActionMessages();
		},

		allowMoveEe: (function () {
			return this.get('model.isReservedEE') && (this.get('TheUser').isGranted('ROLE_DG') || this.get('TheUser').isGranted('ROLE_DE'));
		}).property('model'),
		allowMoveGas: (function () {
			return this.get('model.isReservedGas') && (this.get('TheUser').isGranted('ROLE_DG') || this.get('TheUser').isGranted('ROLE_DE'));
		}).property('model'),
		allowSwitchEE: (function () {
			return this.get('model.isReservedEE') && this.get('isAllowedToEeReservation');
		}).property('model'),
		allowSwitchGas: (function () {
			return this.get('model.isReservedGas') && this.get('isAllowedToGasReservation');
		}).property('model'),

		allowRequestForExtendEeReservation: (function () {
			return false;
			return this.get('model.isReservedEE') && this.get('isAllowedToEeReservation') && this.get('model.eeReservationAcquisitionProcess.reservationsQuantity') == 2;
		}).property('model'),

		allowRequestForEeReservation: (function () {
			return false;
			return !this.get('model.isReservedEE') && this.get('model.eeReservationAcquisitionProcess.salesman.id') == Emberapp.TheUser.model.id;
		}).property('model'),

		allowRequestForGasReservation: (function () {
			return false;
			return !this.get('model.isReservedGas') && this.get('model.gasReservationAcquisitionProcess.salesman.id') == Emberapp.TheUser.model.id;
		}).property('model'),

		allowRequestForExtendGasReservation: (function () {
			return false;
			return this.get('model.isReservedGas') && this.get('isAllowedToGasReservation') && this.get('model.gasReservationAcquisitionProcess.reservationsQuantity') == 2;
		}).property('model'),

		isLoadingSwitchingEE: false,
		isLoadingSwitchingGas: false,

		clientMeta: null,
		salesmen: [],
		isAllowedToEeReservation: (function () {
			return this.get('clientMeta.allowViewReservationEe');
		}).property('clientMeta', 'clientMeta.allowViewReservationEe'),
		isAllowedToGasReservation: (function () {
			return this.get('clientMeta.allowViewReservationGas');
		}).property('clientMeta', 'clientMeta.allowViewReservationGas'),

		dFormLayout: _emberNewTemplatesComponentsDFormTasksDatepicker['default'],

		fillFormEe: function fillFormEe(form) {
			this.set('formMoveEe', form);
			form.set('model.dateMoveEe', null);
		},

		fillFormGas: function fillFormGas(form) {
			this.set('formMoveGas', form);
			form.set('model.dateMoveGas', null);
		},

		actions: {
			addReservation: function addReservation() {
				this.transitionToRoute('tasks.addReservation', { queryParams: { cid: this.get('model.id') } });
			},
			extendEE: function extendEE() {
				this.clearActionMessages();
				var _this = this;
				this.get('model').customUpdate('extendeereservation', []).then(function () {
					_this.addActionMessage('success', "Przedłużono rezerwację EE");
					var route = _this.container.lookup("route:tasks");
					route.refresh();
				}, function (response) {
					if (response.errors.children) {
						_ember['default'].$.each(response.errors.children, function (key, value) {
							_this.addActionMessage('danger', value);
						});
					}
				});
			},
			extendGas: function extendGas() {
				this.clearActionMessages();
				var _this = this;
				this.get('model').customUpdate('extendgasreservation', []).then(function () {
					_this.addActionMessage('success', "Przedłużono rezerwację na Gaz");
				}, function (response) {
					if (response.errors.children) {
						_ember['default'].$.each(response.errors, function (key, value) {
							_this.addActionMessage('danger', value);
						});
					}
				});
			},
			removeEE: function removeEE() {
				if (confirm("Czy na pewno chcesz usunąć rezerwację na EE dla tego klienta? \n\nUsunięcie rezerwacji nie spowoduje wyzerowania limitu przedłużeń.\n\nUsunięcie rezerwacji oznacza zamknięcie procesu akwizycyjnego na EE dla użytkownika i zakończenie wszystkich aktywnych zadań na to medium jako 'Rezygnacja'")) {
					this.clearActionMessages();
					var _this = this;
					this.get('model').customUpdate('removeeereservation', []).then(function () {
						_this.addActionMessage('success', "Usunięto rezerwację na EE");
						var route = _this.container.lookup("route:tasks");
						route.refresh();
					}, function (response) {
						if (response instanceof _emberData['default'].InvalidError) {

							_ember['default'].$.each(response.errors, function (key, value) {
								_this.addActionMessage('danger', value);
							});
						}
					});
				}
			},
			removeGas: function removeGas() {
				if (confirm("Czy na pewno chcesz usunąć rezerwację na Gaz dla tego klienta? \n\nUsunięcie rezerwacji nie spowoduje wyzerowania limitu przedłużeń. \n\nUsunięcie rezerwacji oznacza zamknięcie procesu akwizycyjnego na Gaz dla użytkownika i zakończenie wszystkich aktywnych zadań na to medium jako 'Rezygnacja'")) {
					this.clearActionMessages();
					var _this = this;
					this.get('model').customUpdate('removegasreservation', []).then(function () {
						_this.addActionMessage('success', "Usunięto rezerwację na Gaz");
						var route = _this.container.lookup("route:tasks");
						route.refresh();
					}, function (response) {
						if (response instanceof _emberData['default'].InvalidError) {

							_ember['default'].$.each(response.errors, function (key, value) {
								_this.addActionMessage('danger', value);
							});
						}
					});
				}
			},
			moveEE: function moveEE() {
				this.clearActionMessages();
				var _this = this;
				var payloadData = {
					date: this.get('dateMoveEE')
				};

				var payload = { move: payloadData };

				var moveEePromise = this.get('ajax').ajaxPost(_emberNewConfigEnvironment['default'].apiUrl + '/ajax/client/move_ee_reservation/' + this.get('model.id'), payload);

				moveEePromise.then(function (response) {
					_this.store.pushPayload(response);
					_this.addActionMessage('success', "Przesunięto datę końca rezerwacji EE.");
					var route = _this.container.lookup("route:tasks");
					route.refresh();
				}, function (response) {
					_this.addActionMessage('danger', response.errors.form.children.date.errors[0]);
				});
			},
			moveGas: function moveGas() {
				this.clearActionMessages();
				var _this = this;

				var payloadData = {
					date: this.get('dateMoveGas')
				};

				var payload = { move: payloadData };
				var moveGasPromise = this.get('ajax').ajaxPost(_emberNewConfigEnvironment['default'].apiUrl + '/ajax/client/move_gas_reservation/' + this.get('model.id'), payload);

				moveGasPromise.then(function (response) {
					_this.store.pushPayload(response);
					_this.addActionMessage('success', "Przesunięto datę końca rezerwacji na gaz.");
					var route = _this.container.lookup("route:tasks");
					route.refresh();
				}, function (response) {
					_this.addActionMessage('danger', response.errors.form.children.date.errors[0]);
				});
			},
			switchEE: function switchEE() {
				if (this.get('isLoadingSwitchingEE') === true) {
					return; // akcja już w toku, blokada podwojnego klikniecia
				}

				this.clearActionMessages();
				this.set('isLoadingSwitchingEE', true);
				var _this = this;
				var formData = {
					eeReservationFor: this.get('userToSwitchEE')
				};

				var payload = { client: formData };

				var switchEePromise = this.get('ajax').ajaxPost(_emberNewConfigEnvironment['default'].apiUrl + '/ajax/client/switch_ee_reservation/' + this.get('model.id'), payload);

				switchEePromise.then(function (response) {
					_this.store.pushPayload(response);
					_this.set('isLoadingSwitchingEE', false);
					_this.addActionMessage('success', "Przepięto rezerwację EE i aktywne zadania");
					var route = _this.container.lookup("route:tasks");
					route.refresh();
					setTimeout(function () {
						_this.send('refreshModel');
					}, 2000);
				}, function (response) {
					_this.set('isLoadingSwitchingEE', false);

					if (response.errors.children.eeReservationFor.errors) {
						var msg = response.errors.children.eeReservationFor.errors[0];
						_this.addActionMessage('danger', msg);
					} else {
						_this.addActionMessage('danger', response.errors.errors[0]);
					}
				});
			},
			switchGas: function switchGas() {
				if (this.get('isLoadingSwitchingGas') === true) {
					return; // akcja już w toku, blokada podwojnego klikniecia
				}
				this.clearActionMessages();
				this.set('isLoadingSwitchingGas', true);

				var _this = this;
				var formData = {
					gasReservationFor: this.get('userToSwitchGas')
				};

				var payload = { client: formData };

				var switchGasPromise = this.get('ajax').ajaxPost(_emberNewConfigEnvironment['default'].apiUrl + '/ajax/client/switch_gas_reservation/' + this.get('model.id'), payload);

				switchGasPromise.then(function (response) {
					_this.store.pushPayload(response);
					_this.set('isLoadingSwitchingGas', false);
					_this.addActionMessage('success', "Przepięto rezerwację Gas i aktywne zadania");
					var route = _this.container.lookup("route:tasks");
					route.refresh();
					setTimeout(function () {
						_this.send('refreshModel');
					}, 2000);
				}, function (response) {
					_this.set('isLoadingSwitchingEE', false);

					if (response.errors.children.gasReservationFor.errors) {
						var msg = response.errors.children.gasReservationFor.errors[0];
						_this.addActionMessage('danger', msg);
					} else {
						_this.addActionMessage('danger', response.errors.errors[0]);
					}
				});
			},

			requestEE: function requestEE() {
				this.transitionToRoute('tasksClient.reservation.requestEe');
			},

			requestGas: function requestGas() {
				this.transitionToRoute('tasksClient.reservation.requestGas');
			},

			requestThirdExtensionEe: function requestThirdExtensionEe() {
				this.transitionToRoute('tasksClient.reservation.requestThirdEe');
			},

			requestThirdExtensionGas: function requestThirdExtensionGas() {
				this.transitionToRoute('tasksClient.reservation.requestThirdGas');
			}
		}
	});
});
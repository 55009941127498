define('ember-new/models/analysis-view-models/client-analysis-view-model', ['exports', 'ember-new/models/abstract', 'ember-data/attr'], function (exports, _emberNewModelsAbstract, _emberDataAttr) {
    exports['default'] = _emberNewModelsAbstract['default'].extend({
        alerts: (0, _emberDataAttr['default'])(),
        clientFirstName: (0, _emberDataAttr['default'])('string'),
        clientUuid: (0, _emberDataAttr['default'])('string'),
        clientLastName: (0, _emberDataAttr['default'])('string'),
        clientPesel: (0, _emberDataAttr['default'])('string'),
        clientEmail: (0, _emberDataAttr['default'])('string'),
        clientPhone: (0, _emberDataAttr['default'])('string'),
        clientAddress: (0, _emberDataAttr['default'])(),
        isOkToAnonymize: (0, _emberDataAttr['default'])('string'),
        secondClientFirstName: (0, _emberDataAttr['default'])('string'),
        secondClientLastName: (0, _emberDataAttr['default'])('string'),
        secondClientPesel: (0, _emberDataAttr['default'])('string'),
        secondClientEmail: (0, _emberDataAttr['default'])('string'),
        secondClientPhone: (0, _emberDataAttr['default'])('string'),
        alreadyAnonymized: (0, _emberDataAttr['default'])('string')
    });
});
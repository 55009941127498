define('ember-new/mixins/acquisition-process', ['exports', 'ember', 'ember-new/models/base-statusable-document-mixin', 'ember-data'], function (exports, _ember, _emberNewModelsBaseStatusableDocumentMixin, _emberData) {
	exports['default'] = _ember['default'].Mixin.create({

		acqType: _emberData['default'].attr('string'), // virtual

		uuid: _emberData['default'].attr('string'),

		salesman: _emberData['default'].belongsTo('User', { inverse: null, async: false }),
		client: _emberData['default'].belongsTo('AmbBusinessClient', { inverse: null, async: false }),

		isFavouriteSalesman: _emberData['default'].attr('boolean'),
		isFavouriteManager: _emberData['default'].attr('boolean'),
		favouriteSalesmanSigningExpectedTime: _emberData['default'].attr('string'),
		favouriteSalesmanComment: _emberData['default'].attr('string'),

		eeRegion: _emberData['default'].attr('string'),
		gasRegion: _emberData['default'].attr('string'),

		consumption: _emberData['default'].attr('string'), //z number
		overhead: _emberData['default'].attr('string'), //z number
		osUuid: _emberData['default'].attr('string'),
		osdUuid: _emberData['default'].attr('string'),
		hasLoyaltyContract: _emberData['default'].attr('number'),
		loyaltyContractExpired: _emberData['default'].attr('string'), //Umyślnie ~BH
		loyaltyContractRepudiateMonths: _emberData['default'].attr('number'),

		loyaltyStatus: _emberData['default'].attr('number'),
		loyaltyDate: _emberData['default'].attr('string'),

		hasContract: _emberData['default'].attr('boolean'),
		hasReservation: _emberData['default'].attr('boolean'),
		reservationsQuantity: _emberData['default'].attr('number', { 'default': 0 }),

		statementOfContract: _emberData['default'].attr('boolean'),
		dateStartOfContract: _emberData['default'].attr('string'),
		dateOfReceptionContract: _emberData['default'].attr('string'),
		endOfContract: _emberData['default'].attr('string'),
		isIndexedProduct: _emberData['default'].attr('boolean'),
		isHotDeal: _emberData['default'].attr('boolean'),

		// virtual
		presumedMarginAmount: _emberData['default'].attr('number'),
		volume: _emberData['default'].attr('number'),
		numberOfMonths: _emberData['default'].attr('number'),
		undoneTasksCount: _emberData['default'].attr('number'),
		isOpen: _emberData['default'].attr('boolean'),
		isAgreement: _emberData['default'].attr('boolean'),

		endTask: _emberData['default'].belongsTo('Task', { inverse: null, async: true })
	});
});
define('ember-new/serializers/business-ppg-monthly-info', ['exports', 'ember-new/serializers/application'], function (exports, _emberNewSerializersApplication) {
    exports['default'] = _emberNewSerializersApplication['default'].extend({
        monthlyInfoSerializerInit: (function () {
            this.addAttrsConfig({
                monthlyInfo: { serialize: 'records', deserialize: 'records', noKey: true },
                volume: { serialize: 'records', deserialize: 'records', noKey: true },
                overhead: { serialize: 'records', deserialize: 'records', noKey: true },
                basePrice: { serialize: 'records', deserialize: 'records', noKey: true },
                contractPrice: { serialize: 'records', deserialize: 'records', noKey: true },
                mm: { serialize: 'records', deserialize: 'records', noKey: true },
                mmBasePrices: { serialize: 'records', deserialize: 'records', noKey: true },
                mmFuel: { serialize: 'records', deserialize: 'records', noKey: true },
                mmPerMonth: { serialize: 'records', deserialize: 'records', noKey: true }
            });
        }).on('init')
    });
});
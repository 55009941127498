define("ember-new/routes/individual-contract/show-ee/prices", ["exports", "ember-new/routes/authenticated", "ember-new/mixins/routes/unsaved-alert"], function (exports, _emberNewRoutesAuthenticated, _emberNewMixinsRoutesUnsavedAlert) {
    exports["default"] = _emberNewRoutesAuthenticated["default"].extend(_emberNewMixinsRoutesUnsavedAlert["default"], {

        showLoading: false,

        model: function model(params) {
            this.store.unloadAll('IndividualContractContractConditionListViewModel');
            this.store.unloadAll('IndividualContractVapsListViewModel');
            return Ember.RSVP.hash({
                prices: this.store.query('IndividualContractContractConditionListViewModel', params),
                vaps: this.store.query('IndividualContractVapsListViewModel', params),
                contract: this.modelFor('individual-contract.show-ee')
            });
        },

        setupController: function setupController(ctrl, model) {

            ctrl.set('prices', model.prices);
            ctrl.set('vaps', model.vaps);
            ctrl.set('contract', model.contract);
            ctrl.set('showLoading', this.get('showLoading'));
        },

        actions: {
            refreshModel: function refreshModel() {
                this.set('showLoading', true);
                this.refresh();
                this.set('showLoading', false);
            }
        }
    });
});
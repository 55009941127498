define("ember-new/mixins/controllers/individual-contract-partners/partner-edit-contract", ["exports", "ember", "ember-new/utils/validators", "ember-new/config/environment", "ember-new/mixins/controllers/individual-contract-partners/form-fields"], function (exports, _ember, _emberNewUtilsValidators, _emberNewConfigEnvironment, _emberNewMixinsControllersIndividualContractPartnersFormFields) {
    var computed = _ember["default"].computed;
    var observer = _ember["default"].observer;
    var _Ember$inject = _ember["default"].inject;
    var service = _Ember$inject.service;
    var controller = _Ember$inject.controller;
    exports["default"] = _ember["default"].Mixin.create(_emberNewMixinsControllersIndividualContractPartnersFormFields["default"], {
        loadingPeselData: false,
        ajax: service(),
        individualContractPartnersCtrl: controller('individual-contract-partners'),

        init: function init() {
            this._super();
            this.set('thisContext', this);
        },

        isValidClientPesel: computed('clientPesel', function () {
            var pesel = this.get('clientPesel');
            var isValid = false;
            try {
                isValid = _emberNewUtilsValidators["default"].pesel(pesel);
            } catch (e) {
                isValid = false;
            }
            return isValid;
        }),

        isExistingClient: false,
        isPeselDisabled: computed('foreigner', function () {
            var foreigner = this.get('foreigner');
            //.. some space for complex logic in future .... =)
            return foreigner;
        }),

        isSecondPeselDisabled: computed('foreignerSecondClient', function () {
            var foreignerSecondClient = this.get('foreignerSecondClient');

            return foreignerSecondClient;
        }),

        isLoyaltyDisabled: computed('loyaltyDateSetByBo', function () {
            return this.get('model.loyaltyDateSetByBo');
        }),

        /**
         * Czysci pesel przy zmianie na obcokrajowca...
         */
        foreignerChanged: observer('formData.foreigner', function () {
            if (this.get('formData.foreigner') == true) {
                this.set('formData.clientPesel', '');
            }
        }),

        foreignerSecondClientChanged: observer('formData.foreignerSecondClient', function () {
            if (this.get('formData.foreignerSecondClient') == true) {
                this.set('formData.peselSecondClient', '');
            }
        }),

        fillForm: function fillForm(form) {
            var _this2 = this;

            this.set('formData', form.get('formData'));

            this.get('formFieldsForMappingStates').forEach(function (field) {
                form.set("model." + field, _this2.get("" + field));
            });
        },

        configureFrom: function configureFrom(form) {
            var controller = this.get('targetObject');
            controller.toggleContractTypeFields.call(controller, form);
        },
        afterCommand: function afterCommand(response) {
            var ctrl = this;
            ctrl.transitionToRoute('individual-contract-partners');
            this.get('individualContractPartnersCtrl').send('refresh');
            // ctrl.get('target').send('refreshContractItemModel', ctrl.get('model.id'));
        },

        /**
         * Pokazuje i ukrywa pola formularza zalezne od typu umowy.
         * @param form
         */
        toggleContractTypeFields: function toggleContractTypeFields(form) {
            var _this = this;
            form.addObserver('formData.type', function (form) {
                var type = this.get('formData.type');
                if (type === 'ee') {
                    _this.showEE(form);
                    _this.hideGas(form);
                } else if (type === 'gas') {
                    _this.hideEE(form);
                    _this.showGas(form);
                } else if (type === 'dual') {
                    _this.showEE(form);
                    _this.showGas(form);
                } else {
                    _this.hideEE(form);
                    _this.hideGas(form);
                }
            });
        },

        hideGas: function hideGas(form) {
            form.set('formRows.gasTariffUuid.hidden', true);
            form.set('formRows.gasContractNo.hidden', true);
        },
        showGas: function showGas(form) {
            form.set('formRows.gasTariffUuid.hidden', false);
            form.set('formRows.gasContractNo.hidden', false);
        },
        hideEE: function hideEE(form) {
            form.set('formRows.contractNo.hidden', true);
            form.set('formRows.tariffGroupUuid.hidden', true);
        },
        showEE: function showEE(form) {
            form.set('formRows.contractNo.hidden', false);
            form.set('formRows.tariffGroupUuid.hidden', false);
        },

        resetData: function resetData() {
            var _this3 = this;

            // todo: zastosować jakas metode do resetowania controllera do stanu początkowgo
            this.get('formFieldsForMappingStates').forEach(function (field) {
                _this3.set(field, null);
            });
        }
    });
});
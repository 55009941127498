define("ember-new/templates/components/d-field-switch", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.3.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 120
          }
        },
        "moduleName": "ember-new/templates/components/d-field-switch.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("input");
        dom.setAttribute(el1, "type", "checkbox");
        dom.setAttribute(el1, "data-toggle", "toggle");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        if (this.cachedFragment) {
          dom.repairClonedNode(element0, [], true);
        }
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element0, 'onclick');
        morphs[1] = dom.createAttrMorph(element0, 'checked');
        morphs[2] = dom.createAttrMorph(element0, 'disabled');
        return morphs;
      },
      statements: [["attribute", "onclick", ["subexpr", "action", ["setSelection"], [], ["loc", [null, [1, 31], [1, 56]]]]], ["attribute", "checked", ["get", "checked", ["loc", [null, [1, 88], [1, 95]]]]], ["attribute", "disabled", ["get", "disabled", ["loc", [null, [1, 109], [1, 117]]]]]],
      locals: [],
      templates: []
    };
  })());
});
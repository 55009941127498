define('ember-new/utils/the-user', ['exports', 'ember', 'ember-new/config/environment', 'ember-new/utils/ajax/get'], function (exports, _ember, _emberNewConfigEnvironment, _emberNewUtilsAjaxGet) {

    // Define TheUserClass to use in Application initalizer
    // @author Kamil Jakubowski <k.jakubowski@kontaktenergia.pl>
    var TheUserClass = _ember['default'].Object.extend({
        _inited: false,
        _isLogged: false,
        _isImpersonated: false,
        popupImage: false,
        token: null,
        model: null,
        modelImpersonatingUser: null,
        emberModel: null,
        simpleUsersList: [],
        isLogged: (function () {
            return this.get('_isLogged');
        }).property('_isLogged'),
        isImpersonated: (function () {
            return this.get('_isImpersonated');
        }).property('_isImpersonated'),
        isAllowedToSwitch: (function () {
            return this.isGranted('ROLE_ALLOWED_TO_SWITCH');
        }).property('model'),
        recheckCounter: 1,
        _loadUser: function _loadUser(init) {
            var self = this;
            var promise = new _ember['default'].RSVP.Promise(function (resolve, reject) {
                var query = init !== undefined && init == true ? '?init=1' : '';
                _ember['default'].$.getJSON(_emberNewConfigEnvironment['default'].apiUrl + '/ajax/security/isauthenticated' + query).then(function (response) {
                    resolve(response);
                }, function (jqXHR) {
                    reject(jqXHR);
                });
            }, "checkTheUserAuthentication");

            return promise;
        },
        initallyCheckAuthentication: function initallyCheckAuthentication() {

            return; // metod deprecated

            if (this._inited) {
                return this.recheckAuthentication();
            }

            var self = this;
            var promise = self._loadUser(true);

            promise.then(this.onAuthenticationSuccess, this.onAuthenticationFailure);

            return promise;
        },
        onAuthenticationSuccess: function onAuthenticationSuccess(data, token) {

            var self = this;

            this.app.Security.setUserGrantedRoles(data.grantedRoles);

            this.set('token', token);

            this.set('model', _ember['default'].Object.create(data.user));
            this.set('tmpModel', _ember['default'].Object.create(data.user)); // model copy

            var store = this.app.__container__.lookup('service:store');
            store.pushPayload('user', { user: data.user });
            this.set('emberModel', store.peekRecord('User', data.user.id));

            this.set('logged', true);
            this.set('popupImage', data.popupImage);

            if (data.isImpersonated !== undefined) {
                this.set('_isImpersonated', data.isImpersonated);
                if (data.isImpersonated === true) {
                    this.set('modelImpersonatingUser', data.impersonatingUser);
                }
            }

            if (data.simpleUsersList !== undefined) {
                this.set('simpleUsersList', data.simpleUsersList);
            }
        },
        onAuthenticationFailure: function onAuthenticationFailure() {
            var self = this;
            self.set('token', null);
            self.set('logged', false);
            self.set('_isImpersonated', false);
            self.set('model', null);
            self.set('modelImpersonatingUser', null);

            // dodaje usuwanie ze Store na wszelki wypadek, bo Simple-Auth i tak robi reload po session-invalidate
            var store = this.app.__container__.lookup('store:main');
            store.unloadAll();
        },
        onInvalidateAuthenticationSuccess: function onInvalidateAuthenticationSuccess(data) {
            this.onAuthenticationFailure();
        },
        isMainRoleIn: function isMainRoleIn(roles) {
            if (roles instanceof Array) {
                return roles.indexOf(this.get('model.mainRole')) >= 0 ? true : false;
            }
            return false;
        },
        userRoleIn: function userRoleIn(user, roles) {
            if (roles instanceof Array /* && user instanceof this.app.User*/) {
                    return roles.indexOf(user.get('mainRole')) >= 0 ? true : false;
                }
            return false;
        },
        //sprawdza czy user ma uprawnienia do choćby jednej z podanych ról.
        //funkcja przyjmuje w parametrze jedną role (string) lub liste ról (array)
        isGranted: function isGranted(roles) {
            // Skasować przy najbliższej okazji jeśli okaże się że route'y działają// JC
            // if(!this._isLogged) {
            //     return false;
            // }

            if (!(this.app.Security.grantedRoles instanceof Array)) {
                throw new Error("this.app.Security.grantedRoles not loaded");
            }

            if (!(roles instanceof Array)) {
                roles = [roles];
            }

            var self = this;
            var ret = false;
            roles.forEach(function (role) {
                if (self.app.Security.grantedRoles.indexOf(role) >= 0) {
                    ret = true;
                }
            });

            return ret;
        },
        switchUser: function switchUser(username) {
            if (this.get('isImpersonated')) {
                return;
            }

            var cookies = this.app.__container__.lookup('service:cookies');

            var hash = window.location.hash;
            var url = _emberNewConfigEnvironment['default'].apiUrl + "?_switch_user=" + username + '&isApi=1';
            var _this = this;
            (0, _emberNewUtilsAjaxGet['default'])(url, this.get('token')).then(function (data) {
                if (data.impersonating_success == true) {
                    cookies.write('impersonatingUser', username);
                    _this.onAuthenticationSuccess(data, _this.get('token'));
                    window.location = '/';
                } else {
                    alert('Przelogowywanie nie powiodło się!');
                }
            });
        },
        switchBackToBaseUser: function switchBackToBaseUser() {
            if (!this.get('isImpersonated')) {
                return;
            }

            var cookies = this.app.__container__.lookup('service:cookies');

            var hash = window.location.hash;
            var url = _emberNewConfigEnvironment['default'].apiUrl + "?_switch_user=_exit" + '&isApi=1&bearer=' + this.get('token');

            cookies.clear('impersonatingUser');
            window.location = '/';
        }
    });

    exports['default'] = TheUserClass;
});
define('ember-new/models/sales-report-by-regions-view-model', ['exports', 'ember-data', 'ember-new/models/abstract'], function (exports, _emberData, _emberNewModelsAbstract) {
    exports['default'] = _emberNewModelsAbstract['default'].extend({
        user: _emberData['default'].attr('string'),
        region: _emberData['default'].attr('string'),
        numberOfMeetings: _emberData['default'].attr('number'),
        numberOfContractsReported: _emberData['default'].attr('number'),
        mmReported: _emberData['default'].attr('number'),
        volumeReported: _emberData['default'].attr('number'),
        numberOfContractsAccepted: _emberData['default'].attr('number'),
        mmAccepted: _emberData['default'].attr('number'),
        volumeAccepted: _emberData['default'].attr('number'),
        mmReportedSummary: _emberData['default'].attr('number'),
        mmAcceptedSummary: _emberData['default'].attr('number'),
        volumeAcceptedSummary: _emberData['default'].attr('number'),
        volumeReportedSummary: _emberData['default'].attr('number'),
        contractsReportedSummary: _emberData['default'].attr('number'),
        contractsAcceptedSummary: _emberData['default'].attr('number'),
        tasksSummary: _emberData['default'].attr('number'),
        salesmanCount: _emberData['default'].attr('number')
    });
});
define('ember-new/models/dictionary-list-item-view-model', ['exports', 'ember-data', 'ember-new/models/abstract'], function (exports, _emberData, _emberNewModelsAbstract) {

	var attr = _emberData['default'].attr;
	var refOne = _emberData['default'].belongsTo;

	exports['default'] = _emberData['default'].Model.extend({
		uuid: attr('string'),
		mongoId: attr('string'),
		name: attr('string'),
		type: attr('string')
	});
});
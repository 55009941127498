define('ember-new/models/user', ['exports', 'ember-new/models/abstract', 'ember-data', 'ember'], function (exports, _emberNewModelsAbstract, _emberData, _ember) {

    var User = _emberNewModelsAbstract['default'].extend({
        _attributesExcludeBySerializer: ['enabled', 'credentialsExpired', 'lastLogin', 'roles', 'mainRoleLabel', 'fullName', 'fullNameWithRegions', 'fullNameWithCCInfo', 'searchingLimit', 'searchingLimitDefault', 'reservationsLimitDefault', 'activityLimitTaken', 'marginLimitTaken', 'contractsLimitTaken', 'dzu7LimitTaken', 'reservationsLimit', 'reservationsLimitTaken', 'contractedMeetingsLimitTaken', 'contractedMeetingsNotRated', 'oldPassword', 'avatar', 'region', 'safeName', 'smsNotification', 'emailNotification', 'regionIsPartner', 'regions', 'salesChannel', 'currentWorktimeDeclatation', 'isNeedToSignWorktimeDeclaration', 'currentWorktimeDeclarationKey'],

        // credentials
        username: _emberData['default'].attr('string'),
        email: _emberData['default'].attr('string'),

        // security params
        enabled: _emberData['default'].attr('boolean'),
        locked: _emberData['default'].attr('boolean'),
        credentialsExpired: _emberData['default'].attr('boolean'),
        lastLogin: _emberData['default'].attr('date'),

        // assignations and roles
        region: _emberData['default'].belongsTo('SalesRegionDictionaryEntry', { inverse: null }),
        regions: _emberData['default'].hasMany('SalesRegionDictionaryEntry', { inverse: null }),
        ccRegions: _emberData['default'].hasMany('SalesRegionDictionaryEntry', { inverse: null }),
        districts: _emberData['default'].hasMany('DistrictDictionaryEntry', { inverse: null }),
        counties: _emberData['default'].hasMany('CountyDictionaryEntry', { inverse: null }),
        roles: _emberData['default'].attr(),
        mainRole: _emberData['default'].attr('string'),
        mainRoleLabel: _emberData['default'].attr('string'),
        regionName: _emberData['default'].attr('string'),
        regionIsPartner: _emberData['default'].attr('boolean'),
        duonPartner: _emberData['default'].belongsTo('DuonPartnerDictionaryEntry', { inverse: null }),
        salesChannel: _emberData['default'].belongsTo('UserSalesChannelDictionaryEntry', { inverse: null }),
        userPosition: _emberData['default'].belongsTo('UserPositionDictionaryEntry', { inverse: null }),
        currentWorktimeDeclatation: _emberData['default'].attr('string'),
        isNeedToSignWorktimeDeclaration: _emberData['default'].attr('boolean'),
        currentWorktimeDeclarationKey: _emberData['default'].attr('string'),

        // user info
        firstName: _emberData['default'].attr('string'),
        lastName: _emberData['default'].attr('string'),
        fullName: _emberData['default'].attr('string'),

        address: _emberData['default'].attr('string'),
        personalIdentityNumber: _emberData['default'].attr('string'),
        contractType: _emberData['default'].attr('string'),
        taxIdentityNumber: _emberData['default'].attr('string'),
        businessRegistryNumber: _emberData['default'].attr('string'),
        identityCard: _emberData['default'].attr('string'),
        companyName: _emberData['default'].attr('string'),

        fullNameWithCCInfo: (function () {
            if (EmberNew.TheUser.isMainRoleIn(['ROLE_CC_CONSULTANT', 'ROLE_CC_MANAGER', 'ROLE_CC_HEADMANAGER'])) {
                var counties = '';

                if (this.get('counties.content').length !== 0) {
                    this.get('counties.content').forEach(function (item) {
                        counties += item.get('label') + ', ';
                    });
                }

                var name = this.get('firstName') + ' ' + this.get('lastName').substr(0, 1) + '. (' + this.get('region.label') + (counties.length ? ', Powiat: ' + counties : '') + ') ' + '[' + this.get('contractedMeetingsLimitTaken') + '/' + this.get('contractedMeetingsLimit') + ']';

                if (this.get('blockSalesmanForCc')) {
                    name += ' [NIE UMAWIAĆ]';
                }

                if (this.get('contractedMeetingsLimit') && this.get('contractedMeetingsLimitTaken') >= this.get('contractedMeetingsLimit')) {
                    name += ' [ZREALIZOWANE ZAMÓWIENIE]';
                }

                return name;
            }

            return this.get('fullName');
        }).property('fullName', 'contractedMeetingsLimit', 'contractedMeetingsLimitTaken'),
        lastNameLetter: (function () {
            return this.get('lastName').substr(0, 1) + '.';
        }).property('lastName'),
        fullNameWithRegions: (function () {
            var name = this.get('firstName') + ' ' + this.get('lastName');

            if (this.get('regions.content') && this.get('regions.content').length > 0) {
                name = name + ' [';
                this.get('regions').forEach(function (region, index) {
                    if (index != 0) {
                        name = name + ', ';
                    }

                    name = name + region.get('label');
                });
                name = name + ']';
            }

            return name;
        }).property('firstName', 'lastName', 'regions'),
        fullNameWithOwningRegionName: (function () {
            var name = this.get('firstName') + ' ' + this.get('lastName');
            name += ' [' + (this.get('regionName') ? this.get('regionName') : 'brak nazwy regionu') + ']';
            return name;
        }).property('firstName', 'lastName', 'regionName'),
        nick: _emberData['default'].attr('string'),
        phone: _emberData['default'].attr('string'),
        manager: _emberData['default'].belongsTo('user', { inverse: null }),
        newManager: _emberData['default'].attr('string'),
        safeName: (function () {
            if (this.get('nick') && this.get('nick').length) {
                return this.get('nick');
            } else {
                return this.get('firstName') + ' ' + this.get('lastNameLetter');
            }
        }).property(),

        // limits
        searchingLimit: _emberData['default'].attr('number'),
        //reservationsLimit: DS.attr('number'),

        // do wywalenia - why??????????????????????????????????????????????????????? znaczy TODO ;)
        activityLimit: _emberData['default'].attr('number'),
        activityLimitTaken: _emberData['default'].attr('number'),
        marginLimit: _emberData['default'].attr('number'),
        marginLimitTaken: _emberData['default'].attr('number'),
        contractsLimit: _emberData['default'].attr('number'),
        contractsLimitTaken: _emberData['default'].attr('number'),
        dzu7Limit: _emberData['default'].attr('number'),
        dzu7LimitTaken: _emberData['default'].attr('number'),
        reservationLimit: _emberData['default'].attr('number'),
        reservationsLimit: _emberData['default'].attr('number'),
        reservationsLimitTaken: _emberData['default'].attr('number'),
        contractedMeetingsLimit: _emberData['default'].attr('number'),
        contractedMeetingsLimitTaken: _emberData['default'].attr('number'),
        contractedMeetingsNotRated: _emberData['default'].attr('number'),
        contractedMeetingsNotResolved: _emberData['default'].attr('number'),
        blockSalesmanForCc: _emberData['default'].attr('boolean'),

        searchingLimitDefault: _emberData['default'].attr('number'),
        activityLimitDefault: _emberData['default'].attr('number'),
        marginLimitDefault: _emberData['default'].attr('number'),
        contractsLimitDefault: _emberData['default'].attr('number'),
        dzu7LimitDefault: _emberData['default'].attr('number'),
        reservationLimitDefault: _emberData['default'].attr('number'),
        reservationsLimitDefault: _emberData['default'].attr('number'),
        contractedMeetingsLimitDefault: _emberData['default'].attr('number'),

        // sales:
        hasCommitionBase: _emberData['default'].attr('boolean'),
        toDismiss: _emberData['default'].attr('boolean'),
        dismissionTime: _emberData['default'].attr(),
        range: _emberData['default'].attr('number'),
        employedTime: _emberData['default'].attr(),

        //    activityRequirement: DS.attr('number'),
        //    marginRequirement: DS.attr('number'),
        //    contactsRequirement: DS.attr('number'),
        //    dzu7Requirement: DS.attr('number'),
        //    orderedCCMeetings: DS.attr('number'),
        //
        //    carStatus: DS.attr(),
        //    carModel: DS.attr('string'),
        //    carRegistrationNumber: DS.attr('string'),
        //    phoneStatus: DS.attr(),
        //    tabletStatus: DS.attr(),
        //    medicalCareStatus: DS.attr(),

        plainPassword: _emberData['default'].attr('string'),
        plainPassword2: _emberData['default'].attr('string'),

        oldPassword: _emberData['default'].attr(),

        avatar: _emberData['default'].belongsTo('File', { inverse: null }),
        avatarImage: _emberData['default'].attr('string'),

        //powiadomienia
        smsNotification: _emberData['default'].attr('boolean'),
        emailNotification: _emberData['default'].attr('boolean'),

        availableForCc: _emberData['default'].attr('boolean'),

        car: _emberData['default'].belongsTo('Car', { inverse: null }),
        salesbookPin: _emberData['default'].attr('string'),
        hasSalesbookIntegration: _emberData['default'].attr('boolean'),
        uuid: _emberData['default'].attr('string'),

        getRange: (function () {

            if (this.get('range') === 1) return 'Młodszy specjalista';else if (this.get('range') == 2) return 'Specjalista';else if (this.get('range') == 3) return 'Ekspert';else if (this.get('range') == 4) return 'Przedstawiciel';else if (this.get('range') == 5) return 'Senior Ekspert';else if (this.get('range') == 6) return 'JUNIOR KAM';else if (this.get('range') == 7) return 'KAM';else if (this.get('range') == 8) return 'SENIOR KAM';else return '';
        }).property('range'),
        canCcOrder: (function () {
            if (this.get('range') === 2 || this.get('range') === 3 || this.get('range') === null) {
                return true;
            }
            return false;
        }).property('range'),

        isFromPartnerRegion: (function () {
            if (this.get('region') && this.get('region').get('isPartner')) {
                return true;
            }

            return false;
        }).property('region'),

        isSales: (function () {
            if (this.get('mainRole') === 'ROLE_SALES' || this.get('mainRole') === 'ROLE_SALES_SALESMAN' || this.get('mainRole') === 'ROLE_SALES_TEST_MANAGER' || this.get('mainRole') === 'ROLE_SALES_MANAGER' || this.get('mainRole') === 'ROLE_SALES_HEADMANAGER') {
                return true;
            }
            return false;
        }).property('mainRole'),

        fullNameWithDuonPartner: (function () {
            return this.get('fullName') + ' - ' + this.get('mainRoleLabel') + (this.get('duonPartner.label') ? ' - ' + this.get('duonPartner.label') : '');
        }).property('fullName', 'duonPartner', 'mainRoleLabel')
    });

    exports['default'] = User;
});
define("ember-new/mixins/controllers/individual-contract-partners/partner-add-new-contract", ["exports", "ember", "ember-new/utils/validators", "ember-new/config/environment", "ember-new/mixins/controllers/individual-contract-partners/form-fields"], function (exports, _ember, _emberNewUtilsValidators, _emberNewConfigEnvironment, _emberNewMixinsControllersIndividualContractPartnersFormFields) {
  var computed = _ember["default"].computed;
  var observer = _ember["default"].observer;
  var _Ember$inject = _ember["default"].inject;
  var service = _Ember$inject.service;
  var controller = _Ember$inject.controller;
  exports["default"] = _ember["default"].Mixin.create(_emberNewMixinsControllersIndividualContractPartnersFormFields["default"], {
    loadingPeselData: false,
    loadingPeselSecondClientData: false,
    ajax: service(),
    individualContractPartnersCtrl: controller('individual-contract-partners'),
    branchReadOnly: false,

    dictionariesManager: _ember["default"].inject.service('dictionaries-manager'),

    commandName: computed('formData.type', (function () {
      var type = this.get('formData.type');
      var duonPartnerId = this.get('TheUser').get('model.duonPartner');
      var isContractNumberGenerated = true;

      if (duonPartnerId) {
        isContractNumberGenerated = this.get('dictionariesManager').getMainDictionaryEntryByMongoId('duon_partner', duonPartnerId).get('isAutoGeneratedContractNumber');
      }

      if (!type && isContractNumberGenerated == false) {
        return 'CreateNewIndividualContractEeCommand'; // default...
      } else if (!type && isContractNumberGenerated == true) {
          return 'CreateNewIndividualContractEeWithoutContractNumberContextCommand'; // default...
        }

      var upper = type.charAt(0).toUpperCase() + type.substr(1);

      if (isContractNumberGenerated === true) {
        return "CreateNewIndividualContract" + upper + "WithoutContractNumberContextCommand";
      } else {
        return "CreateNewIndividualContract" + upper + "Command";
      }
    }).observes('formData.type')),

    contractNumberVisibility: (function () {
      var duonPartnerId = this.get('TheUser').get('model.duonPartner');

      if (!duonPartnerId) return true;

      var duonPartnerData = this.get('dictionariesManager').getMainDictionaryEntryByMongoId('duon_partner', duonPartnerId);

      return duonPartnerData.get('isAutoGeneratedContractNumber') ? 'hidden' : null;
    }).property(),

    contractNoTmp: null,
    contractNoTmpObserver: observer('contractNoTmp', 'formData.type', function () {
      var type = this.get('formData.type'),
          no = this.get('contractNoTmp'),
          validateResult = _emberNewUtilsValidators["default"].gasContractNumber(no + 'PG') || false; // tmp check.

      if (!validateResult) {
        this.set('formData.contractNo', null);
        this.set('formData.gasContractNo', null);
        return;
      }

      if (this.get('formData.type') === 'dual') {
        this.set('formData.contractNo', no + 'EE');
        this.set('formData.gasContractNo', no + 'PG');
      } else if (type === 'gas') {
        this.set('formData.gasContractNo', no + 'PG');
      } else if (type === 'ee') {
        this.set('formData.contractNo', no + 'EE');
      }
    }),

    init: function init() {
      this._super();
      this.set('thisContext', this);
    },

    isValidClientPesel: computed('clientPesel', function () {
      var pesel = this.get('clientPesel');
      var isValid = false;
      try {
        isValid = _emberNewUtilsValidators["default"].pesel(pesel);
      } catch (e) {
        isValid = false;
      }
      return isValid;
    }),

    isValidClientPeselSecondClient: computed('peselSecondClient', function () {
      var pesel = this.get('peselSecondClient');
      var isValid = false;
      try {
        isValid = _emberNewUtilsValidators["default"].pesel(pesel);
      } catch (e) {
        isValid = false;
      }
      return isValid;
    }),

    isExistingClient: false,
    isExistingSecondClient: false,
    isPeselDisabled: computed('foreigner', function () {
      var foreigner = this.get('foreigner');
      //.. some space for complex logic in future .... =)
      return foreigner;
    }),

    isSecondPeselDisabled: computed('foreignerSecondClient', function () {
      var foreignerSecondClient = this.get('foreignerSecondClient');
      return foreignerSecondClient;
    }),

    /**
     * Czysci pesel przy zmianie na obcokrajowca...
     */
    foreignerChanged: observer('formData.foreigner', function () {
      this.set('formData.clientPesel', '');
      this.setFormClientData({});
    }),
    foreignerSecondClientChanged: observer('formData.foreignerSecondClient', function () {
      this.set('formData.peselSecondClient', '');
      this.setFormSecondClientData({});
    }),

    /**
     *
     */
    getInfoByPesel: observer('clientPesel', function () {
      var _this2 = this;

      var pesel = this.get('clientPesel');
      var foreigner = this.get('foreigner');
      var isValid = this.get('isValidClientPesel');
      if (!isValid) return;

      this.set('loadingPeselData', true);
      this.getClientDataFromAPI(pesel, foreigner).then(function (res) {
        _this2.parseResponse(res);
        _this2.set('loadingPeselData', false);
      }, function (rej) {
        console.error('response', rej);
        _this2.set('loadingPeselData', false);
      });
    }),

    getSecondClientInfoByPesel: observer('peselSecondClient', function () {
      var _this3 = this;

      var pesel = this.get('peselSecondClient');
      var foreigner = this.get('foreignerSecondClient');
      var isValid = this.get('isValidClientPeselSecondClient');
      if (!isValid) return;

      this.set('loadingPeselSecondClientData', true);
      this.getClientDataFromAPI(pesel, foreigner).then(function (res) {
        _this3.parseResponseSecondClient(res);
        _this3.set('loadingPeselSecondClientData', false);
      }, function (rej) {
        console.error('response', rej);
        _this3.set('loadingPeselSecondClientData', false);
      });
    }),

    getClientDataFromAPI: function getClientDataFromAPI(pesel) {
      var foreigner = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

      var url = _emberNewConfigEnvironment["default"].apiUrl + '/api/individual-client/find_client_by_pesel';
      var data = { pesel: pesel, foreigner: foreigner };

      return this.get('ajax').ajaxPost(url, { form: data });
    },

    parseResponse: function parseResponse(res) {
      if (!res.status) {
        // new client
        this.fillFormNewClient();
      } else {
        this.fillFormExistingClient(res);
      }
    },

    parseResponseSecondClient: function parseResponseSecondClient(res) {
      if (!res.status) {
        // new client
        this.fillFormNewSecondClient();
      } else {
        this.fillFormExistingSecondClient(res);
      }
    },

    fillFormNewClient: function fillFormNewClient() {
      this.set('isExistingClient', false);
      // clear data..
      var client = { firstName: '', lastName: '', phone: '' };
      this.setFormClientData(client);
    },
    fillFormExistingClient: function fillFormExistingClient(data) {
      if (!data.model || !data.model.individualClient) return;

      var client = data.model.individualClient;
      this.set('isExistingClient', true);
      this.setFormClientData(client);
    },

    fillFormNewSecondClient: function fillFormNewSecondClient() {
      this.set('isExistingSecondClient', false);
      // clear data..
      var client = { firstName: '', lastName: '', phone: '' };
      this.setFormSecondClientData(client);
    },
    fillFormExistingSecondClient: function fillFormExistingSecondClient(data) {
      if (!data.model || !data.model.individualClient) return;

      var client = data.model.individualClient;
      this.set('isExistingSecondClient', true);
      this.setFormSecondClientData(client);
    },

    eeTariffGroups: [{ label: 'G11', value: '162e16be-beae-477b-81e7-f0c1301f3e92' }, { label: 'G12', value: '5aeff8ae-e429-49d0-9862-ed8578992939' }, { label: 'G12w', value: '5ed619da-aac9-4cdb-b8b6-87fea9ce3c9a' }],

    branchUser: (function () {
      if (this.get('TheUser').isMainRoleIn(['ROLE_SALES_SALESMAN'])) {
        this.set('branchReadOnly', true);
        this.set('formData.branch', this.get('TheUser').get('model.uuid'));

        return this.get('TheUser').get('model.uuid');
      }

      return null;
    }).property(),

    /**
     *
     * @param firstName
     * @param lastName
     * @param phone
     * @param phone2
     * @param firstNameSecondClient
     * @param lastNameSecondClient
     * @param phoneSecondClient
     * @param phone2SecondClient
     */
    setFormClientData: function setFormClientData(_ref) {
      var firstName = _ref.firstName;
      var lastName = _ref.lastName;
      var phone = _ref.phone;
      var phone2 = _ref.phone2;
      var firstNameSecondClient = _ref.firstNameSecondClient;
      var lastNameSecondClient = _ref.lastNameSecondClient;
      var phoneSecondClient = _ref.phoneSecondClient;
      var phone2SecondClient = _ref.phone2SecondClient;

      if (firstName || lastName || phone || phone2) {
        this.set('formData.clientFirstName', firstName);
        this.set('formData.clientLastName', lastName);
        this.set('formData.clientPhone', phone);
        this.set('formData.clientPhone2', phone2);
      }

      if (firstNameSecondClient || lastNameSecondClient || phoneSecondClient || phone2SecondClient) {
        this.set('formData.clientFirstName', firstNameSecondClient);
        this.set('formData.clientLastName', lastNameSecondClient);
        this.set('formData.clientPhone', phoneSecondClient);
        this.set('formData.clientPhone2', phone2SecondClient);
      }
    },

    setFormSecondClientData: function setFormSecondClientData(_ref2) {
      var firstName = _ref2.firstName;
      var lastName = _ref2.lastName;
      var phone = _ref2.phone;
      var phone2 = _ref2.phone2;
      var firstNameSecondClient = _ref2.firstNameSecondClient;
      var lastNameSecondClient = _ref2.lastNameSecondClient;
      var phoneSecondClient = _ref2.phoneSecondClient;
      var phone2SecondClient = _ref2.phone2SecondClient;

      if (firstName || lastName || phone || phone2) {
        this.set('formData.firstNameSecondClient', firstName);
        this.set('formData.lastNameSecondClient', lastName);
        this.set('formData.phoneSecondClient', phone);
        this.set('formData.phone2SecondClient', phone2);
      }

      if (firstNameSecondClient || lastNameSecondClient || phoneSecondClient || phone2SecondClient) {
        this.set('formData.firstNameSecondClient', firstNameSecondClient);
        this.set('formData.lastNameSecondClient', lastNameSecondClient);
        this.set('formData.phoneSecondClient', phoneSecondClient);
        this.set('formData.phone2SecondClient', phone2SecondClient);
      }
    },

    fillForm: function fillForm(form) {
      this.set('formData', form.get('formData'));

      this.get('formFieldsForMappingStates').forEach(function (field) {
        // form.set(`model.${field}`, this.get(`${field}`));
        form.set("model." + field, null);
      });
    },

    configureFrom: function configureFrom(form) {
      var controller = this.get('targetObject');
      controller.toggleContractTypeFields.call(controller, form);
      controller.toggleFormFieldsByPesel.call(controller, form);
      controller.toggleBranchReadonly.call(controller, form);
    },
    afterCommand: function afterCommand(response) {
      var ctrl = this;
      ctrl.transitionToRoute('individual-contract-partners');
      this.get('individualContractPartnersCtrl').send('refresh');
      // ctrl.get('target').send('refreshContractItemModel', ctrl.get('model.id'));
    },

    /**
     * odblokowywuje pola formularza gdy pesel jest prawidlowy
     * @param form
     */
    toggleFormFieldsByPesel: function toggleFormFieldsByPesel(form) {
      var _this = this;
      var formFields = this.get('formFieldsForMappingStates');
      var fieldToExclude = ['clientPesel', 'foreigner', 'branch'];

      var fieldToExcludeSecondClient = ['peselSecondClient', 'foreignerSecondClient'];
      var fieldsSecondClient = ['firstNameSecondClient', 'lastNameSecondClient', 'phoneSecondClient', 'phone2SecondClient', 'peselSecondClient', 'foreignerSecondClient'];

      var fieldsToMap = formFields.filter(function (el) {
        return !fieldToExclude.includes(el);
      });
      var fieldsClientFiltered = fieldsToMap.filter(function (el) {
        return !fieldsSecondClient.includes(el);
      });

      var fieldsSecondClientFiltered = fieldsSecondClient.filter(function (el) {
        return !fieldToExcludeSecondClient.includes(el);
      });

      // obcokrajowiec
      form.addObserver('formData.foreigner', function (form) {
        fieldsClientFiltered.forEach(function (field) {
          if (_this.get('formData.foreigner')) {
            form.set("formRows." + field + ".field.disabled", false);
          } else {
            form.set("formRows." + field + ".field.disabled", true);
          }
        });
      });

      form.addObserver('formData.foreignerSecondClient', function (form) {
        fieldsSecondClientFiltered.forEach(function (field) {
          if (_this.get('formData.foreignerSecondClient')) {
            form.set("formRows." + field + ".field.disabled", false);
          } else {
            form.set("formRows." + field + ".field.disabled", true);
          }
        });
      });

      // walidacja pesel...
      form.addObserver('formData.clientPesel', function (form) {
        var isValidField = _this.get('isValidClientPesel');
        fieldsClientFiltered.forEach(function (field) {
          form.set("formRows." + field + ".field.disabled", !isValidField);
        });
      });
      form.addObserver('formData.peselSecondClient', function (form) {
        var isValidField = _this.get('isValidClientPeselSecondClient');
        fieldsSecondClientFiltered.forEach(function (field) {
          form.set("formRows." + field + ".field.disabled", !isValidField);
        });
      });
    },

    /**
     * Pokazuje i ukrywa pola formularza zalezne od typu umowy.
     * @param form
     */
    toggleContractTypeFields: function toggleContractTypeFields(form) {
      var _this = this;
      form.addObserver('formData.type', function (form) {
        var type = this.get('formData.type');
        if (type === 'ee') {
          _this.showEE(form);
          _this.readOnlyOnEE(form);
          _this.hideGas(form);
          _this.clearGas(form);
        } else if (type === 'gas') {
          _this.hideEE(form);
          _this.showGas(form);
          _this.readOnlyOnGas(form);
          _this.clearEE(form);
        } else if (type === 'dual') {
          _this.showEE(form);
          _this.readOnlyOnEE(form);
          _this.showGas(form);
          _this.readOnlyOnGas(form);
        } else {
          _this.hideEE(form);
          _this.hideGas(form);
        }
      });
    },

    toggleBranchReadonly: function toggleBranchReadonly(form) {
      if (this.get('TheUser').isMainRoleIn(['ROLE_SALES_SALESMAN'])) {
        this.set('branchReadOnly', true);
        form.set('model.branch', this.get('TheUser').get('model.uuid'));
      }
    },

    hideGas: function hideGas(form) {
      form.set('formRows.gasTariffUuid.hidden', true);
      form.set('formRows.gasContractNo.hidden', true);
      form.set('formRows.gasLoyaltyDate.hidden', true);
    },
    showGas: function showGas(form) {
      form.set('formRows.gasTariffUuid.hidden', false);
      form.set('formRows.gasContractNo.hidden', false);
      form.set('formRows.gasLoyaltyDate.hidden', false);
    },
    clearGas: function clearGas(form) {
      form.set('formData.gasContractNo', '');
      form.set('formData.gasTariffUuid', '');
      form.set('formRows.gasLoyaltyDate,', '');
    },
    hideEE: function hideEE(form) {
      form.set('formRows.contractNo.hidden', true);
      form.set('formRows.tariffGroupUuid.hidden', true);
      form.set('formRows.eeLoyaltyDate.hidden', true);
    },
    showEE: function showEE(form) {
      form.set('formRows.contractNo.hidden', false);
      form.set('formRows.tariffGroupUuid.hidden', false);
      form.set('formRows.eeLoyaltyDate.hidden', false);
    },
    clearEE: function clearEE(form) {
      form.set('formData.contractNo', '');
      form.set('formData.gasTariffUuid', '');
      form.set('formData.eeLoyaltyDate', '');
    },
    readOnlyOnEE: function readOnlyOnEE(form) {
      form.get('formRows.contractNo.field').setReadOnly(true);
    },
    readOnlyOnGas: function readOnlyOnGas(form) {
      form.get('formRows.gasContractNo.field').setReadOnly(true);
    },
    readOnlyOffEE: function readOnlyOffEE(form) {
      form.get('formRows.contractNo.field').setReadOnly(false);
    },
    readOnlyOffGas: function readOnlyOffGas(form) {
      form.get('formRows.gasContractNo.field').setReadOnly(false);
    },

    resetData: function resetData() {
      var _this4 = this;

      // todo: zastosować jakas metode do resetowania controllera do stanu początkowgo
      this.get('formFieldsForMappingStates').forEach(function (field) {
        _this4.set(field, null);
      });
      this.set('contractNoTmp', null);
    }
  });
});
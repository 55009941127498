define('ember-new/controllers/individual-contract/show-ee/status-history', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        resetData: function resetData() {
            this.set('model', null);
        },

        isEditable: (function () {
            var editSells = this.get('model.credentials.editSells');
            var isAnonymized = this.get('model.isAnonymized');
            return editSells && !isAnonymized;
        }).property('model')
    });
});
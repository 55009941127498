define('ember-new/controllers/individual-contract-partners/bulk-filtered', ['exports', 'ember'], function (exports, _ember) {
    var observer = _ember['default'].observer;
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Controller.extend({
        applicationAlerts: service('application-alerts-manager'),
        bulkActionsState: service(),

        // 'filters' from route
        setFiltersInService: observer('filters', function () {
            var filters = this.get('filters');
            this.get('bulkActionsState').setFilters(filters);
        }),

        actions: {
            afterBulkAction: function afterBulkAction() {
                this.get('target').send('refresh');
                this.transitionToRoute('individual-contract-partners');
                this.get('applicationAlerts').addActionMessage('success', 'Pomyślnie wykonano akcje.');
            }
        }
    });
});
define('ember-new/routes/b2b-contract/administration/section-pricelists/list/delete', ['exports', 'ember-new/routes/authenticated'], function (exports, _emberNewRoutesAuthenticated) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({
        model: function model(params) {
            var basePricesetModel = this.modelFor('b2b-contract.administration.section-pricelists');

            var data = { idPriceSet: params.pricesetUuid, basePricesetModel: basePricesetModel };
            return data;
        },
        setupController: function setupController(controller, model) {
            controller.set('idPriceSet', model.idPriceSet);
            controller.set('priceSetModel', model.basePricesetModel);
        }
    });
});
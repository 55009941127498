define('ember-new/controllers/individual-contract-partners/bulk-selected', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;
    var computed = _ember['default'].computed;
    var _Ember$inject = _ember['default'].inject;
    var service = _Ember$inject.service;
    var controller = _Ember$inject.controller;
    exports['default'] = _ember['default'].Controller.extend({
        individualContractPartners: controller(),
        applicationAlerts: service('application-alerts-manager'),

        selectedItems: computed('individualContractPartners.selectedItems', function () {
            return this.get('individualContractPartners.selectedItems');
        }),
        actions: {
            afterBulkAction: function afterBulkAction() {
                this.get('target').send('refresh');
                this.transitionToRoute('individual-contract-partners');
                this.get('applicationAlerts').addActionMessage('success', 'Pomyślnie wykonano akcje.');
            }
        }
    });
});
define('ember-new/mixins/controllers/individual-contract/anonymize', ['exports', 'ember', 'ember-new/config/environment'], function (exports, _ember, _emberNewConfigEnvironment) {
    exports['default'] = _ember['default'].Mixin.create({

        ajax: _ember['default'].inject.service('ajax'),
        applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),

        successMessage: 'Dane klienta zostały pomyślnie zanonimizowane!',

        actions: {
            anonymize: function anonymize(uuid) {
                var _this = this;

                if (!confirm("Czy jesteś pewny? Anonimizacja danych klienta, jego umów i odstąpień jest nieodwracalna.")) {
                    return;
                }

                var applicationAlerts = this.get('applicationAlerts');
                var url = _emberNewConfigEnvironment['default'].apiUrl + '/rest/anonymizeclientandrelatedelementscommands';
                var payload = {
                    'AnonymizeClientAndRelatedElementsCommand': {
                        clientUuid: uuid
                    }
                };

                this.get('ajax').ajaxPost(url, payload).then(function () {
                    applicationAlerts.addActionMessage('success', _this.get('successMessage'));
                    setTimeout(function () {
                        _this.send('refreshRoute');
                        _this.transitionToRoute(_this.get('returnRoute'));
                    }, 3000);
                }, function (reject) {
                    applicationAlerts.addActionMessage('danger', reject.responseJSON.message);
                });
            }
        }
    });
});
define("ember-new/models/base-price-list/ee-price-list-row-errors-view-model", ["exports", "ember-new/models/abstract", "ember-data/attr"], function (exports, _emberNewModelsAbstract, _emberDataAttr) {
    exports["default"] = _emberNewModelsAbstract["default"].extend({
        dateFrom: (0, _emberDataAttr["default"])(),
        dateTo: (0, _emberDataAttr["default"])(),
        tariffGroup: (0, _emberDataAttr["default"])(),
        basePriceArea1: (0, _emberDataAttr["default"])(),
        basePriceArea2: (0, _emberDataAttr["default"])(),
        basePriceArea3: (0, _emberDataAttr["default"])(),
        basePriceArea4: (0, _emberDataAttr["default"])(),
        flatPrice: (0, _emberDataAttr["default"])(),
        baseFee: (0, _emberDataAttr["default"])()
    });
});
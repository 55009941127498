define("ember-new/templates/b2b-contract/administration/section-products/details", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 4
              },
              "end": {
                "line": 3,
                "column": 47
              }
            },
            "moduleName": "ember-new/templates/b2b-contract/administration/section-products/details.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "fa fa-arrow-circle-left");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 31,
                  "column": 28
                },
                "end": {
                  "line": 31,
                  "column": 65
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/administration/section-products/details.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("TAK");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 31,
                  "column": 65
                },
                "end": {
                  "line": 31,
                  "column": 76
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/administration/section-products/details.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("NIE");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 34,
                  "column": 28
                },
                "end": {
                  "line": 34,
                  "column": 59
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/administration/section-products/details.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("TAK");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child3 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 34,
                  "column": 59
                },
                "end": {
                  "line": 34,
                  "column": 70
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/administration/section-products/details.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("NIE");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child4 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 37,
                  "column": 28
                },
                "end": {
                  "line": 37,
                  "column": 92
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/administration/section-products/details.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("TAK");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child5 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 37,
                  "column": 92
                },
                "end": {
                  "line": 37,
                  "column": 103
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/administration/section-products/details.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("NIE");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child6 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 40,
                  "column": 28
                },
                "end": {
                  "line": 42,
                  "column": 28
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/administration/section-products/details.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["content", "product", ["loc", [null, [41, 35], [41, 46]]]]],
            locals: ["product"],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 6,
                "column": 4
              },
              "end": {
                "line": 51,
                "column": 4
              }
            },
            "moduleName": "ember-new/templates/b2b-contract/administration/section-products/details.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row margin-bottom");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-sm-12");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("table");
            dom.setAttribute(el3, "class", "table table-bordered table-striped dataTable prices-table");
            dom.setAttribute(el3, "role", "grid");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("thead");
            var el5 = dom.createTextNode("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("tr");
            dom.setAttribute(el5, "role", "row");
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("th");
            dom.setAttribute(el6, "class", "col-sm-1");
            var el7 = dom.createTextNode("Rodzaj");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("th");
            dom.setAttribute(el6, "class", "col-sm-2");
            var el7 = dom.createTextNode("Nazwa produktu");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("th");
            dom.setAttribute(el6, "class", "col-sm-1");
            var el7 = dom.createTextNode("Typ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("th");
            dom.setAttribute(el6, "class", "col-sm-1");
            var el7 = dom.createTextNode("USYS ID");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("th");
            dom.setAttribute(el6, "class", "col-sm-1");
            var el7 = dom.createTextNode("Czy masa marży widoczna dla doradcy");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("th");
            dom.setAttribute(el6, "class", "col-sm-1");
            var el7 = dom.createTextNode("Czy produkt indeksowany");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("th");
            dom.setAttribute(el6, "class", "col-sm-1");
            var el7 = dom.createTextNode("Wszystkie produkty-warunkowe indeksowane dostępne na warunkach cenowych\n                        ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("th");
            dom.setAttribute(el6, "class", "col-sm-2");
            var el7 = dom.createTextNode("Przypisany produkt do warunku");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("th");
            dom.setAttribute(el6, "class", "col-sm-2");
            var el7 = dom.createTextNode("Nazwa domyślnego produktu na warunku");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                    ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                    ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("tbody");
            var el5 = dom.createTextNode("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("tr");
            dom.setAttribute(el5, "role", "row");
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("td");
            dom.setAttribute(el6, "class", "col-sm-1");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("td");
            dom.setAttribute(el6, "class", "col-sm-2");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("td");
            dom.setAttribute(el6, "class", "col-sm-1");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("td");
            dom.setAttribute(el6, "class", "col-sm-1");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("td");
            dom.setAttribute(el6, "class", "col-sm-1");
            var el7 = dom.createTextNode("\n                            ");
            dom.appendChild(el6, el7);
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n                        ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("td");
            dom.setAttribute(el6, "class", "col-sm-1");
            var el7 = dom.createTextNode("\n                            ");
            dom.appendChild(el6, el7);
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n                        ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("td");
            dom.setAttribute(el6, "class", "col-sm-1");
            var el7 = dom.createTextNode("\n                            ");
            dom.appendChild(el6, el7);
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n                        ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("td");
            dom.setAttribute(el6, "class", "col-sm-2");
            var el7 = dom.createTextNode("\n");
            dom.appendChild(el6, el7);
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("                        ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("td");
            dom.setAttribute(el6, "class", "col-sm-2");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                    ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                    ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1, 1, 1, 3, 1]);
            var morphs = new Array(9);
            morphs[0] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
            morphs[1] = dom.createMorphAt(dom.childAt(element2, [3]), 0, 0);
            morphs[2] = dom.createMorphAt(dom.childAt(element2, [5]), 0, 0);
            morphs[3] = dom.createMorphAt(dom.childAt(element2, [7]), 0, 0);
            morphs[4] = dom.createMorphAt(dom.childAt(element2, [9]), 1, 1);
            morphs[5] = dom.createMorphAt(dom.childAt(element2, [11]), 1, 1);
            morphs[6] = dom.createMorphAt(dom.childAt(element2, [13]), 1, 1);
            morphs[7] = dom.createMorphAt(dom.childAt(element2, [15]), 1, 1);
            morphs[8] = dom.createMorphAt(dom.childAt(element2, [17]), 0, 0);
            return morphs;
          },
          statements: [["content", "model.kind", ["loc", [null, [26, 45], [26, 59]]]], ["content", "model.name", ["loc", [null, [27, 45], [27, 59]]]], ["content", "model.type", ["loc", [null, [28, 45], [28, 59]]]], ["content", "model.usysId", ["loc", [null, [29, 45], [29, 61]]]], ["block", "if", [["get", "model.mmVisibleForSalesman", ["loc", [null, [31, 34], [31, 60]]]]], [], 0, 1, ["loc", [null, [31, 28], [31, 83]]]], ["block", "if", [["get", "model.productIndexed", ["loc", [null, [34, 34], [34, 54]]]]], [], 2, 3, ["loc", [null, [34, 28], [34, 77]]]], ["block", "if", [["get", "model.allConditionProductsAvailableForPriceConditions", ["loc", [null, [37, 34], [37, 87]]]]], [], 4, 5, ["loc", [null, [37, 28], [37, 110]]]], ["block", "each", [["get", "model.linkedProducts", ["loc", [null, [40, 36], [40, 56]]]]], ["key", "@index"], 6, null, ["loc", [null, [40, 28], [42, 37]]]], ["content", "model.defaultConditionProductName", ["loc", [null, [44, 45], [44, 82]]]]],
          locals: [],
          templates: [child0, child1, child2, child3, child4, child5, child6]
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 79,
                  "column": 28
                },
                "end": {
                  "line": 79,
                  "column": 60
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/administration/section-products/details.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("TAK");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 79,
                  "column": 60
                },
                "end": {
                  "line": 79,
                  "column": 71
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/administration/section-products/details.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("NIE");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 80,
                  "column": 28
                },
                "end": {
                  "line": 89,
                  "column": 28
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/administration/section-products/details.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "text-center");
              var el2 = dom.createTextNode("\n                                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              var el3 = dom.createElement("strong");
              dom.setAttribute(el3, "style", "color:orange; text-align:center");
              var el4 = dom.createTextNode("\n                                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("i");
              dom.setAttribute(el4, "class", "fa fa-exclamation-triangle");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" Uwaga!\n                                    ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              var el3 = dom.createElement("strong");
              dom.setAttribute(el3, "style", "color:orange; text-align:center");
              var el4 = dom.createTextNode("\n                                        Brak zdefiniowanego cennika bazowego.\n                                    ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child3 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 92,
                  "column": 28
                },
                "end": {
                  "line": 92,
                  "column": 66
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/administration/section-products/details.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("TAK");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child4 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 92,
                  "column": 66
                },
                "end": {
                  "line": 92,
                  "column": 77
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/administration/section-products/details.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("NIE");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child5 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 95,
                  "column": 28
                },
                "end": {
                  "line": 95,
                  "column": 59
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/administration/section-products/details.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("TAK");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child6 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 95,
                  "column": 59
                },
                "end": {
                  "line": 95,
                  "column": 70
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/administration/section-products/details.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("NIE");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child7 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 101,
                  "column": 24
                },
                "end": {
                  "line": 103,
                  "column": 24
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/administration/section-products/details.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                        ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["content", "product", ["loc", [null, [102, 31], [102, 42]]]]],
            locals: ["product"],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 55,
                "column": 4
              },
              "end": {
                "line": 110,
                "column": 4
              }
            },
            "moduleName": "ember-new/templates/b2b-contract/administration/section-products/details.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row margin-bottom");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-sm-12");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("table");
            dom.setAttribute(el3, "class", "table table-bordered table-striped dataTable prices-table");
            dom.setAttribute(el3, "role", "grid");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("thead");
            var el5 = dom.createTextNode("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("tr");
            dom.setAttribute(el5, "role", "row");
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("th");
            dom.setAttribute(el6, "class", "col-sm-1");
            var el7 = dom.createTextNode("Rodzaj");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("th");
            dom.setAttribute(el6, "class", "col-sm-2");
            var el7 = dom.createTextNode("Nazwa produktu");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("th");
            dom.setAttribute(el6, "class", "col-sm-1");
            var el7 = dom.createTextNode("Typ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("th");
            dom.setAttribute(el6, "class", "col-sm-1");
            var el7 = dom.createTextNode("Czy produkt posiada cennik bazowy");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("th");
            dom.setAttribute(el6, "class", "col-sm-1");
            var el7 = dom.createTextNode("Czy cennik bazowy automatyczny");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("th");
            dom.setAttribute(el6, "class", "col-sm-1");
            var el7 = dom.createTextNode("Czy produkt indeksowany");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("th");
            dom.setAttribute(el6, "class", "col-sm-1");
            var el7 = dom.createTextNode("Typ indeksu");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("th");
            dom.setAttribute(el6, "class", "col-sm-1");
            var el7 = dom.createTextNode("Typ uzupełnianej wartości na warunku");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("th");
            dom.setAttribute(el6, "class", "col-sm-1");
            var el7 = dom.createTextNode("Zmienność warunku");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("th");
            dom.setAttribute(el6, "class", "col-sm-2");
            var el7 = dom.createTextNode("Przypisany do produktu do umowy");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                    ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                    ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("tbody");
            var el5 = dom.createTextNode("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("tr");
            dom.setAttribute(el5, "role", "row");
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("td");
            dom.setAttribute(el6, "class", "col-sm-1");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("td");
            dom.setAttribute(el6, "class", "col-sm-2");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("td");
            dom.setAttribute(el6, "class", "col-sm-1");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("td");
            dom.setAttribute(el6, "class", "col-sm-1");
            var el7 = dom.createTextNode("\n                            ");
            dom.appendChild(el6, el7);
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n");
            dom.appendChild(el6, el7);
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("                        ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("td");
            dom.setAttribute(el6, "class", "col-sm-1");
            var el7 = dom.createTextNode("\n                            ");
            dom.appendChild(el6, el7);
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n                        ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("td");
            dom.setAttribute(el6, "class", "col-sm-1");
            var el7 = dom.createTextNode("\n                            ");
            dom.appendChild(el6, el7);
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n                        ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("td");
            dom.setAttribute(el6, "class", "col-sm-1");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("td");
            dom.setAttribute(el6, "class", "col-sm-1");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("td");
            dom.setAttribute(el6, "class", "col-sm-1");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("td");
            dom.setAttribute(el6, "class", "col-sm-2");
            var el7 = dom.createTextNode("\n");
            dom.appendChild(el6, el7);
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                    ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                    ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1, 1, 1, 3, 1]);
            var element1 = dom.childAt(element0, [7]);
            var morphs = new Array(11);
            morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
            morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
            morphs[2] = dom.createMorphAt(dom.childAt(element0, [5]), 0, 0);
            morphs[3] = dom.createMorphAt(element1, 1, 1);
            morphs[4] = dom.createMorphAt(element1, 3, 3);
            morphs[5] = dom.createMorphAt(dom.childAt(element0, [9]), 1, 1);
            morphs[6] = dom.createMorphAt(dom.childAt(element0, [11]), 1, 1);
            morphs[7] = dom.createMorphAt(dom.childAt(element0, [13]), 0, 0);
            morphs[8] = dom.createMorphAt(dom.childAt(element0, [15]), 0, 0);
            morphs[9] = dom.createMorphAt(dom.childAt(element0, [17]), 0, 0);
            morphs[10] = dom.createMorphAt(dom.childAt(element0, [19]), 1, 1);
            return morphs;
          },
          statements: [["content", "model.kind", ["loc", [null, [75, 45], [75, 59]]]], ["content", "model.name", ["loc", [null, [76, 45], [76, 59]]]], ["content", "model.type", ["loc", [null, [77, 45], [77, 59]]]], ["block", "if", [["get", "model.hasBasePriceSet", ["loc", [null, [79, 34], [79, 55]]]]], [], 0, 1, ["loc", [null, [79, 28], [79, 78]]]], ["block", "if", [["get", "showNoBasePriceListNotification", ["loc", [null, [80, 34], [80, 65]]]]], [], 2, null, ["loc", [null, [80, 28], [89, 35]]]], ["block", "if", [["get", "model.automaticBasePriceSet", ["loc", [null, [92, 34], [92, 61]]]]], [], 3, 4, ["loc", [null, [92, 28], [92, 84]]]], ["block", "if", [["get", "model.productIndexed", ["loc", [null, [95, 34], [95, 54]]]]], [], 5, 6, ["loc", [null, [95, 28], [95, 77]]]], ["content", "model.productModel", ["loc", [null, [97, 45], [97, 67]]]], ["content", "model.valueType", ["loc", [null, [98, 45], [98, 64]]]], ["content", "model.volatilityPeriod", ["loc", [null, [99, 45], [99, 71]]]], ["block", "each", [["get", "model.linkedProducts", ["loc", [null, [101, 32], [101, 52]]]]], ["key", "@index"], 7, null, ["loc", [null, [101, 24], [103, 33]]]]],
          locals: [],
          templates: [child0, child1, child2, child3, child4, child5, child6, child7]
        };
      })();
      return {
        meta: {
          "fragmentReason": {
            "name": "missing-wrapper",
            "problems": ["wrong-type", "multiple-nodes"]
          },
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 112,
              "column": 0
            }
          },
          "moduleName": "ember-new/templates/b2b-contract/administration/section-products/details.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("small");
          var el2 = dom.createTextNode("Powrót do listy produktów");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("    szczegóły produktu na umowie ");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("    szczegóły produktu na warunku ");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 5, 5, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 9, 9, contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "link-to", ["b2b-contract.administration.section-products.list"], ["class", "btn btn-lg", "active", false], 0, null, ["loc", [null, [2, 4], [3, 59]]]], ["block", "if", [["get", "isContractProduct", ["loc", [null, [6, 10], [6, 27]]]]], [], 1, null, ["loc", [null, [6, 4], [51, 11]]]], ["block", "if", [["get", "isPriceConditionProduct", ["loc", [null, [55, 10], [55, 33]]]]], [], 2, null, ["loc", [null, [55, 4], [110, 11]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.3.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 114,
            "column": 10
          }
        },
        "moduleName": "ember-new/templates/b2b-contract/administration/section-products/details.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "box-for-form", [], ["title", "Podgląd produktu"], 0, null, ["loc", [null, [1, 0], [112, 17]]]], ["content", "outlet", ["loc", [null, [114, 0], [114, 10]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});
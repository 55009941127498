define('ember-new/models/individual-contract-template-condition-view-model', ['exports', 'ember-data'], function (exports, _emberData) {

    var attr = _emberData['default'].attr;

    exports['default'] = _emberData['default'].Model.extend({
        uuid: attr('string'),
        type: attr('string'),
        periodType: attr('string'),
        yearFrom: attr('string'),
        monthFrom: attr('string'),
        yearTo: attr('string'),
        monthTo: attr('string'),
        priceG: attr('string'),
        priceE1: attr('string'),
        priceE2: attr('string'),
        priceE3: attr('string'),
        priceE4: attr('string'),
        fee: attr('string'),
        templateType: attr('string'),
        templateUuid: attr('string'),
        hasAnyContract: attr('boolean'),
        numberOfAssignedContracts: attr('number')
    });
});
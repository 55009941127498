define("ember-new/templates/b2b-contract/dzu/partials/prices/value/gas/discount-overhead", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes"]
        },
        "revision": "Ember@2.3.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 11,
            "column": 5
          }
        },
        "moduleName": "ember-new/templates/b2b-contract/dzu/partials/prices/value/gas/discount-overhead.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("td");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("td");
        dom.setAttribute(el1, "colspan", "");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("td");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2]);
        var element1 = dom.childAt(fragment, [4]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        morphs[1] = dom.createMorphAt(element0, 1, 1);
        morphs[2] = dom.createMorphAt(element0, 3, 3);
        morphs[3] = dom.createMorphAt(element1, 1, 1);
        morphs[4] = dom.createMorphAt(element1, 3, 3);
        return morphs;
      },
      statements: [["inline", "cell-data-label", [], ["value", ["subexpr", "@mut", [["get", "periodValue.product", ["loc", [null, [2, 28], [2, 47]]]]], [], []], "label", "Produkt"], ["loc", [null, [2, 4], [2, 65]]]], ["inline", "cell-data-label", [], ["value", ["subexpr", "@mut", [["get", "periodValue.discount.discountValue", ["loc", [null, [5, 28], [5, 62]]]]], [], []], "label", "rabat", "afterValue", "%"], ["loc", [null, [5, 4], [5, 94]]]], ["inline", "cell-data-label", [], ["value", ["subexpr", "format-currency", [["get", "periodValue.discount.overheadValue", ["loc", [null, [6, 45], [6, 79]]]]], [], ["loc", [null, [6, 28], [6, 80]]]], "label", "narzut"], ["loc", [null, [6, 4], [6, 97]]]], ["inline", "cell-data-label", [], ["value", ["subexpr", "@mut", [["get", "periodValue.subscription", ["loc", [null, [9, 26], [9, 50]]]]], [], []], "label", "Abonament na umowie", "afterValue", " zł/m-c"], ["loc", [null, [9, 2], [9, 101]]]], ["inline", "cell-data-label", [], ["value", ["subexpr", "@mut", [["get", "periodValue.baseSubscription", ["loc", [null, [10, 26], [10, 54]]]]], [], []], "label", "Abonament bazowy", "afterValue", " zł/m-c"], ["loc", [null, [10, 2], [10, 102]]]]],
      locals: [],
      templates: []
    };
  })());
});
define("ember-new/controllers/dashboard/add", ["exports", "ember", "ember-new/mixins/dashboard/d-form/controllers/blog-post-form", "ember-new/config/environment"], function (exports, _ember, _emberNewMixinsDashboardDFormControllersBlogPostForm, _emberNewConfigEnvironment) {
  exports["default"] = _ember["default"].Controller.extend(_emberNewMixinsDashboardDFormControllersBlogPostForm["default"], {

    fileId: null,
    applicationAlerts: _ember["default"].inject.service('application-alerts-manager'),

    customUrl: _emberNewConfigEnvironment["default"].apiUrl + '/_uploader/blogPostAttachment/upload',
    paramName: 'content',

    fillForm: function fillForm(form) {
      form.set('model.category', null);
      form.set('model.contentType', null);
      form.set('model.content', this.get('fileId'));
      form.set('model.contentVimeo', null);
      form.set('model.contentYoutube', null);
      form.set('model.contentHtml', null);
      form.set('model.contentTextAttachment', null);
      form.set('model.isArchived', null);
    }

  });
});
define('ember-new/routes/b2b-contract/dzu/show-ee/add-audio-recording', ['exports', 'ember-new/routes/authenticated'], function (exports, _emberNewRoutesAuthenticated) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({

        beforeModel: function beforeModel() {
            window.scrollTo(0, 0);
        },

        model: function model(params) {
            var contract = this.modelFor('b2b-contract.dzu.show-ee');
            this.set('idContract', contract.get('id'));
        },

        setupController: function setupController(controller, model) {
            controller.set('model', model);
            controller.set('idContract', this.get('idContract'));
        },

        actions: {
            updateModel: function updateModel() {
                alert("I'm updating the model mother!");
            },

            refreshModel: function refreshModel() {
                this.refresh();
            }
        }

    });
});
define("ember-new/routes/renouncement/list", ["exports", "ember-new/routes/authenticated", "ember-new/config/environment", "ember"], function (exports, _emberNewRoutesAuthenticated, _emberNewConfigEnvironment, _ember) {
    exports["default"] = _emberNewRoutesAuthenticated["default"].extend({
        model: function model(params) {
            //return Ember.A();
            return this.store.query('renouncementSimplified', params);
        },
        actions: {
            queryParamsDidChange: function queryParamsDidChange() {
                this.refresh();
            },

            refreshRoute: function refreshRoute() {
                this.refresh();
            }
        }
    });
});
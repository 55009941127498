define('ember-new/controllers/renouncement/add/contract-details', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Controller.extend({
		actions: {
			choose: function choose() {
				if (this.get('model.isEe')) {
					this.send('chooseEeContract', this.get('model'));
				} else {
					this.send('chooseGasContract', this.get('model'));
				}

				this.transitionToRoute('renouncement.add');
			},
			closeModal: function closeModal() {

				this.transitionToRoute('renouncement.add');
			}
		}
	});
});
define('ember-new/controllers/individual-contract/export-filtered', ['exports', 'ember', 'ember-new/utils/encode-object-to-uri', 'ember-new/config/environment', 'ember-new/utils/utils'], function (exports, _ember, _emberNewUtilsEncodeObjectToUri, _emberNewConfigEnvironment, _emberNewUtilsUtils) {
    var computed = _ember['default'].computed;
    exports['default'] = _ember['default'].Controller.extend({

        cookies: _ember['default'].inject.service('cookies'),

        init: function init() {
            this._initPartnersToFilter();
        },
        // fields for link
        urlFields: ['reportingDateFrom', 'reportingDateTo', 'signingDateFrom', 'signingDateTo', 'acceptDateFrom', 'acceptDateTo', 'partner', 'product', 'loyalty', 'renouncement'],
        // values for URL
        reportingDateFrom: null,
        reportingDateTo: null,
        signingDateFrom: null,
        signingDateTo: null,
        acceptDateFrom: null,
        acceptDateTo: null,
        partner: computed('partnersSelected', function () {
            return this.get('partnersSelected').mapBy('value').join();
        }),
        product: null,
        loyalty: false,
        renouncement: false,

        // TODO: w wolnech chwili sprobowac tego:
        // https://lookingsideways.co.uk/ember-computed-apply/

        isDisabledExportButton: computed('reportingDateFrom', 'reportingDateTo', 'signingDateFrom', 'signingDateTo', 'acceptDateFrom', 'acceptDateTo', 'partner', 'product', 'loyalty', 'renouncement', function () {
            var _this = this;

            var fields = this.get('urlFields');
            var filters = {}; // filtry i wartosci

            // ustawia tablice filtrow wraz z ich wartosciami
            fields.forEach(function (fieldName) {
                if (_this.get(fieldName)) filters[fieldName] = _this.get(fieldName);
            });
            return Object.keys(filters).length === 0;
        }),

        partnersContent: _ember['default'].A(),
        _initPartnersToFilter: function _initPartnersToFilter() {
            var _this2 = this;

            this.get('store').peekAll('DuonPartnerDictionaryEntry').forEach(function (item) {
                if (item.get('isHidden') !== true && (item.get('partnerType') == 2 || item.get('partnerType') == 3)) {
                    _this2.get('partnersContent').push({
                        value: item.get('uuid'),
                        label: '#' + item.get('idPartner') + ' ' + item.get('label')
                    });
                }
            });
        },

        // must be an array...
        partnersSelected: [],
        /*Ember.computed('partner', function () {
        const content = this.get('partnersToFilter'),
            selectedIds = this.get('partner');
        if (!content || !selectedIds)
            return [];
         return content.filter(item => selectedIds.includes(item.value));
        }),*/

        buildUrl: function buildUrl(endpoint, params) {
            return endpoint + '?' + (0, _emberNewUtilsEncodeObjectToUri['default'])(params);
        },

        reset: function reset() {
            var _this3 = this;

            var fields = this.get('urlFields');
            // ustawia tablice filtrow wraz z ich wartosciami
            fields.forEach(function (fieldName) {
                if (_this3.get(fieldName)) _this3.set(fieldName, null);
            });
        },

        actions: {

            updatePartner: function updatePartner(selection) {
                this.set('partnersSelected', selection);
            },

            dictionaryFilterBy: function dictionaryFilterBy(filterName, selection) {
                var val = selection ? selection.get('value') : '';
                this.set(filterName, val);
            },

            exportContracts: function exportContracts() {
                var _this4 = this;

                var fields = this.get('urlFields');
                var filters = {}; // filtry i wartosci

                // ustawia OBIEKT filtrow wraz z ich wartosciami
                fields.forEach(function (fieldName) {
                    if (_this4.get(fieldName)) filters[fieldName] = _this4.get(fieldName);
                });

                if (Object.keys(filters).length === 0) return false;

                var url = this.buildUrl('/individual/export/contracts', filters);
                window.open(_emberNewUtilsUtils['default'].generateSimpleUrl(url, '&', _emberNewConfigEnvironment['default'], this.get('TheUser'), this.get('cookies')));
            },

            exportContractsBo: function exportContractsBo() {
                var _this5 = this;

                var fields = this.get('urlFields');
                var filters = {}; // filtry i wartosci

                // ustawia OBIEKT filtrow wraz z ich wartosciami
                fields.forEach(function (fieldName) {
                    if (_this5.get(fieldName)) filters[fieldName] = _this5.get(fieldName);
                });

                if (Object.keys(filters).length === 0) return false;

                var url = this.buildUrl('/export/boDedicatedExport', filters);
                window.open(_emberNewUtilsUtils['default'].generateSimpleUrl(url, '&', _emberNewConfigEnvironment['default'], this.get('TheUser'), this.get('cookies')));
            }

        }

    });
});
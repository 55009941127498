define('ember-new/routes/individual-contract-price-lists/template/conditions/edit', ['exports', 'ember', 'ember-new/routes/authenticated', 'ember-new/mixins/individual-contract-price-lists/condition'], function (exports, _ember, _emberNewRoutesAuthenticated, _emberNewMixinsIndividualContractPriceListsCondition) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend(_emberNewMixinsIndividualContractPriceListsCondition['default'], {

        model: function model(params) {
            this.store.unloadAll('IndividualContractTemplateConditionViewModel');
            return this.store.findRecord('IndividualContractTemplateConditionViewModel', params.conditionUuid, { reload: true });
        },

        setupController: function setupController(controller, model) {
            var template = this.modelFor('individual-contract-price-lists.template.conditions').template;
            controller.set('model', model);
            controller.set('areasQuantity', template.get('tariffGroupAreasQuantity'));
        }

    });
});
define("ember-new/routes/tasks/add-reservation", ["exports", "ember-new/routes/authenticated", "ember-new/config/environment", "ember"], function (exports, _emberNewRoutesAuthenticated, _emberNewConfigEnvironment, _ember) {
	exports["default"] = _emberNewRoutesAuthenticated["default"].extend({

		ajax: _ember["default"].inject.service('ajax'),

		model: function model(params) {

			var _this = this;
			var promiseModel = new _ember["default"].RSVP.Promise(function (resolve, reject) {

				_this.store.unloadAll('userForDropdown');

				var promise = _this.get('ajax').ajaxGet(_emberNewConfigEnvironment["default"].apiUrl + '/ajax/amb_client/check_allow_add_reservation?cid=' + params.cid);

				promise.then(function (response) {
					_this.get('store').pushPayload({
						userForDropdown: response.users,
						ambBusinessClient: response.client
					});

					resolve(response);
				});
			}, "LoadModelOnTasksAddReservationRoute");

			return promiseModel;
		},
		afterModel: function afterModel(model, transition) {
			if (model.allowReserveEe !== true && model.allowReserveGas !== true) {
				alert('Nie możesz zarezerwować tego klienta, ma on już rezerwacje na oba media');
				this.transitionTo('tasks');
			}
		},
		actions: {
			didTransition: function didTransition() {
				this.controller.reset();
				return true;
			}
		}

	});
});
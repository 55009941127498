define('ember-new/transforms/nullable-boolean', ['exports', 'ember-data'], function (exports, _emberData) {

    // app/transforms/nullable-boolean.js
    exports['default'] = _emberData['default'].BooleanTransform.extend({
        deserialize: function deserialize(serialized) {
            if (serialized === null) {
                return null;
            }
            return this._super(serialized);
        },

        serialize: function serialize(deserialized) {
            if (deserialized === null) {
                return null;
            }
            return this._super(deserialized);
        }
    });
});
define('ember-new/models/individual-ee-contract', ['exports', 'ember-data', 'ember-new/models/abstract', 'ember-new/models/base-statusable-document-mixin', 'ember-new/mixins/models/individual-ee-gas-contract'], function (exports, _emberData, _emberNewModelsAbstract, _emberNewModelsBaseStatusableDocumentMixin, _emberNewMixinsModelsIndividualEeGasContract) {

	var attr = _emberData['default'].attr;
	var refOne = _emberData['default'].belongsTo;
	var refMany = _emberData['default'].hasMany;

	exports['default'] = _emberNewModelsAbstract['default'].extend(_emberNewModelsBaseStatusableDocumentMixin['default'], _emberNewMixinsModelsIndividualEeGasContract['default'], {

		ppm: refOne('individual-ppe'),

		secondContract: refOne('individual-gas-contract', { inverse: null }),
		// dfGasContract: refOne('individual-gas-contract', {inverse: null}),

		settlementPeriod: refOne('SettlementPeriodDictionaryEntry', { inverse: null }),

		complex: attr('boolean'),

		type: (function () {
			return 'ee';
		}).property()

	});
});
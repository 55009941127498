define('ember-new/utils/ajax/post', ['exports', 'ember'], function (exports, _ember) {

    /**
     * Method to submit a form that is not related with DataStore (DS.Model) RESTFul Model (for example for managing user profile)
     * @param string url
     * @param string formName
     * @param array formData
     * @returns {Ember.RSVP.Promise}
     */
    var ajaxPost = function ajaxPost(url, formName, formData, token) {
        var ajaxData = {};
        ajaxData[formName] = formData;
        var updatePromise = $.ajax({
            url: url,
            type: 'POST',
            dataType: 'json',
            data: ajaxData,
            headers: {
                "Authorization": "Bearer " + token
            }
        });
        var afterUpdatePromise = new _ember['default'].RSVP.Promise(function (resolve, reject) {

            updatePromise.then(function (response) {
                // returns server response JSON as a result of the promise
                resolve(response);
            }, function (jqXHR) {

                if (jqXHR.status == 500) {
                    //record.rollback();
                    errorHandlers.handleBackendException(jqXHR);
                    return;
                } else if (jqXHR.status == 400) {
                    // do nothing validation will be resolved later in .then() of promise
                    reject(jqXHR.responseJSON); // returns JSON with validation errors as a result of the promise
                } else if (jqXHR.status == 403) {
                        errorHandlers.handleBackend403(jqXHR, reject);
                    } else {
                        errorHandlers.handleUnknownAjaxError(jqXHR);
                    }
            });
        }, updatePromise._label + " postAjax");

        return afterUpdatePromise;
    };

    exports['default'] = ajaxPost;
});
/**
 * @deprecated
 * Nie używaj tego. Użyj services/ajax
 * Zostawione for backward compatibility
 * ~KJ
 */
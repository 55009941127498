define('ember-new/serializers/ee-acquisition-process', ['exports', 'ember-new/serializers/application', 'ember-new/mixins/base-statusable-document-serializer'], function (exports, _emberNewSerializersApplication, _emberNewMixinsBaseStatusableDocumentSerializer) {

	var AmbAcquisitionProcessSerializer = _emberNewSerializersApplication['default'].extend(_emberNewMixinsBaseStatusableDocumentSerializer['default'], {
		AmbAcquisitionProcessSerializerInit: (function () {
			this.addAttrsConfig({
				os: { serialize: 'id', deserialize: 'id' },
				osd: { serialize: 'id', deserialize: 'id' },
				mainTariffGroup: { serialize: 'id', deserialize: 'id' },
				comments: { serialize: 'ids', deserialize: 'ids' },
				salesman: { serialize: 'id', deserialize: 'id' },
				endTask: { serialize: 'id', deserialize: 'id' },
				client: { serialize: 'id', deserialize: 'id' }
			});
		}).on('init')
	});

	exports['default'] = AmbAcquisitionProcessSerializer;
});
define('ember-new/mixins/controllers/individual-contract-partners/form-fields', ['exports', 'ember', 'ember-new/config/environment'], function (exports, _ember, _emberNewConfigEnvironment) {
	var computed = _ember['default'].computed;
	var controller = _ember['default'].inject.controller;

	/**
  * Wspolne pola itd. dla formularza edycji/dodawani klienta...
  */
	exports['default'] = _ember['default'].Mixin.create({

		ctrl: controller('individual-contract-partners.partner-add-all'),

		foreigner: false,
		dateSigned: null,
		type: null,
		partnerName: null,
		branch: null,
		clientSatisfaction: null,

		// Klient:
		clientUuid: null,
		clientFirstName: null,
		clientLastName: null,
		clientPesel: null,
		clientPhone: null,
		clientPhone2: null,

		// Drugi Klient
		firstNameSecondClient: null,
		lastNameSecondClient: null,
		peselSecondClient: null,
		phoneSecondClient: null,
		phone2SecondClient: null,
		foreignerSecondClient: null,

		// GAZ / EE
		contractNo: null,
		tariffGroupUuid: null,
		gasContractNo: null,
		gasTariffUuid: null,

		// pomocne do mapowania zeby za gazdym razem dalej nie wypisywac wszystkich pol...
		//
		formFieldsForMappingStates: ['foreigner', 'clientPesel', 'partnerName', 'branch', 'clientSatisfaction', 'dateSigned', 'type', 'clientFirstName', 'clientLastName', 'clientPhone', 'clientPhone2', 'contractNo', 'tariffGroupUuid', 'eeLoyaltyDate', 'gasContractNo', 'gasTariffUuid', 'gasLoyaltyDate', 'phId', 'firstNameSecondClient', 'lastNameSecondClient', 'phoneSecondClient', 'phone2SecondClient', 'peselSecondClient', 'foreignerSecondClient'],

		typeContent: [{ label: "EE", value: "ee" }, { label: "Gaz", value: "gas" }, { label: "EE + Gaz", value: "dual" }],
		eeTariffGroups: [{ label: 'G11', value: '162e16be-beae-477b-81e7-f0c1301f3e92' }, { label: 'G12', value: '5aeff8ae-e429-49d0-9862-ed8578992939' }, { label: 'G12w', value: '5ed619da-aac9-4cdb-b8b6-87fea9ce3c9a' }],

		branchContent: (function () {
			return this.get('ctrl').get('salesUsers');
		}).property(),

		clientSatisfactionRange: [{ label: "1", value: 1 }, { label: "2", value: 2 }, { label: "3", value: 3 }, { label: "4", value: 4 }, { label: "5", value: 5 }]
	});
});
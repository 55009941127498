define('ember-new/models/base-statusable-document-mixin', ['exports', 'ember', 'ember-new/mixins/base-document', 'ember-data'], function (exports, _ember, _emberNewMixinsBaseDocument, _emberData) {

  var BaseStatusableDocument = _ember['default'].Mixin.create(_emberNewMixinsBaseDocument['default'], {
    status: _emberData['default'].belongsTo('setStatusInfo', { embedded: true })
  });
  /**
   * Mixin dla BaseStatusableDocument z symfony, extenduje z BaseDocumentMixin zatem w dzieciach wystarczy zaaplikować tylko ten
   * @author Kamil Jakubowski <k.jakubowski@kontaktenergia.pl>
   * @component CRM 2.0
   */

  exports['default'] = BaseStatusableDocument;
});
define("ember-new/mixins/acquisition/acquisition-comments-controller", ["exports", "ember", "ember-new/config/environment", "ember-new/mixins/comments/comments-controller"], function (exports, _ember, _emberNewConfigEnvironment, _emberNewMixinsCommentsCommentsController) {
    exports["default"] = _ember["default"].Mixin.create(_emberNewMixinsCommentsCommentsController["default"], {

        acquisitionProcess: null,
        acquisitionControllerName: 'null', // do wypelnienia w controlerze uzywajacym mixina

        isCc: (function () {
            return this.get('TheUser').model.mainRole === 'ROLE_CC_CONSULTANT';
        }).property(),

        addCommentApiUrl: (function () {
            return _emberNewConfigEnvironment["default"].apiUrl + '/rest/' + this.get('acquisitionControllerName') + '/' + this.get('acquisitionProcess').get('id') + '/comments';
        }).property('acquisitionProcess'),

        actions: {
            closeAction: function closeAction() {
                this.transitionToRoute('tasks');
            }
        }

    });
});
define("ember-new/models/task-list-item-view-model", ["exports", "ember-new/models/abstract", "ember-data", "ember"], function (exports, _emberNewModelsAbstract, _emberData, _ember) {
  exports["default"] = _emberNewModelsAbstract["default"].extend({
    uuid: _emberData["default"].attr('string'),

    acqUuid: _emberData["default"].attr('string'),
    acqId: _emberData["default"].attr('string'),
    acqIsFavouriteSalesman: _emberData["default"].attr('boolean'),
    acqIsFavouriteManager: _emberData["default"].attr('boolean'),
    acqMainTariffGroup: _emberData["default"].attr('string'),
    acqMainTariffGroupUuid: _emberData["default"].attr('string'),
    acqMainTariffGroupId: _emberData["default"].attr('string'),
    acqGasMainTariffGroup: _emberData["default"].attr('string'),
    acqGasMainTariffGroupUuid: _emberData["default"].attr('string'),
    acqGasMainTariffGroupId: _emberData["default"].attr('string'),
    acqStatus: _emberData["default"].attr('string'),
    acqStatusUuid: _emberData["default"].attr('string'),
    acqStatusId: _emberData["default"].attr('string'),
    acqStatusNumber: _emberData["default"].attr('number'),
    acqLoyaltyStatus: _emberData["default"].attr('number'),
    acqLoyaltyStatusText: _emberData["default"].attr('string'),
    acqLoyaltyDate: _emberData["default"].attr('string'),
    acqOsd: _emberData["default"].attr('string'),
    acqCurrentVendor: _emberData["default"].attr('string'),
    acqMM: _emberData["default"].attr('string'),
    acqMMValue: _emberData["default"].attr('number'),
    acqUnreadCommentsCount: _emberData["default"].attr('number'),
    acqHasEeContract: _emberData["default"].attr('boolean'),
    acqType: _emberData["default"].attr('string'),

    tskType: _emberData["default"].attr('string'),
    tskTime: _emberData["default"].attr('string'),
    tskSource: _emberData["default"].attr('string'),
    tskSourceUuid: _emberData["default"].attr('string'),
    tskSourceId: _emberData["default"].attr('string'),
    tskIsDone: _emberData["default"].attr('boolean'),
    tskIsResignation: _emberData["default"].attr('boolean'),
    tskForUserFullname: _emberData["default"].attr('string'),
    tskForUserLastName: _emberData["default"].attr('string'),
    tskForUserNick: _emberData["default"].attr('string'),
    tskForUserPhone: _emberData["default"].attr('string'),
    tskForUserRegion: _emberData["default"].attr('string'),
    tskCreatedByFullname: _emberData["default"].attr('string'),
    tskCreatedByPhone: _emberData["default"].attr('string'),
    tskCreatedByMainRole: _emberData["default"].attr('string'),
    tskDateCreated: _emberData["default"].attr('string'),
    tskMeetingAddress: _emberData["default"].attr('string'),

    tskCoachingFullname: _emberData["default"].attr('string'),

    clnUuid: _emberData["default"].attr('string'),
    clnId: _emberData["default"].attr('string'),
    clnCompanyName: _emberData["default"].attr('string'),
    clnFullCompanyName: _emberData["default"].attr('string'),
    clnNip: _emberData["default"].attr('string'),
    clnAddress: _emberData["default"].attr('string'),
    clnFullAddress: _emberData["default"].attr('string'),

    clnEeContactPersonFullName: _emberData["default"].attr('string'),
    clnEeContactPersonLastName: _emberData["default"].attr('string'),
    clnEeContactPersonPosition: _emberData["default"].attr('string'),
    clnEeContactPersonPhone: _emberData["default"].attr('string'),
    clnEeContactPersonEmail: _emberData["default"].attr('string'),
    clnGasContactPersonFullName: _emberData["default"].attr('string'),
    clnGasContactPersonLastName: _emberData["default"].attr('string'),
    clnGasContactPersonPosition: _emberData["default"].attr('string'),
    clnGasContactPersonPhone: _emberData["default"].attr('string'),
    clnGasContactPersonEmail: _emberData["default"].attr('string'),

    clnIsReserved: _emberData["default"].attr('boolean'),
    clnIsReservedEE: _emberData["default"].attr('boolean'),
    clnIsReservedGas: _emberData["default"].attr('boolean'),
    clnReservationFor: _emberData["default"].attr('string'),
    clnEeReservationFor: _emberData["default"].attr('string'),
    clnGasReservationFor: _emberData["default"].attr('string'),
    clnReservationExpireDate: _emberData["default"].attr('string'),
    clnEeReservationExpireDate: _emberData["default"].attr('string'),
    clnGasReservationExpireDate: _emberData["default"].attr('string'),

    verMeetingFileId: _emberData["default"].attr('string'),
    verMeetingResult: _emberData["default"].attr('string'),
    verMeetingResultUuid: _emberData["default"].attr('string'),
    verMeetingResultId: _emberData["default"].attr('string'),

    // isDoneOrder: function() {
    //     var now = moment();
    //     now.hour(0);
    //     now.minute(0);
    //     now.second(0);
    //     if (! this.get('tskIsDone') && moment(this.get('tskTime')) < now)
    //         return 0;
    //     else if (this.get('tskIsDone'))
    //         return 2;
    //     else
    //         return 1;
    // }.property('tskIsDone', 'tskTime'),

    isOwnSource: (function () {
      if (this.get('tskSourceId') === '542bc797e771ded34e8b459c') {
        return true;
      }
      return false;
    }).property('tskSourceId', 'tskSource'),

    isHotDeal: _emberData["default"].attr('boolean'),

    isAbleToMoveTaskByTime: _ember["default"].computed('tskTime', function () {
      var now = new Date();
      var taskDate = new Date(this.get('tskTime'));
      now.setHours(23, 59, 59);

      var timeDiff = Math.abs(now.getTime() - taskDate.getTime()),
          diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)),
          rightDiffDays = diffDays - 1;

      if (taskDate < now && rightDiffDays > 2) {
        // this.get('applicationAlerts').addActionMessage('danger', "Nie możesz rozliczyćzmienić daty zadania starszego niż dwa dni");
        return false;
      }

      return true;
    }),

    eeReservationType: _emberData["default"].attr('string'),
    gasReservationType: _emberData["default"].attr('string'),
    eeReservationChannel: _emberData["default"].attr('string'),
    gasReservationChannel: _emberData["default"].attr('string')

  });
});
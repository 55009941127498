define('ember-new/components/subpage-modal', ['exports', 'ember'], function (exports, _ember) {
    var on = _ember['default'].on;
    exports['default'] = _ember['default'].Component.extend({
        keyboard: _ember['default'].inject.service(),

        modalSize: 'modal-lg',
        subTitle: null,

        activateKeyboard: on('didInsertElement', function () {
            this.get('keyboard').activate(this);
        }),

        closeModal: on('keyUp:Escape', function () {
            this.get('targetObject').transitionToRoute(this.get('modalCloseRoute'));
        }),

        deactivateKeyboard: on('willDestroyElement', function () {
            this.get('keyboard').deactivate(this);
        })
    });
});
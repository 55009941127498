define('ember-new/mixins/controllers/individual-contract/base-add', ['exports', 'ember', 'ember-data', 'ember-new/mixins/controllers/helpers/form-processing-controller', 'ember-new/mixins/controllers/unsaved-data', 'ember-new/config/environment'], function (exports, _ember, _emberData, _emberNewMixinsControllersHelpersFormProcessingController, _emberNewMixinsControllersUnsavedData, _emberNewConfigEnvironment) {
    exports['default'] = _ember['default'].Mixin.create(_emberNewMixinsControllersHelpersFormProcessingController['default'], _emberNewMixinsControllersUnsavedData['default'], {

        applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),
        ajax: _ember['default'].inject.service('ajax'),

        customRequestData: {},

        eeRenouncements: null,
        gasRenouncements: null,
        foundEeRenouncement: false,
        foundGasRenouncement: false,
        chosenEeRenouncement: null,
        chosenGasRenouncement: null,
        eeRenouncementStatus: false,
        gasRenouncementStatus: false,

        eeRenouncementWarning: false,
        gasRenouncementWarning: false,

        fieldsNeedFeedback: ['client::firstName', 'client::lastName', 'client::phone', 'client::phone2', 'client::email', 'client::pesel', 'client::foreigner', 'client::identificationDocumentType', 'client::identificationDocumentNumber', 'client::baseAddress::street', 'client::baseAddress::streetNo', 'client::baseAddress::aptNo', 'client::baseAddress::city', 'client::baseAddress::postalCode', 'client::baseAddress::postalCity', 'client::baseAddress::district', 'client::additionalAddress::street', 'client::additionalAddress::streetNo', 'client::additionalAddress::aptNo', 'client::additionalAddress::city', 'client::additionalAddress::postalCode', 'client::additionalAddress::postalCity', 'client::additionalAddress::district', 'signingPlace'],

        isEe: false,
        isGas: false,

        initUnsavedData: (function () {
            this.removeAllLocks();
            this.addLock();
        }).on('init'),

        clear: function clear() {
            this.set('eeRenouncements', null);
            this.set('gasRenouncements', null);
            this.set('foundEeRenouncement', false);
            this.set('foundGasRenouncement', false);
            this.set('chosenEeRenouncement', null);
            this.set('chosenGasRenouncement', null);
            this.set('eeRenouncementStatus', null);
            this.set('gasRenouncementStatus', null);
            this.set('isSecondStep', false);
        },

        chosenEeRenouncementChanged: (function () {
            var contract = this.get('model');
            if (contract && contract.get('signingPlace.id') === '57cd4af8e771dead2262576a') {
                this.set('eeRenouncementStatus', '59a952b86876d26d4debb978');
            } else {
                this.set('eeRenouncementStatus', '59a952b4e7c7ffae2fbcb3dc');
            }
        }).observes('chosenEeRenouncement'),

        chosenGasRenouncementChanged: (function () {
            var contract = this.get('model');
            if (contract && contract.get('signingPlace.id') === '57cd4af8e771dead2262576a') {
                this.set('gasRenouncementStatus', '59a952b86876d26d4debb978');
            } else {
                this.set('gasRenouncementStatus', '59a952b4e7c7ffae2fbcb3dc');
            }
        }).observes('chosenGasRenouncement'),

        eeRenouncementStatusChanged: (function () {
            var contract = this.get('model');
            if (contract && contract.get('signingPlace.id') === '57cd4af8e771dead2262576a' && this.get('eeRenouncementStatus') == '59a952b4e7c7ffae2fbcb3dc') {
                this.set('eeRenouncementWarning', true);
            } else {
                this.set('eeRenouncementWarning', false);
            }
        }).observes('eeRenouncementStatus'),

        gasRenouncementStatusChanged: (function () {
            var contract = this.get('model');
            if (contract && contract.get('signingPlace.id') === '57cd4af8e771dead2262576a' && this.get('gasRenouncementStatus') == '59a952b4e7c7ffae2fbcb3dc') {
                this.set('gasRenouncementWarning', true);
            } else {
                this.set('gasRenouncementWarning', false);
            }
        }).observes('gasRenouncementStatus'),

        actions: {

            validateFormAndSearchRenouncements: function validateFormAndSearchRenouncements() {
                if (this.get('isSecondStep') !== false) {
                    return;
                }

                if (!this.beforeSubmit()) {
                    return;
                }

                this.set('eeRenouncements', null);
                this.set('gasRenouncements', null);
                this.set('foundEeRenouncement', false);
                this.set('foundGasRenouncement', false);
                this.set('chosenEeRenouncement', null);
                this.set('chosenGasRenouncement', null);
                this.set('eeRenouncementStatus', null);
                this.set('gasRenouncementStatus', null);

                var controller = this;
                var contract = this.get('model');
                var contractRestAdapter = this.store.adapterFor(contract._internalModel.modelName);
                var serializedData = contractRestAdapter.serializeCustom(contract, this.get('fieldsNeedFeedback'), 'individualContract');

                serializedData.forEe = controller.get('isEe');
                serializedData.forGas = controller.get('isGas');

                var promise = this.get('ajax').ajaxPost(_emberNewConfigEnvironment['default'].apiUrl + '/api/individual-contract/validate_and_search_renouncements', serializedData);

                promise.then(function (response) {

                    var eeIds = response.eeIds;
                    var gasIds = response.gasIds;
                    delete response.eeIds;
                    delete response.gasIds;

                    if (response.renouncement !== undefined) {
                        controller.store.pushPayload(response);
                    }

                    var renouncements = controller.store.peekAll('renouncement');

                    if (true === controller.get('isEe')) {
                        var eeRenouncements = renouncements.filter(function (item, index, enumerable) {
                            return item !== undefined && item.get('id') !== undefined && eeIds.indexOf(item.get('id')) > -1;
                        });
                        controller.set('eeRenouncements', eeRenouncements);
                        controller.set('foundEeRenouncement', eeIds.length > 0);
                    }

                    if (true === controller.get('isGas')) {
                        var gasRenouncements = renouncements.filter(function (item, index, enumerable) {
                            return item !== undefined && item.get('id') !== undefined && gasIds.indexOf(item.get('id')) > -1;
                        });
                        controller.set('gasRenouncements', gasRenouncements);
                        controller.set('foundGasRenouncement', gasIds.length > 0);
                    }

                    controller.handleSuccess();
                    controller.set('submitResult', "Dane są poprawne! Przejrzyj znalezione odstąpienia i zatwierdź umowę.");

                    if (controller.get('isSecondStep', false) === false) {
                        controller.set('isSecondStep', true);
                    }
                }, function (response) {
                    controller.handleNotValid(response);
                    controller.get('applicationAlerts').addActionMessage('warning', 'Formularz zawiera błędy.');
                });
            },

            confirmContract: function confirmContract() {

                this.removeLock();

                if (this.get('isSecondStep') !== true) {
                    return;
                }

                if (!this.beforeSubmit()) {
                    return;
                }

                var customRequestData = this.get('customRequestData');

                customRequestData.clientId = this.get('model').get('client').get('id');

                if (this.get('isEe')) {
                    customRequestData.assignEeRenouncement = this.get('chosenEeRenouncement') != null;
                    customRequestData.eeRenouncementId = this.get('chosenEeRenouncement');
                    customRequestData.eeRenouncementStatus = this.get('eeRenouncementStatus');
                }

                if (this.get('isGas')) {
                    customRequestData.assignGasRenouncement = this.get('chosenGasRenouncement') != null;
                    customRequestData.gasRenouncementId = this.get('chosenGasRenouncement');
                    customRequestData.gasRenouncementStatus = this.get('gasRenouncementStatus');
                }

                console.log('crd', customRequestData);

                var promise = this.get('model').customPost(null, this.get('fieldsNeedFeedback'), [], 'individualContract', customRequestData);

                var controller = this;

                promise.then(function (response) {
                    var renouncementStatement = response.renouncementStatement;
                    if (renouncementStatement && renouncementStatement.isVisible === true) {
                        alert(renouncementStatement.message);
                    }
                    controller.handleSuccess(response);
                    controller.get('applicationAlerts').addActionMessage('success', 'Dodano nową umowę pomyślnie!');

                    controller.afterSuccess.call(controller, response);
                }, function (response) {

                    //controller.handlePersonsErrors(response);

                    controller.handleNotValid(response);
                    controller.get('applicationAlerts').addActionMessage('warning', 'Formularz zawiera błędy.');
                });
            },

            backToStep1: function backToStep1() {
                this.set('isSecondStep', false);
            },

            fillTestData: function fillTestData() {
                var model = this.get('model');

                model.set('client.firstName', 'Jan');
                model.set('client.lastName', 'Kowalski');
                model.set('client.phone', '555666777');
                model.set('client.email', 'jan@kowalski.cooom');
                model.set('client.identificationDocumentType', this.store.peekRecord('identification-document-type', '5799e38ecce826f6646d1ab9'));
                model.set('client.identificationDocumentNumber', 'AMF100424');

                model.set('client.baseAddress.street', 'Ulica');
                model.set('client.baseAddress.streetNo', '1');
                model.set('client.baseAddress.aptNo', '2');
                model.set('client.baseAddress.postalCode', '00-000');
                model.set('client.baseAddress.city', 'Miasto');
                model.set('client.baseAddress.district', this.store.peekRecord('district-dictionary-entry', '542e98d4e771de5d2b8b4567'));
            },

            copyBaseAddress: function copyBaseAddress() {
                var model = this.get('model');
                model.set('client.additionalAddress.street', model.get('client.baseAddress.street'));
                model.set('client.additionalAddress.streetNo', model.get('client.baseAddress.streetNo'));
                model.set('client.additionalAddress.aptNo', model.get('client.baseAddress.aptNo'));
                model.set('client.additionalAddress.postalCode', model.get('client.baseAddress.postalCode'));
                model.set('client.additionalAddress.postalCity', model.get('client.baseAddress.postalCity'));
                model.set('client.additionalAddress.city', model.get('client.baseAddress.city'));
                model.set('client.additionalAddress.district', this.store.peekRecord('district-dictionary-entry', model.get('client.baseAddress.district.id')));
            },

            copyBaseAddressToPpe: function copyBaseAddressToPpe() {
                var model = this.get('model');
                model.set('ppmAddress.street', model.get('client.baseAddress.street'));
                model.set('ppmAddress.streetNo', model.get('client.baseAddress.streetNo'));
                model.set('ppmAddress.aptNo', model.get('client.baseAddress.aptNo'));
                model.set('ppmAddress.postalCode', model.get('client.baseAddress.postalCode'));
                model.set('ppmAddress.postalCity', model.get('client.baseAddress.postalCity'));
                model.set('ppmAddress.city', model.get('client.baseAddress.city'));
                model.set('ppmAddress.district', this.store.peekRecord('district-dictionary-entry', model.get('client.baseAddress.district.id')));
            }
        }
    });
});
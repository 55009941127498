define('ember-new/components/d-field-collection-of-texts', ['exports', 'ember', 'ember-new/mixins/d-form/d-field', 'ember-new/components/d-form'], function (exports, _ember, _emberNewMixinsDFormDField, _emberNewComponentsDForm) {
	exports['default'] = _ember['default'].Component.extend(_emberNewMixinsDFormDField['default'], {

		dFieldInitialize: (function () {
			if (!this.get('value')) {
				this.set('value', _ember['default'].A());
			}

			this._super();

			if (this.get('form') instanceof _emberNewComponentsDForm['default'] && this.get('key') !== undefined) {
				this.get('form').registerTransformer(this.get('key'), this.transformer, this.reverseTransformer);
			}
		}).on('init'),

		valueValidator: _ember['default'].observer('value', function () {
			var value = this.get('value');

			if (!(value instanceof Array)) {
				throw new _ember['default'].Error("Value of the d-field-collection-of-texts must be Ember.NativeArray");
			}
		}),

		transformer: function transformer(value) {
			var transformedValue = _ember['default'].A();

			if (value instanceof Array && value.length > 0) {
				value.forEach(function (elem) {
					if (elem instanceof String) {
						transformedValue.pushObject(_ember['default'].Object.create({ value: elem }));
					} else {
						throw new _ember['default'].Error("Element of value (collection) given to transform, has to be string. Field key: " + this.get('key'));
					}
				});
			}

			return transformedValue;
		},

		/**
   * transforms from Array/Native({value: xxx}, {value: yyy}) array to Array(xxx,yyy)
   * @param value
   * @returns {[]|*}
   */
		reverseTransformer: function reverseTransformer(value) {
			var reverseTransformedValue = [];

			if (value instanceof Array) {
				value.forEach(function (elem) {
					if (elem instanceof Object && elem.value !== undefined) {
						reverseTransformedValue.push(elem.value);
					} else {
						throw new _ember['default'].Error("Element of value (collection) given to reverse-transform, has to be object with value property. Field key: " + this.get('key'));
					}
				});

				return reverseTransformedValue;
			}

			return value;
		},

		actions: {
			addAnother: function addAnother() {
				this.get('value').pushObject(_ember['default'].Object.create({ value: null }));
			},
			removeElement: function removeElement(element) {
				this.get('value').removeObject(element);
			}
		},

		clearValue: function clearValue() {
			this.set('value', _ember['default'].A());
		}
	});
});
define('ember-new/controllers/individual-contract/show-ee/process', ['exports', 'ember-new/config/environment', 'ember', 'ember-new/mixins/individual-contract/edit-status', 'ember-new/mixins/contract/contract-comments-controller', 'ember-new/mixins/comments/comments-controller'], function (exports, _emberNewConfigEnvironment, _ember, _emberNewMixinsIndividualContractEditStatus, _emberNewMixinsContractContractCommentsController, _emberNewMixinsCommentsCommentsController) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsIndividualContractEditStatus['default'], _emberNewMixinsContractContractCommentsController['default'], _emberNewMixinsCommentsCommentsController['default'], {

        box: null,

        name: 'individual-contract',
        contractControllerName: 'individualeecontracts',

        // w ten sposób widok się nie odświerzy po edycji -- tworzymy nowy d-form w frontend/ember_new/app/components/d-form/individual-contract/process
        customerServiceSimpleUrl: (function () {
            return _emberNewConfigEnvironment['default'].apiUrl + '/rest/individualeecontracts/' + this.get('contract.id') + '/customer/service';
        }).property(),

        verificationCallSimpleUrl: (function () {
            return _emberNewConfigEnvironment['default'].apiUrl + '/rest/individualeecontracts/' + this.get('contract.id') + '/verification/call';
        }).property(),

        verificationStatusSimpleUrl: (function () {
            return _emberNewConfigEnvironment['default'].apiUrl + '/rest/individualeecontracts/' + this.get('contract.id') + '/verification/status';
        }).property(),

        pikaIncompletesSimpleUrl: (function () {
            return _emberNewConfigEnvironment['default'].apiUrl + '/rest/individualeecontracts/' + this.get('contract.id') + '/incompletes';
        }).property(),

        modelForSubmitStatus: (function () {
            return this.get('contract');
        }).property('contract'),

        addCommentApiUrl: (function () {
            return _emberNewConfigEnvironment['default'].apiUrl + '/rest/' + this.get('contractControllerName') + '/' + this.get('contract').get('id') + '/comments';
        }).property('contract'),

        validateData: function validateData() {
            this.clearActionMessages();

            var comment = this.get('comment');
            if (comment == null || comment.length == 0) {
                this.addActionMessage('danger', "Podaj treść komentarza!");
                return false;
            }

            return true;
        },

        ageWarning: (function () {
            return this.get('contract.client.age') >= 70 ? true : false;
        }).property('contract.client.age'),

        prepareDataToAddComment: function prepareDataToAddComment() {
            var data = {
                comment: {
                    content: this.get('comment'),
                    commentRecepients: this.get('commentVisibility')
                }
            };

            return data;
        },

        isEditable: (function () {
            var isAnonymized = this.get('contract.isAnonymized');
            return !isAnonymized;
        }).property('contract'),

        isAllowedToComment: (function () {
            var isAnonymized = this.get('contract.isAnonymized');
            return !this.get('TheUser').isMainRoleIn(['ROLE_GDPR_SPECIALIST']) && !isAnonymized;
        }).property()
    });
});
define('ember-new/components/table-view-mode', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        defaultFromLocalStorage: true,
        viewMode: 'normal',
        _viewMode: _ember['default'].computed(function () {}),
        didInsertElement: function didInsertElement() {
            var defaultVM = this.get('viewMode');
            if (this.get('defaultFromLocalStorage')) {
                defaultVM = localStorage.getItem('tableViewModeDefault') ? localStorage.getItem('tableViewModeDefault') : 'normal'; // domyslny tryb wyswietlania tasks-list
            }
            this.set('viewMode', defaultVM);
        },

        /**
         *
         */
        tableViewModeObserver: _ember['default'].observer('viewMode', function () {
            var vm = this.get('viewMode');
            localStorage.setItem('tableViewModeDefault', vm);
        }),

        viewModeClass: _ember['default'].computed('viewMode', function () {
            // table-responsive, table-mobile-cards, table-mobile-horizontal-scroll
            var vm = this.get('viewMode');

            if (vm === 'cards') {
                return 'table-mobile-cards';
            } else if (vm === 'scroll') {
                return 'table-mobile-horizontal-scroll';
            } else {
                return 'table-responsive';
            }
        })
    });
});
define("ember-new/mixins/controllers/simple-ajax-controller", ["exports", "ember", "ember-new/config/environment"], function (exports, _ember, _emberNewConfigEnvironment) {
    exports["default"] = _ember["default"].Mixin.create({

        commandName: "",
        commandUrl: '', ///rest/deleteeereservationcommands
        command: function command() {},
        successInfo: 'Operacja zakończona poprawnie',
        errorInfo: 'Operacja zakończona niepoprawnie',

        afterSuccessfulCommand: null,

        ajax: _ember["default"].inject.service('ajax'),
        applicationAlerts: _ember["default"].inject.service('application-alerts-manager'),

        actionInProgress: false,

        actions: {
            submit: function submit() {

                if (this.get('actionInProgress')) {
                    return;
                }

                this.set('actionInProgress', true);

                var _this = this;
                var promise = this.get('ajax').ajaxPost(_emberNewConfigEnvironment["default"].apiUrl + _this.commandUrl, _this.command());

                promise.then(function (response) {
                    _this.get('applicationAlerts').addActionMessage('success', _this.successInfo);
                    _this.set('actionInProgress', false);
                    if (typeof _this.afterSuccessfulCommand == 'function') {
                        _this.afterSuccessfulCommand.call(_this, response);
                    }
                }, function (response) {
                    _this.get('applicationAlerts').addActionMessage('warning', _this.errorInfo);
                    _this.set('actionInProgress', false);
                });
            }
        }

    });
});
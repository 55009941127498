define('ember-new/models/status-definition', ['exports', 'ember-new/models/abstract', 'ember-data'], function (exports, _emberNewModelsAbstract, _emberData) {

  var StatusDefinition = _emberNewModelsAbstract['default'].extend({
    uuid: _emberData['default'].attr('string'),
    name: _emberData['default'].attr('string'),
    number: _emberData['default'].attr('number'),
    numberAndName: _emberData['default'].attr('string'),
    category: _emberData['default'].attr('string') // discriminator field
  });
  /**
   * Model dla słowników-statusów (dokument StatusDefinition)
   *
   * @author Kamil Jakubowski <k.jakubowski@kontaktenergia.pl>
   * @component CRM 2.0
   */

  exports['default'] = StatusDefinition;
});
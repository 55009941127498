define('ember-new/controllers/individual-contract-price-lists/template/conditions/delete', ['exports', 'ember', 'ember-new/config/environment'], function (exports, _ember, _emberNewConfigEnvironment) {
    exports['default'] = _ember['default'].Controller.extend({
        ajax: _ember['default'].inject.service('ajax'),
        applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),

        actions: {
            'delete': function _delete() {
                var _this = this;

                var serializedData = {
                    DeleteIndividualProductTemplateConditionCommand: {
                        conditionUuid: this.get('model.conditionUuid')
                    }
                };

                var ctrl = this;

                this.get('ajax').ajaxPost(_emberNewConfigEnvironment['default'].apiUrl + '/rest/deleteindividualproducttemplateconditioncommands', serializedData).then(function () {
                    var route = _this.container.lookup('route:individual-contract-price-lists.template.conditions');
                    route.refresh();
                    _this.get('applicationAlerts').addActionMessage('success', "Warunek cenowy został usunięty");
                    _this.transitionToRoute('individual-contract-price-lists.template.conditions');
                }, function (response) {
                    if (response.errors && Array.isArray(response.errors)) {
                        response.errors.forEach(function (item) {
                            ctrl.get('applicationAlerts').addActionMessage('warning', item.message);
                        });
                    } else {
                        _this.get('applicationAlerts').addActionMessage('warning', "Wystąpił błąd, nie udało się usunąć warunku");
                    }
                });
            }
        }

    });
});
define('ember-new/components/d-form/renouncement/base-address', ['exports', 'ember', 'ember-new/components/d-form', 'ember-new/templates/components/d-form', 'ember-new/config/environment'], function (exports, _ember, _emberNewComponentsDForm, _emberNewTemplatesComponentsDForm, _emberNewConfigEnvironment) {
    exports['default'] = _emberNewComponentsDForm['default'].extend({
        layout: _emberNewTemplatesComponentsDForm['default'],

        renouncementId: null,

        submitRestAsSimple: true,
        submitModelNamespace: 'address',
        submitRestAsSimpleMethod: 'PATCH',

        submitSimpleUrl: (function () {
            return _emberNewConfigEnvironment['default'].apiUrl + '/rest/renouncements/' + this.get('renouncementId') + '/client/info/base/address';
        }).property()

    });
});
define('ember-new/mixins/individual-contract/edit-status', ['exports', 'ember', 'ember-new/config/environment', 'ember-new/mixins/controllers/helpers/form-processing-controller', 'ember-new/components/box-for-form'], function (exports, _ember, _emberNewConfigEnvironment, _emberNewMixinsControllersHelpersFormProcessingController, _emberNewComponentsBoxForForm) {
    exports['default'] = _ember['default'].Mixin.create(_emberNewMixinsControllersHelpersFormProcessingController['default'], {
        ajax: _ember['default'].inject.service('ajax'),
        applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),

        statusesToSelect: [{ value: 1, label: "Kompletna" }, { value: 2, label: "Niekompletna" }, { value: 3, label: "Anulowana" }],

        clientSatisfactionRange: [{ label: "1", value: 1 }, { label: "2", value: 2 }, { label: "3", value: 3 }, { label: "4", value: 4 }, { label: "5", value: 5 }],

        redirectAfterSubmit: null,

        modelForSubmitStatus: (function () {
            return this.get('model');
        }).property('model'),

        clear: function clear() {
            this.set('cerrors', null);
        },

        actions: {
            submitStatus: function submitStatus() {

                if (!this.beforeSubmit()) {
                    return;
                }

                this.clear();

                var model = this.get('modelForSubmitStatus');
                var modelName = model._internalModel.modelName;
                var type = null;
                var _this = this;

                if (modelName == 'individual-ee-contract') {
                    type = 'ee';
                } else {
                    type = 'gas';
                }

                var data = { individualContract: { status: this.get('status') ? this.get('status') : null } };
                var ajaxPromise = this.get('ajax').ajax(_emberNewConfigEnvironment['default'].apiUrl + '/rest/individual' + type + 'contracts/' + model.get('id') + '/status', data, 'PATCH');

                ajaxPromise.then(function (response) {
                    _this.get('applicationAlerts').addActionMessage('success', "Status został zmieniony");

                    _this.handleSuccess(response);
                    _this.store.pushPayload(response);
                    if (_this.get('redirectAfterSubmit')) {
                        _this.transitionToRoute(_this.get('redirectAfterSubmit'));
                    } else {
                        setTimeout(function () {
                            if (_this.get('box') instanceof _emberNewComponentsBoxForForm['default']) {
                                _this.get('box').set('editState', false);
                            }
                        }, 500);
                    }
                }, function (response) {
                    _this.set('isSubmitting', false);
                    _this.set('cerrors', [response.message]);
                });
            }
        }

    });
});
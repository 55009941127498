define('ember-new/mixins/action-messages', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Mixin.create({
        actionMessages: null,
        actionMessagesAutoclose: false,
        actionMessagesAutocloseTime: 20000,

        initActionMessages: (function () {
            this.set('actionMessages', _ember['default'].ArrayProxy.create({
                content: _ember['default'].A()
            }));
        }).on('init'),

        addActionMessage: function addActionMessage(type, msg, autoclose) {
            var actionMessages = this.get('actionMessages');
            var index = actionMessages.get('content').length;
            var obj = {
                // todo: przerowic na obiekt ember.. ~lyczos
                type: type,
                message: msg,
                index: index
            };
            actionMessages.pushObject(obj);

            if (this.get('actionMessagesAutoclose') === true || autoclose !== undefined && autoclose === true) {
                var _this = this;
                setTimeout(function () {
                    actionMessages.removeObject(obj);
                }, this.get('actionMessagesAutocloseTime'));
            }
        },

        closeActionMessage: function closeActionMessage(indexArg) {
            this.get('actionMessages').removeAt(indexArg)
            // dodane w celu reindeksacji ('msg' nie jest to obiekt 'emberowy',
            // a nie chcialem na razie przerabiac bo jest uzywane w wielu miejscach)
            // ~lyczos
            .forEach(function (msg, index) {
                return _ember['default'].set(msg, 'index', index);
            });
        },

        clearActionMessages: function clearActionMessages() {
            this.set('actionMessages', _ember['default'].ArrayProxy.create({
                content: _ember['default'].A()
            }));
        },

        actions: {
            closeActionMessage: function closeActionMessage(index) {
                this.closeActionMessage(index);
            }
        }
    });
});
define('ember-new/components/d-form/individual-contract/process/process', ['exports', 'ember', 'ember-new/components/d-form', 'ember-new/templates/components/d-form', 'ember-new/config/environment'], function (exports, _ember, _emberNewComponentsDForm, _emberNewTemplatesComponentsDForm, _emberNewConfigEnvironment) {
    exports['default'] = _emberNewComponentsDForm['default'].extend({
        layout: _emberNewTemplatesComponentsDForm['default'],

        contractId: null,
        type: null,

        submitRestAsSimple: true,
        submitModelNamespace: 'individualContract',
        submitRestAsSimpleMethod: 'PATCH',

        submitSimpleUrl: (function () {
            return _emberNewConfigEnvironment['default'].apiUrl + '/rest/individual' + this.get('type') + 'contracts/' + this.get('contractId') + '/customer/service';
        }).property(),

        afterSubmit: function afterSubmit(response) {
            this.get('store').pushPayload({ 'individualEeContract': response['individualEeContract'] });
            this.get('store').pushPayload({ 'individualGasContract': response['individualGasContract'] });
        }

    });
});
define('ember-new/mixins/individual-contract/multiple-contract-changes', ['exports', 'ember', 'ember-new/utils/validators', 'ember-new/config/environment'], function (exports, _ember, _emberNewUtilsValidators, _emberNewConfigEnvironment) {
    function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

    var computed = _ember['default'].computed;
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Mixin.create({
        ajax: service(),
        applicationAlerts: service('application-alerts-manager'),

        isLoadingCheck: false,
        isLoadingSet: false,
        checkedObjectName: null,
        checkResult: null,
        errors: _ember['default'].A(),

        inputDataArray: [], // array from textarea-data-extractor compoment..

        // numery umow otrzymane z komponentu
        // rozszerzone o walidacje  ich poprawnosci
        parsedData: computed('inputDataArray', function () {
            var inputData = this.get('inputDataArray');
            // remove duplicates....
            var uniq = new Set(inputData);
            return [].concat(_toConsumableArray(uniq)).map(function (item) {
                return {
                    number: item,
                    isValidEE: _emberNewUtilsValidators['default'].bothContractNumbersEe(item),
                    isValidGas: _emberNewUtilsValidators['default'].bothContractNumbersGas(item)
                };
            });
        }),
        validData: computed('parsedData', function () {
            return this.get('parsedData').filter(function (item) {
                return item.isValidEE || item.isValidGas;
            });
        }),
        validDataCount: computed('validData', function () {
            return this.get('validData').length;
        }),

        // zlicza duplikaty
        duplicatesCount: computed('parsedData', function () {
            return this.get('inputDataArray').length - this.get('parsedData').length;
        }),

        validContractsToSet: computed('checkResult', function () {
            return this.get('checkResult').filter(function (item) {
                return item['isValidToHaveActionMade'] === true;
            });
        }),
        invalidContractsToSet: computed('checkResult', function () {
            return this.get('checkResult').filter(function (item) {
                return item['isValidToHaveActionMade'] === false;
            });
        }),
        // tablcia z UUIDs kontraktow ktore przyszly z backendu z check
        // i beda wyslane na setDates.
        contractsToSetUuids: computed('validContractsToSet', function () {
            return this.get('validContractsToSet').mapBy('contractUuid');
        }),
        validContractsToSetCount: computed('validContractsToSet', function () {
            return this.get('validContractsToSet').length;
        }),
        checkResultCount: computed('checkResult', function () {
            return this.get('checkResult').length;
        }),

        isDisabledSetDatesButton: computed('validContractsToSetCount', function () {
            return this.get('validContractsToSetCount') === 0;
        }),

        handleCheckResponse: function handleCheckResponse(res) {
            var modelName = Object.keys(res)[0];
            this.set('checkedObjectName', modelName);
            this.set('checkResult', res[modelName]);
            this.set('isLoadingCheck', false);
        },
        handleError: function handleError(err) {
            this.set('isLoadingCheck', false);
            this.set('isLoadingSet', false);
            var error = {};
            if (err.status) {
                // obsluga bledow typu xhr
                error.code = err.status;
                error.message = err.statusText;
            } else {
                // obsluga bledow z api
                error = err;
            }
            this.get('errors').pushObject(err);
        },
        clearErrors: function clearErrors() {
            this.set('errors', []);
        },

        handleSetResponse: function handleSetResponse(res) {
            this.set('isLoadingSet', false);
            if (res.status === 'ok') {
                this.get('applicationAlerts').addActionMessage('success', 'Zmieniono dane!');
                var route = this.container.lookup("route:individual-contract");
                route.refresh();
                this.transitionToRoute('individual-contract');
                this.clear();
            }
        },

        clear: function clear() {
            this.clearErrors();
            this.set('checkResult', null);
            this.set('inputDataArray', []);
        },
        // url:

        actions: {
            getParsedData: function getParsedData(data) {
                this.clear();
                this.set('inputDataArray', data);
            }
        }
    });
});
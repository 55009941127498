define('ember-new/models/contract-report-item-view-model', ['exports', 'ember-data', 'ember-new/models/abstract'], function (exports, _emberData, _emberNewModelsAbstract) {
    exports['default'] = _emberNewModelsAbstract['default'].extend({
        'class': _emberData['default'].attr('string'),
        mainData: _emberData['default'].attr('string'),
        status: _emberData['default'].attr('string'),
        number: _emberData['default'].attr('number'),
        name: _emberData['default'].attr('string'),
        date1: _emberData['default'].attr(),
        date2: _emberData['default'].attr(),
        date3: _emberData['default'].attr()
    });
});
define('ember-new/controllers/b2b-contract/salesman/show-ee/volumes', ['exports', 'ember', 'ember-new/mixins/b2b-contract/controllers/volumes-ee'], function (exports, _ember, _emberNewMixinsB2bContractControllersVolumesEe) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsB2bContractControllersVolumesEe['default'], {

        fillSalesmanForm: function fillSalesmanForm(form) {
            form.set('model.contractUuid', this.get('model.id'));
            form.set('model.numberOfMonthsBySalesman', this.get('model.numberOfMonthsBySalesman'));
            form.set('model.consumptionBySalesman', this.get('model.consumptionBySalesman'));
            form.set('model.overheadBySalesman', this.get('model.overheadBySalesman'));
        },

        afterSalesmanCommand: function afterSalesmanCommand() {
            var ctrl = this;
            var route = this.container.lookup('route:b2b-contract.salesman.show-ee.volumes');
            route.refresh();
            setTimeout(function () {
                ctrl.transitionToRoute('b2b-contract.salesman.show-ee.volumes');
            }, 500);
        }

    });
});
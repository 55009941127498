define("ember-new/templates/reports/sales-by-salesman", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 24
            },
            "end": {
              "line": 17,
              "column": 24
            }
          },
          "moduleName": "ember-new/templates/reports/sales-by-salesman.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "d-field-select", [], ["value", ["subexpr", "@mut", [["get", "businessMonth", ["loc", [null, [12, 42], [12, 55]]]]], [], []], "content", ["subexpr", "@mut", [["get", "businessMonths", ["loc", [null, [13, 44], [13, 58]]]]], [], []], "optionLabelPath", "name", "optionValuePath", "uuid", "prompt", " -- wybierz -- "], ["loc", [null, [11, 28], [16, 62]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 22,
              "column": 24
            },
            "end": {
              "line": 29,
              "column": 24
            }
          },
          "moduleName": "ember-new/templates/reports/sales-by-salesman.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "d-field-select", [], ["value", ["subexpr", "@mut", [["get", "aboveMargin", ["loc", [null, [24, 42], [24, 53]]]]], [], []], "content", ["subexpr", "@mut", [["get", "aboveMargins", ["loc", [null, [25, 44], [25, 56]]]]], [], []], "optionLabelPath", "label", "optionValuePath", "value", "prompt", " -- wybierz -- "], ["loc", [null, [23, 28], [28, 62]]]]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 34,
              "column": 24
            },
            "end": {
              "line": 41,
              "column": 24
            }
          },
          "moduleName": "ember-new/templates/reports/sales-by-salesman.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "d-field-select", [], ["value", ["subexpr", "@mut", [["get", "active", ["loc", [null, [36, 42], [36, 48]]]]], [], []], "content", ["subexpr", "@mut", [["get", "actives", ["loc", [null, [37, 44], [37, 51]]]]], [], []], "optionLabelPath", "label", "optionValuePath", "value", "prompt", "Wszyscy"], ["loc", [null, [35, 28], [40, 54]]]]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 46,
              "column": 24
            },
            "end": {
              "line": 53,
              "column": 24
            }
          },
          "moduleName": "ember-new/templates/reports/sales-by-salesman.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "d-field-select", [], ["value", ["subexpr", "@mut", [["get", "contractType", ["loc", [null, [48, 42], [48, 54]]]]], [], []], "content", ["subexpr", "@mut", [["get", "contractTypes", ["loc", [null, [49, 44], [49, 57]]]]], [], []], "optionLabelPath", "label", "optionValuePath", "value", "prompt", "Wszystkie"], ["loc", [null, [47, 28], [52, 56]]]]],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 58,
              "column": 24
            },
            "end": {
              "line": 65,
              "column": 24
            }
          },
          "moduleName": "ember-new/templates/reports/sales-by-salesman.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "d-field-select", [], ["value", ["subexpr", "@mut", [["get", "regions", ["loc", [null, [60, 38], [60, 45]]]]], [], []], "content", ["subexpr", "@mut", [["get", "regionsContent", ["loc", [null, [61, 40], [61, 54]]]]], [], []], "optionLabelPath", "name", "optionValuePath", "uuid", "prompt", " -- wybierz -- "], ["loc", [null, [59, 28], [64, 58]]]]],
        locals: [],
        templates: []
      };
    })();
    var child5 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 97,
                "column": 24
              },
              "end": {
                "line": 108,
                "column": 24
              }
            },
            "moduleName": "ember-new/templates/reports/sales-by-salesman.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            dom.setAttribute(el1, "class", "overall");
            var el2 = dom.createTextNode("\n                                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createElement("strong");
            var el4 = dom.createTextNode("RAZEM");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "class", "text-center vertical_align_middle");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "class", "text-center vertical_align_middle");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "class", "text-center vertical_align_middle");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "class", "text-center vertical_align_middle");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "class", "text-center vertical_align_middle");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "class", "text-center vertical_align_middle");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "class", "text-center vertical_align_middle");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var morphs = new Array(7);
            morphs[0] = dom.createMorphAt(dom.childAt(element2, [3]), 0, 0);
            morphs[1] = dom.createMorphAt(dom.childAt(element2, [5]), 0, 0);
            morphs[2] = dom.createMorphAt(dom.childAt(element2, [7]), 0, 0);
            morphs[3] = dom.createMorphAt(dom.childAt(element2, [9]), 0, 0);
            morphs[4] = dom.createMorphAt(dom.childAt(element2, [11]), 0, 0);
            morphs[5] = dom.createMorphAt(dom.childAt(element2, [13]), 0, 0);
            morphs[6] = dom.createMorphAt(dom.childAt(element2, [15]), 0, 0);
            return morphs;
          },
          statements: [["content", "user.tasksSummary", ["loc", [null, [100, 78], [100, 99]]]], ["content", "user.contractsReportedSummary", ["loc", [null, [101, 78], [101, 111]]]], ["inline", "roundwithspaces", [["get", "user.mmReportedSummary", ["loc", [null, [102, 96], [102, 118]]]]], ["precision", 0, "multiplier", 1], ["loc", [null, [102, 78], [102, 145]]]], ["inline", "roundwithspaces", [["get", "user.volumeReportedSummary", ["loc", [null, [103, 96], [103, 122]]]]], ["precision", 0, "multiplier", 1], ["loc", [null, [103, 78], [103, 149]]]], ["inline", "roundwithspaces", [["get", "user.contractsAcceptedSummary", ["loc", [null, [104, 96], [104, 125]]]]], ["precision", 0, "multiplier", 1], ["loc", [null, [104, 78], [104, 152]]]], ["inline", "roundwithspaces", [["get", "user.mmAcceptedSummary", ["loc", [null, [105, 96], [105, 118]]]]], ["precision", 0, "multiplier", 1], ["loc", [null, [105, 78], [105, 145]]]], ["inline", "roundwithspaces", [["get", "user.volumeAcceptedSummary", ["loc", [null, [106, 96], [106, 122]]]]], ["precision", 0, "multiplier", 1], ["loc", [null, [106, 78], [106, 149]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 108,
                "column": 24
              },
              "end": {
                "line": 119,
                "column": 24
              }
            },
            "moduleName": "ember-new/templates/reports/sales-by-salesman.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createElement("strong");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("br");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "class", "text-center vertical_align_middle");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "class", "text-center vertical_align_middle");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "class", "text-center vertical_align_middle");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "class", "text-center vertical_align_middle");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "class", "text-center vertical_align_middle");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "class", "text-center vertical_align_middle");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "class", "text-center vertical_align_middle");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var element1 = dom.childAt(element0, [1]);
            var morphs = new Array(9);
            morphs[0] = dom.createMorphAt(dom.childAt(element1, [0]), 0, 0);
            morphs[1] = dom.createMorphAt(element1, 2, 2);
            morphs[2] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
            morphs[3] = dom.createMorphAt(dom.childAt(element0, [5]), 0, 0);
            morphs[4] = dom.createMorphAt(dom.childAt(element0, [7]), 0, 0);
            morphs[5] = dom.createMorphAt(dom.childAt(element0, [9]), 0, 0);
            morphs[6] = dom.createMorphAt(dom.childAt(element0, [11]), 0, 0);
            morphs[7] = dom.createMorphAt(dom.childAt(element0, [13]), 0, 0);
            morphs[8] = dom.createMorphAt(dom.childAt(element0, [15]), 0, 0);
            return morphs;
          },
          statements: [["content", "user.user", ["loc", [null, [110, 36], [110, 49]]]], ["content", "user.region", ["loc", [null, [110, 64], [110, 79]]]], ["content", "ruser.numberOfMeetings", ["loc", [null, [111, 70], [111, 96]]]], ["content", "user.numberOfContractsReported", ["loc", [null, [112, 70], [112, 104]]]], ["inline", "roundwithspaces", [["get", "user.mmReported", ["loc", [null, [113, 88], [113, 103]]]]], ["precision", 0, "multiplier", 1], ["loc", [null, [113, 70], [113, 130]]]], ["inline", "roundwithspaces", [["get", "user.volumeReported", ["loc", [null, [114, 88], [114, 107]]]]], ["precision", 0, "multiplier", 1], ["loc", [null, [114, 70], [114, 134]]]], ["inline", "roundwithspaces", [["get", "user.numberOfContractsAccepted", ["loc", [null, [115, 88], [115, 118]]]]], ["precision", 0, "multiplier", 1], ["loc", [null, [115, 70], [115, 145]]]], ["inline", "roundwithspaces", [["get", "user.mmAccepted", ["loc", [null, [116, 88], [116, 103]]]]], ["precision", 0, "multiplier", 1], ["loc", [null, [116, 70], [116, 130]]]], ["inline", "roundwithspaces", [["get", "user.volumeAccepted", ["loc", [null, [117, 88], [117, 107]]]]], ["precision", 0, "multiplier", 1], ["loc", [null, [117, 70], [117, 134]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 96,
              "column": 16
            },
            "end": {
              "line": 120,
              "column": 16
            }
          },
          "moduleName": "ember-new/templates/reports/sales-by-salesman.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "eq", [["get", "user.id", ["loc", [null, [97, 34], [97, 41]]]], "sum"], [], ["loc", [null, [97, 30], [97, 48]]]]], [], 0, 1, ["loc", [null, [97, 24], [119, 31]]]]],
        locals: ["user"],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "fragmentReason": false,
        "revision": "Ember@2.3.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 126,
            "column": 0
          }
        },
        "moduleName": "ember-new/templates/reports/sales-by-salesman.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("article");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("section");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("fieldset");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("legend");
        var el5 = dom.createTextNode("Filtry");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("section");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "row");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "col-xs-12 col-md-6");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "form-group");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "col-xs-12 col-md-6");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "form-group");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "col-xs-12 col-md-6");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "form-group");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "col-xs-12 col-md-6");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "form-group");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "col-xs-12 col-md-6");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "form-group");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("footer");
        dom.setAttribute(el3, "class", "tile-footer text-right");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4, "class", "btn btn-info");
        var el5 = dom.createTextNode("Filtruj");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4, "class", "btn btn-danger");
        var el5 = dom.createTextNode("Wyczyść filtry");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("section");
        dom.setAttribute(el2, "class", "tile block-area");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("fieldset");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("legend");
        var el5 = dom.createTextNode("Wyniki");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "tile-body");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("table");
        dom.setAttribute(el4, "class", "table table-striped table_report");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("thead");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("tr");
        dom.setAttribute(el6, "class", "categories_header");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7, "class", "text-center uppercase vertical_align_middle");
        dom.setAttribute(el7, "style", "text-transform: uppercase;");
        var el8 = dom.createTextNode("Doradca / Region");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7, "class", "text-center uppercase vertical_align_middle");
        dom.setAttribute(el7, "style", "text-transform: uppercase;");
        var el8 = dom.createTextNode("# spotkań");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7, "class", "text-center uppercase vertical_align_middle");
        dom.setAttribute(el7, "style", "text-transform: uppercase;");
        var el8 = dom.createTextNode("# umów zaraportowanych");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7, "class", "text-center uppercase vertical_align_middle");
        dom.setAttribute(el7, "style", "text-transform: uppercase;");
        var el8 = dom.createTextNode("MM zaraportowana");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7, "class", "text-center uppercase vertical_align_middle");
        dom.setAttribute(el7, "style", "text-transform: uppercase;");
        var el8 = dom.createTextNode("Wolumen zaraportowany");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7, "class", "text-center uppercase vertical_align_middle");
        dom.setAttribute(el7, "style", "text-transform: uppercase;");
        var el8 = dom.createTextNode("#umów akcept");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7, "class", "text-center uppercase vertical_align_middle");
        dom.setAttribute(el7, "style", "text-transform: uppercase;");
        var el8 = dom.createTextNode("MM akcept");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.setAttribute(el7, "class", "text-center uppercase vertical_align_middle");
        dom.setAttribute(el7, "style", "text-transform: uppercase;");
        var el8 = dom.createTextNode("Wolumen akcept");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("tbody");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element3 = dom.childAt(fragment, [0]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(element4, [3, 1]);
        var element6 = dom.childAt(element4, [5]);
        var element7 = dom.childAt(element6, [1]);
        var element8 = dom.childAt(element6, [3]);
        var morphs = new Array(8);
        morphs[0] = dom.createMorphAt(dom.childAt(element5, [1, 1]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element5, [3, 1]), 1, 1);
        morphs[2] = dom.createMorphAt(dom.childAt(element5, [5, 1]), 1, 1);
        morphs[3] = dom.createMorphAt(dom.childAt(element5, [7, 1]), 1, 1);
        morphs[4] = dom.createMorphAt(dom.childAt(element5, [9, 1]), 1, 1);
        morphs[5] = dom.createElementMorph(element7);
        morphs[6] = dom.createElementMorph(element8);
        morphs[7] = dom.createMorphAt(dom.childAt(element3, [3, 3, 1, 3]), 1, 1);
        return morphs;
      },
      statements: [["block", "d-form-row", [], ["label", "Miesiąc akceptacji umowy", "inRow", true], 0, null, ["loc", [null, [10, 24], [17, 39]]]], ["block", "d-form-row", [], ["label", "Masa marży", "inRow", true], 1, null, ["loc", [null, [22, 24], [29, 39]]]], ["block", "d-form-row", [], ["label", "Pracownicy", "inRow", true], 2, null, ["loc", [null, [34, 24], [41, 39]]]], ["block", "d-form-row", [], ["label", "Produkt", "inRow", true], 3, null, ["loc", [null, [46, 24], [53, 39]]]], ["block", "d-form-row", [], ["label", "Region", "inRow", true], 4, null, ["loc", [null, [58, 24], [65, 39]]]], ["element", "action", ["applyFilters"], [], ["loc", [null, [72, 20], [72, 45]]]], ["element", "action", ["resetFilters"], [], ["loc", [null, [73, 20], [73, 45]]]], ["block", "each", [["get", "users", ["loc", [null, [96, 24], [96, 29]]]]], [], 5, null, ["loc", [null, [96, 16], [120, 25]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5]
    };
  })());
});
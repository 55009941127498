define('ember-new/mixins/routes/individual-contract-partners/bulk-actions', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Mixin.create({
        beforeModel: function beforeModel(transition) {
            this.set('filters', transition.queryParams);
        },
        setupController: function setupController(controller, model) {
            controller.set('filters', this.get('filters'));
        }

    });
});
define('ember-new/mixins/d-form/d-form-upload', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Mixin.create({

        _copyDataFromFormToModel: function _copyDataFromFormToModel() {

            console.log('copy');

            var model = this.get('model');
            var fields = this.get('fields');
            var formData = this.get('formData');
            var _this = this;
            var form = this;

            Object.keys(fields).forEach(function (key) {
                var value;

                var hasAttributeInModel = false;

                if (_this.get('_isRestModel')) {
                    hasAttributeInModel = _ember['default'].get(model.constructor, 'attributes').has(key) || _ember['default'].get(model.constructor, 'relationshipNames').belongsTo.indexOf(key) > -1 || _ember['default'].get(model.constructor, 'relationshipNames').hasMany.indexOf(key) > -1;
                } else {
                    hasAttributeInModel = model.hasOwnProperty(key);
                }

                if (hasAttributeInModel) {

                    if (_this.hasTransformerFor(key)) {
                        var transformer = _this.getTransformerFor(key);
                        value = transformer.reverseTransform.call(form, formData.get(key));
                    } else {
                        value = formData.get(key);
                    }

                    if (typeof value === 'string') {
                        model.set(key, _this.get('store').peekRecord('upload', value));
                    }
                }
            });
        }

    });
});
define('ember-new/models/individual-gas-contract', ['exports', 'ember-data', 'ember-new/models/abstract', 'ember-new/models/base-statusable-document-mixin', 'ember-new/mixins/models/individual-ee-gas-contract'], function (exports, _emberData, _emberNewModelsAbstract, _emberNewModelsBaseStatusableDocumentMixin, _emberNewMixinsModelsIndividualEeGasContract) {

	var attr = _emberData['default'].attr;
	var refOne = _emberData['default'].belongsTo;
	var refMany = _emberData['default'].hasMany;

	exports['default'] = _emberNewModelsAbstract['default'].extend(_emberNewModelsBaseStatusableDocumentMixin['default'], _emberNewMixinsModelsIndividualEeGasContract['default'], {

		ppm: refOne('individual-ppg'),

		secondContract: refOne('individual-ee-contract', { inverse: null }),
		// dfEeContract: refOne('individual-ee-contract', {inverse: null}),

		settlementPeriod: refOne('GasSettlementPeriodDictionaryEntry', { inverse: null }),
		gasFuelPurpose: refOne('IndividualContractGasFuelPurpose', { inverse: null }),

		type: (function () {
			return 'gas';
		}).property(),

		canBeAssignedRenouncement: (function () {
			return this.get('renouncement') === null;
		}).property('renouncement')
	});
});
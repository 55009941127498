define("ember-new/templates/individual-contract/partials/anonymization", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 6,
                  "column": 20
                },
                "end": {
                  "line": 14,
                  "column": 20
                }
              },
              "moduleName": "ember-new/templates/individual-contract/partials/anonymization.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("ul");
              dom.setAttribute(el1, "class", "todo-list");
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("Imię: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("Nazwisko: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("PESEL: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("E-mail: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("Telefon: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element11 = dom.childAt(fragment, [1]);
              var morphs = new Array(5);
              morphs[0] = dom.createMorphAt(dom.childAt(element11, [1, 0]), 1, 1);
              morphs[1] = dom.createMorphAt(dom.childAt(element11, [3, 0]), 1, 1);
              morphs[2] = dom.createMorphAt(dom.childAt(element11, [5, 0]), 1, 1);
              morphs[3] = dom.createMorphAt(dom.childAt(element11, [7, 0]), 1, 1);
              morphs[4] = dom.createMorphAt(dom.childAt(element11, [9, 0]), 1, 1);
              return morphs;
            },
            statements: [["content", "model.client.clientFirstName", ["loc", [null, [8, 74], [8, 106]]]], ["content", "model.client.clientLastName", ["loc", [null, [9, 78], [9, 109]]]], ["content", "model.client.clientPesel", ["loc", [null, [10, 75], [10, 103]]]], ["content", "model.client.clientEmail", ["loc", [null, [11, 76], [11, 104]]]], ["content", "model.client.clientPhone", ["loc", [null, [12, 77], [12, 105]]]]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 15,
                  "column": 20
                },
                "end": {
                  "line": 24,
                  "column": 20
                }
              },
              "moduleName": "ember-new/templates/individual-contract/partials/anonymization.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("ul");
              dom.setAttribute(el1, "class", "todo-list");
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("Ulica: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("Numer domu: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("Numer lokalu: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("Kod pocztowy: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("Poczta: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "text");
              var el4 = dom.createElement("strong");
              var el5 = dom.createTextNode("Miejscowość: ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element10 = dom.childAt(fragment, [1]);
              var morphs = new Array(6);
              morphs[0] = dom.createMorphAt(dom.childAt(element10, [1, 0]), 1, 1);
              morphs[1] = dom.createMorphAt(dom.childAt(element10, [3, 0]), 1, 1);
              morphs[2] = dom.createMorphAt(dom.childAt(element10, [5, 0]), 1, 1);
              morphs[3] = dom.createMorphAt(dom.childAt(element10, [7, 0]), 1, 1);
              morphs[4] = dom.createMorphAt(dom.childAt(element10, [9, 0]), 1, 1);
              morphs[5] = dom.createMorphAt(dom.childAt(element10, [11, 0]), 1, 1);
              return morphs;
            },
            statements: [["content", "model.client.clientAddress.street", ["loc", [null, [17, 75], [17, 112]]]], ["content", "model.client.clientAddress.streetNo", ["loc", [null, [18, 80], [18, 119]]]], ["content", "model.client.clientAddress.aptNo", ["loc", [null, [19, 82], [19, 118]]]], ["content", "model.client.clientAddress.postalCode", ["loc", [null, [20, 82], [20, 123]]]], ["content", "model.client.clientAddress.postalCity", ["loc", [null, [21, 76], [21, 117]]]], ["content", "model.client.clientAddress.city", ["loc", [null, [22, 81], [22, 116]]]]],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 26,
                    "column": 24
                  },
                  "end": {
                    "line": 34,
                    "column": 24
                  }
                },
                "moduleName": "ember-new/templates/individual-contract/partials/anonymization.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("ul");
                dom.setAttribute(el1, "class", "todo-list");
                var el2 = dom.createTextNode("\n                                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("li");
                var el3 = dom.createElement("span");
                dom.setAttribute(el3, "class", "text");
                var el4 = dom.createElement("strong");
                var el5 = dom.createTextNode("Imię: ");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("li");
                var el3 = dom.createElement("span");
                dom.setAttribute(el3, "class", "text");
                var el4 = dom.createElement("strong");
                var el5 = dom.createTextNode("Nazwisko: ");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("li");
                var el3 = dom.createElement("span");
                dom.setAttribute(el3, "class", "text");
                var el4 = dom.createElement("strong");
                var el5 = dom.createTextNode("PESEL: ");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("li");
                var el3 = dom.createElement("span");
                dom.setAttribute(el3, "class", "text");
                var el4 = dom.createElement("strong");
                var el5 = dom.createTextNode("E-mail: ");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("li");
                var el3 = dom.createElement("span");
                dom.setAttribute(el3, "class", "text");
                var el4 = dom.createElement("strong");
                var el5 = dom.createTextNode("Telefon: ");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element9 = dom.childAt(fragment, [1]);
                var morphs = new Array(5);
                morphs[0] = dom.createMorphAt(dom.childAt(element9, [1, 0]), 1, 1);
                morphs[1] = dom.createMorphAt(dom.childAt(element9, [3, 0]), 1, 1);
                morphs[2] = dom.createMorphAt(dom.childAt(element9, [5, 0]), 1, 1);
                morphs[3] = dom.createMorphAt(dom.childAt(element9, [7, 0]), 1, 1);
                morphs[4] = dom.createMorphAt(dom.childAt(element9, [9, 0]), 1, 1);
                return morphs;
              },
              statements: [["content", "model.client.firstNameSecondClient", ["loc", [null, [28, 78], [28, 116]]]], ["content", "model.client.lastNameSecondClient", ["loc", [null, [29, 82], [29, 119]]]], ["content", "model.client.peselSecondClient", ["loc", [null, [30, 79], [30, 113]]]], ["content", "model.client.emailSecondClient", ["loc", [null, [31, 80], [31, 114]]]], ["content", "model.client.phoneSecondClient", ["loc", [null, [32, 81], [32, 115]]]]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 25,
                  "column": 20
                },
                "end": {
                  "line": 35,
                  "column": 20
                }
              },
              "moduleName": "ember-new/templates/individual-contract/partials/anonymization.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "bs-tab-pane", [], ["title", "Drugi klient"], 0, null, ["loc", [null, [26, 24], [34, 40]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 5,
                "column": 16
              },
              "end": {
                "line": 36,
                "column": 16
              }
            },
            "moduleName": "ember-new/templates/individual-contract/partials/anonymization.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "bs-tab-pane", [], ["title", "Dane klienta"], 0, null, ["loc", [null, [6, 20], [14, 36]]]], ["block", "bs-tab-pane", [], ["title", "Adres"], 1, null, ["loc", [null, [15, 20], [24, 36]]]], ["block", "if", [["get", "model.client.peselSecondClient", ["loc", [null, [25, 26], [25, 56]]]]], [], 2, null, ["loc", [null, [25, 20], [35, 27]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 12
            },
            "end": {
              "line": 37,
              "column": 12
            }
          },
          "moduleName": "ember-new/templates/individual-contract/partials/anonymization.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "bs-tab", [], ["type", "tabs"], 0, null, ["loc", [null, [5, 16], [36, 27]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.3.2",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 44,
                      "column": 28
                    },
                    "end": {
                      "line": 63,
                      "column": 28
                    }
                  },
                  "moduleName": "ember-new/templates/individual-contract/partials/anonymization.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("ul");
                  dom.setAttribute(el1, "class", "todo-list");
                  var el2 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("li");
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "text");
                  var el4 = dom.createElement("strong");
                  var el5 = dom.createTextNode("Typ umowy: ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("li");
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "text");
                  var el4 = dom.createElement("strong");
                  var el5 = dom.createTextNode("Status: ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("li");
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "text");
                  var el4 = dom.createElement("strong");
                  var el5 = dom.createTextNode("Czy Dual Fuel: ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("li");
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "text");
                  var el4 = dom.createElement("strong");
                  var el5 = dom.createTextNode("Wariant umowy: ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("li");
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "text");
                  var el4 = dom.createElement("strong");
                  var el5 = dom.createTextNode("Rodzaj umowy: ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("li");
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "text");
                  var el4 = dom.createElement("strong");
                  var el5 = dom.createTextNode("Miejsce zawarcia umowy: ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("li");
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "text");
                  var el4 = dom.createElement("strong");
                  var el5 = dom.createTextNode("Numer umowy: ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("li");
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "text");
                  var el4 = dom.createElement("strong");
                  var el5 = dom.createTextNode("Odebrane dokumenty w Fortum: ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("li");
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "text");
                  var el4 = dom.createElement("strong");
                  var el5 = dom.createTextNode("Data podpisania: ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("li");
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "text");
                  var el4 = dom.createElement("strong");
                  var el5 = dom.createTextNode("Data startu: ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("li");
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "text");
                  var el4 = dom.createElement("strong");
                  var el5 = dom.createTextNode("Data końca: ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("li");
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "text");
                  var el4 = dom.createElement("strong");
                  var el5 = dom.createTextNode("Liczba miesięcy: ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("li");
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "text");
                  var el4 = dom.createElement("strong");
                  var el5 = dom.createTextNode("Termin płatności: ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("li");
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "text");
                  var el4 = dom.createElement("strong");
                  var el5 = dom.createTextNode("Produkt: ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("li");
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "text");
                  var el4 = dom.createElement("strong");
                  var el5 = dom.createTextNode("Taryfa: ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("li");
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "text");
                  var el4 = dom.createElement("strong");
                  var el5 = dom.createTextNode("Cennik: ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element8 = dom.childAt(fragment, [1]);
                  var morphs = new Array(16);
                  morphs[0] = dom.createMorphAt(dom.childAt(element8, [1, 0]), 1, 1);
                  morphs[1] = dom.createMorphAt(dom.childAt(element8, [3, 0]), 1, 1);
                  morphs[2] = dom.createMorphAt(dom.childAt(element8, [5, 0]), 1, 1);
                  morphs[3] = dom.createMorphAt(dom.childAt(element8, [7, 0]), 1, 1);
                  morphs[4] = dom.createMorphAt(dom.childAt(element8, [9, 0]), 1, 1);
                  morphs[5] = dom.createMorphAt(dom.childAt(element8, [11, 0]), 1, 1);
                  morphs[6] = dom.createMorphAt(dom.childAt(element8, [13, 0]), 1, 1);
                  morphs[7] = dom.createMorphAt(dom.childAt(element8, [15, 0]), 1, 1);
                  morphs[8] = dom.createMorphAt(dom.childAt(element8, [17, 0]), 1, 1);
                  morphs[9] = dom.createMorphAt(dom.childAt(element8, [19, 0]), 1, 1);
                  morphs[10] = dom.createMorphAt(dom.childAt(element8, [21, 0]), 1, 1);
                  morphs[11] = dom.createMorphAt(dom.childAt(element8, [23, 0]), 1, 1);
                  morphs[12] = dom.createMorphAt(dom.childAt(element8, [25, 0]), 1, 1);
                  morphs[13] = dom.createMorphAt(dom.childAt(element8, [27, 0]), 1, 1);
                  morphs[14] = dom.createMorphAt(dom.childAt(element8, [29, 0]), 1, 1);
                  morphs[15] = dom.createMorphAt(dom.childAt(element8, [31, 0]), 1, 1);
                  return morphs;
                },
                statements: [["content", "contract.type", ["loc", [null, [46, 87], [46, 104]]]], ["content", "contract.contractStatus", ["loc", [null, [47, 84], [47, 111]]]], ["content", "contract.isInDualFuel", ["loc", [null, [48, 91], [48, 116]]]], ["content", "contract.variant", ["loc", [null, [49, 91], [49, 111]]]], ["content", "contract.category", ["loc", [null, [50, 90], [50, 111]]]], ["content", "contract.signingPlace", ["loc", [null, [51, 100], [51, 125]]]], ["content", "contract.contractNumber", ["loc", [null, [52, 89], [52, 116]]]], ["inline", "format-date-pl", [["get", "contract.dateReceived", ["loc", [null, [53, 122], [53, 143]]]]], [], ["loc", [null, [53, 105], [53, 145]]]], ["inline", "format-date-pl", [["get", "contract.dateSigned", ["loc", [null, [54, 110], [54, 129]]]]], [], ["loc", [null, [54, 93], [54, 131]]]], ["inline", "format-date-pl", [["get", "contract.dateStart", ["loc", [null, [55, 106], [55, 124]]]]], [], ["loc", [null, [55, 89], [55, 126]]]], ["inline", "format-date-pl", [["get", "contract.dateEnd", ["loc", [null, [56, 105], [56, 121]]]]], [], ["loc", [null, [56, 88], [56, 123]]]], ["content", "contract.numberOfMonths", ["loc", [null, [57, 93], [57, 120]]]], ["content", "contract.paymentTerm", ["loc", [null, [58, 94], [58, 118]]]], ["content", "contract.product", ["loc", [null, [59, 85], [59, 105]]]], ["content", "contract.tariff", ["loc", [null, [60, 84], [60, 103]]]], ["content", "contract.basePricesList", ["loc", [null, [61, 84], [61, 111]]]]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              var child0 = (function () {
                var child0 = (function () {
                  return {
                    meta: {
                      "fragmentReason": false,
                      "revision": "Ember@2.3.2",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 84,
                          "column": 99
                        },
                        "end": {
                          "line": 84,
                          "column": 150
                        }
                      },
                      "moduleName": "ember-new/templates/individual-contract/partials/anonymization.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("/");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [["content", "point.address.aptNo", ["loc", [null, [84, 127], [84, 150]]]]],
                    locals: [],
                    templates: []
                  };
                })();
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.3.2",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 78,
                        "column": 36
                      },
                      "end": {
                        "line": 89,
                        "column": 36
                      }
                    },
                    "moduleName": "ember-new/templates/individual-contract/partials/anonymization.hbs"
                  },
                  isEmpty: false,
                  arity: 2,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                        ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("tr");
                    dom.setAttribute(el1, "role", "row");
                    var el2 = dom.createTextNode("\n                                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("td");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("td");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("td");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("td");
                    var el3 = dom.createTextNode("\n                                                ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode(" ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("br");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                                                ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode(" ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode(" ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                                            ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("td");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                                        ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element6 = dom.childAt(fragment, [1]);
                    var element7 = dom.childAt(element6, [7]);
                    var morphs = new Array(10);
                    morphs[0] = dom.createMorphAt(dom.childAt(element6, [1]), 0, 0);
                    morphs[1] = dom.createMorphAt(dom.childAt(element6, [3]), 0, 0);
                    morphs[2] = dom.createMorphAt(dom.childAt(element6, [5]), 0, 0);
                    morphs[3] = dom.createMorphAt(element7, 1, 1);
                    morphs[4] = dom.createMorphAt(element7, 3, 3);
                    morphs[5] = dom.createMorphAt(element7, 4, 4);
                    morphs[6] = dom.createMorphAt(element7, 7, 7);
                    morphs[7] = dom.createMorphAt(element7, 9, 9);
                    morphs[8] = dom.createMorphAt(element7, 11, 11);
                    morphs[9] = dom.createMorphAt(dom.childAt(element6, [9]), 0, 0);
                    return morphs;
                  },
                  statements: [["inline", "add-one", [["get", "index", ["loc", [null, [80, 58], [80, 63]]]]], [], ["loc", [null, [80, 48], [80, 65]]]], ["content", "point.counterNo", ["loc", [null, [81, 48], [81, 67]]]], ["content", "point.pointNo", ["loc", [null, [82, 48], [82, 65]]]], ["content", "point.address.street", ["loc", [null, [84, 48], [84, 72]]]], ["content", "point.address.streetNo", ["loc", [null, [84, 73], [84, 99]]]], ["block", "if", [["get", "point.address.aptNo", ["loc", [null, [84, 105], [84, 124]]]]], [], 0, null, ["loc", [null, [84, 99], [84, 157]]]], ["content", "point.address.postalCode", ["loc", [null, [85, 48], [85, 76]]]], ["content", "point.address.postalCity", ["loc", [null, [85, 77], [85, 105]]]], ["content", "point.address.city", ["loc", [null, [85, 106], [85, 128]]]], ["content", "point.tariff", ["loc", [null, [87, 48], [87, 64]]]]],
                  locals: ["point", "index"],
                  templates: [child0]
                };
              })();
              var child1 = (function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.3.2",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 89,
                        "column": 36
                      },
                      "end": {
                        "line": 91,
                        "column": 36
                      }
                    },
                    "moduleName": "ember-new/templates/individual-contract/partials/anonymization.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                        ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("tr");
                    var el2 = dom.createElement("td");
                    dom.setAttribute(el2, "colspan", "8");
                    dom.setAttribute(el2, "class", "text-center");
                    var el3 = dom.createElement("strong");
                    var el4 = dom.createTextNode("Brak PPE(G)");
                    dom.appendChild(el3, el4);
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() {
                    return [];
                  },
                  statements: [],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.3.2",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 64,
                      "column": 28
                    },
                    "end": {
                      "line": 96,
                      "column": 28
                    }
                  },
                  "moduleName": "ember-new/templates/individual-contract/partials/anonymization.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("\n                                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("table");
                  dom.setAttribute(el1, "class", "table table-bordered table-striped");
                  dom.setAttribute(el1, "role", "grid");
                  var el2 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("thead");
                  var el3 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("tr");
                  dom.setAttribute(el3, "role", "rowheader");
                  var el4 = dom.createTextNode("\n                                        ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("th");
                  var el5 = dom.createTextNode("#");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                                        ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("th");
                  var el5 = dom.createTextNode("Numer licznika");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                                        ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("th");
                  var el5 = dom.createTextNode("Numer PPG");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                                        ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("th");
                  var el5 = dom.createTextNode("Adres");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                                        ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("th");
                  var el5 = dom.createTextNode("Grupa taryfowa");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("tbody");
                  var el3 = dom.createTextNode("\n\n");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]), 1, 1);
                  return morphs;
                },
                statements: [["block", "each", [["get", "contract.ppms", ["loc", [null, [78, 44], [78, 57]]]]], [], 0, 1, ["loc", [null, [78, 36], [91, 45]]]]],
                locals: [],
                templates: [child0, child1]
              };
            })();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 43,
                    "column": 24
                  },
                  "end": {
                    "line": 97,
                    "column": 24
                  }
                },
                "moduleName": "ember-new/templates/individual-contract/partials/anonymization.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "bs-tab-pane", [], ["title", "Dane umowy"], 0, null, ["loc", [null, [44, 28], [63, 44]]]], ["block", "bs-tab-pane", [], ["title", "PPE(G)"], 1, null, ["loc", [null, [64, 28], [96, 44]]]]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.3.2",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 102,
                      "column": 32
                    },
                    "end": {
                      "line": 104,
                      "column": 32
                    }
                  },
                  "moduleName": "ember-new/templates/individual-contract/partials/anonymization.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("li");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                  return morphs;
                },
                statements: [["content", "alert", ["loc", [null, [103, 40], [103, 49]]]]],
                locals: ["alert"],
                templates: []
              };
            })();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 99,
                    "column": 24
                  },
                  "end": {
                    "line": 106,
                    "column": 24
                  }
                },
                "moduleName": "ember-new/templates/individual-contract/partials/anonymization.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                dom.setAttribute(el1, "class", "text-warning");
                var el2 = dom.createElement("i");
                dom.setAttribute(el2, "class", "fa fa-warning margin-right");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("Uwagi:");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("ul");
                dom.setAttribute(el1, "class", "text-warning");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
                return morphs;
              },
              statements: [["block", "each", [["get", "contract.alerts", ["loc", [null, [102, 40], [102, 55]]]]], [], 0, null, ["loc", [null, [102, 32], [104, 41]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 42,
                  "column": 20
                },
                "end": {
                  "line": 107,
                  "column": 20
                }
              },
              "moduleName": "ember-new/templates/individual-contract/partials/anonymization.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 4, 4, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "bs-tab", [], ["type", "tabs"], 0, null, ["loc", [null, [43, 24], [97, 35]]]], ["block", "if", [["get", "contract.alerts", ["loc", [null, [99, 30], [99, 45]]]]], [], 1, null, ["loc", [null, [99, 24], [106, 31]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 108,
                  "column": 20
                },
                "end": {
                  "line": 110,
                  "column": 20
                }
              },
              "moduleName": "ember-new/templates/individual-contract/partials/anonymization.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              var el2 = dom.createElement("strong");
              dom.setAttribute(el2, "style", "color:green");
              var el3 = dom.createElement("i");
              dom.setAttribute(el3, "class", "fa fa-check-square margin-right");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("Spełnione warunki anonimizacji");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 110,
                  "column": 20
                },
                "end": {
                  "line": 112,
                  "column": 20
                }
              },
              "moduleName": "ember-new/templates/individual-contract/partials/anonymization.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              var el2 = dom.createElement("strong");
              dom.setAttribute(el2, "style", "color:red");
              var el3 = dom.createElement("i");
              dom.setAttribute(el3, "class", "fa fa-exclamation-triangle margin-right");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("Nie spełnione warunki anonimizacji");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 41,
                "column": 16
              },
              "end": {
                "line": 113,
                "column": 16
              }
            },
            "moduleName": "ember-new/templates/individual-contract/partials/anonymization.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "editState", ["loc", [null, [42, 26], [42, 35]]]]], [], 0, null, ["loc", [null, [42, 20], [107, 27]]]], ["block", "if", [["get", "contract.isOkToAnonymize", ["loc", [null, [108, 26], [108, 50]]]]], [], 1, 2, ["loc", [null, [108, 20], [112, 27]]]]],
          locals: ["editState"],
          templates: [child0, child1, child2]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 40,
              "column": 12
            },
            "end": {
              "line": 114,
              "column": 12
            }
          },
          "moduleName": "ember-new/templates/individual-contract/partials/anonymization.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "box-for-form", [], ["title", ["subexpr", "concat", ["Umowa nr: ", ["get", "contract.contractNumber", ["loc", [null, [41, 59], [41, 82]]]]], [], ["loc", [null, [41, 38], [41, 83]]]], "editButtonText", "Szczegóły", "isAllowedEdit", true], 0, null, ["loc", [null, [41, 16], [113, 33]]]]],
        locals: ["contract"],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.3.2",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 121,
                      "column": 28
                    },
                    "end": {
                      "line": 147,
                      "column": 28
                    }
                  },
                  "moduleName": "ember-new/templates/individual-contract/partials/anonymization.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "row");
                  var el2 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "col-md-12");
                  var el3 = dom.createTextNode("\n                                        ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("h5");
                  var el4 = dom.createTextNode("Dane klienta");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                                        ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("ul");
                  dom.setAttribute(el3, "class", "todo-list");
                  var el4 = dom.createTextNode("\n                                            ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("li");
                  var el5 = dom.createElement("span");
                  dom.setAttribute(el5, "class", "text");
                  var el6 = dom.createElement("strong");
                  var el7 = dom.createTextNode("Imię: ");
                  dom.appendChild(el6, el7);
                  dom.appendChild(el5, el6);
                  var el6 = dom.createComment("");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                                            ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("li");
                  var el5 = dom.createElement("span");
                  dom.setAttribute(el5, "class", "text");
                  var el6 = dom.createElement("strong");
                  var el7 = dom.createTextNode("Nazwisko: ");
                  dom.appendChild(el6, el7);
                  dom.appendChild(el5, el6);
                  var el6 = dom.createComment("");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                                            ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("li");
                  var el5 = dom.createElement("span");
                  dom.setAttribute(el5, "class", "text");
                  var el6 = dom.createElement("strong");
                  var el7 = dom.createTextNode("PESEL: ");
                  dom.appendChild(el6, el7);
                  dom.appendChild(el5, el6);
                  var el6 = dom.createComment("");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                                            ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("li");
                  var el5 = dom.createElement("span");
                  dom.setAttribute(el5, "class", "text");
                  var el6 = dom.createElement("strong");
                  var el7 = dom.createTextNode("E-mail: ");
                  dom.appendChild(el6, el7);
                  dom.appendChild(el5, el6);
                  var el6 = dom.createComment("");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                                            ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("li");
                  var el5 = dom.createElement("span");
                  dom.setAttribute(el5, "class", "text");
                  var el6 = dom.createElement("strong");
                  var el7 = dom.createTextNode("Telefon: ");
                  dom.appendChild(el6, el7);
                  dom.appendChild(el5, el6);
                  var el6 = dom.createComment("");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                                        ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n                                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "row");
                  var el2 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "col-md-12");
                  var el3 = dom.createTextNode("\n                                        ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("h5");
                  var el4 = dom.createTextNode("Adres");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                                        ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("ul");
                  dom.setAttribute(el3, "class", "todo-list");
                  var el4 = dom.createTextNode("\n                                            ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("li");
                  var el5 = dom.createElement("span");
                  dom.setAttribute(el5, "class", "text");
                  var el6 = dom.createElement("strong");
                  var el7 = dom.createTextNode("Ulica: ");
                  dom.appendChild(el6, el7);
                  dom.appendChild(el5, el6);
                  var el6 = dom.createComment("");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                                            ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("li");
                  var el5 = dom.createElement("span");
                  dom.setAttribute(el5, "class", "text");
                  var el6 = dom.createElement("strong");
                  var el7 = dom.createTextNode("Numer domu: ");
                  dom.appendChild(el6, el7);
                  dom.appendChild(el5, el6);
                  var el6 = dom.createComment("");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                                            ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("li");
                  var el5 = dom.createElement("span");
                  dom.setAttribute(el5, "class", "text");
                  var el6 = dom.createElement("strong");
                  var el7 = dom.createTextNode("Numer lokalu: ");
                  dom.appendChild(el6, el7);
                  dom.appendChild(el5, el6);
                  var el6 = dom.createComment("");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                                            ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("li");
                  var el5 = dom.createElement("span");
                  dom.setAttribute(el5, "class", "text");
                  var el6 = dom.createElement("strong");
                  var el7 = dom.createTextNode("Kod pocztowy: ");
                  dom.appendChild(el6, el7);
                  dom.appendChild(el5, el6);
                  var el6 = dom.createComment("");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                                            ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("li");
                  var el5 = dom.createElement("span");
                  dom.setAttribute(el5, "class", "text");
                  var el6 = dom.createElement("strong");
                  var el7 = dom.createTextNode("Poczta: ");
                  dom.appendChild(el6, el7);
                  dom.appendChild(el5, el6);
                  var el6 = dom.createComment("");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                                            ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("li");
                  var el5 = dom.createElement("span");
                  dom.setAttribute(el5, "class", "text");
                  var el6 = dom.createElement("strong");
                  var el7 = dom.createTextNode("Miejscowość: ");
                  dom.appendChild(el6, el7);
                  dom.appendChild(el5, el6);
                  var el6 = dom.createComment("");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                                        ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element4 = dom.childAt(fragment, [1, 1, 3]);
                  var element5 = dom.childAt(fragment, [3, 1, 3]);
                  var morphs = new Array(11);
                  morphs[0] = dom.createMorphAt(dom.childAt(element4, [1, 0]), 1, 1);
                  morphs[1] = dom.createMorphAt(dom.childAt(element4, [3, 0]), 1, 1);
                  morphs[2] = dom.createMorphAt(dom.childAt(element4, [5, 0]), 1, 1);
                  morphs[3] = dom.createMorphAt(dom.childAt(element4, [7, 0]), 1, 1);
                  morphs[4] = dom.createMorphAt(dom.childAt(element4, [9, 0]), 1, 1);
                  morphs[5] = dom.createMorphAt(dom.childAt(element5, [1, 0]), 1, 1);
                  morphs[6] = dom.createMorphAt(dom.childAt(element5, [3, 0]), 1, 1);
                  morphs[7] = dom.createMorphAt(dom.childAt(element5, [5, 0]), 1, 1);
                  morphs[8] = dom.createMorphAt(dom.childAt(element5, [7, 0]), 1, 1);
                  morphs[9] = dom.createMorphAt(dom.childAt(element5, [9, 0]), 1, 1);
                  morphs[10] = dom.createMorphAt(dom.childAt(element5, [11, 0]), 1, 1);
                  return morphs;
                },
                statements: [["content", "renouncement.client.clientFirstName", ["loc", [null, [126, 90], [126, 129]]]], ["content", "renouncement.client.clientLastName", ["loc", [null, [127, 94], [127, 132]]]], ["content", "renouncement.client.clientPesel", ["loc", [null, [128, 91], [128, 126]]]], ["content", "renouncement.client.clientEmail", ["loc", [null, [129, 92], [129, 127]]]], ["content", "renouncement.client.clientPhone", ["loc", [null, [130, 93], [130, 128]]]], ["content", "renouncement.client.clientAddress.street", ["loc", [null, [138, 91], [138, 135]]]], ["content", "renouncement.client.clientAddress.streetNo", ["loc", [null, [139, 96], [139, 142]]]], ["content", "renouncement.client.clientAddress.aptNo", ["loc", [null, [140, 98], [140, 141]]]], ["content", "renouncement.client.clientAddress.postalCode", ["loc", [null, [141, 98], [141, 146]]]], ["content", "renouncement.client.clientAddress.postalCity", ["loc", [null, [142, 92], [142, 140]]]], ["content", "renouncement.client.clientAddress.city", ["loc", [null, [143, 97], [143, 139]]]]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.3.2",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 148,
                      "column": 28
                    },
                    "end": {
                      "line": 158,
                      "column": 28
                    }
                  },
                  "moduleName": "ember-new/templates/individual-contract/partials/anonymization.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("ul");
                  dom.setAttribute(el1, "class", "todo-list");
                  var el2 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("li");
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "text");
                  var el4 = dom.createElement("strong");
                  var el5 = dom.createTextNode("Numer umowy z odstąpienia: ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("li");
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "text");
                  var el4 = dom.createElement("strong");
                  var el5 = dom.createTextNode("Źródło odstąpienia: ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("li");
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "text");
                  var el4 = dom.createElement("strong");
                  var el5 = dom.createTextNode("Data wpłynięcia: ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("li");
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "text");
                  var el4 = dom.createElement("strong");
                  var el5 = dom.createTextNode("Data stempla: ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("li");
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "text");
                  var el4 = dom.createElement("strong");
                  var el5 = dom.createTextNode("Status: ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("li");
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "text");
                  var el4 = dom.createElement("strong");
                  var el5 = dom.createTextNode("Czy wymaga odpowiedzi: ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("li");
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "text");
                  var el4 = dom.createElement("strong");
                  var el5 = dom.createTextNode("Komentarz: ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element3 = dom.childAt(fragment, [1]);
                  var morphs = new Array(7);
                  morphs[0] = dom.createMorphAt(dom.childAt(element3, [1, 0]), 1, 1);
                  morphs[1] = dom.createMorphAt(dom.childAt(element3, [3, 0]), 1, 1);
                  morphs[2] = dom.createMorphAt(dom.childAt(element3, [5, 0]), 1, 1);
                  morphs[3] = dom.createMorphAt(dom.childAt(element3, [7, 0]), 1, 1);
                  morphs[4] = dom.createMorphAt(dom.childAt(element3, [9, 0]), 1, 1);
                  morphs[5] = dom.createMorphAt(dom.childAt(element3, [11, 0]), 1, 1);
                  morphs[6] = dom.createMorphAt(dom.childAt(element3, [13, 0]), 1, 1);
                  return morphs;
                },
                statements: [["content", "renouncement.contractNo", ["loc", [null, [150, 103], [150, 130]]]], ["content", "renouncement.source", ["loc", [null, [151, 96], [151, 119]]]], ["inline", "format-date-pl", [["get", "renouncement.dateEnter", ["loc", [null, [152, 110], [152, 132]]]]], [], ["loc", [null, [152, 93], [152, 134]]]], ["inline", "format-date-pl", [["get", "renouncement.datePostmark", ["loc", [null, [153, 107], [153, 132]]]]], [], ["loc", [null, [153, 90], [153, 134]]]], ["content", "renouncement.renouncementStatus", ["loc", [null, [154, 84], [154, 119]]]], ["inline", "yes-no", [["get", "renouncement.requiresResponse", ["loc", [null, [155, 108], [155, 137]]]]], [], ["loc", [null, [155, 99], [155, 139]]]], ["content", "renouncement.comment", ["loc", [null, [156, 87], [156, 111]]]]],
                locals: [],
                templates: []
              };
            })();
            var child2 = (function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.3.2",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 159,
                      "column": 28
                    },
                    "end": {
                      "line": 183,
                      "column": 28
                    }
                  },
                  "moduleName": "ember-new/templates/individual-contract/partials/anonymization.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "row");
                  var el2 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "col-md-12");
                  var el3 = dom.createTextNode("\n                                        ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("h5");
                  var el4 = dom.createTextNode("Dane Umowy/PPE/PPG");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                                        ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("ul");
                  dom.setAttribute(el3, "class", "todo-list");
                  var el4 = dom.createTextNode("\n                                            ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("li");
                  var el5 = dom.createElement("span");
                  dom.setAttribute(el5, "class", "text");
                  var el6 = dom.createElement("strong");
                  var el7 = dom.createTextNode("Data zawarcia umowy: ");
                  dom.appendChild(el6, el7);
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  var el5 = dom.createTextNode(" ");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createComment("");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                                            ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("li");
                  var el5 = dom.createElement("span");
                  dom.setAttribute(el5, "class", "text");
                  var el6 = dom.createElement("strong");
                  var el7 = dom.createTextNode("Numer PPE: ");
                  dom.appendChild(el6, el7);
                  dom.appendChild(el5, el6);
                  var el6 = dom.createComment("");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                                            ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("li");
                  var el5 = dom.createElement("span");
                  dom.setAttribute(el5, "class", "text");
                  var el6 = dom.createElement("strong");
                  var el7 = dom.createTextNode("Numer licznika PPE: ");
                  dom.appendChild(el6, el7);
                  dom.appendChild(el5, el6);
                  var el6 = dom.createComment("");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                                            ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("li");
                  var el5 = dom.createElement("span");
                  dom.setAttribute(el5, "class", "text");
                  var el6 = dom.createElement("strong");
                  var el7 = dom.createTextNode("Numer PPG: ");
                  dom.appendChild(el6, el7);
                  dom.appendChild(el5, el6);
                  var el6 = dom.createComment("");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                                            ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("li");
                  var el5 = dom.createElement("span");
                  dom.setAttribute(el5, "class", "text");
                  var el6 = dom.createElement("strong");
                  var el7 = dom.createTextNode("Numer licznika PPG: ");
                  dom.appendChild(el6, el7);
                  dom.appendChild(el5, el6);
                  var el6 = dom.createComment("");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                                        ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "col-md-12");
                  var el3 = dom.createTextNode("\n                                        ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("h5");
                  var el4 = dom.createTextNode("Adres PPE(G)");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                                        ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("ul");
                  dom.setAttribute(el3, "class", "todo-list");
                  var el4 = dom.createTextNode("\n                                            ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("li");
                  var el5 = dom.createElement("span");
                  dom.setAttribute(el5, "class", "text");
                  var el6 = dom.createElement("strong");
                  var el7 = dom.createTextNode("Ulica: ");
                  dom.appendChild(el6, el7);
                  dom.appendChild(el5, el6);
                  var el6 = dom.createComment("");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                                            ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("li");
                  var el5 = dom.createElement("span");
                  dom.setAttribute(el5, "class", "text");
                  var el6 = dom.createElement("strong");
                  var el7 = dom.createTextNode("Numer domu: ");
                  dom.appendChild(el6, el7);
                  dom.appendChild(el5, el6);
                  var el6 = dom.createComment("");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                                            ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("li");
                  var el5 = dom.createElement("span");
                  dom.setAttribute(el5, "class", "text");
                  var el6 = dom.createElement("strong");
                  var el7 = dom.createTextNode("Numer lokalu: ");
                  dom.appendChild(el6, el7);
                  dom.appendChild(el5, el6);
                  var el6 = dom.createComment("");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                                            ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("li");
                  var el5 = dom.createElement("span");
                  dom.setAttribute(el5, "class", "text");
                  var el6 = dom.createElement("strong");
                  var el7 = dom.createTextNode("Kod pocztowy: ");
                  dom.appendChild(el6, el7);
                  dom.appendChild(el5, el6);
                  var el6 = dom.createComment("");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                                            ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("li");
                  var el5 = dom.createElement("span");
                  dom.setAttribute(el5, "class", "text");
                  var el6 = dom.createElement("strong");
                  var el7 = dom.createTextNode("Poczta: ");
                  dom.appendChild(el6, el7);
                  dom.appendChild(el5, el6);
                  var el6 = dom.createComment("");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                                            ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("li");
                  var el5 = dom.createElement("span");
                  dom.setAttribute(el5, "class", "text");
                  var el6 = dom.createElement("strong");
                  var el7 = dom.createTextNode("Miejscowość: ");
                  dom.appendChild(el6, el7);
                  dom.appendChild(el5, el6);
                  var el6 = dom.createComment("");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                                        ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element0 = dom.childAt(fragment, [1]);
                  var element1 = dom.childAt(element0, [1, 3]);
                  var element2 = dom.childAt(element0, [3, 3]);
                  var morphs = new Array(11);
                  morphs[0] = dom.createMorphAt(dom.childAt(element1, [1]), 2, 2);
                  morphs[1] = dom.createMorphAt(dom.childAt(element1, [3, 0]), 1, 1);
                  morphs[2] = dom.createMorphAt(dom.childAt(element1, [5, 0]), 1, 1);
                  morphs[3] = dom.createMorphAt(dom.childAt(element1, [7, 0]), 1, 1);
                  morphs[4] = dom.createMorphAt(dom.childAt(element1, [9, 0]), 1, 1);
                  morphs[5] = dom.createMorphAt(dom.childAt(element2, [1, 0]), 1, 1);
                  morphs[6] = dom.createMorphAt(dom.childAt(element2, [3, 0]), 1, 1);
                  morphs[7] = dom.createMorphAt(dom.childAt(element2, [5, 0]), 1, 1);
                  morphs[8] = dom.createMorphAt(dom.childAt(element2, [7, 0]), 1, 1);
                  morphs[9] = dom.createMorphAt(dom.childAt(element2, [9, 0]), 1, 1);
                  morphs[10] = dom.createMorphAt(dom.childAt(element2, [11, 0]), 1, 1);
                  return morphs;
                },
                statements: [["inline", "format-date-pl", [["get", "renouncement.contractDateSigned", ["loc", [null, [164, 130], [164, 161]]]]], [], ["loc", [null, [164, 113], [164, 163]]]], ["content", "renouncement.ppeNo", ["loc", [null, [165, 95], [165, 117]]]], ["content", "renouncement.ppeCounterNo", ["loc", [null, [166, 104], [166, 133]]]], ["content", "renouncement.ppgNo", ["loc", [null, [167, 95], [167, 117]]]], ["content", "renouncement.ppgCounterNo", ["loc", [null, [168, 104], [168, 133]]]], ["content", "renouncement.ppmAddress.street", ["loc", [null, [174, 91], [174, 125]]]], ["content", "renouncement.ppmAddress.streetNo", ["loc", [null, [175, 96], [175, 132]]]], ["content", "renouncement.ppmAddress.aptNo", ["loc", [null, [176, 98], [176, 131]]]], ["content", "renouncement.ppmAddress.postalCode", ["loc", [null, [177, 98], [177, 136]]]], ["content", "renouncement.ppmAddress.postalCity", ["loc", [null, [178, 92], [178, 130]]]], ["content", "renouncement.ppmAddress.city", ["loc", [null, [179, 97], [179, 129]]]]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 120,
                    "column": 24
                  },
                  "end": {
                    "line": 184,
                    "column": 24
                  }
                },
                "moduleName": "ember-new/templates/individual-contract/partials/anonymization.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "bs-tab-pane", [], ["title", "Dane klienta"], 0, null, ["loc", [null, [121, 28], [147, 44]]]], ["block", "bs-tab-pane", [], ["title", "Dane odstąpienia"], 1, null, ["loc", [null, [148, 28], [158, 44]]]], ["block", "bs-tab-pane", [], ["title", "Dane Umowy/PPE/PPG"], 2, null, ["loc", [null, [159, 28], [183, 44]]]]],
              locals: [],
              templates: [child0, child1, child2]
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.3.2",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 189,
                      "column": 32
                    },
                    "end": {
                      "line": 191,
                      "column": 32
                    }
                  },
                  "moduleName": "ember-new/templates/individual-contract/partials/anonymization.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("li");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                  return morphs;
                },
                statements: [["content", "alert", ["loc", [null, [190, 40], [190, 49]]]]],
                locals: ["alert"],
                templates: []
              };
            })();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 186,
                    "column": 24
                  },
                  "end": {
                    "line": 193,
                    "column": 24
                  }
                },
                "moduleName": "ember-new/templates/individual-contract/partials/anonymization.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                dom.setAttribute(el1, "class", "text-warning");
                var el2 = dom.createElement("i");
                dom.setAttribute(el2, "class", "fa fa-warning margin-right");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("Uwagi:");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("ul");
                dom.setAttribute(el1, "class", "text-warning");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
                return morphs;
              },
              statements: [["block", "each", [["get", "renouncement.alerts", ["loc", [null, [189, 40], [189, 59]]]]], [], 0, null, ["loc", [null, [189, 32], [191, 41]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 119,
                  "column": 20
                },
                "end": {
                  "line": 194,
                  "column": 20
                }
              },
              "moduleName": "ember-new/templates/individual-contract/partials/anonymization.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 4, 4, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "bs-tab", [], ["type", "tabs"], 0, null, ["loc", [null, [120, 24], [184, 35]]]], ["block", "if", [["get", "renouncement.alerts", ["loc", [null, [186, 30], [186, 49]]]]], [], 1, null, ["loc", [null, [186, 24], [193, 31]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 195,
                  "column": 20
                },
                "end": {
                  "line": 197,
                  "column": 20
                }
              },
              "moduleName": "ember-new/templates/individual-contract/partials/anonymization.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              var el2 = dom.createElement("strong");
              dom.setAttribute(el2, "style", "color:green");
              var el3 = dom.createElement("i");
              dom.setAttribute(el3, "class", "fa fa-check-square");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" Spełnione warunki anonimizacji");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 197,
                  "column": 20
                },
                "end": {
                  "line": 199,
                  "column": 20
                }
              },
              "moduleName": "ember-new/templates/individual-contract/partials/anonymization.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              var el2 = dom.createElement("strong");
              dom.setAttribute(el2, "style", "color:red");
              var el3 = dom.createElement("i");
              dom.setAttribute(el3, "class", "fa fa-exclamation-triangle");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" Nie spełnione warunki anonimizacji");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 118,
                "column": 16
              },
              "end": {
                "line": 200,
                "column": 16
              }
            },
            "moduleName": "ember-new/templates/individual-contract/partials/anonymization.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "editState", ["loc", [null, [119, 26], [119, 35]]]]], [], 0, null, ["loc", [null, [119, 20], [194, 27]]]], ["block", "if", [["get", "renouncement.isOkToAnonymize", ["loc", [null, [195, 26], [195, 54]]]]], [], 1, 2, ["loc", [null, [195, 20], [199, 27]]]]],
          locals: ["editState"],
          templates: [child0, child1, child2]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 117,
              "column": 12
            },
            "end": {
              "line": 201,
              "column": 12
            }
          },
          "moduleName": "ember-new/templates/individual-contract/partials/anonymization.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "box-for-form", [], ["title", ["subexpr", "concat", ["Odstąpienie ", ["get", "renouncement.mediumName", ["loc", [null, [118, 61], [118, 84]]]]], [], ["loc", [null, [118, 38], [118, 85]]]], "editButtonText", "Szczegóły", "isAllowedEdit", true], 0, null, ["loc", [null, [118, 16], [200, 33]]]]],
        locals: ["renouncement"],
        templates: [child0]
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 209,
              "column": 8
            },
            "end": {
              "line": 209,
              "column": 119
            }
          },
          "moduleName": "ember-new/templates/individual-contract/partials/anonymization.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("i");
          dom.setAttribute(el1, "class", "fa fa-arrow-left");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" Anuluj");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.3.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 218,
            "column": 10
          }
        },
        "moduleName": "ember-new/templates/individual-contract/partials/anonymization.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "class", "content");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-md-3");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-md-5");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-md-4");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-md-12 col-sm-12 col-lg-12 col-xs-12");
        dom.setAttribute(el3, "style", "border-top: 1px solid #e4e4e4; padding-top: 17px");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4, "type", "button");
        dom.setAttribute(el4, "class", "btn btn-danger");
        var el5 = dom.createElement("i");
        dom.setAttribute(el5, "class", "fa fa-eraser");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" Anonimizuj\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n\n\n\n\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element12 = dom.childAt(fragment, [0]);
        var element13 = dom.childAt(element12, [1]);
        var element14 = dom.childAt(element12, [3, 1]);
        var element15 = dom.childAt(element14, [1]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(dom.childAt(element13, [1]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element13, [3]), 1, 1);
        morphs[2] = dom.createMorphAt(dom.childAt(element13, [5]), 1, 1);
        morphs[3] = dom.createElementMorph(element15);
        morphs[4] = dom.createMorphAt(element14, 3, 3);
        return morphs;
      },
      statements: [["block", "box-for-form", [], ["title", "Klient", "isAllowedEdit", false], 0, null, ["loc", [null, [4, 12], [37, 29]]]], ["block", "each", [["get", "model.contracts", ["loc", [null, [40, 20], [40, 35]]]]], ["key", "@index"], 1, null, ["loc", [null, [40, 12], [114, 21]]]], ["block", "each", [["get", "model.renouncements", ["loc", [null, [117, 20], [117, 39]]]]], ["key", "@index"], 2, null, ["loc", [null, [117, 12], [201, 21]]]], ["element", "action", ["anonymize", ["get", "model.client.clientUuid", ["loc", [null, [206, 41], [206, 64]]]]], [], ["loc", [null, [206, 20], [206, 66]]]], ["block", "link-to", ["individual-contract"], ["type", "button", "class", "btn btn-primary"], 3, null, ["loc", [null, [209, 8], [209, 131]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  })());
});
define('ember-new/components/d-field-switch', ['exports', 'ember', 'ember-new/mixins/d-form/d-field', 'ember-new/components/d-field-checkbox'], function (exports, _ember, _emberNewMixinsDFormDField, _emberNewComponentsDFieldCheckbox) {
  exports['default'] = _emberNewComponentsDFieldCheckbox['default'].extend({

    options: null,

    $elem: null,

    initOptions: (function () {
      this.set('options', {
        size: 'small',
        onText: 'TAK',
        offText: 'NIE'
      });
      //Ember.$.fn.bootstrapSwitch.defaults.onText = 'TAK';
      //Ember.$.fn.bootstrapSwitch.defaults.offText = 'NIE';
    }).on('init'),

    installPlugin: (function () {
      var _this = this;
      var $elem = $("#" + this.elementId + ' input');
      var options = this.get('options');
      options.state = this.get('value');
      options.disabled = this.get('disabled');
      $elem.bootstrapSwitch(options);
      $elem.on('switchChange.bootstrapSwitch', function (event, state) {
        _this.set('value', state);
      });
    }).on('didInsertElement'),

    valueChangedExternally: function valueChangedExternally() {
      $("#" + this.elementId + ' input').bootstrapSwitch('state', this.get('value') ? true : false, true);
    },

    disabledState: function disabledState(value) {
      this.set('disabled', value);
      $("#" + this.elementId + ' input').bootstrapSwitch('disabled', value, true);
    },

    switchChange: function switchChange() {
      var $elem = $("#" + this.elementId + ' input');
      var value = this.get('value');

      $elem.prop('checked', value).change();
    },

    disableStateAndSwitchChange: function disableStateAndSwitchChange(value) {
      this.disabledState(value);
      this.switchChange();
    }
  });
});
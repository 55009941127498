define('ember-new/controllers/b2b-contract/dzu/show-ee/ppe/details/invoices', ['exports', 'ember-new/config/environment', 'ember', 'ember-new/utils/ajax/post'], function (exports, _emberNewConfigEnvironment, _ember, _emberNewUtilsAjaxPost) {
    exports['default'] = _ember['default'].Controller.extend({
        ajax: _ember['default'].inject.service('ajax'),

        isDeleting: false,
        isSecondAreaDisabled: null,
        isThirdAreaDisabled: null,
        isTariffGroup: null,

        actions: {
            submitForm: function submitForm() {
                console.log('submitForm...');
                this.get('form').send('submit');
            },

            deleteInvoice: function deleteInvoice(invoice, ppe) {

                var _this = this;
                //this.set('isDeleting', true);

                var payload = {};
                payload['DeleteInvoiceDzuCommand'] = {};
                payload['DeleteInvoiceDzuCommand']['ppeId'] = ppe;
                payload['DeleteInvoiceDzuCommand']['invoiceId'] = invoice;

                var ajaxPromise = this.get('ajax').ajaxPost(_emberNewConfigEnvironment['default'].apiUrl + '/rest/deleteinvoicedzucommands', payload);

                ajaxPromise.then(function (response) {

                    setTimeout(function () {
                        var route = _this.container.lookup("route:b2b-contract.dzu.show-ee.ppe");
                        route.refresh();
                        _this.send('refreshModel');
                    }, 200);
                }, function (response) {});
            }
        },

        fillForm: function fillForm(form) {
            this.set('form', form); // uzywane w submitForm()...
            form.set('model.ppeId', this.get('ppeId'));
            form.set('model.consumption1', null);
            form.set('model.consumption2', null);
            form.set('model.consumption3', null);
            form.set('model.dateFrom', null);
            form.set('model.dateTo', null);
            if (this.get('areasQuantity') < 2) {
                this.set('isSecondAreaDisabled', true);
            } else {
                this.set('isSecondAreaDisabled', false);
            }
            if (this.get('areasQuantity') < 3) {
                this.set('isThirdAreaDisabled', true);
            } else {
                this.set('isThirdAreaDisabled', false);
            }
        },

        onInit: (function () {
            var _this2 = this;

            setTimeout(function () {
                return _this2.send('refreshModel');
            }, 200);
            var invoicesQty = this.get('invoicesQty');
            this.set('isInvoicesExists', invoicesQty > 0);
        }).on('init')

    });
});
define("ember-new/templates/components/bulk-actions-partner-contracts", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": {
            "name": "triple-curlies"
          },
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 7,
              "column": 0
            }
          },
          "moduleName": "ember-new/templates/components/bulk-actions-partner-contracts.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "progress");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "progress-bar progress-bar-striped active");
          dom.setAttribute(el2, "role", "progressbar");
          dom.setAttribute(el2, "aria-valuenow", "100");
          dom.setAttribute(el2, "aria-valuemin", "0");
          dom.setAttribute(el2, "aria-valuemax", "100");
          dom.setAttribute(el2, "style", "width: 100%");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 10,
                  "column": 8
                },
                "end": {
                  "line": 14,
                  "column": 8
                }
              },
              "moduleName": "ember-new/templates/components/bulk-actions-partner-contracts.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "alert alert-danger");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("strong");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element17 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(element17, [1]), 0, 0);
              morphs[1] = dom.createMorphAt(element17, 3, 3);
              return morphs;
            },
            statements: [["content", "err.code", ["loc", [null, [12, 24], [12, 36]]]], ["content", "err.message", ["loc", [null, [12, 46], [12, 61]]]]],
            locals: ["err"],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 9,
                "column": 4
              },
              "end": {
                "line": 15,
                "column": 4
              }
            },
            "moduleName": "ember-new/templates/components/bulk-actions-partner-contracts.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "each", [["get", "errors", ["loc", [null, [10, 16], [10, 22]]]]], [], 0, null, ["loc", [null, [10, 8], [14, 17]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.3.2",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 46,
                      "column": 32
                    },
                    "end": {
                      "line": 46,
                      "column": 98
                    }
                  },
                  "moduleName": "ember-new/templates/components/bulk-actions-partner-contracts.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("i");
                  dom.setAttribute(el1, "class", "fa fa-flash");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" EE");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.3.2",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 47,
                      "column": 32
                    },
                    "end": {
                      "line": 47,
                      "column": 99
                    }
                  },
                  "moduleName": "ember-new/templates/components/bulk-actions-partner-contracts.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("i");
                  dom.setAttribute(el1, "class", "fa fa-fire");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" Gaz");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            })();
            var child2 = (function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.3.2",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 53,
                      "column": 32
                    },
                    "end": {
                      "line": 57,
                      "column": 32
                    }
                  },
                  "moduleName": "ember-new/templates/components/bulk-actions-partner-contracts.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1, "class", "ok");
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("i");
                  dom.setAttribute(el2, "class", "fa fa-check");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode(" Tak\n                        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            })();
            var child3 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.3.2",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 57,
                        "column": 32
                      },
                      "end": {
                        "line": 61,
                        "column": 32
                      }
                    },
                    "moduleName": "ember-new/templates/components/bulk-actions-partner-contracts.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1, "class", "cancelled");
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("i");
                    dom.setAttribute(el2, "class", "fa fa-circle-o");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode(" Anulowana\n                    ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() {
                    return [];
                  },
                  statements: [],
                  locals: [],
                  templates: []
                };
              })();
              var child1 = (function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.3.2",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 61,
                        "column": 32
                      },
                      "end": {
                        "line": 65,
                        "column": 32
                      }
                    },
                    "moduleName": "ember-new/templates/components/bulk-actions-partner-contracts.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1, "class", "not-ok");
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("i");
                    dom.setAttribute(el2, "class", "fa fa-times");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode(" Niedozwolony status\n                    ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                                ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() {
                    return [];
                  },
                  statements: [],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.3.2",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 57,
                      "column": 32
                    },
                    "end": {
                      "line": 65,
                      "column": 32
                    }
                  },
                  "moduleName": "ember-new/templates/components/bulk-actions-partner-contracts.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["get", "value.isCancelled", ["loc", [null, [57, 42], [57, 59]]]]], [], 0, 1, ["loc", [null, [57, 32], [65, 32]]]]],
                locals: [],
                templates: [child0, child1]
              };
            })();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 42,
                    "column": 20
                  },
                  "end": {
                    "line": 68,
                    "column": 20
                  }
                },
                "moduleName": "ember-new/templates/components/bulk-actions-partner-contracts.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("tr");
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                var el3 = dom.createTextNode("\n                                ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                                ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                            ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                var el3 = dom.createTextNode("\n                                ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                            ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                            ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element11 = dom.childAt(fragment, [1]);
                var element12 = dom.childAt(element11, [3]);
                var morphs = new Array(6);
                morphs[0] = dom.createAttrMorph(element11, 'class');
                morphs[1] = dom.createMorphAt(dom.childAt(element11, [1]), 0, 0);
                morphs[2] = dom.createMorphAt(element12, 1, 1);
                morphs[3] = dom.createMorphAt(element12, 3, 3);
                morphs[4] = dom.createMorphAt(dom.childAt(element11, [5]), 1, 1);
                morphs[5] = dom.createMorphAt(dom.childAt(element11, [7]), 1, 1);
                return morphs;
              },
              statements: [["attribute", "class", ["concat", [["subexpr", "if", [["get", "value.hasSecondContract", ["loc", [null, [43, 40], [43, 63]]]], "hasSecondContract"], [], ["loc", [null, [43, 35], [43, 85]]]], " type-", ["get", "value.contractType", ["loc", [null, [43, 93], [43, 111]]]]]]], ["content", "value.contractNo", ["loc", [null, [44, 32], [44, 52]]]], ["block", "if", [["subexpr", "eq", [["get", "value.contractType", ["loc", [null, [46, 42], [46, 60]]]], "ee"], [], ["loc", [null, [46, 38], [46, 66]]]]], [], 0, null, ["loc", [null, [46, 32], [46, 105]]]], ["block", "if", [["subexpr", "eq", [["get", "value.contractType", ["loc", [null, [47, 42], [47, 60]]]], "gas"], [], ["loc", [null, [47, 38], [47, 67]]]]], [], 1, null, ["loc", [null, [47, 32], [47, 106]]]], ["inline", "if", [["get", "value.hasSecondContract", ["loc", [null, [50, 37], [50, 60]]]], ["get", "value.secondContractNo", ["loc", [null, [50, 61], [50, 83]]]], "Brak"], [], ["loc", [null, [50, 32], [50, 92]]]], ["block", "if", [["get", "value.isValidToHaveActionMade", ["loc", [null, [53, 38], [53, 67]]]]], [], 2, 3, ["loc", [null, [53, 32], [65, 39]]]]],
              locals: ["value"],
              templates: [child0, child1, child2, child3]
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.3.2",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 86,
                        "column": 28
                      },
                      "end": {
                        "line": 88,
                        "column": 28
                      }
                    },
                    "moduleName": "ember-new/templates/components/bulk-actions-partner-contracts.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("strong");
                    var el2 = dom.createTextNode("Warunki nie spełnione, nie można wykonac akcji");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() {
                    return [];
                  },
                  statements: [],
                  locals: [],
                  templates: []
                };
              })();
              var child1 = (function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.3.2",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 88,
                        "column": 28
                      },
                      "end": {
                        "line": 93,
                        "column": 28
                      }
                    },
                    "moduleName": "ember-new/templates/components/bulk-actions-partner-contracts.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("button");
                    dom.setAttribute(el1, "class", "btn btn-success  m-l-10");
                    var el2 = dom.createTextNode("\n                                ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("i");
                    dom.setAttribute(el2, "class", "fa fa-check");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode(" Wykonaj akcje\n                            ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element7 = dom.childAt(fragment, [1]);
                    var morphs = new Array(1);
                    morphs[0] = dom.createElementMorph(element7);
                    return morphs;
                  },
                  statements: [["element", "action", ["applyChanges"], [], ["loc", [null, [89, 36], [89, 61]]]]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.3.2",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 85,
                      "column": 24
                    },
                    "end": {
                      "line": 94,
                      "column": 24
                    }
                  },
                  "moduleName": "ember-new/templates/components/bulk-actions-partner-contracts.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["get", "isDisabledApplyButton", ["loc", [null, [86, 34], [86, 55]]]]], [], 0, 1, ["loc", [null, [86, 28], [93, 35]]]]],
                locals: [],
                templates: [child0, child1]
              };
            })();
            var child1 = (function () {
              var child0 = (function () {
                var child0 = (function () {
                  return {
                    meta: {
                      "fragmentReason": false,
                      "revision": "Ember@2.3.2",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 125,
                          "column": 32
                        },
                        "end": {
                          "line": 125,
                          "column": 98
                        }
                      },
                      "moduleName": "ember-new/templates/components/bulk-actions-partner-contracts.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createElement("i");
                      dom.setAttribute(el1, "class", "fa fa-flash");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode(" EE");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() {
                      return [];
                    },
                    statements: [],
                    locals: [],
                    templates: []
                  };
                })();
                var child1 = (function () {
                  return {
                    meta: {
                      "fragmentReason": false,
                      "revision": "Ember@2.3.2",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 126,
                          "column": 32
                        },
                        "end": {
                          "line": 126,
                          "column": 99
                        }
                      },
                      "moduleName": "ember-new/templates/components/bulk-actions-partner-contracts.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createElement("i");
                      dom.setAttribute(el1, "class", "fa fa-fire");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode(" Gaz");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() {
                      return [];
                    },
                    statements: [],
                    locals: [],
                    templates: []
                  };
                })();
                var child2 = (function () {
                  return {
                    meta: {
                      "fragmentReason": false,
                      "revision": "Ember@2.3.2",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 132,
                          "column": 32
                        },
                        "end": {
                          "line": 136,
                          "column": 32
                        }
                      },
                      "moduleName": "ember-new/templates/components/bulk-actions-partner-contracts.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                                    ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1, "class", "ok");
                      var el2 = dom.createTextNode("\n                                        ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("i");
                      dom.setAttribute(el2, "class", "fa fa-check");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode(" OK\n                                    ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() {
                      return [];
                    },
                    statements: [],
                    locals: [],
                    templates: []
                  };
                })();
                var child3 = (function () {
                  return {
                    meta: {
                      "fragmentReason": false,
                      "revision": "Ember@2.3.2",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 136,
                          "column": 32
                        },
                        "end": {
                          "line": 140,
                          "column": 32
                        }
                      },
                      "moduleName": "ember-new/templates/components/bulk-actions-partner-contracts.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                                    ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1, "class", "not-ok");
                      var el2 = dom.createTextNode("\n                                        ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("i");
                      dom.setAttribute(el2, "class", "fa fa-times");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode(" Błąd\n                                    ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() {
                      return [];
                    },
                    statements: [],
                    locals: [],
                    templates: []
                  };
                })();
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.3.2",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 121,
                        "column": 20
                      },
                      "end": {
                        "line": 143,
                        "column": 20
                      }
                    },
                    "moduleName": "ember-new/templates/components/bulk-actions-partner-contracts.hbs"
                  },
                  isEmpty: false,
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                        ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("tr");
                    var el2 = dom.createTextNode("\n                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("td");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("td");
                    var el3 = dom.createTextNode("\n                                ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                                ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                            ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("td");
                    var el3 = dom.createTextNode("\n                                ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                            ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("td");
                    var el3 = dom.createTextNode("\n");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("                            ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element4 = dom.childAt(fragment, [1]);
                    var element5 = dom.childAt(element4, [3]);
                    var morphs = new Array(6);
                    morphs[0] = dom.createAttrMorph(element4, 'class');
                    morphs[1] = dom.createMorphAt(dom.childAt(element4, [1]), 0, 0);
                    morphs[2] = dom.createMorphAt(element5, 1, 1);
                    morphs[3] = dom.createMorphAt(element5, 3, 3);
                    morphs[4] = dom.createMorphAt(dom.childAt(element4, [5]), 1, 1);
                    morphs[5] = dom.createMorphAt(dom.childAt(element4, [7]), 1, 1);
                    return morphs;
                  },
                  statements: [["attribute", "class", ["concat", [["subexpr", "if", [["get", "value.hasSecondContract", ["loc", [null, [122, 40], [122, 63]]]], "hasSecondContract"], [], ["loc", [null, [122, 35], [122, 85]]]], " type-", ["get", "value.contractType", ["loc", [null, [122, 93], [122, 111]]]]]]], ["content", "value.contractNo", ["loc", [null, [123, 32], [123, 52]]]], ["block", "if", [["subexpr", "eq", [["get", "value.contractType", ["loc", [null, [125, 42], [125, 60]]]], "ee"], [], ["loc", [null, [125, 38], [125, 66]]]]], [], 0, null, ["loc", [null, [125, 32], [125, 105]]]], ["block", "if", [["subexpr", "eq", [["get", "value.contractType", ["loc", [null, [126, 42], [126, 60]]]], "gas"], [], ["loc", [null, [126, 38], [126, 67]]]]], [], 1, null, ["loc", [null, [126, 32], [126, 106]]]], ["inline", "if", [["get", "value.hasSecondContract", ["loc", [null, [129, 37], [129, 60]]]], ["get", "value.secondContractNo", ["loc", [null, [129, 61], [129, 83]]]], "Brak"], [], ["loc", [null, [129, 32], [129, 92]]]], ["block", "if", [["get", "value.isValidToHaveActionMade", ["loc", [null, [132, 38], [132, 67]]]]], [], 2, 3, ["loc", [null, [132, 32], [140, 39]]]]],
                  locals: ["value"],
                  templates: [child0, child1, child2, child3]
                };
              })();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.3.2",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 109,
                      "column": 12
                    },
                    "end": {
                      "line": 148,
                      "column": 12
                    }
                  },
                  "moduleName": "ember-new/templates/components/bulk-actions-partner-contracts.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  var el2 = dom.createTextNode("\n                ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("table");
                  dom.setAttribute(el2, "id", "bulk-list-exe");
                  dom.setAttribute(el2, "class", "table table-condensed table-bordered table-striped");
                  var el3 = dom.createTextNode("\n                    ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("thead");
                  var el4 = dom.createTextNode("\n                    ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("tr");
                  var el5 = dom.createTextNode("\n                        ");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createElement("th");
                  var el6 = dom.createTextNode("Numer umowy");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  var el5 = dom.createTextNode("\n                        ");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createElement("th");
                  var el6 = dom.createTextNode("Rodzaj paliwa");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  var el5 = dom.createTextNode("\n                        ");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createElement("th");
                  var el6 = dom.createTextNode("Powiązana umowa");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  var el5 = dom.createTextNode("\n                        ");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createElement("th");
                  var el6 = dom.createTextNode("Wynik akcji");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  var el5 = dom.createTextNode("\n                    ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                    ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                    ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("tbody");
                  var el4 = dom.createTextNode("\n");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                    ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n            ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element6 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createAttrMorph(element6, 'class');
                  morphs[1] = dom.createMorphAt(dom.childAt(element6, [1, 3]), 1, 1);
                  return morphs;
                },
                statements: [["attribute", "class", ["concat", ["bulk-actions-table-wrapper view-", ["get", "type", ["loc", [null, [110, 58], [110, 62]]]]]]], ["block", "each", [["get", "exeResult", ["loc", [null, [121, 28], [121, 37]]]]], [], 0, null, ["loc", [null, [121, 20], [143, 29]]]]],
                locals: [],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 75,
                    "column": 12
                  },
                  "end": {
                    "line": 149,
                    "column": 12
                  }
                },
                "moduleName": "ember-new/templates/components/bulk-actions-partner-contracts.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "row m-t-20");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "col-md-2 text-right bold");
                var el3 = dom.createTextNode("Nowy status:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "col-md-3");
                var el3 = dom.createTextNode("\n                        ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "col-md-3 text-left");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                        ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n\n\n                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "col-md-4 text-left");
                var el3 = dom.createTextNode("\n                        ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("p");
                dom.setAttribute(el3, "class", "help-text not-ok");
                var el4 = dom.createTextNode("\n                            ");
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                        ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                        ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("p");
                dom.setAttribute(el3, "class", "help-text not-ok");
                var el4 = dom.createTextNode("\n                            ");
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                        ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element8 = dom.childAt(fragment, [1]);
                var element9 = dom.childAt(element8, [5]);
                var element10 = dom.childAt(element8, [7]);
                var morphs = new Array(6);
                morphs[0] = dom.createMorphAt(dom.childAt(element8, [3]), 1, 1);
                morphs[1] = dom.createMorphAt(element9, 1, 1);
                morphs[2] = dom.createMorphAt(element9, 3, 3);
                morphs[3] = dom.createMorphAt(dom.childAt(element10, [1]), 1, 1);
                morphs[4] = dom.createMorphAt(dom.childAt(element10, [3]), 1, 1);
                morphs[5] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["inline", "d-field-select-dictionary-uuid", [], ["dictionaryName", "verification_call_status", "key", "vcStatusUuid", "value", ["subexpr", "@mut", [["get", "vcStatusUuid", ["loc", [null, [82, 38], [82, 50]]]]], [], []]], ["loc", [null, [79, 24], [82, 52]]]], ["block", "if", [["get", "notInProgress", ["loc", [null, [85, 30], [85, 43]]]]], [], 0, null, ["loc", [null, [85, 24], [94, 31]]]], ["inline", "loading-spinner", [], ["isLoading", ["subexpr", "@mut", [["get", "isLoadingApplyChanges", ["loc", [null, [95, 52], [95, 73]]]]], [], []], "size", 1, "inline", true], ["loc", [null, [95, 24], [95, 94]]]], ["inline", "if", [["subexpr", "not", [["get", "vcStatusUuid", ["loc", [null, [101, 38], [101, 50]]]]], [], ["loc", [null, [101, 33], [101, 51]]]], "Wybierz nowy status."], [], ["loc", [null, [101, 28], [101, 76]]]], ["inline", "if", [["subexpr", "eq", [["get", "checkResultCanBeChangedContractsCount", ["loc", [null, [104, 37], [104, 74]]]], 0], [], ["loc", [null, [104, 33], [104, 77]]]], "Brak umów dla których można zmienić status."], [], ["loc", [null, [104, 28], [104, 125]]]], ["block", "if", [["get", "exeResult", ["loc", [null, [109, 18], [109, 27]]]]], [], 1, null, ["loc", [null, [109, 12], [148, 19]]]]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          var child2 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.3.2",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 150,
                      "column": 16
                    },
                    "end": {
                      "line": 151,
                      "column": 16
                    }
                  },
                  "moduleName": "ember-new/templates/components/bulk-actions-partner-contracts.hbs"
                },
                isEmpty: true,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              var child0 = (function () {
                var child0 = (function () {
                  return {
                    meta: {
                      "fragmentReason": false,
                      "revision": "Ember@2.3.2",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 154,
                          "column": 28
                        },
                        "end": {
                          "line": 156,
                          "column": 28
                        }
                      },
                      "moduleName": "ember-new/templates/components/bulk-actions-partner-contracts.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                                ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("strong");
                      var el2 = dom.createTextNode("Warunki nie spełnione, nie można wykonać akcji");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() {
                      return [];
                    },
                    statements: [],
                    locals: [],
                    templates: []
                  };
                })();
                var child1 = (function () {
                  return {
                    meta: {
                      "fragmentReason": false,
                      "revision": "Ember@2.3.2",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 156,
                          "column": 28
                        },
                        "end": {
                          "line": 161,
                          "column": 28
                        }
                      },
                      "moduleName": "ember-new/templates/components/bulk-actions-partner-contracts.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                                ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("button");
                      dom.setAttribute(el1, "class", "btn btn-success  m-l-10");
                      var el2 = dom.createTextNode("\n                                    ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("i");
                      dom.setAttribute(el2, "class", "fa fa-check");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode(" Wykonaj akcje\n                                ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element2 = dom.childAt(fragment, [1]);
                      var morphs = new Array(1);
                      morphs[0] = dom.createElementMorph(element2);
                      return morphs;
                    },
                    statements: [["element", "action", ["applyChanges"], [], ["loc", [null, [157, 40], [157, 65]]]]],
                    locals: [],
                    templates: []
                  };
                })();
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.3.2",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 153,
                        "column": 24
                      },
                      "end": {
                        "line": 162,
                        "column": 24
                      }
                    },
                    "moduleName": "ember-new/templates/components/bulk-actions-partner-contracts.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["block", "if", [["get", "isDisabledApplyButton", ["loc", [null, [154, 34], [154, 55]]]]], [], 0, 1, ["loc", [null, [154, 28], [161, 35]]]]],
                  locals: [],
                  templates: [child0, child1]
                };
              })();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.3.2",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 151,
                      "column": 16
                    },
                    "end": {
                      "line": 165,
                      "column": 16
                    }
                  },
                  "moduleName": "ember-new/templates/components/bulk-actions-partner-contracts.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "row m-t-20");
                  var el2 = dom.createTextNode("\n");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element3 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(element3, 1, 1);
                  morphs[1] = dom.createMorphAt(element3, 3, 3);
                  return morphs;
                },
                statements: [["block", "if", [["get", "notInProgress", ["loc", [null, [153, 30], [153, 43]]]]], [], 0, null, ["loc", [null, [153, 24], [162, 31]]]], ["inline", "loading-spinner", [], ["isLoading", ["subexpr", "@mut", [["get", "isLoadingApplyChanges", ["loc", [null, [163, 52], [163, 73]]]]], [], []], "size", 1, "inline", true], ["loc", [null, [163, 24], [163, 94]]]]],
                locals: [],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 149,
                    "column": 12
                  },
                  "end": {
                    "line": 166,
                    "column": 12
                  }
                },
                "moduleName": "ember-new/templates/components/bulk-actions-partner-contracts.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["subexpr", "eq", [["get", "type", ["loc", [null, [150, 26], [150, 30]]]], "comment"], [], ["loc", [null, [150, 22], [150, 41]]]]], [], 0, 1, ["loc", [null, [150, 16], [165, 23]]]]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          var child3 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 169,
                    "column": 12
                  },
                  "end": {
                    "line": 185,
                    "column": 12
                  }
                },
                "moduleName": "ember-new/templates/components/bulk-actions-partner-contracts.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "row m-t-20");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "col-md-2 text-right bold");
                var el3 = dom.createTextNode("Komentarz:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "col-md-10");
                var el3 = dom.createTextNode("\n                        ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "row m-t-20");
                dom.setAttribute(el1, "style", "float:right; margin: 20px 0 0 0;");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("button");
                dom.setAttribute(el2, "class", "btn btn-success  m-l-10");
                var el3 = dom.createTextNode("\n                        ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("i");
                dom.setAttribute(el3, "class", "fa fa-check");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" Wykonaj akcje\n                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [3]);
                var element1 = dom.childAt(element0, [3]);
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]), 1, 1);
                morphs[1] = dom.createMorphAt(element0, 1, 1);
                morphs[2] = dom.createElementMorph(element1);
                return morphs;
              },
              statements: [["inline", "d-field-textarea", [], ["key", "commentText", "value", ["subexpr", "@mut", [["get", "commentText", ["loc", [null, [175, 38], [175, 49]]]]], [], []]], ["loc", [null, [173, 24], [175, 51]]]], ["inline", "loading-spinner", [], ["isLoading", ["subexpr", "@mut", [["get", "isLoadingApplyChanges", ["loc", [null, [179, 48], [179, 69]]]]], [], []], "size", 1, "inline", true], ["loc", [null, [179, 20], [179, 90]]]], ["element", "action", ["applyChanges"], [], ["loc", [null, [180, 28], [180, 53]]]]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 16,
                  "column": 8
                },
                "end": {
                  "line": 188,
                  "column": 8
                }
              },
              "moduleName": "ember-new/templates/components/bulk-actions-partner-contracts.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "row margin-b-10");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col-md-6");
              var el3 = dom.createTextNode("\n                    Znalezione: ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("strong");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("br");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "ok");
              var el4 = dom.createTextNode("Do zmiany: ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("strong");
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col-md-6");
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "text-right");
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("button");
              dom.setAttribute(el4, "class", "btn btn-info ");
              var el5 = dom.createElement("i");
              dom.setAttribute(el5, "class", "fa fa-refresh");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode(" Sprawdź\n                            ponownie\n                        ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("table");
              dom.setAttribute(el2, "id", "bulk-list");
              dom.setAttribute(el2, "class", "table table-condensed table-bordered table-striped");
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("thead");
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("tr");
              var el5 = dom.createTextNode("\n                        ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("th");
              var el6 = dom.createTextNode("Numer umowy");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                        ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("th");
              var el6 = dom.createTextNode("Rodzaj paliwa");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                        ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("th");
              var el6 = dom.createTextNode("Powiązana umowa");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                        ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("th");
              var el6 = dom.createTextNode("Akcja możliwa?");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                    ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("tbody");
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element13 = dom.childAt(fragment, [1]);
              var element14 = dom.childAt(element13, [1]);
              var element15 = dom.childAt(element13, [3, 1, 1]);
              var element16 = dom.childAt(fragment, [3]);
              var morphs = new Array(8);
              morphs[0] = dom.createMorphAt(dom.childAt(element14, [1]), 0, 0);
              morphs[1] = dom.createMorphAt(dom.childAt(element14, [4, 1]), 0, 0);
              morphs[2] = dom.createElementMorph(element15);
              morphs[3] = dom.createAttrMorph(element16, 'class');
              morphs[4] = dom.createMorphAt(dom.childAt(element16, [1, 3]), 1, 1);
              morphs[5] = dom.createMorphAt(fragment, 5, 5, contextualElement);
              morphs[6] = dom.createMorphAt(fragment, 7, 7, contextualElement);
              morphs[7] = dom.createMorphAt(fragment, 9, 9, contextualElement);
              return morphs;
            },
            statements: [["content", "checkResultCount", ["loc", [null, [19, 40], [19, 60]]]], ["content", "checkResultCanBeChangedContractsCount", ["loc", [null, [20, 56], [20, 97]]]], ["element", "action", ["checkData"], [], ["loc", [null, [24, 32], [24, 54]]]], ["attribute", "class", ["concat", ["bulk-actions-table-wrapper view-", ["get", "type", ["loc", [null, [31, 58], [31, 62]]]]]]], ["block", "each", [["get", "checkResult", ["loc", [null, [42, 28], [42, 39]]]]], [], 0, null, ["loc", [null, [42, 20], [68, 29]]]], ["block", "if", [["subexpr", "eq", [["get", "type", ["loc", [null, [75, 22], [75, 26]]]], "edit-vc-status"], [], ["loc", [null, [75, 18], [75, 44]]]]], [], 1, 2, ["loc", [null, [75, 12], [166, 19]]]], ["block", "if", [["subexpr", "eq", [["get", "type", ["loc", [null, [169, 22], [169, 26]]]], "comment"], [], ["loc", [null, [169, 18], [169, 37]]]]], [], 3, null, ["loc", [null, [169, 12], [185, 19]]]], ["content", "yield", ["loc", [null, [187, 12], [187, 21]]]]],
            locals: [],
            templates: [child0, child1, child2, child3]
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 188,
                  "column": 8
                },
                "end": {
                  "line": 190,
                  "column": 8
                }
              },
              "moduleName": "ember-new/templates/components/bulk-actions-partner-contracts.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            Brak umów spełniających podane kryteria.\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 15,
                "column": 4
              },
              "end": {
                "line": 194,
                "column": 4
              }
            },
            "moduleName": "ember-new/templates/components/bulk-actions-partner-contracts.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment(" debug.... ");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [["block", "if", [["get", "checkResult", ["loc", [null, [16, 14], [16, 25]]]]], [], 0, 1, ["loc", [null, [16, 8], [190, 15]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 0
            },
            "end": {
              "line": 195,
              "column": 0
            }
          },
          "moduleName": "ember-new/templates/components/bulk-actions-partner-contracts.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "hasErrors", ["loc", [null, [9, 10], [9, 19]]]]], [], 0, 1, ["loc", [null, [9, 4], [194, 11]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.3.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 198,
            "column": 0
          }
        },
        "moduleName": "ember-new/templates/components/bulk-actions-partner-contracts.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "if", [["get", "isLoading", ["loc", [null, [1, 6], [1, 15]]]]], [], 0, 1, ["loc", [null, [1, 0], [195, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});
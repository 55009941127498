define('ember-new/services/model-metadata', ['exports', 'ember'], function (exports, _ember) {

	/**
  * The service to provide models metadata info
  */
	exports['default'] = _ember['default'].Service.extend({

		store: _ember['default'].inject.service(),

		/**
   * Returns all attributes and relations names of model in NativeArray
   * @param modelName string with name of model (in Ember notation - for example for model "model/user-view-model.js" -> "UserViewModel")
   * @returns {NativeArray}
   */
		getModelAttributesAndRelationsNames: function getModelAttributesAndRelationsNames(modelName) {
			var tmpRecord = this.get('store').createRecord(modelName);
			var modelClass = tmpRecord.constructor;

			var attrsAndRelations = _ember['default'].A();

			modelClass.eachAttribute(function (name) {
				attrsAndRelations.pushObject(name);
			});

			modelClass.eachRelationship(function (name) {
				attrsAndRelations.pushObject(name);
			});

			this.get('store').deleteRecord(tmpRecord);

			return attrsAndRelations;
		}

	});
});
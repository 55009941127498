define('ember-new/models/business-ppe-monthly-info', ['exports', 'ember-new/models/abstract'], function (exports, _emberNewModelsAbstract) {

    var string = DS.attr('string');

    exports['default'] = _emberNewModelsAbstract['default'].extend({
        year: string,
        month: string,
        volume: DS.belongsTo('BusinessPpeVolumeInfo', { embedded: true }),
        overhead: DS.belongsTo('BusinessPpeOverheadInfo', { embedded: true }),
        basePrice: DS.belongsTo('BusinessPpeBasePriceInfo', { embedded: true }),
        contractPrice: DS.belongsTo('BusinessPpeContractPriceInfo', { embedded: true }),
        mm: DS.belongsTo('BusinessPpeMmInfo', { embedded: true }),
        mmBasePrices: DS.belongsTo('BusinessPpeMmBasePrices', { embedded: true }),
        mmFuel: DS.belongsTo('BusinessPpeMmFuel', { embedded: true }),
        mmPerMonth: DS.belongsTo('BusinessPpeMmPerMonthInfo', { embedded: true })
    });
});
define('ember-new/models/gas-tariff-group-dictionary-entry', ['exports', 'ember-new/models/abstract'], function (exports, _emberNewModelsAbstract) {

    var GasTariffGroupDictionaryEntry = _emberNewModelsAbstract['default'].extend({
        label: DS.attr('string'),
        name: DS.attr('string'),
        pgnigTariffs: DS.hasMany('GasTariffGroupPgnigDictionaryEntry', { inverse: null }),
        duonTariffs: DS.hasMany('GasTariffGroupDuonDictionaryEntry', { inverse: null }),
        lvl: DS.attr('number'),
        isHidden: DS.attr('boolean'),
        uuid: DS.attr('string')
    });

    exports['default'] = GasTariffGroupDictionaryEntry;
});
define('ember-new/mixins/b2b-contract/components/d-form/tariffs/flatten-tariff-groups', ['exports', 'ember', 'ember-new/templates/components/d-form'], function (exports, _ember, _emberNewTemplatesComponentsDForm) {
    exports['default'] = _ember['default'].Mixin.create({
        layout: _emberNewTemplatesComponentsDForm['default'],

        configure: function configure() {

            // wszystkie grupy taryfowe
            var tariffGroups = this.get('store').peekAll('business-ee-tariff-group');

            /**
             *  Sprawdza czy grupa taryfowa jest jednostrefowa
             * @param tariffGroup
             * @returns {boolean}
             */
            var isFlattenTariffGroup = function isFlattenTariffGroup(tariffGroup) {
                return tariffGroup.get('areasQuantity') === 1;
            };

            // tablica  z obiektami wyplaszczonych grup taryfowych
            var flattenTariffGroups = tariffGroups.filter(function (group) {
                return isFlattenTariffGroup(group);
            });
            // tablica  z UUIDs wyplaszczonych grup taryfowych
            var flattenTariffGroupsUuids = flattenTariffGroups.map(function (group) {
                return group.get('uuid');
            });

            this.registerContentFilter('flattenTariffGroup', function (originalContent) {
                // zwraca tylko te rekordy ktore maja id nazezace do jednostrefowej grupy taryfowej
                return originalContent.filter(function (record) {
                    return flattenTariffGroupsUuids.includes(record.value);
                });
            });
        },

        tariffGroupObserver: (function () {
            var controller = this.get('targetObject');

            //sprawdzenie, czy wybrana taryfa więcej niż 1-strefowa
            var selectedTariffGroupUuid = this.get('formData.tariffGroup');
            var selectedTariffGroup = this.get('store').peekAll('business_ee_tariff_group').find(function (record) {
                return record.get('uuid') === selectedTariffGroupUuid;
            });

            if (selectedTariffGroup) {
                var areasQuantity = selectedTariffGroup.get('areasQuantity');

                //odblokowanie wypłaszczenia dla taryf > 1 strefowych
                if (areasQuantity > 1) {
                    controller.set('disableFlattening', false);
                } else {
                    this.set('formData.flattenTariffGroup', null);
                    controller.set('disableFlattening', true);
                }
            } else {
                this.set('formData.flattenTariffGroup', null);
                controller.set('disableFlattening', true);
            }
        }).observes('formData.tariffGroup')

    });
});
define('ember-new/models/duon-partner-dictionary-entry', ['exports', 'ember-new/models/abstract'], function (exports, _emberNewModelsAbstract) {

	var DuonPartnerDictionaryEntry = _emberNewModelsAbstract['default'].extend({
		label: DS.attr('string'),
		uuid: DS.attr('string'),
		name: DS.attr('string'),
		idPartner: DS.attr('number'),
		nameWithIdPartner: (function () {
			return this.get('name') + ' (#' + this.get('idPartner') + ')';
		}).property('name', 'label', 'idPartner'),
		isHidden: DS.attr('boolean'),
		usysExportId: DS.attr('string'),
		partnerType: DS.attr('number'),
		individualType: DS.attr('number'),
		departmentId: DS.attr('number'),
		isIndividual: DS.attr('boolean'),
		isKam: DS.attr('boolean'),
		isAutoGeneratedContractNumber: DS.attr('boolean'),
		verificationCallType: DS.belongsTo('verification-call-type', { inverse: null })
	});

	exports['default'] = DuonPartnerDictionaryEntry;
});
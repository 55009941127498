define('ember-new/routes/renouncement/show', ['exports', 'ember-new/routes/authenticated'], function (exports, _emberNewRoutesAuthenticated) {

    var RenouncemenShowRoute = _emberNewRoutesAuthenticated['default'].extend({

        beforeModel: function beforeModel() {
            this.store.unloadAll('upload');
        },

        model: function model(params) {
            return this.store.findRecord('renouncement', params.renouncement_id, {
                reload: true
            });
        }
    });

    exports['default'] = RenouncemenShowRoute;
});
define('ember-new/mixins/routes/scroll-top-on-reload-route', ['exports', 'ember'], function (exports, _ember) {

	/**
  * Mixin that
  */
	exports['default'] = _ember['default'].Mixin.create({
		beforeModel: function beforeModel(transition) {
			window.scrollTo(0, 0); // scroll to the top of application before reloading
		}
	});
});
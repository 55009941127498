define("ember-new/components/d-form/individual-contract-price-lists/condition", ["exports", "ember-new/components/d-form-command", "ember-new/templates/components/d-form"], function (exports, _emberNewComponentsDFormCommand, _emberNewTemplatesComponentsDForm) {
    exports["default"] = _emberNewComponentsDFormCommand["default"].extend({
        layout: _emberNewTemplatesComponentsDForm["default"],

        afterSuccessfulSubmit: function afterSuccessfulSubmit() {
            var targetController = this.get('targetObject');
            var mainRoute = targetController.container.lookup("route:individual-contract-price-lists.template.conditions");

            mainRoute.send('refreshModelAction');

            setTimeout(function () {
                targetController.transitionToRoute('individual-contract-price-lists.template.conditions');
            }, 100);

            var formData = this.get('formData');

            formData.set('type', null);
            formData.set('periodType', null);
        },

        resetAllFormFields: (function () {

            if (this.get('mode') === 'add') {
                this.set('formData.type', null);
                this.set('formData.periodType', null);
                this.set('formData.yearFrom', null);
                this.set('formData.monthFrom', null);
                this.set('formData.yearTo', null);
                this.set('formData.monthTo', null);
                this.set('formData.priceG', null);
                this.set('formData.priceE1', null);
                this.set('formData.priceE2', null);
                this.set('formData.priceE3', null);
                this.set('formData.fee', null);
            }
        }).on('didInsertElement')

    });
});
define("ember-new/templates/file-upload-form", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 103
            },
            "end": {
              "line": 11,
              "column": 154
            }
          },
          "moduleName": "ember-new/templates/file-upload-form.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("(i)");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 13,
              "column": 24
            },
            "end": {
              "line": 18,
              "column": 24
            }
          },
          "moduleName": "ember-new/templates/file-upload-form.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "class", "btn btn-primary");
          dom.setAttribute(el1, "href", "javascript:;");
          dom.setAttribute(el1, "style", "color:#fff;");
          var el2 = dom.createTextNode("Usuń plik");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n\n                        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          dom.setAttribute(el1, "class", "uploadedInfo");
          dom.setAttribute(el1, "style", "font-size: 12px;");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(fragment, [3]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createAttrMorph(element1, 'id');
          return morphs;
        },
        statements: [["element", "action", ["removeFileAction", ["get", "name", ["loc", [null, [14, 59], [14, 63]]]]], [], ["loc", [null, [14, 31], [14, 65]]]], ["attribute", "id", ["concat", ["uploadedInfo_", ["get", "parentView.attrs.fieldRow.value.key", ["loc", [null, [17, 46], [17, 81]]]]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.3.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 36,
            "column": 6
          }
        },
        "moduleName": "ember-new/templates/file-upload-form.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row");
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "column-lg-12");
        var el3 = dom.createTextNode("\n\n            ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "control-group");
        var el4 = dom.createTextNode("\n\n                ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "controls");
        var el5 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "style", "position:relative; margin: 10px;");
        var el6 = dom.createTextNode("\n\n                        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6, "class", "btn btn-primary");
        dom.setAttribute(el6, "href", "javascript:;");
        dom.setAttribute(el6, "style", "color:#fff;");
        var el7 = dom.createTextNode("Wybierz plik");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("input");
        dom.setAttribute(el7, "type", "file");
        dom.setAttribute(el7, "style", "cursor:pointer;width:106px;height:34px;position:absolute;z-index:2;top:0;left:0;filter: alpha(opacity=0);-ms-filter:\"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)\";opacity:0;background-color:transparent;color:transparent;");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "style", "display:none;");
        var el7 = dom.createTextNode("\n                            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7, "class", "loading_overlay");
        var el8 = dom.createTextNode("\n                                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8, "class", "loading_overlay_loading_box");
        var el9 = dom.createTextNode("\n                                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("h1");
        dom.setAttribute(el9, "class", "text-center");
        var el10 = dom.createElement("img");
        dom.setAttribute(el10, "src", "assets/images/gears.svg");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n                ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n            ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0, 1, 1, 1, 1]);
        var element3 = dom.childAt(element2, [1]);
        var element4 = dom.childAt(element3, [2]);
        var element5 = dom.childAt(element2, [5]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(element3, 1, 1);
        morphs[1] = dom.createAttrMorph(element4, 'id');
        morphs[2] = dom.createAttrMorph(element4, 'name');
        morphs[3] = dom.createElementMorph(element4);
        morphs[4] = dom.createMorphAt(element2, 3, 3);
        morphs[5] = dom.createAttrMorph(element5, 'id');
        return morphs;
      },
      statements: [["block", "if", [["get", "parentView.attrs.fieldRow.value.multiple", ["loc", [null, [11, 109], [11, 149]]]]], [], 0, null, ["loc", [null, [11, 103], [11, 161]]]], ["attribute", "id", ["concat", ["upload_", ["get", "view.parentView.attrs.fieldRow.value.key", ["loc", [null, [11, 181], [11, 221]]]]]]], ["attribute", "name", ["concat", [["get", "view.parentView.attrs.fieldRow.value.key", ["loc", [null, [11, 233], [11, 273]]]]]]], ["element", "view.parentView.attrs.fieldRow.value.multiple", [], [], ["loc", [null, [11, 524], [11, 573]]]], ["block", "if", [["get", "isFileExist", ["loc", [null, [13, 30], [13, 41]]]]], [], 1, null, ["loc", [null, [13, 24], [18, 31]]]], ["attribute", "id", ["concat", ["uploadedLoadingInfo_", ["get", "parentView.attrs.fieldRow.value.key", ["loc", [null, [20, 77], [20, 112]]]]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});
define('ember-new/helpers/add', ['exports', 'ember'], function (exports, _ember) {
    exports.add = add;

    function add(params) {
        return params.reduce(function (a, b) {
            return a + b;
        }, 0);
    }

    exports['default'] = _ember['default'].Helper.helper(add);
});
define('ember-new/controllers/tasks/tasks-client/ee-contact', ['exports', 'ember-new/config/environment', 'ember'], function (exports, _emberNewConfigEnvironment, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        queryParams: ['taskUuid', 'taskId'],
        taskUuid: null,
        taskId: null,

        fillForm: function fillForm(form) {
            form.set('model.clientUuid', this.get('model.uuid'));
            form.set('model.firstName', this.get('model.eeContactPerson.firstName'));
            form.set('model.lastName', this.get('model.eeContactPerson.lastName'));
            form.set('model.position', this.get('model.eeContactPerson.position'));
            form.set('model.email', this.get('model.eeContactPerson.email'));
            form.set('model.phoneMobile', this.get('model.eeContactPerson.phoneMobile'));
            form.set('model.phoneLandline', this.get('model.eeContactPerson.phoneLandline'));
        },

        afterCommand: function afterCommand() {
            var ctrl = this;
            ctrl.get('model').reload();
            ctrl.transitionToRoute('tasks');
            var route = this.container.lookup('route:tasks');
            route.refresh();
        }

    });
});
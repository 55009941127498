define('ember-new/models/business-gas-contract-mm-info', ['exports', 'ember-new/models/abstract', 'ember-data'], function (exports, _emberNewModelsAbstract, _emberData) {

    var string = _emberData['default'].attr('string');

    exports['default'] = _emberNewModelsAbstract['default'].extend({
        consumptionByDzu: string,
        consumptionBySalesman: string,
        mmByDzu: string,
        mmFromPrices: string,
        mmFromVas: string,
        mmBySalesman: string,
        overheadByDzu: string,
        overheadBySalesman: string,
        volumeByDzu: string,
        volumeBySalesman: string,
        ppgInfo: _emberData['default'].hasMany('BusinessPpgInfo', { embedded: true }),
        isArchivalContract: _emberData['default'].attr('boolean'),
        numberOfMonthsBySalesman: _emberData['default'].attr('number')
    });
});
define("ember-new/controllers/b2b-contract/administration/vas/add", ["exports", "ember-new/mixins/controllers/b2b-contract/administration/add-vas", "ember"], function (exports, _emberNewMixinsControllersB2bContractAdministrationAddVas, _ember) {
    exports["default"] = _ember["default"].Controller.extend(_emberNewMixinsControllersB2bContractAdministrationAddVas["default"], {

        commandName: 'CreateVasCommand',

        fillForm: function fillForm(form) {
            form.set('model.name', null);
            form.set('model.inclusionComponent', 'ee_mwh');
            form.set('model.idUsys', null);
            form.set('model.inclusionType', 'discount');
            form.set('model.valueType', 'percent');
            form.set('model.overheadRatePerPercentPoint', null);
            form.set('model.baseValue', null);
            form.set('model.sellValue', null);
            form.set('model.overheadTypeForMM', 'const');
            form.set('model.overheadForMM', null);
        }
    });
});
define('ember-new/controllers/b2b-contract/administration/section-products/list/edit-product-to-contract', ['exports', 'ember', 'ember-new/mixins/b2b-contract/controllers/section-products/add-product-to-contract'], function (exports, _ember, _emberNewMixinsB2bContractControllersSectionProductsAddProductToContract) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsB2bContractControllersSectionProductsAddProductToContract['default'], {

        selectedProducts: [],
        fillForm: function fillForm(form) {
            this.initializeFilteredProducts();
            form.set('model.uuid', this.get('model.id'));
            form.set('model.productName', this.get('model.name'));
            form.set('model.medium', this.get('model.typeKey'));
            form.set('model.usysId', this.get('model.usysId'));
            form.set('model.isMmVisible', this.get('model.isMmVisibleToSalesman'));
            form.set('model.isIndex', this.get('model.isIndex'));
            form.set('model.isDefaultConditionProduct', !!this.get('model.defaultProduct.uuid'));
            form.set('model.everyIndexAvailableInConditions', this.get('model.everyIndexAvailableInConditions'));
            form.set('model.allowedConditionProduct', this.get('model.allowedConditionProductsUuids'));
            form.set('model.defaultConditionProductName', this.get('model.defaultProduct.uuid'));
        },

        initializeFilteredProducts: function initializeFilteredProducts() {
            var valueEveryIndex = this.get('model.everyIndexAvailableInConditions');
            var valueMedium = this.get('model.typeKey');

            if (valueMedium === 'ee' && valueEveryIndex !== true) {
                this.set('filteredProducts', this.get('eeProducts'));
                this.set('filteredDefaultProducts', this.get('eeProducts'));
            } else if (valueMedium === 'ee' && valueEveryIndex === true) {
                this.set('filteredProducts', this.get('filteredEeProducts'));
                this.set('filteredDefaultProducts', this.get('eeProducts'));
            }

            if (valueMedium === 'gas' && valueEveryIndex !== true) {
                this.set('filteredProducts', this.get('gasProducts'));
                this.set('filteredDefaultProducts', this.get('gasProducts'));
            } else if (valueMedium === 'gas' && valueEveryIndex === true) {
                this.set('filteredProducts', this.get('filteredGasProducts'));
                this.set('filteredDefaultProducts', this.get('gasProducts'));
            }
        }
    });
});
define('ember-new/routes/b2b-contract/dzu/show-ee/ppe/add', ['exports', 'ember-new/routes/authenticated', 'ember'], function (exports, _emberNewRoutesAuthenticated, _ember) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({

        contractId: null,

        model: function model() {
            var contract = this.modelFor('b2b-contract.dzu.show-ee');
            this.set('contractId', contract.get('id'));

            return this.store.findRecord('ContractClientDzuViewModel', contract.get('id'));
        },

        setupController: function setupController(ctrl, model) {
            ctrl.set('model', model);
            ctrl.set('contractId', this.get('contractId'));
        }

    });
});
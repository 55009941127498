define('ember-new/mixins/b2b-contract/components/d-form/osd-os-observers-ee', ['exports', 'ember', 'ember-new/templates/components/d-form'], function (exports, _ember, _emberNewTemplatesComponentsDForm) {
	exports['default'] = _ember['default'].Mixin.create({
		layout: _emberNewTemplatesComponentsDForm['default'],
		first: true,
		counter: 0,

		osdObserver: (function () {
			var _this = this;

			if (this.first === true) {
				return;
			}
			var osd = this.get('formData.osd');
			var controller = this.get('targetObject');

			if (osd) {

				this.get('formRows.reserveAgreement.field').disabledState(false);
				this.get('formRows.isNewConnection.field').disabledState(false);
				this.get('formRows.isContractTerminating.field').disabledState(false);
				controller.set('disableSellerChange', false);

				// filtrowanie wartości os w zależności od osd
				//ustawienie tylko gdy nowe przyłącze lub umowa rezerwowa na tak
				//lub gdy czy wypowiadamy na tak i pierwsza zmiana
				if (this.get('formData.isNewConnection') === true || this.get('formData.reserveAgreement') === true || this.get('formData.isContractTerminating') === true && this.get('formData.sellerChange') === "a1579e40-b192-4143-b97a-b73665646f3e") {
					(function () {
						controller.set('disableOs', false);

						var selectedOsdUuid = _this.get('formData.osd');
						var selectedOsd = _this.get('store').peekAll('osd-dictionary-entry').find(function (record) {
							return record.get('uuid') === selectedOsdUuid;
						});
						// osUuid z urzędu lub FORTUM
						var osUuid = selectedOsd.get('osUuids')[0] ? selectedOsd.get('osUuids')[0] : "8f61fdeb-efb3-488a-bc38-0c411e3ed39e";

						_this.set('formData.os', osUuid);

						if (!_this.get('formData.isContractTerminating')) {
							controller.set('disableOs', true);
							controller.set('disableSellerChange', true);
						}
					})();
				}

				return true;

				//    zresetowanie OSD gdy już było wcześniej wybrane
			} else if (osd === '') {
					//zresetowanie um. rezerwowa - zablokowanie i ustawienia domyślne (N)
					this.set('formData.reserveAgreement', false);
					this.get('formRows.reserveAgreement.field').valueChangedExternally();
					this.get('formRows.reserveAgreement.field').disabledState(true);

					//zresetowanie nowe przyłącze - zablokowanie i ustawienia domyślne (N)
					this.set('formData.isNewConnection', false);
					this.get('formRows.isNewConnection.field').valueChangedExternally();
					this.get('formRows.isNewConnection.field').disabledState(true);

					//zresetowanie czy wypowaidamy umowę - zablokowanie i ustawienia domyślne (T)
					this.get('formRows.isContractTerminating.field').disabledState(false);
					// jezeli z backend przychodzi NULL to znaczy ze wartosc nie byla wczesniej ustawiana
					if (this.get('formData.isContractTerminating') === null || this.get('formData.isContractTerminating') === true) {
						this.set('formData.isContractTerminating', true);
						this.get('formRows.isContractTerminating.field').valueChangedExternally();
						this.get('formRows.isContractTerminating.field').disabledState(true);
					}

					//zresetowanie zmiana sprzedawcy i sprzedawca przed Fortum
					this.set('formData.sellerChange', null);
					this.set('formData.os', null);
					controller.set('disableSellerChange', true);
					controller.set('disableOs', true);

					return false;
				}

			return false;
		}).observes('formData.osd'),

		isContractTerminatingFieldValue: (function () {
			if (this.first === true) {
				return;
			}
			var controller = this.get('targetObject');

			if (this.osdObserver()) {
				if (this.get('formData.reserveAgreement') === undefined || this.get('formData.isNewConnection') === undefined || this.get('formData.isContractTerminating') === undefined) {

					this.set('formData.reserveAgreement', this.get('model.reserveAgreement'));
					this.set('formData.isNewConnection', this.get('model.isNewConnection'));
					this.set('formData.isContractTerminating', this.get('model.isContractTerminating'));
				}
				if (this.get('formData.isNewConnection') === true) {
					this.get('formRows.isContractTerminating.field').disabledState(false);
					this.set('formData.isContractTerminating', false);
					this.get('formRows.isContractTerminating.field').valueChangedExternally();
					this.get('formRows.isContractTerminating.field').disabledState(true);
					this.set('formData.sellerChange', "54da2441-86a9-47c6-95dd-67df496ebb94");

					controller.set('disableOs', true);

					return true;
				} else {
					this.get('formRows.isContractTerminating.field').disabledState(false);
					this.set('formData.isContractTerminating', true);
					this.get('formRows.isContractTerminating.field').valueChangedExternally();
					this.set('formData.os', null);

					return false;
				}
			}
		}).observes('formData.isNewConnection', 'formData.reserveAgreement'),

		//Ustaw Zmianę sprzedawcy na KOLEJNA jeżeli umowa rezerwowa
		isReserveAgreement: (function () {
			if (this.get('formData.reserveAgreement') === true) {
				this.set('disableSellerChange', true);
				this.get('formRows.isContractTerminating.field').disabledState(false);
				this.set('formData.isContractTerminating', false);
				this.get('formRows.isContractTerminating.field').valueChangedExternally();
				this.get('formRows.isContractTerminating.field').disabledState(true);
				this.set('formData.sellerChange', "a85f4767-2a13-4d3f-a8af-8f8b8c477a34");
			}
		}).observes('formData.reserveAgreement'),

		osFieldValueWhenUnlocked: (function () {
			var _this2 = this;

			if (this.first === true) {
				return;
			}
			var controller = this.get('targetObject');

			if (this.get('formData.isContractTerminating') === true && this.get('formData.sellerChange') != null) {

				controller.set('disableOs', false);

				//kiedy PIERWSZA ZMIANA
				if (this.get('formData.sellerChange') === "a1579e40-b192-4143-b97a-b73665646f3e") {
					(function () {
						var selectedOsdUuid = _this2.get('formData.osd');
						var selectedOsd = _this2.get('store').peekAll('osd-dictionary-entry').find(function (record) {
							return record.get('uuid') === selectedOsdUuid;
						});
						var osUuid = selectedOsd.get('osUuids')[0] ? selectedOsd.get('osUuids')[0] : "8f61fdeb-efb3-488a-bc38-0c411e3ed39e";

						_this2.set('formData.os', osUuid);
					})();
				}
			}
		}).observes('formData.isContractTerminating', 'formData.sellerChange'),

		/**
   * funkcja, która blokuje odpalanie się observerów przy pierwszym wejściu w formularz - w przypadku gdy isContractTerminating == false. pozwala to na wyświetlenie się prawdziwych wartości zanim zostaną nadpisane przez observery. counter potrzebny, bo observery uruchamiają się klika razy, zanim zostanie dokonana jakakolwiek zmiana na formularzu.
   * --- na przyszłość - używać computedProperites zamiast observerów w takich przypadkach
   */
		checkDefaultValues: (function () {

			this.counter++;

			var controller = this.get('targetObject');
			if (controller.get('model.isContractTerminating') === true) {
				this.first = false;
			}
			if (this.first && this.counter === 6) {
				if (controller.get('model.isContractTerminating') === false) {
					if (controller.get('model.reserveAgreement') === true) {
						this.get('formRows.isContractTerminating.field').disabledState(true);
					} else {
						this.get('formRows.isContractTerminating.field').disabledState(false);
					}
					if (controller.get('model.isNewConnection') === true) {
						controller.set('disableSellerChange', true);
						this.get('formRows.isContractTerminating.field').disabledState(true);
					}
					this.set('formData.isContractTerminating', false);
					this.get('formRows.isNewConnection.field').disabledState(false);
					this.get('formRows.reserveAgreement.field').disabledState(false);
				}

				this.first = false;
			}
		}).observes('formData.isContractTerminating', 'formData.sellerChange', 'formData.isNewConnection')

	});
});
define('ember-new/routes/renouncement/add', ['exports', 'ember-new/routes/authenticated', 'ember'], function (exports, _emberNewRoutesAuthenticated, _ember) {

    var RenouncementAddRoute = _emberNewRoutesAuthenticated['default'].extend({
        model: function model() {
            return this.store.createRecord('renouncement', {
                renouncementStatus: this.store.peekRecord('renouncement-status-dictionary-entry', '59a952b900287fbd4cf4985a'),
                ppmAddress: this.store.createRecord('address'),
                clientInfo: this.store.createRecord('embed-individual-client', {
                    baseAddress: this.store.createRecord('address')
                })
            });
        },
        setupController: function setupController(controller, model) {
            controller.clear();
            controller.clearFormResult();
            controller.set('model', model);
        },
        deactivate: function deactivate() {

            var model = this.modelFor('renouncement.add');

            if (model.get('isNew')) {
                model.get('ppmAddress.content').rollbackAttributes();
                model.get('clientInfo.content.baseAddress.content').rollbackAttributes();
                model.get('clientInfo.content').rollbackAttributes();
                model.rollbackAttributes();
            }
        },
        actions: {
            chooseEeContract: function chooseEeContract(contract) {
                this.get('controller').set('chosenEeContract', contract.get('id'));
            },
            chooseGasContract: function chooseGasContract(contract) {
                this.get('controller').set('chosenGasContract', contract.get('id'));
            }
        }
    });

    exports['default'] = RenouncementAddRoute;
});
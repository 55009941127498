define('ember-new/routes/individual-contract-price-lists/business-tariff/edit', ['exports', 'ember', 'ember-new/routes/authenticated'], function (exports, _ember, _emberNewRoutesAuthenticated) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({

        beforeModel: function beforeModel(transition) {
            window.scrollTo(0, 0); // scroll to the top of application before reloading
        },

        model: function model(params) {
            return this.store.findRecord('IndividualContractBusinessTariffListViewModel', params.businessTariffUuid, { reload: true });
        },

        setupController: function setupController(ctrl, model) {
            ctrl.set('model', model);
        },

        afterModel: function afterModel(model) {
            return model.reload();
        },

        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            }
        }
    });
});
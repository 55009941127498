define("ember-new/components/d-form/b2b-contract/dzu/add-ee-invoice", ["exports", "ember-new/components/d-form-command", "ember-new/templates/components/d-form-b2b-contract-add-invoice", "ember-new/mixins/b2b-contract/controllers/reload-contract-header"], function (exports, _emberNewComponentsDFormCommand, _emberNewTemplatesComponentsDFormB2bContractAddInvoice, _emberNewMixinsB2bContractControllersReloadContractHeader) {
    exports["default"] = _emberNewComponentsDFormCommand["default"].extend(_emberNewMixinsB2bContractControllersReloadContractHeader["default"], {
        layout: _emberNewTemplatesComponentsDFormB2bContractAddInvoice["default"],
        classNames: ['d-table-row'],
        tagName: 'form',

        commandName: 'AddEeInvoiceToPpeDzuCommand',

        afterSuccessfulSubmit: function afterSuccessfulSubmit() {
            var targetController = this.get('targetObject');
            var mainController = targetController.container.lookup('controller:b2b-contract.dzu.show-ee.ppe.details.invoices');
            var mainRoute = mainController.get('target');
            mainRoute.send('refreshModel');
            this.reloadContractHeader();
            setTimeout(function () {
                targetController.transitionToRoute('b2b-contract.dzu.show-ee.ppe.details.invoices');
            }, 500);
        }
    });
});
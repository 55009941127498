define('ember-new/controllers/b2b-contract/administration/section-products/add-to-condition', ['exports', 'ember', 'ember-new/mixins/b2b-contract/controllers/section-products/add-to-condition'], function (exports, _ember, _emberNewMixinsB2bContractControllersSectionProductsAddToCondition) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsB2bContractControllersSectionProductsAddToCondition['default'], {

        typeFilterIsGas: (function () {
            return this.get('medium') === 'gas';
        }).property('medium'),

        typeFilterIsEe: (function () {
            return this.get('medium') === 'ee';
        }).property('medium'),

        actions: {
            typeFilterEe: function typeFilterEe() {
                this.set('medium', 'ee');
                return this.get('medium');
            },

            typeFilterGas: function typeFilterGas() {
                this.set('medium', 'gas');

                return this.get('medium');
            }
        },

        fillForm: function fillForm(form) {
            form.set('model.productName', null);
            form.set('model.medium', null);
            form.set('model.isIndex', this.isIndex);
            form.set('model.valueType', null);
            form.set('model.periodType', null);
            form.set('model.productModel', null);
            form.set('model.isProductBasePrice', this.isProductBasePrice);
            form.set('model.isAutomaticBasePrice', this.isAutomaticBasePrice);
        },

        afterCommand: function afterCommand(response) {
            this.send('refreshModelAction');
        }
    });
});
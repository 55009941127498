define('ember-new/controllers/b2b-contract/administration/vas/delete', ['exports', 'ember', 'ember-new/config/environment'], function (exports, _ember, _emberNewConfigEnvironment) {
    exports['default'] = _ember['default'].Controller.extend({
        ajax: _ember['default'].inject.service('ajax'),
        applicationAlerts: _ember['default'].inject.service('application-alerts-manager'),
        isDeleting: false,

        actions: {
            'delete': function _delete() {
                var _this2 = this;

                var _mainThis = this;
                this.set('isDeleting', true);
                var serializedData = {
                    DeleteVasCommand: {
                        uuid: this.get('model.uuid')
                    }
                };

                this.get('ajax').ajaxPost(_emberNewConfigEnvironment['default'].apiUrl + '/rest/deletevascommands', serializedData).then(function () {
                    _mainThis.set('isDeleting', false);
                    var route = _this2.container.lookup('route:b2b-contract.administration.vas');
                    route.refresh();
                    _this2.get('applicationAlerts').addActionMessage('success', 'VAS został usunięty');
                    _this2.transitionToRoute('b2b-contract.administration.vas');
                }, function (response) {
                    _mainThis.set('isDeleting', false);
                    var _this = _this2;
                    if (response['errors']) {
                        response['errors'].forEach(function (error) {
                            _this.get('applicationAlerts').addActionMessage('danger', error['message']);
                        });
                    } else {
                        _this2.get('applicationAlerts').addActionMessage('danger', "Nie udało się usunąć VAS-u");
                    }
                });
            }
        }
    });
});
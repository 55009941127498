define("ember-new/models/individual-person-info", ["exports", "ember", "ember-data", "ember-new/models/abstract"], function (exports, _ember, _emberData, _emberNewModelsAbstract) {

    var attr = _emberData["default"].attr;
    var refOne = _emberData["default"].belongsTo;

    exports["default"] = _emberNewModelsAbstract["default"].extend({
        pesel: attr('string'),
        firstName: attr('string'),
        lastName: attr('string'),

        phone: attr('string'),
        email: attr('string')
    });
});
define("ember-new/helpers/add-one", ["exports", "ember", "ember-new/config/environment"], function (exports, _ember, _emberNewConfigEnvironment) {
    exports["default"] = _ember["default"].Helper.helper(function (params) {

        var number = params[0];

        number = parseInt(number);
        number += 1;

        return number;
    });
});
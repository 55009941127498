define("ember-new/controllers/b2b-contract/administration/section-products/list", ["exports", "ember", "ember-new/mixins/sortable-controller", "ember-new/mixins/filterable-controller", "ember-new/mixins/paginable-controller"], function (exports, _ember, _emberNewMixinsSortableController, _emberNewMixinsFilterableController, _emberNewMixinsPaginableController) {
    exports["default"] = _ember["default"].Controller.extend(_emberNewMixinsSortableController["default"], _emberNewMixinsFilterableController["default"], _emberNewMixinsPaginableController["default"], {

        applicationAlerts: _ember["default"].inject.service('application-alerts-manager'),

        totalItems: null,

        isReloading: false,

        typeFilter: null,
        kindFilter: null,
        contractProduct: null,

        isGrantedToEdit: (function () {
            return this.get('TheUser').isGranted(['ROLE_ADMIN', 'ROLE_DG', 'ROLE_DE', 'ROLE_SALES_SUPPORT', 'ROLE_DZU_MANAGER', 'ROLE_DZU2_MANAGER']);
        }).property(),

        hasProducts: _ember["default"].computed('model', function () {
            return this.get('model.length') > 0;
        }),

        type: [{ label: 'EE', value: 'ee' }, { label: 'Gaz', value: 'gas' }],

        kind: [{ label: 'do umowy', value: 'contract' }, { label: 'do warunku', value: 'price_condition' }],

        yesno: [{ label: 'Tak', value: '1' }, { label: 'Nie', value: '0' }]

    });
});
define("ember-new/routes/b2b-contract/administration/section-products/list", ["exports", "ember-new/routes/authenticated", "ember"], function (exports, _emberNewRoutesAuthenticated, _ember) {
    exports["default"] = _emberNewRoutesAuthenticated["default"].extend({

        queryParams: {
            page: { refreshModel: false },
            itemsPerPage: { refreshModel: false },
            typeFilter: { refreshModel: false },
            kindFilter: { refreshModel: false },
            contractProduct: { refreshModel: false },
            everyIndexAvailableInConditions: { refreshModel: false }
        },

        model: function model(params) {
            return _ember["default"].RSVP.hash({
                productsList: this.store.query('BusinessProductListViewModel', params),
                contractProductsList: this.store.query('BusinessContractProductViewModel', params)
            });
        },

        setupController: function setupController(ctrl, model) {
            ctrl.set('model', model.productsList);
            ctrl.set('contractProductsList', model.contractProductsList);
        },

        actions: {
            queryParamsDidChange: function queryParamsDidChange() {
                this.refresh();
            }
        }
    });
});
define('ember-new/utils/encode-object-to-uri', ['exports'], function (exports) {
    exports['default'] = encodeObjectToURI;
    /**
     * Konwertuje obiekt na string zgodny z URI RFC
     * Dane w formie obiektu {a: "foo", b: "bar"}
     * @param data obiekt do konwersji
     * @returns {string} zgodny z URI RFC
     */

    function encodeObjectToURI(data) {
        return Object.keys(data).map(function (value) {
            if (data[value]) return value + '=' + encodeURIComponent(data[value]);else return value + '=';
        }).join('&');
    }
});
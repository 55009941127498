define("ember-new/templates/b2b-contract/dzu/partials/ppm-prices/value/gas/contract-price-with-base", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": false,
        "revision": "Ember@2.3.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 13,
            "column": 5
          }
        },
        "moduleName": "ember-new/templates/b2b-contract/dzu/partials/ppm-prices/value/gas/contract-price-with-base.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("td");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "cell-flex text-center");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "flex-item");
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "flex-item");
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element0, [3]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(element1, 1, 1);
        morphs[1] = dom.createMorphAt(element1, 3, 3);
        morphs[2] = dom.createMorphAt(element1, 5, 5);
        morphs[3] = dom.createMorphAt(element2, 1, 1);
        morphs[4] = dom.createMorphAt(element2, 3, 3);
        return morphs;
      },
      statements: [["inline", "cell-data-label", [], ["value", ["subexpr", "@mut", [["get", "periodValue.product", ["loc", [null, [4, 34], [4, 53]]]]], [], []], "label", "Produkt", "afterValue", ""], ["loc", [null, [4, 10], [4, 86]]]], ["inline", "cell-data-label", [], ["value", ["subexpr", "@mut", [["get", "periodValue.price", ["loc", [null, [5, 34], [5, 51]]]]], [], []], "label", "Cena na umowie", "afterValue", " gr/kWh"], ["loc", [null, [5, 10], [5, 97]]]], ["inline", "cell-data-label", [], ["value", ["subexpr", "@mut", [["get", "periodValue.basePrice", ["loc", [null, [6, 34], [6, 55]]]]], [], []], "label", "cena bazowa", "afterValue", " gr/kWh"], ["loc", [null, [6, 10], [6, 98]]]], ["inline", "cell-data-label", [], ["value", ["subexpr", "@mut", [["get", "periodValue.subscription", ["loc", [null, [9, 34], [9, 58]]]]], [], []], "label", "Abonament na umowie", "afterValue", " zł/m-c"], ["loc", [null, [9, 10], [9, 109]]]], ["inline", "cell-data-label", [], ["value", ["subexpr", "@mut", [["get", "periodValue.baseSubscription", ["loc", [null, [10, 34], [10, 62]]]]], [], []], "label", "Abonament bazowy", "afterValue", " zł/m-c"], ["loc", [null, [10, 10], [10, 110]]]]],
      locals: [],
      templates: []
    };
  })());
});
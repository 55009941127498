define('ember-new/controllers/meeting-verification', ['exports', 'ember', 'ember-new/mixins/controllers/meeting-verification/authorization-checker'], function (exports, _ember, _emberNewMixinsControllersMeetingVerificationAuthorizationChecker) {
    exports['default'] = _ember['default'].Controller.extend(_emberNewMixinsControllersMeetingVerificationAuthorizationChecker['default'], {

        isAllowedToCampaignList: (function () {
            return this.get('TheUser').isGranted(['ROLE_CC_VERIFICATOR', 'ROLE_DE', 'ROLE_DG', 'ROLE_SALES_SUPPORT']);
        }).property(),

        isAllowedToCampaignOrdering: (function () {
            return this.get('TheUser').isGranted(['ROLE_CC_VERIFICATOR', 'ROLE_DE', 'ROLE_DG', 'ROLE_SALES_SUPPORT']);
        }).property()

    });
});
define('ember-new/components/div-pagination-info', ['exports', 'ember', 'ember-new/mixins/d-form/d-field', 'ember-new/components/d-form'], function (exports, _ember, _emberNewMixinsDFormDField, _emberNewComponentsDForm) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'div',

        page: 1,
        itemsPerPage: 10,
        totalItems: 1,

        itemsFrom: (function () {
            return this.get('page') * this.get('itemsPerPage') - this.get('itemsPerPage') + 1;
        }).property('page', 'itemsPerPage'),

        itemsTo: (function () {
            var ret = this.get('page') * this.get('itemsPerPage');
            return ret < this.get('totalItems') ? ret : this.get('totalItems');
        }).property('page', 'itemsPerPage', 'totalItems'),

        totalPages: (function () {
            return Math.ceil(this.get('totalItems') / this.get('itemsPerPage'));
        }).property('itemsPerPage', 'totalItems'),

        contentChanged: (function () {
            this.set('content', 'dupa');
        }).observes('page', 'itemsPerPage', 'totalItems'),

        haveToPaginate: (function () {
            if (this.get('totalItems') > 0) {
                return true;
            }
            return false;
        }).property('totalItems')

    });
});
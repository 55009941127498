define("ember-new/routes/b2b-contract/administration/gas-business-tariff/delete", ["exports", "ember-new/routes/authenticated", "ember"], function (exports, _emberNewRoutesAuthenticated, _ember) {
    exports["default"] = _emberNewRoutesAuthenticated["default"].extend({

        model: function model(params) {
            return _ember["default"].RSVP.hash({
                model: this.store.findRecord('GasBusinessTariffEntryViewModel', params.tariffUuid),
                uuid: params.tariffUuid
            });
        },

        setupController: function setupController(controller, model) {
            controller.set('uuid', model.uuid);
            controller.set('model', model.model);
        },

        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            }
        }
    });
});
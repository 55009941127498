define('ember-new/serializers/individual-contract-simplified', ['exports', 'ember-new/serializers/application', 'ember-new/mixins/base-document-serializer'], function (exports, _emberNewSerializersApplication, _emberNewMixinsBaseDocumentSerializer) {

    var IndividualContractSimplifiedSerializer = _emberNewSerializersApplication['default'].extend(_emberNewMixinsBaseDocumentSerializer['default'], {
        IndividualContractSimplifiedSerializerInit: (function () {
            this.addAttrsConfig({
                renouncementStatus: { serialize: 'id', deserialize: 'id' }
            });
        }).on('init')
    });

    exports['default'] = IndividualContractSimplifiedSerializer;
});
define('ember-new/mixins/individual-contract-price-lists/models/individual-contract-template-condition-list-view-model', ['exports', 'ember-data'], function (exports, _emberData) {

    var attr = _emberData['default'].attr;

    exports['default'] = Ember.Mixin.create({
        uuid: attr('string'),
        type: attr('string'),
        periodType: attr('string'),
        yearFrom: attr('string'),
        monthFrom: attr('string'),
        yearTo: attr('string'),
        monthTo: attr('string'),
        price: attr('string'),
        price2: attr('string'),
        price3: attr('string'),
        fee: attr('string')
    });
});
define("ember-new/templates/b2b-contract/salesman/show-gas/volumes", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 20,
                    "column": 20
                  },
                  "end": {
                    "line": 22,
                    "column": 20
                  }
                },
                "moduleName": "ember-new/templates/b2b-contract/salesman/show-gas/volumes.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "d-field-text", [], ["type", "hidden", "key", "contractUuid"], ["loc", [null, [21, 24], [21, 73]]]]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 23,
                    "column": 20
                  },
                  "end": {
                    "line": 25,
                    "column": 20
                  }
                },
                "moduleName": "ember-new/templates/b2b-contract/salesman/show-gas/volumes.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "d-field-text", [], ["key", "numberOfMonthsBySalesman"], ["loc", [null, [24, 24], [24, 71]]]]],
              locals: [],
              templates: []
            };
          })();
          var child2 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 26,
                    "column": 20
                  },
                  "end": {
                    "line": 28,
                    "column": 20
                  }
                },
                "moduleName": "ember-new/templates/b2b-contract/salesman/show-gas/volumes.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "d-field-text", [], ["key", "consumptionBySalesman"], ["loc", [null, [27, 24], [27, 68]]]]],
              locals: [],
              templates: []
            };
          })();
          var child3 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 29,
                    "column": 20
                  },
                  "end": {
                    "line": 31,
                    "column": 20
                  }
                },
                "moduleName": "ember-new/templates/b2b-contract/salesman/show-gas/volumes.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "d-field-text", [], ["key", "overheadBySalesman"], ["loc", [null, [30, 24], [30, 65]]]]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 19,
                  "column": 16
                },
                "end": {
                  "line": 32,
                  "column": 16
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/salesman/show-gas/volumes.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
              morphs[3] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "d-form-row", [], ["hidden", true], 0, null, ["loc", [null, [20, 20], [22, 35]]]], ["block", "d-form-row", [], ["label", "Liczba miesięcy"], 1, null, ["loc", [null, [23, 20], [25, 35]]]], ["block", "d-form-row", [], ["label", "Zużycie (MWh)"], 2, null, ["loc", [null, [26, 20], [28, 35]]]], ["block", "d-form-row", [], ["label", "Narzut (zł/MWh)"], 3, null, ["loc", [null, [29, 20], [31, 35]]]]],
            locals: [],
            templates: [child0, child1, child2, child3]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 18,
                "column": 12
              },
              "end": {
                "line": 33,
                "column": 12
              }
            },
            "moduleName": "ember-new/templates/b2b-contract/salesman/show-gas/volumes.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "d-form-command", [], ["commandName", "EditGasContractDataBySalesmanContextCommand", "fillCommandModel", ["subexpr", "@mut", [["get", "fillSalesmanForm", ["loc", [null, [19, 109], [19, 125]]]]], [], []], "afterSuccessfulCommand", ["subexpr", "@mut", [["get", "afterSalesmanCommand", ["loc", [null, [19, 149], [19, 169]]]]], [], []]], 0, null, ["loc", [null, [19, 16], [32, 35]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 33,
                "column": 12
              },
              "end": {
                "line": 40,
                "column": 12
              }
            },
            "moduleName": "ember-new/templates/b2b-contract/salesman/show-gas/volumes.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("ul");
            dom.setAttribute(el1, "class", "todo-list");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("Masa marży: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("Wolumen: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("Zużycie: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("Narzut: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element6 = dom.childAt(fragment, [1]);
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(dom.childAt(element6, [1, 0]), 1, 1);
            morphs[1] = dom.createMorphAt(dom.childAt(element6, [3, 0]), 1, 1);
            morphs[2] = dom.createMorphAt(dom.childAt(element6, [5, 0]), 1, 1);
            morphs[3] = dom.createMorphAt(dom.childAt(element6, [7, 0]), 1, 1);
            return morphs;
          },
          statements: [["content", "model.mmBySalesman", ["loc", [null, [35, 72], [35, 94]]]], ["content", "model.volumeBySalesman", ["loc", [null, [36, 69], [36, 95]]]], ["content", "model.consumptionBySalesman", ["loc", [null, [37, 69], [37, 100]]]], ["content", "model.overheadBySalesman", ["loc", [null, [38, 68], [38, 96]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 8
            },
            "end": {
              "line": 41,
              "column": 8
            }
          },
          "moduleName": "ember-new/templates/b2b-contract/salesman/show-gas/volumes.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "editState", ["loc", [null, [18, 18], [18, 27]]]]], [], 0, 1, ["loc", [null, [18, 12], [40, 19]]]]],
        locals: ["editState"],
        templates: [child0, child1]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 86,
                  "column": 44
                },
                "end": {
                  "line": 86,
                  "column": 129
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/salesman/show-gas/volumes.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "roundwithspaces", [["get", "info.basePrice.p", ["loc", [null, [86, 86], [86, 102]]]]], ["precision", 2, "multiplier", 1], ["loc", [null, [86, 68], [86, 129]]]]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 86,
                  "column": 129
                },
                "end": {
                  "line": 86,
                  "column": 138
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/salesman/show-gas/volumes.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("0");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 90,
                  "column": 44
                },
                "end": {
                  "line": 90,
                  "column": 137
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/salesman/show-gas/volumes.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "roundwithspaces", [["get", "info.contractPrice.p", ["loc", [null, [90, 90], [90, 110]]]]], ["precision", 2, "multiplier", 1], ["loc", [null, [90, 72], [90, 137]]]]],
            locals: [],
            templates: []
          };
        })();
        var child3 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 90,
                  "column": 137
                },
                "end": {
                  "line": 90,
                  "column": 146
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/salesman/show-gas/volumes.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("0");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child4 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 94,
                  "column": 44
                },
                "end": {
                  "line": 94,
                  "column": 127
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/salesman/show-gas/volumes.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "roundwithspaces", [["get", "info.overhead.o", ["loc", [null, [94, 85], [94, 100]]]]], ["precision", 2, "multiplier", 1], ["loc", [null, [94, 67], [94, 127]]]]],
            locals: [],
            templates: []
          };
        })();
        var child5 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 94,
                  "column": 127
                },
                "end": {
                  "line": 94,
                  "column": 136
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/salesman/show-gas/volumes.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("0");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child6 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 98,
                  "column": 44
                },
                "end": {
                  "line": 98,
                  "column": 119
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/salesman/show-gas/volumes.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "roundwithspaces", [["get", "info.volume", ["loc", [null, [98, 81], [98, 92]]]]], ["precision", 2, "multiplier", 1], ["loc", [null, [98, 63], [98, 119]]]]],
            locals: [],
            templates: []
          };
        })();
        var child7 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 98,
                  "column": 119
                },
                "end": {
                  "line": 98,
                  "column": 128
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/salesman/show-gas/volumes.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("0");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child8 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 102,
                  "column": 44
                },
                "end": {
                  "line": 102,
                  "column": 115
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/salesman/show-gas/volumes.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "roundwithspaces", [["get", "info.mm.m", ["loc", [null, [102, 79], [102, 88]]]]], ["precision", 2, "multiplier", 1], ["loc", [null, [102, 61], [102, 115]]]]],
            locals: [],
            templates: []
          };
        })();
        var child9 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 102,
                  "column": 115
                },
                "end": {
                  "line": 102,
                  "column": 124
                }
              },
              "moduleName": "ember-new/templates/b2b-contract/salesman/show-gas/volumes.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("0");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 82,
                "column": 32
              },
              "end": {
                "line": 104,
                "column": 32
              }
            },
            "moduleName": "ember-new/templates/b2b-contract/salesman/show-gas/volumes.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            dom.setAttribute(el1, "class", "first-computed-row");
            var el2 = dom.createTextNode("\n                                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "rowspan", "5");
            dom.setAttribute(el2, "class", "text-center");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("br");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("Ceny bazowe");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            var el2 = dom.createTextNode("\n                                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("Ceny na umowie");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            var el2 = dom.createTextNode("\n                                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("Narzut");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            var el2 = dom.createTextNode("\n                                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("Wolumen");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            var el2 = dom.createTextNode("\n                                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("Masa marży");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var element1 = dom.childAt(element0, [1]);
            var morphs = new Array(7);
            morphs[0] = dom.createMorphAt(element1, 0, 0);
            morphs[1] = dom.createMorphAt(element1, 2, 2);
            morphs[2] = dom.createMorphAt(dom.childAt(element0, [5]), 0, 0);
            morphs[3] = dom.createMorphAt(dom.childAt(fragment, [3, 3]), 0, 0);
            morphs[4] = dom.createMorphAt(dom.childAt(fragment, [5, 3]), 0, 0);
            morphs[5] = dom.createMorphAt(dom.childAt(fragment, [7, 3]), 0, 0);
            morphs[6] = dom.createMorphAt(dom.childAt(fragment, [9, 3]), 0, 0);
            return morphs;
          },
          statements: [["inline", "month-number-to-name-transformer", [["get", "info.month", ["loc", [null, [84, 111], [84, 121]]]]], [], ["loc", [null, [84, 76], [84, 123]]]], ["content", "info.year", ["loc", [null, [84, 128], [84, 141]]]], ["block", "if", [["get", "info.basePrice.p", ["loc", [null, [86, 50], [86, 66]]]]], [], 0, 1, ["loc", [null, [86, 44], [86, 145]]]], ["block", "if", [["get", "info.contractPrice.p", ["loc", [null, [90, 50], [90, 70]]]]], [], 2, 3, ["loc", [null, [90, 44], [90, 153]]]], ["block", "if", [["get", "info.overhead.o", ["loc", [null, [94, 50], [94, 65]]]]], [], 4, 5, ["loc", [null, [94, 44], [94, 143]]]], ["block", "if", [["get", "info.volume", ["loc", [null, [98, 50], [98, 61]]]]], [], 6, 7, ["loc", [null, [98, 44], [98, 135]]]], ["block", "if", [["get", "info.mm.m", ["loc", [null, [102, 50], [102, 59]]]]], [], 8, 9, ["loc", [null, [102, 44], [102, 131]]]]],
          locals: ["info"],
          templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 53,
              "column": 16
            },
            "end": {
              "line": 111,
              "column": 16
            }
          },
          "moduleName": "ember-new/templates/b2b-contract/salesman/show-gas/volumes.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "single-ppm");
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("PPG numer #");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("ul");
          dom.setAttribute(el2, "class", "todo-list");
          var el3 = dom.createTextNode("\n                            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("li");
          var el4 = dom.createElement("span");
          dom.setAttribute(el4, "class", "text");
          var el5 = dom.createElement("strong");
          var el6 = dom.createTextNode("Masa marży: ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("li");
          var el4 = dom.createElement("span");
          dom.setAttribute(el4, "class", "text");
          var el5 = dom.createElement("strong");
          var el6 = dom.createTextNode("Wolumen: ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("li");
          var el4 = dom.createElement("span");
          dom.setAttribute(el4, "class", "text");
          var el5 = dom.createElement("strong");
          var el6 = dom.createTextNode("Zużycie: ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("li");
          var el4 = dom.createElement("span");
          dom.setAttribute(el4, "class", "text");
          var el5 = dom.createElement("strong");
          var el6 = dom.createTextNode("Narzut: ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "button-container");
          var el3 = dom.createTextNode("\n                            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          dom.setAttribute(el3, "type", "button");
          dom.setAttribute(el3, "class", "btn btn-sm btn-success pull-right");
          var el4 = dom.createTextNode("Pokaż więcej");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "ppm-more-box hide");
          var el3 = dom.createTextNode("\n                            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("table");
          dom.setAttribute(el3, "class", "table table-bordered table-striped");
          var el4 = dom.createTextNode("\n                                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("thead");
          var el5 = dom.createTextNode("\n                                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("tr");
          var el6 = dom.createTextNode("\n                                    ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("th");
          dom.setAttribute(el6, "class", "text-center");
          var el7 = dom.createTextNode("Miesiąc");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                                    ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("th");
          dom.setAttribute(el6, "class", "text-center");
          var el7 = dom.createTextNode("Nazwa");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                                    ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("th");
          dom.setAttribute(el6, "class", "text-center");
          var el7 = dom.createTextNode("Wartość");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("tbody");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("                                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var element3 = dom.childAt(element2, [4]);
          var element4 = dom.childAt(element2, [6, 1]);
          var element5 = dom.childAt(element2, [8]);
          var morphs = new Array(9);
          morphs[0] = dom.createMorphAt(dom.childAt(element2, [2]), 1, 1);
          morphs[1] = dom.createMorphAt(dom.childAt(element3, [1, 0]), 1, 1);
          morphs[2] = dom.createMorphAt(dom.childAt(element3, [3, 0]), 1, 1);
          morphs[3] = dom.createMorphAt(dom.childAt(element3, [5, 0]), 1, 1);
          morphs[4] = dom.createMorphAt(dom.childAt(element3, [7, 0]), 1, 1);
          morphs[5] = dom.createAttrMorph(element4, 'data-element');
          morphs[6] = dom.createElementMorph(element4);
          morphs[7] = dom.createAttrMorph(element5, 'data-element');
          morphs[8] = dom.createMorphAt(dom.childAt(element5, [1, 3]), 1, 1);
          return morphs;
        },
        statements: [["content", "ppg.pointNumber", ["loc", [null, [59, 40], [59, 59]]]], ["content", "ppg.mm", ["loc", [null, [62, 80], [62, 90]]]], ["content", "ppg.volume", ["loc", [null, [63, 77], [63, 91]]]], ["content", "ppg.consumption", ["loc", [null, [64, 77], [64, 96]]]], ["content", "ppg.overhead", ["loc", [null, [65, 76], [65, 92]]]], ["attribute", "data-element", ["concat", ["button-", ["get", "ppe.id", ["loc", [null, [69, 164], [69, 170]]]]]]], ["element", "action", ["toggleShowMorePpgInformation", ["get", "ppg.id", ["loc", [null, [69, 76], [69, 82]]]]], [], ["loc", [null, [69, 36], [69, 84]]]], ["attribute", "data-element", ["concat", [["get", "ppg.id", ["loc", [null, [72, 71], [72, 77]]]]]]], ["block", "each", [["get", "ppg.monthlyInfo", ["loc", [null, [82, 40], [82, 55]]]]], [], 0, null, ["loc", [null, [82, 32], [104, 41]]]]],
        locals: ["ppg"],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes", "wrong-type"]
        },
        "revision": "Ember@2.3.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 120,
            "column": 10
          }
        },
        "moduleName": "ember-new/templates/b2b-contract/salesman/show-gas/volumes.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-md-6 col-sm-6");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "box box-primary");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "box-header");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h3");
        dom.setAttribute(el5, "class", "box-title");
        var el6 = dom.createTextNode("Dane wg DzU");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "box-body");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("ul");
        dom.setAttribute(el5, "class", "todo-list");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createElement("span");
        dom.setAttribute(el7, "class", "text");
        var el8 = dom.createElement("strong");
        var el9 = dom.createTextNode("Masa marży: ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createElement("span");
        dom.setAttribute(el7, "class", "text");
        var el8 = dom.createElement("strong");
        var el9 = dom.createTextNode("Wolumen: ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createElement("span");
        dom.setAttribute(el7, "class", "text");
        var el8 = dom.createElement("strong");
        var el9 = dom.createTextNode("Zużycie: ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createElement("span");
        dom.setAttribute(el7, "class", "text");
        var el8 = dom.createElement("strong");
        var el9 = dom.createTextNode("Narzut: ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-md-6 col-sm-6");
        var el3 = dom.createTextNode("\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "box box-primary");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "box-header");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h3");
        dom.setAttribute(el5, "class", "box-title");
        var el6 = dom.createTextNode("Lista PPG:");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "box-body");
        var el5 = dom.createTextNode("\n\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element7 = dom.childAt(fragment, [0]);
        var element8 = dom.childAt(element7, [1]);
        var element9 = dom.childAt(element8, [1, 3, 1]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(dom.childAt(element9, [1, 0]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element9, [3, 0]), 1, 1);
        morphs[2] = dom.createMorphAt(dom.childAt(element9, [5, 0]), 1, 1);
        morphs[3] = dom.createMorphAt(dom.childAt(element9, [7, 0]), 1, 1);
        morphs[4] = dom.createMorphAt(element8, 3, 3);
        morphs[5] = dom.createMorphAt(dom.childAt(element7, [3, 1, 3]), 1, 1);
        morphs[6] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["content", "model.mmByDzu", ["loc", [null, [9, 72], [9, 89]]]], ["content", "model.volumeByDzu", ["loc", [null, [10, 69], [10, 90]]]], ["content", "model.consumptionByDzu", ["loc", [null, [11, 69], [11, 95]]]], ["content", "model.overheadByDzu", ["loc", [null, [12, 68], [12, 91]]]], ["block", "box-for-form", [], ["title", "Dane wg Doradcy", "isAllowedEdit", true], 0, null, ["loc", [null, [17, 8], [41, 25]]]], ["block", "each", [["get", "model.ppgInfo", ["loc", [null, [53, 24], [53, 37]]]]], [], 1, null, ["loc", [null, [53, 16], [111, 25]]]], ["content", "outlet", ["loc", [null, [120, 0], [120, 10]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});
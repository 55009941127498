define('ember-new/models/contract-header-dzu-view-model', ['exports', 'ember-data', 'ember-new/models/abstract'], function (exports, _emberData, _emberNewModelsAbstract) {

    var attr = _emberData['default'].attr;

    exports['default'] = _emberData['default'].Model.extend({
        contractNo: attr('string'),
        type: attr('string'),
        clientId: attr('string'),
        productId: attr('string'),
        contractStatus: attr('number'),
        commentsNotifications: attr('number'),
        notificationsExist: attr('boolean')
    });
});
define('ember-new/models/ee-distribution-rate', ['exports', 'ember-new/models/abstract', 'ember-data'], function (exports, _emberNewModelsAbstract, _emberData) {

    var attr = _emberData['default'].attr;
    var refOne = _emberData['default'].belongsTo;
    var refMany = _emberData['default'].hasMany;

    exports['default'] = _emberNewModelsAbstract['default'].extend({
        osd: refOne('osd-dictionary-entry', { inverse: null }),
        tariff: refOne('individual-contract-ee-tariff-group', { inverse: null }),
        name: attr('string'),
        usysExportId: attr('number'),
        oplataAbonamentowa: refOne('oplataAbonamentowa', { inverse: null }),
        oplataPrzejsciowa: refOne('oplataPrzejsciowa', { inverse: null }),
        oplataSieciowa: refOne('oplataSieciowa', { inverse: null }),
        priceOplataAbonamentowa: attr('number'),
        priceOplataPrzejsciowa: attr('number'),
        priceOplataSieciowa: attr('number')
    });
});
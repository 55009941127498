define('ember-new/routes/individual-contract-price-lists/template/edit', ['exports', 'ember', 'ember-new/routes/authenticated', 'ember-new/mixins/individual-contract-price-lists/template'], function (exports, _ember, _emberNewRoutesAuthenticated, _emberNewMixinsIndividualContractPriceListsTemplate) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend(_emberNewMixinsIndividualContractPriceListsTemplate['default'], {

        model: function model(params) {

            this.store.unloadAll('IndividualContractProductListViewModel');

            this.set('templateUuid', params.templateUuid);

            return _ember['default'].RSVP.hash({
                products: this.store.query('IndividualContractProductListViewModel', params),
                tariffs: this.store.query('IndividualContractBusinessTariffListViewModel', params),
                template: this.store.findRecord('IndividualContractTemplateListViewModel', params.templateUuid, { reload: true })
            });
        },

        setupController: function setupController(ctrl, model) {
            ctrl.set('model', model.template);
            ctrl.set('products', model.products);
            ctrl.set('tariffs', model.tariffs);
            ctrl.set('templateUuid', this.get('templateUuid'));
        }

    });
});
define('ember-new/components/div-filter-autoenter', ['exports', 'ember', 'ember-new/components/div-filter', 'ember-new/templates/components/div-filter'], function (exports, _ember, _emberNewComponentsDivFilter, _emberNewTemplatesComponentsDivFilter) {
    exports['default'] = _emberNewComponentsDivFilter['default'].extend({

        layout: _emberNewTemplatesComponentsDivFilter['default'],

        yieldValueChanged: (function () {
            this.send('applyFilter');
        }).observes('yieldValue')

    });
});
define("ember-new/mixins/individual-contract-price-lists/product", ["exports", "ember", "ember-new/routes/authenticated", "ember-new/config/environment"], function (exports, _ember, _emberNewRoutesAuthenticated, _emberNewConfigEnvironment) {
    exports["default"] = _ember["default"].Mixin.create({

        beforeModel: function beforeModel(transition) {
            window.scrollTo(0, 0); // scroll to the top of application before reloading
        },

        model: function model(params) {

            this.set('productUuid', params.productUuid);

            return this.store.findRecord('IndividualContractProductListViewModel', params.productUuid, { reload: true });
        },

        setupController: function setupController(ctrl, model) {
            ctrl.set('model', model);
            ctrl.set('productUuid', this.get('productUuid'));
        },

        afterModel: function afterModel(model) {
            return model.reload();
        },

        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            }
        }
    });
});
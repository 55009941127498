define('ember-new/routes/meeting-verification/handling', ['exports', 'ember-new/routes/authenticated'], function (exports, _emberNewRoutesAuthenticated) {
    exports['default'] = _emberNewRoutesAuthenticated['default'].extend({
        model: function model() {
            return this.get('store').createRecord('MeetingVerificationThuliumImport');
        },

        setupController: function setupController(ctrl, model) {
            ctrl.set('model', model);
            ctrl.set('store', this.get('store'));
        },

        actions: {
            refreshRoute: function refreshRoute() {
                this.refresh();
            }
        }
    });
});
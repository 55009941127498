define('ember-new/mixins/b2b-contract/components/d-form/district-county', ['exports', 'ember', 'ember-new/templates/components/d-form'], function (exports, _ember, _emberNewTemplatesComponentsDForm) {
    exports['default'] = _ember['default'].Mixin.create({
        layout: _emberNewTemplatesComponentsDForm['default'],
        districtField: null,
        countyField: null,
        initializeMixin: (function () {
            if (!this.get('districtField') || !this.get('countyField')) {
                throw new _ember['default'].Error("You need to specify  'districtField' and 'countyField' to use district-county-mixin");
            }
        }).on('init'),

        configure: function configure() {
            var _this = this;

            var county = this.get('countyField'),
                district = this.get('districtField');

            /**
             * @param originalContent zawartosc do przefiltrowania
             * @returns {*}
             */
            var getFilteredContent = function getFilteredContent(originalContent) {
                var selectedDistrictUuid = _this.get('formData.' + district); // aktualnie wybran districtUuid

                if (!selectedDistrictUuid) {
                    return originalContent;
                }

                var filtredCounties = _this.get('store').peekAll('county-dictionary-entry').filter(function (record) {
                    return record.get('districtUuid') === selectedDistrictUuid;
                });

                var countiesUuids = [];

                filtredCounties.forEach(function (element) {
                    countiesUuids.push(element.get('uuid'));
                });

                return originalContent.filter(function (record) {
                    return countiesUuids.includes(record.value);
                });
            };

            /**
             * Filtrowanie pola osdSection
             */
            this.registerContentFilter(county, function (originalContent) {
                return getFilteredContent(originalContent);
            });

            /**
             * Przy zmianie wartosci pola `clientDistrict` przefiltruj `countyField`
             */
            this.addObserver('formData.' + district, function () {
                _this.filterContentFor(county, false); // przekazujemy 'false' aby zapobiec zNULLowaniu wartości pola OSD SECTION przez metodę filterContentFor w d-form.js
            });
        }
    });
});
define('ember-new/helpers/is-array', ['exports', 'ember-truth-helpers/helpers/is-array'], function (exports, _emberTruthHelpersHelpersIsArray) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberTruthHelpersHelpersIsArray['default'];
    }
  });
  Object.defineProperty(exports, 'isArray', {
    enumerable: true,
    get: function get() {
      return _emberTruthHelpersHelpersIsArray.isArray;
    }
  });
});
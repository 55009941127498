define('ember-new/controllers/tasks/tasks-client/reservation/request-ee', ['exports', 'ember', 'ember-new/config/environment'], function (exports, _ember, _emberNewConfigEnvironment) {
    exports['default'] = _ember['default'].Controller.extend({

        fillForm: function fillForm(form) {
            console.log('fill');
            form.set("model.idClient", this.get('model.id'));
            form.set("model.comment", null);
        }

    });
});
define('ember-new/models/ee-price-condition-product-view-model', ['exports', 'ember-new/models/abstract', 'ember-data'], function (exports, _emberNewModelsAbstract, _emberData) {

    var attr = _emberData['default'].attr;

    exports['default'] = _emberNewModelsAbstract['default'].extend({
        name: attr('string'),
        periodType: attr('string'),
        priceSetSelectVisible: attr('boolean'),
        requiresBasePrices: attr('boolean'),
        isIndex: attr('boolean'),
        autoBasePrices: attr('boolean')
    });
});
define('ember-new/components/user-switch', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        selectedUser: null,
        visibleOnMobile: false,
        dictionaryManager: _ember['default'].inject.service('dictionaries-manager'),
        usersToSwitch: null,

        init: function init() {
            this._super.apply(this, arguments);
            this._prepereUsersListForSelect();
        },

        // initialize: function () {
        //     if (!this.get('users'))
        //         this.set('users', this.usersToSwitchDictionary());
        // }.on('didInsertElement'),

        _prepereUsersListForSelect: function _prepereUsersListForSelect() {
            var usersDictionary = this.get('dictionaryManager').getUsersDictionary('users_to_switch');
            var usersList = this._mapUsersToList(usersDictionary);
            this.set('usersToSwitch', usersList);
        },

        _mapUsersToList: function _mapUsersToList(usersDictionary) {
            return usersDictionary.map(function (user) {
                return { label: user.fullNameWithRole, value: user.username };
            });
        },

        isAllowedToSwitchUsers: (function () {
            return this.get('TheUser').isGranted('ROLE_ALLOWED_TO_SWITCH');
        }).property(),

        // usersToSwitchDictionary: function () {
        //     return this.get('dictionaryManager').getUsersDictionary('users_to_switch');
        // }.property(),

        actions: {
            switchUser: function switchUser() {
                var selectedUser = this.get('selectedUser');
                if (typeof selectedUser === 'undefined' || !selectedUser) {
                    alert('Wybierz użytkownika!');
                    return false;
                }

                this.get('TheUser').switchUser(selectedUser.value);
            },
            switchUserFromSelect: function switchUserFromSelect(selection) {
                this.set('selectedUser', selection);
                this.get('TheUser').switchUser(selection.value);
            },
            switchBackToBaseUser: function switchBackToBaseUser() {
                this.get('TheUser').switchBackToBaseUser();
            }
        }
    });
});